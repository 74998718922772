import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTeamProps,
  EditTeamProps,
  GetAllMemberListSliceProps,
  Member,
  State,
  TeamDetailSliceProps,
  User,
} from "../../../../type";
import { CreateTeamLayout } from "../../../../layouts/admin/teams/createTeam/CreateTeamLayout";
import { createTeam } from "../../../../redux/slices/CreateTeamSlice";
import { setUnsavedTeamChanges } from "../../../../redux/slices/CreateTeamSlice"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { CreateTeamCancelModal } from "./createTeamCancelModal/createTeamCancelModal";
import TeamMemberList from "./TeamMemberList";
import { useCustomStyle } from "../../../../styles";
import { createTeamStyles } from "../helper";
import { getAllMemberList } from "../../../../redux/slices/AllMemberList";
import { USER_ROLES } from "../../../../enums/teamEnums";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTeamDetails } from "../../../../redux/slices/TeamDetailsSlice";
import { editTeam } from "../../../../redux/slices/EditTeamSlice";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { OperationsEnum } from "../../../../enums/operationEnums";
import { filter, find, map } from "lodash";
import { HttpStatus } from "../../../../enums/httpStatus";

// EditTeam page also using this CreateTeam component 
export const CreateEditTeam = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const [allSelectedUsers, setAllSelectedUsers] = React.useState<User[]>([]);
  const [teamName, setTeamName] = React.useState<String>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [editTeamSubmitted, setEditTeamSubmitted] = useState<boolean>(false);
  const [createTeamSubmitted, setCreateTeamSubmitted] = useState<boolean>(false);
  const [allExisitingUsers, setAllExisitingUsers] = React.useState<User[]>([]);
  const [removeUsers, setRemoveUsers] = React.useState<User[]>([])
   const [errors, setErrors] = useState<any>({});
  const users: Array<User> = [];
  const [allSelectedUserRoles, setAllSelectedUserRoles] = React.useState<
    Member[]
  >([]);

  const labels = useSelector((state: State) => state.membersList.data) ?? users;
  const loading: boolean = useSelector(
    (state: State) => state.membersList.loading
  );

  const { data: teamDetails } = useSelector((state: State) => state.teamDetails) ?? [];
  const teamMembers = teamDetails?.teamMembers ?? [];
  const teamLeads = teamDetails?.teamLeads ?? [];
  const classes = createTeamStyles();
  const customClasses = useCustomStyle();
  const currentRoutePath: string = useLocation().pathname;
  const isEditTeamRouteView: boolean = currentRoutePath.startsWith(
    "/admin/teams/edit"
  );

  const teamIdCreateResponse = useSelector((state: State)=> state.createTeamDetails?.data?.id); 
  const teamIdEditResponse = useSelector((state: State)=> state.editTeamDetails?.data?.id);
  const { teamId }: any = useParams();
  const { status, text } = useSelector(
    (state: State) => state.createTeamDetails
  );
  const editTeamDetails = useSelector(
    (state: State) => state.editTeamDetails
  );
    const unsavedTeamChanges = useSelector(
    (state: State) => state.createTeamDetails.unsavedTeamChanges
  );


  const mergeUsersWithRole = (teamLeads: User[], teamMembers: User[]) => {
  const teamLeadsWithRole = (teamLeads || [])?.map((member) => ({
      ...member,
      role: USER_ROLES.TEAM_LEAD as USER_ROLES,
    }));

  const teamMembersWithRole = (teamMembers || [])?.map((member) => ({
      ...member,
      role: USER_ROLES.TEAM_MEMBER as USER_ROLES,
    }));

    return [...teamLeadsWithRole, ...teamMembersWithRole];
  };
  const initialUsers = useMemo(() => {
    return mergeUsersWithRole(teamDetails?.teamLeads, teamDetails?.teamMembers);
  }, [teamDetails]);


  const checkValidation = (): boolean => {
    if (teamName.trim().length === 0) {
      return false;
    }
    if (teamName.trim().length > 50) {
      return false;
    }
    if (allSelectedUsers.length === 0 && !isEditTeamRouteView) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (status === HttpStatus.BAD_REQUEST) {
      const apiError = JSON.parse(text)?.message;
      const validationErrors = { ...errors };
      validationErrors["teamName"] = apiError;
      setErrors(validationErrors);
    }
  }, [status, text]);

  useEffect(() => {
    if (editTeamDetails.status === HttpStatus.BAD_REQUEST) {
      const apiError = JSON.parse(editTeamDetails.text)?.message;
      const validationErrors = { ...errors };
      validationErrors["teamName"] = apiError;
      setErrors(validationErrors);
    }
  }, [editTeamDetails]);

  useEffect(() => {
    setErrors(false);
    dispatch(setUnsavedTeamChanges(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const params : GetAllMemberListSliceProps = {
      isActiveUser: true
    };
    const fetchedUsers = getAllMemberList(params);
    dispatch(fetchedUsers);
  }, []);

  useEffect(() => {
    if (teamId) {
      const param: TeamDetailSliceProps = {
        teamId: teamId,
      };
      dispatch(getTeamDetails(param));
    }
  }, [teamId]);

  useEffect(() => {
    if (teamDetails?.teamName && isEditTeamRouteView) {
     setTeamName(teamDetails.teamName); 
      const allTeamMembersAndLeads = mergeUsersWithRole(
        teamDetails.teamLeads,
        teamDetails.teamMembers
      );
      setAllExisitingUsers(allTeamMembersAndLeads);
    }
  }, [teamLeads, teamMembers]);

  useEffect(() => {
    if (editTeamSubmitted && teamIdEditResponse) {
      history.push({
        pathname: `/admin/teams/details/${teamIdEditResponse}`,
        state: { from: "edit_teams", teamUpdated: true },
      });
      setEditTeamSubmitted(false);
    }
  }, [teamIdEditResponse, editTeamSubmitted])

  useEffect(() => {
    if (createTeamSubmitted && teamIdCreateResponse) {
      history.push({
        pathname: `/admin/teams/details/${teamIdCreateResponse}`,
        state: { from: "create_teams", teamAdded: true },
      });
      setCreateTeamSubmitted(false);
    }
  }, [teamIdCreateResponse, createTeamSubmitted]);
  
  const handleCreateTeam = () => {
    if (checkValidation()) {
      setCreateTeamSubmitted(true);
      const updatedUsers: Member[] = allSelectedUsers.map((teamMember) => ({
        id: teamMember.id,
        role:
          allSelectedUserRoles?.find(({ id }) => id === teamMember.id)?.role ??
          USER_ROLES.TEAM_MEMBER,
      }));
      const args: CreateTeamProps = {
        name: teamName,
        members: updatedUsers,
      };
      dispatch(createTeam(args));
    }
  };

  const handleEditTeam = () => {
    if (checkValidation()) {
      setEditTeamSubmitted(true);
      const addedUsers = map(
        filter(
          allSelectedUsers,
          (user) => !find(initialUsers, (initialU) => initialU.id === user.id)
        ),
        (user) => ({
          id: user.id,
          role:
            allSelectedUserRoles?.find(({ id }) => id === user.id)?.role ??
            USER_ROLES.TEAM_MEMBER,
        })
      );

      const removedUsers = map(
        filter(
        removeUsers,
        (removedUser) => !find(allSelectedUsers, (selectedUser) => selectedUser.id === removedUser.id)
        ),
        (user) => ({ id: user.id, role: user.role })
      );

      const updatedUsers = allSelectedUserRoles.filter((user) =>
        initialUsers.find(
          (initialU) => user.id === initialU.id && user.role !== initialU.role
        )
      );

      const updatedUsersWithOperations = [
        {
          name: OperationsEnum.ADD_MEMBER,
          members: addedUsers,
        },
        {
          name: OperationsEnum.REMOVE_MEMBER,
          members: removedUsers,
        },
        {
          name: OperationsEnum.UPDATE_MEMBER,
          members: updatedUsers,
        },
      ];

      const args: EditTeamProps = {
        id: teamId,
        teamName: teamName,
        operations: updatedUsersWithOperations,
      };
      dispatch(editTeam(args));
    }
  };

  const handleRenderOptions = (option, selected) => (
    <React.Fragment>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={10} container justify="flex-start" alignItems="center">
          <Avatar
            alt={option.firstName.toUpperCase()}
            src={option.imageUrl}
            className={customClasses.avatarSmall}
          />
          <div className={classes.typography_body_3}>
            <Typography
              variant="body1"
              className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_3}`}
            >
              {option.firstName + " " + option.lastName}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const handleCancel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setSubmitted(true);
    setOpenModal(false);
    const currentPage = props.location?.state?.currentPage || 1;
    history.push({ pathname: "/admin/teams", search: `?page=${currentPage}` });
  };

  const isSaveDisabled = !checkValidation();
  useEffect(() => {
    if (!isEditTeamRouteView) {
      dispatch(setUnsavedTeamChanges(!isSaveDisabled));
    }
  }, [isSaveDisabled]);

  const statusCode: number | null = useSelector((state: State) => {  
    const createTeamDetailsStatus = state.createTeamDetails?.status;
    const editTeamDetailsStatus = state.editTeamDetails?.status;
    if(!isEditTeamRouteView) {
      return createTeamDetailsStatus === HttpStatus.CREATED 
        ? createTeamDetailsStatus
        : null;                     
    } else {
      return editTeamDetailsStatus === HttpStatus.OK 
        ? editTeamDetailsStatus
        : null;
    }});

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderTextField = () => (
    <>
      <Typography className={classes.label} style={{ marginBottom: 6 }}>
        {!isEditTeamRouteView ? "Create New Team" : "Edit Team Details"}
      </Typography>
      <Divider className={classes.divider} />
      <Typography
        className={classes.label}
        style={{ marginBottom: 12, marginTop: 12 }}
      >
        Team Name<span style={{ color: "#DC0101" }}>*</span>
      </Typography>
      <TextField
        fullWidth
        placeholder="Enter team name here"
        variant="outlined"
        value={teamName}
        helperText={errors?.teamName || " "}
        error={errors?.teamName}
        className={classes.attributeNameInput}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setTeamName(event.target.value);
        }}
      ></TextField>
    </>
  );

  const renderAutocomplete = () => {
    return (
      <>
        <Grid container>
          <CreateTeamCancelModal
            open={openModal}
            handleCancel={handleCancelModal}
            handleSuccess={handleConfirm}
            disableActions={submitted}
          />

          <Typography
            className={classes.label}
            style={{ marginBottom: 12, marginTop: 42 }}
          >
            Choose Member <span style={{ color: "#DC0101" }}>*</span>
          </Typography>

          <Typography className={classes.infoText} style={{ marginBottom: 12 }}>
            Select one or more members to add in team
          </Typography>

          <Autocomplete
            id="tags-filled"
            className={`${customClasses.popper} ${customClasses.popperWidth} dashboard-autocomplete`}
            multiple
            value={allSelectedUsers}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_event, newValue) =>  setAllSelectedUsers(newValue)}
            renderTags={(value: User[], getTagProps) =>
              value.map((user: User, index: number) => (
                <Chip
                  key={user.id}
                  variant="outlined"
                  avatar={
                    <Avatar
                      alt={user.firstName?.toUpperCase()}
                      src={user.imageUrl}
                      className={classes.small}
                    />
                  }
                  label={
                    <Typography className={classes.typography_body_3}>
                      {user.firstName + " " + user.lastName}
                    </Typography>
                  }
                  className={classes.chip}
                  {...getTagProps({ index })}
                />
              ))
            }
            filterSelectedOptions
            disableCloseOnSelect
            options={labels.filter((user) => !allExisitingUsers.find((existingUser) =>  existingUser.id == user.id))}
            getOptionLabel={(option) => {
              return (
                option.firstName && option.firstName + " " + option.lastName
              );
            }}
            renderOption={(option, { selected }) =>
              handleRenderOptions(option, selected)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search member by name"
                variant="outlined"
              />
            )}
          />
          {(isEditTeamRouteView ? allExisitingUsers?.length > 0 || allSelectedUsers?.length > 0 :
            allSelectedUsers?.length > 0) && (
            <Grid container direction="row" className={classes.bannerContainer}>
              <TeamMemberList
                users={isEditTeamRouteView ? [...allSelectedUsers, ...allExisitingUsers] : allSelectedUsers}
                setAllSelectedUsers={setAllSelectedUsers}
                setAllExisitingUsers={setAllExisitingUsers}
                removeUsers={removeUsers}
                setRemoveUsers={setRemoveUsers}
                allSelectedUsers={allSelectedUsers}
                allExisitingUsers={allExisitingUsers}
                loading={loading}
                roles={allSelectedUserRoles}
                setRoles={setAllSelectedUserRoles}
                isTeamDetailsView={false}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (!isDetailView) {
      if (unsavedTeamChanges) {
        setOpenModal(true);
      } else {
        const currentPage = props.location?.state?.currentPage || 1;
        history.push({ pathname: "/admin/teams", search: `?page=${currentPage}` });
      }
    }
  }, [isDetailView]);

  return (
    <CreateTeamLayout
      props={props}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      loading={loading}
      setLogout={setLogout}
      renderAutocomplete={renderAutocomplete}
      renderTextField={renderTextField}
      handleCreateTeam={
        !isEditTeamRouteView ? handleCreateTeam : handleEditTeam
      }
      handleCancel={handleCancel}
      isSaveDisabled={isSaveDisabled}
      isEditTeamRouteView={isEditTeamRouteView}
    ></CreateTeamLayout>
  );
};
