import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatus } from "../../enums/httpStatus";
import { ReminderRequest } from "../../type";
import { getReminder } from "./GetReminderSlice";
import { patch } from "../../util/APIService";

export interface UpdateReminderSliceProp {
    reminderId: number;
    reminderDetails: ReminderRequest;
}

export const updateReminder = createAsyncThunk(
    "admin/reminder/update",
    async (params: UpdateReminderSliceProp, storeActions) => {
        const url = `api/v1/admin/reminder/${params.reminderId}`;
        const response: any = await patch(url, params.reminderDetails, storeActions);

        if (response.status === HttpStatus.OK) {
            storeActions.dispatch(getReminder());
            return {
                status: HttpStatus.OK,
                data: await response.json(),
            };
        } else {
            return {
                status: response.status,
                errorMessage: (await response.json()).message,
            };
        }
    }
);

const updateReminderSlice = createSlice({
    name: "UpdateReminder",
    initialState: {
        status: null as number | null,
        loading: false as boolean,
        errorMessage: null as string | null,
        data: null as number | null,
    },
    reducers: {
        clearUpdateReminderData: (state) => {
            state.status = null;
            state.loading = false;
            state.errorMessage = null;
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateReminder.fulfilled, (state, action) => {
            state.status = action.payload.status;
            state.loading = false;
            if (action.payload.data !== undefined) {
                state.data = action.payload.data;
                state.errorMessage = null;
            } else if (action.payload.errorMessage !== undefined) {
                state.data = null;
                state.errorMessage = action.payload.errorMessage;
            }
        });
        builder.addCase(updateReminder.pending, (state) => {
            state.status = null;
            state.loading = true;
            state.data = null;
            state.errorMessage = null;
        });
        builder.addCase(updateReminder.rejected, (state) => {
            state.status = HttpStatus.SERVICE_UNAVAILABLE;
            state.loading = false;
            state.data = null;
            state.errorMessage = null;
        });
    },
});

export const { clearUpdateReminderData } = updateReminderSlice.actions;

export default updateReminderSlice;
