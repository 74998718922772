import { makeStyles } from "@material-ui/core";
import { appColor } from "./modules/constants/customThemeColor";

export const useCustomStyle = makeStyles((theme)=>({
  remainingChar: {
    fontSize: theme.spacing(1.5),
    textAlign: "end",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.125),
    },
  },
  typography_font_14_8_500: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },
  typography_font_14_8: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },
  typography_font_14_10: {
    textAlign: "left",
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  typography_font_14_10_600: {
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },typography_font_14_12_500: {
    textTransform: "none",
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
    },
  },typography_font_14_12_600: {
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
    },
  },
  typography_font_18_12_500: {
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  typography_font_18_12_600: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  typography_font_18_14_500: {
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  typography_font_18_14_600: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  typography_font_20_14_600: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  typography_font_20_14_500: {
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },typography_font_24_14_500: {
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  typography_font_24_14_600: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: `${appColor.whiteBackground}`,
    width: "-webkit-fill-available",
    fontSize: 13,
    "&:focus": {
      borderColor: `${appColor.regularBorder} !important`,
    },
    "&:hover": {
      borderColor: `${appColor.regularBorder} !important`,
    },
  },
  popperWidth: {
    width: "-moz-available",
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sendCTA: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(1.375, 4.125, 1.375, 4.125),
    backgroundColor: `${appColor.regularBackgroundColor}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
}));
