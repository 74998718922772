import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../home/Layout";
import { ToolBar } from "../../../modules/home/ToolBar";
import { AttributeStatus } from "../../../modules/admin/customAttributeAndQuestions/helper";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(11, 6, 2, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  loaderBackground: {
    height: "100%",
    padding: 0,
    background: "none",
    overflow: "hidden"
  },
  disabledContainer: {
    pointerEvents: "none",
    opacity: 0.3
  },
}));

export const Loader = () => {
  const classes = useStyle();
  return (
    <Grid container className={classes.loaderBackground}>
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const AttributeDetailLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  renderErrorModal,
  renderAddQuestionButton,
  renderAddQuestionModal,
  renderQuestionsList,
  renderInfoBanner,
  renderAttributeName,
  attributeStatus
}) => {
  document.title = "Grow-Wize | Admin - Attributes";
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Attribute Details"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const isDisabled = attributeStatus === AttributeStatus.INACTIVE;

  const renderAttributeDetail = () => (
    <>
      {renderErrorModal()}
      {renderAddQuestionModal()}
      <Grid container direction="row" className={classes.root} justify="center">
        {loading && <Grid
          item
          xs={12}
          container
          style={{ width: "100%", height: "80vh", position: "absolute" }}
        >
          <Loader></Loader>
        </Grid>}
        {isDisabled && renderInfoBanner()}
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ width: "inherit" }}
          className={`${isDisabled ? classes.disabledContainer : ""}`}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {renderAttributeName()}
            {renderAddQuestionButton()}
          </Grid>
          {renderQuestionsList()}
        </Grid>
      </Grid>
    </>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderAttributeDetail}
    ></HomeLayout>
  );
};
