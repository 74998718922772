import { Divider, Grid, Icon, IconButton, Snackbar, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
    root: {
        top: theme.spacing(11.5)
    },
    grid: {
        background: (props: any) =>
            props?.type === MESSAGE_TYPE.SUCCESS ? "#E6FAF8" : "#E5F6FD",
        padding: theme.spacing(2),
        gap: theme.spacing(1),
        boxShadow: "0px 8px 34px 0px rgba(0, 0, 0, 0.25)",
        minWidth: 400,
        display: "grid",
        gridTemplateColumns: "24px auto max-content max-content"
    },
    message: {
        fontSize: 14,
        fontWeight: 400,
        color: (props: any) =>
            props?.type === MESSAGE_TYPE.SUCCESS ? "#01BDB3" : "#0288D1",
        lineHeight: "21px",
        marginRight: theme.spacing(3)
    },
    divider: {
        height: theme.spacing(3),
        background: (props: any) =>
            props?.type === MESSAGE_TYPE.SUCCESS ? "#01BDB3" : "#0288D1",
        opacity: "30%"
    }
}));

export enum MESSAGE_TYPE {
    SUCCESS = "SUCCESS",
    INFO = "INFO",
}

export interface MessageObject {
    message: string;
    type?: MESSAGE_TYPE;
  }

const MESSAGE_ICONS = {
    SUCCESS: "/CheckCircleIcon.svg",
    INFO: "/infoBlue.svg"
}

const CANCEL_ICONS = {
    SUCCESS: "/CancelGreen.svg",
    INFO: "/CancelBlue.svg",
}

const ToastMessage = (props) => {
    const { open, handleClose, message, type = MESSAGE_TYPE.SUCCESS } = props;
    const classes = useStyle({ type });

    return (
        <Snackbar TransitionProps={{ appear: false }} open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.root}>
            <Grid container direction="row" alignItems="center" className={classes.grid}>
                <Icon style={{ padding: 0 }}>
                    <img src={MESSAGE_ICONS[type]} alt="success" height={24} width={24}></img>
                </Icon>
                <Typography className={classes.message}>{message}</Typography>
                <Divider orientation="vertical" className={classes.divider} />
                <IconButton
                    onClick={handleClose}
                    style={{
                        padding: "0px",
                        height: "24px",
                        width: "24px",
                        marginLeft: "8px"
                    }}
                >
                    <img src={CANCEL_ICONS[type]} alt="close" />
                </IconButton>
            </Grid>

        </Snackbar>
    );
}

export default ToastMessage;
