import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OtpRequestProps } from "../../../src/type";
import { OtpResponse } from "../../type";

export const otpRequest = createAsyncThunk(
  "otp",
  async (requestData: OtpRequestProps) => {
    try {
     const options = {
         method: "post",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(requestData),
       };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/otp`,
        options
      );
      if (response.status === 200 || response.status === 500) {
        return {
          status: 200,
          data: await response.json(),
        };
      } else {
        return {
          status: response.status,
          text: await response.text(),
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

const otpRequestSlice = createSlice({
  name: "otpResponse",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as OtpResponse | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(otpRequest.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;

      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }

    });
    builder.addCase(otpRequest.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(otpRequest.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = otpRequestSlice.actions;

export default otpRequestSlice;
