import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../home/Layout";
import { ToolBar } from "../../../modules/home/ToolBar";
import { useSelector } from "react-redux";
import { State } from "../../../type";
import ToastMessage from "../../../modules/components/ToastMessage";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    width: `${window.innerWidth - 224}px`,
    padding: theme.spacing(6, 5, 2, 4),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
}));

export const AdminDashboardLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  renderErrorModal,
  renderRecentActivities,
  renderNudgeBoard,
  renderLeaderboard,
  renderFeedbackTrend,
  showSuccess,
  handleClose,
  nudgeSuccessMsg
}) => {
  document.title = "Grow-Wize | Admin-Dashboard";
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const loggedInUserMail = useSelector(
    (state: State) => state.auth.email ?? "test@technogise.com"
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Dashboard"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderDashboardLayout = () => (
    <>
      {renderErrorModal()}
      <Grid xs={12} item container className={classes.root}>
        <ToastMessage
          open={showSuccess}
          handleClose={handleClose}
          message={nudgeSuccessMsg?.message}
          type={nudgeSuccessMsg?.type}
        />
        <Grid item xs={7}>
          {renderFeedbackTrend()}
        </Grid>
        <Grid item xs={5}>
          {renderLeaderboard()}
        </Grid>
          <>
            <Grid item xs={7}>
             {renderNudgeBoard()} 
            </Grid>
            <Grid item xs={5}>
              {renderRecentActivities()}
            </Grid>
          </>
      </Grid>
    </>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderDashboardLayout}
    ></HomeLayout>
  );
};
