import {
    Typography,
  } from "@material-ui/core";
  import { Modal } from "../../../../components/Modal/Modal";
  import { ModalHeader } from "../../../../components/Modal/ModalHeader";
  import { ModalBody } from "../../../../components/Modal/ModalBody";
  import { ModalFooter } from "../../../../components/Modal/ModalFooter";
  import { removeMemberModalStyles } from "./helper";
  
  export const RemoveMemberModal = ({
    open,
    handleCancel,
    handleRemove,
    disableActions,
    memberName
  }) => {
    const classes = removeMemberModalStyles();
    
    return (
      <Modal open={open} >
        <ModalHeader title="Remove Member" showBackground={false} handleCancel={handleCancel} />
        <ModalBody>
          <Typography className={classes.confirmationInfo}>
          Are you sure you want to remove {memberName} from the team?
          </Typography>
        </ModalBody>
        <ModalFooter
          buttonLabel="Remove"
          secondaryLabel="Cancel"
          handleCancel={handleCancel}
          handleSubmit={handleRemove}
          disableActions={disableActions}
        />
      </Modal>
    );
  };
  