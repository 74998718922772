import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SharedFeedback } from "../../modules/user/shared_feedback/typeSharedFeedbacks";
import { SharedFeedbacksParams } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getSavedDrafts = createAsyncThunk(
  "feedback/drafted",
  async (requestParams: SharedFeedbacksParams, storeActions) => {
    const url = `api/v1/user/feedbacks/drafted` + requestParams.requestingParams;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const savedDraftsSlice = createSlice({
  name: "savedDrafts",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as Array<SharedFeedback> | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSavedDrafts.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(getSavedDrafts.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(getSavedDrafts.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = savedDraftsSlice.actions;

export default savedDraftsSlice;
