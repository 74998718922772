import {
  Backdrop,
  Button,
  Fade,
  fade,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { DeleteAttributeCardComponentProp } from "./type";

const useStyle = makeStyles((theme) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  modalGrid: {
    width: theme.spacing(70),
    height: theme.spacing(35),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4, 2, 4, 2),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(40),
      height: theme.spacing(25),
      padding: theme.spacing(6, 0, 6, 0),
    },
  },
  modalErrorText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: "#576BEA",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(2),
    },
  },
  btn1: {
    padding: theme.spacing(1.375, 4.25, 1.375, 4.25),
    backgroundColor: "#576BEA",
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
      marginTop: theme.spacing(2.5),
      fontSize: theme.spacing(1.75),
    },
  },
  cancelBtn: {
    padding: theme.spacing(1.375, 4.25, 1.375, 4.25),
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    border: "1px solid #868686",
    color: "#868686",
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
      marginTop: theme.spacing(2.5),
      fontSize: theme.spacing(1.75),
    },
  },
}));

export const DeleteAttributeCardComponent: React.FC<
  DeleteAttributeCardComponentProp
> = ({
  openModal,
  deletingAttribute,
  handleModalClose,
  handleDelete,
  from,
}) => {
  const classes = useStyle();
  return (
    <>
      <Modal
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Paper elevation={0} variant="outlined">
            <Grid container className={classes.modalGrid}>
              <Grid
                item
                xs={12}
                container
                justify="center"
                alignItems="flex-end"
              >
                <Typography className={classes.modalErrorText}>
                  {from === "SENT_REQUEST" &&
                    "Are you sure you want to cancel the request?"}
                  {from !== "SENT_REQUEST" &&
                    `Are you sure you want to remove ${deletingAttribute.attributeName}?`}
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item xs={6} container justify="center">
                  <Button
                    onClick={handleModalClose}
                    className={classes.cancelBtn}
                    style={from === "SENT_REQUEST" ? {marginLeft: "40px"}: {}}
                  >
                    {from === "SENT_REQUEST" && "No"}
                    {from !== "SENT_REQUEST" && "Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={6} container justify="center">
                  <Button
                    onClick={() => from === "SENT_REQUEST" ? handleDelete() : handleDelete(deletingAttribute.attributeId)}
                    className={classes.btn1}
                    style={from === "SENT_REQUEST" ? {marginRight: "40px"}: {}}
                  >
                    {from === "SENT_REQUEST" && "Yes"}
                    {from !== "SENT_REQUEST" && "Delete"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};
