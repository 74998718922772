import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { appColor } from "../../../constants/customThemeColor";
import { HeaderTabs, HeaderTabsForNoRequests } from "./headerTabs";
import { NoDraftText } from "./noDraftText";
import { ShowDraftCard } from "./showDraftCard";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "inherit",
  },
  body: {
    width: "100%",
    color: `${appColor.pureWhite}`,
    backgroundColor: `${appColor.pureWhite}`,
  },
  row: {
    width: "100%",
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
  },
}));

export const DraftContainer = ({
  loading,
  setRequestedUserId,
  feedbacks,
  setSelectedUser,
  setTabValue,
  setShowDraftWithId,
  setShareFeedbackData,
}) => {
  const classes = useStyle();

  let iter = 0;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Paper variant="outlined" square style={{ width: "inherit" }}>
        {feedbacks.length === 0 ? <HeaderTabsForNoRequests /> : <HeaderTabs />}
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : null}
        {!loading && feedbacks.length === 0 ? (
          <Grid className={classes.body}>
            <NoDraftText />
          </Grid>
        ) : null}
        {!loading ? (feedbacks.map((draft) => (
          <Grid container key={iter++} className={classes.row}>
            <ShowDraftCard
              draft={draft}
              setRequestedUserId={setRequestedUserId}
              setSelectedUser={setSelectedUser}
              setTabValue={setTabValue}
              setShowDraftWithId={setShowDraftWithId}
            ></ShowDraftCard>
          </Grid>
        ))): null}
      </Paper>
    </Grid>
  );
};
