import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CountProps, DeactivateMemberProp } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getCountSharedAndReceivedFeedback = createAsyncThunk(
  "admin/count/feedback/received-and-shared",
  async (requestParams: DeactivateMemberProp, storeActions) => {
    const url = `api/v1/admin/feedback/count?memberId=${requestParams.memberId}&requestTag=${requestParams.tag}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const countSharedAndReceivedSlice = createSlice({
  name: "countSharedAndReceivedFeedback",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: { receivedCount: 0, sharedCount: 0 } as CountProps | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCountSharedAndReceivedFeedback.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.loading = false;
        if (action.payload.data !== undefined) {
          state.data = action.payload.data;
          state.text = null;
        } else if (action.payload.text !== undefined) {
          state.data = null;
          state.text = action.payload.text;
        }
      }
    );
    builder.addCase(getCountSharedAndReceivedFeedback.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = { receivedCount: 0, sharedCount: 0 };
      state.text = null;
    });
    builder.addCase(getCountSharedAndReceivedFeedback.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = countSharedAndReceivedSlice.actions;

export default countSharedAndReceivedSlice;
