import { Button, Grid, Typography, makeStyles, Tooltip, Avatar, CircularProgress } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { DateRangeProps, NudgeCountSliceProps, NudgeSendNotificationProps, NudgeSliceProps, State, User } from "../../../type";
import { nudgeSendNotification } from "../../../redux/slices/NudgeSendNotificationSlice"
import { useHistory, withRouter } from "react-router-dom";
import { appColor } from "../../constants/customThemeColor";
import { NudgeBoardLayout } from "../../../layouts/admin/nudge/Layout";
import theme from "../../../theme";
import { getDateInLeaderBoardFormat } from "../../../util/GetDateInDesiredFormat";
import { getNudgeList } from "../../../redux/slices/NudgeListSlice";
import { HttpStatus } from "../../../enums/httpStatus";
import ToastMessage, { MESSAGE_TYPE, MessageObject } from "../../components/ToastMessage";
import { MESSAGES } from "../dashboard/Helper";
import { getDateInActivityFormat } from "../../../util/GetDateInActitivityFormat";
import { countNudge } from "../../../redux/slices/NudgeCountSLice";


const useStyle = makeStyles((theme) => ({
  btn: {
    height: theme.spacing(4.75),
    width: theme.spacing(13.5),
    textTransform: "none",
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    color: `${appColor.darkGray}`,
    padding: theme.spacing(1.25, 0.75),
    border: "1px solid rgba(175,175,175,0.5)",
    marginLeft: '40px',
    "&:hover": {
      backgroundColor: `${appColor.adminThemeColor}`,
      color: `${appColor.pureWhite}`,
      border: "none",
    },
  },
  datarow: {
    backgroundColor: "#098f67"
  },
  noFocusOutline: {
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-row:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none !important',
    },
  },
  columnHeader: {
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none !important',
    },
  },
}));

const NudgeBoardPage = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [nudgeCtaSubmitted, setNudgeCtaSubmitted] = React.useState<boolean>(false);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const [showSuccess, setShowSuccess] = React.useState<Boolean>(false);
  const [page, setPage] = React.useState(0);
  const [nudgeSuccessMsg, setNudgeSuccessMsg] = React.useState<MessageObject>({ message: '', type: MESSAGE_TYPE.INFO });
  const nudgeDateRangeState : DateRangeProps = useSelector((state: State) => state.adminNudgeData.nudgeDateRange);
  const nudgeLoading: Boolean = useSelector(
    (state: State) => state.adminNudgeData.loading
  );
  const {
    status: nudgeSendNotificationStatus,
    loading: sendingNudgeNotification,
  } = useSelector((state: State) => state.adminNudgeSendNotification);

  const statusCode: number | null = useSelector((state: State) =>
    state.adminNudgeData.status !== 200 ? state.adminNudgeData.status : null
  );

  const totalNudgeCount = useSelector(
    (state: State) => state.adminNudgeCountData.count
  );

  const fetchCountNudge = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let startDate = getDateInLeaderBoardFormat(nudgeDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(nudgeDateRangeState.endDate);
    const str = `&startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`;
    const param: NudgeCountSliceProps = {
      requestingParams: str,
    };
    dispatch(countNudge(param));
  };

  const fetchData = () => {
    if (totalNudgeCount !== null) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let startDate = getDateInLeaderBoardFormat(nudgeDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(nudgeDateRangeState.endDate);
    const str = `&startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`;
      const param: NudgeSliceProps = {
          requestingParams: str,
          offSet: 0,
          count: totalNudgeCount,
        };
        dispatch(getNudgeList(param));
      }
  };

  useEffect(() => {
    fetchCountNudge();
  }, [nudgeDateRangeState]);

  useEffect(() => {
    if (totalNudgeCount !== null) {
      fetchData();
      setPage(0);
    }
  }, [totalNudgeCount]);


  useEffect(() => {
    if (nudgeCtaSubmitted && !sendingNudgeNotification) {
      setShowSuccess(true);
      if (nudgeSendNotificationStatus === HttpStatus.OK) {
        setNudgeSuccessMsg({
          message: nudgeSendNotificationStatus === 200 ? "Nudge Notification Sent Successfully!" : "Failed To Send Nudge Notification.",
          type: nudgeSendNotificationStatus === 200 ? MESSAGE_TYPE.SUCCESS : MESSAGE_TYPE.INFO,
        });
      } else {
        setNudgeSuccessMsg({
          message: MESSAGES.NUDGE_FAIL,
          type: MESSAGE_TYPE.INFO,
        });
      }
      
      setNudgeCtaSubmitted(false);
      fetchData();
    }
  }, [sendingNudgeNotification]);


  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchData();
  };

  const handleNudgeAction = (user: User) => {
    setNudgeCtaSubmitted(true);
    const params: NudgeSendNotificationProps = {
      userId: user.id,
    };
    dispatch(nudgeSendNotification(params));
  };

  const ActionButton = ({ user }) => (
    <Button className={classes.btn} onClick={() => handleNudgeAction(user)}>
      <Typography variant="h5" style={{ textAlign: 'center' }}>Nudge</Typography>
    </Button>
  );

  const handleCancel = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
    setNudgeSuccessMsg({ message: '' });
  };

  const ActionColumnHeader = () => (
    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '130px'  }}>
      <Typography variant="h5" style={{ marginRight: '8px' }}>
        Action  
      </Typography>
      <Tooltip title="Nudge them via email" placement="bottom" arrow>
        <Avatar
          src={"/INFO_MARK_Action_Nudge.svg"}
          alt={"info"}
          style={{
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginRight: theme.spacing(1),
        }}
      />
      </Tooltip>
    </div>
  );
  if (!isDetailView) {
    const currentPage = props.location?.state?.currentPage || 1;
    history.push({ pathname: "/admin/dashboard" });
  }

  const nudges: GridRowsProp = useSelector((state: State) => state.adminNudgeData.nudges);

  const columns: GridColDef[] = [
  { 
    field: "user.firstName",
    headerName: "Name", 
    width: 391 , 
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'left',
    renderHeader: (params) => (
      <div style={{ paddingLeft: '35px' }}>
        {params.colDef.headerName}
      </div>
    ),
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
        <Avatar
          src={params.row.user?.imageUrl}
          alt={params.row.user?.firstName}
          style={{
            width: 40,
            height: 40,
            marginRight: 20,
          }}
        />
        <div>
          <Typography 
            variant="h5"
            style={{
              maxWidth: 210,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              color: "#272727",
            }}
          >
            {params.row.user &&
              params.row.user.firstName &&
              params.row.user.firstName.charAt(0).toUpperCase() +
                params.row.user.firstName.slice(1) +
                " " +
                (params.row.user.lastName.charAt(0).toUpperCase() +
                  params.row.user.lastName.slice(1))}
          </Typography>
          {params.row.nudgeSenderData && params.row.nudgeSenderData.sender ? (
          <Typography variant="subtitle2">
            Nudged by: {params.row.nudgeSenderData.sender.firstName.charAt(0).toUpperCase() +
              params.row.nudgeSenderData.sender.firstName.slice(1)}{" "}
              |{" "}
            {params.row.nudgeSenderData.nudgeDate &&
              getDateInActivityFormat(new Date(params.row.nudgeSenderData.nudgeDate))}
          </Typography>
          ) : (
            <Typography variant="subtitle2">
              Not yet nudged
            </Typography>
          )}
        </div>
      </div>
    ),
  },
  { 
    field: "sharedFeedbackCount", 
    headerName: "Shared", 
    width: 325,
    disableColumnMenu: true,
    headerAlign: 'left',
    renderHeader: (params) => (
      <div style={{ paddingLeft: '125px' }}>
        {params.colDef.headerName}
      </div>
    ),
    renderCell: (params) => (
      <div style={{ paddingLeft: '145px' }}>
        {params.value}
      </div>
    ),
  },
  { 
    field: "receivedFeedbackCount", 
    headerName: "Received", 
    width: 325,
    disableColumnMenu: true,
    headerAlign: 'left',
    renderHeader: (params) => (
      <div style={{ paddingLeft: '120px' }}>
        {params.colDef.headerName}
      </div>
    ),
    renderCell: (params) => (
      <div style={{ paddingLeft: '145px' }}>
        {params.value}
      </div>
    ),
  },
  { 
    field: "action", 
    headerName: "Action",
    width: 325, 
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'left',
    renderHeader: (params) => <ActionColumnHeader />,
    renderCell: (params) => (
      <div style={{ paddingLeft: '95px' }}>
        <ActionButton user={params.row.user} />
      </div>
    ),
  },
];

  const paginationPageSize = 10;
  const rowHeight = 67;
  const minHeight = rowHeight + 110;
  const currentPageRows = nudges.slice(page * paginationPageSize, (page + 1) * paginationPageSize);
  const currentPageRowsCount = currentPageRows.length;
  const gridHeight = currentPageRowsCount * rowHeight + 110 > minHeight 
    ? currentPageRowsCount * rowHeight + 110
    : minHeight;


  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const dataRow = (params) => {
    return 'datarow';
  };

  const renderNudgeList = () => {
    if (nudgeLoading) {
      return (
        <Grid container justify="center" alignItems="center" style={{ height: 'calc(100vh - 120px)', width: '100%' }}>
          <CircularProgress />
        </Grid>
      );
    }

    if (nudges.length === 0) {
      return (
        <Grid container justify="center" alignItems="center" style={{ height: 'calc(100vh - 120px)', width: '100%' }}>
          <Typography variant="h4">No Nudges...</Typography>
        </Grid>
      );
    }

    return (
    <div className={classes.noFocusOutline} style={{ backgroundColor: 'white', height: gridHeight, width: '100%' }}>
      <div style={{ height: 'calc(100% )' }}>
        <DataGrid 
          getRowId={(row) => row.user.id}
          rows={nudges}
          columns={columns} 
          pagination
          page={page}
          pageSize={paginationPageSize}
          onPageChange={handlePageChange}
          rowHeight={rowHeight}
          getRowClassName={dataRow}
          disableSelectionOnClick
        />
      </div>
    </div>
    );
  };

  return (
    <>
    <NudgeBoardLayout
        props={props}
        loading={nudgeLoading}
        setLogout={setLogout}
        isDetailView={isDetailView}
        setIsDetailView={setIsDetailView}
        renderErrorModal={renderErrorModal}
        renderNudgeList={renderNudgeList}
      />
      <ToastMessage
        open={showSuccess}
        handleClose={handleCancel}
        message={nudgeSuccessMsg?.message || ''}
        type={nudgeSuccessMsg?.type || MESSAGE_TYPE.INFO}
      />
    </>
  );
};
const Component = withRouter(NudgeBoardPage);
export { Component as NudgeBoardPage };

