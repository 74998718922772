import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { SuccessPageProps } from "./type";

const useStyle = makeStyles((theme) => ({
  headerSection: {
    position: "relative",
    height: theme.spacing(70),
    width: theme.spacing(75),
    top: theme.spacing(3.4),
    border: "1px solid #DCDCDC",
    boxShadow: " 0 2px 4px 4px rgba(191,191,191,0.3)",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(0.5),
  },
  consent: {
    width: theme.spacing(36.975),
    height: theme.spacing(36.75),
    marginTop: theme.spacing(8.25),
  },
  thankyou: {
    fontSize: theme.spacing(3),
    color: "#576BEA",
    marginTop: theme.spacing(5),
  },
  successMessageText: {
    fontSize: theme.spacing(2.25),
    color: "#272727",
    margin: theme.spacing(4.5, 0, 11.25, 0),
  },
  commonFontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    textAlign: "center",
  },
}));

export const SuccessPage: React.FC<SuccessPageProps> = ({ status }) => {
  const classes = useStyle();

  return (
    <Grid
      xs={12}
      container
      className={classes.headerSection}
      id={status ? "already-shared" : "shared-successfully"}
      justify="center"
    >
      <Grid xs={12} item container justify="center">
        <img
          src="/Consent.svg"
          alt="illustration"
          className={classes.consent}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonFontStyle} ${classes.thankyou}`}
        >
          THANK YOU!
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonFontStyle} ${classes.successMessageText}`}
        >
          {status
            ? "Feedback is already shared!"
            : "Feedback has been successfully shared!"}
        </Typography>
      </Grid>
    </Grid>
  );
};
