import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShareFeedbackByExternalClientLayout } from "../../../layouts/client/share_feedback/Layout";
import {
  AttributeQuestionEmoji,
  Requests,
  ResponseAttribute,
  ResponseScaleProp,
  SharedDraftDetailByClientSliceProps,
  SharedDraftProps,
  ShareFeedbackAttribute,
  ShareFeedbackByClientSliceProps,
  ShareFeedbackProps,
  State,
} from "../../../type";
import ScrollToTop from "./ScrollOnTop";
import { FeedbackSection } from "./FeedbackSection";
import { GrowWizeLogo } from "./GrowWizeLogo";
import { GuidelineText } from "./GuidelinesText";
import { SubmitFeedbackButton } from "./SubmitFeedbackCTA";
import { WelcomeText } from "./WelcomeText";
import ShareFeedbackHeader from "./Header";
import { SuccessPage } from "./SuccessPage";
import { attributesDataForClients } from "../../../redux/slices/AttributesWithDetailData";
import { shareFeedbackByClient } from "../../../redux/slices/ShareFeedbackByClient";
import { useHistory } from "react-router-dom";
import { ErrorPopUp } from "../../components/ErrorPages/errorModal";
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  updateEmojiAndAttributeOnClick,
  updateEmojiAndAttributeWithDraftData,
} from "../../user/share_feedback/addNewButtonHandles";
import { sharedDraftDetailByClient } from "../../../redux/slices/SharedDraftDetailForClient";
import { clearData as clearSubmitFeedbackByClient } from "../../../redux/slices/SubmitFeedbackByExternalClient";
import { SharedFeedbackDetailData } from "../../user/shared_feedback/typeSharedFeedbacks";
import { RightsText } from "./RightsText";
import { CookieFooter } from "./CookieFooter";
import { submitFeedbackByClient } from "../../../redux/slices/SubmitFeedbackByExternalClient";
import { ValidationPromptModal } from "../../components/ValidationModal";

const useStyle = makeStyles((theme) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  modalGrid: {
    width: theme.spacing(70),
    height: theme.spacing(35),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(8, 0, 4, 0),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(40),
      height: theme.spacing(25),
      padding: theme.spacing(6, 0, 6, 0),
    },
  },
  modalErrorText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: "#576BEA",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(2),
    },
  },
  btn1: {
    padding: theme.spacing(1.375, 4.25, 1.375, 4.25),
    backgroundColor: "#576BEA",
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
      fontSize: theme.spacing(1.75),
    },
  },
}));

export const ShareFeedbackByExternalClient = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  let { requestId } = props.match.params;
  const successStatus = useSelector(
    (state: State) => state.shareFeedbackByClient.status
  );
  const [attributes, setAttributes] = React.useState<ResponseAttribute[]>([]);
  const [shareFeedbackData, setShareFeedbackData] = React.useState<
    ShareFeedbackAttribute[]
  >([]);
  const [emojiSelected, setEmojiSelected] = React.useState<
    Array<AttributeQuestionEmoji>
  >([]);
  const [value, setValue] = React.useState(-2);
  const [isSubmit, setIsSubmit] = React.useState<Boolean>(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [dispatched, setDispatched] = React.useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isAnyAttribute, setIsAnyAttribute] = React.useState<boolean>(false);
  const [isMissing, setMissing] = React.useState<boolean>(false);
  const [missingElement, setMissingElement] = React.useState<string | null>(null);
  const [charLimitExceeded, setCharLimitExceeded] =
    React.useState<Boolean>(false);
  const [logout, setLogout] = React.useState(false);
  const [context, setContext] = React.useState<string>("");
  const [isContext, setIsContext] = React.useState<Boolean>(false);
  const [isAnyChange, onAnyChange] = React.useState<boolean>(false);
  const [isDataChange, onDataChange] = React.useState<boolean>(false);
  const [savedDraftDate, setDraftDate] = React.useState<Date>(new Date());
  const [selectedDraftId, setSelectedDraftId] = React.useState<number>(-1);
  const [index, setIndex] = React.useState<number>(-1);
  const [userChanged, setUserChanged] = React.useState<number>(0);
  const [requestLoad, setRequestLoad] = React.useState<number>(0);
  const [isDraftLoad, setIsDraftLoad] = React.useState<Boolean>(false);
  const [isFooter, setIsFooter] = React.useState<Boolean>(true);
  const [showPolicy, setShowPolicy] = React.useState<Boolean>(false);
  useEffect(() => {
    if (value === -2) dispatch(attributesDataForClients(requestId));
  }, []);
  const message =
    useSelector((state: State) => state.attributesDataForClient.text) ?? "";

  const [dataLoadingFailed, setDataLoadingFailed] =
    React.useState<boolean>(false);
  const statusForData = useSelector(
    (state: State) => state.attributesDataForClient.status
  );
  const status = useSelector(
    (state: State) => state.shareFeedbackByClient.status
  );
  const loading = useSelector(
    (state: State) => state.shareFeedbackByClient.loading
  );
  const finalStatus = useSelector(
    (state: State) => state.submitFeedbackByClient.status
  );
  const finalLoading = useSelector(
    (state: State) => state.submitFeedbackByClient.loading
  );

  const requests: Array<Requests> = [];
  const attr: Array<ResponseAttribute> = [];
  const emptyDrafts: Array<SharedDraftProps> = [];
  const initialAttributes =
    useSelector(
      (state: State) => state.attributesDataForClient.data?.request
    ) ?? requests;
  const attLabels =
    useSelector(
      (state: State) => state.attributesDataForClient.data?.attributes
    ) ?? attr;
  const drafts =
    useSelector((state: State) => state.attributesDataForClient.data?.drafts) ??
    emptyDrafts;

  const draftDetailData: SharedFeedbackDetailData =
    useSelector((state: State) => state.sharedDraftDetailByClient.data) ?? null;

  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector(
      (state: State) =>
        state.attributesDataForClient.data?.scaleResponseEntityList
    ) ?? emptyList;

  const data =
    useSelector((state: State) => state.attributesDataForClient?.data) ?? null;

  const renderLogo = () => <GrowWizeLogo />;

  const renderWelcomeText = () => <WelcomeText />;

  const renderGuideLineText = () => <GuidelineText />;

  const renderFeedbackSection = () => (
    <FeedbackSection
      attributes={attributes}
      setAttributes={setAttributes}
      shareFeedbackData={shareFeedbackData}
      setShareFeedbackData={setShareFeedbackData}
      emojiSelected={emojiSelected}
      setEmojiSelected={setEmojiSelected}
      setValue={setValue}
      context={context}
      user={data?.employee}
      setContext={setContext}
      setIsContext={setIsContext}
      setSelectedDraftId={setSelectedDraftId}
      initialAttributes={data?.request}
      isAnyAttribute={isAnyAttribute}
      onAnyChange={onAnyChange}
      isMissing={isMissing}
    />
  );

  const renderSubmitFeedbackCTA = () => <SubmitFeedbackButton />;

  const renderScrollOnTop = () => <ScrollToTop />;

  const renderShareFeedbackHeader = () => (
    <ShareFeedbackHeader
      value={value}
      setValue={setValue}
      validLength={shareFeedbackData.length > 0}
      isDataChange={isDataChange}
      savedDraftDate={savedDraftDate}
      onDataChange={onDataChange}
    />
  );

  const renderAllRightsReserved = () => <RightsText />;

  const renderFooter = () =>
    isFooter ? (
      <CookieFooter setIsFooter={setIsFooter} setShowPolicy={setShowPolicy} />
    ) : (
      <></>
    );

  const renderSuccessPageOnSuccessfulFeedbackSubmission = () => (
    <>
      {message?.length > 10 ? <SuccessPage status={true} /> : <SuccessPage />}
    </>
  );
  const statusCode: number | null = useSelector((state: State) =>
    state.shareFeedbackByClient.status !== 201
      ? state.shareFeedbackByClient.status
      : state.attributesDataForClient.status !== 200
      ? state.attributesDataForClient.status
      : state.submitFeedbackByClient.status !== 201
      ? state.submitFeedbackByClient.status
      : null
  );
  const requestStatus: number | null = useSelector(
    (state: State) => state.attributesDataForClient.status
  );
  useEffect(() => {
    if (selectedDraftId > 0) {
      let params: SharedDraftDetailByClientSliceProps = {
        feedbackId: selectedDraftId,
        senderId: data?.clientId ?? -1,
      };
      dispatch(sharedDraftDetailByClient(params));
      setShareFeedbackData([]);
    }
  }, [selectedDraftId]);

  useEffect(() => {
    if (isAnyChange) {
      onDataChange(true);
    }
  }, [isAnyChange]);

  useEffect(() => {
    onDataChange(true);
  }, [shareFeedbackData]);

  useEffect(() => {
    if (initialAttributes.length > 0 || drafts.length > 0) {
      setAttributes([]);
      setIndex(-1);
      let id = -1;
      const user = data?.employee;
      for (let j = 0; j < initialAttributes.length; j++) {
        if (
          initialAttributes[j].userId === user?.id &&
          drafts.findIndex(
            (d) => d.requestId === initialAttributes[j].requestId
          ) === -1
        ) {
          setIndex(j);
          id = j;
          if (initialAttributes[j].context.length > 8) {
            setContext(initialAttributes[j].context);
            setIsContext(true);
          }
          break;
        } else if (
          initialAttributes[j].userId === user?.id &&
          drafts.findIndex(
            (d) => d.requestId === initialAttributes[j].requestId
          ) >= 0
        ) {
          const draft: SharedDraftProps | undefined = drafts.find(
            (d) => d.requestId === initialAttributes[j].requestId
          );
          if (draft) {
            setSelectedDraftId(draft.id);
          }
          break;
        }
      }
      setIndex(id);

      setUserChanged(1);
    }
  }, [initialAttributes]);

  useEffect(() => {
    if (requestLoad < 2 && requestStatus === 200) {
      if (index >= 0 && attributes.length === 0) {
        let att: Array<ResponseAttribute> = [];
        for (let i = 0; i < attLabels.length; i++) {
          for (
            let j = 0;
            j < initialAttributes[index].attributeIds.length;
            j++
          ) {
            if (attLabels[i].id === initialAttributes[index].attributeIds[j]) {
              att.push(attLabels[i]);
              setAttributes(att);
            }
          }
        }
      }
      updateEmojiAndAttributeOnClick(
        attributes,
        emojiSelected,
        shareFeedbackData,
        setEmojiSelected,
        setShareFeedbackData
      );
      setUserChanged(0);
      if (requestLoad === 0) {
        setRequestLoad(1);
      } else if (requestLoad === 1) {
        setRequestLoad(0);
      }
      if ((shareFeedbackData.length > 0 && requestLoad < 2) || index < 0) {
        setRequestLoad(3);
      }
    }
  }, [userChanged, requestLoad]);

  useEffect(() => {
    if (selectedDraftId >= 0) {
      if (draftDetailData) {
        if (draftDetailData.context.length > 8) {
          setContext(draftDetailData.context);
          setIsContext(true);
        }
        if (attributes.length === 0 && !isDraftLoad) {
          let att: Array<ResponseAttribute> = [];
          for (let j = 0; j < draftDetailData.attributes.length; j++) {
            for (let i = 0; i < attLabels.length; i++) {
              if (attLabels[i].name === draftDetailData.attributes[j].name) {
                att.push(attLabels[i]);
                setAttributes(att);
              }
            }
          }
        }
        updateEmojiAndAttributeWithDraftData(
          attributes,
          emojiSelected,
          shareFeedbackData,
          setEmojiSelected,
          setShareFeedbackData,
          draftDetailData?.attributes,
          setSelectedDraftId,
          responseScales
        );
        setIsDraftLoad(true);
      }
    }
  }, [draftDetailData, selectedDraftId, attributes]);

  const history = useHistory();
  const goToPreviousPath = () => {
    if (history.length > 10) {
      history.goBack();
    } else {
      history.push("/");
    }
  };
  const logoutUser = () => {
    setLogout(true);
  };

  useEffect(() => {
    if (
      statusForData !== 200 &&
      statusForData !== 400 &&
      statusForData !== null
    ) {
      setDataLoadingFailed(true);
      setShowErrorModal(true);
    } else if (
      successStatus !== 201 &&
      statusForData === 400 &&
      message.length < 40
    ) {
      setIsSubmit(true);
    }
  }, [dataLoadingFailed, statusForData]);

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={showErrorModal}
      setShowModal={setShowErrorModal}
      statusCode={statusCode || statusForData}
      handleNoAuthorization={logoutUser}
      handleAccessDenied={goToPreviousPath}
    ></ErrorPopUp>
  );

  useEffect(() => {
    if (dispatched === true && finalStatus === 201 && finalLoading === false) {
      setShareFeedbackData([]);
      setDispatched(false);
      setIsSubmit(true);
      dispatch(clearSubmitFeedbackByClient());
    }
  }, [dispatched, finalStatus, finalLoading]);

  useEffect(() => {
    if (
      dispatched === true &&
      status === 201 &&
      loading === false &&
      value === 6
    ) {
      setDraftDate(new Date());
      const timer = setTimeout(() => {
        if (loading === false && value === 6) {
          setValue(-1);
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatched, loading, status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading === false && value === 6) {
        setValue(-1);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [loading, value]);

  const AUTO_SAVE_INTERVAL = 30000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (!loading && isDataChange) {
        setValue(6);
        onDataChange(false);
        onAnyChange(false);
      }
    }, AUTO_SAVE_INTERVAL);
    return () => clearInterval(timer);
  }, [isDataChange, loading]);

  useEffect(() => {
    if (shareFeedbackData.length > 0) {
      setValue(-1);
    } else {
      setValue(-2);
    }
  }, [shareFeedbackData]);

  useEffect(() => {
    if (
      statusCode &&
      statusCode !== 200 &&
      statusCode !== 400 &&
      statusCode !== 201 &&
      value !== 6
    ) {
      setShowErrorModal(true);
    }
  }, [statusCode]);

  useEffect(() => {
    if (status && status !== 201) {
      setValue(5);
    }
  }, [status, loading]);

  useEffect(() => {
    if (value === 6) {
      handleSubmit(window.event);
    }
  }, [value]);

  const handleErrors = () => {
    if (shareFeedbackData.length === 0) {
      setIsAnyAttribute(true);
    } else {
      setIsAnyAttribute(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(()=>{
    if(!open && isMissing && missingElement) {
      let val = document.getElementById(missingElement)?.offsetTop;
      let HEADER_HEIGHT: number | undefined = 300;
      window.scrollTo({
        top: val ? (val - HEADER_HEIGHT) : val,
        behavior: 'smooth',
      })
    }
  },[open, isMissing, missingElement])

  const renderValidationModal = () => (
    <ValidationPromptModal
      open={open}
      setOpen={setOpen}
      charLimitExceeded={charLimitExceeded}
    />
  );

  function handleSubmit(e) {
    e?.preventDefault();
    setCharLimitExceeded(false);
    onAnyChange(false);
    let flag = false;
    let attributesLength = false;
    if (shareFeedbackData.length === 0) {
      attributesLength = true;
    } else {
      for (let idx = 0; idx < shareFeedbackData.length; idx++) {
        for (
          let idx2 = 0;
          idx2 < shareFeedbackData[idx].questions.length;
          idx2++
        ) {
          if (
            shareFeedbackData[idx].questions[idx2]?.answer?.length > 80
          ) {
            flag = true;
            setCharLimitExceeded(true);
          }
        }
        if (
          shareFeedbackData[idx].context?.length > 10000 ||
          shareFeedbackData[idx].resources?.length > 10000 ||
          shareFeedbackData[idx].suggestions?.length > 10000
        ) {
          flag = true;
          setCharLimitExceeded(true);
        }
      }
    }
    if (flag || attributesLength) {
      if (attributesLength && value !== 6) {
        handleErrors();
      } else if (flag) {
        handleOpen();
      }
    } else {
      const arg: ShareFeedbackProps = {
        receiverId: data?.employee?.id ?? -1,
        attributes: shareFeedbackData,
        requestId: data?.requestId ?? -1,
        context: context.replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim(),
        status: "DRAFTED",
      };
      const params: ShareFeedbackByClientSliceProps = {
        requestData: arg,
        senderId: data?.clientId ?? -1,
      };
      dispatch(shareFeedbackByClient(params));
      setDispatched(true);
    }
  }

  function handleFinalSubmit(e) {
    e?.preventDefault();
    setCharLimitExceeded(false);
    let regex = /(<([^>]+)>)/ig
    let flag = false;
    let attributesLength = false;
    if (shareFeedbackData.length === 0) {
      attributesLength = true;
    } else {
      for (let idx = 0; idx < shareFeedbackData.length; idx++) {
        for (
          let idx2 = 0;
          idx2 < shareFeedbackData[idx].questions.length;
          idx2++
        ) {
          if (
            value !== 6 &&
            shareFeedbackData[idx].questions[idx2].answer.length < 1
          ) {
            flag = true;
            setMissing(true);
            setMissingElement(`que-id-${shareFeedbackData[idx].questions[idx2].questionId}`);
            break;
          } else {
            let ans = shareFeedbackData[idx].questions[idx2].answer;
            if (
              value !== 6 &&
              (ans?.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'').length < 1 || ans.length < 0)
            ) {
              flag = true;
              setMissing(true);
              setMissingElement(`que-id-${shareFeedbackData[idx].questions[idx2].questionId}`);
              break;
            } else if (
              shareFeedbackData[idx].questions[idx2].answer.length > 80
            ) {
              flag = true;
              setCharLimitExceeded(true);
            }
          }
        }
        if(flag) break;
        let str = shareFeedbackData[idx].context.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'');
        if (str?.replace(/\s/g, "").length < 1 && value !== 6) {
          flag = true;
          setMissing(true);
          setMissingElement(`explanation-id-${shareFeedbackData[idx].attributeId}`);
        } else if (shareFeedbackData[idx].context?.length < 9 && value !== 6) {
          setMissing(true);
          setMissingElement(`explanation-id-${shareFeedbackData[idx].attributeId}`);
          flag = true;
        } else if (
          shareFeedbackData[idx].context?.length > 10000 ||
          shareFeedbackData[idx].resources?.length > 10000 ||
          shareFeedbackData[idx].suggestions?.length > 10000
        ) {
          flag = true;
          setCharLimitExceeded(true);
        }
        if(flag) break;
      }
    }
    if (flag || attributesLength) {
      if (attributesLength && value !== 6) {
        handleErrors();
      } else if (flag) {
        handleOpen();
      }
    } else {
      const arg: ShareFeedbackProps = {
        receiverId: data?.employee?.id ?? -1,
        attributes: shareFeedbackData,
        requestId: data?.requestId ?? -1,
        context: context.replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim(),
        status: "SUBMITTED",
      };
      const params: ShareFeedbackByClientSliceProps = {
        requestData: arg,
        senderId: data?.clientId ?? -1,
      };
      dispatch(submitFeedbackByClient(params));
      setMissing(false);
      setDispatched(true);
    }
  }

  if (statusForData === 400) {
    if (message.length > 40) {
      history.push({
        pathname: "/error",
        state: {
          status: message,
        },
      });
    }
  }

  if (status === 400 || finalStatus === 400) {
      history.push({
        pathname: "/error",
        state: {
          status: "We are sorry but the feedback request is cancelled and no longer available.",
        },
      });
  }

  return (
    <ShareFeedbackByExternalClientLayout
      isSubmit={isSubmit}
      renderLogo={renderLogo}
      renderWelcomeText={renderWelcomeText}
      renderGuideLineText={renderGuideLineText}
      renderFeedbackSection={renderFeedbackSection}
      renderSubmitFeedbackCTA={renderSubmitFeedbackCTA}
      handleSubmit={handleFinalSubmit}
      renderScrollOnTop={renderScrollOnTop}
      renderShareFeedbackHeader={renderShareFeedbackHeader}
      renderSuccessPageOnSuccessfulFeedbackSubmission={
        renderSuccessPageOnSuccessfulFeedbackSubmission
      }
      renderErrorModal={renderErrorModal}
      renderValidationModal={renderValidationModal}
      renderAllRightsReserved={renderAllRightsReserved}
      renderFooter={renderFooter}
    ></ShareFeedbackByExternalClientLayout>
  );
};
