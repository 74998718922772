import { CircularProgress, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeLayout } from "../../../home/Layout";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { useCustomStyle } from "../../../../styles";
import { DeactivateMemberProp, ReceivedFeedbackDataProps, RequestingParams, State } from "../../../../type";
import { FeedbackReportCountCard } from "../../../../modules/admin/members/details/FeedbackReportCountCard";
import { getAllCountSharedAndReceivedFeedback } from "../../../../redux/slices/ReceivedAndSharedAllCountSlice";
import { countReceivedFeedback } from "../../../../redux/slices/CountReceivedFeedbacksSlice";
import { ExportCTA } from "../../../../modules/admin/members/details/exportCTA/ExportCTA";
import { DateProps } from "../../../../modules/admin/dashboard/type";
import { getReceivedFeedbackReport } from "../../../../redux/slices/ConsolidateReportOfReceivedFeedbackSlice";
import { CalendarCTA } from "../../../../modules/components/CalenderCTA";
import { getDateInLeaderBoardFormat } from "../../../../util/GetDateInDesiredFormat";
import { getSelfReport } from "../../../../redux/slices/SelfReportSlice";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    width: `${window.innerWidth - 224}px`,
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(10),
    background: "#FFFFFF",
    minHeight: "calc(100vh - 80px)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
      width: `${window.innerWidth}px`,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  widthStyle: {
    width: "-webkit-fill-available",
  },
  commonCardStyle: {
    width: "286px",
    height: "52px",
    borderRadius: "4px",
    marginBottom: theme.spacing(3),
  },
  divider: {
    margin: "32px 32px 0px 0px",
    width: "calc(100% + 32px)",
    height: "1px",
    background: "#E7E7E7",
    marginLeft: theme.spacing(-2)
  },
  receivedCountBackground: {
    marginRight: theme.spacing(1.125),
    background: `#F6F6F6`
  },
  stickyContainer: {
    position: "sticky",
    top: theme.spacing(10),
    zIndex: 1,
    width: "calc(100% + 32px)",
    background: "#FFFFFF",
    marginLeft: theme.spacing(-2),
    padding: theme.spacing(4, 2, 0, 2),
    display: "flex",
    justifyContent: "space-between",
  },
  stickyFadingEffect: {
    position: 'sticky',
    top: 236,
  },
  fadingElement: {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100px',
    background: `linear-gradient(white, rgba(255, 255, 255, 0))`,
    pointerEvents: 'none',
  },
  loaderBackground: {
    height: "100%",
    padding: 0,
    background: "none",
    overflow: "hidden"
  },
}));

export const Loader = () => {
  const classes = useStyle();
  return (
    <Grid container className={classes.loaderBackground}>
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const ReceivedFeedbackReportLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  memberName,
  renderErrorModal,
  renderMemberCard,
  renderAttributeTabs,
  renderAttributeContent,
  renderScrollOnTop,
  renderNoRecordText,
  renderNoFeedbacks,
}) => {
  document.title = "Grow-Wize | Feedback Report";

  const classes = useStyle();
  const customClasses = useCustomStyle();
  const token = useSelector((state: State) => state.auth.token);
  const emptyList: Array<ReceivedFeedbackDataProps> = [];
  const reportData: Array<ReceivedFeedbackDataProps> =
    useSelector(
      (state: State) =>
        memberName === "Self" ? state.selfReport.data : state.receivedFeedbackReport.data
    ) ?? emptyList;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const itemNo = props.location.state?.itemValue;
  const [itemValue, setItemValue] = React.useState<number>(itemNo ? itemNo : 1);
  const [showFadingEffect, setShowFadingEffect] = useState<boolean>(false);
  const [selectedDateCount, setSelectedDateCount] = useState<number>(0);

  const [receivedFeedbackDateRangeState, setReceivedFeedbackDateRangeState] =
    React.useState<DateProps>({
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date(),
    });

  const loggedInUserName = useSelector((state: State) => state.auth.name);
  const userFullName = (memberName === 'Self') ? loggedInUserName : memberName;


  const getFeedbackReceivedForDateRange = () => {
    let str = "";
    let startDate = getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.endDate);
    str += `startDate=${startDate}&endDate=${endDate}`;
    if (memberName === "Self") {
      const params: DeactivateMemberProp = {
        requestingParam: str,
      };
      dispatch(getSelfReport(params));
    } else {
      const params: DeactivateMemberProp = {
        memberId: id,
        tag: "INTERNAL",
        requestingParam: str,
      };
      dispatch(getReceivedFeedbackReport(params));
    }
  }

  const getCountsForDateRange = () => {
    let str = "";
    let startDate = getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.endDate);
    str += `&startDate=${startDate}&endDate=${endDate}`;
    if (memberName === "Self") {
      const requestingData: RequestingParams = {
        requestParams: "?requestTag=ANY" + str,
      };
      dispatch(countReceivedFeedback(requestingData));
    } else {
      const arg: DeactivateMemberProp = {
        memberId: id,
        tag: itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
        requestingParam: str,
      };
      dispatch(getAllCountSharedAndReceivedFeedback(arg));
    }
  }

  useEffect(() => {
    getCountsForDateRange();
  }, [])

  useEffect(() => {
    if (selectedDateCount === 2) {
      getFeedbackReceivedForDateRange();
      getCountsForDateRange();
      setSelectedDateCount(0);
    }
  }, [token, selectedDateCount]);

  const counts: number = useSelector(
    (state: State) => memberName === "Self"
      ? (state.countReceived?.data || 0)
      : (state.countAllSharedAndReceived?.data?.receivedCount || 0)
  );
  const loadingCounts = useSelector((state: State) => state.countAllSharedAndReceived?.loading);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName={
        memberName === "Self"
          ? "Feedback Summary"
          : `Feedback Report - ${memberName}`
      }
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 168) {
      setShowFadingEffect(true);
    } else {
      setShowFadingEffect(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderReceivedFeedbackReportLayout = () => (
    <Grid container direction="row" className={classes.root}
      style={{ marginTop: memberName === "Self" ? "0px" : "80px", minHeight: memberName === "Self" ? "100vh" : "calc(100vh - 80px)" }} justify="center">
      <Grid item xs={12} style={{ height: "100%" }}>
        <>
          {renderErrorModal()}
          <Grid xs={12} item container>
            {renderMemberCard()}
          </Grid>
          {memberName !== "Self" && <Divider className={classes.divider} />}
          {loading || loadingCounts ? <Grid
            item
            xs={12}
            container
            style={{ width: "calc(100% - 300px)", height: "70vh", position: "absolute", zIndex: 3 }}
          >
            <Loader></Loader>
          </Grid> :
            <>
              <Grid item container className={classes.stickyContainer}>
                <Grid container alignItems="center"
                  item
                  className={`${classes.commonCardStyle} ${classes.receivedCountBackground}`}
                >
                  <FeedbackReportCountCard
                    cardName={"Total Feedback Received"}
                    cardCount={counts || 0}
                  ></FeedbackReportCountCard>
                </Grid>
                <Grid style={{ display: "flex" }}>
                  <Grid style={{ marginRight: "18px" }}>
                    {/* Calender CTA */}
                    <CalendarCTA
                      setDateRange={setReceivedFeedbackDateRangeState}
                      dateRange={receivedFeedbackDateRangeState}
                      selectedDateCount={selectedDateCount}
                      setSelectedDateCount={setSelectedDateCount}
                    />
                    {/* Calender CTA end*/}
                  </Grid>
                  <Grid >
                    <ExportCTA
                      receivedFeedbackDateRangeState={receivedFeedbackDateRangeState}
                      memberName={userFullName}
                      receivedFeedbacksWithinSelectedRange={reportData}
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" direction="column">
                  {reportData.length === 0 ? renderNoFeedbacks() : renderAttributeTabs()}
                </Grid>
              </Grid>
              {showFadingEffect && <Grid className={classes.stickyFadingEffect}>
                <Grid className={classes.fadingElement} />
              </Grid>}
              <Grid
                xs={12}
                item
                container
                className={`${classes.widthStyle} ${customClasses.popperWidth}`}
              >
                {renderAttributeContent()}
              </Grid>
            </>
          }
        </>
        {renderScrollOnTop()}
      </Grid >
    </Grid >
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderReceivedFeedbackReportLayout}
    ></HomeLayout>
  );
};
