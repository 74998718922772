import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { markRequestClientTourDone } from "../../../redux/slices/CoachMarkSlice";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../type";
import "../../../App.css";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  tabs: {
    color: "#576BEA",
    width: theme.spacing(20),
    textAlign: "left",
  },
  divider: {
    backgroundColor: "#576bea66",
    width: "inherit",
  },
  typography_body_1: {
    textTransform: "none",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  tab: {
    width: "inherit",
    minWidth: theme.spacing(10),
    padding: theme.spacing(0.5, 2.5, 0, 2.5),
  },
}));

const RequestEmployeeText = () => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
      Request Employee
    </Typography>
  );
};

const RequestClientText = () => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h5" className={`${classes.typography_body_1} request-client-btn`}>
      Request Client
    </Typography>
  );
};

const PastRequestsText = () => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
      Past Requests
    </Typography>
  );
};

export const TabLabels = ({ tabValue, setTabValue, props }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [showTour, setShowTour] = useState(false);
  const requestClientButtonTourStatus = useSelector((state: State) => state.coachMarks.dashboardTour.requestClientTour);
  const requestPageVisitCount = useSelector((state: State) => state.coachMarks.dashboardTour.requestNowPageVisitCount);
  const tourRef = useRef<Shepherd.Tour | null>(null);
  const history = useHistory();

useEffect(() => {
  if (requestClientButtonTourStatus === "PENDING" && requestPageVisitCount === 2) {
    setShowTour(true);
    dispatch(markRequestClientTourDone()); 
  } else {
    setShowTour(false);
  }
}, [requestClientButtonTourStatus, requestPageVisitCount]);

useEffect(() => {
  if (showTour) {
    tourRef.current = new Shepherd.Tour({
      defaultStepOptions: {
        classes: 'custom-class-sm',
        scrollTo: { behavior: 'smooth', block: 'center' },
      }
    });

    const attachToOptions = {
      element: '.request-client-btn',
      on: 'top'
    };

    if (window.innerWidth <= 767) {
      // Modify attachTo options for smaller screens
      attachToOptions.on = 'bottom';
    }

    tourRef.current.addSteps([
      // Tour steps
      {
        text: 'Request feedback from your clients and external vendors from here',
        attachTo: attachToOptions,
        id: 'step2',
        scrollTo: true,
        when: {
          show: () => {
            setTimeout(() => {
              tourRef.current?.next();
            }, 5000);
          }
        },
        classes: 'request-clients-coachmark'
      }
    ]);

    tourRef.current.start();
  }
}, [showTour]);

useEffect(() => {
  return history.listen(() => {
    if (tourRef.current) {
      tourRef.current?.cancel(); // *** Cancel the tour on route change ***
    }
  });
}, [history]);

  const indexToTab = {
    0: "employee",
    1: "client",
    2: "sent",
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.history.push(`/feedback/requests/${indexToTab[newValue]}`);
    setTabValue(newValue);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    props.history.push(
      `/feedback/requests/${indexToTab[event.target.value as number]}`
    );
    setTabValue(event.target.value as number);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Hidden smDown>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab
            classes={{ root: classes.tab }}
            label={<RequestEmployeeText />}
          />

          <Tab classes={{ root: classes.tab }} label={<RequestClientText />} />
          <Tab classes={{ root: classes.tab }} label={<PastRequestsText />} />
        </Tabs>
        <Divider className={classes.divider} />
      </Hidden>
      {
        <Hidden mdUp>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <MenuItem value={0}>
              <RequestEmployeeText />
            </MenuItem>
            <MenuItem value={1}>
              <RequestClientText />
            </MenuItem>
            <MenuItem value={2}>
              <PastRequestsText />
            </MenuItem>
          </Select>
        </Hidden>
      }
    </Grid>
  );
};
