import {
    Grid,
    IconButton,
    makeStyles,
    Typography,
  } from "@material-ui/core";
  import ClearIcon from "@material-ui/icons/Clear";
  import React from "react";
  import {
    ShareFeedbackAttributeNameAndId,
  } from "../../../type";
import { AttributeTitleComponentProp } from "../self_assessment/type";
  
  const useStyle = makeStyles((theme) => ({
    textBox_1: {
      marginBottom: "20px",
      marginLeft: "10px",
    },
  }));

export const AttributeTitleComponent: React.FC<AttributeTitleComponentProp>=({attribute, setDeletingAttribute,handleModalOpen})=> {
    const classes = useStyle();
    return <Grid
    container
    direction="row"
    key={attribute.attributeId}
    style={{ backgroundColor: "rgba(87,107,234,0.05)" }}
  >
    <Grid container item md={11} xs={11}>
      <Typography
        gutterBottom
        variant="h2"
        className={classes.textBox_1}
        style={{ margin: "10px 0px 0px 20px" }}
      >
        {attribute.attributeName}
      </Typography>
    </Grid>
    <Grid
      container
      item
      md={1}
      xs={1}
      justify="flex-end"
      alignItems="flex-end"
    >
      <IconButton
        aria-label="clear"
        onClick={() => {
          handleModalOpen();
          const arg: ShareFeedbackAttributeNameAndId = {
            attributeId: attribute.attributeId,
            attributeName: attribute.attributeName,
          };
          setDeletingAttribute(arg);
        }}
      >
        <ClearIcon />
      </IconButton>
    </Grid>
  </Grid>
}
