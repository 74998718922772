import { Grid, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyle = makeStyles((theme) => ({
  scrollOnTop: {
    position: "fixed",
    height: theme.spacing(5.25),
    width: theme.spacing(5.25),
    right: theme.spacing(1.5),
    bottom: theme.spacing(8),
    backgroundColor: "#7C69EC",
    "&:hover": {
      backgroundColor: "#7C69EC",
    },
  },
}));

export default function ScrollToTop() {
  const classes = useStyle();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <Grid xs={12} item container justify="flex-end" alignItems="center">
          <IconButton onClick={scrollToTop} className={classes.scrollOnTop}>
            <img src="/whiteArrow.svg" alt="Go To Top" />
          </IconButton>
        </Grid>
      )}
    </div>
  );
}
