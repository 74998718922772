import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReceivedFeedbacksSliceProp,
  RequestingParams,
} from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const receivedFeedback = createAsyncThunk(
  "feedback/received",
  async (requestParams: RequestingParams, storeActions) => {
    const url = `api/v1/user/feedbacks/received` + requestParams.requestParams;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        feedbacks: await response.json(),
      };
    }
    return {
      status: response.status,
      feedbacks: null,
    };
  }
);

const receivedFeedbacksSlice = createSlice({
  name: "receivedFeedbacks",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    feedbacks: null as null | ReceivedFeedbacksSliceProp,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.feedbacks = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      receivedFeedback.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          status: number;
          feedbacks: ReceivedFeedbacksSliceProp | null;
        }>
      ) => {
        state.status = payload.status;
        state.loading = false;
        state.feedbacks = payload.feedbacks;
      }
    );
    builder.addCase(receivedFeedback.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.feedbacks = null;
    });
    builder.addCase(receivedFeedback.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.feedbacks = null;
    });
  },
});

export const { clearData } = receivedFeedbacksSlice.actions;

export default receivedFeedbacksSlice;
