import { FeedbackAssessmentDetailLayout } from "../../../../../layouts/admin/members/details/FeedbackAssessmentDetailLayout";
import {
  ReceivedFeedbackDetailData,
  State,
  UserAndAttributeSliceProps,
} from "../../../../../type";
import { Divider, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { requestData } from "../../../../../redux/slices/UsersAndAttributesSlice";
import { DetailPageSliceProps } from "./type";
import { receivedFeedbackDetailByMember } from "../../../../../redux/slices/ReceivedFeedbackDetailByMemberSlice";
import { sharedAssessmentDetailByMember } from "../../../../../redux/slices/SharedAssessmentDetailByMemberSlice";
import { sharedFeedbackDetailByMember } from "../../../../../redux/slices/SharedFeedbackDetailByMemberSlice";
import { SharedFeedbackDetailData } from "../../../../user/shared_feedback/typeSharedFeedbacks";
import { SharedAssessmentDetailProps } from "../../../../user/self_assessment/type";
import { CommonFunctionalityComponent } from "../../../../components/CommonFunctionalityComponent";
import {
  UserName,
  RenderDate,
  AttributeChipComponent,
  ContextText,
  RenderAttributeText,
  RenderAttributesDetails,
} from "../../../../components/list/detail/DetailComponent";

const useStyle = makeStyles((theme) => ({
  divider: {
    backgroundColor: "rgb(140 142 154 / 48%)",
    height: theme.spacing(0.125),
    margin: theme.spacing(5, 0, 2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2.5, 0, 1, 0),
    },
  },
}));

export const FeedbackAssessmentDetail = (props) => {
  const memberId = props.location.state?.memberId;
  const tabValue = props.location.state?.tabValue;
  const from = props.location.state?.from;
  const feedbackOrAssessmentId = props.location.state?.feedbackOrAssessmentId;
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const dispatch = useDispatch();
  const teamId = props.location.state?.teamId;

  const classes = useStyle();

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: false,
      isAttributes: false,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: true,
    };
    dispatch(requestData(param));
    const params: DetailPageSliceProps = {
      memberId: memberId,
      feedbackOrAssessmentId: feedbackOrAssessmentId,
    };
    if (tabValue === 0) {
      dispatch(receivedFeedbackDetailByMember(params));
    } else if (tabValue === 1) {
      dispatch(sharedFeedbackDetailByMember(params));
    } else if (tabValue === 4) {
      dispatch(sharedAssessmentDetailByMember(params));
    }
  }, [feedbackOrAssessmentId, tabValue, memberId]);

  const receivedFeedbackDetaildata: ReceivedFeedbackDetailData = useSelector(
    (state: State) => state.receivedFeedbackDetailByMember.feedback
  );
  const sharedFeedbackDetaildata: SharedFeedbackDetailData =
    useSelector((state: State) => state.sharedFeedbackDetailByMember.data) ??
    null;

  const sharedAssessmentDetailData: SharedAssessmentDetailProps = useSelector(
    (state: State) => state.sharedAssessmentDetailByMember.data
  );

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.sharedFeedbackDetailByMember.status !== 200 &&
    state.sharedFeedbackDetailByMember.status !== null
      ? state.sharedFeedbackDetailByMember.status
      : state.sharedAssessmentDetailByMember.status !== 200 &&
        state.sharedAssessmentDetailByMember.status !== null
      ? state.sharedAssessmentDetailByMember.status
      : state.receivedFeedbackDetailByMember.status !== 200 &&
        state.receivedFeedbackDetailByMember.status !== null
      ? state.receivedFeedbackDetailByMember.status
      : state.data.status !== 200 && state.data.status !== null
      ? state.data.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderDivider = () => <Divider className={classes.divider}></Divider>;

  const renderName = () => (
    <>
      {tabValue === 0 && (
        <UserName
          headerName={"Received from"}
          user={receivedFeedbackDetaildata?.user}
        ></UserName>
      )}
      {tabValue === 1 && (
        <UserName
          headerName={"Shared to"}
          user={sharedFeedbackDetaildata?.user}
        ></UserName>
      )}
    </>
  );

  const renderDateComponent = () => (
    <>
      {tabValue === 0 && (
        <RenderDate
          from={"RECEIVED_FEEDBACK"}
          date={receivedFeedbackDetaildata?.createdAt}
        ></RenderDate>
      )}
      {tabValue === 1 && (
        <RenderDate
          from={"SHARED_FEEDBACK"}
          date={sharedFeedbackDetaildata?.createdAt}
        ></RenderDate>
      )}
      {tabValue === 4 && (
        <RenderDate
          from={"ASSESSMENT_SUBMITTED"}
          date={sharedAssessmentDetailData?.createdAt}
        ></RenderDate>
      )}
    </>
  );

  const renderAllAttributes = () => (
    <>
      {tabValue === 1 && (
        <AttributeChipComponent
          attributes={sharedFeedbackDetaildata?.attributes}
          from={"ADMIN"}
        ></AttributeChipComponent>
      )}
    </>
  );

  const renderFeedbackContext = () => (
    <>
      {tabValue === 0 && (
        <ContextText
          text={receivedFeedbackDetaildata?.context}
          from={"ADMIN"}
        ></ContextText>
      )}
      {tabValue === 1 && (
        <ContextText
          text={sharedFeedbackDetaildata?.context}
          from={"ADMIN"}
        ></ContextText>
      )}
    </>
  );

  const renderAttributesDetails = () => (
    <>
      {tabValue === 0 && (
        <>
          <RenderAttributeText from={"RECEIVED_FEEDBACK"} />
          <RenderAttributesDetails
            attributes={receivedFeedbackDetaildata?.attributes}
            from={"RECEIVED_FEEDBACK"}
          ></RenderAttributesDetails>
        </>
      )}
      {tabValue === 1 && (
        <>
          <RenderAttributeText from={"SHARED_FEEDBACK"} />
          <RenderAttributesDetails
            attributes={sharedFeedbackDetaildata?.attributes}
            from={"SHARED_FEEDBACK"}
          ></RenderAttributesDetails>
        </>
      )}
      {tabValue === 4 && (
        <>
          <RenderAttributeText from={"ASSESSMENT_SUBMITTED"} />
          <RenderAttributesDetails
            attributes={sharedAssessmentDetailData?.attributes}
            from={"ASSESSMENT_SUBMITTED"}
          ></RenderAttributesDetails>
        </>
      )}
    </>
  );

  const location = useLocation();
  const currentPath = location.pathname;
  
  if (!isDetailView) {
    if (from === "dashboard") {
      history.push({
        pathname: `/admin/dashboard`,
      });
    } else if (currentPath === '/admin/detail/view') {
      history.push({
        pathname: `/admin/member/detail/${memberId}`,
        state: {
          member: props.location.state?.member,
          tabValue: tabValue,
          pageNumber: props.location.state?.pageNumber,
          itemValue: props.location.state?.itemValue,
          teamId: teamId 
        },
      });
    }else{
      history.push({
        pathname: `/member/detail/${memberId}`,
        state: {
          member: props.location.state?.member,
          tabValue: tabValue,
          pageNumber: props.location.state?.pageNumber,
          itemValue: props.location.state?.itemValue,
          teamId: teamId 
        },
      });
    }
  }

  return (
    <FeedbackAssessmentDetailLayout
      props={props}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      headerName={
        tabValue === 0
          ? "Received Feedback Details"
          : tabValue === 1
          ? "Shared Feedback Details"
          : tabValue === 4
          ? "Shared Assessment Details"
          : ""
      }
      renderErrorModal={renderErrorModal}
      renderDivider={renderDivider}
      renderName={renderName}
      renderDateComponent={renderDateComponent}
      renderContext={renderFeedbackContext}
      renderAllAttributes={renderAllAttributes}
      renderAttributesDetail={renderAttributesDetails}
      tabValue={tabValue}
    ></FeedbackAssessmentDetailLayout>
  );
};
