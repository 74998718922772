import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ToolBar } from "../../../modules/home/ToolBar";
import theme from "../../../theme";
import { DashboardLayoutProps } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 1, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
  },
  leftSide: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 0.0, 2.25),
    },
  },
}));

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  renderErrorModal,
  renderDashboardTopArtwork,
  renderComingSoon,
  renderCountCards,
  renderPendingListComponent,
}) => {
  document.title="Grow-Wize | Dashboard";
  const classes = useStyle();
  window.scrollTo(0, 0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction =()=> {
    return 0;
  }

  const renderToolBar = () => (
    <ToolBar
      headerName="Dashboard"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderDashboardLayout = () => (
    <>
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        {renderErrorModal()}
        <Grid container style={{ marginBottom: theme.spacing(2.625) }}>
          {renderDashboardTopArtwork()}
        </Grid>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            md={7}
            xs={12}
            direction="row"
            className={classes.leftSide}
          >
            <Grid item xs={12}>
              {renderCountCards()}
            </Grid>
            <Grid item xs={12}>
              {renderPendingListComponent()}
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={5}
            xs={12}
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {renderComingSoon()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderDashboardLayout}
    ></HomeLayout>
  );
};
