import {
  Button,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttributeDetailLayout } from "../../../../layouts/admin/attributeDetail/Layout";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import {
  AttributeDetailSliceProps,
  State,
} from "../../../../type";
import { useHistory, useParams } from "react-router-dom";
import { Question } from "../question/question";
import { getAttributeDetail } from "../../../../redux/slices/AttributeDetailSlice";
import {
  UpdateAttributeSliceProps,
  updateAttribute,
} from "../../../../redux/slices/UpdateAttributeSlice";
import { AddQuestionModal } from "../addQuestionModal/addQuestionModal";
import ToastMessage from "../../../components/ToastMessage";
import { clearAddQuestionData } from "../../../../redux/slices/AddQuestionSlice";
import { attributeDetailStyles } from "./helper";
import { AttributeStatus, Messages, ValidationErrors } from "../helper";
import { HttpStatus } from "../../../../enums/httpStatus";
import { clearUpdateQuestionData, selectUpdateQuestionResponseData } from "../../../../redux/slices/UpdateQuestionSlice";

export const AttributeDetail = (props) => {
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const { attributeId }: any = useParams();
  const [showAddQuestionModal, setShowAddQuestionModal] =
    React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [attributeName, setAttributeName] = React.useState<string>("");
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [errors, setErrors] = React.useState<any>({});
  const [enablingQuestion, setEnablingQuestion] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  const { status, text } = useSelector((state: State) => state.updateAttribute);
  const updatedQuestionData = useSelector(selectUpdateQuestionResponseData);

  const checkValidation = () => {
    let isValid = true;
    const errors = {};
    if (attributeName.trim().length === 0) {
      isValid = false;
      errors["attributeName"] = ValidationErrors.ATTRIBUTE_NAME;
    } else if (attributeName.trim().length > 50) {
      isValid = false;
      errors["attributeName"] = ValidationErrors.ATTRIBUTE_NAME_LENGTH;
    }
    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (status === HttpStatus.BAD_REQUEST) {
      const apiError = JSON.parse(text)?.message;
      const validationErrors = { ...errors };
      validationErrors["attributeName"] = apiError;
      setErrors(validationErrors);
    }
  }, [status, text]);

  useEffect(() => {
    if (submitted) {
      checkValidation();
    }
  }, [attributeName]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
    setMessage("");
  };

  useEffect(() => {
    const addedAttribute = props.location.state?.addedAttribute;
    const questionAdded = props.location.state?.questionAdded;
    if (addedAttribute) {
      setShowSuccess(true);
      history.replace({
        pathname: `/admin/manage/attributes/${attributeId}`,
        state: { from: "manage_attributes" },
      });
      setMessage(Messages.ADD_ATTRIBUTE);
    } else if (questionAdded) {
      setShowSuccess(true);
      history.replace({
        pathname: `/admin/manage/attributes/${attributeId}`,
        state: { from: "manage_attributes" },
      });
      setMessage(Messages.ADD_QUESTION);
    }
  }, []);

  const dispatch = useDispatch();

  const { data: attributeDetails } = useSelector(
    (state: State) => state.attributeDetail
  );

  const isDisabled = attributeDetails?.status === AttributeStatus.INACTIVE;

  const token = useSelector((state: State) => state.auth.token);

  useEffect(() => {
    if (attributeDetails?.name) {
      setAttributeName(attributeDetails?.name);
    }
  }, [attributeDetails]);

  useEffect(() => {
    if (attributeId) {
      const param: AttributeDetailSliceProps = {
        attributeId: Number(attributeId),
      };
      dispatch(getAttributeDetail(param));
    }
  }, [attributeId]);

  const classes = attributeDetailStyles();

  const loading: Boolean = useSelector(
    (state: State) => state.attributeDetail.loading || state.addAttribute.loading || state.updateAttribute.loading
  );

  const statusCode: number | null = useSelector((state: State) =>
    state.attributeDetail.status !== HttpStatus.OK
      ? state.attributeDetail.status
      : state.updateAttribute.status !== HttpStatus.OK && !state.updateAttribute.text
        ? state.updateAttribute.status
        : state.addAttribute.status !== HttpStatus.OK
          ? state.addAttribute.status
          : null
  );

  const updatingAttribute: Boolean = useSelector(
    (state: State) => state.updateAttribute.loading
  );

  useEffect(() => {
    if (submitted && !updatingAttribute && status === HttpStatus.OK) {
      setShowSuccess(true);
      setMessage(Messages.UPDATE_ATTRIBUTE_NAME);
      resetData();

    }
  }, [attributeDetails?.name])

  const resetData = () => {
    setIsEditing(false);
    setErrors({});
    setAttributeName(attributeDetails?.name);
    setSubmitted(false);
  }

  const updateAttributeName = () => {
    setSubmitted(true);
    const isValid = checkValidation();
    if (!isValid) return;

    if (attributeName.trim() === attributeDetails?.name.trim()) { resetData(); return; }

    const params: UpdateAttributeSliceProps = {
      id: attributeDetails?.id,
      name: attributeName,
      attributeStatus: attributeDetails?.status,
    };
    dispatch(updateAttribute(params));
  };

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const handleOpenAddQuestionModal = () => {
    setShowAddQuestionModal(true);
  };

  const renderAddQuestionButton = () => {
    return attributeDetails ? (
      <Button
        color="primary"
        className={`${classes.btn} ${isEditing || isEditMode ? classes.disabled : ""}`}
        onClick={() => handleOpenAddQuestionModal()}
        variant="contained"
        disabled={isDisabled}
      >
        New Question
      </Button>
    ) : null;
  };

  const handleQuestionStatusUpdateSuccess = ({ updatedStatus, questionId = null }) => {
    if (questionId === updatedQuestionData?.id) {
      const isDisabled = updatedQuestionData?.status === AttributeStatus.INACTIVE
      setMessage(isEditMode
        ? Messages.UPDATE_QUESTION : isDisabled
          ? Messages.DISABLE_QUESTION : Messages.ENABLE_QUESTION);
      setIsEditMode(false);
      setShowSuccess(true);
      dispatch(clearUpdateQuestionData());
    }
  };

  const renderQuestionsList = () => {
    return (
      <Grid container direction="column" className={`${classes.questionsList} ${isEditing ? classes.disabled : ""}`} style={{ marginTop: isEditing ? "12px" : "38px" }}>
        {attributeDetails?.questions?.map((question, index) => (
          <Question handleUpdateQuestionSuccess={handleUpdateQuestionSuccess} key={question?.id} index={index} {...question} attributeStatus={question?.status} setEnablingQuestion={setEnablingQuestion} setIsEditMode={setIsEditMode} isEditMode={isEditMode} handleQuestionStatusUpdateSuccess={handleQuestionStatusUpdateSuccess} />
        ))}
      </Grid>
    );
  };

  const handleAddQuestionSuccess = () => {
    setShowAddQuestionModal(false);
    setMessage(Messages.ADD_QUESTION);
    setShowSuccess(true);
    const param: AttributeDetailSliceProps = {
      attributeId: Number(attributeId),
    };
    dispatch(getAttributeDetail(param));
    dispatch(clearAddQuestionData());
  };

  const handleCancel = () => {
    setShowAddQuestionModal(false);
    dispatch(clearAddQuestionData());
  };

  const renderAddQuestionModal = () => (
    <AddQuestionModal
      open={showAddQuestionModal}
      handleCancel={handleCancel}
      handleSuccess={handleAddQuestionSuccess}
      attributeId={attributeId}
    />
  );

  const renderInfoBanner = () => {
    return (
      <Grid container direction="row" className={classes.bannerContainer}>
        <Icon
          style={{
            height: "16px",
            width: "16px",
            paddingBottom: "35px",
            marginRight: "8px"
          }}
        >
          <img src="/infoDark.svg" alt="info" height={16} width={16}></img>
        </Icon>
        <Typography className={classes.infoText}>
          The attribute is currently disabled. To enable editing, Kindly use the switch provided on the attribute list.
        </Typography>
      </Grid>
    )
  }

  const handleUpdateQuestionSuccess = () => {
    if (isEditMode) {
      setMessage(Messages.UPDATE_QUESTION);
      setShowSuccess(true);
      dispatch(clearUpdateQuestionData());
      setIsEditMode(false)
    }
  };

  const renderAttributeName = () =>
    attributeDetails ? (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={`${classes.heading} ${isEditMode ? classes.disabled : ""}`}
        style={{ marginTop: !isEditing ? "6px" : "4px" }}
      >
        <ToastMessage
          open={showSuccess}
          handleClose={handleClose}
          message={message}
        />
        {!isEditing ? (
          <Typography className={classes.attributeName}>
            {attributeDetails?.name}
          </Typography>
        ) : (
          <TextField
            fullWidth
            placeholder="Attribute Name"
            variant="outlined"
            value={attributeName}
            helperText={errors?.attributeName || " "}
            error={errors?.attributeName}
            className={classes.attributeNameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAttributeName(event.target.value);
            }}
          ></TextField>
        )}
        {!isEditing ? (
          <IconButton
            onClick={() => setIsEditing(true)}
            style={{
              padding: "4px",
              position: "relative",
              height: "20px",
              width: "20px",
              marginLeft: "16px",
            }}
            disabled={isDisabled}
          >
            <img src="/editCTA.svg" alt="edit" />
          </IconButton>
        ) : (
          <Grid container direction="row" className={classes.buttonsContainer}>
            <Button
              className={classes.actionButton}
              onClick={updateAttributeName}
            >
              Save
            </Button>
            <Button
              className={classes.actionButton}
              onClick={resetData}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    ) : null;

  useEffect(() => {
    if (showSuccess && message === Messages.ADD_QUESTION) {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [showSuccess, message, attributeDetails?.questions?.length])

  const history = useHistory();

  if (!isDetailView) {
    const currentPage = props.location?.state?.currentPage || 1;
    history.push({ pathname: "/admin/manage/attributes", search: `?page=${currentPage}` });
  }

  return (
    <AttributeDetailLayout
      props={props}
      loading={loading}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderAddQuestionButton={renderAddQuestionButton}
      renderQuestionsList={renderQuestionsList}
      renderAddQuestionModal={renderAddQuestionModal}
      renderAttributeName={renderAttributeName}
      renderInfoBanner={renderInfoBanner}
      attributeStatus={attributeDetails?.status}
    ></AttributeDetailLayout>
  );
};
