import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestData, UserAndAttributeSliceProps } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const requestData = createAsyncThunk(
  "requestData/sendRequest",
  async (params: UserAndAttributeSliceProps, storeActions) => {
    const url = `api/v1/user/users-and-attributes?isUsers=${params.isUsers}&isAttributes=${params.isAttributes}&isRequests=${params.isRequests}&isDrafts=${params.isDrafts}&isScale=${params.isScale}&isClients=${params.isClients}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    status: null as number | null,
    text: null as string | null,
    data: {
      users: [],
      attributes: [],
      requests: [],
      drafts: [],
      scaleResponseEntityList: [],
      clients: [],
    } as RequestData | null,
    loaded: false as boolean,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.loaded = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestData.fulfilled, (state, action) => {
      state.status = action.payload.status;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(requestData.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.text = null;
      state.data = {
        users: [],
        attributes: [],
        requests: [],
        drafts: [],
        scaleResponseEntityList: [],
        clients: [],
      };
      state.loaded = false;
    });
    builder.addCase(requestData.rejected, (state, action) => {
      state.status = 503;
      state.text = null;
      state.data = {
        users: [],
        attributes: [],
        requests: [],
        drafts: [],
        scaleResponseEntityList: [],
        clients: [],
      };
      state.loaded = false;
      state.loading = false;
    });
  },
});

export const { clearData } = dataSlice.actions;

export default dataSlice;
