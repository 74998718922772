import { ResponseTeam } from "../../../../type";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { teamListTableStyles, TITLE } from "./helper";
import TeamListTableItem from "./teamListTableItem";
import { NoRecordFoundInTable } from "../../../components/list/noRecordPage/NoRecordText";
const TeamListTable = ({ data: teams, pageNumber, loading }) => {
  const classes = teamListTableStyles();

  const renderTitles = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        {TITLE.map((title) => (
          <Grid item key={title.name} xs={title.size}>
            <Typography variant="h5" className={classes.typography_body}>
              {title.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      className={classes.root}
    >
      <Paper
        variant="outlined"
        square
        style={{ width: "100%", position: "relative" }}
      >
        {renderTitles()}
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%", position: "absolute" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {teams?.length ? (
              teams.map((team: ResponseTeam) => (
                <TeamListTableItem
                  teamId={team?.id}
                  teamName={team?.name}
                  teamLeads={team?.teamLeads}
                  totalMembers={team?.totalMembers}
                  key={team?.id}
                  titles={TITLE}
                  pageNumber={pageNumber}
                />
              ))
            ) : (
              <NoRecordFoundInTable from="TEAMS_LIST" />
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default TeamListTable;
