import {
  CircularProgress,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import { TEAM_TITLES, teamMemberListStyles } from "../helper";
import TeamMemberListItem from "./TeamMemberLisItem";
import { Member, User } from "../../../../type";
import { USER_ROLES } from "../../../../enums/teamEnums";

const TeamMemberList = ({
  users,
  loading,
  roles,
  setRoles,
  setAllSelectedUsers,
  isTeamDetailsView,
  allExisitingUsers ,
  setAllExisitingUsers ,
  allSelectedUsers,
  removeUsers,
  setRemoveUsers 
}) => {
  
  const classes = teamMemberListStyles();

  const renderTitles = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        {TEAM_TITLES.map((title) => (
          <Grid item key={title.name} xs={title.size}>
            <Typography variant="h5" className={`${classes.typography_body}`}>
              {title.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  if (users?.length === 0) {
    return null;
  }

  const onChangeUserRole = (user: Member) => {
    const currentRole = roles?.find(({ id }) => user.id === id);
    let updatedRoles = [...roles];
    if (currentRole != null && currentRole != undefined) {
      updatedRoles = roles.map((item) => {
        const { id } = item;
        if (user.id === id) {
          return {
            ...item,
            role: user.role,
          };
        } else {
          return item;
        }
      });
    } else {
      updatedRoles = [...roles, { id: user.id, role: user.role }];
    }
    setRoles(updatedRoles);
  };

  const RemoveMemberChange = (userId) => {  

    const exisitingUser = allExisitingUsers.find(user =>user.id === userId);
    if(exisitingUser) {
      const updatedUsers = allExisitingUsers.filter((user) => user.id != userId);
      setAllExisitingUsers(updatedUsers);
      setRemoveUsers([...removeUsers, exisitingUser])
    } else {
      const updatedUsers = allSelectedUsers.filter((user) => user.id != userId);
      setAllSelectedUsers(updatedUsers);
    }   
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      className={classes.root}
    >
        <Grid
          container
          direction="row"
          className={classes.bannerContainer}
          style={{ marginBottom: 12, marginTop: 24 }}
        >
      {!isTeamDetailsView && (
        <>
          <Icon
            style={{
              height: "18px",
              width: "18px",
              paddingBottom: "32px",
              marginRight: "6px",
            }}
          >
            <img src="/infoIcon.svg" alt="info" height={18} width={18}></img>
          </Icon>
          <Typography className={classes.infoText}>
            To complete team creation assign Team Lead role to a member .
          </Typography>
        </>
      )}
        </Grid>
      <Paper
        variant="outlined"
        square
        style={{ width: "100%", position: "relative" }}
      >
        {renderTitles()}
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%", position: "absolute" }}
          >
            <CircularProgress />
          </Grid>
        )}
        {users?.map((user: User) => (
          <TeamMemberListItem
            key={user.id}
            user={user}
            titles={TEAM_TITLES}
            onChangeUserRole={onChangeUserRole}
            memberRole={user.role}
            RemoveMemberChange={RemoveMemberChange}
            isTeamDetailsView={isTeamDetailsView}
          />
        ))}
      </Paper>
    </Grid>
  );
};

TeamMemberList.defaultProps = {
  setAllExisitingUsers : ()=>{},
  allExisitingUsers:[], 
  removeUsers : [],
  allSelectedUsers : [],
  setRemoveUsers :  ()=>{}
}
export default TeamMemberList;
