import { useDispatch } from "react-redux";
import { clearData as clearAuthData } from "../../redux/slices/AuthSlice";
import { clearData as clearCountReceived } from "../../redux/slices/CountReceivedFeedbacksSlice";
import { clearData as clearCountRequestReceived } from "../../redux/slices/CountRequestsReceivedSlice";
import { clearData as clearCountRequestSend } from "../../redux/slices/CountRequestsSendSlice";
import { clearData as clearCountShared } from "../../redux/slices/CountSharedFeedbackSlice";
import { clearData as clearAllReceivedRequests } from "../../redux/slices/FeedbackRequestsReceivedSlice";
import { clearData as clearAllSendRequests } from "../../redux/slices/FeedbackRequestsSendSlice";
import { clearData as clearReceivedFeedbackDetail } from "../../redux/slices/ReceivedFeedbackDetailSlice";
import { clearData as clearReceivedFeedbacks } from "../../redux/slices/ReceivedFeedbacksSlice";
import { clearData as clearRequest } from "../../redux/slices/RequestFeebackSlice";
import { clearData as clearSharedFeedbackDetail } from "../../redux/slices/SharedFeedbackDetailSlice";
import { clearData as clearSharedAssessmentDetail } from "../../redux/slices/SharedAssessmentDetailSlice";
import { clearData as clearSharedDraftDetail } from "../../redux/slices/SharedDraftDetailSlice";
import { clearData as clearSharedFeedbacks } from "../../redux/slices/SharedFeedbacksSlice";
import { clearData as clearSavedDrafts } from "../../redux/slices/SavedDraftsSlice";
import { clearData as clearShareFeedback } from "../../redux/slices/ShareFeedbackSlice";
import { clearData as clearShareAssessment } from "../../redux/slices/ShareAssessmentSlice";
import { clearData as clearUsersAndAttributes } from "../../redux/slices/UsersAndAttributesSlice";
import { clearData as clearAllMemberList } from "../../redux/slices/AllMemberList";
import { clearData as clearReceivedFeedbackReport } from "../../redux/slices/ConsolidateReportOfReceivedFeedbackSlice";
import { clearData as clearDeactiveMember } from "../../redux/slices/DeactivateMemberSlice";
import { clearData as clearDeleteDraft } from "../../redux/slices/DeleteDraftSlice";
import { clearData as clearSharedAssessmentDetailByMember } from "../../redux/slices/SharedAssessmentDetailByMemberSlice";
import { clearData as clearReceivedFeedbackDetailByMember } from "../../redux/slices/ReceivedFeedbackDetailByMemberSlice";
import { clearData as clearSharedFeedbackDetailByMember } from "../../redux/slices/SharedFeedbackDetailByMemberSlice";
import { clearData as clearSharedAssessmentListByMember } from "../../redux/slices/SharedAssessmentListByMemberSlice";
import { clearData as clearSharedAssessmentList } from "../../redux/slices/SharedAssessmentListSlice";
import { clearData as clearCountSharedAssessment } from "../../redux/slices/CountSharedAssessmentsSlice";
import { clearData as clearCountSharedAssessmentByMember } from "../../redux/slices/CountAssessmentSharedByMemberSlice";
import { clearData as clearCountRequestReceivedByMember } from "../../redux/slices/CountRequestReceivedByMemberSlice";
import { clearData as clearCountRequestSentByMember } from "../../redux/slices/CountRequestSentByMemberSlice";
import { clearData as clearFeedbackRequestReceivedByMember } from "../../redux/slices/FeedbackRequestReceivedByMemberSlice";
import { clearData as clearFeedbackRequestsSentByMember } from "../../redux/slices/FeedbackRequestsSentByMemberSlice";
import { clearData as clearReceivedAndSharedCount } from "../../redux/slices/ReceivedAndSharedCountSlice";
import { clearData as clearReceivedAndSharedAllCount } from "../../redux/slices/ReceivedAndSharedAllCountSlice";
import { clearData as clearReceivedFeedbackListByMember } from "../../redux/slices/ReceivedFeedbackListByMemberSlice";
import { clearData as clearSharedFedbackListByMember } from "../../redux/slices/SharedFeedbackListByMemberSlice";
import { clearData as clearAppraisalListData } from "../../redux/slices/AppraisalSlice";
import { clearData as clearAttributesWithDetailData } from "../../redux/slices/AttributesWithDetailData";
import { clearData as clearLeaderFeedbackListData } from "../../redux/slices/LeaderFeedbackSlice";
import { clearData as clearReceivedFeedbackRequestDetailsData } from "../../redux/slices/ReceivedFeedbackRequestDetailsSlice";
import { clearData as clearRecentActivityData } from "../../redux/slices/RecentActivitiesSlice";
import { clearData as clearRequestFeedbackFromExtClientData } from "../../redux/slices/RequestFeedbackFromExtClientSlice";
import { clearData as clearSendFeedbackRequestDetailsData } from "../../redux/slices/SentFeedbackRequestDetailsSlice";
import { clearData as clearShareAssessmentData } from "../../redux/slices/ShareAssessmentSlice";
import { clearData as clearSharedDraftDetailForClientData } from "../../redux/slices/SharedDraftDetailForClient";
import { clearData as clearShareFeedbackByClientData } from "../../redux/slices/ShareFeedbackByClient";
import { clearData as clearSubmitFeedbackByClientData } from "../../redux/slices/SubmitFeedbackByExternalClient";
import { clearData as clearSubmitFeedback } from "../../redux/slices/SubmitFeedbackSlice";
import { clearData as clearSelfReportData } from "../../redux/slices/SelfReportSlice";
import { clearData as clearShareNugget } from "../../redux/slices/NuggetShareSlice";
import { clearData as clearUpdateOrganizationData } from "../../redux/slices/UpdateOrganizationSlice";
import { clearData as clearAddTeamMember } from "../../redux/slices/AddTeamMembersSlice";
import { clearData as clearAccessRole } from "../../redux/slices/UpdateMemberAccessRole";

export const ClearAllData = (setDataCleared) => {
  const dispatch = useDispatch();
  dispatch(clearAuthData());
  dispatch(clearReceivedAndSharedAllCount());
  dispatch(clearSubmitFeedback());
  dispatch(clearAppraisalListData());
  dispatch(clearAttributesWithDetailData());
  dispatch(clearLeaderFeedbackListData());
  dispatch(clearReceivedFeedbackRequestDetailsData());
  dispatch(clearRecentActivityData());
  dispatch(clearRequestFeedbackFromExtClientData());
  dispatch(clearSendFeedbackRequestDetailsData());
  dispatch(clearShareAssessmentData());
  dispatch(clearSharedDraftDetailForClientData());
  dispatch(clearShareFeedbackByClientData());
  dispatch(clearSubmitFeedbackByClientData());
  dispatch(clearCountReceived());
  dispatch(clearCountRequestReceived());
  dispatch(clearCountRequestSend());
  dispatch(clearCountShared());
  dispatch(clearAllReceivedRequests());
  dispatch(clearAllSendRequests());
  dispatch(clearReceivedFeedbackDetail());
  dispatch(clearReceivedFeedbacks());
  dispatch(clearRequest());
  dispatch(clearSharedFeedbackDetail());
  dispatch(clearSharedDraftDetail());
  dispatch(clearSharedFeedbacks());
  dispatch(clearShareFeedback());
  dispatch(clearShareAssessment());
  dispatch(clearUsersAndAttributes());
  dispatch(clearSharedAssessmentDetail());
  dispatch(clearSavedDrafts());
  dispatch(clearAllMemberList());
  dispatch(clearReceivedFeedbackReport());
  dispatch(clearDeactiveMember());
  dispatch(clearDeleteDraft());
  dispatch(clearSharedAssessmentDetailByMember());
  dispatch(clearReceivedFeedbackDetailByMember());
  dispatch(clearSharedFeedbackDetailByMember());
  dispatch(clearSharedAssessmentListByMember());
  dispatch(clearSharedAssessmentList());
  dispatch(clearCountSharedAssessment());
  dispatch(clearCountSharedAssessmentByMember());
  dispatch(clearCountRequestReceivedByMember());
  dispatch(clearCountRequestSentByMember());
  dispatch(clearFeedbackRequestReceivedByMember());
  dispatch(clearFeedbackRequestsSentByMember());
  dispatch(clearReceivedAndSharedCount());
  dispatch(clearReceivedFeedbackListByMember());
  dispatch(clearSharedFedbackListByMember());
  dispatch(clearSelfReportData());
  dispatch(clearShareNugget());
  dispatch(clearUpdateOrganizationData());
  dispatch(clearAddTeamMember());
  dispatch(clearAccessRole());
  setDataCleared(true);
};
