import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCustomStyle } from "../../../styles";
import {
  EditorForText,
  RemainingCharWarnText,
} from "../share_feedback/addNewButtonElements";
import "../share_feedback/style.css";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    remainingChar: {
      fontSize: theme.spacing(1.5),
      color: "#868686",
      textAlign: "end",
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.125),
      },
    },
    editor: {
      width: "-webkit-fill-available",
    },
  })
);

export const ContextTextEditorComponent = ({
  showToolbar,
  setShowToolbar,
  editorState,
  onEditorStateChange,
  context,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  return (
    <>
      <div className={`${customClasses.popperWidth} ${classes.editor}`}>
        <EditorForText
          idx={1}
          textHeader={"req-context"}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <RemainingCharWarnText text={context} limit={10000} />
        </Grid>
      </Grid>
    </>
  );
};
