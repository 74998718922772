import { Grid } from "@material-ui/core";
import React from "react";

export const GrowWizeLogo = () => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <img src="/LogoWithName.svg" alt="Grow Wize" style={{height: "103px", width: "262px"}}/>
      </Grid>
    </Grid>
  );
};
