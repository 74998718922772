import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import React from "react";
import theme from "../../theme";
import { LocalDrawer } from "./Drawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: theme.spacing(28),
      boxShadow: "2px 0 10px 0 rgba(175,175,175,0.3)",
    },
  })
);

function dummyFunction() { }

export const HiddenNavWhenLarge = ({ mobileOpen, handleDrawerToggle, props }) => {
  const classes = useStyles();
  return (<Hidden smUp implementation="css">
    <Drawer
      variant="temporary"
      open={mobileOpen}
      anchor={theme.direction === "rtl" ? "right" : "left"}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <LocalDrawer props={props} handleDrawerToggle={handleDrawerToggle}></LocalDrawer>
    </Drawer>
  </Hidden>)
};

export const HiddenNavWhenSmall = ({ props }) => {
  const classes = useStyles();
  return (<Hidden xsDown implementation="css">
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      open
    >
      <LocalDrawer props={props} handleDrawerToggle={dummyFunction}></LocalDrawer>
    </Drawer>
  </Hidden>)
};
