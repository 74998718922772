import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AttributeDetailSliceProps, User } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getAttributeDetail = createAsyncThunk(
  "admin/attributes",
  async (params: AttributeDetailSliceProps, storeActions) => {
    const url = `api/v1/admin/attributes/${params.attributeId}`;
    const response: any = await get(url, storeActions);
    
    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const attributeDetailSlice = createSlice({
  name: "attributeDetail",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: [] as Array<User> | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAttributeDetail.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(getAttributeDetail.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = state.data ? state.data : null;
      state.text = null;
    });
    builder.addCase(getAttributeDetail.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = attributeDetailSlice.actions;

export default attributeDetailSlice;
