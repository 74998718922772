import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "inherit",
      padding: theme.spacing(4),
      height: `100%`,
    },
  })
);

interface Props {
  renderAppLogo: () => React.ReactNode;
  renderSetupForm: () => React.ReactNode;
  renderErrorModal: () => React.ReactNode;
}

export const WorkspaceSetupLayout: React.FC<Props> = ({
  renderAppLogo,
  renderSetupForm,
  renderErrorModal,
}) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container direction="row" className={classes.root}>
      {renderAppLogo()}
      {renderSetupForm()}
      {renderErrorModal()}
    </Grid>
  );
};
