import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "24px",
    },
    h2: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "18px",
    },
    h3: {
      color: "#FFFFFF",
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    h4: {
      color: "#A0A0A0",
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
    },
    h6: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#272727",
    },
    body1: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "16px",
    },
    body2: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#868686",
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  }
});

export default theme;
