import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const countSharedAssessment = createAsyncThunk(
  "assessments/shared/count",
  async (_, storeActions) => {
    const url = `api/v1/user/assessment/shared/count`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const countSharedAssessmentsSlice = createSlice({
  name: "countSharedAssessments",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as Number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(countSharedAssessment.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(countSharedAssessment.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(countSharedAssessment.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = countSharedAssessmentsSlice.actions;

export default countSharedAssessmentsSlice;
