import { makeStyles } from "@material-ui/core";
import { Dispatch } from "react";
import { AnswerTypeEnum } from "../../../enums/answerTypeEnums";

export const dropdownStyles = makeStyles((theme) => ({
    expandWrapper: {
        padding: "8px 12px",
        border: "1px solid #E7E7E7",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        cursor: "pointer"
    },
    expandOptionsWrapper: {
        background: "#FFFFFF",
        width: "100%",
        border: "0.5px solid rgba(175, 175, 175, 0.3)",
        borderRadius: 4,
        boxShadow: "-5px 9px 19px 0px rgba(90, 90, 90, 0.3983)",
        marginTop: 2
    },
    wrapper: {
        padding: "8px 12px",
        border: "1px solid #E7E7E7",
        borderRadius: "4px",
        alignItems: "center",
        width: "180px",
        height: "41px",
        cursor: "pointer",
        position:"relative",
        display: "grid",
        gridTemplateColumns: "auto 32px"
    },
    optionsWrapper: {
        background: "#FFFFFF",
        width: "180px",
        border: "0.5px solid rgba(175, 175, 175, 0.3)",
        borderRadius: 4,
        boxShadow: "-5px 9px 19px 0px rgba(90, 90, 90, 0.3983)",
        marginTop: 2,
        position:"absolute",
        zIndex:3
    },
    openWrapper:{
        border: "1px solid #576BEA !important" ,
    },
    selected: {
        color: "#272727",
        fontSize: 14,
        
    },
    placeholder: {
        color: "#A0A0A0",
        fontSize: 14,
        fontWeight: 500
    },
    option: {
        fontSize: 14,
        fontWeight: 500,
        color: "#272727",
        padding: "8px 12px",
        borderRadius: 4,
        cursor: "pointer"
    },
    selectedOption: {
        fontSize: 14,
        color: "#272727",
        background: "#EBE8FF",
        padding: "8px 12px",
        borderRadius: 4,
        cursor: "pointer",
        fontWeight: 500,
    },
    isDisabled: {
        background: "rgba(175, 175, 175, 0.30)",
        border: "1px solid rgba(175, 175, 175, 0.30)"
    }
}));

export type OptionType = {
    id: number;
    key: string;
    value: string;
}

export type DropdownProps = {
    placeholder?: string;
    options: OptionType[];
    selectedOption: OptionType | undefined;
    onSelect: Dispatch<any>;
    expand?:Boolean;
    isDisabled?:Boolean;
}
