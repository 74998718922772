import {
  Avatar,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  blackFont: {
    color: "#272727",
    fontFamily: "Montserrat",
    textAlign: "start",
  },
  memberName: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: "8px"
  },
  memberMail: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  img: {
    height: "86px",
    width: "86px",
    marginright: theme.spacing(1),
  },
}));

export const UserInfoCard = ({ member }) => {
  const classes = useStyle();
  return (
    <>
      <Grid xs={4} item container>
        <Grid xs={3} item container>
          <Avatar
            src={member?.imageUrl}
            alt={member?.firstName?.toUpperCase()}
            className={classes.img}
            style={
              member?.imageUrl === member?.firstName ? { fontSize: "48px" } : {}
            }
          />
        </Grid>

        <Grid xs={8} item container>
          <Grid
            xs={12}
            item
            container
            justify="flex-start"
            alignItems="flex-end"
          >
            <Typography
              className={`${classes.blackFont} ${classes.memberName}`}
            >{`${member?.firstName} ${member?.lastName}`}</Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Typography
              className={`${classes.blackFont} ${classes.memberMail}`}
            >
              {`${member?.emailId}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
