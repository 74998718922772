function datediff(first: Date, second: Date) {
  return Math.round(
    (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export function getDateInActivityFormat(date: Date) {
  let diff = datediff(date,new Date());
  let str: string = "";
  if(diff === 0) str += "Today, "
  else if(diff === 1) str += "Yesterday, "
  else str += `${diff} days ago, `
  str += date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
  str += ":";
  str += date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
  return str;
}

export function getTimeInDesiredFormat(date: Date) {
  let diff = datediff(date,new Date());
  let str: string = "";
  if(diff === 0) str += "today, "
  else if(diff === 1) str += "yesterday, "
  else str += `${diff} days ago, `
  str += "at "
  str += date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
  str += ":";
  str += date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
  return str;
}
