import React from "react";
import { RenderAttributesDetails } from "../components/list/detail/DetailComponent";
import jwt_decode from "jwt-decode";
import { TokenDataProps } from "../home/Drawer";
import { useSelector } from "react-redux";
import { State } from "../../type";
import { FAQ_DATA } from "../constants/FAQQuestionsData";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FAQComponentProps } from "./type";

const useStyle = makeStyles((theme) => ({
  container: {
    border: "1px solid #DCDCDC",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px 4px rgba(191,191,191,0.3)",
    padding: theme.spacing(5, 7.5),
    margin:theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  text: {
    fontSize: theme.spacing(5),
    color: "#272727",
    fontWeight: 800,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
    },
  },
  text_container: {
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  extra_style: {
    padding: theme.spacing(3, 5),
  },
}));

export const FAQComponent: React.FC<FAQComponentProps> = ({ setOpen }) => {
  const classes = useStyle();
  const token = useSelector((state: State) => state.auth.token);

  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    status: "ACTIVE",
  };
  try {
    tokenData = jwt_decode(token);
  } catch (err) {
    console.error((err as Error).message);
  }

  const faqs = FAQ_DATA;

  const renderFAQHeader = () => (
    <Grid
      item
      xs={12}
      container
      justify="center"
      alignItems="center"
      className={classes.text_container}
    >

<Grid item xs={12} container justify="flex-end">
        <Grid>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Grid item>
          <Typography className={classes.text}>FAQs</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderFAQAccordions = () => (
    <Grid xs={12} item container className={classes.container}>
      <RenderAttributesDetails
        attributes={[]}
        from={"FAQ"}
        faqs={
          tokenData.role === "ADMIN"
            ? faqs
            : faqs.filter((q) => q.to === "user")
        }
      ></RenderAttributesDetails>
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      className={classes.extra_style}
      alignItems="flex-start"
      justify="center"
    >
      {renderFAQHeader()}
      {renderFAQAccordions()}
    </Grid>
  );
};
