import { Chip, Divider, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import React from "react";
import { AttributeChipProps } from "../../../../type";
import { AdditionalTextAlert, SelectColleaguesProps, UserRole } from "./type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(2, 2, 1, 1),
      borderColor: "rgba(87, 107, 234, 0.5)",
      padding: theme.spacing(2, 0),
    },
    dashboardChip: {
      margin: theme.spacing(0, 0.5, 1 , 0.5),
      borderColor: "rgba(87, 107, 234, 0.5)",
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 0.25, 1, 0.25),
        padding: theme.spacing(0.5, 0),
      },
    },
    typography_h3: {
      color: "#272727",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    typography_body_1: {
      textTransform: "none",
      color: "#868686",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    typography_body_2: {
      color: "#868686",
      marginTop: theme.spacing(3),
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    typography_body_3: {
      color: "#A0A0A0",
      fontSize: "14px",
      marginTop: theme.spacing(0.25),
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
      },
    },
    typography_body_4: {
      color: "#000000",
      fontWeight: 500,
    },
    attributeChipFont: {
      fontSize: theme.spacing(1.5),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1),
      }
    },
    attributeChipFont2: {
      fontSize: theme.spacing(1.75),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.25),
      }
    },
    divider: {
      backgroundColor: "#576bea66",
      width: "inherit",
      margin: theme.spacing(5, 0, 4, 0),
    },
  })
);

export const ChooseMemberText: React.FC<UserRole> = ({ role }) => {
  const classes = useStyles();
  return (
    <Typography gutterBottom variant="h2" className={classes.typography_h3}>
      {role === "EMPLOYEE" ? "Choose Member" : "Add/Choose Client"}
    </Typography>
  );
};

export const SelectColleagues: React.FC<SelectColleaguesProps> = ({
  isAnyUser,
  role,
}) => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.typography_body_1}
    >
      {role === "EMPLOYEE" && (
        <>
          {isAnyUser && (
            <span style={{ color: "#DC0101" }}>
              Select one or more colleagues to send request
            </span>
          )}
          {!isAnyUser && "Select one or more colleagues to send request"}
        </>
      )}
      {role === "CLIENT" && (
        <>
          {isAnyUser && (
            <span style={{ color: "#DC0101" }}>
              Select one or more clients to send request
            </span>
          )}
          {!isAnyUser && "Select one or more clients to send request"}
        </>
      )}
    </Typography>
  );
};

export const ContextText: React.FC<UserRole> = ({ role }) => {
  const classes = useStyles();
  return (
    <>
      {role === "EMPLOYEE" && (
        <Typography gutterBottom variant="h2" className={classes.typography_h3}>
          Context
        </Typography>
      )}
      {role === "CLIENT" && (
        <Typography gutterBottom variant="h2" className={classes.typography_h3}>
          {"Context "}
          <span style={{ color: "#DC0101", fontSize: "14px" }}>(required)</span>
        </Typography>
      )}
    </>
  );
};

export const OptionalText = () => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.typography_body_3}>
      (optional)
    </Typography>
  );
};

export const AdditionalInfoText: React.FC<AdditionalTextAlert> = ({
  alert,
}) => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.typography_body_1}
    >
      {!alert && "Additional information to share with the feedback provider"}
      {alert && (
        <span style={{ color: "#DC0101" }}>
          Additional information to share with the feedback provider
        </span>
      )}
    </Typography>
  );
};

export const AdditionalInfoHintText = () => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.typography_body_1}
    >
      Additional information to share with the feedback receiver
    </Typography>
  );
};

export const SelectAttributesText = () => {
  const classes = useStyles();
  return (
    <Typography gutterBottom variant="h2" className={classes.typography_h3}>
      Select Attributes
    </Typography>
  );
};

export const AttributeSelectionText = () => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.typography_body_1}
    >
      Select attributes on which you would like to receive feedback
    </Typography>
  );
};

export const AttributeSelectionNoteText = () => {
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.typography_body_2}>
      Note: The person giving the feedback can add to or remove the attributes
      that you’ve selected
    </Typography>
  );
};

export const AttributeChips: React.FC<AttributeChipProps> = ({
  attributes,
  selectedAttributeIds,
  setSelectedAttributeIds,
  from
}) => {
  const classes = useStyles();

  function handleAttributeSelect(attributeId: number) {
    if (selectedAttributeIds.includes(attributeId)) {
      setSelectedAttributeIds(
        selectedAttributeIds.filter((item) => item !== attributeId)
      );
    } else {
      setSelectedAttributeIds(selectedAttributeIds.concat(attributeId));
    }
  }

  return (
    <Grid container alignItems="flex-start" direction="row">
      {attributes.map((attr) => (
        <Chip
          key={attr.id}
          icon={
            selectedAttributeIds.includes(attr.id) ? (
              <StarIcon style={{color: "#576bea"}} />
            ) : (
              <StarBorderIcon style={{color: "#576bea"}}/>
            )
          }
          color="primary"
          variant="outlined"
          label={
            from === "DASHBOARD" ? 
            <Typography className={`${classes.typography_body_4} ${classes.attributeChipFont}`}>
              {attr.name}
            </Typography> : 
            <Typography className={`${classes.typography_body_4} ${classes.attributeChipFont2}`}>
              {attr.name}
            </Typography>
          }
          className={""+ from==="DASHBOARD" ? `${classes.dashboardChip}`: `${classes.chip}`}
          onClick={() => handleAttributeSelect(attr.id)}
          style={
            selectedAttributeIds.includes(attr.id)
              ? { backgroundColor: "rgba(87,107,234,0.1)" }
              : { backgroundColor: "#FFFFFF" }
          }
        />
      ))}
    </Grid>
  );
};

export const FormDivider = () => {
  const classes = useStyles();
  return <Divider className={classes.divider}></Divider>;
};
