import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../../type";

const useStyles = makeStyles((theme) => ({
  commonFontStyle: {
    textAlign: "justify",
    fontFamily: "Montserrat",
    color: "#272727",
  },
  content: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    marginTop: theme.spacing(1.25),
  },
}));

export const GuidelineText = () => {
  const classes = useStyles();
  const user = useSelector(
    (state: State) => state.attributesDataForClient.data?.employee
  );

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid xs={12} item>
        <Typography className={`${classes.content} ${classes.commonFontStyle}`}>
          Welcome to Grow-Wize.
          <span style={{ fontWeight: 600 }}>
            {user && " " + user.firstName + " " + user.lastName}
          </span>{" "}
          has invited you to share your valuable feedback. We urge you to share
          your candid feedback. Please note the following points:
          <br /> 1. Fields with <span style={{ color: "#DC0101" }}>*</span> are
          mandatory.
          <br />
          2. Attribute(s) are pre-selected.
          <br />
          3. You can Add new attribute(s) or remove pre-selected attribute(s).
          <br />
          4. Select 'SKIP' option, if you dont want to rate{" "}
          <span style={{ fontWeight: 600 }}>
            {user && " " + user.firstName + " " + user.lastName}
          </span>{" "}
          on a question
        </Typography>
      </Grid>
    </Grid>
  );
};
