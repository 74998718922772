import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShareFeedbackByClientSliceProps } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";

export const shareFeedbackByClient = createAsyncThunk(
  "client/feedback/share",
  async (params: ShareFeedbackByClientSliceProps) => {
    try {
      const authToken: string = JSON.parse(
        JSON.parse(localStorage.getItem("persist:root") ?? " ").auth
      ).token;
      const options = {
        method: "post",
        headers: {
          Accept: "application/text",
          "Content-Type": "application/json",
          authToken: authToken,
        },
        body: JSON.stringify(params.requestData),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/external/feedback?senderId=${params.senderId}`,
        options
      );
      if (response.status === HttpStatus.CREATED) {
        return {
          status: HttpStatus.CREATED,
        };
      }
      return {
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  }
);

const shareFeedbackByClientSlice = createSlice({
  name: "shareFeedbackByClient",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      shareFeedbackByClient.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(shareFeedbackByClient.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(shareFeedbackByClient.rejected, (state) => {
      state.status = 503;
      state.loading = false;
    });
  },
});

export const { clearData } = shareFeedbackByClientSlice.actions;

export default shareFeedbackByClientSlice;
