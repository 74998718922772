import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import { ErrorPopUp } from "../../components/ErrorPages/errorModal";
import { ClearAllData } from "../../constants/clearData";
import {
  AddAttributeText,
  AddAttributeTitleText,
  ChooseMemberTextForShareFeedback,
  SelectColleague,
} from "../../constants/shareFeedbackText";
import { ShareFeedbackLayout } from "../../../layouts/user/share_feedback/Layout";
import { setUnsavedChanges, shareFeedback } from "../../../redux/slices/ShareFeedbackSlice";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import {
  AttributeQuestionEmoji,
  FeedbackDetailAPICallProps,
  ResponseAttribute,
  ShareFeedbackAttribute,
  ShareFeedbackProps,
  State,
  User,
  UserAndAttributeSliceProps,
} from "../../../type";
import {
  AdditionalInfoHintText,
  ContextText,
  OptionalText,
} from "../request_feedback/request_employee/requestFeedbackText";
import { AddNewButton } from "./addNewButton";
import { AddButton, CancelButton, SendButton } from "./buttons";
import { ConsentModal } from "../request_feedback/request_employee/popUp";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "./style.css";
import { sharedDraftDetail } from "../../../redux/slices/SharedDraftDetailSlice";
import { clearData as clearSharedDraftDetail } from "../../../redux/slices/SharedDraftDetailSlice";

import { clearData as clearSubmitFeedback } from "../../../redux/slices/SubmitFeedbackSlice";
import { TabLabels } from "./tabLabels";
import { useCustomStyle } from "../../../styles";
import { appColor } from "../../constants/customThemeColor";
import { submitFeedback } from "../../../redux/slices/SubmitFeedbackSlice";
import { ValidationPromptModal } from "../../components/ValidationModal";
import { EditorForText, RemainingCharWarnText } from "./addNewButtonElements";
import { AttributePromptModal } from "../../../layouts/user/share_feedback/AttributesPromptModal";

const useStyle = makeStyles((theme) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  modalGrid: {
    width: theme.spacing(70),
    height: theme.spacing(35),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(8, 0, 4, 0),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(40),
      height: theme.spacing(25),
      padding: theme.spacing(6, 0, 6, 0),
    },
  },
  modalErrorText: {
    color: `${appColor.activeRegularColor}`,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(2),
    },
  },
  typography_body_context: {
    textAlign: "justify",
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5, 0.5, 0.5, 0.5),
    },
  },
  btn1: {
    padding: theme.spacing(1.375, 4.25, 1.375, 4.25),
    backgroundColor: `${appColor.regularBackgroundColor}`,
    "&:hover": {
      backgroundColor: `${appColor.regularBackgroundColor}`,
    },
    color: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
  editor: {
    width: "-webkit-fill-available",
  },
  editor1: {
    width: "-moz-available",
  },
}));

export const ShareFeedback = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const customClasses = useCustomStyle();

  const [logout, setLogout] = React.useState(false);
  let { id } = props.match.params;
  const tabToIndex = {
    default: 0,
    drafts: 1,
  };
  const [tabValue, setTabValue] = React.useState<number>(
    id ? (tabToIndex[id] ? tabToIndex[id] : 0) : 0
  );
  const [selectedUser, setSelectedUser] = React.useState<User>();
  const [attributes, setAttributes] = React.useState<ResponseAttribute[]>([]);
  const [shareFeedbackData, setShareFeedbackData] = React.useState<
    ShareFeedbackAttribute[]
  >([]);
  const [emojiSelected, setEmojiSelected] = React.useState<
    Array<AttributeQuestionEmoji>
  >([]);
  const [showModal, setShowModal] = React.useState(false);
  const [index, setIndex] = React.useState<number>(-1);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [dispatched, setDispatched] = React.useState(false);
  const [requestId, setRequestId] = React.useState<number | null>(null);
  const [isUserSelected, setIsUserSelected] = React.useState<Boolean>(false);
  const [requestedUserId, setRequestedUserId] = React.useState<number>(
    parseInt(id)
  );
  const [dataCleared, setDataCleared] = React.useState<boolean>(false);
  const [isMissing, setMissing] = React.useState<boolean>(false);
  const [missingElement, setMissingElement] = React.useState<string | null>(null);
  const [isDraftLoad, setIsDraftLoad] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isAnyChange, onAnyChange] = React.useState<boolean>(false);
  const [isDataChange, onDataChange] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(-2);
  const [isValid, setIsValid] = React.useState<number>(0);
  const [isAnyAttribute, setIsAnyAttribute] = React.useState<boolean>(false);
  const [charLimitExceeded, setCharLimitExceeded] =
    React.useState<Boolean>(false);

  const [dataLoadingFailed, setDataLoadingFailed] =
    React.useState<boolean>(false);
  const statusForData = useSelector((state: State) => state.data.status);
  const status = useSelector((state: State) => state.shareFeedback.status);
  const loading = useSelector((state: State) => state.shareFeedback.loading);
  const attr: Array<ResponseAttribute> = [];
  const attLabels =
    useSelector((state: State) => state.data.data?.attributes) ?? attr;
  const finalStatus = useSelector(
    (state: State) => state.submitFeedback.status
  );
  const finalLoading = useSelector(
    (state: State) => state.submitFeedback.loading
  );
  const [context, setContext] = React.useState<string>("");
  const [isContext, setIsContext] = React.useState<Boolean>(false);
  const [finalSubmit, setFinalSubmit] = React.useState<Boolean>(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [selectedDraftId, setSelectedDraftId] = React.useState<number>(-1);
  const [showDraftWithId, setShowDraftWithId] = React.useState<number>(-1);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isLock, setLock] = React.useState<boolean>(false);
  const [openAttributePromptModal, setOpenAttributePromptModal] = React.useState<boolean>(false)
  const [savedDraftDate, setDraftDate] = React.useState<Date>(new Date());
  let checkAttributes = [] as ResponseAttribute[];
  attLabels.forEach(attribute => {
    if (attribute.isPrimaryAttribute) {
      checkAttributes.push(attribute);
    }
  });
  const [promptAttributes, setPromptAttributes] = React.useState<ResponseAttribute[]>(checkAttributes);

  const sessionExpired = useSelector((state: State) => state.auth.isExpired);

  const param: UserAndAttributeSliceProps = {
    isUsers: true,
    isAttributes: true,
    isClients: false,
    isDrafts: true,
    isRequests: true,
    isScale: true,
  };

  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.shareFeedback.status !== 201
        ? state.shareFeedback.status
        : state.submitFeedback.status !== 201
          ? state.submitFeedback.status
          : state.sharedDraftDetail.status !== 200
            ? state.sharedDraftDetail.status
            : state.savedDrafts.status !== 200
              ? state.savedDrafts.status
              : null
  );
  useEffect(() => {
    if (!isUserSelected) {
      setEditorState(EditorState.createEmpty());
      setMissing(false);
      dispatch(clearSharedDraftDetail());
    }
  }, [isUserSelected]);

  useEffect(() => {
    if (isAnyChange) {
      onDataChange(true);
      setIsDirty(true);
    }
  }, [isAnyChange]);

  useEffect(() => {
    if (!isLock) {
      onDataChange(true);
      setIsDirty(true);
    }

    shareFeedbackData.forEach(attribute => {
      let requiredAttribute = checkAttributes.findIndex(a => attribute.attributeId == a.id);
      if (requiredAttribute != -1) {
        checkAttributes.splice(requiredAttribute, 1)
      }
    });
    setPromptAttributes(checkAttributes);

  }, [shareFeedbackData]);

  useEffect(() => {
    if (isLock) {
      onDataChange(false);
      onAnyChange(false);
      setIsDirty(false);
      setTimeout(() => {
        setLock(false);
      }, 1000);
    }
  }, [isLock]);

  useEffect(() => {
    dispatch(requestData(param));
    dispatch(clearSharedDraftDetail());
  }, []);

  useEffect(() => {
    if (selectedDraftId > 0) {
      window.history.pushState(
        selectedUser?.id,
        document.title,
        `/feedback/share/${selectedUser?.id}`
      );
      let params: FeedbackDetailAPICallProps = {
        feedbackId: selectedDraftId,
      };
      dispatch(sharedDraftDetail(params));
      setShareFeedbackData([]);
    }
  }, [selectedDraftId]);

  const history = useHistory();
  const goToPreviousPath = () => {
    if (history.length > 10) {
      history.goBack();
    } else {
      history.push("/");
    }
  };
  const logoutUser = () => {
    setLogout(true);
  };

  const renderTabLabels = () => (
    <TabLabels
      tabValue={tabValue}
      setTabValue={setTabValue}
      props={props}
      setMissing={setMissing}
    ></TabLabels>
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    setContext(draftToHtml(convertToRaw(state.getCurrentContent())));
    onAnyChange(true);
  };

  useEffect(() => {
    if (
      statusForData !== null &&
      statusForData !== 200 &&
      statusForData !== 201
    ) {
      setDataLoadingFailed(true);
      setShowErrorModal(true);
    }
  }, [dataLoadingFailed, showErrorModal, statusForData]);

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={showErrorModal}
      setShowModal={setShowErrorModal}
      statusCode={statusCode}
      handleNoAuthorization={logoutUser}
      handleAccessDenied={goToPreviousPath}
    ></ErrorPopUp>
  );

  const renderModal = () => (
    <ConsentModal
      showModal={showModal}
      setShowModal={setShowModal}
      setValue={setValue}
      to={"SHARE_FEEDBACK"}
    ></ConsentModal>
  );

  const renderChooseMemberText = () => <ChooseMemberTextForShareFeedback />;

  const renderSelectColleagues = () => <SelectColleague isValid={isValid} />;

  const renderAddButton = () => (
    <AddButton
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      attributes={attributes}
      setAttributes={setAttributes}
      setContext={setContext}
      setIsContext={setIsContext}
      emojiSelected={emojiSelected}
      setEmojiSelected={setEmojiSelected}
      shareFeedbackData={shareFeedbackData}
      setShareFeedbackData={setShareFeedbackData}
      setRequestId={setRequestId}
      requestedUserId={requestedUserId}
      setRequestedUserId={setRequestedUserId}
      isUserSelected={isUserSelected}
      setIsUserSelected={setIsUserSelected}
      selectedDraftId={selectedDraftId}
      setSelectedDraftId={setSelectedDraftId}
      showDraftWithId={showDraftWithId}
      setShowDraftWithId={setShowDraftWithId}
      setIsDraftLoad={setIsDraftLoad}
      isDraftLoad={isDraftLoad}
      index={index}
      setIndex={setIndex}
      setLock={setLock}
      setEditorState={setEditorState}
    ></AddButton>
  );

  const renderContext = () => <ContextText role={"EMPLOYEE"}></ContextText>;

  const renderContextOptionText = () => <OptionalText></OptionalText>;

  const renderContextHintText = () => (
    <AdditionalInfoHintText></AdditionalInfoHintText>
  );

  const renderTextBox = () => (
    <>
      {!isContext && (
        <>
          <div className={`${classes.editor1} ${classes.editor}`}>
            <EditorForText
              idx={1}
              textHeader={"main-context"}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
            />
          </div>

          <Grid container>
            <Grid item xs={12}>
              <RemainingCharWarnText text={context} limit={10000} />
            </Grid>
          </Grid>
        </>
      )}
      {isContext && (
        <Typography
          variant="h6"
          className={`${customClasses.typography_font_14_10} ${classes.typography_body_context}`}
          dangerouslySetInnerHTML={{
            __html: context,
          }}
        />
      )}
    </>
  );

  const renderAddAttributeTitleText = () => <AddAttributeTitleText />;

  const renderAddAttributeText = () => (
    <AddAttributeText isAnyAttribute={isAnyAttribute} />
  );

  const renderAddNewButton = () => (
    <AddNewButton
      attributes={attributes}
      setAttributes={setAttributes}
      shareFeedbackData={shareFeedbackData}
      setShareFeedbackData={setShareFeedbackData}
      emojiSelected={emojiSelected}
      setEmojiSelected={setEmojiSelected}
      setValue={setValue}
      onAnyChange={onAnyChange}
      isMissing={isMissing}
      attLabels={attLabels}
      from={'EMPLOYEE'}
    />
  );

  const renderCancelButton = () => (
    <CancelButton setValue={setValue}></CancelButton>
  );

  const renderSendButton = () => <SendButton></SendButton>;
  const renderRouterPrompt = () =>  {
     isDirty ? dispatch(setUnsavedChanges(true)) : dispatch(setUnsavedChanges(false))
    return null;
  };

  useEffect(() => {
    if (dispatched === true && finalStatus === 201 && finalLoading === false) {
      setIsDirty(false);
      setSelectedUser(undefined);
      setRequestedUserId(parseInt("default"));
      setContext("");
      setShareFeedbackData([]);
      setShowModal(true);
      setDispatched(false);
      setFinalSubmit(false);
      dispatch(requestData(param));
      dispatch(clearSubmitFeedback());
    }
  }, [dispatched, finalLoading, finalStatus]);

  useEffect(() => {
    if (
      dispatched === true &&
      status === 201 &&
      loading === false &&
      value === 6
    ) {
      const timer = setTimeout(() => {
        if (loading === false && value === 6) {
          setValue(-1);
          setDraftDate(new Date());
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatched, loading, status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading === false && value === 6) {
        setValue(-1);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [loading, value]);

  useEffect(() => {
    if (selectedUser && selectedUser?.id > 0 && shareFeedbackData.length > 0) {
      setValue(-1);
    } else {
      setValue(-2);
      setIsDirty(false);
    }
  }, [selectedUser, shareFeedbackData]);

  useEffect(() => {
    if (statusCode && statusCode !== 200 && statusCode !== 201) {
      setIsDirty(false);
      setShowErrorModal(true);
    }
  }, [statusCode]);

  useEffect(() => {
    if (status && status !== 201) {
      setValue(5);
    }
  }, [status, loading]);

  useEffect(() => {
    if (value === 6) {
      handleSubmit(window.event);
    }
  }, [value]);

  const AUTO_SAVE_INTERVAL = 30000;

  useEffect(() => {
    if (!isDataChange) {
      setIsDirty(false);
    }
    const timer = setInterval(() => {
      if (!loading && tabValue === 0 && isDataChange) {
        setValue(6);
        onDataChange(false);
        onAnyChange(false);
        setIsDirty(false);
      }
    }, AUTO_SAVE_INTERVAL);
    return () => clearInterval(timer);
  }, [isDataChange, loading, tabValue]);

  const handleErrors = () => {
    if (selectedUser) {
      setIsValid(0);
    } else {
      setIsValid(1);
    }
    if (shareFeedbackData.length === 0 && selectedUser) {
      setIsAnyAttribute(true);
    } else {
      setIsAnyAttribute(false);
    }
  };

  useEffect(() => {
    if (!open && isMissing && missingElement) {
      let val = document.getElementById(missingElement)?.offsetTop;
      let HEADER_HEIGHT: number | undefined = 300;
      window.scrollTo({
        top: val ? (val - HEADER_HEIGHT) : val,
        behavior: 'smooth',
      })
    }
  }, [open, isMissing, missingElement])

  const handleOpen = () => {
    setFinalSubmit(false);
    setOpen(true);
  };

  const renderValidationModal = () => (
    <ValidationPromptModal
      open={open}
      setOpen={setOpen}
      charLimitExceeded={charLimitExceeded}
    />
  );

  const handleCancelModal = () => {
    setOpenAttributePromptModal(false);
  }

  const handleSendAnyway = (e) => {
    setOpenAttributePromptModal(false);
    handleSubmitFeedback(e);
  }

  const renderPromptModal = () => (
    <AttributePromptModal
      open={openAttributePromptModal}
      handleCancel={handleCancelModal}
      handleSendAnyway={handleSendAnyway}
      disableActions={false}
      promptAttributes={promptAttributes}
    />
  );


  function handleSubmit(e) {
    e?.preventDefault();
    onAnyChange(false);
    setCharLimitExceeded(false);
    let checkUser = false;
    if (selectedUser === undefined || !selectedUser) {
      checkUser = true;
    }

    let flag = false;
    let attributesLength = false;
    if (shareFeedbackData.length === 0) {
      attributesLength = true;
    } else {
      if (context.length > 10000) {
        flag = true;
        setCharLimitExceeded(true);
      }
      for (let idx = 0; idx < shareFeedbackData.length; idx++) {
        for (
          let idx2 = 0;
          idx2 < shareFeedbackData[idx].questions.length;
          idx2++
        ) {
          if (
            shareFeedbackData[idx].questions[idx2]?.answer?.length > 10000
          ) {
            flag = true;
            setCharLimitExceeded(true);
          }
        }
        if (
          shareFeedbackData[idx].context?.length > 10000 ||
          shareFeedbackData[idx].resources?.length > 10000 ||
          shareFeedbackData[idx].suggestions?.length > 10000
        ) {
          flag = true;
          setCharLimitExceeded(true);
        }
      }
    }
    if (tabValue === 0 && !finalSubmit) {
      if (flag || checkUser || attributesLength) {
        if ((checkUser || attributesLength) && value !== 6) {
          handleErrors();
        } else if (flag) {
          handleOpen();
        }
      } else {
        if (selectedUser) {
          const arg: ShareFeedbackProps = {
            receiverId: selectedUser.id,
            attributes: shareFeedbackData,
            requestId: requestId,
            context: context.replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim(),
            status: "DRAFTED",
          };
          dispatch(shareFeedback(arg));
          setDispatched(true);
        }
      }
    }
  }

  function handleSubmitFeedback(e) {
    if (selectedUser) {
      const arg: ShareFeedbackProps = {
        receiverId: selectedUser.id,
        attributes: shareFeedbackData,
        requestId: requestId,
        context: context.replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim(),
        status: "SUBMITTED",
      };
        dispatch(submitFeedback(arg));
        setMissing(false);
        setDispatched(true);
    }
  }

  function handleSubmit2(e) {
    setFinalSubmit(true);
    e?.preventDefault();
    let regex = /(<([^>]+)>)/ig
    setCharLimitExceeded(false);
    let checkUser = false;
    if (selectedUser === undefined || !selectedUser) {
      checkUser = true;
    }

    let flag = false;
    let attributesLength = false;
    if (shareFeedbackData.length === 0) {
      attributesLength = true;
    } else {
      if (context.length > 10000) {
        flag = true;
        setCharLimitExceeded(true);
      }
      for (let idx = 0; idx < shareFeedbackData.length; idx++) {
        for (
          let idx2 = 0;
          idx2 < shareFeedbackData[idx].questions.length;
          idx2++
        ) {
          if (
            value !== 6 &&
            shareFeedbackData[idx].questions[idx2].answer?.length < 1
          ) {
            flag = true;
            setMissing(true);
            setMissingElement(`que-id-${shareFeedbackData[idx].questions[idx2].questionId}`);
            break;
          } else {
            let ans = shareFeedbackData[idx].questions[idx2].answer;
            if (
              value !== 6 &&
              (!ans?.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g, '').replace(/\n/g, '').length || ans.length < 0)
            ) {
              flag = true;
              setMissing(true);
              setMissingElement(`que-id-${shareFeedbackData[idx].questions[idx2].questionId}`);
              break;
            } else if (
              shareFeedbackData[idx].questions[idx2].answer?.length > 10000
            ) {
              flag = true;
              setCharLimitExceeded(true);
              break;
            }
          }
        }
        if (flag) break;
        let str = shareFeedbackData[idx].context.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g, '').replace(/\n/g, '');
        if (str.length < 2 && value !== 6) {
          flag = true;
          setMissing(true);
          setMissingElement(`explanation-id-${shareFeedbackData[idx].attributeId}`);
        } else if (shareFeedbackData[idx].context?.length < 9 && value !== 6) {
          flag = true;
          setMissing(true);
          setMissingElement(`explanation-id-${shareFeedbackData[idx].attributeId}`);
        } else if (
          shareFeedbackData[idx].context?.length > 10000 ||
          shareFeedbackData[idx].resources?.length > 10000 ||
          shareFeedbackData[idx].suggestions?.length > 10000
        ) {
          flag = true;
          setCharLimitExceeded(true);
        }
        if (flag) break;
      }
    }
    if (tabValue === 0) {
      if (flag || checkUser || attributesLength) {
        if ((checkUser || attributesLength) && value !== 6) {
          handleErrors();
        } else if (flag) {
          handleOpen();
        }
      }
      else if (open === false) {
        if (promptAttributes?.length > 0) {
          setOpenAttributePromptModal(true);
          return;
        } else {
          handleSubmitFeedback(e);
        }
      }
    }
  }

  if (value === 0) {
    return <Redirect to="/home" />;
  }

  if (id === ":id" || (value === 1 && id !== "default")) {
    return <Redirect to="/feedback/share/default" />;
  }

  if (logout && !dataCleared) ClearAllData(setDataCleared);

  if (logout && dataCleared) {
    history.push({ pathname: "/", state: { path: sessionExpired ? props.location.pathname : null } });
  }

  return (
    <ShareFeedbackLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      value={value}
      setValue={setValue}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      setShowDraftWithId={setShowDraftWithId}
      isContext={isContext}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      isUserSelected={isUserSelected}
      isDataChange={isDataChange}
      onDataChange={onDataChange}
      savedDraftDate={savedDraftDate}
      tabValue={tabValue}
      setTabValue={setTabValue}
      setSelectedDraftId={setSelectedDraftId}
      renderTabs={renderTabLabels}
      renderContext={renderContext}
      renderContextHintText={renderContextHintText}
      renderContextOptionText={renderContextOptionText}
      renderTextBox={renderTextBox}
      shareFeedbackData={shareFeedbackData}
      setShareFeedbackData={setShareFeedbackData}
      renderErrorModal={renderErrorModal}
      renderValidationModal={renderValidationModal}
      renderModal={renderModal}
      handleSubmit={handleSubmit2}
      renderChooseMemberText={renderChooseMemberText}
      renderSelectColleagues={renderSelectColleagues}
      renderAddButton={renderAddButton}
      renderAddAttributeTitleText={renderAddAttributeTitleText}
      renderAddAttributeText={renderAddAttributeText}
      renderAddNewButton={renderAddNewButton}
      renderCancelButton={renderCancelButton}
      renderSendButton={renderSendButton}
      renderRouterPrompt={renderRouterPrompt}
      setAttributes={setAttributes}
      setIsContext={setIsContext}
      renderPromptModal={renderPromptModal}
    ></ShareFeedbackLayout>
  );
};
