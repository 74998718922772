export const appColor = {
  "activeRegularColor": "#576BEA",
  "regularBackgroundColor": "#576BEA",
  "whiteBackground": "#FFFFFF",
  "regularBorder": "#576BEA",
  "pureWhite": "#FFFFFF",
  "pureBlack": "#272727",
  "themeBlueHeader": "#576BEA",
  "customError": "#c40808",
  "darkGray": "#868686",
  "lightGrey": "#A0A0A0",
  "redWarn": "#DC0101",
  "adminThemeColor": "#7C69EC",
}
