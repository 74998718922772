import {
  Avatar,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import {
  AddUserButtonProps,
  CancelButtonProps,
  Requests,
  ResponseAttribute,
  ResponseScaleProp,
  SharedDraftProps,
  State,
  User,
} from "../../../type";
import { SharedFeedbackDetailData } from "../shared_feedback/typeSharedFeedbacks";
import {
  updateEmojiAndAttributeOnClick,
  updateEmojiAndAttributeWithDraftData,
} from "./addNewButtonHandles";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,} from "draft-js";

const useStyle = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(2, 1, 0, 1),
    backgroundColor: `${appColor.whiteBackground}`,
    borderColor: `${appColor.regularBorder}`,
  },
  btn_: {
    margin: theme.spacing(3, 5, 0, 0),
    borderColor: `${appColor.pureWhite}`,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3.5, 0, 0),
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
  },
  typography_body_4: {
    alignItems: "center",
    color: `${appColor.pureWhite}`,
  },
  draftIcon: {
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(2),
      width: theme.spacing(3.5),
    },
  },
}));

export const AddButton: React.FC<AddUserButtonProps> = ({
  selectedUser,
  setSelectedUser,
  attributes,
  setAttributes,
  emojiSelected,
  setContext,
  setIsContext,
  setEmojiSelected,
  shareFeedbackData,
  setShareFeedbackData,
  setRequestId,
  requestedUserId,
  setRequestedUserId,
  isUserSelected,
  setIsUserSelected,
  selectedDraftId,
  setSelectedDraftId,
  showDraftWithId,
  setShowDraftWithId,
  isDraftLoad,
  setIsDraftLoad,
  index,
  setIndex,
  setLock,
  setEditorState,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  const [userChanged, setUserChanged] = React.useState<number>(0);
  const [requestLoad, setRequestLoad] = React.useState<number>(0);
  const [requestIndex, setRequestIndex] = React.useState<number>(0);

  function handleDelete(id: number | undefined) {
    if (selectedUser !== undefined) {
      setSelectedUser(selectedUser.id !== id);
    }
  }

  const users: Array<User> = [];
  const requests: Array<Requests> = [];
  const attr: Array<ResponseAttribute> = [];
  const emptyDrafts: Array<SharedDraftProps> = [];
  const labels = useSelector((state: State) => state.data.data?.users) ?? users;
  const initialAttributes =
    useSelector((state: State) => state.data.data?.requests) ?? requests;
  const drafts =
    useSelector((state: State) => state.data.data?.drafts) ?? emptyDrafts;
  const attLabels =
    useSelector((state: State) => state.data.data?.attributes) ?? attr;
  const draftDetailData: SharedFeedbackDetailData =
    useSelector((state: State) => state.sharedDraftDetail.data) ?? null;
  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector((state: State) => state.data.data?.scaleResponseEntityList) ??
    emptyList;

  useEffect(() => {
    if (selectedUser && drafts && requestIndex < 2) {
      setAttributes([]);
      setIndex(-1);
      let isRequest = false;
      let isDraft = false;
      for (let j = 0; j < initialAttributes.length; j++) {
        if (
          initialAttributes[j].userId === selectedUser.id &&
          drafts.findIndex(
            (d) => d.requestId === initialAttributes[j].requestId
          ) === -1 &&
          showDraftWithId === -1
        ) {
          isRequest = true;
          setIndex(j);
          setRequestId(initialAttributes[j].requestId);
          if (initialAttributes[j].context.length > 8) {
            setContext(initialAttributes[j].context);
            setIsContext(true);
          }
          break;
        } else if (
          initialAttributes[j].userId === selectedUser.id &&
          drafts.findIndex(
            (d) => d.requestId === initialAttributes[j].requestId
          ) >= 0
        ) {
          const draft: SharedDraftProps | undefined = drafts.find(
            (d) => d.requestId === initialAttributes[j].requestId
          );
          if (draft !== undefined && showDraftWithId === -1) {
            isDraft = true;
            setSelectedDraftId(draft.id);
            setRequestId(draft.requestId);
            setShowDraftWithId(draft.id);
            setIsContext(true);
          }
          break;
        }
      }
      if (
        !isRequest &&
        drafts.filter((d) => d.receiverId === selectedUser.id).length === 0
      ) {
        setRequestId(null);
        setContext("");
        setIsContext(false);
      } else if (
        !isRequest &&
        drafts.find(
          (d) => d.receiverId === selectedUser.id && d.requestId === null
        ) &&
        !isDraft
      ) {
        setSelectedDraftId(
          drafts.filter(
            (d) => d.receiverId === selectedUser.id && d.requestId === null
          )[0].id
        );
        setRequestId(null);
      } else if (
        !isRequest &&
        drafts.find((d) => d.receiverId === selectedUser.id) &&
        !isDraft
      ) {
        if (showDraftWithId === -1) {
          setSelectedDraftId(
            drafts.filter((d) => d.receiverId === selectedUser.id)[0].id
          );
          setRequestId(
            drafts.filter((d) => d.receiverId === selectedUser.id)[0].requestId
          );
        } else {
          setSelectedDraftId(showDraftWithId);
          setRequestId(drafts.find((d) => d.id === showDraftWithId)?.requestId);
        }
      }
      if (isRequest && index < 0) {
        if (requestIndex === 0) setRequestIndex(1);
        else setRequestIndex(0);
      } else {
        setRequestIndex(3);
        setUserChanged(1);
      }
    }
  }, [selectedUser, requestIndex]);

  useEffect(() => {
    if (requestLoad < 2 && selectedUser) {
      if (index >= 0 && attributes.length === 0) {
        let att: Array<ResponseAttribute> = [];
        for (let i = 0; i < attLabels.length; i++) {
          for (
            let j = 0;
            j < initialAttributes[index].attributeIds.length;
            j++
          ) {
            if (attLabels[i].id === initialAttributes[index].attributeIds[j]) {
              att.push(attLabels[i]);
              setAttributes(att);
            }
          }
        }
      }
      updateEmojiAndAttributeOnClick(
        attributes,
        emojiSelected,
        shareFeedbackData,
        setEmojiSelected,
        setShareFeedbackData
      );
      setUserChanged(0);
      if (requestLoad === 0) {
        setRequestLoad(1);
      } else if (requestLoad === 1) {
        setRequestLoad(0);
      }
      if (
        (shareFeedbackData.length > 0 && requestLoad < 2) ||
        (index < 0 && requestIndex === 3)
      ) {
        setRequestLoad(3);
      }
    }
  }, [userChanged, requestLoad]);

  useEffect(() => {
    setShowDraftWithId(-1);
    if (selectedDraftId >= 0) {
      setLock(true);
      if (draftDetailData) {
        if (draftDetailData.context.length > 8) {
          setContext(draftDetailData.context);
          initialAttributes.map((req)=>{
            if(req.requestId === draftDetailData.requestId && req.requestId !== null && req.context.length > 8) {setIsContext(true);}
          })
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(draftDetailData.context).contentBlocks,
                convertFromHTML(draftDetailData.context).entityMap
              )
            )
          );
        }
        console.log("here draft data ",attributes);
        if (attributes.length === 0 && !isDraftLoad) {
          let att: Array<ResponseAttribute> = [];
          for (let j = 0; j < draftDetailData.attributes.length; j++) {
            for (let i = 0; i < attLabels.length; i++) {
              if (attLabels[i].name === draftDetailData.attributes[j].name) {
                att.push(attLabels[i]);
                setAttributes(att);
              }
            }
          }
        }
        updateEmojiAndAttributeWithDraftData(
          attributes,
          emojiSelected,
          shareFeedbackData,
          setEmojiSelected,
          setShareFeedbackData,
          draftDetailData?.attributes,
          setSelectedDraftId,
          responseScales
        );
        if (shareFeedbackData.length === draftDetailData?.attributes.length) {
          setIsDraftLoad(true);
          setSelectedDraftId(-1);
        }
      }
    }
  }, [userChanged, draftDetailData, selectedDraftId, attributes]);

  useEffect(() => {
      if (requestedUserId > 0) {
        for (let idx = 0; idx < labels.length; idx++) {
          if (labels[idx].id === requestedUserId) {
            setSelectedUser(labels[idx]);
            setRequestedUserId(0);
            break;
          }
        }
      }
  }, [labels]);

  useEffect(() => {
    if (selectedUser) {
      setIsUserSelected(true);
      window.history.pushState(
        selectedUser?.id,
        document.title,
        `/feedback/share/${selectedUser?.id}`
      );
    } else {
      setRequestIndex(0);
      setRequestLoad(0);
      setIsContext(false);
      setSelectedDraftId(-1);
      setIsDraftLoad(false);
      setShowDraftWithId(-1);
      setIsUserSelected(false);
      setShareFeedbackData([]);
      setAttributes([]);
    }
  }, [selectedUser]);

  return (
    <>
      <Grid container alignItems="flex-start" direction="row">
        {selectedUser && (
          <Chip
            variant="outlined"
            key={selectedUser?.id}
            avatar={
              <Avatar
                alt={selectedUser?.firstName.toUpperCase()}
                src={selectedUser?.imageUrl}
                className={customClasses.avatarSmall}
              />
            }
            label={
              <Typography
                className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_3}`}
              >
                {selectedUser?.firstName + " " + selectedUser?.lastName}
              </Typography>
            }
            className={classes.chip}
            onDelete={() => handleDelete(selectedUser?.id)}
          />
        )}
      </Grid>
      {!isUserSelected && (
        <Autocomplete
          id="tags-filled"
          className={`${customClasses.popper} ${customClasses.popperWidth} dashboard-autocomplete`}
          freeSolo
          value={selectedUser}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            if (typeof newValue !== "string") setSelectedUser(newValue);
          }}
          filterSelectedOptions
          disableCloseOnSelect
          options={labels}
          getOptionLabel={(option) => 
            option.firstName && option.firstName + " " + option.lastName
          }
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Grid container xs={12} alignItems="center">
                <Grid
                  item
                  xs={10}
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  <Avatar
                  alt={option.firstName.toUpperCase()}
                  src={
                    option.imageUrl
                  }
                    className={customClasses.avatarSmall}
                  />
                  <div className={classes.typography_body_3}>
                    <Typography
                      variant="body1"
                      className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_3}`}
                    >
                      {option.firstName + " " + option.lastName}
                    </Typography>
                  </div>
                </Grid>
                {drafts.find((d) => d.receiverId === option.id) && (
                  <Grid item xs={2} container justify="flex-end">
                    <img
                      src="/draftIcon.svg"
                      alt="Draft"
                      className={classes.draftIcon}
                    />
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search member by name"
              variant="outlined"
            />
          )}
        />
      )}
    </>
  );
};

export const SendButton = () => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  return (
    <Button
      type="submit"
      className={customClasses.sendCTA}
      color="primary"
      variant="contained"
    >
      <Typography
        className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_4}`}
      >
        Send
      </Typography>
    </Button>
  );
};

export const CancelButton: React.FC<CancelButtonProps> = ({ setValue }) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  async function handleCancel(e) {
    e.preventDefault();
    setValue(0);
  }
  return (
    <Button variant="outlined" onClick={handleCancel} className={classes.btn_}>
      <Typography
        className={customClasses.typography_font_14_10_600}
        style={{
          alignItems: "center",
          color: `${appColor.darkGray}`,
        }}
      >
        Cancel
      </Typography>
    </Button>
  );
};
