import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedbackListSliceProps, ReceivedFeedbacksSliceProp } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const receivedFeedbackListByMember = createAsyncThunk(
  "admin/feedback/received",
  async (requestParams: FeedbackListSliceProps, storeActions) => {
    const url = `api/v1/admin/member/detail/feedback/received?memberId=${requestParams.memberId}&offSet=${requestParams.offSet}&count=${requestParams.count}&requestTag=${requestParams.tag}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        feedbacks: await response.json(),
      };
    }
    return {
      status: response.status,
      feedbacks: null,
    };
  }
);

const receivedFeedbackListByMemberSlice = createSlice({
  name: "receivedFeedbackListByMember",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    feedbacks: null as null | ReceivedFeedbacksSliceProp,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.feedbacks = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      receivedFeedbackListByMember.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          status: number;
          feedbacks: ReceivedFeedbacksSliceProp | null;
        }>
      ) => {
        state.status = payload.status;
        state.loading = false;
        state.feedbacks = payload.feedbacks;
      }
    );
    builder.addCase(receivedFeedbackListByMember.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.feedbacks = null;
    });
    builder.addCase(receivedFeedbackListByMember.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.feedbacks = null;
    });
  },
});

export const { clearData } = receivedFeedbackListByMemberSlice.actions;

export default receivedFeedbackListByMemberSlice;
