import { makeStyles } from "@material-ui/core";

export const useSettingsStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    label: {
        color: "#272727",
        fontSize: 18,
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        columnGap: theme.spacing(0.5),
    },
    divider: {
        width: "100%",
        background: "#E7E7E7",
        height: 0.5,
        marginBottom: theme.spacing(4)
    },
    radioWrapper: {
        cursor: "pointer",
    },
    radioRoot: {
        color: '#272727',
        '&:hover': {
            backgroundColor: '#7C69EC10',
        },
    },
    radioChecked: {
        color: '#7C69EC !important',
        '&:hover': {
            backgroundColor: '#7C69EC10 !important',
        },
    },
    radioLabel: {
        color: "#272727",
        fontSize: 14,
        fontWeight: 500
    },
    footer: {
        marginTop: "auto",
    },
    saveButton: {
        height: "fit-content",
        background: "#7C69EC",
        textTransform: "uppercase",
        fontWeight: 600,
        fontFamily: "Montserrat",
        padding: "8px 40px",
        marginBottom: theme.spacing(5),
    },
    disabledButton: {
        pointerEvents: "none",
        backgroundColor: "#A0A0A0 !important",
        color: "#fff !important"
    },
    datePicker: {
        border: "1px solid #E7E7E7",
        padding: "6px 8px 6px 12px",
        borderRadius: 4,
        width: 190,
        cursor: "ponter !important",
    },
    tooltip: {
        background: "#272727",
        color: "#FFFFFF",
        fontSize: 14,
        fontFamily: "Montserrat",
        padding: "6px 10px"
    },
    tooltipArrow: {
        color: "#272727"
    },
    selectedDate: {
        "& > button": {
            background: "#7C69EC",
            color: "#FFFFFF"
        }
    },
    bannerContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    infoText: {
        color: "#A0A0A0",
        fontSize: 14,
        fontWeight: 400,
    }
}));

export const MESSAGES = {
    SAVE_REMINDER: "New setting saved successfully"
}
