import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestFeedbackListLayout } from "../../../../layouts/user/request_feedback/requestFeedbackListLayout";
import { countRequestSendFeedback } from "../../../../redux/slices/CountRequestsSendSlice";
import { getAllSendRequests } from "../../../../redux/slices/FeedbackRequestsSendSlice";
import {
  RequestingParams,
  SendRequest,
  State,
  User,
} from "../../../../type";
import { RequestFeedbackListProps } from "../typeRequestFeedback";
import { UserSearchBox } from "../../../components/UserSearchBoxComponent";
import { ChangePageButtonComponent } from "../../../components/changePageButtons";
import { RequestListProps } from "../../../components/type";
import { ListContainerComponent } from "../../../components/list/ListContainer";
import { CustomSelect } from "../../../components/CustomSelect";
import { DeleteAttributeCardComponent } from "../../../components/DeleteAttributeCard";
import { updateRequestStatus } from "../../../../redux/slices/UpdateRequestStatusSlice";

export interface CancelRequestParams {
  documentId: number;
  userId: number;
}

export const RequestFeedbackList: React.FC<RequestFeedbackListProps> = ({
  user,
  setUser,
  pageNumber,
  setPageNumber,
  setRequestedUserId,
  setRequestedFeedbackId,
  filterValue,
  setFilterValue,
}) => {
  const users = useSelector((state: State) => state.data.data?.users) ?? [];

  const [cancelRequestParam, setCancelRequestParam] =
    React.useState<CancelRequestParams>({
      documentId: 0,
      userId: 0,
    });
  const [open, setOpen] = React.useState<boolean>(false);
  const cancelReqStatus = useSelector(
    (state: State) => state.updateRequestStatus.status
  );

  const clients: Array<User> = useSelector(
    (state: State) => state.data.data?.clients ?? []
  );

  const dispatch = useDispatch();
  const emptyRequestArrray: Array<SendRequest> = [];

  useEffect(() => {
    if (cancelReqStatus === 200) {
      setCancelRequestParam({
        documentId: 0,
        userId: 0,
      });
    }
    let requestParams: string = "";
    requestParams += "?offSet=" + (pageNumber - 1) * 10;
    if (user !== null) requestParams += "&responderId=" + user.id;
    if (filterValue === 1) requestParams += "&requestTag=INTERNAL";
    else if (filterValue === 2) requestParams += "&requestTag=EXTERNAL";
    else requestParams += "&requestTag=ANY";
    const params: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(getAllSendRequests(params));
  }, [pageNumber, user, filterValue, cancelReqStatus]);

  const requests =
    useSelector((state: State) => state.sendRequests.data) ??
    emptyRequestArrray;

  const loading: Boolean = useSelector(
    (state: State) => state.sendRequests.loading
  );
  const listData: Array<RequestListProps> = requests.map((r) => {
    const obj: RequestListProps = {
      user: r.respondingUser,
      createdAt: r.timeOfRequest,
      attributesName: r.attributeNames,
      feedbackId: r.feedbackRequestId,
      feedbackRequestStatus: r.feedbackRequestStatus,
    };
    return obj;
  });

  useEffect(() => {
    let str: string = "";
    if (user) str += "?responderId=" + user.id;
    if (user) {
      if (filterValue === 1) str += "&requestTag=INTERNAL";
      else if (filterValue === 2) str += "&requestTag=EXTERNAL";
      else str += "&requestTag=ANY";
    } else {
      if (filterValue === 1) str += "?requestTag=INTERNAL";
      else if (filterValue === 2) str += "?requestTag=EXTERNAL";
      else str += "?requestTag=ANY";
    }
    const params: RequestingParams = {
      requestParams: str,
    };
    dispatch(countRequestSendFeedback(params));
  }, [user, filterValue]);

  useEffect(() => {
    if (
      cancelRequestParam.documentId > 0 &&
      cancelRequestParam.userId > 0 &&
      !open
    ) {
      setOpen(true);
    }
  }, [cancelRequestParam]);

  const count =
    useSelector((state: State) => state.countRequestsSend.data) ?? -1;

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let params =
      "?feedbackRequestId=" +
      cancelRequestParam.documentId +
      "&responderId=" +
      cancelRequestParam.userId;
    const param: RequestingParams = {
      requestParams: params,
    };
    dispatch(updateRequestStatus(param));
    setOpen(false);
  };

  const renderRequestCancelModal = () => (
    <DeleteAttributeCardComponent
      openModal={open}
      handleModalClose={handleModalClose}
      handleDelete={handleDelete}
      from={"SENT_REQUEST"}
    />
  );

  const renderSentRequests = () => (
    <ListContainerComponent
      loading={loading}
      data={listData}
      setDocumentId={setRequestedFeedbackId}
      setUserId={setRequestedUserId}
      from={"SENT_REQUEST"}
      setCancelRequestParam={setCancelRequestParam}
    ></ListContainerComponent>
  );

  const renderSearchBox = () => (
    <UserSearchBox
      user={user}
      setUser={setUser}
      setPageNumber={setPageNumber}
      users={[...users, ...clients]}
    ></UserSearchBox>
  );

  const renderChangePageButtons = () => (
    <>
      {count > 0 && (
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <ChangePageButtonComponent
            pageCount={Math.ceil(count / 10)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          ></ChangePageButtonComponent>
        </Grid>
      )}
    </>
  );

  const renderFilter = () => (
    <CustomSelect
      tabValue={filterValue}
      setTabValue={setFilterValue}
      setPageNumber={setPageNumber}
      name={"SENT_REQUEST"}
    />
  );

  return (
    <RequestFeedbackListLayout
      loading={count === -1}
      renderSearchBox={renderSearchBox}
      renderFilter={renderFilter}
      renderRequests={renderSentRequests}
      renderChangePageButtons={renderChangePageButtons}
      renderRequestCancelModal={renderRequestCancelModal}
    ></RequestFeedbackListLayout>
  );
};
