import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles((theme) => ({
    modalHeader: {
        padding: theme.spacing(2),
    },
    headerBackground: {
        background: "#7C69EC0D",
    },
    title: {
        fontWeight: 600,
        fontSize: theme.spacing(3),
        color: "#272727",
        fontFamily: "Montserrat",
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.spacing(1.75),
        },
    },
}))

export const ModalHeader = ({ title, showBackground = true, handleCancel }) => {
    const classes = useStyle()
    return (
        <Grid
            item
            xs={12}
            container
            justify="space-between"
            alignItems="center"
            className={`${classes.modalHeader} ${showBackground ? classes.headerBackground : ""}`}
        >
            <Typography className={classes.title}>
                {title}
            </Typography>
            <IconButton
                onClick={handleCancel}
                style={{
                    padding: "4px",
                    position: "relative",
                    height: "30px",
                    width: "30px",
                }}
            >
                <img src="/CancelGray.svg" alt="close" />
            </IconButton>
        </Grid>
    )
}