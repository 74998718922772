import {
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ListHeaderProps } from "../type";

const useStyle = makeStyles((theme) => ({
  grid: {
    backgroundColor: "rgba(124,105,236,0.05)",
    padding: theme.spacing(1, 0, 1, 0),
  },
  text: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#868686",
    fontFamily: "Montserrat",
  },
  actionAlignMent: {
    textAlign: "end",
    marginRight: theme.spacing(11.625),
  },
  nameAlignMent: {
    textAlign: "start",
    marginLeft: theme.spacing(5),
  },
  dateAlignMent: {
    textAlign: "start",
  },
}));

export const ListHeader: React.FC<ListHeaderProps> = ({
  headerName,
  headerAction,
  itemValue,
  setItemValue,
  tabValue,
  setPageNumber,
}) => {
  const classes = useStyle();
  const [openFilter, setFilter] = React.useState<Boolean>(false);

  return (
    <Grid xs={12} item container className={classes.grid} alignItems="center">
      <Grid xs={4} item container direction="row" alignItems="center">
        {(tabValue === 0 || tabValue === 3) && (
          <Grid xs={1} item container direction="column">
            <IconButton onClick={() => setFilter(!openFilter)}>
              <img
                alt="filter"
                src="/filter.svg"
                style={{ height: "16px", width: "16px", marginLeft: "4px" }}
              ></img>
            </IconButton>
            <Grid item xs={12} container justify="flex-start">
              {openFilter && (
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => setFilter(false)}
                >
                  <List
                    component="nav"
                    style={{
                      position: "absolute",
                      width: "115px",
                      zIndex: 5,
                      border: "1px solid rgba(0, 0, 0, 0.04)",
                      boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
                      opacity: 1,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <ListItem
                      style={itemValue === 1 ? { color: "#576BEA" } : {}}
                      button
                      onClick={() => {
                        setItemValue(1);
                        setPageNumber(1);
                      }}
                    >
                      All
                    </ListItem>
                    <ListItem
                      style={itemValue === 2 ? { color: "#576BEA" } : {}}
                      button
                      onClick={() => {
                        setItemValue(2);
                        setPageNumber(1);
                      }}
                    >
                      Employee
                    </ListItem>
                    <ListItem
                      style={itemValue === 3 ? { color: "#576BEA" } : {}}
                      button
                      onClick={() => {
                        setItemValue(3);
                        setPageNumber(1);
                      }}
                    >
                      Client
                    </ListItem>
                  </List>
                </ClickAwayListener>
              )}
            </Grid>
          </Grid>
        )}
        <Grid xs={11} item>
          <Typography className={`${classes.nameAlignMent} ${classes.text}`}>
            {headerName}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={2} item>
        <Typography className={`${classes.dateAlignMent} ${classes.text}`}>
          {headerName !== "Shared on" && "Date"}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography className={`${classes.actionAlignMent} ${classes.text}`}>
          {headerAction}
        </Typography>
      </Grid>
    </Grid>
  );
};
