import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import "./extra_fonts/landingFont.css";

const useStyle = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    margin: theme.spacing(30, 0, 0, 0),
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(4, 0, 2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4),
      width: `${window.innerWidth}px`,
    },
  },
  slide_1_main_text: {
    color: "#012241",
    fontSize: theme.spacing(4),
    fontWeight: 600,
    fontFamily: "EuclideCircularASemiBold",
    letterSpacing: theme.spacing(0),
    width: theme.spacing(67.75),
    textAlign: "left",
    direction: "ltr",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      textAlign: "center",
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  slide_1_sub_text: {
    color: "#012241",
    fontSize: theme.spacing(2.25),
    fontWeight: 500,
    fontFamily: "Open Sans",
    textAlign: "left",
    letterSpacing: theme.spacing(0.05),
    width: theme.spacing(67.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
      textAlign: "center",
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  img: {
    width: theme.spacing(67.75),
    backgroundColor: "#D8D8D8",
    border: "1px solid #D8D8D8",
    height: theme.spacing(37.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  left_section: {
    marginRight: theme.spacing(4.5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(5, 0, 2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
    },
  },
  right_section: {
    marginLeft: theme.spacing(4.5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0),
    },
  },
  container: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(5, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4),
    },
  },
}));

const FEATURE_OBJ = [
  {
    heading: "Nuggets for agile environments",
    description:
      "Share real-time feedback to boost productivity and promote a fast-paced work environment",
  },
  {
    heading: "360° feedback",
    description:
      "With Grow-wize, you can request and collect feedback from your teammates or external clients and vendors",
  },
  {
    heading: "Feedback summary",
    description:
      "Get a bird’s eye view over performance with feedback summaries. Get contextual and statistical overview on strengths and opportunities for growth",
  },
  {
    heading: "Custom feedback parameters",
    description:
      "Make your feedback work for your organization’s unique culture. Set attributes and questions aligned with your values and specific objectives",
  },
  {
    heading: "Bird’s eye view of individual feedback",
    description:
      "Get a detailed overview of each employee’s feedback history: the number of shared and received feedbacks, pending requests, and self-assessments all in one place",
  },
];

export const FeatureSectionComponent: React.FC = () => {
  const classes = useStyle();
  return (
    <>
      <Grid
        xs={12}
        item
        container
        className={classes.parallax}
        justify="center"
        alignItems="center"
      >
        {FEATURE_OBJ.map((item, idx) => (
          <Grid
            xs={12}
            item
            key={idx}
            container
            justify="center"
            alignItems="center"
            className={classes.container}
            style={idx % 2 === 0 ? { direction: "ltr" } : { direction: "rtl" }}
          >
            <Grid
              item
              className={
                idx % 2 === 0 ? classes.left_section : classes.right_section
              }
            >
              <Typography className={classes.slide_1_main_text}>
                {item.heading}
              </Typography>
              <Typography className={classes.slide_1_sub_text}>
                {item.description}
              </Typography>
            </Grid>
            <Grid
              item
              className={
                idx % 2 === 0 ? classes.right_section : classes.left_section
              }
            >
              <Grid xs={12} item>
                <Paper className={classes.img} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
