import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";
import { NudgeSendNotificationProps } from "../../type";

export const nudgeSendNotification = createAsyncThunk(
  "admin/nudge/sendNotification",
  async (requestParams: NudgeSendNotificationProps, storeActions) => {
    const url = `api/v1/admin/send/nudge`;
    const response: any = await post(url, requestParams, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: response.status,
        data: await response.json(),
      };
    } else {

      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const adminNudgeSendNotificationSlice = createSlice({
  name: "adminNudgeSendNotification",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nudgeSendNotification.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(nudgeSendNotification.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(nudgeSendNotification.rejected, (state) => {
      state.status = HttpStatus.SERVICE_UNAVAILABLE;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData  } = adminNudgeSendNotificationSlice.actions;

export default adminNudgeSendNotificationSlice;
