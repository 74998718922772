import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import {
  addTeamMembers,
  AddTeamMembersSliceProp,
} from "../../../redux/slices/AddTeamMembersSlice";
import {
  updateOrgData,
  UpdateOrgDataSliceProp,
} from "../../../redux/slices/UpdateOrganizationSlice";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { State } from "../../../type";
import { useStyle } from "../../user/request_feedback/request_client/buttons";

const useWorkSpaceStyle = makeStyles((Theme) => ({
  popper: {
    border: `0.5px solid #272727 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: "transparent",
    width: "429px",
    minHeight: "46px",
    padding: theme.spacing(0.5, 1.125, 0, 0),
    "&:focus": {
      borderColor: "#272727 !important",
    },
    "&:hover": {
      borderColor: "#272727 !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: `${4 * (window.innerWidth / 5)}px`,
    },
  },
  textField: {
    "&:focus": {
      border: "none !important",
    },
    "&:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "0.5px solid #272727",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #272727",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
    fontSize: theme.spacing(1.75),
    border: "none !important",
    borderRadius: theme.spacing(0.5),
    height: "inherit",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  submit: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    backgroundColor: "#576bea",
    padding: "12px 21px",
    width: "240px",
    borderRadius: "4px",
    marginTop: "40px",
    textTransform: "none",
  },
  barLine: {
    backgroundColor: "#576BEA",
    borderColor: "#576BEA",
    width: theme.spacing(10),
    height: theme.spacing(0.5),
    margin: theme.spacing(1, 0, 6, 0),
    borderRadius: theme.spacing(1),
  },
  container_style: {
    marginTop: "60px",
    width: "429px",
    [theme.breakpoints.down("sm")]: {
      width: `${4 * (window.innerWidth / 5)}px`,
      marginTop: theme.spacing(3),
    },
  },
  text_container_style: {
    marginBottom: theme.spacing(4),
    width: "429px",
    height: theme.spacing(5.25),
    [theme.breakpoints.down("sm")]: {
      width: `${4 * (window.innerWidth / 5)}px`,
      marginBottom: theme.spacing(2),
    },
  },
}));

export const SetupForm: React.FC = () => {
  const classes = useStyle();
  const customStyle = useCustomStyle();
  const currentStyle = useWorkSpaceStyle();
  const dispatch = useDispatch();

  const [orgName, setOrgName] = React.useState<string>("");
  const [emails, setEmails] = React.useState<string[]>([]);
  const [isValid, setValid] = React.useState<Boolean>(true);

  const orgLoading = useSelector((state: State) => state.updateOrgData.loading);
  const teamLoading = useSelector(
    (state: State) => state.addTeamMembers.loading
  );

  const loggedInUserEmail: string = useSelector(
    (state: State) => state.auth.email
  );
  let userDomain = atob(loggedInUserEmail)?.split("@")[1];
  const [disabled, setDisabled] = React.useState(true);
  const labels: Array<string> = [];

  useEffect(() => {
    let check = true;

    if (userDomain === undefined) {
      userDomain = atob(loggedInUserEmail)?.split("@")[1];
    }

      emails.map((e) => {
        const invitedMemberDomain = e.split("@")[1];
        if (!validator.isEmail(e) || !(invitedMemberDomain===userDomain)) check = false;
      });

    setValid(check);
    setDisabled(orgName.length < 3 || !isValid || emails.length < 1);
  }, [orgName, emails, isValid]);

  function handleSubmit(e) {
    e.preventDefault();
    const params: UpdateOrgDataSliceProp = {
      orgName: orgName,
    };
    dispatch(updateOrgData(params));
    const params2: AddTeamMembersSliceProp = {
      orgName: orgName,
      memberEmails: emails,
    };
    dispatch(addTeamMembers(params2));
  }
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      item
      xs={12}
      className={currentStyle.container_style}
      id="workspace"
    >
      <form onSubmit={handleSubmit}>
        <Typography variant="h1" style={{ color: "#272727" }}>
          Set up your workspace
        </Typography>
        <hr className={currentStyle.barLine} />
        <InputLabel
          htmlFor="component-error"
          error={orgName.length < 3 && orgName.length > 0}
          style={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#272727",
            marginBottom: "12px",
          }}
        >
          Your Company Name <span style={{ color: "#DC0101" }}>*</span>
        </InputLabel>

        <TextField
          variant="outlined"
          className={`${currentStyle.text_container_style} ${currentStyle.textField}`}
          placeholder="Type here..."
          onChange={(e) => setOrgName(e.target.value)}
          error={orgName.length < 3 && orgName.length > 0}
          inputProps={{
            style: {
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              color: "#2C3256",
            },
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#272727",
              marginBottom: "12px",
            }}
          >
            Invite Team Members <span style={{ color: "#DC0101" }}>*</span>
          </Typography>
          <Typography
            style={{
              color: "#A0A0A0",
              fontWeight: 600,
              fontSize: "12px",
              marginBottom: "12px",
              fontFamily: "Montserrat",
              whiteSpace: "break-spaces",
            }}
          > <span>(minimum 1)</span>
          </Typography>
        </div>
        <FormControl
          error
          variant="standard"
          style={{ width: "-webkit-fill-available" }}
        >
          <Autocomplete
            id="tags-filled"
            className={`${currentStyle.popper} ${customStyle.popperWidth} dashboard-autocomplete`}
            multiple
            freeSolo
            value={emails}
            getOptionSelected={(option, value) => option === value}
            onChange={(event, newValue, reason) => {
              setEmails(newValue);
            }}
            filterSelectedOptions
            disableCloseOnSelect
            options={labels}
            renderTags={(value: string[], getTagProps) =>
              value.map((user: string, index: number) => (
                <React.Fragment key={user}>
                  <Chip
                    key={user}
                    variant="outlined"
                    avatar={
                      <Avatar
                        alt={user?.toUpperCase()}
                        src={user?.toUpperCase()}
                        className={classes.small}
                        style={{
                          color: "#FFFFFF",
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.typography_body_3}
                        style={
                          !validator.isEmail(user) || !(user.split("@")[1]===(userDomain))
                            ? { borderColor: "#DC0101" }
                            : {}
                        }
                      >
                        {user}
                      </Typography>
                    }
                    className={classes.chip}
                    style={
                      !validator.isEmail(user) || !(user.split("@")[1]===(userDomain))
                        ? { borderColor: "#DC0101" }
                        : {}
                    }
                    {...getTagProps({ index })}
                  />
                </React.Fragment>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={emails.length < 1 ? "Email comma separated" : ""}
                variant="outlined"
                className={currentStyle.textField}
                onBlurCapture={(e) => {
                  if (
                    !emails.find(
                      (u) =>
                        u === (e.target as HTMLInputElement).value.toLowerCase()
                    ) &&
                    (e.target as HTMLInputElement).value.length > 0
                  )
                    setEmails(
                      emails.concat(
                        (e.target as HTMLInputElement).value.toLowerCase()
                      )
                    );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " " || e.key === ",") {
                    e.stopPropagation();
                    e.preventDefault();
                    if (
                      !emails.find(
                        (u) =>
                          u ===
                          (e.target as HTMLInputElement).value.toLowerCase()
                      )
                    )
                      setEmails(
                        emails.concat(
                          (e.target as HTMLInputElement).value.toLowerCase()
                        )
                      );
                  }
                }}
              />
            )}
          />
        </FormControl>
        <Typography
          variant="h6"
          style={{
            fontSize: "12px",
            fontWeight: 600,
            margin: "12px 0px 0px 0px",
          }}
        >
          Note: You will be the admin of this workspace
        </Typography>
        <Grid item xs={12} container justify="center" alignItems="center">
          {!teamLoading && !orgLoading && (
            <Button
              type="submit"
              color="primary" 
              variant="contained"
              disabled={disabled}
              className={currentStyle.submit}
            >
              Set up and Continue
            </Button>
          )}
          {(teamLoading || orgLoading) && (
            <>
              <CircularProgress className={currentStyle.submit} />
            </>
          )}
        </Grid>
      </form>
    </Grid>
  );
};
