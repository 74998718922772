import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ToolBar } from "../../../modules/home/ToolBar";
import { SharedFeedbacksLayoutProp } from "../../../modules/user/shared_feedback/typeSharedFeedbacks";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(4, 3, 3, 3),
    },
  },
  root2: {
    padding: theme.spacing(6, 0, 0, 0),
    marginTop: theme.spacing(10),
    background: "#FFFFFF",
    minHeight: `${window.innerHeight - 85}px`,
  },
  loader: {
    margin: theme.spacing("25%", "40%"),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing("35%", "37%"),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing("60%", "40%"),
    },
  },
}));

export const SharedFeedbacksLayout: React.FC<SharedFeedbacksLayoutProp> = ({
  props,
  count,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  showNoFeedback,
  renderErrorModal,
  renderNoFeedback,
  renderSearchBox,
  renderSharedFeedbacks,
  renderChangePageButtons,
}) => {
  const classes = useStyle();
  window.scrollTo(0,0);
  document.title = "Grow-Wize | Shared Feedback";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Shared Feedback"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderSharedFeedbacksLayout = () => (
    <Grid container className={showNoFeedback ? classes.root2 : classes.root}>
      {renderErrorModal()}
      {showNoFeedback ? (
        <Grid container justify="center">
          {renderNoFeedback()}
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ width: "inherit" }}
        >
          {renderSearchBox()}
          {renderSharedFeedbacks()}
          {count !== 0 ? renderChangePageButtons() : null}
        </Grid>
      )}
    </Grid>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderSharedFeedbacksLayout}
    ></HomeLayout>
  );
};
