import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ClearAllData } from "../constants/clearData";
import { ErrorPopUp } from "./ErrorPages/errorModal";
import { CommonFunctionalityComponentProp } from "./type";
import { useSelector } from "react-redux";
import { State } from "../../type";

export const CommonFunctionalityComponent: React.FC<
  CommonFunctionalityComponentProp
> = ({
  props,
  logout,
  setLogout,
  statusCode,
}) => {
  const history = useHistory();
  const [dataCleared, setDataCleared] = React.useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
  const goToPreviousPath = () => {
    if (history.length > 10) {
      history.goBack();
    } else {
      history.push("/");
    }
  };
  const logoutUser = () => {
    setLogout(true);
  };

  const sessionExpired = useSelector((state: State) => state.auth.isExpired);

  useEffect(() => {
    if(sessionExpired) {
      logoutUser();
    }
  }, [sessionExpired])

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={showErrorModal}
      setShowModal={setShowErrorModal}
      statusCode={statusCode}
      handleNoAuthorization={logoutUser}
      handleAccessDenied={goToPreviousPath}
    ></ErrorPopUp>
  );

  useEffect(() => {
    if (statusCode && statusCode !== 200 && statusCode !== 201) {
      setShowErrorModal(true);
    }
  }, [statusCode]);

  if (logout && !dataCleared) {
    ClearAllData(setDataCleared);
  }

  if (logout && dataCleared) {
    history?.push({
      pathname: "/",
      state: { path: sessionExpired ? props.location.pathname : null },
      search:
        new URLSearchParams(props.location?.search).get("uid") === null
          ? ""
          : `?uid=${new URLSearchParams(props.location?.search).get("uid")}`,
    });
  }
  return <>{renderErrorModal()}</>;
};
