import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import { SharedFeedbacksLayout } from "../../../layouts/user/shared_feedback/Layout";
import { countSharedFeedback } from "../../../redux/slices/CountSharedFeedbackSlice";
import { getSharedFeedbacks } from "../../../redux/slices/SharedFeedbacksSlice";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import {
  RequestingParams,
  ResponseAttribute,
  SharedFeedbacksParams,
  State,
  User,
  UserAndAttributeSliceProps,
} from "../../../type";
import { SharedFeedback } from "./typeSharedFeedbacks";
import { userToOption } from "../../../util/userOption";
import { attributeToOption } from "../../../util/attributeOption";
import { CustomSearchBoxComponent } from "../../components/CustomSearchboxComponent";
import { SearchOption } from "../../components/type";
import { NoRecordComponent } from "../../components/list/noRecordPage/NoRecordPage";
import { ListContainerComponent } from "../../components/list/ListContainer";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";

export const SharedFeedbacks = (props) => {
  const [value, setValue] = React.useState(-1);
  const [sharedFeedbackId, setSharedFeedbackId] = React.useState<number>(0);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [allFeedbackCount, setAllFeedbackCount] = React.useState<number>(-1);

  const pageValue = props.location.state?.pageNo;
  const opt = props.location.state?.option;

  const dispatch = useDispatch();
  const count: number =
    useSelector((state: State) => state.countShared.data) ?? -1;
  const users: Array<User> =
    useSelector((state: State) => state.data.data?.users) ?? [];
  const attributes: Array<ResponseAttribute> =
    useSelector((state: State) => state.data.data?.attributes) ?? [];
  const [option, setOption] = React.useState<SearchOption | null>(
    opt ? opt : null
  );
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );
  const feedbacks: Array<SharedFeedback> =
    useSelector((state: State) => state.sharedFeedbacks.data) ?? [];
  const loadingDataAndCount = useSelector(
    (state: State) => state.data.loading || state.countShared.loading
  );
  const loadingList: Boolean = useSelector(
    (state: State) => state.sharedFeedbacks.loading
  );

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: true,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
  }, []);

  useEffect(() => {
    if (option === null) setAllFeedbackCount(count);
  }, [count, option]);

  useEffect(() => {
    setPageNumber(pageValue ? pageValue : 1);
  }, [option]);

  useEffect(() => {
    let requestParams: string = "";
    if (option) {
      if (option.userId === null)
        requestParams += "?attributeId=" + option.attrId;
      else requestParams += "?receiverId=" + option.userId;
    }
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(countSharedFeedback(requestingData));
  }, [option]);

  useEffect(() => {
    let requestParams: string = "";
    requestParams += "?offSet=" + (pageNumber - 1) * 10;
    if (option) {
      if (option.userId === null)
        requestParams += "&attributeId=" + option.attrId;
      else requestParams += "&receiverId=" + option.userId;
    }
    let requestingParams: SharedFeedbacksParams = {
      requestingParams: requestParams,
    };
    dispatch(getSharedFeedbacks(requestingParams));
  }, [pageNumber, option, dispatch]);
  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.countShared.status !== 200
      ? state.countShared.status
      : state.sharedFeedbacks.status !== 200
      ? state.sharedFeedbacks.status
      : null
  );
  const history = useHistory();

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderNoFeedback = () => (
    <NoRecordComponent setValue={setValue} from={"SHARED_FEEDBACK"} />
  );

  const renderSearchBox = () => (
    <CustomSearchBoxComponent
      option={option}
      setOption={setOption}
      setPageNumber={setPageNumber}
      options={[
        ...users.map(userToOption),
        ...attributes.map(attributeToOption),
      ]}
    ></CustomSearchBoxComponent>
  );

  const renderSharedFeedbacks = () => (
    <ListContainerComponent
      loading={loadingList}
      data={feedbacks}
      setDocumentId={setSharedFeedbackId}
      from={"SHARED_FEEDBACK"}
    ></ListContainerComponent>
  );

  const renderChangePageButtons = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <ChangePageButtonComponent
        pageCount={Math.ceil(count / 10)}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      ></ChangePageButtonComponent>
    </Grid>
  );

  if (value === 1) return <Redirect to={`/feedback/share/default`} />;

  if (sharedFeedbackId !== 0) {
    history.replace({
      pathname: "/feedback/shared",
      state: {
        pageNo: pageNumber,
        option: option,
      },
    });
    history.push({
      pathname: `/feedback/shared/detail/${sharedFeedbackId}`,
      state: {
        pageNo: pageNumber,
        option: option,
      },
    });
  }

  return (
    <SharedFeedbacksLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      loading={loadingDataAndCount}
      count={count}
      showNoFeedback={allFeedbackCount === 0}
      renderErrorModal={renderErrorModal}
      renderNoFeedback={renderNoFeedback}
      renderSearchBox={renderSearchBox}
      renderSharedFeedbacks={renderSharedFeedbacks}
      renderChangePageButtons={renderChangePageButtons}
    ></SharedFeedbacksLayout>
  );
};
