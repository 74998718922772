import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HttpStatus } from "../../enums/httpStatus";

export interface InterestRecordData {
    name: string;
    emailId: string;
    orgName: string;
}

export const interestSave = createAsyncThunk(
  "interest/organisation",
  async (requestData: InterestRecordData) => {
    try {
      const options = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/connect-with-us`,
        options
      );
      if (response.status === HttpStatus.CREATED) {
        return {
          status: HttpStatus.CREATED,
        };
      }
      return {
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  }
);

const orgInterestSlice = createSlice({
  name: "orgInterest",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
        interestSave.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(interestSave.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(interestSave.rejected, (state) => {
      state.status = 503;
      state.loading = false;
    });
  },
});

export const { clearData } = orgInterestSlice.actions;

export default orgInterestSlice;
