import { Button, ClickAwayListener, makeStyles } from "@material-ui/core"
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { getDateInDesiredFormat } from "../../util/GetDateInDesiredFormat";
import { useLocation } from "react-router-dom";
import theme from "../../theme";

const useStyle = makeStyles((theme) => ({
    calenderCTA: {
        padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
        textTransform: "none",
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        color: "#7C69EC",
        fontFamily: "Montserrat",
        height: theme.spacing(6),
    },
    dateRangeWrapper: {
        position: "absolute",
        zIndex: 100,
        right: theme.spacing(78),
        boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
        border: "1px solid rgba(175,175,175,0.3)",
        fontFamily: "Montserrat",
        marginTop: "399px",
        "& > .rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper": {
            display: "none",
        }
    },
}));

export const NudgeCalendarCTA = ({
    setDateRange,
    dateRange,
    selectedDateCount,
    setSelectedDateCount,
}) => {
    const classes = useStyle();
    const [showDateRange, setShowDateRange] = useState<boolean>(false);

    const location = useLocation();
    const currentPath = location.pathname;

    // Conditionally set marginTop based on the current route
    const marginTop = currentPath === "/admin/nudge" ? "0px" : "399px";

    // Conditionally set right based on the current route
    const right = currentPath === "/admin/nudge" ? theme.spacing(6) : theme.spacing(78);

    const handleDateChange = (range) => {
        const nextRange = {
            startDate: range?.range1?.startDate,
            endDate: range?.range1?.endDate,
        };
        setDateRange(nextRange);
        if (selectedDateCount === 1) {
            setShowDateRange(false);
            setSelectedDateCount(2);
        } else if (selectedDateCount === 0) {
            setSelectedDateCount(1);
        }
    };

    return (<>
        <Button
            startIcon={<img src="/CalendarIconInvert.svg" alt="export_report"></img>}
            variant="text"
            data-testid="export-button"
            className={classes.calenderCTA}
            onClick={() => setShowDateRange(true)}
        >
            {/* Sep 2023 - Sep 2024 */}
            {getDateInDesiredFormat(dateRange.startDate)} - {getDateInDesiredFormat(dateRange.endDate)}
        </Button>
        {showDateRange && (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => setShowDateRange(false)}
            >
                <div className={classes.dateRangeWrapper} style={{ 
                    marginTop,
                    right, // Apply right dynamically
                    // Other common styles
                    position: "absolute",
                    zIndex: 100,
                    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
                    border: "1px solid rgba(175,175,175,0.3)",
                    fontFamily: "Montserrat",
                }}>
                    <DateRangePicker
                        ranges={[dateRange]}
                        onChange={(e) => handleDateChange(e)}
                        maxDate={new Date()}
                        staticRanges={[]}
                        inputRanges={[]}
                        dateDisplayFormat={"d MMM yyyy"}
                    />
                </div>
            </ClickAwayListener>
        )}
    </>
    );
}

export {};