import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { DetailPage } from "../../../components/list/detail/DetailPage";
import { ReceivedFeedbackDetailLayout } from "../../../../layouts/user/received_feedback/detail/Layout";
import { receivedFeedbackDetail } from "../../../../redux/slices/ReceivedFeedbackDetailSlice";
import { requestData } from "../../../../redux/slices/UsersAndAttributesSlice";
import jwt_decode from "jwt-decode";
import {
  FeedbackDetailAPICallProps,
  ReceivedFeedbackDetailData,
  State,
  UserAndAttributeSliceProps,
} from "../../../../type";

interface TokenDataProps {
  iat: number;
  exp: number;
  sub: number;
  role: string;
  org_id?: number;
  status?: string;
}

export const ReceivedFeedbackDetail = (props) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [value, setValue] = React.useState(-1);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const token: string = useSelector((state: State) => state.auth.token);
  const uid = new URLSearchParams(props.location.search).get("uid");

  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    org_id: 0,
  };
  try {
    tokenData = jwt_decode(token);
  } catch (e) {
    console.log((e as Error).message);
  }

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: false,
      isAttributes: false,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: true,
    };
    dispatch(requestData(param));
    const params: FeedbackDetailAPICallProps = {
      feedbackId: id,
    };
    dispatch(receivedFeedbackDetail(params));
  }, [id]);

  const data: ReceivedFeedbackDetailData = useSelector(
    (state: State) => state.receivedFeedbackDetail.feedback
  );

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.receivedFeedbackDetail.status !== 200
      ? state.receivedFeedbackDetail.status
      : state.data.status !== 200
      ? state.data.status
      : null
  );

  const renderErrorModal = () => (
    <>
      {statusCode === 403 && tokenData.role === "ADMIN" && uid ? (
        <></>
      ) : (
        <CommonFunctionalityComponent
          props={props}
          logout={logout}
          setLogout={setLogout}
          statusCode={statusCode}
        />
      )}
    </>
  );

  const renderDetails = () => (
    <DetailPage
      user={data?.user}
      date={data?.createdAt}
      requestId={data?.requestId}
      context={data?.context}
      attributes={data?.attributes}
      from={"RECEIVED_FEEDBACK"}
      setValue={setValue}
    />
  );

  if (!isDetailView) {
    history.push({
      pathname: "/feedback/received",
      state: {
        pageNo: props.location.state?.pageNumber,
        user: props.location.state?.user,
        tabValue: props.location.state?.tabValue,
      },
    });
  }

  if (value === 2) {
    if (data.user.organizationId === tokenData.org_id)
      history.push({
        pathname: "/feedback/requests/employee",
        state: {
          user: data.user,
        },
      });
    else
      history.push({
        pathname: "/feedback/requests/client",
        state: {
          user: data.user,
        },
      });
  }

  if(statusCode === 403 && tokenData.role === "ADMIN" && uid !== null) {
    history.push({
      pathname: `/admin/detail/view`,
      state: {
        feedbackOrAssessmentId: id,
        memberId: uid,
        from: "dashboard",
        tabValue: 0,
      },
    });
  }

  return (
    <ReceivedFeedbackDetailLayout
      props={props}
      logout={logout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      setLogout={setLogout}
      requestId={data?.requestId}
      renderDetails={renderDetails}
      renderErrorModal={renderErrorModal}
    ></ReceivedFeedbackDetailLayout>
  );
};
