import {
  List,
  ListItem,
  Typography, makeStyles,
} from "@material-ui/core";
import { Modal } from "../../../modules/components/Modal/Modal";
import { ModalHeader } from "../../../modules/components/Modal/ModalHeader";
import { ModalBody } from "../../../modules/components/Modal/ModalBody";
import { ModalFooter } from "../../../modules/components/Modal/ModalFooter"

export const AttributePromptModal = ({
  open,
  handleCancel,
  handleSendAnyway,
  disableActions,
  promptAttributes
}) => {

  const useStyle = makeStyles((theme) => ({
    confirmationInfo: {
      fontSize: 14,
      fontWeight: 500,
      color: "#272727",
      marginBottom: 20
    },
    listItem: {
      display: 'list-item',
      fontWeight: 'bold',
      color: "#272727",
      paddingLeft: "10px",
      fontSize: 14
    }

  }));
  const classes = useStyle();
  return (
    <Modal open={open} >
      <ModalHeader title="Suggestions" showBackground={false} handleCancel={handleCancel} />
      <ModalBody>
        <Typography className={classes.confirmationInfo}>
          The current feedback is limited. it would be beneficial for receiver, if you could also provide feedback on the following attributes: <br />
        </Typography>
        <List style={{ listStyleType: 'disc', marginLeft: "40px" }}>
          {promptAttributes.map(attribute => {
            return <ListItem className={classes.listItem}>
              {attribute.name}
            </ListItem>
          })}
        </List>
      </ModalBody>
      <ModalFooter
        buttonLabel="Send anyway"
        secondaryLabel="Go back"
        handleCancel={handleCancel}
        handleSubmit={handleSendAnyway}
        disableActions={disableActions}
      />
    </Modal>
  );
};
