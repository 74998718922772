import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { DetailPage } from "../../../components/list/detail/DetailPage";
import { FeedbackRequestDetailsLayout } from "../../../../layouts/user/feedback_request/detail/Layout";
import { receivedFeedbackRequestDetails } from "../../../../redux/slices/ReceivedFeedbackRequestDetailsSlice";
import { requestData } from "../../../../redux/slices/UsersAndAttributesSlice";
import {
  ReceivedFeedbackRequestDetailAPICallProps,
  State,
  UserAndAttributeSliceProps,
} from "../../../../type";
import { FeedbackRequestDetailData } from "../typeFeedbackrequest";

export const FeedbackRequestDetails = (props) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState<Boolean>(false);

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: false,
      isAttributes: false,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: true,
    };
    dispatch(requestData(param));
    const params: ReceivedFeedbackRequestDetailAPICallProps = {
      feedbackRequestId: id,
    };
    dispatch(receivedFeedbackRequestDetails(params));
  }, [id]);

  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);

  const data: FeedbackRequestDetailData =
    useSelector((state: State) => state.receivedRequestDetails.data) ?? null;

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.receivedRequestDetails.status !== 200
      ? state.receivedRequestDetails.status
      : state.data.status !== 200
      ? state.data.status
      : null
  );


  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderDetails = () => (
    <DetailPage
      user={data?.user}
      date={data?.createdAt}
      context={data?.context}
      attributeNames={data?.requestedAttributes}
      attributes={data?.responseAttributes}
      from={"RECEIVED_REQUEST"}
    />
  );

  if (!isDetailView) {
    history.push({
      pathname: `/feedback/requests/received/${props.location.state?.tabName}`,
      state: {
        pageNo: props.location.state?.pageNo,
        user: props.location.state?.user,
      },
    });
  }

  return (
    <FeedbackRequestDetailsLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderDetails={renderDetails}
    ></FeedbackRequestDetailsLayout>
  );
};
