import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import React from "react";
import { PageButtonsProps } from "./type";

const useStyle = makeStyles((theme) => ({
  typography_body_3: {
    padding: theme.spacing(0, 0, 0, 0),
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  pagination: {
    margin: theme.spacing(4, 0, 4, 0),
    
    ...(window.location.pathname === "/admin/dashboard" && {
      margin: theme.spacing(1, 0, 1, 0),
    }),

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3.75, 0, 3.75, 0),
    },
  },
  disabledButton: {
    color: "darkgrey",
  },
  ul: {
    "& .Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#7C69EC",
      "&:hover": {
        backgroundColor: "#5c49cc",
      },  
      fontWeight: 600,
    },
  },
}));

export const ChangePageButtonComponent: React.FC<PageButtonsProps> = ({
  pageCount,
  pageNumber,
  setPageNumber,
  userRole,
}) => {
  const classes = useStyle();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <>
      <Button
        color="default"
        disabled={pageNumber === 1 ? true : false}
        className={classes.pagination}
        onClick={() => setPageNumber(pageNumber - 1)}
        classes={{ disabled: classes.disabledButton }}
      >
        <Typography variant="h5" className={classes.typography_body_3}>
          Previous
        </Typography>
      </Button>
      <Pagination
        count={pageCount}
        page={pageNumber}
        defaultPage={1}
        siblingCount={1}
        hideNextButton
        hidePrevButton
        onChange={handleChange}
        color="primary"
        className={classes.pagination}
        classes={userRole === "ADMIN" ? { ul: classes.ul } : {}}
        shape="rounded"
      />
      <Button
        color="default"
        disabled={pageNumber === pageCount ? true : false}
        className={classes.pagination}
        onClick={() => setPageNumber(pageNumber + 1)}
        classes={{ disabled: classes.disabledButton }}
      >
        <Typography variant="h5" className={classes.typography_body_3}>
          Next
        </Typography>
      </Button>
    </>
  );
};
