import {
  Dialog,
  Slide,
  SlideProps,
} from "@material-ui/core";
import * as React from "react";
import { FAQComponent } from "./Component";
import { Props } from "./type";
import "./style.css";

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const FullScreenDialog: React.FC<Props> = ({ open, setOpen }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ top: 85 }}
        className={"faq-dialog-box"}
      >
        <FAQComponent setOpen={setOpen} />
      </Dialog>
    </div>
  );
};
