import {
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import theme from "../../../../theme";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  QuestionDetail,
  ResponseProp,
  ResponseScaleProp,
} from "../../../../type";
import { ResponseValue } from "./renderResponse";
import { appColor } from "../../../constants/customThemeColor";
import { useCustomStyle } from "../../../../styles";
import { AttributeCardProp } from "../../../user/shared_feedback/typeSharedFeedbacks";

const useStyle = makeStyles((theme) => ({
  typography_body_1: {
    textAlign: "justify",
    padding: theme.spacing(1.5, 0, 3.75, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.875, 0, 2.25, 0),
    },
  },
  typography_body_2: {
    color: `${appColor.pureBlack}`,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  attributeTitleBar: {
    backgroundColor: "rgba(87,107,234,0.05)",
    width: "-webkit-fill-available",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(87,107,234,0.05)",
    },
    padding: theme.spacing(1, 1.25, 1, 2.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0.25, 0, 1),
    },
  },
  typography_body_4: {
    textAlign: "justify",
    padding: theme.spacing(2, 0, 0.6, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 0.6, 0),
    },
  },
  typography_inner_title: {
    padding: theme.spacing(2.5, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.875, 0, 0.875, 0),
    },
  },
  paper: {
    textAlign: "start",
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  container: {
    padding: theme.spacing(0, 2.5, 0, 2.5),
    margin: theme.spacing(1.5, 0, 1.5, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5, 0, 1.5),
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  divider: {
    backgroundColor: "rgb(140 142 154 / 48%)",
    height: theme.spacing(0.125),
    margin: theme.spacing(0, 0, 2.25, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 1.375, 0),
    },
  },
}));

const getAnswerPropertiesByAnswerValue = (
  index: number,
  que: QuestionDetail,
  responseScales: Array<ResponseScaleProp>
) => {
  const currentScale: Array<ResponseProp> | undefined = responseScales.find(
    (scale) =>
      scale.scaleName === que.answerType &&
      scale.scaleVersion === que.scaleVersion
  )?.scaleResponses;
  const response: ResponseProp | undefined = currentScale?.find(
    (response) => response.url === que.answer
  );
  return [
    response?.name,
    response?.url,
    response?.color,
    response?.borderColor,
  ];
};

export const AttributeCard: React.FC<AttributeCardProp> = ({
  attribute,
  id,
  showDetails,
  setShowDetails,
  responseScales,
  from,
  faq,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  let answer;

  const handleClickDetail = (showDetails, setShowDetails, idx) => {
    let newValue = [...showDetails];
    newValue[idx] = !newValue[idx];
    setShowDetails(newValue);
  };
  return (
    <Paper className={classes.paper} variant="outlined">
      {
        <Grid xs={12} item>
          <Button
            onClick={() => handleClickDetail(showDetails, setShowDetails, id)}
            className={`${customClasses.popperWidth} ${classes.attributeTitleBar}`}
          >
            <Grid container xs={12} item alignItems="center">
              <Grid container item xs={11} justify="flex-start">
                <Typography variant="h2" className={classes.typography_body_2}>
                  {from === "FAQ" ? faq?.question : attribute.name}
                </Typography>
              </Grid>
              <Grid container item xs={1} justify="flex-end">
                <IconButton>
                  {showDetails && showDetails[id] ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      }
      {showDetails && showDetails[id] && (
        <Grid container direction="column" className={classes.container}>
          {from !== "ASSESSMENT_SUBMITTED" && from !== "FAQ" && (
            <Grid
              container
              direction="column"
              style={{ marginBottom: theme.spacing(1.5) }}
            >
              {attribute?.questions.map(
                (que, index) =>
                  que.answer !== "Skipped" && (
                    <Grid
                      key={que.questionText}
                      style={
                        que.answer === "Skipped"
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                    >
                      <Typography
                        className={`${customClasses.typography_font_14_10_600} ${classes.typography_inner_title}`}
                      >
                        {que.questionText}
                      </Typography>
                      {(que.answerType === "STRING" ||
                        que.answer === "Skipped") && (
                        <Typography
                          variant="h6"
                          className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
                          dangerouslySetInnerHTML={{
                            __html: que.answer,
                          }}
                        />
                      )}
                      {que.answer !== "Skipped" && que.answerType !== "STRING" && (
                        <Grid
                          container
                          justify="flex-start"
                          direction="column"
                          style={{ margin: theme.spacing(1.25, 1.25, 1.25, 0) }}
                        >
                          <>
                            {(answer = getAnswerPropertiesByAnswerValue(
                              index,
                              que,
                              responseScales
                            )) && (
                              <ResponseValue answer={answer}></ResponseValue>
                            )}
                          </>
                        </Grid>
                      )}
                    </Grid>
                  )
              )}
            </Grid>
          )}
          {attribute?.questions?.filter((q) => q.answer !== "Skipped").length >
            0 && <Divider className={classes.divider}></Divider>}
          <Grid container direction="column">
            {from !== "FAQ" && (
              <Typography
                className={`${customClasses.typography_font_14_10_600} ${classes.typography_inner_title}`}
              >
                {from === "ASSESSMENT_SUBMITTED"
                  ? "What went well?"
                  : "Feedback Message"}
              </Typography>
            )}
            <Typography
              variant="h6"
              className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
              style={
                from === "FAQ" ? { margin: "0px 0px", padding: "0px", fontSize: "16px" } : {}
              }
              dangerouslySetInnerHTML={{
                __html: from === "FAQ" && faq ? faq?.answer : attribute.context,
              }}
            />
          </Grid>
          {attribute.suggestions && (
            <>
              {" "}
              <Divider className={classes.divider}></Divider>
              <Grid container direction="column">
                <Typography
                  className={`${customClasses.typography_font_14_10_600} ${classes.typography_inner_title}`}
                >
                  {from === "ASSESSMENT_SUBMITTED"
                    ? "What needs to be improved?"
                    : "Suggestions"}
                </Typography>
                <Typography
                  variant="h6"
                  className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
                  dangerouslySetInnerHTML={{
                    __html: attribute.suggestions,
                  }}
                />
              </Grid>
            </>
          )}
          {attribute?.resources && (
            <>
              <Divider className={classes.divider}></Divider>{" "}
              <Grid container direction="column">
                <Typography
                  className={`${customClasses.typography_font_14_10_600} ${classes.typography_inner_title}`}
                >
                  References
                </Typography>
                <Typography
                  variant="h6"
                  className={`${customClasses.typography_font_14_10} ${classes.typography_body_4}`}
                  dangerouslySetInnerHTML={{
                    __html: attribute.resources,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Paper>
  );
};
