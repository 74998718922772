import { FAQObject } from "../user/shared_feedback/typeSharedFeedbacks";

export const FAQ_DATA: Array<FAQObject> = [
    {
      question:
        "Why can't I see all my teammates in while requesting or sharing feedback?",
      answer:
        "Grow-Wize shows the users who have already joined or were invited during setup. You can ask your teammates to login using corporate mail ID (@domian.com) and then you will be able to exchange feedback with them.",
      to: "user",
    },
    {
      question: "How can I invite more team mates?",
      answer:
        "To make things simple, you dont need to invite all of your team mates. You can ask your teammates to login using corporate mail ID (@domian.com) and then you will be able to exchange feedback with them.",
      to: "user",
    },
    {
      question:
        "I have a very large team. Is there a way that all users can be onboarded quickly?",
      answer:
        '<p>You can share official email ID of your team with us on <a href="mailto:support@grow-wize.com" target="_blank" style="color:#576BEA;">support@grow-wize.com</a>. We will take it for ward and import all your teammates.</p>',
      to: "user",
    },
    {
      question:
        "Is there a way to change the attributes like leadership, Org involvement, etc?",
      answer:
        '<p>Yes, we can help you configure the feedback form based on your organization needs. You can share the attributes, questions and even the response scale that fits best for you and your organization with us on <a href="mailto:support@grow-wize.com" target="_blank" style="color:#576BEA;">support@grow-wize.com</a>. Share with us a spreadsheet with following details and we will make the required changes: </p><ul><li>Attribute name </li><li>Questions </li><li>Response Scale</li></ul>',
      to: "admin",
    },
    {
      question:
        "Is there a way to change or remove the questions in feedback form?",
      answer:
        '<p>Yes, we can help you configure the feedback form based on your organization needs. You can share the attributes, questions and even the response scale that fits best for you and your organization with us on <a href="mailto:support@grow-wize.com" target="_blank" style="color:#576BEA;">support@grow-wize.com</a>',
      to: "admin",
    },
    {
      question: "Is there a way for me to edit or delete the feedback shared? ",
      answer:
        "Currently, we are not allowing anyone (sender, receiver or even HR) to remove or edit the feedback shared. ",
      to: "user",
    },
    {
      question: "Who all can see my feedback?",
      answer:
        "The feedback and nuggets shared in the system is accessible only by sender, receiver of feedback and the HR Admins of your workspace?",
      to: "user",
    },
  ];