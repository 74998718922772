import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ToolBar } from "../../../modules/home/ToolBar";
import { RequestFeedbackLayoutProps } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: `${window.innerHeight - 102}px`,
    marginTop: theme.spacing(6.5),
    padding: theme.spacing(6, 6, 6, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(4, 3, 3, 3),
    },
  },
}));

export const RequestFeedbackLayout: React.FC<RequestFeedbackLayoutProps> = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  tabValue,
  renderErrorModal,
  renderTabs,
  renderRequestFeedbackForm,
  renderRequestFeedbackList,
  renderRequestFeedbackFromExtClientForm,
}) => {
  const indexToTab =
    {
          0: "Employee",
          1: "Client",
          2: "Past Requests",
        };
  const classes = useStyle();
  document.title = `Grow-Wize | Request Feedback | ${indexToTab[tabValue]}`;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Request Feedback"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderRequestFeedbackLayout = () => (
    <Grid container className={classes.root}>
      {renderErrorModal()}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        style={{ width: "inherit" }}
      >
        {renderTabs()}
        {tabValue === 0 ? renderRequestFeedbackForm() : null}
        {tabValue === 1 &&
          renderRequestFeedbackFromExtClientForm()}
        {tabValue === 2 &&
          renderRequestFeedbackList()}
      </Grid>
    </Grid>
  );

  return (
    <HomeLayout
      loading={false}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderRequestFeedbackLayout}
    ></HomeLayout>
  );
};
