import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles((theme) => ({
    modalBody: {
        padding: theme.spacing(2),
    },
}))

export const ModalBody = ({ children }) => {
    const classes = useStyle()
    return (
        <Grid container className={classes.modalBody}>
            {children}
        </Grid>
    )
}