import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { CookieProps } from "./type";

const useStyle = makeStyles((theme) => ({
  footerSection: {
    position: "fixed",
    height: theme.spacing(7.5),
    bottom: theme.spacing(0),
    boxShadow: "0 2px 10px 0 rgba(175,175,175,0.3)",
    backgroundColor: "#272727",
    zIndex: 10,
    [theme.breakpoints.down("sm")]:{
      height: "auto",
      textAlign: "justify",
      padding: theme.spacing(1)
    }
  },
  textColor: {
    color: "#FFFFFF",
  },
  btnStyle: {
    margin: "0px",
    padding: "0px",
    textTransform: "none",
  },
}));

export const CookieFooter: React.FC<CookieProps> = ({
  setIsFooter,
}) => {
  const classes = useStyle();
  return (
    <Grid
      xs={12}
      container
      alignItems="center"
      className={classes.footerSection}
      justify="center"
      direction="row"
    >
      <Grid item style={{display:"flex"}}>
        <Typography variant="h5" className={classes.textColor}>
          <p>
            The site uses cookies to help improve, promote and protect it. By
            continuing to use the site, you agree to the{" "}
            <a
              target="_blank"
              href="/about/policy"
              style={{ color: "#FFFFFF" }}
            >
              Privacy Policy
            </a>
          </p>
        </Typography>
        <IconButton
          style={{ margin: "0px 0px 0px 20px", padding: "0px" }}
          onClick={() => setIsFooter(false)}
        >
          <img src="/CrossIcon.svg" alt="X" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
