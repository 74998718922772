import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import "./extra_fonts/landingFont.css";

const useStyle = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    height: `${window.innerHeight - 100}px`,
    padding: theme.spacing(0, 15),
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#012241",
    // backgroundImage: "url(/Illustrator.svg)",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 4),
    },
  },
  slide_1_main_text: {
    color: "#FFFFFF",
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    fontFamily: "EuclideCircularABold",
    margin: theme.spacing(0, 0, 3.625, 0),
    textAlign: "center",
    letterSpacing: theme.spacing(0.0625),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
      margin: theme.spacing(3, 0),
      width: `${window.innerWidth - 50}px`,
    },
  },
  slide_1_sub_text: {
    color: "#FFFFFF",
    fontSize: theme.spacing(2.25),
    letterSpacing: theme.spacing(0.0625),
    fontWeight: 500,
    fontFamily: "Open Sans",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.5),
      width: `${window.innerWidth - 50}px`,
      padding: theme.spacing(0, 2),
    },
  },
  img: {
    width: theme.spacing(91.875),
    backgroundColor: "#012241",
    height: theme.spacing(47.75),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      width: `${3 * (window.innerWidth / 4)}px`,
      height: `${3 * (window.innerWidth / 4)}px`,
    },
  },
}));

export const BridgeComponent: React.FC = () => {
  const classes = useStyle();
  return (
    <>
      <Grid
        xs={12}
        item
        container
        className={classes.parallax}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} container justify="center" alignItems="center">
          <Grid item xs={12} container justify="center">
            <Grid item xs={12}>
              <Typography className={classes.slide_1_main_text}>
                The bridge between you, your team members, and everyone else
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.slide_1_sub_text}>
                Knowledge is the key to successful decision-making. Leverage the
                power of constructive, meaningful feedback to empower your team
                to make the right decisions and nurture personal growth and
                professional development within the workplace. With proper
                feedback, employees are 4.6 times more likely to grow. With
                Grow-wize, sharing your feedback with your employees is faster,
                simpler, and more hassle-free than ever.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <img src="/bridge_map.svg" alt="bridge_map" className={classes.img} />
        </Grid>
      </Grid>
    </>
  );
};
