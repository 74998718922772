import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { ReceivedFeedbackDetailLayoutProp, State } from "../../../../type";
import { HomeLayout } from "../../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 5, 6, 5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(12.25),
      padding: theme.spacing(3),
    },
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: `${window.innerHeight - 102}px`,
  },
  dateTypeGrid: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(5, 0, 0, 0),
    },
  },
}));

export const ReceivedFeedbackDetailLayout: React.FC<ReceivedFeedbackDetailLayoutProp> =
  ({
    props,
    setLogout,
    isDetailView,
    setIsDetailView,
    requestId,
    renderDetails,
    renderErrorModal,
  }) => {
    const classes = useStyle();
    window.scrollTo(0,0);
    document.title = "Grow-Wize | Received Feedback Detail";
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const tempFunction = () => {
      return 0;
    };

    const renderToolBar = () => (
      <ToolBar
        headerName="Received Feedback Detail"
        setLogout={setLogout}
        isDetailView={isDetailView}
        setIsDetailView={setIsDetailView}
        handleDrawerToggle={handleDrawerToggle}
        value={-2}
        setValue={tempFunction}
        tabValue={-2}
        validLength={false}
      ></ToolBar>
    );

    const renderReceivedFeedbackDetailLayout = () => (
      <Grid container direction="row" className={classes.root}>
        {renderErrorModal()}
        <Grid item xs={12} md={12}>
          {loading && <CircularProgress />}
          {!loading && <>{renderDetails()}</>}
        </Grid>
      </Grid>
    );

    const loading: Boolean = useSelector(
      (state: State) =>
        state.receivedFeedbackDetail.loading || state.data.loading
    );

    return (
      <HomeLayout
        loading={loading}
        renderToolBar={renderToolBar}
        props={props}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        renderSelectedOption={renderReceivedFeedbackDetailLayout}
      ></HomeLayout>
    );
  };
