import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatus } from "../../enums/httpStatus";
import { ReminderRequest } from "../../type";
import { getReminder } from "./GetReminderSlice";
import { post } from "../../util/APIService";

export interface CreateReminderSliceProp {
  reminderDetails: ReminderRequest;
}

export const createReminder = createAsyncThunk(
  "admin/reminder/add",
  async (params: CreateReminderSliceProp, storeActions) => {
    const url = `api/v1/admin/reminder`;
    const response: any = await post(url, params.reminderDetails, storeActions);

    if (response.status === HttpStatus.CREATED) {
      storeActions.dispatch(getReminder());
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        errorMessage: (await response.json()).message,
      };
    }
  }
);

const createReminderSlice = createSlice({
  name: "CreateReminder",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    errorMessage: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearCreateReminderData: (state) => {
      state.status = null;
      state.loading = false;
      state.errorMessage = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReminder.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.errorMessage = null;
      } else if (action.payload.errorMessage !== undefined) {
        state.data = null;
        state.errorMessage = action.payload.errorMessage;
      }
    });
    builder.addCase(createReminder.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
    });
    builder.addCase(createReminder.rejected, (state) => {
      state.status = HttpStatus.SERVICE_UNAVAILABLE;
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
    });
  },
});

export const { clearCreateReminderData } = createReminderSlice.actions;

export const selectCreateReminderStatus = (state) => state.reminderDetails.status;
export const selectCreateReminderErrorMessage = (state) =>
  state.reminderDetails.errorMessage;

export default createReminderSlice;
