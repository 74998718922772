import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { ResponseAttribute, State, User } from "../../../type";
import { appColor } from "../../constants/customThemeColor";
import { AttributesAndQuestionsLayout } from "../../../layouts/admin/attributesAndQuestions/Layout";
import { AddAttributeModal } from "./addAttributeModal/addAttributeModal";
import { CustomSearchBoxComponent } from "../../components/CustomSearchboxComponent";
import { SearchOption } from "../../components/type";
import { attributeToOption } from "../../../util/attributeOption";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import AttributeListTable from "./attributeListTable/attributeListTable";
import { useHistory, withRouter } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    height: theme.spacing(62.85),
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(2),
    border: "1px solid #E7E7E7",
    borderRadius: theme.spacing(1.25),
    boxShadow: "0 2px 4px 0 rgba(90,90,90,0.1)",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 4, 0),
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
      margin: theme.spacing(3, 0, 4, 0),
    },
  },
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: `${appColor.whiteBackground}`,
    width: "-webkit-fill-available",
    "&:focus": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
    "&:hover": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
  },
  editor: {
    width: "-webkit-fill-available",
    backgroundColor: appColor.pureWhite,
  },
  marginStyle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(1),
    },
  },
  shareCTA: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: appColor.pureWhite,
    backgroundColor: appColor.regularBackgroundColor,
    padding: theme.spacing(1, 2.5),
    marginTop: theme.spacing(3),
    "&:hover": {
      color: appColor.pureWhite,
      backgroundColor: appColor.regularBackgroundColor,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    color: appColor.pureBlack,
  },
  chip: {
    margin: theme.spacing(5, 0, 1, 0),
    borderColor: "#576BEA",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  typography_header_style: {
    margin: theme.spacing(1.5, 0, 0.5, 0),
  },
  btn: {
    height: "fit-content",
    background: "#7C69EC",
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "10px 28px",
  },
}));

const CustomAttributeAndQuestions = (props) => {
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [filteredAttributes, setFilteredAttributes] = React.useState<
    Array<ResponseAttribute>
  >([]);
  const [pageData, setPageData] = React.useState<Array<ResponseAttribute>>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const opt = props.location.state?.option;
  const [option, setOption] = React.useState<SearchOption | null>(
    opt ? opt : null
  );

  const queryParams = new URLSearchParams(props.location.search);
  const pageValue = props.location.state?.pageNo || Number(queryParams.get("page")) ;

  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );
  const history = useHistory();

  const attributes = useSelector(
    (state: State) => state.adminAttributeData.attributes
  );

  const count = attributes?.length;

  useEffect(() => {
    const pageData = option ? [...filteredAttributes] : filteredAttributes?.slice(
      (pageNumber - 1) * 10,
      pageNumber * 10
    );
    setPageData(pageData);
    history.push({
      pathname: '/admin/manage/attributes', search: !option ? `?page=${pageNumber}` : `?search=${option?.name}`
    })
  }, [filteredAttributes, pageNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber])

  useEffect(() => {
    if (option === null) {
      setFilteredAttributes(attributes);
    }
    if (option !== null) {
      const matchingAttributes = attributes.filter(
        (attribute) => attribute?.id === option?.attrId
      );
      setFilteredAttributes(matchingAttributes);
    }
  }, [option, attributes]);

  useEffect(() => {
    setPageNumber(pageValue ? pageValue : 1);
  }, [option]);

  const classes = useStyle();

  const loading: Boolean = useSelector(
    (state: State) => state.adminAttributeData.loading
  );

  const statusCode: number | null = useSelector((state: State) =>
    state.adminAttributeData.status !== 200
      ? state.adminAttributeData.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const handleCancel = () => {
    setOpen(false);
  };

  const renderAddAttributeModal = () => (
    <AddAttributeModal
      open={open}
      handleCancel={handleCancel}
    />
  );

  const renderSearchBox = () => (
    <CustomSearchBoxComponent
      option={option}
      setOption={setOption}
      setPageNumber={setPageNumber}
      options={[...attributes?.map(attributeToOption)]}
      placeholder="Search by attribute name"
    ></CustomSearchBoxComponent>
  );

  const renderAttributesList = () => {
    return (
      <AttributeListTable
        loading={loading}
        data={pageData}
        pageNumber={pageNumber}
      ></AttributeListTable>
    );
  };

  const openAddAttributeModal = () => {
    setOpen(true);
  };

  const renderNewAttributeButton = () => {
    return (
      <Button
        color="primary"
        className={classes.btn}
        onClick={() => openAddAttributeModal()}
        variant="contained"
      >
        New Attribute
      </Button>
    );
  };

  const renderChangePageButtons = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <ChangePageButtonComponent
        pageCount={Math.ceil(filteredAttributes.length / 10)}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      ></ChangePageButtonComponent>
    </Grid>
  );

  return (
    <AttributesAndQuestionsLayout
      props={props}
      loading={loading}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderAddAttributeModal={renderAddAttributeModal}
      renderAttributesList={renderAttributesList}
      renderSearchBox={renderSearchBox}
      renderChangePageButtons={renderChangePageButtons}
      count={count}
      renderNewAttributeButton={renderNewAttributeButton}
    ></AttributesAndQuestionsLayout>
  );
};

const Component = withRouter(CustomAttributeAndQuestions)
export { Component as CustomAttributeAndQuestions }
