import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { avatarColor } from "../../../constants/avatarBackgroundColor";
import { appColor } from "../../../constants/customThemeColor";
import { useCustomStyle } from "../../../../styles";
import theme from "../../../../theme";
import { ResponseScaleProp, State } from "../../../../type";
import { getDateInDesiredFormat } from "../../../../util/GetDateInDesiredFormat";
import { AttributeCard } from "./AttributeCard";
import {
  UserNameProp,
  DateProp,
  AttributesHeadingProp,
  ActionCtaProp,
  RequestTypeProp,
  ContextTextProp,
  AttributeChipComponentProp,
  RequestStatusProp,
} from "./type";
import { FAQCard } from "./FAQCard";

const useStyle = makeStyles((theme) => ({
  userNameGrid: {
    margin: theme.spacing(3, 0, 4.9375, 1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1.5, 0, 2.4375, 1),
    },
  },
  avatar: {
    width: theme.spacing(9.25),
    height: theme.spacing(9.25),
    margin: theme.spacing(0, 1.75, 0, 0),
    fontSize: "xxx-large",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: theme.spacing(0, 1.25, 0, 0),
      fontSize: "x-large",
    },
  },
  typography_body_1: {
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(1, 1.25, 1, 1),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0.5, 0.75, 0.5, 0.5),
    },
  },
  typography_body_2: {
    color: "#272727",
    letterSpacing: theme.spacing(0),
    margin: theme.spacing(5, 0, 1, 1),
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
      margin: theme.spacing(2.875, 0, 1.75, 1),
    },
  },
  typography_body_3: {
    color: "#272727",
    margin: theme.spacing(0, 0, 0, 0),
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  typography_body_4: {
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0.75, 1, 0.75, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0.5, 0.5, 0.5, 0),
    },
  },
  typography_body_5: {
    color: "#272727",
    letterSpacing: theme.spacing(0),
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(4.9375, 0, 3, 1),
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
      margin: theme.spacing(2.4375, 0, 1.75, 1),
    },
  },
  btn: {
    margin: theme.spacing(6.25, 0, 4.25, 0),
    padding: theme.spacing(1.125, 3, 1.125, 2.875),
    backgroundColor: "#576BEA",
    color: "white",
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3.75, 0, 5, 0),
      padding: theme.spacing(1, 1, 1, 1),
    },
  },
  btn_text: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },
  receivedDateGrid: {
    width: theme.spacing(26.25),
    margin: theme.spacing(0, 0, 0, 1),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(18.125),
    },
  },
  typeGrid: {
    width: theme.spacing(18.5),
    margin: theme.spacing(0, 1.5, 0, 1),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(13.125),
      margin: theme.spacing(0, 0.75, 0, 1),
    },
  },
  gridStyle: {
    backgroundColor: "rgba(87,107,234,0.05)",
    height: theme.spacing(4.25),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(2.75),
      borderRadius: theme.spacing(0.25),
    },
  },
  typography_body_context: {
    color: `${appColor.pureBlack}`,
    textAlign: "justify",
    margin: theme.spacing(2, 1, 3, 1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5, 0.5, 0.5),
    },
  },
  chips: {
    borderColor: "rgba(87,107,234,0.5)",
    padding: theme.spacing(2.1, 0, 2.1, 0),
    margin: theme.spacing(0.75, 0.75, 3, 0.75),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.375),
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  divider: {
    backgroundColor: "rgb(140 142 154 / 48%)",
    height: theme.spacing(0.125),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2.5, 0, 1, 0),
    },
  },
  typography_closed: {
    color: "#01BDB3",
    textAlign: "center",
    backgroundColor: "#E6FAF8",
    padding: theme.spacing(0.75),
    fontWeight: 500,
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(11.25),
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(8),
      fontSize: "10px",
    },
  },
  divider2: {
    backgroundColor: "rgb(140 142 154 / 48%)",
    height: theme.spacing(0.125),
    margin: theme.spacing(0, 0, 2.25, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 1.375, 0),
    },
  },
}));

export const CustomDivider = () => {
  const classes = useStyle();
  return <Divider className={classes.divider}></Divider>;
};

export const UserName: React.FC<UserNameProp> = ({ user, headerName }) => {
  const classes = useStyle();
  return (
    <>
      <Grid container justify="flex-start" alignItems="center">
        <Typography className={classes.typography_body_2}>
          {headerName ? headerName : "Name"}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
        className={classes.userNameGrid}
      >
        <Grid item>
          <Avatar
            alt={user?.firstName.toUpperCase()}
            src={user?.imageUrl}
            className={classes.avatar}
            style={
              user?.imageUrl === user?.firstName
                ? {
                    paddingBottom: "0px",
                    backgroundColor:
                      avatarColor[Math.floor(Math.random() * 10)],
                    color: "#FFFFFF",
                  }
                : {}
            }
          />
        </Grid>
        <Grid item>
          <Typography className={classes.typography_body_3}>
            {user?.firstName + " " + user?.lastName}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const RenderDate: React.FC<DateProp> = ({ from, date }) => {
  const classes = useStyle();

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      direction="column"
      className={`${classes.gridStyle} ${classes.receivedDateGrid}`}
    >
      <Grid item>
        <Typography variant="body2" className={classes.typography_body_1}>
          <span
            style={{
              color: "#868686",
            }}
          >
            {from === "RECEIVED_FEEDBACK" && "Received on: "}
            {from === "SHARED_FEEDBACK" && "Shared on: "}
            {from === "ASSESSMENT_SUBMITTED" && "Submitted on: "}
            {(from === "RECEIVED_REQUEST" || from === "SENT_REQUEST") &&
              "Requested on: "}
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" className={classes.typography_body_4}>
          {getDateInDesiredFormat(new Date(date))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const RenderTypeOfRequest: React.FC<RequestTypeProp> = ({
  requestId,
}) => {
  const classes = useStyle();
  return (
    <>
      {requestId && (
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="row"
          className={`${classes.gridStyle} ${classes.typeGrid}`}
        >
          <Grid item>
            <Typography variant="body2" className={classes.typography_body_1}>
              <span
                style={{
                  color: "#868686",
                }}
              >
                Type:
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              className={classes.typography_body_4}
              style={{ margin: theme.spacing(0, 0.5, 0, 0) }}
            >
              {"Requested"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const ContextText: React.FC<ContextTextProp> = ({ text, from }) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  return (
    <>
      {text && (
        <>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="row"
          >
            <Typography variant="h6" className={`${classes.typography_body_2}`}>
              Context
            </Typography>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="row"
          >
            <Typography
              className={`${customClasses.typography_font_14_10} ${classes.typography_body_context}`}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </Grid>
          {from === "ADMIN" && <CustomDivider />}
        </>
      )}
    </>
  );
};

export const AttributeChipComponent: React.FC<AttributeChipComponentProp> = ({
  attributes,
  from,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  let attributeNames: Array<string> = [];
  if (attributes?.length === 1 && typeof attributes[0] !== "string") {
    attributeNames = attributes[0].name.split(",");
  }
  return (
    <>
      <Grid container justify="flex-start" alignItems="center" direction="row">
        <Typography variant="h6" className={`${classes.typography_body_2}`}>
          Requested Attributes
        </Typography>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        {attributes &&
          attributeNames.length === 0 &&
          attributes.map((attribute) =>
            typeof attribute === "string" ? (
              <Chip
                key={attribute}
                label={
                  <Typography
                    variant="h6"
                    className={`${customClasses.typography_font_14_10}`}
                  >
                    {attribute}
                  </Typography>
                }
                variant="outlined"
                color="primary"
                className={classes.chips}
              />
            ) : (
              <Chip
                key={attribute?.name}
                label={
                  <Typography
                    variant="h6"
                    className={`${customClasses.typography_font_14_10}`}
                  >
                    {attribute?.name}
                  </Typography>
                }
                variant="outlined"
                color="primary"
                className={classes.chips}
              />
            )
          )}
        {attributeNames.length > 0 &&
          attributeNames.map((a) => (
            <Chip
              key={a}
              label={
                <Typography
                  variant="h6"
                  className={`${customClasses.typography_font_14_10}`}
                >
                  {a}
                </Typography>
              }
              variant="outlined"
              color="primary"
              className={classes.chips}
            />
          ))}
      </Grid>
      {from && <CustomDivider />}
    </>
  );
};

export const RenderAttributeText: React.FC<AttributesHeadingProp> = ({
  from,
}) => {
  const classes = useStyle();
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Typography className={classes.typography_body_2}>
        {from === "RECEIVED_FEEDBACK" && "Attributes Feedback Received on"}
        {(from === "SHARED_FEEDBACK" || from === "RECEIVED_REQUEST") &&
          "Attributes Feedback Shared on"}
        {from === "ASSESSMENT_SUBMITTED" &&
          "Attributes assessment submitted on"}
      </Typography>
    </Grid>
  );
};

export const RenderAttributesDetails: React.FC<AttributeChipComponentProp> = ({
  attributes,
  from,
  faqs,
}) => {
  const classes = useStyle();
  const [showDetails, setShowDetails] = React.useState<Boolean[]>([]);
  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector((state: State) => state.data.data?.scaleResponseEntityList) ??
    emptyList;
  useEffect(() => {
    if (attributes && attributes.length > 0) {
      const arg: Array<Boolean> = [];
      for (let index = 0; index < attributes.length; index++) {
        arg.push(true);
      }
      setShowDetails(arg);
    } else if (faqs) {
      const arg: Array<Boolean> = [];
      for (let index = 0; index < faqs.length; index++) {
        arg.push(false);
      }
      setShowDetails(arg);
    }
  }, [attributes, faqs]);
  return (
    <>
      {attributes &&
        attributes.map(
          (attribute, idx) =>
            typeof attribute !== "string" && (
              <AttributeCard
                id={idx}
                attribute={attribute}
                key={attribute.name}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                responseScales={responseScales}
                from={from}
              ></AttributeCard>
            )
        )}
      {faqs &&
        faqs.map((faq, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={12} container alignItems="center" direction="row">
              <Grid item xs={12}>
                <FAQCard
                  id={idx}
                  attribute={{
                    attributeId: 1,
                    questions: [],
                    context: "",
                    suggestions: "",
                    resources: "",
                    name: "",
                  }}
                  key={faq.question}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  responseScales={responseScales}
                  from={from}
                  faq={faq}
                ></FAQCard>
              </Grid>
              <Grid item xs={12}>
                {idx < faqs.length && (
                  <Divider className={classes.divider2}></Divider>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
    </>
  );
};

export const ActionCta: React.FC<ActionCtaProp> = ({
  from,
  setValue,
  userStatus,
}) => {
  const classes = useStyle();
  return (
    <>
      {userStatus === "ACTIVE" && (
        <Grid container justify="flex-end" alignItems="center" direction="row">
          <Button
            className={classes.btn}
            onClick={() => {
              if (from === "RECEIVED_FEEDBACK") setValue(2);
              else setValue(1);
            }}
          >
            <Typography className={classes.btn_text}>
              {from === "RECEIVED_FEEDBACK" && "REQUEST ANOTHER"}
              {from === "SHARED_FEEDBACK" && "SHARE ANOTHER"}
            </Typography>
          </Button>
        </Grid>
      )}
    </>
  );
};

export const Status: React.FC<RequestStatusProp> = ({ status }) => {
  const classes = useStyle();

  return (
    <>
      <Grid container justify="flex-start" alignItems="center">
        <Typography
          variant="h6"
          className={`${classes.typography_body_2}`}
          style={{ marginTop: "0px" }}
        >
          Status
        </Typography>
      </Grid>
      <Grid container justify="flex-start" alignItems="center">
        {status === "CLOSED" && (
          <Typography className={classes.typography_closed}>
            Received
          </Typography>
        )}
      </Grid>
    </>
  );
};
