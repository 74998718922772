import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ReceivedFeedbacksLayout } from "../../../layouts/user/received_feedback/Layout";
import { countReceivedFeedback } from "../../../redux/slices/CountReceivedFeedbacksSlice";
import { receivedFeedback } from "../../../redux/slices/ReceivedFeedbacksSlice";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import {
  ReceivedFeedbackSlice,
  RequestingParams,
  State,
  User,
  UserAndAttributeSliceProps,
} from "../../../type";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import { UserSearchBox } from "../../components/UserSearchBoxComponent";
import { NoRecordComponent } from "../../components/list/noRecordPage/NoRecordPage";
import { ListContainerComponent } from "../../components/list/ListContainer";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { CustomSelect } from "../../components/CustomSelect";

export const ReceivedFeedbacks = (props) => {
  const dispatch = useDispatch();
  const pageValue = props.location.state?.pageNo;
  const usr = props.location.state?.user;
  const tabNo = props.location.state?.tabValue;
  const [value, setValue] = React.useState(-1);
  const [tabValue, setTabValue] = React.useState(tabNo ? tabNo : 0);
  const [receivedFeedbackId, setReceivedFeedbackId] = React.useState<number>(0);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [user, setUser] = React.useState<User | null>(usr ? usr : null);
  const [allFeedbackCount, setAllFeedbackCount] = React.useState<number>(-1);
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: false,
      isClients: true,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
  }, []);

  useEffect(() => {
    let requestParams: string = "";
    if (user) requestParams += "?senderId=" + user.id;

    if (user) {
      if (tabValue === 1) requestParams += "&requestTag=INTERNAL";
      else if (tabValue === 2) requestParams += "&requestTag=EXTERNAL";
      else requestParams += "&requestTag=ANY";
    } else {
      if (tabValue === 1) requestParams += "?requestTag=INTERNAL";
      else if (tabValue === 2) requestParams += "?requestTag=EXTERNAL";
      else requestParams += "?requestTag=ANY";
    }
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(countReceivedFeedback(requestingData));
  }, [user, tabValue]);

  useEffect(() => {
    let requestParams: string = "";
    requestParams += "?offSet=" + (pageNumber - 1) * 10;
    if (user) requestParams += "&senderId=" + user.id;
    if (tabValue === 1) requestParams += "&requestTag=INTERNAL";
    else if (tabValue === 2) requestParams += "&requestTag=EXTERNAL";
    else requestParams += "&requestTag=ANY";
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(receivedFeedback(requestingData));
  }, [pageNumber, user, tabValue]);

  const users: Array<User> = useSelector(
    (state: State) => state.data.data?.users ?? []
  );
  const clients: Array<User> = useSelector(
    (state: State) => state.data.data?.clients ?? []
  );

  const count: number = useSelector(
    (state: State) => state.countReceived.data ?? 0
  );

  useEffect(() => {
    if (user === null && tabValue === 0) setAllFeedbackCount(count);
  }, [count, tabValue, user]);

  const feedbackLoading = useSelector(
    (state: State) => state.receivedFeedbacks.loading
  );

  const receivedFeedbackData: Array<ReceivedFeedbackSlice> = [];
  const feedbackData =
    useSelector((state: State) => state.receivedFeedbacks.feedbacks) ??
    receivedFeedbackData;
  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.countReceived.status !== 200
      ? state.countReceived.status
      : state.receivedFeedbacks.status !== 200
      ? state.receivedFeedbacks.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderSearchBox = () => (
    <UserSearchBox
      user={user}
      setUser={setUser}
      setPageNumber={setPageNumber}
      users={[...users, ...clients]}
    ></UserSearchBox>
  );

  const renderFilter = () => (
    <CustomSelect
      tabValue={tabValue}
      setTabValue={setTabValue}
      setPageNumber={setPageNumber}
      name={"RECEIVED_FEEDBACK"}
    />
  );

  const renderReceivedFeedbacksList = () => (
    <ListContainerComponent
      loading={feedbackLoading}
      data={feedbackData}
      setDocumentId={setReceivedFeedbackId}
      from={"RECEIVED_FEEDBACK"}
    ></ListContainerComponent>
  );

  const renderNoFeedback = () => (
    <NoRecordComponent setValue={setValue} from={"RECEIVED_FEEDBACK"} />
  );

  const renderChangePageButtons = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <ChangePageButtonComponent
        pageCount={Math.ceil(count / 10)}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      ></ChangePageButtonComponent>
    </Grid>
  );

  if (value === 2) return <Redirect to={`/feedback/requests/employee`} />;

  if (receivedFeedbackId > 0) {
    history.replace({
      pathname: "/feedback/received",
      state: {
        pageNo: pageNumber,
        user: user,
        tabValue: tabValue,
      },
    });
    history.push({
      pathname: `/feedback/received/detail/${receivedFeedbackId}`,
      state: {
        pageNumber: pageNumber,
        user: user,
        tabValue: tabValue,
      },
    });
  }

  return (
    <ReceivedFeedbacksLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      count={count}
      showNoRequest={allFeedbackCount === 0}
      renderErrorModal={renderErrorModal}
      renderSearchBox={renderSearchBox}
      renderReceivedFeedbacksList={renderReceivedFeedbacksList}
      renderChangePageButtons={renderChangePageButtons}
      renderNoFeedbacks={renderNoFeedback}
      renderFilter={renderFilter}
    ></ReceivedFeedbacksLayout>
  );
};
