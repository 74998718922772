import { OptionType } from "../modules/components/Dropdown/helper";

export const FREQUENCY_OPTIONS: OptionType[] = [
    {
        id: 1,
        key: "ONE_MONTH",
        value: "Monthly"
    },
    {
        id: 2,
        key: "THREE_MONTHS",
        value: "Quarterly"
    },
    {
        id: 3,
        key: "SIX_MONTHS",
        value: "Half yearly"
    },
    {
        id: 4,
        key: "TWELVE_MONTHS",
        value: "Yearly"
    }
]
