import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { State } from "../../../../type";
import { HomeLayout } from "../../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5.5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    minHeight: `${window.innerHeight - 102}px`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 3, 3, 3),
      marginTop: theme.spacing(11.25),
    },
  },
}));

export const SelfAssessmentDetailLayout = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  value,
  setValue,
  renderErrorModal,
  renderSharedDate,
  renderAttributesDetail,
  renderEditAssessmentButton
}) => {
  const classes = useStyle();
  window.scrollTo(0, 0);
  document.title = "Grow-Wize | Self Assessment Detail";
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const loading: Boolean = useSelector(
    (state: State) => state.sharedAssessmentDetail.loading
  );

  const renderToolBar = () => (
    <ToolBar
      headerName="Self Assessment Detail"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={value}
      setValue={setValue}
      tabValue={-1}
      validLength={false}
    ></ToolBar>
  );

  const renderSelfAssessmentDetailLayout = () => (
    <Grid
      container
      direction="row"
      className={classes.root}
      alignItems="stretch"
    >
      <Grid container alignItems="stretch" direction="column">
        {renderErrorModal()}
        <Grid style={{"display": "flex", "justifyContent": "space-between"}}>
          {renderSharedDate()}
          {renderEditAssessmentButton()}
        </Grid>
        {renderAttributesDetail()}
      </Grid>
    </Grid>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <HomeLayout
      loading={loading}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderToolBar={renderToolBar}
      renderSelectedOption={renderSelfAssessmentDetailLayout}
    ></HomeLayout>
  );
};
