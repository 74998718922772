import {
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import {
  CancelButtonProps,
} from "../../../type";

const useStyle = makeStyles((theme) => ({
  btn1: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(1.375, 4.125, 1.375, 4.125),
    backgroundColor: `${appColor.regularBackgroundColor}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
  btn_: {
    margin: theme.spacing(3, 5, 0, 0),
    borderColor: `${appColor.pureWhite}`,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3.5, 0, 0),
    },
  },
}));

export const SendButton = ({validLength}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  return (
    <Button
      type="submit"
      className={classes.btn1}
      color="primary"
      variant="contained"
      disabled={!validLength}
    >
      <Typography variant="h5" className={`${customClasses.typography_font_14_10_600}`}>
        Submit
      </Typography>
    </Button>
  );
};

export const CancelButton: React.FC<CancelButtonProps> = ({ setValue }) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();

  async function handleCancel(e) {
    e.preventDefault();
    setValue(0);
  }
  return (
    <Button variant="outlined" onClick={handleCancel} className={classes.btn_}>
      <Typography variant="h5" className={customClasses.typography_font_14_10_600}>
        Cancel
      </Typography>
    </Button>
  );
};
