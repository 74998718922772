import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patch } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";
import { EditAssessmentProps } from "../../modules/user/self_assessment/type";

export const editAssessment = createAsyncThunk(
  "/home/self-assessment/edit",
    async (params: EditAssessmentProps, storeActions) => {
    const response: any = await patch(`api/v1/user/assessment?assessmentId=${params.assessmentId}`, params, storeActions);
    if (response.status === HttpStatus.OK) {
      return {
        status: response.status,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const editAssessmentSlice = createSlice({
  name: "editSelfAssessment",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editAssessment.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(editAssessment.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(editAssessment.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = editAssessmentSlice.actions;

export default editAssessment;
