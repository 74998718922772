import { AnswerTypeEnum } from "../enums/answerTypeEnums";

export const QuestionResponseTypeMapping = {
  [AnswerTypeEnum.LIKERT]: "Express emotions",
  [AnswerTypeEnum.LIKERT_NA]: "Express emotions with N/A",
  [AnswerTypeEnum.VRIDDHI]: "Vriddhi Framework",
  [AnswerTypeEnum.STRING]: "Free text field",
} as const;

export const RESPONSE_TYPE_LIST = [
  { id: 1, key: AnswerTypeEnum.LIKERT, value: "Express emotions" },
  { id: 2, key: AnswerTypeEnum.LIKERT_NA, value: "Express emotions with NA" },
  { id: 4, key: AnswerTypeEnum.STRING, value: "Free text field" },
];

if (["dev", "stage"].includes(process.env.REACT_APP_ENV as string)) {
  RESPONSE_TYPE_LIST.splice(2, 0, {
    id: 3,
    key: AnswerTypeEnum.VRIDDHI,
    value: "Vriddhi Framework",
  });
}
