import jwt_decode from "jwt-decode";
import jwt_encode from "jsonwebtoken";

export function IsAuthenticate(logout, setLogout, state) {
  if (
    (state.auth.status === 401 ||
      state.countReceived.status === 401 ||
      state.countRequestsReceived.status === 401 ||
      state.countRequestsSend.status === 401 ||
      state.countShared.status === 401 ||
      state.data.status === 401 ||
      state.receivedFeedbackDetail.status === 401 ||
      state.receivedFeedbacks.status === 401 ||
      state.receivedRequests.status === 401 ||
      state.request.status === 401 ||
      state.receivedRequestDetails.status === 401 ||
      state.sentRequestDetails.status === 401 ||
      state.sendRequests.status === 401 ||
      state.shareFeedback.status === 401 ||
      state.sharedFeedbackDetail.status === 401 ||
      state.sharedFeedbacks.status === 401) &&
    !state.auth.loading &&
    !state.countReceived.loading &&
    !state.countRequestsReceived.loading &&
    !state.countRequestsSend.loading &&
    !state.countShared.loading &&
    !state.data.loading &&
    !state.receivedFeedbackDetail.loading &&
    !state.receivedFeedbacks.loading &&
    !state.receivedRequests.loading &&
    !state.request.loading &&
    !state.receivedRequestDetails.loading &&
    !state.sentRequestDetails.loading &&
    !state.sendRequests.loading &&
    !state.shareFeedback.loading &&
    !state.sharedFeedbackDetail.loading &&
    !state.sharedFeedbacks.loading &&
    !logout
  ) {
    alert("Session expired\nPlease login again");
    setLogout(true);
  }
}

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const tokenData: any = jwt_decode(token);
    const expiration = new Date(tokenData?.exp * 1000);
    return expiration < new Date();
  } catch (e) {
    console.log(e);
  }
};

export const createMockToken = () => {
  const payload = {
    userId: 123,
    username: "exampleUser",
  };

  const secretKey = "testkey";

  const options = {
    algorithm: "HS256",
    expiresIn: "1d",
  };

  const token = jwt_encode.sign(payload, secretKey, options);
  return token;
}

