import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { ToolBar } from "../../../modules/home/ToolBar";
import { State } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5.5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    minHeight: `${window.innerHeight - 102}px`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 3, 3, 3),
      marginTop: theme.spacing(11.25),
    },
  },
  form: {
    marginTop: theme.spacing(3),
    width: "inherit",
  },
}));

export const SelfAssessmentLayout = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  value,
  setValue,
  tabValue,
  renderTabs,
  renderErrorModal,
  isAnyChange,
  onAnyChange,
  savedDraftDate,
  renderValidationModal,
  renderModal,
  handleSubmit,
  renderCancelButton,
  renderSendButton,
  renderAddAttributeTitleText,
  renderAddNewButton,
  renderHeaderTabs,
  renderAssessmentList,
  renderChangePageButtons,
  validLength,
  renderRouterPrompt,
  isEditAssessmentView
}) => {
  const classes = useStyle();
  if (tabValue === 0) {
    document.title = "Grow-Wize | Self Assessment";
  } else {
    document.title = "Grow-Wize | Shared assessment";
  }
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const loading: Boolean = useSelector(
    (state: State) => state.data.loading || state.shareAssessment.loading
  );
  const draftLoading: Boolean =
    useSelector((state: State) => state.sharedAssessmentDetail.loading) ?? null;

  const renderToolBar = () => (
    <ToolBar
      headerName="Self Assessment"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={value}
      setValue={setValue}
      tabValue={isEditAssessmentView ? -2 : tabValue}
      validLength={validLength}
      isDataChange={isAnyChange}
      onAnyChange={onAnyChange}
      savedDraftDate={savedDraftDate}
    ></ToolBar>
  );

  const renderShareFeedbackLayout = () => (
    <Grid
      container
      xs={12}
      direction="column"
      className={classes.root}
      justify="flex-start"
      alignItems="flex-start"
    >
      {renderTabs()}
      {tabValue === 0 && (
        <>
          {renderErrorModal()}
          {renderModal()}
          {loading && value !== 6 && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "65vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
          {(!loading || (loading && value === 6)) && (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid xs={12} item container direction="row">
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  {draftLoading && (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: "35vh" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  {!draftLoading && (
                    <>
                      {renderAddAttributeTitleText()}

                      {renderAddNewButton()}
                    </>
                  )}
                </Grid>
                <Grid container justify="center" alignItems="center">
                  {renderValidationModal()}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  {renderCancelButton()}
                  {renderSendButton()}
                  {renderRouterPrompt()}
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
      {tabValue === 1 && (
        <>
          <Paper variant="outlined" square style={{ width: "inherit" }}>
            {renderHeaderTabs()}
            {renderAssessmentList()}
          </Paper>
          {renderChangePageButtons()}
        </>
      )}
    </Grid>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <HomeLayout
      loading={draftLoading}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderToolBar={renderToolBar}
      renderSelectedOption={renderShareFeedbackLayout}
    ></HomeLayout>
  );
};
