import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { HomeLayout } from "../../../home/Layout";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { State } from "../../../../type";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    width: `${window.innerWidth - 224}px`,
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
}));

export const FeedbackAssessmentDetailLayout = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  headerName,
  renderErrorModal,
  renderDateComponent,
  renderName,
  renderContext,
  renderAllAttributes,
  renderAttributesDetail,
  renderDivider,
  tabValue,
}) => {
  document.title = `Grow-Wize | ${headerName}`;
  window.scrollTo(0, 0);
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const loading: Boolean = useSelector(
    (state: State) =>
      state.receivedFeedbackDetailByMember.loading ||
      state.sharedFeedbackDetailByMember.loading ||
      state.sharedAssessmentDetailByMember.loading
  );

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName={`${headerName}`}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderFeedbackAssessmentDetailLayout = () => (
    <Grid
      container
      direction="column"
      className={classes.root}
      justify="flex-start"
    >
      <Grid container alignItems="stretch" direction="column">
        {renderErrorModal()}
        {renderDateComponent()}
        {renderName()}
        {renderDivider()}
        {renderContext()}
        {renderAllAttributes()}
        {renderAttributesDetail()}
      </Grid>
    </Grid>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderFeedbackAssessmentDetailLayout}
    ></HomeLayout>
  );
};
