import { TabsProps } from "../type";
import { Divider, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { CountProps, Counts, State } from "../../../../type";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  tabs: {
    width: "-webkit-fill-available",
    padding: theme.spacing(0),
  },
  tabsMozWidth: {
    width: "-moz-available",
  },
  divider: {
    width: "inherit",
    border: "1px solid #EFEEF5",
  },
  indicator: {
    backgroundColor: "#7C69EC",
  },
  tabsGrid: {
    backgroundColor: "#F5F6FA",
  },
  tabText: {
    textTransform: "none",
    width: "inherit",
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  defaultColor: {
    color: "#A0A0A0",
  },
  activeColor: {
    color: "#7C69EC",
  },
  tab: {
    width: "inherit",
    minWidth: theme.spacing(10),
    padding: theme.spacing(0.5, 2.5, 0, 2.5),
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const TitleBarTabs: React.FC<TabsProps> = ({
  tabValue,
  setTabValue,
  setPageNumber,
  setItemValue,
}) => {
  const classes = useStyle();
  const counts: CountProps = useSelector(
    (state: State) => state.countSharedAndReceivedFeedback?.data
  );
  const allCounts: CountProps = useSelector(
    (state: State) => state.countAllSharedAndReceived?.data
  );

  const requestReceivedCount: Counts | null = useSelector(
    (state: State) => state.countRequestReceivedByMember.data
  );

  const requestSentCount: number | null = useSelector(
    (state: State) => state.countRequestSendByMember.data
  );

  const countAssessments: number | null = useSelector(
    (state: State) => state.countAssessmentSharedByMember.data
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPageNumber(1);
    setItemValue(1);
    setTabValue(newValue);
  };

  return (
    <Grid
      xs={12}
      item
      className={classes.tabsGrid}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        className={`${classes.tabsMozWidth} ${classes.tabs}`}
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab
          key={0}
          classes={{ root: classes.tab }}
          label={
            tabValue === 0 ? (
              <span
                className={`${classes.tabText} ${classes.activeColor}`}
              >{`Received (${counts?.receivedCount})`}</span>
            ) : (
              <span
                className={`${classes.tabText} ${classes.defaultColor}`}
              >{`Received (${counts?.receivedCount})`}</span>
            )
          }
          {...a11yProps(0)}
        />
        <Tab
          key={1}
          classes={{ root: classes.tab }}
          label={
            tabValue === 1 ? (
              <span
                className={`${classes.tabText} ${classes.activeColor}`}
              >{`Shared (${allCounts?.sharedCount})`}</span>
            ) : (
              <span
                className={`${classes.tabText} ${classes.defaultColor}`}
              >{`Shared (${allCounts?.sharedCount})`}</span>
            )
          }
          {...a11yProps(1)}
        />
        <Tab
          key={2}
          classes={{ root: classes.tab }}
          label={
            tabValue === 2 ? (
              <span
                className={`${classes.tabText} ${classes.activeColor}`}
              >{`Received request (${typeof requestReceivedCount?.pendingRequestCount === 'number' ? requestReceivedCount?.pendingRequestCount : 0})`}</span>
            ) : (
              <span
                className={`${classes.tabText} ${classes.defaultColor}`}
              >{`Received request (${typeof requestReceivedCount?.pendingRequestCount === 'number' ? requestReceivedCount?.pendingRequestCount : 0})`}</span>
            )
          }
          {...a11yProps(2)}
        />
        <Tab
          key={3}
          classes={{ root: classes.tab }}
          label={
            tabValue === 3 ? (
              <span
                className={`${classes.tabText} ${classes.activeColor}`}
              >{`Sent request (${typeof requestSentCount === 'number' ? requestSentCount : 0})`}</span>
            ) : (
              <span
                className={`${classes.tabText} ${classes.defaultColor}`}
              >{`Sent request (${typeof requestSentCount === 'number' ? requestSentCount : 0})`}</span>
            )
          }
          {...a11yProps(3)}
        />
        <Tab
          key={4}
          classes={{ root: classes.tab }}
          label={
            tabValue === 4 ? (
              <span
                className={`${classes.tabText} ${classes.activeColor}`}
              >{`Self assessments (${typeof countAssessments === 'number' ? countAssessments : 0})`}</span>
            ) : (
              <span
                className={`${classes.tabText} ${classes.defaultColor}`}
              >{`Self assessments (${typeof countAssessments === 'number' ? countAssessments : 0})`}</span>
            )
          }
          {...a11yProps(4)}
        />
      </Tabs>
      <Divider className={classes.divider} />
    </Grid>
  );
};
