import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles((theme) => ({
    modalFooter: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2.5),
        alignSelf: "flex-end"
    },
    cancelText: {
        alignItems: "center",
        color: "#868686",
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
    submitText: {
        alignItems: "center",
        textTransform: "none",
        color: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
    submitButton: {
        margin: theme.spacing(2, 0, 0, 0),
        padding: theme.spacing(1.375, 4.95, 1.375, 4.95),
        backgroundColor: "#7C69EC",
        "&:hover": {
            backgroundColor: "#5c49cc",
        },
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
        },
    },
    cancelButton: {
        margin: theme.spacing(2, 2, 0, 0),
        padding: theme.spacing(1.125, 4.95, 1.375, 4.95),
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(3, 3.5, 0, 0),
            padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
        },
    },
}))

export const ModalFooter = ({ handleCancel, handleSubmit, buttonLabel, secondaryLabel = "Cancel", disableActions = false }) => {
    const classes = useStyle()
    return (
        <Grid
            xs={12}
            item
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.modalFooter}
        >
            <Grid item>
                <Button
                    variant="text"
                    onClick={handleCancel}
                    className={classes.cancelButton}
                    disabled={disableActions}
                >
                    <Typography
                        variant="h5"
                        className={classes.cancelText}
                    >
                        {secondaryLabel}
                    </Typography>
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={handleSubmit}
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    disabled={disableActions}
                >
                    <Typography
                        variant="h5"
                        className={classes.submitText}
                    >
                        {buttonLabel}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}
