import {
  Button,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { QuestionResponseTypeMapping, RESPONSE_TYPE_LIST } from "../../../../constants/questionConstants";
import { addQuestionModalStyles } from "../addQuestionModal/helper";
import { State } from "../../../../type";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  UpdateQuestionSliceProp,
  clearUpdateQuestionData,
  selectUpdateQuestionResponseErrorMessage,
  selectUpdateQuestionResponseHttpStatus,
  selectUpdateQuestionResponseLoading,
  updateQuestion,
} from "../../../../redux/slices/UpdateQuestionSlice";

import { getMatchingResponseType, questionComponentStyle, scrollToTargetAdjusted } from "./helper";
import { HttpStatus } from "../../../../enums/httpStatus";
import { SwitchButton } from "../../../components/SwitchButton";
import { AttributeStatus } from "../helper";
import { UpdateQuestionStatusModal } from "../updateQuestionStatusModal/updateQuestionStatusModal";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { OptionType } from "../../../components/Dropdown/helper";
import { AnswerTypeEnum } from "../../../../enums/answerTypeEnums";

interface IValidationErrors {
  questionText: string;
  responseType: string;
}

export const Question = ({ id, questionText, answerType, index, attributeStatus, setEnablingQuestion, handleUpdateQuestionSuccess, setIsEditMode, isEditMode, handleQuestionStatusUpdateSuccess }) => {
  const classes = { ...questionComponentStyle(), ...addQuestionModalStyles() };
  const [isCurrentQuestionEditing, setIsCurrentQuestionEditing] = React.useState<boolean>(false);
  const [questionName, setQuestionName] = React.useState<string>(questionText);
  const [selectedResponseType, setSelectedResponseType] = React.useState<OptionType | undefined>(getMatchingResponseType(answerType));
  const [openConfirmStatusUpdateModal, setOpenConfirmStatusUpdateModal] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IValidationErrors>({
    questionText: "",
    responseType: "",
  });
  const [edited, setEdited] = React.useState<boolean>(false);

  const isDisabled = attributeStatus === AttributeStatus.INACTIVE;

  const token = useSelector((state: State) => state.auth.token);
  const dispatch = useDispatch();
  const { attributeId }: any = useParams();

  const updateQuestionResponseStatus = useSelector(selectUpdateQuestionResponseHttpStatus);
  const updateQuestionErrorMessage = useSelector(
    selectUpdateQuestionResponseErrorMessage
  );


  const shouldFadeOut = isEditMode && !isCurrentQuestionEditing

  const checkValidation = (): boolean => {
    let isValid = true;
    const errors = {} as IValidationErrors;
    if (questionName.trim().length === 0) {
      isValid = false;
      errors.questionText = "Please write a question here";
    }
    if (!selectedResponseType) {
      isValid = false;
      errors.responseType = "Please select response type";
    }
    setErrors(errors);
    return isValid;
  };

  const handleUpdateQuestion = () => {
    if (!edited && checkValidation()) {
      const params: UpdateQuestionSliceProp = {
        attributeId,
        questionId: id,
        questionDetails: {
          questionText: questionName,
          answerType: selectedResponseType?.key as AnswerTypeEnum,
        },
      };
      setEdited(true);
      dispatch(updateQuestion(params));
    }
  };

  const isUpdatingQuestion = useSelector(selectUpdateQuestionResponseLoading);

  useEffect(() => {
    if(isCurrentQuestionEditing && !isUpdatingQuestion) {
      setIsCurrentQuestionEditing(false);
    }
  }, [questionText, answerType, isUpdatingQuestion])


  useEffect(() => {
    switch (updateQuestionResponseStatus) {
      case HttpStatus.OK:
        if (isCurrentQuestionEditing) {
          handleUpdateQuestionSuccess();
          setEdited(false);
          return;
        }
        handleQuestionStatusUpdateSuccess({ status: attributeStatus, questionId: id });
        break;
      case HttpStatus.BAD_REQUEST:
        const validationErrors = { ...errors };
        validationErrors.questionText = updateQuestionErrorMessage;
        setErrors(validationErrors);
        setEdited(false);
        break;
      default:
        break;
    }
  }, [updateQuestionResponseStatus]);

  const resetQuestionData = () => {
    setIsCurrentQuestionEditing(false);
    setErrors({
      questionText: "",
      responseType: "",
    } as IValidationErrors);
    setQuestionName(questionText);
    dispatch(clearUpdateQuestionData);
  };

  const editQuestion = () => {
    resetQuestionData();
    setIsCurrentQuestionEditing(true);
    setIsEditMode(true);
  };

  const updateQuestionStatus = (status: AttributeStatus) => {
    const params: UpdateQuestionSliceProp = {
      attributeId,
      questionId: id,
      questionDetails: {
        questionStatus: status
      },
    };
    dispatch(updateQuestion(params));
  }

  const handleUpdateStatus = () => {
    if (isDisabled) {
      setEnablingQuestion(true);
      updateQuestionStatus(AttributeStatus.ACTIVE);
      return
    }
    setOpenConfirmStatusUpdateModal(true);
  }

  const handleCancelStatusUpdate = () => {
    setOpenConfirmStatusUpdateModal(false);
  }

  const handleCloseUpdateStatusModal = () => {
    setOpenConfirmStatusUpdateModal(false);
  }

  const renderUpdateQuestionStatusModal = () => {
    return (
      <UpdateQuestionStatusModal
        open={openConfirmStatusUpdateModal}
        handleCancel={handleCancelStatusUpdate}
        handleConfirm={() => updateQuestionStatus(isDisabled ? AttributeStatus.ACTIVE : AttributeStatus.INACTIVE)}
        onClose={handleCloseUpdateStatusModal}
      />
    )
  }

  const isEditingQuestion = useSelector((state: State) => state.updateQuestionData?.loading);
  const editQuestionStatus = useSelector((state: State) => state.updateQuestionData?.status);

  useEffect(() => {
    if (edited && !isEditingQuestion && editQuestionStatus !== HttpStatus.OK) {
      setEdited(false)
    }
  }, [edited, isEditingQuestion])

  return (
    <Grid container direction="column" className={`${classes.container} ${shouldFadeOut ? classes.disabled : ""}`}>
      {renderUpdateQuestionStatusModal()}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={`${classes.header} ${isDisabled ? classes.disabledHeader : ""}`}
      >
        <Typography className={`${classes.title} ${isDisabled ? classes.disabledText : ""}`}>Question {index + 1}</Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.actionButtons}
        >
          {!isCurrentQuestionEditing ? (
            <>
              <Button
                variant="text"
                className={`${classes.editButton} ${isDisabled ? classes.disabledText : ""}`}
                onClick={editQuestion}
                disabled={isDisabled}
              >
                Edit
              </Button>
              <SwitchButton checked={!isDisabled} onChange={handleUpdateStatus} helperText={isDisabled ? "Enable question" : "Disable question"} />
            </>
          ) : (
            <>
              <Button
                variant="text"
                className={classes.editButton}
                onClick={handleUpdateQuestion}
                disabled={edited}
              >
                Save
              </Button>
              <Button
                variant="text"
                className={classes.editButton}
                onClick={() => {
                  resetQuestionData();
                  setIsCurrentQuestionEditing(false);
                  setIsEditMode(false);
                }}
                disabled={edited}
              >
                Cancel
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.body}>
        <Grid direction="column" className={classes.fullWidth}>
          {!isCurrentQuestionEditing ? (
            <Typography className={`${classes.question_text} ${isDisabled ? classes.disabledText : ""}`}>
              {questionText}
            </Typography>
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Question Name"
              value={questionName}
              error={errors.questionText ? true : false}
              helperText={errors?.questionText}
              className={classes.questionNameInput}
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestionName(event.target.value);
                checkValidation();
              }}
            ></TextField>
          )}
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className={classes.responseType}
          >
            <Typography className={`${classes.label} ${isDisabled ? classes.disabledText : ""}`}>Response Type:</Typography>
            {!isCurrentQuestionEditing ? (
              <Typography className={`${classes.value} ${isDisabled ? classes.disabledText : ""}`}>
                {QuestionResponseTypeMapping[answerType] || ""}
              </Typography>
            ) : (
              <Dropdown
                options={RESPONSE_TYPE_LIST}
                onSelect={setSelectedResponseType}
                placeholder="Select response"
                selectedOption={selectedResponseType}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
