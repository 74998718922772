import {
  Avatar,
  Grid,
  Paper,
  Hidden,
  Fade,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appColor } from "../../../constants/customThemeColor";
import { deleteDraftData } from "../../../../redux/slices/DeleteDraftSlice";
import { useCustomStyle } from "../../../../styles";
import theme from "../../../../theme";
import {
  FeedbackDetailAPICallProps,
  SharedDraftProps,
  State,
} from "../../../../type";
import { getDateInDesiredFormat } from "../../../../util/GetDateInDesiredFormat";

const useStyle = makeStyles((theme) => ({
  name: {
    textAlign: "left",
    padding: theme.spacing(1, 1, 1, 1),
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      paddingLeft: "3vw",
    },
  },
  typography_body_1: {
    textAlign: "left",
    padding: theme.spacing(1, 1, 1, 1),
  },
  typography_pending: {
    textAlign: "center",
    width: theme.spacing(11.25),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(8),
    },
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  nameAndPicture: {
    padding: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  modalGrid: {
    width: theme.spacing(113),
    height: theme.spacing(35),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 0, 4, 0),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(40),
      height: theme.spacing(25),
      padding: theme.spacing(0, 0, 6, 0),
    },
  },
  deleteDraftHeader: {
    color: "#272727",
    margin: theme.spacing(2.75, 0, 3, 3),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.75),
      margin: theme.spacing(1.5, 0, 1, 1.5),
    },
  },
  deleteDraftText: {
    margin: theme.spacing(0, 0, 2, 3),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 0, 1, 1.5),
    },
  },
  modalPaper: {
    width: "inherit",
    margin: theme.spacing(0, 2, 0, 3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 1, 0, 1.5),
    },
  },
  modalPaperGrid: {
    padding: theme.spacing(1.5, 0, 1.5, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.75, 0, 0.75, 0),
    },
  },
  btn1: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(1.375, 4.125, 1.375, 4.125),
    backgroundColor: `${appColor.regularBackgroundColor}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
  btn_: {
    margin: theme.spacing(3, 5, 0, 0),
    borderColor: `${appColor.whiteBackground}`,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3.5, 0, 0),
    },
  },
}));

export const ShowDraftCard = ({
  draft,
  setRequestedUserId,
  setSelectedUser,
  setTabValue,
  setShowDraftWithId,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();

  const emptyDrafts: Array<SharedDraftProps> = [];
  const drafts =
    useSelector((state: State) => state.data.data?.drafts) ?? emptyDrafts;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState<boolean>(false);

  async function handleCancel(e) {
    e.preventDefault();
    setOpen(false);
  }

  const handleDelete = (id) => {
    const arg: FeedbackDetailAPICallProps = {
      feedbackId: id,
    };
    dispatch(deleteDraftData(arg));
    setOpen(false);
  };

  const renderDeleteDraftModal = () => (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper elevation={0} variant="outlined">
          <Grid container className={classes.modalGrid}>
            <Grid
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              <Typography variant="h1" className={classes.deleteDraftHeader}>
                Delete Draft
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              <Typography
                variant="h6"
                className={`${customClasses.typography_font_14_10} ${classes.deleteDraftText}`}
              >
                Are you sure you want to delete this draft permanently?
              </Typography>
            </Grid>
            <Paper variant="outlined" square className={classes.modalPaper}>
              <Grid
                container
                direction="row"
                className={classes.modalPaperGrid}
              >
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  md={4}
                  xs={8}
                  className={classes.nameAndPicture}
                >
                  <Grid
                    container
                    item
                    justify="flex-start"
                    alignItems="center"
                    xs={2}
                    sm={2}
                    md={2}
                    xl={1}
                  >
                    <Avatar
                      alt={draft.user.firstName + " " + draft.user.lastName}
                      src={draft.user.imageUrl}
                      className={classes.avatar}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    justify="flex-start"
                    alignItems="center"
                    xs={10}
                    sm={10}
                    md={10}
                    xl={11}
                  >
                    <Typography variant="h6" className={classes.name}>
                      {draft.user.firstName + " " + draft.user.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  justify="flex-start"
                  alignItems="center"
                  container
                  item
                  md={2}
                  xs={4}
                >
                  <Typography
                    variant="h6"
                    className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
                  >
                    {getDateInDesiredFormat(new Date(draft.createdAt))}
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid
                    justify="flex-start"
                    alignItems="center"
                    container
                    item
                    md={6}
                  >
                    <Typography
                      variant="h6"
                      className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
                      style={{ paddingRight: "2vw" }}
                    >
                      {draft.attributesName.join(", ")}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
            </Paper>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
              style={{ marginRight: theme.spacing(2) }}
            >
              {
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  className={classes.btn_}
                >
                  <Typography
                    variant="h5"
                    className={`${customClasses.typography_font_14_10_600}`}
                    style={{
                      alignItems: "center",
                      color: `${appColor.darkGray}`,
                    }}
                  >
                    Cancel
                  </Typography>
                </Button>
              }
              {
                <Button
                  onClick={() => {
                    handleDelete(draft.feedbackId);
                  }}
                  className={classes.btn1}
                  color="primary"
                  variant="contained"
                >
                  <Typography
                    variant="h5"
                    className={customClasses.typography_font_14_10_600}
                    style={{
                      alignItems: "center",
                      color: `${appColor.pureWhite}`,
                    }}
                  >
                    Delete
                  </Typography>
                </Button>
              }
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );

  return (
    <Grid
      container
      direction="row"
      style={{
        padding: theme.spacing(1, 0, 1, 0),
      }}
    >
      {renderDeleteDraftModal()}
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        md={3}
        xs={5}
        className={classes.nameAndPicture}
      >
        <Grid
          container
          item
          justify="flex-start"
          alignItems="center"
          xs={2}
          sm={2}
          md={2}
          xl={1}
        >
          <Avatar
            alt={draft.user.firstName + " " + draft.user.lastName}
            src={draft.user.imageUrl}
            className={classes.avatar}
          />
        </Grid>
        <Grid
          container
          item
          justify="flex-start"
          alignItems="center"
          xs={10}
          sm={10}
          md={10}
          xl={11}
        >
          <Typography variant="h6" className={classes.name}>
            {draft.user.firstName + " " + draft.user.lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        justify="flex-start"
        alignItems="center"
        container
        item
        md={2}
        xs={4}
      >
        <Typography
          variant="h6"
          className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
        >
          {getDateInDesiredFormat(new Date(draft.createdAt))}
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid justify="flex-start" alignItems="center" container item md={4}>
          <Typography
            variant="h6"
            className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
            style={{ paddingRight: "2vw" }}
          >
            {draft.attributesName.join(", ")}
          </Typography>
        </Grid>
        <Grid justify="flex-start" alignItems="center" container item md xs={3}>
          {drafts.findIndex(
            (d) => d.requestStatus === "PENDING" && d.id === draft.feedbackId
          ) >= 0 ? (
            <Typography
              variant="h6"
              className={`${customClasses.typography_font_14_10} ${classes.typography_pending}`}
            >
              Requested
            </Typography>
          ) : null}
        </Grid>
      </Hidden>
      <Grid
        justify="flex-start"
        alignItems="center"
        container
        item
        md={1}
        xs={3}
        direction="row"
      >
        <Grid item xs={6} md={3}>
          <IconButton
            onClick={() => {
              setShowDraftWithId(draft.feedbackId);
              setSelectedUser(draft.user);
              setRequestedUserId(draft.feedbackId);
              setTabValue(0);
            }}
            disabled={draft.user.status === "INACTIVE"}
          >
            <img src="/editCTA.svg" alt="edit" />
          </IconButton>
        </Grid>
        <Grid item xs={6} md={7}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <img src="/deleteCTA.svg" alt="delete" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
