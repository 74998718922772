import { Grid, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { DropdownProps, OptionType, dropdownStyles } from './helper';

export const Dropdown = ({ placeholder = "Select", options, selectedOption, onSelect, expand=true, isDisabled=false  }: DropdownProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const classes = dropdownStyles();
    const ref = useRef<any>(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    }

    const handleSelect = (option: OptionType) => {
        setOpen(false);
        onSelect(option);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return (
        <Grid ref={ref}>
            <Grid className={`${expand ? classes.expandWrapper : `${classes.wrapper} ${open ? classes.openWrapper : "" }`} ${isDisabled ? classes.isDisabled : ""}`} onClick={() => setOpen(!open)}>
                <Typography className={selectedOption ? classes.selected : classes.placeholder} data-testid="dropdown-placeholder">
                    {selectedOption?.value || placeholder}
                </Typography>
                <IconButton
                    style={{
                        padding: "6px 4px 4px 4px",
                        position: "relative",
                        height: "20px",
                        width: "20px",
                        marginLeft: "16px",
                    }}
                >
                    <img src={!open ? "/arrowDown2.svg" : "/arrowUp3.svg"} alt="toggle" />
                </IconButton>
            </Grid>
            {open && (
                <Grid className={`${expand ? classes.expandOptionsWrapper : classes.optionsWrapper}`}>
                    {options?.map(option => {
                        const isSelected = option?.key === selectedOption?.key;
                        return (
                            <Grid key={option?.id} onClick={() => handleSelect(option)}>
                                <Typography className={isSelected ? classes.selectedOption : classes.option}>
                                    {option?.value}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}
