import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import "./extra_fonts/landingFont.css";
import { Link } from "react-router-dom"

const useStyle = makeStyles((theme) => ({
  headerSection: {
    height: theme.spacing(7.5),
    bottom: 0,
    boxShadow: "0 2px 10px 0 rgba(175,175,175,0.3)",
    backgroundColor: "#012241",
    padding: theme.spacing(0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 4),
      height: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 1),
    },
  },
  logo: {
    height: theme.spacing(5.25),
    width: theme.spacing(5.25),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  },
  logo2: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  logoName: {
    fontSize: theme.spacing(1.75),
    fontWeight: 800,
    fontFamily: "Nunito",
    color: "#FFFFFF",
    margin: theme.spacing(0, 3, 0, 0.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
      marginLeft: theme.spacing(0.35),
    },
  },
  rightsText: {
    fontFamily: "EuclideCircularARegular",
    fontWeight: 500,
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(0.85),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
      marginLeft: theme.spacing(0.35),
    },
  },
}));

interface Props {}

export const FooterComponent: React.FC<Props> = ({}) => {
  const classes = useStyle();

  return (
      <>
        {
          <Grid
              xs={12}
              item
              container
              alignItems="center"
              className={classes.headerSection}
          >
            <Grid xs={6} item container justify="flex-start" alignItems="center">
              All right reserved &nbsp; <img src="/LandingPageCopyrightIcon.svg" alt="c" className={classes.logo2} />
              <Typography className={classes.rightsText}>
                {` ${new Date().getFullYear()} `}
                <a className={classes.rightsText}>Grow-wize</a>
              </Typography>
            </Grid>
            <Grid xs={3} item container justify="flex-end" alignItems="center">
              <a
                  target="_blank"
                  href="/about/policy"
                  className={classes.rightsText}
                  style={{ textDecoration: "underline" }}
              >
                Privacy Policy
              </a>
            </Grid>
            <Grid xs={3} item container justify="flex-end" alignItems="center">
              <a
                  target="_blank"
                  href="/about/policy"
                  className={classes.rightsText}
                  style={{ textDecoration: "underline" }}
              >
                Terms & Conditions
              </a>
            </Grid>
          </Grid>
        }
      </>
  );
};