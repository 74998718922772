import {
    Divider,
    Grid,
    Hidden,
    makeStyles,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Typography,
  } from "@material-ui/core";

  const useStyle = makeStyles((theme) => ({
    tabs: {
      color: "#576BEA",
      width: "100%",
      textAlign: "left",
    },
    divider: {
      backgroundColor: "#576bea66",
      width: "100%",
    },
    typography_body_1: {
      textTransform: "none",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    tab: {
      width: "auto",
      minWidth: theme.spacing(10),
      padding: theme.spacing(0.5, 2.5, 0, 2.5),
    },
  }));

  
  const TeamDetailsText = () => {
    const classes = useStyle();
    return (
      <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
        Team Details
      </Typography>
    );
  };
  
  const RoundRobinText = () => {
    const classes = useStyle();
    return (
      <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
        Round Robin
      </Typography>
    );
  };
  
  export const TabLabels = ({ tabValue, setTabValue }) => {
    const classes = useStyle();
  
    const indexToTab = {
      0: "teamDetails",
      1: "roundRobin",
    };

    const tabToIndex = {
      teamDetails: 0,
      roundRobin: 1,
    };
  
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
  
    const handleChange = (event) => {
      setTabValue(event.target.value);
    };
    

    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Hidden smDown>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab classes={{ root: classes.tab }} label={<TeamDetailsText />} value={0} />
            <Tab classes={{ root: classes.tab }} label={<RoundRobinText />} value={1} />
          </Tabs>
          <Divider className={classes.divider} />
        </Hidden>
        <Hidden mdUp>
          <Select
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <MenuItem value={0}>
              <TeamDetailsText />
            </MenuItem>
            <MenuItem value={1}>
              <RoundRobinText />
            </MenuItem>
          </Select>
        </Hidden>
      </Grid>
    );
};
  