import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { handleOnChange } from "./addNewButtonHandles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../share_feedback/style.css";
import { RenderAddNewProps } from "./type";
import { useCustomStyle } from "../../../styles";
import { RemainingCharWarnText } from "../share_feedback/addNewButtonElements";
import {
  onDraftEditorCopy,
  onDraftEditorCut,
  handleDraftEditorPastedText,
} from "draftjs-conductor";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    typography_body_4_: {
      color: "#DC0101",
    },
    textBox: {
      marginTop: theme.spacing(1),
      fontSize: theme.spacing(1.75),
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    textBoxx: {
      margin: theme.spacing(0, 2.5, 2.5, 2.5),
      width: "-webkit-fill-available",
      flexWrap: "wrap",
      wordWrap: "break-word",
    },
    textBoxx1: {
      width: "-moz-available",
    },
  })
);

export const RenderSuggestions: React.FC<RenderAddNewProps> = ({
  attribute,
  idx,
  shareAssessmentData,
  setShareAssessmentData,
  onAnyChange,
}) => {
  const classes = useStyle();
  let regex = /(<([^>]+)>)/ig
  const customClasses = useCustomStyle();

  const [editorState, setEditorState] = React.useState(
    attribute.needsToBeImproved?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(attribute.needsToBeImproved).contentBlocks,
            convertFromHTML(attribute.needsToBeImproved).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const onEditorStateChange = (state) => {
    setEditorState(state);
    attribute.needsToBeImproved = draftToHtml(
      convertToRaw(state.getCurrentContent())
    ).replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim().replaceAll("_self","_blank");
    onAnyChange(true);
  };

  const handlePastedText =(text: string, html: string, editorState: EditorState)=> {
    let newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      onEditorStateChange(newState);
      return true;
    }

    return false;
  }

  let str = attribute.needsToBeImproved;
  return (
    <div className={`${classes.textBoxx1} ${classes.textBoxx}`}>
      <Typography gutterBottom variant="h5" className={classes.textBox}>
        {"What needs to be improved? "}
        {(str?.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'').length < 2 ||
          attribute.needsToBeImproved?.length < 9) && (
          <span
            className={`${customClasses.typography_font_14_8} ${classes.typography_body_4_}`}
          >
            (required)
          </span>
        )}
      </Typography>
      <Editor
        id={`needstobeimproved ${idx}`}
        editorState={editorState}
        placeholder="Type here..."
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: ["inline", "link", "emoji", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            options: ["link", "unlink"],
          },
          list: {
            options: ["unordered"],
          },
        }}
        onEditorStateChange={onEditorStateChange}
        onCopy={onDraftEditorCopy}
        onCut={onDraftEditorCut}
        handlePastedText={handlePastedText}
      />
      <TextareaAutosize
        id={`needsToBeImproved-${idx}`}
        hidden
        value={attribute.needsToBeImproved}
        onChange={handleOnChange(
          idx,
          shareAssessmentData,
          setShareAssessmentData
        )}
      />
      <RemainingCharWarnText text={attribute.needsToBeImproved} limit={10000} />
    </div>
  );
};

export const RenderContext: React.FC<RenderAddNewProps> = ({
  attribute,
  idx,
  shareAssessmentData,
  setShareAssessmentData,
  onAnyChange,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  let regex = /(<([^>]+)>)/ig
  const [editorState, setEditorState] = React.useState(
    attribute.whatWentWell?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(attribute.whatWentWell).contentBlocks,
            convertFromHTML(attribute.whatWentWell).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const onEditorStateChange = (state) => {
    setEditorState(state);
    attribute.whatWentWell = draftToHtml(
      convertToRaw(state.getCurrentContent())
      ).replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim().replaceAll("_self","_blank");
    onAnyChange(true);
  };

  const handlePastedText =(text: string, html: string, editorState: EditorState)=> {
    let newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      onEditorStateChange(newState);
      return true;
    }

    return false;
  }
  let str = attribute.whatWentWell;
  return (
    <div className={`${classes.textBoxx1} ${classes.textBoxx}`}>
      <Typography gutterBottom variant="h5" className={classes.textBox}>
        {"What went well? "}
        {(!str?.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'').length ||
          attribute.whatWentWell?.length < 9) && (
          <span
            className={`${customClasses.typography_font_14_8} ${classes.typography_body_4_}`}
          >
            (required)
          </span>
        )}
      </Typography>
      <Editor
        id={`context ${idx}`}
        editorState={editorState}
        placeholder="Type here..."
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: ["inline", "link", "emoji", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            options: ["link", "unlink"],
          },
          list: {
            options: ["unordered"],
          },
        }}
        onEditorStateChange={onEditorStateChange}
        onCopy={onDraftEditorCopy}
        onCut={onDraftEditorCut}
        handlePastedText={handlePastedText}
      />
      <TextareaAutosize
        id={`context-${idx}`}
        hidden
        value={attribute.whatWentWell}
        onChange={handleOnChange(
          idx,
          shareAssessmentData,
          setShareAssessmentData
        )}
      />
      <RemainingCharWarnText text={attribute.whatWentWell} limit={10000} />
    </div>
  );
};
