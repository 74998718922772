import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatus } from "../../enums/httpStatus";
import { get } from "../../util/APIService";

export const getReminder = createAsyncThunk(
    "admin/reminder/get",
    async (_, storeActions) => {
        const url = `api/v1/admin/reminder`;
        const response: any = await get(url, storeActions);

        if (response.status === HttpStatus.OK) {
            return {
                status: HttpStatus.OK,
                data: await response.json(),
            };
        } else {
            return {
                status: response.status,
                errorMessage: (await response.json()).message,
            };
        }
    }
);

const getReminderSlice = createSlice({
    name: "getReminder",
    initialState: {
        status: null as number | null,
        loading: false as boolean,
        errorMessage: null as string | null,
        data: null as any | null,
    },
    reducers: {
        clearGetReminderData: (state) => {
            state.status = null;
            state.loading = false;
            state.errorMessage = null;
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReminder.fulfilled, (state, action) => {
            state.status = action.payload.status;
            state.loading = false;
            if (action.payload.data !== undefined) {
                state.data = action.payload.data;
                state.errorMessage = null;
            } else if (action.payload.errorMessage !== undefined) {
                state.data = null;
                state.errorMessage = action.payload.errorMessage;
            }
        });
        builder.addCase(getReminder.pending, (state) => {
            state.status = null;
            state.loading = true;
            state.data = null;
            state.errorMessage = null;
        });
        builder.addCase(getReminder.rejected, (state) => {
            state.status = HttpStatus.SERVICE_UNAVAILABLE;
            state.loading = false;
            state.data = null;
            state.errorMessage = null;
        });
    },
});

export const { clearGetReminderData } = getReminderSlice.actions;

export default getReminderSlice;
