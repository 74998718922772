import { CircularProgress, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import {
  HiddenNavWhenLarge,
  HiddenNavWhenSmall,
} from "../../modules/home/NavBar";
import { HomeLayoutProps } from "../../type";

const drawerWidth = 224;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "inherit",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: "#FFFFFF",
      boxShadow: "0 2px 10px 0 rgba(175,175,175,0.3)",
      height: theme.spacing(10),
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(12.65)
      },
    },
    loaderBackground: {
      minHeight: `${window.innerHeight - 48}px`,
      padding: theme.spacing(6, 6, 6, 5),
      marginTop: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 4, 4, 4),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(11.25),
        padding: theme.spacing(4, 3, 3, 3),
      },
      background: "#FFFFFF",
    },
    mainSection: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
  })
);

export const Loader = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.loaderBackground}>
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const HomeLayout: React.FC<HomeLayoutProps> = ({
  loading,
  props,
  mobileOpen,
  handleDrawerToggle,
  renderToolBar,
  renderSelectedOption,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {renderToolBar()}
      </AppBar>
      <nav className={classes.drawer}>
        {
          <HiddenNavWhenLarge
            props={props}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          ></HiddenNavWhenLarge>
        }
        {<HiddenNavWhenSmall props={props}></HiddenNavWhenSmall>}
      </nav>
       <div className={classes.mainSection}>{renderSelectedOption()}</div>
    </div>
  );
};
