import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ResponseAttribute, State } from "../../../type";
import { AddNewButton } from "../../user/share_feedback/addNewButton";
import { FeedbackHeaderText } from "./FeedbackHeader";
import { CustomDivider } from "./LocalDivider";
import { MemberChip } from "./MemberChip";
import { FeedbackSectionProps } from "./type";

const useStyles = makeStyles((theme) => ({
  feedbackArea: {
    border: "1px solid #DCDCDC",
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px 4px rgba(191,191,191,0.3)",
    marginTop: theme.spacing(7.5),
  },
  memberHeading: {
    fontSize: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  commonTextStyle: {
    textAlign: "justify",
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#272727",
    fontSize: theme.spacing(3),
    padding: theme.spacing(0, 3, 0, 3),
  },
  contextStyle: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    margin: theme.spacing(3.75, 0, 5, 0),
  },
  attributeHintTextStyle: {
    textAlign: "justify",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#868686",
    fontSize: theme.spacing(2),
    padding: theme.spacing(0, 3, 0, 3),
    marginTop: theme.spacing(1.75),
  },
}));

export const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  attributes,
  setAttributes,
  shareFeedbackData,
  setShareFeedbackData,
  emojiSelected,
  setEmojiSelected,
  setValue,
  context,
  user,
  isAnyAttribute,
  onAnyChange,
  isMissing,
}) => {
  const classes = useStyles();
  const attr: Array<ResponseAttribute> = [];
  const attLabels =
    useSelector(
      (state: State) => state.attributesDataForClient?.data?.attributes
    ) ?? attr;

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.feedbackArea}
    >
      <Grid xs={12} item>
        <FeedbackHeaderText />
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonTextStyle} ${classes.memberHeading}`}
        >
          Member
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <MemberChip user={user} />
      </Grid>
      <Grid xs={12} item>
        <CustomDivider />
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonTextStyle} ${classes.memberHeading}`}
        >
          Context
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonTextStyle} ${classes.contextStyle}`}
          dangerouslySetInnerHTML={{
            __html: context ? context : "",
          }}
        />
      </Grid>
      <Grid xs={12} item>
        <CustomDivider />
      </Grid>
      <Grid xs={12} item>
        <Typography
          className={`${classes.commonTextStyle} ${classes.memberHeading}`}
        >
          Attributes
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography className={`${classes.attributeHintTextStyle}`}>
          <span style={isAnyAttribute ? { color: "#DC0101" } : {}}>
            Attributes to share feedback on
          </span>
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Grid container justify="flex-start">
          <AddNewButton
            attributes={attributes}
            setAttributes={setAttributes}
            shareFeedbackData={shareFeedbackData}
            setShareFeedbackData={setShareFeedbackData}
            emojiSelected={emojiSelected}
            setEmojiSelected={setEmojiSelected}
            setValue={setValue}
            onAnyChange={onAnyChange}
            isMissing={isMissing}
            attLabels={attLabels}
            from={"CLIENT"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
