import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeactivateMemberProp } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const countRequestSendByMember = createAsyncThunk(
  "admin/requestFeedback/countRequests/send",
  async (requestParams: DeactivateMemberProp, storeActions) => {
    const url = `api/v1/admin/member/requests/sent/count?memberId=${requestParams.memberId}&requestTag=${requestParams.tag}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const countRequestSendByMemberSlice = createSlice({
  name: "countRequestSendByMember",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: -1 as number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(countRequestSendByMember.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(countRequestSendByMember.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(countRequestSendByMember.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = countRequestSendByMemberSlice.actions;

export default countRequestSendByMemberSlice;
