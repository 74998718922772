import { SearchOption } from "../modules/components/type";
import { User } from "../type";

export function userToOption(user: User) {
  return {
    userId: user.id,
    attrId: null,
    name: user.firstName + " " + user.lastName,
  } as SearchOption;
}
