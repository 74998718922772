import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
  ClickAwayListener,
  IconButton
} from "@material-ui/core";
import React from "react";
import { CustomSelect } from "../../components/CustomSelect";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { DateRangePicker } from "react-date-range";
import { CustomDivider } from "./CustomDivider";
import { RequestFeedbackActivityCard } from "./FeedbackRequestActivityCard";
import { SharedFeedbackActivityCard } from "./FeedbackSharedActivityCard";
import { RecentActivityComponentProp } from "./type";
import { UserOnBoardedActivityCard } from "./UserOnBoardedActivityCard";
import { SelfAssessmentActivityCard } from "./SelfAssessmentActivityCard";

const useStyles = makeStyles((theme) => ({
  recentActivitySection: {
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    boxSizing: "border-box",
    margin: theme.spacing(0, 1),
  },
   dates: {
      position: "absolute",
      zIndex: 100,
      right: theme.spacing(6),
      boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
      border: "1px solid rgba(175,175,175,0.3)",
      fontFamily: "Montserrat",
      marginTop: "16px",
    },
}));

export const RecentActivityComponent: React.FC<RecentActivityComponentProp> = ({
  data,
  recentActivityTab,
  setRecentActivityTab,
  loading,
  setFeedbackId,
  setAssessmentId,
  setMember,
  activityDateRangeState,
  setActivityDateRangeState,
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyle();
  const [show, setShow] = React.useState<Boolean>(false);

    const handleDateChange = (range) => {
        const nextRange = {
          startDate: range?.range1?.startDate,
          endDate: range?.range1?.endDate,
        };
        setActivityDateRangeState(nextRange);
      };


  return (
    <Grid
      xs={12}
      item
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.recentActivitySection}
    >
      <Grid xs={12} item container style={{ padding: "11px 14px 11px 14px" }}>
        <Grid xs={9} item container justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{ color: "#272727" }}>
            Activities
          </Typography>
        </Grid>
        <Grid xs container justify="flex-end" alignItems="center">
          <Grid item>
              <IconButton
                onClick={() => setShow(true)}
                style={{
                  padding: "0px 4px",
                  margin: "0px",
                  position: "relative",
                  height: "20px",
                  width: "20px",
                }}
              >
                <img src="/CalendarIcon.svg" alt="trend-calendar" />
              </IconButton>
              {show && (
              <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => setShow(false)}
            >
              <div className={classes.dates}>
                <DateRangePicker
                  ranges={[activityDateRangeState]}
                  onChange={(e) => handleDateChange(e)}
                  maxDate={new Date()}
                  dateDisplayFormat={"d MMM yyyy"}
                />
              </div>
            </ClickAwayListener>)}
            </Grid>
        </Grid>
        <Grid xs container justify="flex-end" alignItems="center">
          <CustomSelect
            tabValue={recentActivityTab}
            setTabValue={setRecentActivityTab}
            name={"ACTIVITY_LIST"}
          />
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <CustomDivider />
      </Grid>
      <Grid style={{ height: theme.spacing(44), overflowY: "scroll" }}>
        {data?.length > 0 &&
          data.map((d, id) => (
            <div key={`activity-${id}`}>
              {d.activityName === "FEEDBACK_SHARED" && (
                <SharedFeedbackActivityCard
                  activityId={d.activityDetail.activityId}
                  activityDate={d.activityDetail.activityDate}
                  activityUser={d.activityDetail.activityUser}
                  others={d.activityDetail.others}
                  setFeedbackId={setFeedbackId}
                  setMember={setMember}
                />
              )}
              {d.activityName === "FEEDBACK_REQUESTED" && (
                <RequestFeedbackActivityCard
                  activityDate={d.activityDetail.activityDate}
                  activityUser={d.activityDetail.activityUser}
                  others={d.activityDetail.others}
                />
              )}
              {d.activityName === "USER_ONBOARDED" && (
                <UserOnBoardedActivityCard
                  activityDate={d.activityDetail.activityDate}
                  activityUser={d.activityDetail.activityUser}
                />
              )}
              {d.activityName === "SELF_ASSESSMENT_UPDATED" && (
                <SelfAssessmentActivityCard
                  activityId={d.activityDetail.activityId}
                  activityDate={d.activityDetail.activityDate}
                  activityUser={d.activityDetail.activityUser}
                  setAssessmentId={setAssessmentId}
                  setMember={setMember}
                />
              )}
              <Grid xs={12} item container>
                <CustomDivider />
              </Grid>
            </div>
          ))}
        {loading && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ position: "relative", marginTop: theme.spacing(16) }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && data?.length === 0 && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ marginTop: theme.spacing(16) }}
          >
            <Typography
              variant="body2"
              className={customClasses.typography_font_24_14_500}
            >
              No recent activities...
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
