import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import jwt_decode from "jwt-decode";
import {
  SideLabelAdminDashboardText,
  SideLabelAllMembersText,
  SideLabelManageQuestionsText,
  SideLabelManageTeamsText,
  SideLabelSettings,
  SideLabelText,
} from "./HomeText";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../type";
import { useHistory, useLocation } from "react-router-dom";
import { useCustomStyle } from "../../styles";
import { setUnsavedChanges } from "../../redux/slices/ShareFeedbackSlice";
import { DiscardContentModal } from "../components/ErrorPages/DiscardExistingContent";
import { getAllTeamsOfTeamLead } from "../../redux/slices/TeamLeadTeams";
import { CreateTeamCancelModal } from "../admin/teams/createTeams/createTeamCancelModal/createTeamCancelModal";
import { setUnsavedTeamChanges } from "../../redux/slices/CreateTeamSlice";
import { dashboardVisitIncrementCounter, requestNowPageVisitIncrementCounter } from "../../redux/slices/CoachMarkSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default_tabStyle: {
      display: "inline-grid",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: theme.spacing(0.75, 0, 0.75, 2),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0.25, 0, 0.25, 0.75),
      },
    },
    active_tabStyle: {
      display: "inline-grid",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#E4E8FB",
      margin: theme.spacing(0.75, 0, 0.75, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0.25, 0, 0.25, 0),
      },
    },
    leftInficatorUserView: {
      borderLeft: "5px solid #576BEA",
    },
    leftInficatorAdminView: {
      borderLeft: "5px solid #7C69EC",
    },
    indicator: {
      backgroundColor: "#E4E8FB",
    },
    logo: {
      margin: theme.spacing(2.5, 0, 7, 0.25),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2.5, 0, 6, 0.125),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(2.5, 0, 5, 0),
      },
    },
    logoName: {
      color: "#21395E",
      fontSize: theme.spacing(2),
      fontWeight: 800,
      fontFamily: "Nunito",
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
        marginLeft: theme.spacing(1.5),
      },
    },
    logoImg: {
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    },
    closeCta: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5),
      color: "#353131",
    },
    roleGridUserView: {
      position: "fixed",
      bottom: `${window.innerHeight / 12 - 40}px`,
      [theme.breakpoints.down("xs")]: {
        bottom: `${window.innerHeight / 28}px`,
      },
    },
    roleGridAdminView: {
      position: "fixed",
      bottom: `${window.innerHeight / 12 - 40}px`,
      [theme.breakpoints.down("xs")]: {
        bottom: `${window.innerHeight / 28}px`,
      },
      width:"fit-content",
    },
    logoCta: {
      width: "-webkit-fill-available",
      padding: theme.spacing(0),
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(-2.5),
      },
    },
    toggleViewUserCTA: {
      color: "#7C69EC",
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: theme.spacing(1.75),
      border: "1px solid #7C69EC",
      borderRadius: theme.spacing(0.5),
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#7C69EC",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    toggleViewAdminCTA: {
      color: "#576BEA",
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: theme.spacing(1.75),
      border: "1px solid #576BEA",
      borderRadius: theme.spacing(0.5),
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#576BEA",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    navScrollStyle: {
      height: `${(11 / 12) * window.innerHeight - 120}px`,
      overflowY: "scroll",
      [theme.breakpoints.down("md")]: {
        height: `${(11 / 12) * window.innerHeight - 200}px`,
      },
    },
  })
);

const SIDE_LABEL_DATA = [
  "Dashboard",
  "Share Feedback",
  "Request Feedback",
  "Shared Feedback",
  "Received Feedback",
  "Feedback Requests",
  "Self Assessment",
  "Feedback Summary",
  "My Teams",
];

export interface TokenDataProps {
  iat: number;
  exp: number;
  sub: number;
  role: string;
  org_id?: number;
  status?: string;
}

export const LocalDrawer = ({ props, handleDrawerToggle }) => {
  const classes = useStyles();
  const customClasses = useCustomStyle();
  const dispatch = useDispatch();
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState<Boolean>(false);
  const [isTabModalOpen, setIsTabModalOpen] = useState<Boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [switchViewClicked, setSwitchViewClicked] = useState<boolean>(false);
  const [newTabValue, setNewTabValue] = useState<number | null>(null);

  const token = useSelector((state: State) => state.auth.token);

  const userTeams = useSelector((state: State) => state.teamLeadTeamData.teams);

  const currentLocation = useLocation();
  const currentPagePath = currentLocation.pathname;

    useEffect(() => {
      if (currentPagePath === "/home" || currentPagePath === "/teams"){
        const fetchUserTeams = async () => {
          try {
            dispatch(getAllTeamsOfTeamLead());
    
          } catch (error) {
            console.error("Error fetching user teams:", error);
          }
        };
        fetchUserTeams();
      }
    }, [dispatch]);

  const unsavedChanges = useSelector(
    (state: State) => state.shareFeedback.unsavedChanges
  );
  const unsavedTeamChanges = useSelector(
    (state: State) => state.createTeamDetails.unsavedTeamChanges
  );

  useEffect(() => {
    dispatch(setUnsavedTeamChanges(false));
  }, []);

  useEffect(() => {
    dispatch(setUnsavedChanges(false));
  }, []);

  const history = useHistory();
  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
  };
  try {
    tokenData = jwt_decode(token);
  } catch (err) {
    console.error((err as Error).message);
  }
  const indexToTab = {
    0: "/home",
    1: "/feedback/share/:id",
    2: "/feedback/requests/employee",
    3: "/feedback/shared",
    4: "/feedback/received",
    5: "/feedback/requests/received/pending",
    6: "/home/self-assessment",
    7: "/report",
    8: "/teams"
  };

  const indexToTabForAdmin = {
    0: "/admin/dashboard",
    1: "/admin/members",
    2: "/admin/teams",
    3: "/admin/manage/attributes",
    4: "/admin/settings",
  };

  const tabToIndex = {
    "/home": 0,
    "/feedback/share/:id": 1,
    "/feedback/requests/:STATUS": 2,
    "/feedback/request/sent/detail/:id/:uid": 2,
    "/feedback/shared": 3,
    "/feedback/shared/detail/:id": 3,
    "/feedback/received": 4,
    "/feedback/requests/received/:STATUS": 5,
    "/feedback/requests/received/detail/:id": 5,
    "/feedback/received/detail/:id": 4,
    "/home/self-assessment": 6,
    "/home/self-assessment/:id": 6,
    "/report": 7,
    "/teams": 8,
    "/teams/details/:teamId":8,
    "/member/detail/:id":8,
    "/members/report/feedback/received/:id":8,
    "/detail/view":8
  };

  const tabToIndexForAdmin = {
    "/admin/dashboard": 0,
    "/admin/nudge": 0,
    "/admin/members": 1,
    "/admin/teams": 2,
    "/admin/teams/create": 2,
    "/admin/teams/edit/:teamId": 2,
    "/admin/teams/details/:teamId": 2,
    "/admin/manage/attributes": 3,
    "/admin/manage/attributes/:attributeId": 3,
    "/admin/settings": 4,
    "/admin/members/report/feedback/received/:id": 1,
    "/admin/member/detail/:id": 1,
    "/admin/detail/view": 1,
  };

  const path: string = props.match.path;
  let index = path[1] === "a" ? tabToIndexForAdmin[path] : tabToIndex[path];

  const [value, setValue] = React.useState<number>(index);
  const [adminRole, setAdminRole] = React.useState<Boolean>(
    path[1] === "a" ? true : false
  );

  const [isToggleCTAHovered, setToggleCTAHovered] =
    React.useState<Boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNewTabValue(newValue);
    if (unsavedChanges) {
      setIsTabModalOpen(true);
    }
    else if(unsavedTeamChanges){
      setOpenModal(true);
    }
    else {
      window.scrollTo(0, 0);
      const prevLength = history.length;
      if (adminRole) {
        props.history.push(indexToTabForAdmin[newValue]);
        setValue(newValue);
      } else {
        props.history.push(indexToTab[newValue]);
        const nextLength = history.length;
        if (nextLength - prevLength > 0) setValue(newValue);
      }
    }
  };
  const renderSwitchViewModal = () => (    
    <DiscardContentModal
      open={isSwitchModalOpen}
      handleCancel={() => setIsSwitchModalOpen(false)}
      handleConfirm={handleConfirmSwitchModal}
      onClose={() => setIsSwitchModalOpen(false)}
    />
  );

  const renderSwitchViewTeamModal = () => {
    return (
      <CreateTeamCancelModal
        open={openModal}
        handleCancel={handleCancelModal}
        handleSuccess={switchViewClicked ? handleConfirmTeamSwitchModal : handleConfirmTeamTabModal}
        disableActions={submitted}
      />
    );
  };

  const handleConfirmTeamTabModal = () => {
    setSubmitted(true);
    setOpenModal(false);
    dispatch(setUnsavedTeamChanges(false));
    swtichTabOnClick();
    
  };

  const handleConfirmTeamSwitchModal = () => {
    setSubmitted(true);
    setOpenModal(false);
    dispatch(setUnsavedTeamChanges(false));
    switchPageOnClick()
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };
  
  const location = useLocation();
  const currentPath = location.pathname;


  const renderSwitchTabModal = () => {
    if (currentPath === "/admin/teams/create") {
      return (
        <CreateTeamCancelModal
        open={openModal}
        handleCancel={handleCancelModal}
        handleSuccess={switchViewClicked ? handleConfirmTeamSwitchModal : handleConfirmTeamTabModal}
        disableActions={submitted}
      />
      );
    } else {
      return (
        <DiscardContentModal
          open={isTabModalOpen}
          handleCancel={() => setIsTabModalOpen(false)}
          handleConfirm={handleConfirmTabModal}
          onClose={() => setIsTabModalOpen(false)}
        />
      );
    }
  };
  
  const switchPageOnClick = () => {
    if (!adminRole) {
      props.history.push(indexToTabForAdmin[0]);
    } else {
      props.history.push(indexToTab[0]);
    }
    setValue(0);
    setAdminRole(!adminRole);
  };

  const swtichTabOnClick = () => {
    window.scrollTo(0, 0);
    const newValue : number = newTabValue as number;
    if (adminRole) {
      props.history.push(indexToTabForAdmin[newValue]);
      setValue(newValue);
      setNewTabValue(null);
    } else {
      props.history.push(indexToTab[newValue]);
      setValue(newValue);
      setNewTabValue(null);
    }
  };
  const handleConfirmSwitchModal = () => {
    dispatch(setUnsavedChanges(false));
    setIsSwitchModalOpen(false);
    switchPageOnClick();
  };
  const handleConfirmTabModal = () => {
    dispatch(setUnsavedChanges(false));
    swtichTabOnClick();
  };

  const shouldRenderTab = (label: string) => {
    if (label === "My Teams") {
      return userTeams.length > 0;
    } else {
      return true;
    }
  }

  const dashboardClicked = () => {
    dispatch(dashboardVisitIncrementCounter());
  }

  const requestNowClicked = () => {
    dispatch(requestNowPageVisitIncrementCounter());
  }

  return (
    <>
      {renderSwitchViewModal()}
      {renderSwitchTabModal()}
      {renderSwitchViewTeamModal()}
      <Grid>
        <Hidden smUp>
          <Grid
            container
            xs={12}
            item
            alignItems="center"
            className={classes.logo}
          >
            <Grid
              xs={9}
              item
              container
              justify="flex-start"
              alignItems="center"
            >
              <Button
                onClick={() =>
                  tabToIndex[path] !== 0 && props.history.push("/home")
                }
                className={`${customClasses.popperWidth} ${classes.logoCta}`}
              >
                <Grid item>
                  <img
                    src="/LogoWeb1.svg"
                    alt="App Logo"
                    className={classes.logoImg}
                  ></img>
                </Grid>
                <Grid item>
                  <Typography className={classes.logoName}>
                    {"Grow-Wize"}
                  </Typography>
                </Grid>
              </Button>
            </Grid>
            <Grid xs={3} item container justify="flex-end" alignItems="center">
              <IconButton aria-label="clear" onClick={handleDrawerToggle}>
                <ClearIcon className={classes.closeCta} />
              </IconButton>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid
            container
            className={classes.logo}
            justify="center"
            alignItems="center"
          >
            <Button
              onClick={() =>
                tabToIndex[path] !== 0 && props.history.push("/home")
              }
              className={classes.logoCta}
            >
              <Grid item>
                <img
                  src="/LogoWeb1.svg"
                  alt="App Logo"
                  className={classes.logoImg}
                ></img>
              </Grid>
              <Grid item>
                <Typography className={classes.logoName}>
                  {"Grow-Wize"}
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Hidden>
        {!adminRole && (
          <div className={classes.navScrollStyle}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              classes={{
                indicator: classes.indicator,
              }}
            >
              {SIDE_LABEL_DATA.map((label, idx) => (
               shouldRenderTab(label) && <Tab
                  label={
                    <SideLabelText
                      value={value}
                      idx={idx}
                      name={label}
                    ></SideLabelText>
                  }
                  className={
                    value === idx
                      ? `${classes.active_tabStyle} ${classes.leftInficatorUserView}`
                      : classes.default_tabStyle
                  }
                  onClick={() => {
                    if (label === 'Dashboard') {
                      dashboardClicked();
                    } else if (label === 'Request Feedback') {
                      requestNowClicked();
                    }
                  }}
                />
              ))}
            </Tabs>
          </div>
        )}
        {adminRole && (
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              label={
                <SideLabelAdminDashboardText
                  value={value}
                ></SideLabelAdminDashboardText>
              }
              className={
                value === 0
                  ? `${classes.active_tabStyle} ${classes.leftInficatorAdminView}`
                  : classes.default_tabStyle
              }
            />
            <Tab
              label={
                <SideLabelAllMembersText
                  value={value}
                ></SideLabelAllMembersText>
              }
              className={
                value === 1
                  ? `${classes.active_tabStyle} ${classes.leftInficatorAdminView}`
                  : classes.default_tabStyle
              }
            />
            <Tab
              label={
                <SideLabelManageTeamsText
                  value={value}
                ></SideLabelManageTeamsText>
              }
              className={
                value === 2
                  ? `${classes.active_tabStyle} ${classes.leftInficatorAdminView}`
                  : classes.default_tabStyle
              }
            />
            <Tab
              label={
                <SideLabelManageQuestionsText
                  value={value}
                ></SideLabelManageQuestionsText>
              }
              className={
                value === 3
                  ? `${classes.active_tabStyle} ${classes.leftInficatorAdminView}`
                  : classes.default_tabStyle
              }
            />
            <Tab
              label={<SideLabelSettings value={value}></SideLabelSettings>}
              className={
                value === 4
                  ? `${classes.active_tabStyle} ${classes.leftInficatorAdminView}`
                  : classes.default_tabStyle
              }
            />
          </Tabs>
        )}

        {tokenData?.role === "ADMIN" && (
          <Grid container alignItems="center" justify="center">
            <Grid
              item
              container
              justify="center"
              xs={12}
              className={
                adminRole ? classes.roleGridAdminView : classes.roleGridUserView
              }
            >
              <Button
                startIcon={
                  isToggleCTAHovered ? (
                    <img src="/HoveredToggleCTA.svg" alt="toggle"></img>
                  ) : adminRole ? (
                    <img src="/ToggleCTA.svg" alt="toggle"></img>
                  ) : (
                    <img src="/ToggleCTAPurple.svg" alt="toggle"></img>
                  )
                }
                onClick={() => {
                  setSwitchViewClicked(true);
                  if (unsavedChanges) {
                    setIsSwitchModalOpen(true);
                  } else if (unsavedTeamChanges) {
                    setOpenModal(true);
                  } else {
                    switchPageOnClick();
                  }
                }}
                variant="outlined"
                className={
                  adminRole
                    ? classes.toggleViewAdminCTA
                    : classes.toggleViewUserCTA
                }
                onMouseEnter={() => setToggleCTAHovered(true)}
                onMouseLeave={() => setToggleCTAHovered(false)}
              >
                SWITCH VIEW
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
