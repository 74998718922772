import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TextCountProps } from "./typeFeedbackrequest";

const useStyle = makeStyles((theme) => ({
  typography_body_2: {
    textTransform: "none",
    paddingLeft: theme.spacing(0.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const AllText: React.FC<TextCountProps> = ({ count }) => {
  const classes = useStyle();
  return (
    <Typography variant="h5" className={classes.typography_body_2}>
      All ({count})
    </Typography>
  );
};

export const PendingText: React.FC<TextCountProps> = ({ count }) => {
  const classes = useStyle();
  return (
    <Typography variant="h5" className={classes.typography_body_2}>
      Requests Pending ({count})
    </Typography>
  );
};

export const ClosedText: React.FC<TextCountProps> = ({ count }) => {
  const classes = useStyle();
  return (
    <Typography variant="h5" className={classes.typography_body_2}>
      Requests Closed ({count})
    </Typography>
  );
};


