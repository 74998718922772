import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { TabsWithReportBtnProps } from "../type";
import { ReportCTA } from "./ReportButton";
import { TitleBarTabs } from "./TitleTabs";

const useStyle = makeStyles((theme) => ({
  tabsWithReport: {
    height: theme.spacing(4.75),
    margin: theme.spacing(10, 0, 5, 0),
    display: "flex",
    justifyContent: "space-between",
  },
  tabsWrapper: {
    width: "fit-content",
  },
  buttonsWrapper: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export const TabsWithReportCTA: React.FC<TabsWithReportBtnProps> = ({
  tabValue,
  setTabValue,
  setOpen,
  setPageNumber,
  setItemValue,
  member,
}) => {
  const classes = useStyle();
  return (
    <Grid
      xs={12}
      item
      container
      alignItems="center"
      className={classes.tabsWithReport}
    >
      <Grid item container className={classes.tabsWrapper}>
        <TitleBarTabs
          tabValue={tabValue}
          setTabValue={setTabValue}
          setPageNumber={setPageNumber}
          setItemValue={setItemValue}
        />
      </Grid>
      {tabValue === 0 && (
        <Grid className={classes.buttonsWrapper}>
          <Grid item container justify="flex-end">
            <ReportCTA setOpen={setOpen} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
