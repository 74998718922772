import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DetailPageSliceProps } from "../../modules/admin/members/details/detail/type";
import { SharedAssessmentDetailProps } from "../../modules/user/self_assessment/type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const sharedAssessmentDetailByMember = createAsyncThunk(
  "admin/assessment/shared/detail",
  async (params: DetailPageSliceProps, storeActions) => {
    const url = `api/v1/admin/member/detail/self/assessment/detail?assessmentId=${params.feedbackOrAssessmentId}&memberId=${params.memberId}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const sharedAssessmentDetailByMemberSlice = createSlice({
  name: "sharedAssessmentDetailByMember",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as SharedAssessmentDetailProps | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedAssessmentDetailByMember.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(sharedAssessmentDetailByMember.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(sharedAssessmentDetailByMember.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = sharedAssessmentDetailByMemberSlice.actions;

export default sharedAssessmentDetailByMemberSlice;
