export type NudgeTitleProps = {
    name: string;
    size: | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | "auto"
    | boolean
    | undefined;
}

export const NUDGE_TITLE: Array<NudgeTitleProps> = [
    {
        name: "Name",
        size: 6,
    },
    {
        name: "Shared",
        size: 2,
    },
    {
        name: "Received",
        size: 2,
    },
    {
        name: "Action",
        size: 2,
    },
];

export enum MESSAGES {
    NUDGE_SUCCESS = "Nudge Notification Sent Successfully",
    NUDGE_FAIL = "Nudge Notification Failed",
}
export const COUNT = 200;
