import { makeStyles } from "@material-ui/core";
import { ValidationErrors } from "../helper";

export const addAttributeModalStyles = makeStyles((theme) => ({
    attributeInput: {
        marginBottom: theme.spacing(1.75),
        "& > p:last-child": {
            color: "#DC0101",
            fontSize: 12,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: theme.spacing(1),
            fontFamily: "Montserrat"
        }
    },
    addQuestionInput: {
        marginBottom: theme.spacing(1.75),
        marginTop: theme.spacing(1.75),
        "& > p:last-child": {
            color: "#DC0101",
            fontSize: 12,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: theme.spacing(1),
            fontFamily: "Montserrat"
        }
    },
    showQuestionsWrapper: {
        marginBottom: theme.spacing(1.75),
    },
    dropdown: {
        padding: "4px 12px",
        "& > :first-child": {
            backgroundColor: "none",
            "&:focus": {
                background: "none",
            },
        },
    },
    helperText: {
        color: "#DC0101",
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 0,
        marginTop: theme.spacing(1),
        fontFamily: "Montserrat"
    },
    dropdownWrapper: {
        marginLeft: theme.spacing(3)

    }
}));

export const checkValidation = ({ attributeName, questionText, selectedResponseType, setErrors }) => {
    let isValid = true;
    const errors = {};
    if (attributeName.trim().length === 0) {
        isValid = false;
        errors["attributeName"] = ValidationErrors.ATTRIBUTE_NAME;
    } else if (attributeName.trim().length > 50) {
        isValid = false;
        errors["attributeName"] = ValidationErrors.ATTRIBUTE_NAME_LENGTH;
    }
    if (questionText.trim().length === 0) {
        isValid = false;
        errors["questionText"] = ValidationErrors.QUESTION_TEXT;
    }
    if (!selectedResponseType) {
        isValid = false;
        errors["responseType"] = ValidationErrors.RESPONSE_TYPE;
    }
    setErrors(errors);
    return isValid;
};
