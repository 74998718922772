import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../../home/Layout";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { appColor } from "../../../../modules/constants/customThemeColor";
import { createTeamLayoutStyle } from "../helper";

export const CreateTeamLayout = ({
    props,
    loading,
    setLogout,
    isDetailView,
    setIsDetailView,
    renderErrorModal,
    renderTextField,
    handleCreateTeam,
    handleCancel,
    renderAutocomplete,
    isSaveDisabled,
    isEditTeamRouteView
}) => {
    document.title = !isEditTeamRouteView ? "Grow-Wize | Create Team" : "Grow-Wize | Edit Team";

    const classes = createTeamLayoutStyle();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderToolBar = () => (
        <ToolBar
            headerName={!isEditTeamRouteView ? 'New Team' : 'Team Details'}
            setLogout={setLogout}
            isDetailView={isDetailView}
            setIsDetailView={setIsDetailView}
            handleDrawerToggle={handleDrawerToggle}
            value={-2}
            setValue={0}
            tabValue={-2}
            validLength={false}
        ></ToolBar>
    );

    const renderCreateTeamLayout = () => (
        <>
        {renderErrorModal()}
        <Grid className={classes.root}>
            {renderTextField()}
            {renderAutocomplete()}
            <Grid className={classes.footer} container direction='row' justify="flex-end" alignItems="flex-start">
                <Divider className={classes.divider} />
                <Button
                     variant="outlined" 
                     className={classes.btn_}
                     onClick={handleCancel}
                     >
                    <Typography
                        className={classes.typography_font_14_10_600}
                        style={{
                            alignItems: "center",
                            color: `${appColor.darkGray}`,
                        }}
                    >
                        Cancel
                    </Typography>
                </Button>
                <Button
                    color="primary"
                    className={`${classes.saveButton} ${isSaveDisabled ? classes.disabledButton : ""} `}
                    onClick={handleCreateTeam}
                    variant="contained"
                    disabled={isSaveDisabled}
                >
                    {!isEditTeamRouteView ? 'Create' : 'Save'}
                </Button>
            </Grid>
        </Grid>
        </>
    );

    return (
        <HomeLayout
            loading={loading}
            renderToolBar={renderToolBar}
            props={props}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            renderSelectedOption={renderCreateTeamLayout}
        ></HomeLayout>
    );
};
