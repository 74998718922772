import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NudgeSliceProps, ResponseNudge, ResponseTeam } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getNudgeList = createAsyncThunk(
  "nudgesData/getNudgeList",
  async (requestParams: NudgeSliceProps, storeActions) => {
    const url = `api/v1/admin/feedback/nudgeBoardUserList?${requestParams.requestingParams}&offSet=${requestParams.offSet}&count=${requestParams.count}` //
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const adminNudgeDataSlice = createSlice({
  name: "adminNudgeData",
  initialState: {
    status: null as number | null,
    text: null as string | null,
    nudges: [] as ResponseNudge[] | [],
    nudgeDateRange: {   startDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000) as Date | null,
                    endDate: new Date() as Date | null},
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.nudges = [];
      state.nudgeDateRange= {   startDate: null,
        endDate: null};
    },
    setNudgeDateRange : (state, dateRange) => {
      state.nudgeDateRange = dateRange.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNudgeList.fulfilled, (state, action) => {
      state.status = action.payload.status;
      if (action.payload.data !== undefined) {
        state.nudges = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.nudges = [];
        state.text = action.payload.text;
      }
      state.loading = false;
    });
    builder.addCase(getNudgeList.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.text = null;
      state.nudges = state.nudges?.length > 0 ? state.nudges : [];
    });
    builder.addCase(getNudgeList.rejected, (state, action) => {
      state.status = 503;
      state.text = null;
      state.nudges = [];
      state.loading = false;
    });
  },
});

export const { clearData, setNudgeDateRange } = adminNudgeDataSlice.actions;

export default adminNudgeDataSlice;
