import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { DraftListLayoutProps } from "../../../../type";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "-webkit-fill-available",
    marginTop: theme.spacing(4),
  },
  loaderBackground: {
    width: "inherit",
    height: `${window.innerHeight - 225}px`,
  },
}));

export const DraftListLayout: React.FC<DraftListLayoutProps> =
  ({ loading, renderList }) => {
    const classes = useStyle();
    window.scrollTo(0, 0);

      return (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.root}>
          {renderList()}
        </Grid>
      );
  };
