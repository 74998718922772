import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { RequestFeedbackFormLayoutProps } from "../../../type";

const useStyle = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(4, 1, 0, 1),
    textAlign: "initial",
    width: "inherit",
  },
  loaderBackground: {
    width: "inherit",
    height: `${window.innerHeight - 225}px`,
  },
}));

export const RequestFeedbackFormLayout: React.FC<RequestFeedbackFormLayoutProps> =
  ({
    loading,
    usersArrayLength,
    renderValidationModal,
    renderFormDivider,
    renderErrorModal,
    renderModal,
    handleSubmit,
    renderChooseMemberText,
    renderSelectColleagues,
    renderAddButton,
    renderSelectAttributesText,
    renderAttributeSelectionText,
    renderAttributes,
    renderAttributeSelectionNoteText,
    renderContextText,
    renderOptionalText,
    renderAdditionalInfoText,
    renderTextField,
    renderCancelButton,
    renderSendButton,
    renderWarningText,
  }) => {
    const classes = useStyle();

    if (loading)
      return (
        <Grid container className={classes.loaderBackground}>
          <Grid container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </Grid>
      );
    else
      return (
        <Grid container>
          {renderErrorModal()}
          {renderModal()}
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid
              container
              spacing={1}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              {renderChooseMemberText()}
              {usersArrayLength ===0 && renderSelectColleagues()}
              {renderAddButton()}
              {renderWarningText()}
              {renderFormDivider()}
              <Grid container>
                {renderSelectAttributesText()}
                {renderOptionalText()}
              </Grid>
              {renderAttributeSelectionText()}
              {renderAttributes()}
              {renderAttributeSelectionNoteText()}
              {renderFormDivider()}
              <Grid container>
                {renderContextText()}
                {renderOptionalText()}
              </Grid>
              {renderAdditionalInfoText()}
              {renderTextField()}
              {renderValidationModal()}
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                {renderCancelButton()}
                {renderSendButton()}
              </Grid>
            </Grid>
          </form>
        </Grid>
      );
  };
