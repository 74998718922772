import { CircularProgress, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { CustomDivider } from "../../../modules/client/share_feedback/LocalDivider";
import { State } from "../../../type";
import { ShareFeedbackByExternalClientLayoutProp } from "./type";

const drawerWidth = 0;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "inherit",
    },
    loaderBackground: {
      minHeight: `${window.innerHeight - 48}px`,
      padding: theme.spacing(6, 6, 6, 5),
      marginTop: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 4, 4, 4),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(11.25),
        padding: theme.spacing(4, 3, 3, 3),
      },
      background: "#FFFFFF",
    },
    mainSection: {
      margin: theme.spacing(2, 2, 1, 2),
      width: `calc(100% - 0px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        margin: theme.spacing(4, 18.75, 1, 18.75),
      },
    },
  })
);

export const Loader = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.loaderBackground}>
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const ShareFeedbackByExternalClientLayout: React.FC<ShareFeedbackByExternalClientLayoutProp> =
  ({
    isSubmit,
    renderLogo,
    renderWelcomeText,
    renderGuideLineText,
    renderFeedbackSection,
    renderSubmitFeedbackCTA,
    handleSubmit,
    renderScrollOnTop,
    renderShareFeedbackHeader,
    renderSuccessPageOnSuccessfulFeedbackSubmission,
    renderErrorModal,
    renderValidationModal,
    renderAllRightsReserved,
    renderFooter,
  }) => {
    const classes = useStyles();

    const loading: Boolean = useSelector(
      (state: State) =>
        state.attributesDataForClient?.loading ||
        state.submitFeedbackByClient.loading
    );
    return (
      <Grid xs={12} container direction="row" className={classes.root}>
        {renderShareFeedbackHeader()}
        <div className={classes.mainSection}>
          {!loading && (
            <>
              <Grid container justify="center">
                <form onSubmit={handleSubmit}>
                  {renderLogo()}
                  {renderErrorModal()}
                  {renderValidationModal()}
                  {!isSubmit && renderWelcomeText()}
                  {!isSubmit && renderGuideLineText()}
                  {!isSubmit && renderFeedbackSection()}
                  {!isSubmit && renderSubmitFeedbackCTA()}
                  {!isSubmit && renderScrollOnTop()}
                  {isSubmit &&
                    renderSuccessPageOnSuccessfulFeedbackSubmission()}
                </form>
              </Grid>
            </>
          )}
          {loading && (
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{ width: `${window.innerWidth}` }}
            >
              <Loader></Loader>
            </Grid>
          )}
        </div>
        {renderFooter()}
        <Grid
          xs={12}
          item
          style={{ position: "relative", margin: "40px 0 10px 0" }}
        >
          <CustomDivider />
        </Grid>
        {renderAllRightsReserved()}
      </Grid>
    );
  };
