import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddQuestionRequest } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";
import { post } from "../../util/APIService";

export interface AddQuestionSliceProp {
  attributeId: number;
  questionDetails: AddQuestionRequest;
}

export const addQuestion = createAsyncThunk(
  "admin/questions/add",
  async (params: AddQuestionSliceProp, storeActions) => {
    const url = `api/v1/admin/attributes/${params.attributeId}/questions`;
    const response: any = await post(url, params.questionDetails, storeActions);

    if (response.status === HttpStatus.CREATED) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        errorMessage: (await response.json()).message,
      };
    }
  }
);

const addQuestionSlice = createSlice({
  name: "addQuestion",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    errorMessage: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearAddQuestionData: (state) => {
      state.status = null;
      state.loading = false;
      state.errorMessage = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addQuestion.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.errorMessage = null;
      } else if (action.payload.errorMessage !== undefined) {
        state.data = null;
        state.errorMessage = action.payload.errorMessage;
      }
    });
    builder.addCase(addQuestion.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
    });
    builder.addCase(addQuestion.rejected, (state) => {
      state.status = HttpStatus.SERVICE_UNAVAILABLE;
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
    });
  },
});

export const { clearAddQuestionData } = addQuestionSlice.actions;

export const selectAddQuestionStatus = (state) => state.addQuestionData.status;
export const selectAddQuestionErrorMessage = (state) =>
  state.addQuestionData.errorMessage;

export default addQuestionSlice;
