import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { FeedbackRequestDetailsLayoutProp } from "../../../../modules/user/feedback_requests/typeFeedbackrequest";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { State } from "../../../../type";
import { HomeLayout } from "../../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(8.5, 6, 6, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(4, 3, 3, 3),
    },
    marginTop: theme.spacing(10),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: `${window.innerHeight - 102}px`,
  },
}));

export const FeedbackRequestDetailsLayout: React.FC<FeedbackRequestDetailsLayoutProp> =
  ({
    props,
    setLogout,
    isDetailView,
    setIsDetailView,
    renderErrorModal,
    renderDetails,
  }) => {
    const classes = useStyle();
    window.scrollTo(0,0);
    document.title = "Grow-Wize | Feedback Request Detail";
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const tempFunction = () => {
      return 0;
    };

    const renderToolBar = () => (
      <ToolBar
        headerName="Feedback Request Detail"
        setLogout={setLogout}
        isDetailView={isDetailView}
        setIsDetailView={setIsDetailView}
        handleDrawerToggle={handleDrawerToggle}
        value={-2}
        setValue={tempFunction}
        tabValue={-2}
        validLength={false}
      ></ToolBar>
    );

    const renderFeedbackRequestDetailLayout = () => (
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.root}
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid container justify="flex-start" alignItems="flex-start">
          {renderErrorModal()}
        </Grid>
        <Grid container alignItems="stretch" direction="column">
          {renderDetails()}
        </Grid>
      </Grid>
    );

    const loading = useSelector(
      (state: State) =>
        state.receivedRequestDetails.loading || state.data.loading
    );

    return (
      <HomeLayout
        loading={loading}
        renderToolBar={renderToolBar}
        props={props}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        renderSelectedOption={renderFeedbackRequestDetailLayout}
      ></HomeLayout>
    );
  };
