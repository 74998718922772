import {
  Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { clearData as clearSharedAssessmentDetail } from "../../../redux/slices/SharedAssessmentDetailSlice";
import { AddAttributeTitleText } from "../../constants/shareFeedbackText";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import {
  ResponseAttribute,
  SharedFeedbacksParams,
  State,
  UserAndAttributeSliceProps,
} from "../../../type";
import { AddNewButton } from "./addNewButton";
import { CancelButton, SendButton } from "./buttons";
import { ConsentModal } from "../request_feedback/request_employee/popUp";

import "../share_feedback/style.css";
import { TabLabels } from "./tabLabels";
import { SelfAssessmentLayout } from "../../../layouts/user/self_assessment/Layout";
import "../share_feedback/style.css";
import {
  EditAssessmentProps,
  ShareAssessmentAttribute,
  ShareAssessmentProps,
  SharedAssessmentDetailProps,
  SharedAssessmentDetailSliceProps,
} from "./type";
import { sharedAssessmentDetail } from "../../../redux/slices/SharedAssessmentDetailSlice";
import { shareAssessment } from "../../../redux/slices/ShareAssessmentSlice";
import { getSharedAssessmentList } from "../../../redux/slices/SharedAssessmentListSlice";
import { AssessmentList } from "./sharedAssessmentListContent";
import { countSharedAssessment } from "../../../redux/slices/CountSharedAssessmentsSlice";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import { TitleProps } from "../../components/type";
import { HeaderTitles } from "../../components/list/ListHeader";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { ValidationPromptModal } from "../../components/ValidationModal";
import { setUnsavedChanges } from "../../../redux/slices/ShareFeedbackSlice";
import editAssessment from "../../../redux/slices/EditSelfAssessment";
import { AssessmentStatus } from "../../../enums/assessmentEnum";

export const SelfAssessment = (props) => {
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState(false);
  const tabNumber = props.location.state?.tab;
  const pageValue = props.location.state?.pageNo;
  const [tabValue, setTabValue] = React.useState<number>(
    tabNumber ? tabNumber : 0
  );
  // console.log("tabValue", tabValue);
  const [attributes, setAttributes] = React.useState<ResponseAttribute[]>([]);
  const [shareAssessmentData, setShareAssessmentData] = React.useState<
    ShareAssessmentAttribute[]
  >([]);
  const [showModal, setShowModal] = React.useState(false);
  const [dispatched, setDispatched] = React.useState(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [isAnyChange, onAnyChange] = React.useState<Boolean>(false);
  const [savedDraftDate, setDraftDate] = React.useState<Date>(new Date());
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(-1);
  const [charLimitExceeded, setCharLimitExceeded] =
    React.useState<Boolean>(false);
  const [draftLoad, setDraftLoad] = React.useState<number>(0);
  const status = useSelector((state: State) => state.shareAssessment.status);
  const loading = useSelector((state: State) => state.shareAssessment.loading);
  const [assessmentId, setAssessmentId] = React.useState<number>(0);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const attr: Array<ResponseAttribute> = [];
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );

  const assessmentDraftData: SharedAssessmentDetailProps =
    useSelector((state: State) => state.sharedAssessmentDetail.data) ?? null;   
  const draftLoading: Boolean = useSelector(
    (state: State) => state.sharedAssessmentDetail.loading
  );
  const attLabels =
    useSelector((state: State) => state.data.data?.attributes) ?? attr;
  const [isSubmitted, setIsSubmitted] = React.useState<Boolean>(false);

  const count: number = 
    useSelector((state: State) => state.countSharedAssessments.data) ?? -1;
    const currentRoutePath: string = useLocation().pathname;
    const isEditAssessmentView: boolean = currentRoutePath.startsWith(
      "/home/self-assessment/edit"
    ); 
    const  { id }: any  = useParams();
    const editParams: SharedAssessmentDetailSliceProps = {
      assessmentId: id
    };
  
  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.shareAssessment.status !== 201
      ? state.shareAssessment.status
      : state.sharedAssessmentDetail.status !== 201
      ? state.sharedAssessmentDetail.status
      : state.countSharedAssessments.status !== 200
      ? state.countSharedAssessments.status
      : null
  );
  const titles: Array<TitleProps> = [
    {
      name: "Shared on",
      xsSize: 9,
      smSize: 9,
      mdSize: 11,
    },
    {
      name: "Action",
      xsSize: 3,
      smSize: 3,
      mdSize: 1,
    },
  ];

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: false,
      isAttributes: true,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
    dispatch(countSharedAssessment());
    const params: SharedAssessmentDetailSliceProps = {
      assessmentId: "0",
    };
    if(!isEditAssessmentView) {
      dispatch(sharedAssessmentDetail(params));
    }
  }, []);

  useEffect(() => {
    let requestParams: string = "";
    requestParams += "?offSet=" + (pageNumber - 1) * 10;
    let requestingParams: SharedFeedbacksParams = {
      requestingParams: requestParams,
    };
    dispatch(getSharedAssessmentList(requestingParams));
  }, [pageNumber, dispatch]);

  const history = useHistory();

  const renderTabLabels = () => (
    <TabLabels
      tabValue={tabValue}
      setTabValue={setTabValue}
      props={props}
    ></TabLabels>
  );

  useEffect(() => {
    if (
      assessmentDraftData &&
      assessmentDraftData.attributes?.length > 0 &&
      draftLoad < 4 &&
      !draftLoading
    ) {
      if (
        attributes.length === 0 ||
        attributes.length !== assessmentDraftData.attributes.length
      ) {
        let att: Array<ResponseAttribute> = [];
      
        assessmentDraftData.attributes.forEach(draftAttribute => {
          let  attribute = attLabels.find((label) => draftAttribute.name == label.name)
          if(attribute) {
            att.push(attribute)
            setAttributes(att);
          }
        });
      }

      if (attributes.length === assessmentDraftData.attributes.length) {
        let updatedShareFBAttribute: Array<ShareAssessmentAttribute> = [];
        for (let index = 0; index < attributes.length; index++) {
          const arg1: ShareAssessmentAttribute = {
            attributeId: attributes[index].id,
            attributeName: attributes[index].name,
            whatWentWell: assessmentDraftData.attributes[index]?.context,
            needsToBeImproved:
              assessmentDraftData.attributes[index]?.suggestions,
          };
          let idx = 0;
          for (idx = 0; idx < shareAssessmentData.length; idx++) {
            if (shareAssessmentData[idx].attributeId === attributes[index].id) {
              break;
            }
          }
          if (idx === shareAssessmentData.length) {
            updatedShareFBAttribute.push(arg1);
          } else {
            updatedShareFBAttribute.push(shareAssessmentData[idx]);
          }
        }
        if (
          attributes.length === assessmentDraftData.attributes.length &&
          shareAssessmentData.length === 0
        ) {
          setShareAssessmentData(updatedShareFBAttribute);
          dispatch(clearSharedAssessmentDetail());
          setDraftLoad(5);
        }
      }
      if (draftLoad === 0) {
        setDraftLoad(3);
      } else if (draftLoad === 3) {
        setDraftLoad(0);
      }
    }
  }, [assessmentDraftData, attributes, dispatch, draftLoad]);

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderModal = () => (
    <ConsentModal
      showModal={showModal}
      setShowModal={setShowModal}
      setValue={setValue}
      to={"SELF_ASSESSMENT"}
    ></ConsentModal>
  );

  const renderChangePageButtons = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <ChangePageButtonComponent
        pageCount={Math.ceil(count / 10)}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      ></ChangePageButtonComponent>
    </Grid>
  );

  const renderAddAttributeTitleText = () => <AddAttributeTitleText />;

  const renderAddNewButton = () => (
    <AddNewButton
      attributes={attributes}
      setAttributes={setAttributes}
      shareAssessmentData={shareAssessmentData}
      setShareAssessmentData={setShareAssessmentData}
      setValue={setValue}
      onAnyChange={onAnyChange}
    />
  );

  const renderCancelButton = () => (
    <CancelButton setValue={setValue}></CancelButton>
  );

  const renderSendButton = () => (
    <SendButton validLength={shareAssessmentData.length > 0}></SendButton>
  );

  const renderHeaderTabs = () => <HeaderTitles titles={titles} />;
  const renderRouterPrompt = () =>  {
    isDirty ? dispatch(setUnsavedChanges(true)) : dispatch(setUnsavedChanges(false))
   return null;
 };

  const renderAssessmentList = () => (
    <AssessmentList setAssessmentId={setAssessmentId} />
  );

  useEffect(() => {
    if (
      dispatched === true &&
      status === 201 &&
      loading === false &&
      isSubmitted
    ) {
      dispatch(clearSharedAssessmentDetail());
      setShareAssessmentData([]);
      setAttributes([]);
      setIsDirty(false);
      setShowModal(true);
      setDispatched(false);
    } else if (
      dispatched === true &&
      status === 201 &&
      loading === false &&
      value === 6
    ) {
      const timer = setTimeout(() => {
        if (loading === false && value === 6) {
          setValue(-1);
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatched, loading, status, isSubmitted]);

  useEffect(() => {
    if (isAnyChange) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [isAnyChange]);

  const AUTO_SAVE_INTERVAL = 30000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (!loading && tabValue === 0 && isAnyChange) {
        setValue(6);
        onAnyChange(false);
      }
    }, AUTO_SAVE_INTERVAL);
    return () => clearInterval(timer);
  }, [isAnyChange, loading, tabValue]);

  useEffect(() => {
    if (shareAssessmentData.length > 0) {
      setValue(-1);
    } else {
      setIsDirty(false);
      setValue(-2);
    }
  }, [shareAssessmentData]);

  useEffect(() => {
    if (status && status !== 201) {
      setValue(5);
    }
  }, [status, loading]);

  useEffect(() => {
    if (value === 6 && !isEditAssessmentView) {
      handleSubmit(window.event);
    }
  }, [value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const renderValidationModal = () => (
    <ValidationPromptModal
      open={open}
      setOpen={setOpen}
      charLimitExceeded={charLimitExceeded}
    />
  );

  function handleSubmit(e) {
    e?.preventDefault();
    let regex = /(<([^>]+)>)/ig
    if (tabValue === 0) {
      setCharLimitExceeded(false);

      let flag = false;
      let attributesLength = false;
      if (shareAssessmentData.length === 0) {
        attributesLength = true;
      } else {
        for (let idx = 0; idx < shareAssessmentData.length; idx++) {
          let str = shareAssessmentData[idx].whatWentWell.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'');
          let str1 = shareAssessmentData[idx].needsToBeImproved.replaceAll(/\&nbsp;/g, "").replaceAll(regex, "").replace(/\s/g,'').replace(/\n/g,'');
          if (str?.length < 1 && value !== 6) {
            flag = true;
          } else if (
            shareAssessmentData[idx].whatWentWell?.length < 9 &&
            value !== 6
          ) {
            flag = true;
          } else if (str1?.length < 1 && value !== 6) {
            flag = true;
          } else if (
            shareAssessmentData[idx].needsToBeImproved?.length < 9 &&
            value !== 6
          ) {
            flag = true;
          } else if (
            shareAssessmentData[idx].whatWentWell?.length > 10000 ||
            shareAssessmentData[idx].needsToBeImproved?.length > 10000
          ) {
            flag = true;
            setCharLimitExceeded(true);
          }
        }
      }

      if (flag) {
        handleOpen();
      } else if (shareAssessmentData.length > 0) {
        if (value !== 6) {
          setIsSubmitted(true);
        }
        const arg: ShareAssessmentProps = {
          status: value === 6 ? "DRAFTED" : "SUBMITTED",
          attributes: shareAssessmentData,
        };
        if(isEditAssessmentView){
          const arg: EditAssessmentProps = {
            assessmentId: `${id}`,
            status: AssessmentStatus.SUBMITTED,
            attributes: shareAssessmentData
          };
          dispatch(editAssessment(arg))
          setIsSubmitted(true);
          setShowModal(true);
          dispatch(clearSharedAssessmentDetail());
        }
        else 
        {
          dispatch(shareAssessment(arg));
          setDraftDate(new Date());
        }
        setDispatched(true);
      }
    }
  }

  if (value === 0) {
    return <Redirect to="/home" />;
  }

  if (assessmentId > 0) {
    history.push({
      pathname: `/home/self-assessment/${assessmentId}`,
      state: {
        pageNo: pageNumber,
      },
    });
  }

  return (
    <SelfAssessmentLayout
      props={props}
      setLogout={setLogout}
      value={value}
      setValue={setValue}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      isAnyChange={isAnyChange}
      onAnyChange={onAnyChange}
      savedDraftDate={savedDraftDate}
      tabValue={tabValue}
      renderTabs={renderTabLabels}
      renderErrorModal={renderErrorModal}
      renderValidationModal={renderValidationModal}
      renderModal={renderModal}
      handleSubmit={handleSubmit}
      renderAddAttributeTitleText={renderAddAttributeTitleText}
      renderAddNewButton={renderAddNewButton}
      renderCancelButton={renderCancelButton}
      renderSendButton={renderSendButton}
      renderHeaderTabs={renderHeaderTabs}
      renderAssessmentList={renderAssessmentList}
      renderChangePageButtons={renderChangePageButtons}
      validLength={shareAssessmentData.length > 0}
      renderRouterPrompt={renderRouterPrompt}
      isEditAssessmentView={isEditAssessmentView}
    ></SelfAssessmentLayout>
  );
};
