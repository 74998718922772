import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  CannotFindPageProps,
  Error404Props,
  ErrorCode,
} from "./typeErrorPages";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  typography_head: {
    color: "#E4E8FB",
    position: "relative",
    zIndex: 3,
    fontWeight: 600,
    fontSize: "100px",
    padding: theme.spacing(5, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: theme.spacing(7.5, 0, 0, 0),
    },
  },
  typography_body: {
    color: "#576BEA",
    position: "relative",
    zIndex: 3,
    fontWeight: 600,
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5, 0, 5),
      fontSize: "14px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "14px",
    padding: theme.spacing(1, 6, 1, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5, 3.25, 0.5, 3.25),
      fontSize: "10px",
    },
  },
  typography_error_code: {
    color: "#E4E8FB",
    fontWeight: 600,
    fontSize: "100px",
    padding: theme.spacing(6.25, 0, 5, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: theme.spacing(5, 0, 3.75, 0),
    },
  },

  typography_error_message: {
    color: "#576BEA",
    fontWeight: 600,
    fontSize: "24px",
    textAlign: "center",
    padding: theme.spacing(7.5, 0, 2.5, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: theme.spacing(5, 0, 2.25, 0),
    },
  },
  typography_sec_error_message: {
    color: "#BFBFBF",
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "center",
    margin: theme.spacing(0, 10),
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  errorButtonText: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "center",
    padding: theme.spacing(1.25, 5, 1.25, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.75, 1.25, 0.75, 1.25),
      fontSize: "10px",
    },
  },
}));

export const Error404: React.FC<Error404Props> = ({ client }) => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h3" className={classes.typography_head}>
      {client ? "Whoops! Error 400" : "Whoops! Error 404"}
    </Typography>
  );
};

export const CannotFindPage: React.FC<CannotFindPageProps> = ({
  client,
  status,
}) => {
  const classes = useStyle();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.typography_body}
    >
      {client ? status : "We cannot find the page you’re looking for"}
    </Typography>
  );
};

export const ButtonText = () => {
  const classes = useStyle();
  return (
    <Typography variant="body1" className={classes.buttonText}>
      BACK TO HOME
    </Typography>
  );
};

export const ErrorMessage: React.FC<ErrorCode> = ({ statusCode }) => {
  const classes = useStyle();
  let str = "";
  if (statusCode === 403) str = "Access denied";
  else if (statusCode === 401) str = "Login required";
  else if (statusCode === 101) str = "Cookies are disabled!";
  else str = "Something went wrong";

  return (
    <Typography variant="h3" className={classes.typography_error_message}>
      {str}
    </Typography>
  );
};

export const SecondaryErrorMessage: React.FC<ErrorCode> = ({
  statusCode,
  customErrorMessage,
}) => {
  const classes = useStyle();
  let str = "";
  if (statusCode === 403)
    str =
      "Sorry, but you do not have permission to access the requested information";
  else if (statusCode === 401) str = "Please log in and try again";
  else if (statusCode === 101)
    str =
      "Cookies are currently disabled/blocked for your browser. You must allow cookies to use Grow-Wize. You can enable cookies in the privacy settings of your browser.";
  else
    str =
      "We are sorry!!! There is a problem with our server. Please try again later";

  console.log("Custom error message : ", customErrorMessage);

  return (
    <Typography
      gutterBottom
      variant="h3"
      className={classes.typography_sec_error_message}
    >
      {customErrorMessage && customErrorMessage.length > 0
        ? customErrorMessage
        : str}
    </Typography>
  );
};

export const ErrorButtonText: React.FC<ErrorCode> = ({ statusCode }) => {
  const classes = useStyle();
  let str = "";
  if (statusCode === 403) str = "GO BACK";
  else if (statusCode === 401) str = "SIGN IN";
  else str = "OKAY";

  return (
    <Typography variant="h3" className={classes.errorButtonText}>
      {str}
    </Typography>
  );
};
