import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { DetailCardComponent } from "./ListContentCard";
import { HeaderTitles, HeaderTitlesWithNoData } from "./ListHeader";
import {
  receivedListTitles,
  sharedListTitles,
  receivedRequestTitles,
  sentRequestTitles,
} from "./ListTitles";
import { NoCustomDataText } from "./noRecordPage/NoRecordText";
import { TitleProps } from "../type";
import { ListContainerComponentProps } from "../type";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "inherit",
  },
  row: {
    width: "100%",
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
  },
}));

export const ListContainerComponent: React.FC<ListContainerComponentProps> = ({
  loading,
  data,
  setDocumentId,
  from,
  setUserId,
  setValue,
  requestType,
  setCancelRequestParam,
}) => {
  const classes = useStyle();

  let titles: Array<TitleProps> = [];
  if (from === "SHARED_FEEDBACK") titles = sharedListTitles;
  else if (from === "RECEIVED_FEEDBACK") titles = receivedListTitles;
  else if (from === "RECEIVED_REQUEST") titles = receivedRequestTitles;
  else if (from === "SENT_REQUEST") titles = sentRequestTitles;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Paper variant="outlined" square style={{ width: "inherit" }}>
        {data.length === 0 ? (
          <HeaderTitlesWithNoData titles={titles} />
        ) : (
          <HeaderTitles titles={titles} />
        )}
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && data.length === 0 ? (
          <NoCustomDataText from={from} requestType={requestType} />
        ) : (
          data.map((d) => (
            <DetailCardComponent
              user={d?.user}
              date={d?.createdAt}
              attributesName={d?.attributesName}
              documentId={d?.feedbackId}
              setUserId={setUserId}
              setDocumentId={setDocumentId}
              requestStatus={d?.feedbackRequestStatus}
              from={from}
              setValue={setValue}
              context={d?.context}
              key={`document-${d?.feedbackId}`}
              setCancelRequestParams={setCancelRequestParam}
            />
          ))
        )}
      </Paper>
    </Grid>
  );
};
