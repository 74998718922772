import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NudgeCountSliceProps } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const countNudge = createAsyncThunk(
  "Nudge/count",
  async (requestParams: NudgeCountSliceProps, storeActions) => {
    const url = `api/v1/admin/feedback/nudgeBoardUserListCount?` + requestParams.requestingParams;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const adminNudgeCountDataSlice = createSlice({
  name: "adminNudgeCountData",
  initialState: {
    status: null as number | null,
    text: null as string | null,
    count: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.count = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(countNudge.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.count = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.count = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(countNudge.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.text = null;
      state.count = null;
    });
    builder.addCase(countNudge.rejected, (state) => {
      state.status = 503;
      state.text = null;
      state.count = null;
      state.loading = false;
    });
  },
});

export const { clearData } = adminNudgeCountDataSlice.actions;

export default adminNudgeCountDataSlice;
