import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import theme from "../../../theme";
import { State } from "../../../type";
import { getTimeInDesiredFormat } from "../../../util/GetDateInActitivityFormat";

const useStyle = makeStyles((theme) => ({
  headerSection: {
    position: "fixed",
    height: theme.spacing(12.5),
    top: theme.spacing(0),
    boxShadow: "0 2px 10px 0 rgba(175,175,175,0.3)",
    backgroundColor: "#FFFFFF",
  },
  logo: {
    height: theme.spacing(8),
    width: theme.spacing(20.5),
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      height: theme.spacing(6),
      width: theme.spacing(15.5),
    },
  },
  headerSection2: {
    position: "fixed",
    height: theme.spacing(12.5),
    top: theme.spacing(0),
    left: `${window.innerWidth / 2}px`,
    boxShadow: "0 0px 10px 0 rgba(175,175,175,0.3)",
    backgroundColor: "#FFFFFF",
  },
  btn_: {
    textTransform: "none",
    border: "1px solid #576BEA",
    borderRadius: theme.spacing(0.5),
    color: "#576BEA",
    backgroundColor: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    marginRight: theme.spacing(5),
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      marginRight: theme.spacing(2),
    },
  },
  btn_Hover: {
    textTransform: "none",
    border: "1px solid #576BEA",
    borderRadius: theme.spacing(0.5),
    color: "#FFFFFF",
    backgroundColor: "#576BEA",
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    fontFamily: "Montserrat",
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      marginRight: theme.spacing(2),
    },
  },
  saveIcon: {
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5),
      margin: theme.spacing(0, 0.25, 0, 0),
    },
  },
  saveMargin: {
    margin: theme.spacing(0, 0, 0, 5),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5),
      margin: theme.spacing(0, 0.25, 0, 1.5),
    },
  },
  typograph_saving: {
    margin: theme.spacing(0, 0, 0.35, 0.75),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#A0A0A0",
    textAlign: "start",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 2, 0, 0.5),
      textAlign: "right",
    },
  },
  typograph_saved: {
    margin: theme.spacing(0, 0, 0, 0.75),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#2C8100",
    textAlign: "start",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 2, 0, 0.5),
      textAlign: "right",
    },
  },
  typograph_error: {
    margin: theme.spacing(0, 2, 0, 0.75),
    textAlign: "right",
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#F04D4D",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 1, 0, 0),
      textAlign: "left",
    },
  },
  last_saved: {
    color: "#A0A0A0",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "justify",
    wordBreak: "break-word",
    marginRight: "40px",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2),
      fontSize: theme.spacing(1.25),
    },
  },
  gridStyle: {
    display: "flex",
    marginRight: "32px",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function ShareFeedbackHeader({
  setValue,
  value,
  validLength,
  isDataChange,
  onDataChange,
  savedDraftDate,
}) {
  const classes = useStyle();
  const [isVisible, setIsVisible] = useState(false);
  const [isHover, setIsHover] = React.useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  const { loading, status } = useSelector(
    (state: State) => state.shareFeedbackByClient
  );
  return (
    <div className="scroll-to-top" style={{ zIndex: 10 }}>
      {isVisible && (
        <Grid
          xs={12}
          container
          alignItems="center"
          className={classes.headerSection}
        >
          <Grid
            xs={5}
            md={6}
            item
            container
            justify="flex-start"
            alignItems="center"
          >
            <img
              src="/LogoWithName.svg"
              alt="Grow Wize"
              className={classes.logo}
            />
          </Grid>
          <Grid
            xs={7}
            md={6}
            item
            container
            justify="flex-end"
            alignItems="center"
          >
            <>
              {value === 6 && loading && validLength && (
                <>
                  <Grid item xs={6} md={9}></Grid>
                  <Grid item xs={1}>
                    <img
                      src="/Shape2.svg"
                      alt="saving"
                      className={classes.saveIcon}
                      style={{
                        margin: theme.spacing(0, 0, 0, 4),
                      }}
                    ></img>
                  </Grid>
                  <Grid item xs={5} md>
                    <Typography className={classes.typograph_saving}>
                      Saving...
                    </Typography>
                  </Grid>
                </>
              )}
              {value === 6 && !loading && status === 201 && validLength && (
                <>
                  <Grid item xs={7} md={9}></Grid>
                  <Grid item className={classes.gridStyle}>
                    <img
                      src="/Shape.svg"
                      alt="saved"
                      className={`${classes.saveMargin} ${classes.saveIcon}`}
                    ></img>
                    <Typography className={classes.typograph_saved}>
                      Saved
                    </Typography>
                  </Grid>
                </>
              )}
              {value === 5 && !loading && status !== 201 && validLength && (
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                  style={{ margin: theme.spacing(0.25, 0, 0, 1) }}
                  justify="flex-end"
                >
                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/errorICON.svg"
                      alt="error"
                      className={classes.saveIcon}
                      style={{ margin: "0px 8px 0px 0px" }}
                    ></img>
                    <Typography className={classes.typograph_error}>
                      Failed to save draft
                    </Typography>
                    <Button
                      onClick={() => setValue(6)}
                      variant="outlined"
                      className={classes.btn_}
                      style={{
                        padding: theme.spacing(0, 0, 0, 0),
                        marginBottom: theme.spacing(0),
                      }}
                    >
                      Retry
                    </Button>
                  </Grid>
                </Grid>
              )}
              {value === -1 && validLength && isDataChange && (
                <Button
                  startIcon={
                    <img
                      src={isHover ? "/draftWhite.svg" : "/draftBlue.svg"}
                      alt="save"
                      className={classes.saveIcon}
                    />
                  }
                  onClick={() => {
                    setValue(6);
                    onDataChange(false);
                  }}
                  type="submit"
                  variant="outlined"
                  className={isHover ? classes.btn_Hover : classes.btn_}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  Save as draft
                </Button>
              )}
              {value === -1 && validLength && !isDataChange && (
                <Typography
                  className={classes.last_saved}
                >{`last saved ${getTimeInDesiredFormat(
                  savedDraftDate
                )}`}</Typography>
              )}
            </>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
