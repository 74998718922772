import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Props } from "../../../src/type";
import { post } from "../../util/APIService";

export const requestFeedback = createAsyncThunk(
  "requestFeedback/sendRequest",
  async (requestData: Props, storeActions) => {
    const url = `api/v1/user/requests`;
    const response: any = await post(url, requestData, storeActions);

    return {
      status: response.status,
    };
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      requestFeedback.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(requestFeedback.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(requestFeedback.rejected, (state) => {
      state.status = 503;
      state.loading = true;
    });
  },
});

export const { clearData } = requestSlice.actions;

export default requestSlice;
