import jsPDF from "jspdf";
import { ICollatedFeedbackData } from "../interface/feedback.interface";


const cheerio = require('cheerio');

let yOffset;
const bullet = "\u2022";

const formatTextAndAddToDoc = (text, tag, doc, tagName, pageHeight, link = null) => {
  doc.setFontSize(10);
  if (text.toString().trim() === "") {
    return;
  }
  switch (tagName) {
    case 'li': //li
      doc.setFont('Helvetica', 'normal');
      breakLines(`${bullet}  ${text.toString()}`, doc, pageHeight, true)
      break;
    case 'a': //link
      doc.setFont('Helvetica', 'normal');
      doc.text(`${text.toString()} [${link}]`, 18, yOffset);
      break;
    case 'strong': //bold
    case 'em': //italic
    case 'ins': //underline
    case 'p': //paragraph
    default:
      doc.setFont('Helvetica', 'normal');
      breakLines(`${text.toString()}`, doc, pageHeight, false)
      break;
  }
}

const breakLines = (content, doc, pageHeight, isBullet) => {
  if (content.trim() === "") return;
  const lines = doc.splitTextToSize(
    `${content}`,
    180
  );
  lines.forEach(line => {
    const xOffset = !isBullet ? 18 : line.startsWith(bullet) ? 18 : 21;
    doc.text(line, xOffset, yOffset);

    yOffset += 6;
    if (yOffset + 20 > pageHeight) {
      doc.addPage();
      yOffset = 30;
    }
  });
}

const cleanHtml = (htmlContent) => {
  let cleanedContent = htmlContent;
  cleanedContent = cleanedContent.replaceAll("<strong>", "");
  cleanedContent = cleanedContent.replaceAll("</strong>", "");
  cleanedContent = cleanedContent.replaceAll("<em>", "");
  cleanedContent = cleanedContent.replaceAll("</em>", "");
  cleanedContent = cleanedContent.replaceAll("<ins>", "");
  cleanedContent = cleanedContent.replaceAll("</ins>", "");
  cleanedContent = cleanedContent.replaceAll("<ins>", "");
  cleanedContent = cleanedContent.replaceAll("<br>", " ");

  return cleanedContent;
}

function convertHtmlToFormattedText(htmlContent, doc, pageHeight) {
  const $ = cheerio.load(htmlContent);

  // Define mapping for supported HTML tags to their text formatting
  const tagMapping = {
    p: '\n\n', // New paragraphs
    br: '\n',   // Line breaks
    ul: '\n',   // List items
    li: ' • ',  // List item bullets
    strong: 'b', // Strong text
    em: 'i',    // Italic text
    ins: 'u',   // Underlined text
    a: (text, node) => `[${text}](${node.attribs.href})`, // Links
  };

  // Recursively process elements and concatenate formatted text
  function processElement(element) {
    const tagName = element.name;
    if (tagName === undefined) {
      // Text node
      return element.data;
    } else if (tagName in tagMapping) {

      // If the tag is mapped, apply the formatting
      const format = tagMapping[tagName];
      if (typeof format === 'string') {
        formatTextAndAddToDoc(element.children.map(processElement).join(''), format, doc, tagName, pageHeight);

      } else if (typeof format === 'function') {
        formatTextAndAddToDoc(element.children.map(processElement).join(''), format, doc, tagName, pageHeight, element.attribs.href);
      }
    } else {
      // If the tag is not mapped, just process its children
      formatTextAndAddToDoc(element.children.map(processElement).join(''), "", doc, tagName, pageHeight);
    }
  }

  const formattedText = processElement($('body').children()[0]);
  return formattedText;
}

function generatePDF(
  pdfData: ICollatedFeedbackData[],
  pdfName: string,
  username: string,
  startDate: string,
  endDate: string
) {
  const doc = new jsPDF('p', 'mm', 'a4');

  yOffset = 40;
  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(18);
  doc.text(`Feedback Report - ${username}`, 10, 10);
  doc.setFontSize(14);
  doc.text(`${startDate} to ${endDate}`, 10, 20);
  doc.line(10, 25, 200, 25);

  let pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.getWidth();

  if (pdfData.length === 0) {
    doc.setTextColor(150);
    doc.setFontSize(18);
    const textWidth =
      (doc.getStringUnitWidth("No feedbacks found.") * 18) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - textWidth) / 2;
    const textY = (pageHeight - 18) / 2;
    doc.text("No feedbacks found.", textX, textY);
    doc.save(`${pdfName}.pdf`);
    return;
  }

  for (const item of pdfData) {
    if (yOffset + 20 > pageHeight) {
      doc.addPage();
      yOffset = 30;
    }

    doc.setFontSize(16);
    doc.text(`${item.attributeName}`, 10, yOffset);
    yOffset += 10;

    doc.setFontSize(12);
    doc.text(`Feedbacks : `, 10, yOffset);
    yOffset += 10;

    for (const feedback of item.feedbacks) {
      if (yOffset + 20 > pageHeight) {
        doc.addPage();
        yOffset = 30;
      }

      yOffset += 2;
      doc.setFontSize(10);

      const info = doc.splitTextToSize(
        `Received on ${feedback.date} by ${feedback.sentBy}`,
        180
      );

      doc.setFont('Helvetica', 'italic');
      doc.setTextColor("#7C69EC");
      doc.text(info, 15, yOffset);

      doc.setFont('Helvetica', 'normal', 'normal');
      doc.setTextColor("black");

      doc.setFontSize(10);
      yOffset += 6;

      const details = `<ul>${feedback.details}</ul>`
      convertHtmlToFormattedText(cleanHtml(details), doc, pageHeight);
    }

    yOffset += 6;
    if (item.suggestions.length) {
      doc.setFontSize(12);
      doc.text(`Suggestions : `, 10, yOffset);
      yOffset += 10;

      for (const suggestion of item.suggestions) {
        if (yOffset + 20 > pageHeight) {
          doc.addPage();
          yOffset = 30;
        }

        yOffset += 2;
        doc.setFontSize(10);
        const info = doc.splitTextToSize(
          `Received on ${suggestion.date} by ${suggestion.sentBy}`,
          180
        );

        doc.setFont('Helvetica', 'italic');
        doc.setTextColor("#7C69EC");
        doc.text(info, 15, yOffset);

        doc.setFont('Helvetica', 'normal', 'normal');
        doc.setTextColor("black");

        doc.setFontSize(10);

        yOffset += 6;

        const details = `<ul>${suggestion.details}</ul>`
        convertHtmlToFormattedText(cleanHtml(details), doc, pageHeight);

      }
    }

    yOffset += 6;
    if (yOffset + 20 > pageHeight) {
      doc.addPage();
      yOffset = 30;
    } else {
      doc.line(10, yOffset, 200, yOffset);
      yOffset += 10;
    }

    yOffset += 1;
  }

  doc.save(`${pdfName}.pdf`);
}

const getPDFName = (name: string, startDate: string, endDate: string) => {
  return `${name
    .split(" ")
    .join("_")}_Feedback_Report_${startDate}_to_${endDate}`;
};

export const generateFeedbackReport = (
  name: string,
  startDate: string,
  endDate: string,
  receivedFeedbacks: ICollatedFeedbackData[]
) => {
  if (name) {
    name = name.toUpperCase();
  }
  const pdfName = getPDFName(name, startDate, endDate);
  generatePDF(receivedFeedbacks, pdfName, name, startDate, endDate);
};
