import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../../home/Layout";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { Loader } from "../../attributeDetail/Layout";
import ToastMessage from "../../../../modules/components/ToastMessage";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(11, 6, 2, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "calc(100vh - 52px)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
}));

export const TeamDetailsLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  renderErrorModal,
  renderTeamName,
  renderEditTeamButton,
  renderContent,
  showSuccess,
  handleClose,
  message,
  tabValue,
  renderTabs
}) => {

  const indexToTab =
  {
    0: "Team Details",
    1: "Round Robin",
  };
  document.title = `${indexToTab[tabValue]}`;
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderToolBar = () => (
    <ToolBar
      headerName={renderTeamName()}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={0}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderTeamDetailsLayout = () => (
    <>
      {renderErrorModal()}
      <Grid container direction="row" className={classes.root} justify="center">
        {loading ? (
          <Grid
            item
            xs={12}
            container
            style={{ width: "100%", height: "80vh", position: "absolute" }}
          >
            <Loader></Loader>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            style={{ width: "inherit" }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <ToastMessage
                open={showSuccess}
                handleClose={handleClose}
                message={message}
              />

            </Grid>
            {renderTabs()}
            {tabValue === 0 ? (
              <>
                {renderEditTeamButton()}
                {renderContent()}
              </>
            ) : (
              renderContent()
            )}

          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderTeamDetailsLayout}
    ></HomeLayout>
  );
};
