import React from "react";
import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import { LeaderCardProps } from "./type";
import theme from "../../../theme";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    minHeight: theme.spacing(23.25),
    width: theme.spacing(22.5),
    maxWidth: theme.spacing(28),
    backgroundColor: `#F5F6FA`,
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(1.25, 1.75),
    padding: theme.spacing(1.5),
  },
}));

export const TopLeaderCardComponent: React.FC<LeaderCardProps> = ({
  users,
  feedbackCount,
}) => {
  const classes = useStyles();
  let names: Array<string> = [];
  if (users) {
    names = users?.map((u) => u?.firstName + " " + u?.lastName);
    names = names.reverse();
  }
  return (
    <Grid xs={12} item container justify="center" alignItems="center">
      <Grid
        xs={12}
        item
        container
        justify="center"
        alignItems="center"
        className={classes.cardGrid}
      >
        <Grid item container justify="center">
          <div className="avatars">
            {users?.map((u, idx) => (
              <span className="avatar" id={`1-${idx}`} key={`1-${idx}`}>
                <img
                  src={u.imageUrl}
                  alt={u.firstName}
                  style={{
                    height: theme.spacing(8.75),
                    width: theme.spacing(8.75),
                  }}
                />
              </span>
            ))}
          </div>
        </Grid>
        <Grid item container justify="center">
          <Avatar
            src="/Position1Icon.svg"
            alt="1st"
            style={{
              height: theme.spacing(4),
              width: theme.spacing(4),
              position: "relative",
              top: "-17px",
            }}
          ></Avatar>
        </Grid>

        <Grid xs item container justify="center">
          <Typography
            variant="h5"
            style={{
              color: "#272727",
              margin: theme.spacing(0, 0, 1, 0),
            }}
          >
            {names.length > 0 && names.join(", ")}
          </Typography>
        </Grid>

        <Grid item container justify="center">
          <Typography variant="h1" style={{ color: "#5F41B2" }}>
            {feedbackCount}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
