import { makeStyles } from "@material-ui/core";

export const teamListTableItemStyles = makeStyles((theme) => ({
    container: {
        padding: "14px 44px",
        height: theme.spacing(11),
        borderTop: theme.spacing(0.005),
        borderColor: "#97979724",
        borderLeft: theme.spacing(0),
        borderRight: theme.spacing(0),
        borderBottom: theme.spacing(0),
        borderStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 0, 1, 0),
        },
    },
    typography_body_3: {
        textAlign: "start",
        alignItems: "center",
        fontSize: theme.spacing(1.75),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
            padding: theme.spacing(0, 0, 0, 1),
            marginRight: theme.spacing(1),
        },
    },
    typography_body_2: {
        textAlign: "left",
        wordBreak: "break-word",
        fontWeight: 500,
        fontSize: theme.spacing(1.75),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 0, 0, 0),
            fontSize: theme.spacing(1.25),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 0, 0, 1.5),
        },
    },
}));

export const teamListTableStyles = makeStyles((theme) => ({
    root: {
        width: "inherit",
    },
    header: {
        padding: "14px 44px",
        backgroundColor: "rgba(87,107,234,0.05)",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 0, 1, 0),
        },
    },
    typography_body: {
        color: "#868686",
        fontSize: "14px",
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
            padding: theme.spacing(0, 0, 0, 1),
        },
    },
}));

export type TitleProps = {
    name: string;
    size: | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | "auto"
    | boolean
    | undefined;
}

export const TITLE: Array<TitleProps> = [
    {
        name: "Team Name",
        size: 4,
    },
    {
        name: "Team Lead",
        size: 4,
    },
    {
        name: "Members",
        size: 3,
    },
    {
        name: "Action",
        size: 1,
    },
];
