import { Grid } from "@material-ui/core"
import React from "react"
import theme from "../../../theme"

export const CustomDivider = () => {
  return (<Grid container alignItems="stretch" direction="column">
  <div
    style={{
      border: "0.5px solid #bfbfbf36",
      height: "0.10vw",
      margin: theme.spacing(0, 3, 0, 3),
    }}
  ></div>
</Grid>)
}