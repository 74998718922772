import { makeStyles } from "@material-ui/core";
import { ReceivedFeedbackDataProps } from "../../../type";
import { flatten, orderBy, map } from 'lodash';

export const reportAttributeDetailStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "100%",
        marginTop: theme.spacing(3)
    },
    wrapperSelf: {
        marginTop: theme.spacing(7)
    },
    listItemWrapper: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 4px 0px rgba(175, 175, 175, 0.5)",
        borderRadius: theme.spacing(1),
        background: "#FFFFFF",
        width: "100%"
    },
    feedbackHeader: {
        display: "grid",
        gridTemplateColumns: "38px max-content auto",
        gap: theme.spacing(2),
        alignItems: "center",
        background: "#F7F8FE",
        borderRadius: theme.spacing(1),
        width: "100%",
        padding: theme.spacing(2),
    },
    profilePic: {
        width: 38,
        height: 38,
        borderRadius: "50%"
    },
    name: {
        color: "#272727",
        fontSize: 16,
        fontWeight: 700
    },
    dateWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    receivedText: {
        color: "#272727",
        fontSize: 14,
        fontWeight: 500
    },
    date: {
        color: "#272727",
        fontSize: 14,
        fontWeight: 600
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        padding: theme.spacing(4, 2),
    },
    content: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2)
    },
    title: {
        color: "#272727",
        fontSize: 18,
        fontWeight: 700
    },
    feedbackText: {
        color: "#272727",
        fontSize: 14,
        fontWeight: 400,
        wordWrap: "break-word",
        overflow: "hidden"
    },
    divider: {
        width: "calc(100% + 16px)",
        height: 1,
        background: "#E7E7E7",
        margin: "16px 0px",
        marginLeft: theme.spacing(-1)
    }
}));

export const getMappedFeedbacks = (data: ReceivedFeedbackDataProps) => {
    const mappedData = map(data?.responseDetails, (memberFeedbacks => {
        const mapped = map(memberFeedbacks?.details, feedback => ({
            ...feedback,
            userName: memberFeedbacks?.userName,
            imageUrl: memberFeedbacks?.imgUrl
        }));
        return mapped;
    }))
    const flattedData = flatten(mappedData);
    const sortedData = orderBy(flattedData, "date", ["desc"]);
    return sortedData;
}