import React, { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { Typography } from "@material-ui/core";
import updateAttributeStatusModalStyles from "./helper";
import { useSelector } from "react-redux";
import { State } from "../../../../type";

export const UpdateAttributeStatusModal = ({ open, handleCancel, handleConfirm, onClose }) => {
    const [submitted, setSubmitted] = useState(false);

    const classes = updateAttributeStatusModalStyles()

    const loading: Boolean | undefined = useSelector(
        (state: State) => state.adminAttributeData.loading
    );

    useEffect(() => {
        if (submitted && !loading) {
            onClose();
        }
    }, [submitted, loading])

    const handleSubmit = () => {
        handleConfirm();
        setSubmitted(true);
    }

    return (
        <Modal open={open} loading={loading} onClose={onClose}>
            <ModalHeader title="Disable Attribute" handleCancel={handleCancel} showBackground={false} />
            <ModalBody>
                <Typography className={classes.text}>
                    Once you disable the attribute, you won't be able to make any changes to its details. It will be in a read-only state.
                    <br />Are you certain you want to proceed with disabling this attribute?
                </Typography>
            </ModalBody>
            <ModalFooter buttonLabel="Disable" handleCancel={handleCancel} handleSubmit={handleSubmit} />
        </Modal>
    );
};
