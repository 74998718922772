import {
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { appColor } from "../../../constants/customThemeColor";
import { useCustomStyle } from "../../../../styles";
import { AttributeCardProp } from "../../../user/shared_feedback/typeSharedFeedbacks";

const useStyle = makeStyles((theme) => ({
  typography_body_1: {
    textAlign: "justify",
    margin: theme.spacing(0),
    letterSpacing: "1px",
    fontSize: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.875, 0, 2.25, 0),
      fontSize: theme.spacing(1.25),
    },
  },
  typography_body_2: {
    color: `${appColor.pureBlack}`,
    letterSpacing: "1px",
    padding: theme.spacing(0),
    fontSize: theme.spacing(2.25),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  attributeTitleBar: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(0),
    width: "-webkit-fill-available",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(87,107,234,0.05)",
    },
  },
  container: {
    padding: theme.spacing(0, 2.5, 0, 2.5),
    margin: theme.spacing(1.5, 0, 1.5, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5, 0, 1.5),
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
}));

export const FAQCard: React.FC<AttributeCardProp> = ({
  attribute,
  id,
  showDetails,
  setShowDetails,
  from,
  faq,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();

  const handleClickDetail = (showDetails, setShowDetails, idx) => {
    let newValue = [...showDetails];
    newValue[idx] = !newValue[idx];
    setShowDetails(newValue);
  };
  return (
    <>
      {
        <Grid xs={12} item>
          <Button
            onClick={() => handleClickDetail(showDetails, setShowDetails, id)}
            className={`${customClasses.popperWidth} ${classes.attributeTitleBar}`}
          >
            <Grid container xs={12} item alignItems="center">
              <Grid container item xs={11} justify="flex-start">
                <Typography variant="h2" className={classes.typography_body_2}>
                  {from === "FAQ" ? faq?.question : attribute.name}
                </Typography>
              </Grid>
              <Grid container item xs={1} justify="flex-end">
                <IconButton>
                  {showDetails && showDetails[id] ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      }
      {showDetails && showDetails[id] && (
        <Grid container direction="column" className={classes.container}>
          <Grid container direction="column">
            <Typography
              variant="h6"
              className={`${customClasses.typography_font_14_10} ${classes.typography_body_1}`}
              dangerouslySetInnerHTML={{
                __html: from === "FAQ" && faq ? faq?.answer : attribute.context,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
