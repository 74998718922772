import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseAttribute } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getAllAttributesData = createAsyncThunk(
  "attributeData/getAllAttributes",
  async (_, storeActions) => {
    const url = `api/v1/admin/attributes`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const adminAttributeDataSlice = createSlice({
  name: "adminAttributeData",
  initialState: {
    status: null as number | null,
    text: null as string | null,
    attributes: [] as ResponseAttribute[] | [],
    loaded: false as boolean,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.loaded = false;
      state.text = null;
      state.attributes = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAttributesData.fulfilled, (state, action) => {
      state.status = action.payload.status;
      if (action.payload.data !== undefined) {
        state.attributes = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.attributes = [];
        state.text = action.payload.text;
      }
      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(getAllAttributesData.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.text = null;
      state.attributes = state.attributes?.length > 0 ? state.attributes : [];
      state.loaded = false;
    });
    builder.addCase(getAllAttributesData.rejected, (state, action) => {
      state.status = 503;
      state.text = null;
      state.attributes = [];
      state.loaded = false;
      state.loading = false;
    });
  },
});

export const { clearData } = adminAttributeDataSlice.actions;

export default adminAttributeDataSlice;
