import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography_head: {
      color: "#576BEA",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    typography_body: {
      color: "#272727",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    typography_body_1: {
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    typography_error: {
      color: "#c40808",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  })
);

interface TextProp {
  from?: string;
}

export const ThankYouText = () => {
  const classes = useStyles();

  return (
    <Typography gutterBottom variant="h1" className={classes.typography_head}>
      <b>THANK YOU!</b>
    </Typography>
  );
};

export const RequestSuccessfulText: React.FC<TextProp> = ({ from }) => {
  const classes = useStyles();

  return (
    <Typography gutterBottom variant="h2" className={classes.typography_body}>
      {(from === "REQUEST_EMPLOYEE" || from === "REQUEST_CLIENT") && (
        <b>Feedback request successfully sent!</b>
      )}
      {from === "SHARE_FEEDBACK" && <b>Feedback successfully sent!</b>}
      {from === "SELF_ASSESSMENT" && (
        <b>Self-assessment successfully submitted!</b>
      )}
    </Typography>
  );
};

export const ReturnDashboardText = () => {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={classes.typography_body_1}>
      RETURN TO DASHBOARD
    </Typography>
  );
};

export const RequestAnotherText: React.FC<TextProp> = ({ from }) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={classes.typography_body_1}>
      {(from === "REQUEST_EMPLOYEE" || from === "REQUEST_CLIENT") &&
        "REQUEST ANOTHER"}
      {from === "SHARE_FEEDBACK" && "SHARE ANOTHER"}
      {from === "SELF_ASSESSMENT" && "SUBMIT ANOTHER"}
    </Typography>
  );
};

export const ErrorMessageText = () => {
  const classes = useStyles();

  return (
    <Typography gutterBottom variant="h1" className={classes.typography_error}>
      <b>Please login again</b>
    </Typography>
  );
};
