import { Grid, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import theme from "../../../../theme";

const useStyle = makeStyles((theme) => ({
  typography_body_1: {
    color: "#868686",
    textAlign: "left",
    padding: theme.spacing(1, 1, 1, 1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  name: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
    },
  },
  header: {
    padding: theme.spacing(1, 0, 1, 0),
    backgroundColor: "rgba(87,107,234,0.05)",
  },
}));

export const HeaderTabs = () => {
  const classes = useStyle();

  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="flex-start"
      className={classes.header}
    >
      <Grid
        container
        item
        justify="flex-start"
        alignItems="center"
        className={classes.name}
        md={3}
        xs={4}
      >
        <Typography variant="h5" className={classes.typography_body_1}>
          Name
        </Typography>
      </Grid>
        <Grid item container justify="flex-start" alignItems="center" md={2} xs={4}>
          <Typography variant="h5" className={classes.typography_body_1}>
            Updated
          </Typography>
        </Grid>
      <Hidden smDown>
        <Grid item container justify="flex-start" alignItems="center" md={4}>
          <Typography variant="h5" className={classes.typography_body_1}>
            Attributes
          </Typography>
        </Grid>
      <Grid item container justify="flex-start" alignItems="center" md xs={4}>
        <Typography variant="h5" className={classes.typography_body_1}>
          Type
        </Typography>
      </Grid>
      </Hidden>
      <Grid item container justify="flex-start" alignItems="center" md={1} xs={2}>
        <Typography variant="h5" className={classes.typography_body_1}>
          Action
        </Typography>
      </Grid>
    </Grid>
  );
};

export const HeaderTabsForNoRequests = () => {
  const classes = useStyle();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{
        padding: theme.spacing(0, 6.25, 0, 2.5),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(0, 3.75, 0, 1),
        },
      }}
      className={classes.header}
    >
      <Grid item>
        <Typography variant="h5" className={classes.typography_body_1}>
          Name
        </Typography>
      </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.typography_body_1}>
            Updated
          </Typography>
        </Grid>
      <Hidden smDown>
        <Grid item>
          <Typography variant="h5" className={classes.typography_body_1}>
            Attributes
          </Typography>
        </Grid>
      <Grid item>
        <Typography variant="h5" className={classes.typography_body_1}>
          Type
        </Typography>
      </Grid>
      </Hidden>
      <Grid item>
        <Typography variant="h5" className={classes.typography_body_1}>
          Action
        </Typography>
      </Grid>
    </Grid>
  );
};
