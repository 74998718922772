import { TitleProps } from "../type";

export const sharedListTitles: Array<TitleProps> = [
  {
    name: "Name",
    xsSize: 6,
    smSize: 6,
    mdSize: 3,
    lgSize: 3,
  },
  {
    name: "Shared Date",
    xsSize: 3,
    smSize: 3,
    mdSize: 2,
    lgSize: 2,
  },
  {
    name: "Attributes",
    xsSize: undefined,
    smSize: undefined,
    mdSize: 5,
    lgSize: 5,
  },
  {
    name: "Action",
    xsSize: 3,
    smSize: 3,
    mdSize: 2,
    lgSize: 2,
  },
];

export const receivedListTitles: Array<TitleProps> = [
  {
    name: "Name",
    xsSize: 6,
    smSize: 6,
    mdSize: 3,
    lgSize: 3,
  },
  {
    name: "Received Date",
    xsSize: 3,
    smSize: 3,
    mdSize: 2,
    lgSize: 2,
  },
  {
    name: "Attributes",
    xsSize: undefined,
    smSize: undefined,
    mdSize: 5,
    lgSize: 5,
  },
  {
    name: "Action",
    xsSize: 3,
    smSize: 3,
    mdSize: 2,
    lgSize: 2,
  },
];

export const sentRequestTitles: Array<TitleProps> = [
  {
    name: "Name",
    xsSize: 6,
    smSize: 6,
    mdSize: 3,
  },
  {
    name: "Requested Date",
    xsSize: undefined,
    mdSize: 2,
  },
  {
    name: "Attributes",
    xsSize: undefined,
    mdSize: 4,
  },
  {
    name: "Status",
    xsSize: 3,
    smSize: 3,
    mdSize: true,
  },
  {
    name: "Action",
    xsSize: 3,
    smSize: 3,
    mdSize: true,
  },
];

export const receivedRequestTitles: Array<TitleProps> = [
  {
    name: "Name",
    xsSize: 6,
    smSize: 6,
    mdSize: 3,
  },
  {
    name: "Requested Date",
    xsSize: undefined,
    mdSize: 2,
  },
  {
    name: "Context",
    xsSize: undefined,
    mdSize: 4,
  },
  {
    name: "Status",
    xsSize: 3,
    smSize: 3,
    mdSize: true,
  },
  {
    name: "Action",
    xsSize: 3,
    smSize: 3,
    mdSize: true,
  },
];
