import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { HeaderTitlesProps } from "../type";

const useStyle = makeStyles((theme) => ({
  typography_body: {
    color: "#868686",
    textAlign: "center",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0, 0, 0, 1),
    },
  },
  typography_body_1: {
    color: "#868686",
    textAlign: "start",
    paddingLeft: theme.spacing(2.5),
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  header: {
    padding: theme.spacing(1.75, 0, 1.75, 0),
    backgroundColor: "rgba(87,107,234,0.05)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
}));

const hiddenTitles = ["Attributes", "Context", "Requested Date"];

export const HeaderTitles: React.FC<HeaderTitlesProps> = ({ titles, from }) => {
  const classes = useStyle();
  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.header}
    >
      {titles?.map((title) => (
        <React.Fragment key={title.name}>
          {hiddenTitles.includes(title.name) ? (
            <Hidden smDown>
              <Grid
                key={title.name}
                item
                xs={title?.xsSize}
                sm={title?.smSize}
                md={title?.mdSize}
                lg={title?.lgSize}
              >
                <Typography variant="h5" className={classes.typography_body_1}>
                  {title.name}
                </Typography>
              </Grid>
            </Hidden>
          ) : (
            <Grid
              key={title.name}
              item
              xs={title?.xsSize}
              sm={title?.smSize}
              md={title?.mdSize}
              lg={title?.lgSize}
            >
              <Typography variant="h5" className={classes.typography_body_1}>
                {title.name.includes("Date") && window.innerWidth < 800
                  ? "Date"
                  : title.name}
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export const HeaderTitlesWithNoData: React.FC<HeaderTitlesProps> = ({
  titles,
}) => {
  const classes = useStyle();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.header}
      style={{ padding: "14px 20px 14px 20px" }}
    >
      {titles.map((title) => (
        <Grid item key={title.name}>
          <Typography variant="h5" className={classes.typography_body}>
            {title.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
