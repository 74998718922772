import React from "react";
import Chart from "react-apexcharts";
import { TrendChartProp } from "./type";
import "./style.css";
import {
  getDateInDesiredFormat,
  getMonthAndYearFormat,
} from "../../../util/GetDateInDesiredFormat";
export enum ChartCurve {
  smooth = "smooth",
  straight = "straight",
  stepline = "stepline",
}

export enum GridPosition {
  back = "back",
  front = "front",
}

export enum ChartType {
  line = "line",
}

export enum ChartLineCap {
  butt = "butt",
  round = "round",
  square = "square",
}

export enum ChartFill {
  gradient = "gradient",
}

export enum ChartXAxis {
  datetime = "datetime",
}

export interface DataValueProp {
  x: string;
  y: number;
}

export const TrendChart: React.FC<TrendChartProp> = ({ data }) => {
  let arr: Array<DataValueProp> = [];
  if (data.length > 0 && data[0].date !== null) {
    data.map((d) => {
      arr.push({
        x: getDateInDesiredFormat(new Date(d.date)),
        y: d.numberOfFeedbackExchanged,
      });
    });
  } else if (data.length > 0 && data[0].monthYear !== null) {
    data.map((d) => {
      arr.push({
        x: getMonthAndYearFormat(d.monthYear),
        y: d.numberOfFeedbackExchanged,
      });
    });
  }
  const options = {
    noData: {
      text: "No data in this range",
    },
    grid: {
      show: true,
      position: GridPosition.back,
    },
    series: [
      {
        type: "column",
        data: arr,
      },
    ],
    stroke: {
      curve: ChartCurve.smooth,
      show: true,
      width: 5,
      dashArray: 0,
      lineCap: ChartLineCap.butt,
    },
    markers: {
      size: 1,
    },
    fill: {
      type: ChartFill.gradient,
    },
    colors: ["#7C69EC"],
    chart: {
      type: ChartType.line,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    legend: {
      show: false,
    },
  };
  return (
    <Chart
      options={options}
      series={[
        {
          type: "column",
          data: arr,
        },
      ]}
      type="line"
      width="100%"
      height="330"
    />
  );
};
