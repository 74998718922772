import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { NoCustomDataTextProps, NoRecordFoundInTableProps, NoRecordTextProps } from "./type";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "14px 44px",
    height: theme.spacing(11),
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  typography_head: {
    color: "#576BEA",
    textAlign: "center",
    padding: theme.spacing(10, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  typography_body: {
    color: "#272727",
    textAlign: "center",
    padding: theme.spacing(0, 4, 0, 4),
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  typography_body_1: {
    color: "#FFFFFF",
    textAlign: "center",
    padding: theme.spacing(0.5, 3, 0.5, 3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  typography_body_2: {
    textAlign: "left",
    wordBreak: "break-word",
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0, 1.5),
    },
  },
  typography_body_3: {
    fontSize: theme.spacing(2),
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const NoRecordMainText: React.FC<NoRecordTextProps> = ({ from }) => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h1" className={classes.typography_head}>
      <b>{from === "RECEIVED_REQUEST" && "No feedback requests"}</b>
      <b>
        {from === "SHARED_FEEDBACK" && "You’ve not shared any feedback yet!"}
      </b>
      <b>{from === "SENT_REQUEST" && "No feedback requests"}</b>
      <b>
        {from === "RECEIVED_FEEDBACK" &&
          "You've not received any feedback yet!"}
      </b>
    </Typography>
  );
};

export const NoRecordSubText: React.FC<NoRecordTextProps> = ({ from }) => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h2" className={classes.typography_body}>
      <b>
        {from === "RECEIVED_REQUEST" &&
          "Do not wait for a request, share feedback NOW!"}
      </b>
      <b>{from === "SHARED_FEEDBACK" && "Share a feedback NOW!"}</b>
      <b>
        {from === "SENT_REQUEST" &&
          "Do not wait for a request, share feedback NOW!"}
      </b>
      <b>{from === "RECEIVED_FEEDBACK" && "Request for a feedback NOW!"}</b>
    </Typography>
  );
};

export const ActionCTAText: React.FC<NoRecordTextProps> = ({ from }) => {
  const classes = useStyle();
  return (
    <Typography variant="h5" className={classes.typography_body_1}>
      {from === "SHARED_FEEDBACK" && "SHARE FEEDBACK"}
      {from === "RECEIVED_REQUEST" && "SHARE FEEDBACK"}
      {from === "RECEIVED_FEEDBACK" && "REQUEST FEEDBACK"}
      {from === "FEEDBACK_SUMMARY" && "REQUEST FOR FEEDBACK"}
    </Typography>
  );
};

export const NoCustomDataText: React.FC<NoCustomDataTextProps> = ({
  requestType,
  from,
}) => {
  const classes = useStyle();

  const allText: string = "No received request";
  const pendingText: string = "No pending request";
  const closedText: string = "No closed request";
  const noSendRequestText: string = "No request sent";
  const noReceivedFeedbackText: string = "No Feedback Received...";
  const noSharedFeedbackText: string = "No Feedback Shared...";
  const noAssessmentText: string = "No assessment submitted yet!";
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "50vh" }}
    >
      <Typography variant="h4" className={classes.typography_body_3}>
        {from === "RECEIVED_REQUEST" && requestType &&
          (requestType === "ALL"
            ? allText
            : requestType === "PENDING"
            ? pendingText
            : requestType === "CLOSED"
            ? closedText
            : null)}
        {from === "SENT_REQUEST" && noSendRequestText}
        {from === "RECEIVED_FEEDBACK" && noReceivedFeedbackText}
        {from === "SHARED_FEEDBACK" && noSharedFeedbackText}
        {from === "SHARED_ASSESSMENT" && noAssessmentText}
      </Typography>
    </Grid>
  );
};

export const NoRecordFoundInTable: React.FC<NoRecordFoundInTableProps> = ({
  from,
}) => {
  const classes = useStyle();
  return (
    <Grid
      xs={12}
      item
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={`${classes.container}`}
    >
      <Typography variant="body2" className={classes.typography_body_2}>
        {from === "TEAMS_LIST" && "No Teams Found..."}
      </Typography>
    </Grid>
  );
};
