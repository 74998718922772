import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { ToolBar } from "../../../modules/home/ToolBar";
import { DraftList } from "../../../modules/user/share_feedback/drafts/draftList";
import theme from "../../../theme";
import { ShareFeedbackFormProps, State } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5.5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    minHeight: `${window.innerHeight - 102}px`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 3, 3, 3),
      marginTop: theme.spacing(11.25),
    },
  },
  form: {
    marginTop: theme.spacing(3),
    width: "inherit",
  },
}));

export const ShareFeedbackLayout: React.FC<ShareFeedbackFormProps> = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  selectedUser,
  isUserSelected,
  shareFeedbackData,
  isContext,
  isDataChange,
  savedDraftDate,
  onDataChange,
  value,
  setValue,
  tabValue,
  setTabValue,
  setSelectedDraftId,
  setShareFeedbackData,
  setSelectedUser,
  setShowDraftWithId,
  setIsContext,
  renderTabs,
  renderContext,
  renderContextHintText,
  renderContextOptionText,
  renderTextBox,
  renderErrorModal,
  renderValidationModal,
  renderModal,
  handleSubmit,
  renderChooseMemberText,
  renderSelectColleagues,
  renderAddButton,
  renderCancelButton,
  renderSendButton,
  renderAddAttributeText,
  renderAddAttributeTitleText,
  renderAddNewButton,
  renderRouterPrompt,
  renderPromptModal,
  setAttributes,
}) => {
  const classes = useStyle();
  if (tabValue === 0) {
    document.title = "Grow-Wize | Share Feedback";
  } else {
    document.title = "Grow-Wize | Drafts";
  }
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const loading: Boolean = useSelector(
    (state: State) => state.data.loading || state.submitFeedback.loading
  );
  const draftLoading: Boolean =
    useSelector((state: State) => state.sharedDraftDetail.loading) ?? null;

  const renderToolBar = () => (
    <ToolBar
      headerName="Share Feedback"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={value}
      setValue={setValue}
      tabValue={tabValue}
      validLength={shareFeedbackData.length > 0}
      isDataChange={isDataChange}
      savedDraftDate={savedDraftDate}
      onAnyChange={onDataChange}
    ></ToolBar>
  );

  const renderShareFeedbackLayout = () => (
  <>
    {renderPromptModal()}
    <Grid
      container
      xs={12}
      direction="column"
      className={classes.root}
      justify="flex-start"
      alignItems="flex-start"
    >
      {renderTabs()}
      {tabValue === 0 && (
        <>
          {renderErrorModal()}
          {renderModal()}
          {loading && value !== 6 && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "65vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
          {(!loading || (loading && value === 6)) && (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid xs={12} item container direction="row">
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  {renderChooseMemberText()}
                  {!isUserSelected && renderSelectColleagues()}
                  {renderAddButton()}

                  <Grid container alignItems="stretch" direction="column">
                    <div
                      style={{
                        border: "0.5px solid #bfbfbf36",
                        height: "0.10vw",
                        margin: theme.spacing(5, 0, 4, 0),
                      }}
                    ></div>
                  </Grid>
                  {draftLoading && (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: "35vh" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  {!draftLoading && (
                    <>
                      <Grid container justify="flex-start" alignItems="center">
                        {selectedUser && renderContext()}
                        <Grid style={{ marginTop: theme.spacing(-1.25) }}>
                          {selectedUser &&
                            !isContext &&
                            renderContextOptionText()}
                        </Grid>
                      </Grid>
                      {selectedUser && !isContext && renderContextHintText()}
                      {selectedUser && renderTextBox()}
                      {selectedUser && (
                        <Grid container alignItems="stretch" direction="column">
                          <div
                            style={{
                              border: "0.5px solid #bfbfbf36",
                              height: "0.10vw",
                              margin: theme.spacing(5, 0, 4, 0),
                            }}
                          ></div>
                        </Grid>
                      )}
                      {selectedUser ? renderAddAttributeTitleText() : null}
                      {selectedUser
                        ? shareFeedbackData?.length === 0
                          ? renderAddAttributeText()
                          : null
                        : null}
                      {selectedUser ? renderAddNewButton() : null}
                    </>
                  )}
                </Grid>
                <Grid container justify="center" alignItems="center">
                  {renderValidationModal()}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  {renderCancelButton()}
                  {renderSendButton()}
                </Grid>
              </Grid>
              {renderRouterPrompt()}
            </form>
          )}
        </>
      )}
      {tabValue === 1 && (
        <>
          <DraftList
            setSelectedDraftId={setSelectedDraftId}
            setSelectedUser={setSelectedUser}
            setTabValue={setTabValue}
            setShowDraftWithId={setShowDraftWithId}
            setShareFeedbackData={setShareFeedbackData}
            setAttributes={setAttributes}
            setIsContext={setIsContext}
            onDataChange={onDataChange}
          ></DraftList>
        </>
      )}
    </Grid>
    </>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <HomeLayout
      loading={false}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderToolBar={renderToolBar}
      renderSelectedOption={renderShareFeedbackLayout}
    ></HomeLayout>
  );
};
