import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { appColor } from "../../constants/customThemeColor";
import { NudgeSendNotificationProps, User } from "../../../type";
import { useDispatch } from "react-redux";
import { nudgeSendNotification } from "../../../redux/slices/NudgeSendNotificationSlice";
import { getDateInActivityFormat } from "../../../util/GetDateInActitivityFormat";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: theme.spacing(4.75),
    width: theme.spacing(13.5),
    textTransform: "none",
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    color: `${appColor.darkGray}`,
    padding: theme.spacing(1.25, 0.75),
    border: "1px solid rgba(175,175,175,0.5)",
    "&:hover": {
      backgroundColor: `${appColor.adminThemeColor}`,
      color: `${appColor.pureWhite}`,
      border: "none",
    },
  },
  btn2: {
    height: theme.spacing(4.75),
    width: theme.spacing(13.5),
    textTransform: "none",
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    border: "1px solid #7C69EC",
    color: `#7C69EC`,
    padding: theme.spacing(1.25, 0.75),
    "&:hover": {
      backgroundColor: `${appColor.adminThemeColor}`,
      color: `${appColor.pureWhite}`,
      border: "none",
    },
  },
}));

export const NudgeCard = ({
  user,
  sharedFeedbackCount,
  receivedFeedbackCount,
  nudgeSenderData,
  titles,
  setNudgeCtaSubmitted,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let diff = -1;
  const handleNudgeCta = (user: User) => {
    if (user.id) {
      setNudgeCtaSubmitted(true);
      const params: NudgeSendNotificationProps = {
        userId: user.id,
      };
      dispatch(nudgeSendNotification(params));
    }
  };

  return (
    <Grid
      xs={12}
      item
      container
      justify="center"
      alignItems="center"
      style={{ padding: "4px 6px 4px 6px", backgroundColor: "#FFFFFF" }}
    >
      <Grid
        xs={12}
        item
        container
        justify="flex-start"
        style={
          diff === 0
            ? {
                padding: "10px 8px",
                backgroundColor: "rgba(124,105,236,0.1)",
                borderRadius: "5px",
              }
            : {
                padding: "10px 8px",
                borderRadius: "5px",
              }
        }
        alignItems="center"
      >
        <Grid
          xs={6}
          item
          container
          justify="flex-start"
          alignItems="center"
          direction="row"
        >
          <Grid xs={1} item>
            <Avatar
              src={user?.imageUrl}
              alt={user?.firstName}
              style={{ height: "40px", width: "40px", marginRight: "20px" }}
            />
          </Grid>
          <Grid xs={11} item>
            <Grid xs={12} item>
              <Typography
                variant="h5"
                style={{
                  marginLeft: "25px",
                  maxWidth: "210px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                }}
              >
                <span style={{ color: "#272727" }}>
                  {user &&
                    user.firstName &&
                    user.firstName.charAt(0).toUpperCase() +
                      user.firstName.slice(1) +
                      " " +
                      (user.lastName.charAt(0).toUpperCase() +
                        user.lastName.slice(1))}
                </span>
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography
                variant="h4"
                style={{
                  marginLeft: "25px",
                  textAlign: "start",
                  fontSize: "10px",
                  marginTop: "4px",
                }}
              >
                {!nudgeSenderData?.sender?.firstName ? (
                  <>Not yet nudged</>
                ) : (
                  <>
                    {" "}
                    Nudged By:{" "}
                    {nudgeSenderData?.sender?.firstName
                      .charAt(0)
                      .toUpperCase() +
                      nudgeSenderData?.sender?.firstName.slice(1)}{" "}
                    |{" "}
                    {nudgeSenderData?.nudgeDate &&
                      getDateInActivityFormat(
                        new Date(nudgeSenderData?.nudgeDate)
                      )}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={titles[1].size} item>
          <span style={{ color: "#272727", paddingLeft: "15px" }}>
            {sharedFeedbackCount}
          </span>
        </Grid>

        <Grid xs={titles[2].size} item>
          <span style={{ color: "#272727", paddingLeft: "25px" }}>
            {receivedFeedbackCount}
          </span>
        </Grid>

        <Grid xs={2} item container justify="flex-end">
          <Button className={classes.btn} onClick={() => handleNudgeCta(user)}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Nudge
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
