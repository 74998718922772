import { Grid, Popover, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AddQuestionModal } from "../addQuestionModal/addQuestionModal";
import { useDispatch, useSelector } from "react-redux";
import { clearAddQuestionData } from "../../../../redux/slices/AddQuestionSlice";
import { SwitchButton } from "../../../components/SwitchButton";
import { UpdateAttributeSliceProps, updateAttribute } from "../../../../redux/slices/UpdateAttributeSlice";
import { State } from "../../../../type";
import { UpdateAttributeStatusModal } from "../updateAttributeStatusModal/updateAttributeStatusModal";
import { attributeListTableItemStyles } from "./helper";
import { AttributeStatus } from "../helper";

const AttributeListTableItem = ({
  attributeId,
  attributeName,
  questions,
  titles,
  status,
  setUpdatingAttribute,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const classes = attributeListTableItemStyles();
  const [anchorElMap, setAnchorElMap] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [openAddQuestionModal, setOpenAddQuestionModal] =
    useState<boolean>(false);
  const [addingQuestionForAttributeId, setAddingQuestionForAttributeId] =
    useState<number | null>(null);
  const [openConfirmStatusUpdateModal, setOpenConfirmStatusUpdateModal] = useState<boolean>(false);

  const isDisabled = status === AttributeStatus.INACTIVE;
  const history = useHistory();
  const token = useSelector((state: State) => state.auth.token);

  const showMoreOptions = (
    event: React.MouseEvent<EventTarget>,
    attributeId: number
  ) => {
    setAnchorElMap((prevAnchorElMap) => ({
      ...prevAnchorElMap,
      [attributeId]: event.currentTarget as HTMLElement,
    }));
  };

  const handleClose = (attributeId: number) => {
    setAnchorElMap((prevAnchorElMap) => ({
      ...prevAnchorElMap,
      [attributeId]: null,
    }));
  };

  const handleViewMore = (attributeId: number) => {
    history.push({
      pathname: `/admin/manage/attributes/${attributeId}`,
      state: { from: "manage_attributes", currentPage: pageNumber },
    });
  };

  const handleAddQuestionCancel = (): void => {
    resetAddQuestionModalData();
    dispatch(clearAddQuestionData());
  };

  const handleAddQuestionSuccess = (): void => {
    setOpenAddQuestionModal(false);
    if (addingQuestionForAttributeId) {
      history.push({
        pathname: `/admin/manage/attributes/${addingQuestionForAttributeId}`,
        state: {
          from: "manage_attributes",
          questionAdded: true,
        },
      });
    }
    dispatch(clearAddQuestionData());
  };

  const resetAddQuestionModalData = (): void => {
    setAddingQuestionForAttributeId(null);
    setOpenAddQuestionModal(false);
  };

  const renderAddQuestionModal = () => (
    <AddQuestionModal
      open={openAddQuestionModal}
      handleCancel={handleAddQuestionCancel}
      handleSuccess={handleAddQuestionSuccess}
      attributeId={addingQuestionForAttributeId}
    />
  );

  const handleCancelStatusUpdate = () => {
    setOpenConfirmStatusUpdateModal(false);
  }

  const handleCloseUpdateStatusModal = () => {
    setOpenConfirmStatusUpdateModal(false);
  }

  const renderUpdateStatusConfirmationModal = () => (
    <UpdateAttributeStatusModal
      open={openConfirmStatusUpdateModal}
      handleCancel={handleCancelStatusUpdate}
      handleConfirm={() => updateAttributeStatus(isDisabled ? AttributeStatus.ACTIVE : AttributeStatus.INACTIVE)}
      onClose={handleCloseUpdateStatusModal}
    />
  )

  const handleAddNewQuestion = (attributeId: number) => {
    setAddingQuestionForAttributeId(attributeId);
    setOpenAddQuestionModal(true);
  };

  const updateAttributeStatus = (updatedStatus) => {
    const params: UpdateAttributeSliceProps = {
      id: attributeId,
      attributeStatus: updatedStatus
    };
    dispatch(updateAttribute(params));
  };

  const atttributePopover = (attributeId: number) => {
    return (
      <Popover
        id={String(attributeId)}
        open={Boolean(anchorElMap[attributeId])}
        anchorEl={anchorElMap[attributeId]}
        onClose={() => handleClose(attributeId)}
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onBlur={() => handleClose(attributeId)}
        className={classes.popover}
      >
        <Grid
          key={attributeId}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.header2}
        >
          <div onClick={() => handleViewMore(attributeId)}>
            <Typography className={classes.attributeActionsPopover}>
              View More
            </Typography>
          </div>
          <div onClick={() => !isDisabled ? handleAddNewQuestion(attributeId) : {}}>
            <Typography className={`${classes.attributeActionsPopover} ${isDisabled ? classes.disabledAction : ""}`}>
              Add Question
            </Typography>
          </div>
        </Grid>
      </Popover>
    );
  };

  const handleUpdateStatus = () => {
    if (isDisabled) {
      updateAttributeStatus(AttributeStatus.ACTIVE);
    } else {
      setOpenConfirmStatusUpdateModal(true);
    }
    setUpdatingAttribute(true);
  }

  return (
    <>
      {renderUpdateStatusConfirmationModal()}
      {renderAddQuestionModal()}
      <Grid
        key={attributeId}
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={`${classes.container} ${isDisabled ? classes.disabledContainer : ""}`}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={titles[0]?.size}
        >
          <Typography className={`${classes.typography_body_2} ${isDisabled ? classes.disabledText : ""}`}>
            {attributeName}
          </Typography>
        </Grid>

        <Grid item xs={titles[1]?.size}>
          <Typography variant="h6" className={`${classes.typography_body_3} ${isDisabled ? classes.disabledText : ""}`}>
            {questions?.length}
          </Typography>
        </Grid>

        <Grid container direction="row" alignItems="center" item xs={titles[2]?.size} style={{ display: "flex" }}>
          <SwitchButton checked={!isDisabled} onChange={handleUpdateStatus} helperText={isDisabled ? "Enable attribute" : "Disable attribute"} />
          <Typography
            style={{
              color: "text.secondary",
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <MoreVert
              onClick={(event) => {
                event.stopPropagation();
                showMoreOptions(event, attributeId);
              }}
              onFocus={(event) => event.stopPropagation()}
            />
            {atttributePopover(attributeId)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AttributeListTableItem;
