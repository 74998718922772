import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../../layouts/home/Layout";
import { ToolBar } from "../../home/ToolBar";

export const Attributes = (props) => {
  document.title = "Grow-Wize | Admin-Attributes";
  window.scrollTo(0, 0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Attributes"
      setLogout={tempFunction}
      isDetailView={false}
      setIsDetailView={tempFunction}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderDashboardLayout = () => (
    <Grid xs={12} item justify="center" alignItems="center">
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "100px",
            fontFamily: "Montserrat",
            color: "#868686",
            marginTop: "200px"
          }}
        >
          Coming soon...
        </Typography>
      </Grid>
  );

  return (
    <HomeLayout
      loading={false}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderDashboardLayout}
    ></HomeLayout>
  );
};
