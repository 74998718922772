import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { DetailCardComponentProps } from "../type";
import { Avatar, Button } from "@material-ui/core";
import { getDateInDesiredFormat } from "../../../util/GetDateInDesiredFormat";
import { avatarColor } from "../../constants/avatarBackgroundColor";
import { TokenDataProps } from "../../home/Drawer";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { State } from "../../../type";

const useStyle = makeStyles((theme) => ({
  typography_body_1: {
    color: "#868686",
    textAlign: "start",
    paddingLeft: theme.spacing(2.5),
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  header: {
    padding: theme.spacing(1.75, 0, 1.75, 0),
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  small: {
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    margin: theme.spacing(0, 0, 0, 2.5),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: theme.spacing(0, 0, 0, 1),
    },
  },
  avatarMargin: {
    marginLeft: theme.spacing(4.5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  body: {
    width: "100%",
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF",
  },
  typography_body_3: {
    textAlign: "start",
    alignItems: "center",
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0, 0, 0, 2.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0, 0, 0, 1),
      marginRight: theme.spacing(1),
    },
  },
  typography_body_2: {
    textAlign: "left",
    wordBreak: "break-word",
    padding: theme.spacing(0, 0, 0, 6),
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 5.5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0, 1.5),
    },
  },
  btn: {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 0, 0),
  },
  typograph_cta: {
    fontWeight: 500,
    color: "#576BEA",
    fontStyle: "Montserrat",
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "none",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  typography_pending: {
    color: "#db5b5b",
    backgroundColor: "#ffe9e9",
  },
  typography_closed: {
    color: "#01BDB3",
    backgroundColor: "#E6FAF8",
  },
  typography_cancelled: {
    color: "#7e827e",
    backgroundColor: "#e6e0e0",
  },
  typography_status: {
    textAlign: "center",
    padding: theme.spacing(0.75),
    fontWeight: 500,
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(2.5),
    width: theme.spacing(11.25),
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(8),
      fontSize: "10px",
    },
  },
}));

export const DetailCardComponent: React.FC<DetailCardComponentProps> = ({
  user,
  date,
  documentId,
  setDocumentId,
  setUserId,
  setValue,
  attributesName,
  requestStatus,
  context,
  from,
  setCancelRequestParams,
}) => {
  const classes = useStyle();
  const token: string = useSelector((state: State) => state.auth.token);
  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    org_id: 0,
  };
  try {
    tokenData = jwt_decode(token);
  } catch (e) {
    console.log(e);
  }
  return (
    <Grid
      key={documentId}
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.header}
    >
      <Grid item container md={3} xs={6} direction="row" alignItems="center">
        {(from === "RECEIVED_FEEDBACK" || from === "SENT_REQUEST") && (
          <Grid item md={1} xs={2}>
            <Avatar
              alt={tokenData.org_id === user?.organizationId ? "E" : "C"}
              src={
                tokenData.org_id === user?.organizationId
                  ? "/EmployeeIcon.svg"
                  : "/ClientIcon.svg"
              }
              className={classes.small}
              style={{
                height: "16px",
                width: "16px",
              }}
            />
          </Grid>
        )}
        <Grid item md={1} xs={2}>
          <Avatar
            alt={user?.firstName.toUpperCase()}
            src={user?.imageUrl}
            className={classes.small}
            style={
              user?.firstName === user?.imageUrl
                ? {
                    backgroundColor:
                      avatarColor[Math.floor(Math.random() * 10)],
                    color: "#FFFFFF",
                  }
                : {}
            }
          />
        </Grid>
        <Grid item md={10} xs={8}>
          <Typography className={classes.typography_body_2}>
            {user?.firstName + " " + user?.lastName}
          </Typography>
        </Grid>
      </Grid>
      {requestStatus ? (
        <Hidden smDown>
          <Grid item md={2} xs={3}>
            <Typography variant="h6" className={classes.typography_body_3}>
              {getDateInDesiredFormat(new Date(date))}
            </Typography>
          </Grid>
        </Hidden>
      ) : (
        <Grid item md={2} xs={3}>
          <Typography variant="h6" className={classes.typography_body_3}>
            {getDateInDesiredFormat(new Date(date))}
          </Typography>
        </Grid>
      )}
      {requestStatus ? (
        <Hidden smDown>
          <Grid item md={4}>
            <>
              {context ? (
                <Typography
                  variant="h6"
                  className={classes.typography_body_3}
                  dangerouslySetInnerHTML={{
                    __html: context,
                  }}
                />
              ) : (
                <Typography variant="h6" className={classes.typography_body_3}>
                  {attributesName?.join(", ")}
                </Typography>
              )}
            </>
          </Grid>
        </Hidden>
      ) : (
        <Hidden smDown>
          <Grid item md={5}>
            <Typography variant="h6" className={classes.typography_body_3}>
              {attributesName?.join(", ")}
            </Typography>
          </Grid>
        </Hidden>
      )}
      {requestStatus && (
        <Grid justify="flex-start" alignItems="center" container item md xs={3}>
          {requestStatus === "PENDING" && (
            <Typography
              className={`${classes.typography_status} ${classes.typography_pending}`}
            >
              Pending
            </Typography>
          )}
          {requestStatus === "CLOSED" && from === "RECEIVED_REQUEST" && (
            <Typography
              className={`${classes.typography_status} ${classes.typography_closed}`}
            >
              Shared
            </Typography>
          )}
          {requestStatus === "CLOSED" && from !== "RECEIVED_REQUEST" && (
            <Typography
              className={`${classes.typography_status} ${classes.typography_closed}`}
            >
              Received
            </Typography>
          )}
          {requestStatus === "CANCELLED" && (
            <Typography
              className={`${classes.typography_status} ${classes.typography_cancelled}`}
            >
              Cancelled
            </Typography>
          )}
          {requestStatus === "EXPIRED" && (
              <Typography
                  className={`${classes.typography_status} ${classes.typography_cancelled}`}
              >
                Expired
              </Typography>
          )}
          {requestStatus === "REJECTED" && (
              <Typography
                  className={`${classes.typography_status} ${classes.typography_cancelled}`}
              >
                Declined
              </Typography>
          )}
        </Grid>
      )}
      {requestStatus ? (
        <>
          {from === "SENT_REQUEST" && (
            <Grid item md xs={3} container justify="flex-start">
              {requestStatus === "CLOSED" && (
                <Button
                  className={classes.btn}
                  onClick={() => {
                    setDocumentId(documentId);
                    if (setUserId) {
                      setUserId(user?.id);
                    }
                  }}
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <Typography className={classes.typograph_cta}>
                    View More
                  </Typography>
                </Button>
              )}
              {requestStatus === "PENDING" && (
                <Button
                  className={classes.btn}
                  onClick={() => {
                    setCancelRequestParams({
                      documentId: documentId,
                      userId: user?.id
                    })
                  }}
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <Typography className={classes.typograph_cta}>
                    Cancel
                  </Typography>
                </Button>
              )}
            </Grid>
          )}
          {from === "RECEIVED_REQUEST" && requestStatus === "CLOSED" && (
            <Grid item md xs={3} container justify="flex-start">
              <Button
                className={classes.btn}
                onClick={() => {
                  setDocumentId(documentId);
                }}
                style={{
                  marginLeft: "20px",
                }}
              >
                <Typography className={classes.typograph_cta}>
                  View More
                </Typography>
              </Button>
            </Grid>
          )}
          {from === "RECEIVED_REQUEST" && requestStatus === "PENDING" && (
            <Grid item md xs={3} container justify="flex-start">
              <Button
                className={classes.btn}
                onClick={() => {
                  setUserId(user?.id);
                  setValue(1);
                }}
                style={{
                  marginLeft: "20px",
                }}
              >
                <Typography className={classes.typograph_cta}>
                  Share Now
                </Typography>
              </Button>
            </Grid>
          )}
        </>
      ) : (
        <Grid item md={2} xs={3} container justify="flex-start">
          <Button
            className={classes.btn}
            onClick={() => {
              setDocumentId(documentId);
            }}
          >
            <Typography
              className={classes.typograph_cta}
              style={{ paddingLeft: "20px" }}
            >
              View More
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
