import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { avatarColor } from "../../../constants/avatarBackgroundColor";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { OptionType } from "../../../components/Dropdown/helper";
import { TEAM_ROLES_LIST } from "../../../../constants/teamConstants";
import { teamMemberListItemStyles } from "../helper";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RemoveMemberModal } from "./removeMemberModal/removeMemberModal";
import { UserStatus } from "../../../../enums/userStatus";

const TeamMemberListItem = ({
  user,
  titles,
  memberRole,
  onChangeUserRole,
  RemoveMemberChange,
  isTeamDetailsView
}) => { 
  const classes = teamMemberListItemStyles();
  const [selectedRoleType, setSelectedRoleType] = React.useState<OptionType | undefined>(
    memberRole === TEAM_ROLES_LIST[0].key ? TEAM_ROLES_LIST[0] : TEAM_ROLES_LIST[1]
  );

    useEffect(() => {
      setSelectedRoleType(memberRole === TEAM_ROLES_LIST[0].key ? TEAM_ROLES_LIST[0] : TEAM_ROLES_LIST[1])
    }, [memberRole])

  const { teamId }: any = useParams();
  const history = useHistory();
  const location = useLocation();
  const isDisabled = user.status === UserStatus.INACTIVE ? true : false;
  const isAdminView = location.pathname.includes("admin");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);


  const handleChange = (role: OptionType) => {
    setSelectedRoleType(role)
    onChangeUserRole({ id: user.id, role: role.key })
  };

  const handleRemoveMember = () => {
    setOpenModal(true);
  }

  const handleCancelModal = () => {
    setOpenModal(false);
  }

  const handleConfirm = () => { 
    setSubmitted(true);
    RemoveMemberChange(user.id);
    setOpenModal(false);
  }

  const handleViewMemberDetailBtn = (memberId: number) => {
    if (isAdminView) {
        history.push({
          pathname: `/admin/member/detail/${user?.id}`,
          state: { from: "teams_details", member: user, teamId },
        });
    }else{
          history.push({
            pathname: `/member/detail/${user?.id}`,
            state: { from: "teams_details", member: user, teamId },
        });
    }
  }

  return (
    <>
    <RemoveMemberModal
        open={openModal}
        handleCancel={handleCancelModal}
        handleRemove={handleConfirm}
        disableActions={submitted}
        memberName={user?.firstName}
    />
      <Grid
        key={user.id}
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={`${classes.container} ${isDisabled && !isTeamDetailsView ? classes.disabledContainer : ""}`}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={titles[0]?.size}
        >
          <Avatar
            alt={user?.firstName?.toUpperCase()}
            src={user?.imageUrl}
            className={classes.small}
            style={
              user?.firstName === user?.imageUrl
                ? {
                  backgroundColor: avatarColor[Math.floor(Math.random() * 10)],
                  color: "#FFFFFF",
                }
                : {
                }
            }
          />
          <Typography className={`${classes.typography_body_2} ${isDisabled && !isTeamDetailsView ? classes.disabledText : ""}`}>
            {user?.firstName + " " + user?.lastName}
          </Typography>

        </Grid>

        <Grid item xs={titles[1]?.size} className={`${isDisabled && !isTeamDetailsView ? classes.disabledAction : ""}`}>
          {!isTeamDetailsView ? (
            <Dropdown
              options={TEAM_ROLES_LIST}
              placeholder="Select response"
              onSelect={handleChange}
              selectedOption={selectedRoleType}
              expand={false}
              isDisabled={isDisabled}
            />
          ) : (
            <Typography className={`${classes.typography_body_2}`}>
              {selectedRoleType?.value}
            </Typography>
          )}
        </Grid>

        <Grid item xs={titles[2]?.size}>
          <Typography
            variant="h6"
            className={`${classes.typography_user_status} ${user.status == 'ACTIVE' ? classes.typography_active : classes.disabled_status}`}>
            {user.status}
          </Typography>
        </Grid>

        <Grid container direction="row" alignItems="center" item xs={titles[3]?.size} style={{ display: "flex" }}>
          {!isTeamDetailsView ?
            (<Button
              className={`${classes.btn}`}
              onClick={() => {
                handleRemoveMember()
              }}
              style={{
                marginRight: 20,
              }}
            >
              <Typography className={classes.typograph_cta}>
                Remove Member
              </Typography>
            </Button>
            ):
            (<Typography>
              <button
                style={{
                  borderRadius: "5px",
                  border: "1px solid #D7D7D7",
                  background: "#FFF",
                  display: "flex",
                  padding: "11px 32px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  color: "#868686",
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  cursor: "pointer",
                }}
                onClick={() => handleViewMemberDetailBtn(user.id)}
              >
                View
              </button>
            </Typography>)}

        </Grid>
      </Grid>
    </>
  );
};

export default TeamMemberListItem;
