import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { ReceivedFeedbackDataProps } from "../../../../../type";
import { useDispatch } from "react-redux";
import {
  clearGeneratePDFState,
} from "../../../../../redux/slices/FeedbackReportSlice";
import { generateFeedbackReport } from "../../../../../util/pdfGenerator";
import { getDateInLeaderBoardFormat } from "../../../../../util/GetDateInDesiredFormat";
import { ICollatedFeedbackData } from "../../../../../interface/feedback.interface";
import { DateFormat, formatDate } from "../../../../../util/DateUtils";
import ToastMessage, { MESSAGE_TYPE, MessageObject } from "../../../../components/ToastMessage";
import { isEmpty } from 'lodash';

const useStyle = makeStyles((theme) => ({
  exportCTA: {
    padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
    textTransform: "none",
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    backgroundColor: "#7C69EC",
    fontFamily: "Montserrat",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(6),
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5c49cc",
    }
  },
}));

export const ExportCTA = ({
  receivedFeedbackDateRangeState,
  memberName,
  receivedFeedbacksWithinSelectedRange
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [showExportSuccessToast, setShowExportSuccessToast] =
    useState<Boolean>(false);
  const [exportSuccessMsg, setExportSuccessMsg] = useState<MessageObject>();

  const handleExport = () => {
    if (isEmpty(receivedFeedbacksWithinSelectedRange)) {
      dispatch(clearGeneratePDFState());
      setShowExportSuccessToast(true);
      setExportSuccessMsg({ message: "No feedback found", type: MESSAGE_TYPE.INFO });
    }
    else {
      generateFeedbackReport(
        memberName,
        getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.startDate),
        getDateInLeaderBoardFormat(receivedFeedbackDateRangeState.endDate),
        getCollatedFeedbacks(receivedFeedbacksWithinSelectedRange)
      );
      dispatch(clearGeneratePDFState());
      setShowExportSuccessToast(true);
      setExportSuccessMsg({ message: "Report exported successfully", type: MESSAGE_TYPE.SUCCESS });
    }
  };

  const getCollatedFeedbacks = (
    receivedFeedbacks: ReceivedFeedbackDataProps[]
  ): ICollatedFeedbackData[] => {
    return receivedFeedbacks.map((attribute) => {
      const feedbacks: any = [];
      attribute.responseDetails.forEach((response) => {
        const sentBy = response.userName;
        response.details.forEach((feedback) => {
          feedbacks.push({
            sentBy,
            date: formatDate(new Date(feedback.date), DateFormat.DDMonYYYY),
            details: feedback.context,
            suggestions: feedback.suggestions,
          });
        });
      });
      return {
        attributeName: attribute.attributeName,
        feedbacks,
        suggestions: feedbacks
          .map((feedback) => ({ sentBy: feedback.sentBy, date: feedback.date, details: feedback.suggestions }))
          .filter((suggestion) => Boolean(suggestion.details)),
      };
    });
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowExportSuccessToast(false);
    setExportSuccessMsg({ message: "" });
  };

  return (
    <div data-testid="export-button-wrapper">
      <ToastMessage
        open={showExportSuccessToast}
        handleClose={handleToastClose}
        message={exportSuccessMsg?.message}
        type={exportSuccessMsg?.type}
      />
      <Button
        startIcon={<img src="/exportReport.svg" alt="export_report"></img>}
        onClick={handleExport}
        variant="outlined"
        data-testid="export-button"
        className={classes.exportCTA}
      >
        EXPORT
      </Button>
    </div>
  );
};
