import { Button, Grid, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Box from '@material-ui/core/Box';
import { useGoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { clearData, signInFailed } from "../../../redux/slices/AuthSlice";
import { signInUser } from "../../../redux/slices/AuthSlice";
import { TokenId } from "../../../type";


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginBottom: "30px",
    alignItems: "center",
  },
  signInTxt: {
    color: "#1B3C87",
    fontSize: "20px",
    marginBottom: theme.spacing(2),
  },
  sessionExpired: {
    color: "#DC0101",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  signInHeaderTxt: {
    color: "#1B3C87",
    fontSize: "20px",
    marginBottom: "25px",
  },
  signInSubHeaderTxt: {
    color: "#1B3C87",
    fontSize: "18px",
    marginBottom: theme.spacing(2),
  },
  signInBtn: {
    textAlign: "center",
    height: "55px",
    textTransform: "none",
    backgroundColor: "#576BEA",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 600,
    width: "100%",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "19px",
    "&:hover": {
      backgroundColor: "#576BEA",
    },
  },
}));

const GoogleIcon = () => {
  return (
    <Icon
      style={{
        paddingRight: "45px",
        marginRight: "-6px",
        marginTop: "10px",
        marginLeft: "0px",
        marginBottom: "10px",
        paddingBottom: "40px",
        paddingLeft: "5px",
      }}
    >
      <img src="/google-icon.svg" alt="" height={40} width={40}></img>
    </Icon>
  );
};

const ColoredLine = ({ color, text }) => (
  <div style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
    <div style={{ flex: 1, backgroundColor: color, height: "1px" }} />
    <p style={{ margin: "0 10px" }}>{text}</p>
    <div style={{ flex: 1, backgroundColor: color, height: "1px" }} />
  </div>
);

const checkIfCookiesDisabled = (response: any) : boolean => {
  return response?.details === "Cookies are not enabled in current environment.";
}

export const SignInButton = ({ setIsDispatch, setIsError, pagePath, setStatusCode, corporateEmailEntered }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    const arg: TokenId = {
      id_token: response.tokenObj.id_token,
      name: response.profileObj.name,
      imageUrl: response.profileObj.imageUrl,
      email: response.profileObj.email,
      isCorporate: false,
      otp: '',
    };
    dispatch(signInUser(arg));
  };

  const onFailure = (response : any) => {
    setIsError(true);
    setIsDispatch(false);
    const areCookiesDisabled = checkIfCookiesDisabled(response);
    if(areCookiesDisabled) {
      setStatusCode(101);
    } else if(response?.error !== "popup_closed_by_user"){
      dispatch(signInFailed());
    } 
  };

  const onRequest = () => {
    setIsDispatch(true);
  };

  const clientId = `${process.env.REACT_APP_CLIENT_ID}`;

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    onRequest,
    clientId,
  });

  return (
    <Grid lg={corporateEmailEntered ? 8 : 12} style={{ margin: "auto" }}>
      <Box>
        <Typography className={classes.signInHeaderTxt} variant="h5">Sign in to access Grow-Wize using</Typography>
        <Typography className={classes.signInSubHeaderTxt} variant="h5">
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            className={classes.signInBtn}
            onClick={() => {
              dispatch(clearData());
              signIn();
              //setCorporateLogin(false);
            }}
          >
            Google Workspace
          </Button>

          {pagePath && (
            <Grid item>
              <Typography variant="h2" className={classes.sessionExpired}>
                Your session has expired, please relogin!
              </Typography>
            </Grid>
          )}
          <ColoredLine color="grey" text="OR" />
        </Typography>
      </Box>
    </Grid>
  );
};
