import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addTeamMembersSlice from "../slices/AddTeamMembersSlice";
import allMemberListSlice from "../slices/AllMemberList";
import appraisalsSlice from "../slices/AppraisalSlice";
import attributesDataForClientSlice from "../slices/AttributesWithDetailData";
import authSlice, { sessionExpired } from "../slices/AuthSlice";
import receivedFeedbackReportSlice from "../slices/ConsolidateReportOfReceivedFeedbackSlice";
import countAssessmentSharedByMemberSlice from "../slices/CountAssessmentSharedByMemberSlice";
import countReceivedSlice from "../slices/CountReceivedFeedbacksSlice";
import countRequestReceivedByMemberSlice from "../slices/CountRequestReceivedByMemberSlice";
import countRequestSendByMemberSlice from "../slices/CountRequestSentByMemberSlice";
import countRequestReceivedSlice from "../slices/CountRequestsReceivedSlice";
import countRequestSendSlice from "../slices/CountRequestsSendSlice";
import countSharedAssessmentsSlice from "../slices/CountSharedAssessmentsSlice";
import countSharedSlice from "../slices/CountSharedFeedbackSlice";
import orgInterestSlice from "../slices/CreateNewOrgInterest";
import deactivateMemberSlice from "../slices/DeactivateMemberSlice";
import sharedDraftDeleteDataSlice from "../slices/DeleteDraftSlice";
import allReceivedRequestsByMemberSlice from "../slices/FeedbackRequestReceivedByMemberSlice";
import allReceivedRequestsSlice from "../slices/FeedbackRequestsReceivedSlice";
import allSendRequestsSlice from "../slices/FeedbackRequestsSendSlice";
import allSendRequestsByMemberSlice from "../slices/FeedbackRequestsSentByMemberSlice";
import feedbackTrendSlice from "../slices/FeedbackTrendDataSlice";
import leadersWithFeedbackCountSlice from "../slices/LeaderFeedbackSlice";
import nuggetFeedbackSlice from "../slices/NuggetShareSlice";
import countAllSharedAndReceivedSlice from "../slices/ReceivedAndSharedAllCountSlice";
import countSharedAndReceivedSlice from "../slices/ReceivedAndSharedCountSlice";
import receivedFeedbackDetailByMemberSlice from "../slices/ReceivedFeedbackDetailByMemberSlice";
import receivedFeedbackDetailSlice from "../slices/ReceivedFeedbackDetailSlice";
import receivedFeedbackListByMemberSlice from "../slices/ReceivedFeedbackListByMemberSlice";
import receivedFeedbackRequestDetailsSlice from "../slices/ReceivedFeedbackRequestDetailsSlice";
import receivedFeedbacksSlice from "../slices/ReceivedFeedbacksSlice";
import recentActivitiesSlice from "../slices/RecentActivitiesSlice";
import requestSlice from "../slices/RequestFeebackSlice";
import requestFeedbackFromExtClientSlice from "../slices/RequestFeedbackFromExtClientSlice";
import savedDraftsSlice from "../slices/SavedDraftsSlice";
import selfReportSlice from "../slices/SelfReportSlice";
import sentFeedbackRequestDetailsSlice from "../slices/SentFeedbackRequestDetailsSlice";
import shareAssessmentSlice from "../slices/ShareAssessmentSlice";
import sharedAssessmentDetailByMemberSlice from "../slices/SharedAssessmentDetailByMemberSlice";
import sharedAssessmentDetailSlice from "../slices/SharedAssessmentDetailSlice";
import sharedAssessmentListByMemberSlice from "../slices/SharedAssessmentListByMemberSlice";
import sharedAssessmentListSlice from "../slices/SharedAssessmentListSlice";
import sharedDraftDetailByClientSlice from "../slices/SharedDraftDetailForClient";
import sharedDraftDetailSlice from "../slices/SharedDraftDetailSlice";
import sharedFeedbackDetailByMemberSlice from "../slices/SharedFeedbackDetailByMemberSlice";
import sharedFeedbackDetailSlice from "../slices/SharedFeedbackDetailSlice";
import sharedFeedbackListByMemberSlice from "../slices/SharedFeedbackListByMemberSlice";
import sharedFeedbacksSlice from "../slices/SharedFeedbacksSlice";
import shareFeedbackByClientSlice from "../slices/ShareFeedbackByClient";
import shareFeedbackSlice from "../slices/ShareFeedbackSlice";
import submitFeedbackByClientSlice from "../slices/SubmitFeedbackByExternalClient";
import submitFeedbackSlice from "../slices/SubmitFeedbackSlice";
import changeMemberAccessRoleSlice from "../slices/UpdateMemberAccessRole";
import updateOrgDataSlice from "../slices/UpdateOrganizationSlice";
import updateRequestStatusSlice from "../slices/UpdateRequestStatusSlice";
import dataSlice from "../slices/UsersAndAttributesSlice";
import otpRequestSlice from "../slices/OtpRequestSlice";
import otpValidationResponse from "../slices/OtpValidationSlice";
import adminAttributeDataSlice from "../slices/attributeSlice";
import attributeDetailSlice from "../slices/AttributeDetailSlice";
import addAttributeSlice from "../slices/AddAttributeSlice";
import updateAttributeSlice from "../slices/UpdateAttributeSlice";
import addQuestionSlice from "../slices/AddQuestionSlice";
import updateQuestionSlice from "../slices/UpdateQuestionSlice";
import receivedFeedbackReportByDateRange from "../slices/FeedbackReportSlice";
import createReminderSlice from "../slices/CreateReminderSlice";
import updateReminderSlice from "../slices/UpdateReminderSlice";
import getReminderSlice from "../slices/GetReminderSlice";
import { isTokenExpired } from "../../util/handleTokenExpiration";
import adminTeamDataSlice from "../slices/teamSlice";
import teamLeadTeamDataSlice from "../slices/TeamLeadTeams";
import createTeamSlice from "../slices/CreateTeamSlice";
import teamDetailsSlice from "../slices/TeamDetailsSlice";
import editTeamSlice from "../slices/EditTeamSlice";
import adminNudgeDataSlice from "../slices/NudgeListSlice";
import adminNudgeSendNotificationSlice from "../slices/NudgeSendNotificationSlice";
import adminNudgeCountDataSlice from "../slices/NudgeCountSLice";
import coachMarkSlice from "../slices/CoachMarkSlice";
import roundRobinFeedbackSlice from "../slices/RoundRobinFeedbackSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "coachMarks"],
};

const reducer = combineReducers({
  auth: authSlice.reducer,
  countRequestsReceived: countRequestReceivedSlice.reducer,
  countRequestsSend: countRequestSendSlice.reducer,
  countShared: countSharedSlice.reducer,
  countReceived: countReceivedSlice.reducer,
  data: dataSlice.reducer,
  receivedFeedbackDetail: receivedFeedbackDetailSlice.reducer,
  receivedFeedbacks: receivedFeedbacksSlice.reducer,
  request: requestSlice.reducer,
  receivedRequests: allReceivedRequestsSlice.reducer,
  receivedRequestDetails: receivedFeedbackRequestDetailsSlice.reducer,
  sendRequests: allSendRequestsSlice.reducer,
  sentRequestDetails: sentFeedbackRequestDetailsSlice.reducer,
  sharedFeedbackDetail: sharedFeedbackDetailSlice.reducer,
  sharedFeedbacks: sharedFeedbacksSlice.reducer,
  shareFeedback: shareFeedbackSlice.reducer,
  sharedDraftDetail: sharedDraftDetailSlice.reducer,
  savedDrafts: savedDraftsSlice.reducer,
  deleteDraft: sharedDraftDeleteDataSlice.reducer,
  membersList: allMemberListSlice.reducer,
  otpResponse: otpRequestSlice.reducer,
  otpValidationResponse: otpValidationResponse.reducer,
  deactivateMember: deactivateMemberSlice.reducer,
  receivedFeedbackReport: receivedFeedbackReportSlice.reducer,
  countSharedAndReceivedFeedback: countSharedAndReceivedSlice.reducer,
  countAllSharedAndReceived: countAllSharedAndReceivedSlice.reducer,
  countRequestReceivedByMember: countRequestReceivedByMemberSlice.reducer,
  countRequestSendByMember: countRequestSendByMemberSlice.reducer,
  receivedFeedbackListByMember: receivedFeedbackListByMemberSlice.reducer,
  sharedFeedbackListByMember: sharedFeedbackListByMemberSlice.reducer,
  allReceivedRequestsByMember: allReceivedRequestsByMemberSlice.reducer,
  allSendRequestsByMember: allSendRequestsByMemberSlice.reducer,
  sharedAssessmentDetail: sharedAssessmentDetailSlice.reducer,
  shareAssessment: shareAssessmentSlice.reducer,
  sharedAssessmentList: sharedAssessmentListSlice.reducer,
  countSharedAssessments: countSharedAssessmentsSlice.reducer,
  countAssessmentSharedByMember: countAssessmentSharedByMemberSlice.reducer,
  sharedAssessmentListByMember: sharedAssessmentListByMemberSlice.reducer,
  sharedFeedbackDetailByMember: sharedFeedbackDetailByMemberSlice.reducer,
  receivedFeedbackDetailByMember: receivedFeedbackDetailByMemberSlice.reducer,
  sharedAssessmentDetailByMember: sharedAssessmentDetailByMemberSlice.reducer,
  requestFeedbackFromExtClient: requestFeedbackFromExtClientSlice.reducer,
  attributesDataForClient: attributesDataForClientSlice.reducer,
  shareFeedbackByClient: shareFeedbackByClientSlice.reducer,
  sharedDraftDetailByClient: sharedDraftDetailByClientSlice.reducer,
  submitFeedbackByClient: submitFeedbackByClientSlice.reducer,
  recentActivities: recentActivitiesSlice.reducer,
  appraisals: appraisalsSlice.reducer,
  leadersWithFeedbackCount: leadersWithFeedbackCountSlice.reducer,
  submitFeedback: submitFeedbackSlice.reducer,
  feedbackTrend: feedbackTrendSlice.reducer,
  selfReport: selfReportSlice.reducer,
  nuggetFeedback: nuggetFeedbackSlice.reducer,
  orgInterest: orgInterestSlice.reducer,
  updateRequestStatus: updateRequestStatusSlice.reducer,
  updateOrgData: updateOrgDataSlice.reducer,
  addTeamMembers: addTeamMembersSlice.reducer,
  changeMemberAccessRole: changeMemberAccessRoleSlice.reducer,
  otpRequest: otpRequestSlice.reducer,
  signInCorporate: otpValidationResponse.reducer,
  adminAttributeData: adminAttributeDataSlice.reducer,
  adminTeamData: adminTeamDataSlice.reducer,
  adminNudgeData: adminNudgeDataSlice.reducer,
  adminNudgeSendNotification: adminNudgeSendNotificationSlice.reducer,
  adminNudgeCountData: adminNudgeCountDataSlice.reducer,
  teamLeadTeamData: teamLeadTeamDataSlice.reducer,
  attributeDetail: attributeDetailSlice.reducer,
  addAttribute: addAttributeSlice.reducer,
  updateAttribute: updateAttributeSlice.reducer,
  addQuestionData: addQuestionSlice.reducer,
  updateQuestionData: updateQuestionSlice.reducer,
  generatefeedbackReportState: receivedFeedbackReportByDateRange.reducer,
  createReminderDetails: createReminderSlice.reducer,
  updateReminderDetails: updateReminderSlice.reducer,
  getReminderDetails: getReminderSlice.reducer,
  createTeamDetails: createTeamSlice.reducer,
  teamDetails: teamDetailsSlice.reducer,
  editTeamDetails: editTeamSlice.reducer,
  editTeamSlice: editTeamSlice.reducer,
  coachMarks: coachMarkSlice.reducer,
  roundRobin: roundRobinFeedbackSlice.reducer
});

const rootReducer = persistReducer(persistConfig, reducer);

const tokenExpirationMiddleware = ({ dispatch, getState }) => (next) => (action) => {

  if(action.type !== 'auth/sessionExpired') {
    const state = getState();
    const token = state.auth.token;
    const isSessionExpired = state.auth.isExpired;

    // Check token expiration before making an API request
    if (!isSessionExpired && token !== null && isTokenExpired(token)) {
      dispatch(sessionExpired());
    }
  }
  return next(action);
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  `${process.env.REACT_APP_LOGGER}` === "true" && logger,
  tokenExpirationMiddleware
].filter(Boolean);

export const configureStores = () => {
  const store = configureStore({ reducer: rootReducer, middleware });
  const persistor = persistStore(store);
  return { persistor, store };
};


export const testConfigureStores = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  const persistor = persistStore(store);
  return { persistor, store };
};
