import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { ListContainerComponent } from "../../components/list/ListContainer";
import { NoRecordComponent } from "../../components/list/noRecordPage/NoRecordPage";
import { RequestListProps } from "../../components/type";
import { UserSearchBox } from "../../components/UserSearchBoxComponent";
import { FeedbackRequestLayout } from "../../../layouts/user/feedback_request/Layout";
import { countRequestReceivedFeedback } from "../../../redux/slices/CountRequestsReceivedSlice";
import { getAllReceivedRequests } from "../../../redux/slices/FeedbackRequestsReceivedSlice";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import theme from "../../../theme";
import {
  ReceivedRequest,
  RequestingParams,
  SharedFeedbacksParams,
  State,
  User,
  UserAndAttributeSliceProps,
} from "../../../type";
import { AllText, ClosedText, PendingText } from "./feedbackRequestText";

const useStyle = makeStyles((theme) => ({
  tabs: {
    color: "#576BEA",
    width: theme.spacing(20),
    textAlign: "left",
  },
  divider: {
    width: "inherit",
    backgroundColor: "#576bea66",
  },
  tab: {
    width: "inherit",
    minWidth: theme.spacing(6),
    padding: theme.spacing(0.5, 2.5, 0, 2.5),
  },
}));

export const FeedbackRequests = (props) => {
  const { STATUS } = props.match.params;
  const pageValue = props.location.state?.pageNo;
  const usr = props.location.state?.user;
  const [value, setValue] = React.useState(-1);
  const [requestedUserId, setRequestedUserId] = React.useState(0);
  const [requestedFeedbackId, setRequestedFeedbackId] =
    React.useState<number>(0);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );
  const emptyRequestArrray: Array<ReceivedRequest> = [];

  const indexToTab = {
    0: "pending",
    1: "closed",
    2: "all",
  };

  const tabToIndex = {
    pending: 0,
    closed: 1,
    all: 2,
  };

  const indexToStatus = {
    0: "PENDING",
    1: "CLOSED",
    2: "ALL",
  };

  const [tabValue, setTabValue] = React.useState(tabToIndex[STATUS]);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.history.push(`/feedback/requests/received/${indexToTab[newValue]}`);
    setPageNumber(1);
    setTabValue(newValue);
  };
  const [user, setUser] = React.useState<User | null>(usr ? usr : null);
  const emptyUsers: Array<User> = [];
  const users =
    useSelector((state: State) => state.data.data?.users) ?? emptyUsers;

  const loadingDataAndCount = useSelector(
    (state: State) => state.data.loading || state.countRequestsReceived.loading
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.history.push(
      `/feedback/requests/received/${indexToTab[event.target.value as number]}`
    );
    setPageNumber(1);
    setTabValue(event.target.value as number);
  };

  const dispatch = useDispatch();

  const classes = useStyle();

  useEffect(() => {
    let requestParams: string = "";
    requestParams += "?offSet=" + (pageNumber - 1) * 10;
    if (user !== null) requestParams += "&requestingUserId=" + user.id;
    if (tabValue !== 2) requestParams += "&status=" + indexToStatus[tabValue];
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(getAllReceivedRequests(requestingData));
  }, [pageNumber, user, tabValue]);
  const requests =
    useSelector((state: State) => state.receivedRequests.data) ??
    emptyRequestArrray;

  const listData: Array<RequestListProps> = requests.map((r) => {
    const obj: RequestListProps = {
      user: r.requestingUser,
      createdAt: r.timeOfRequest,
      context: r.context,
      feedbackId: r.feedbackRequestId,
      feedbackRequestStatus: r.feedbackRequestStatus,
    };
    return obj;
  });

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: false,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
  }, []);

  useEffect(() => {
    let str: string = "";
    if (user) str += "?requestingUserId=" + user.id;
    let params: SharedFeedbacksParams = {
      requestingParams: str,
    };
    dispatch(countRequestReceivedFeedback(params));
  }, [user]);

  const count = useSelector(
    (state: State) => state.countRequestsReceived.data
  ) ?? {
    allRequestCount: -1,
    pendingRequestCount: -1,
    closedRequestCount: -1,
  };

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.countRequestsReceived.status !== 200
      ? state.countRequestsReceived.status
      : state.receivedRequests.status !== 200
      ? state.receivedRequests.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderNoRequest = () => (
    <NoRecordComponent setValue={setValue} from={"RECEIVED_REQUEST"} />
  );

  const renderChangePageButtons = () => (
    <>
      {tabValue === 0 && count.pendingRequestCount > 0 && (
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <ChangePageButtonComponent
            pageCount={Math.ceil(count.pendingRequestCount / 10)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          ></ChangePageButtonComponent>
        </Grid>
      )}
      {tabValue === 1 && count.closedRequestCount > 0 && (
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <ChangePageButtonComponent
            pageCount={Math.ceil(count.closedRequestCount / 10)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          ></ChangePageButtonComponent>
        </Grid>
      )}
      {tabValue === 2 && count.allRequestCount > 0 && (
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <ChangePageButtonComponent
            pageCount={Math.ceil(count.allRequestCount / 10)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          ></ChangePageButtonComponent>
        </Grid>
      )}
    </>
  );

  const renderRequestList = () => (
    <ListContainerComponent
      loading={loadingDataAndCount}
      data={listData}
      setDocumentId={setRequestedFeedbackId}
      setUserId={setRequestedUserId}
      from={"RECEIVED_REQUEST"}
      setValue={setValue}
      requestType={indexToStatus[tabValue]}
    ></ListContainerComponent>
  );

  const renderSearchBox = () => (
    <UserSearchBox
      user={user}
      setUser={setUser}
      setPageNumber={setPageNumber}
      users={users}
    ></UserSearchBox>
  );

  const renderTabLabels = () => (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Hidden smDown>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab
            classes={{ root: classes.tab }}
            label={
              <PendingText count={count.pendingRequestCount}></PendingText>
            }
          />
          <Tab
            classes={{ root: classes.tab }}
            label={<ClosedText count={count.closedRequestCount}></ClosedText>}
          />
          <Tab
            classes={{ root: classes.tab }}
            label={<AllText count={count.allRequestCount}></AllText>}
          />
        </Tabs>
        <Divider className={classes.divider} />
      </Hidden>
      {
        <Hidden mdUp>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <MenuItem value={0}>
              <PendingText count={count.pendingRequestCount}></PendingText>
            </MenuItem>
            <MenuItem value={1}>
              <ClosedText count={count.closedRequestCount}></ClosedText>
            </MenuItem>
            <MenuItem value={2}>
              <AllText count={count.allRequestCount}></AllText>
            </MenuItem>
          </Select>
        </Hidden>
      }
    </Grid>
  );

  if (
    props.location.pathname !== "/feedback/requests/received/all" &&
    props.location.pathname !== "/feedback/requests/received/pending" &&
    props.location.pathname !== "/feedback/requests/received/closed"
  )
    return <Redirect to="/error"></Redirect>;

  if (STATUS === ":STATUS") {
    return (
      <Redirect to={`/feedback/requests/received/${indexToTab[tabValue]}`} />
    );
  }

  if (value === 1) {
    return <Redirect to={`/feedback/share/${requestedUserId}`} />;
  }

  if (requestedFeedbackId !== 0) {
    history.replace({
      pathname: `/feedback/requests/received/${indexToTab[tabValue]}`,
      state: {
        pageNo: pageNumber,
        user: user,
      },
    });
    history.push({
      pathname: `/feedback/requests/received/detail/${requestedFeedbackId}`,
      state: {
        tabName: indexToTab[tabValue],
        pageNo: pageNumber,
        user: user,
      },
    });
  }

  return (
    <FeedbackRequestLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      tabValue={tabValue}
      loading={loadingDataAndCount}
      count={count}
      selectedUser={user}
      renderErrorModal={renderErrorModal}
      renderTabLabels={renderTabLabels}
      renderSearchBox={renderSearchBox}
      renderNoRequest={renderNoRequest}
      renderRequestList={renderRequestList}
      renderChangePageButtons={renderChangePageButtons}
    ></FeedbackRequestLayout>
  );
};
