import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CountCardProps } from "../type";

const useStyles = makeStyles((theme) => ({
  commonStyle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  cardName: {
    fontSize: theme.spacing(1.75),
    margin: "16px 43px 16px 16px",
  },
  cardCount: {
    color: "#282828",
    textAlign: "right",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "40px",
  },
}));

export const FeedbackReportCountCard: React.FC<CountCardProps> = ({
  cardName,
  cardCount,
}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Typography className={`${classes.commonStyle} ${classes.cardName}`}>
        {cardName}
      </Typography>
      <Typography className={`${classes.commonStyle} ${classes.cardCount}`}>
        {cardCount < 10 && cardCount > 0 ? `0${cardCount}` : `${cardCount}`}
      </Typography>
    </Grid>
  );
};
