import {
  createStyles,
  InputBase,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import theme from "../../theme";
import { CustomSelectProp } from "./type";

enum TEXT_ALIGN_PROPERTY {
  left = "left",
  right = "right",
}

const BootstrapInputEmployee = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #a0a0a0",
      fontSize: 14,
      padding: theme.spacing(1, 0, 0.75, 1.5),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: "Montserrat",
    },
  })
)(InputBase);

const BootstrapInputAdmin = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      fontSize: 10,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: "Montserrat",
    },
  })
)(InputBase);

export const LIST_ITEMS = {
  RECEIVED_FEEDBACK: ["All", "Employees", "Clients"],
  SENT_REQUEST: ["All", "Employees", "Clients"],
  ACTIVITY_LIST: ["All", "Shared", "Requested", "On boarded", "Self assessment"],
  FEEDBACK_TYPE_LIST: ["Shared", "Received"],
  TIME_UNIT: ["Day", "Month"],
  MONTH_DURATION_LIST: ["30 days", "60 days", "90 days"],
};

const EMPLOYEE_STYLE_ARRAY = ["RECEIVED_FEEDBACK", "SENT_REQUEST"];

const EMPLOYEE_STYLE_OBJ = {
  width: theme.spacing(18),
  textAlign: TEXT_ALIGN_PROPERTY.left,
  margin: theme.spacing(0),
  fontWeight: 500,
  fontFamily: "Montserrat",
  color: "#272727",
};

const ADMIN_STYLE_OBJ = {
  width: theme.spacing(14),
  textAlign: TEXT_ALIGN_PROPERTY.right,
  margin: theme.spacing(0),
  fontWeight: 500,
  fontFamily: "Montserrat",
  color: "#272727",
};

export const CustomSelect: React.FC<CustomSelectProp> = ({
  tabValue,
  setTabValue,
  setPageNumber,
  name,
}) => {
  const items = LIST_ITEMS[name];

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (EMPLOYEE_STYLE_ARRAY.includes(name)) setPageNumber(1);
    if (name === "MONTH_DURATION_LIST")
      setTabValue(((event.target.value as number) + 1) * 30);
    else setTabValue(event.target.value as number);
  };
  return (
    <Select
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      value={tabValue}
      onChange={handleChange}
      input={
        EMPLOYEE_STYLE_ARRAY.includes(name) ? (
          <BootstrapInputEmployee />
        ) : (
          <BootstrapInputAdmin />
        )
      }
      style={
        EMPLOYEE_STYLE_ARRAY.includes(name)
          ? EMPLOYEE_STYLE_OBJ
          : ADMIN_STYLE_OBJ
      }
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
    >
      {items.map((item, index) => (
        <MenuItem
          key={index}
          value={index}
          style={
            EMPLOYEE_STYLE_ARRAY.includes(name)
              ? { fontSize: "14px", fontWeight: 500 }
              : { fontSize: "10px", fontWeight: 500 }
          }
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
