import { makeStyles } from "@material-ui/core";

export const attributeListTableItemStyles = makeStyles((theme) => ({
    typography_body_1: {
        color: "#868686",
        textAlign: "start",
        paddingLeft: theme.spacing(2.5),
        fontSize: "14px",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    },
    container: {
        padding: "14px 44px",
        height: theme.spacing(11),
        borderTop: theme.spacing(0.005),
        borderColor: "#97979724",
        borderLeft: theme.spacing(0),
        borderRight: theme.spacing(0),
        borderBottom: theme.spacing(0),
        borderStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 0, 1, 0),
        },
    },
    small: {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
        margin: theme.spacing(0, 0, 0, 2.5),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            margin: theme.spacing(0, 0, 0, 1),
        },
    },
    avatarMargin: {
        marginLeft: theme.spacing(4.5),
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(0.5),
        },
    },
    body: {
        width: "100%",
        color: "#FFFFFF",
        backgroundColor: "#FFFFFF",
    },
    typography_body_3: {
        textAlign: "start",
        alignItems: "center",
        fontSize: theme.spacing(1.75),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
            padding: theme.spacing(0, 0, 0, 1),
            marginRight: theme.spacing(1),
        },
    },
    typography_body_2: {
        textAlign: "left",
        wordBreak: "break-word",
        fontWeight: 500,
        fontSize: theme.spacing(1.75),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 0, 0, 0),
            fontSize: theme.spacing(1.25),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 0, 0, 1.5),
        },
    },
    btn: {
        padding: theme.spacing(0, 0, 0, 0),
        margin: theme.spacing(0, 0, 0, 0),
    },
    typograph_cta: {
        fontWeight: 500,
        color: "#576BEA",
        fontStyle: "Montserrat",
        textAlign: "left",
        textDecoration: "underline",
        textTransform: "none",
        fontSize: theme.spacing(1.75),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
        },
    },
    typography_pending: {
        color: "#db5b5b",
        backgroundColor: "#ffe9e9",
    },
    typography_closed: {
        color: "#01BDB3",
        backgroundColor: "#E6FAF8",
    },
    typography_cancelled: {
        color: "#7e827e",
        backgroundColor: "#e6e0e0",
    },
    typography_status: {
        textAlign: "center",
        padding: theme.spacing(0.75),
        fontWeight: 500,
        borderRadius: theme.spacing(0.5),
        marginLeft: theme.spacing(2.5),
        width: theme.spacing(11.25),
        fontSize: "14px",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(8),
            fontSize: "10px",
        },
    },
    attributeActionsPopover: {
        padding: theme.spacing(1),
        fontWeight: 500,
        cursor: "pointer",
    },
    header2: {
        padding: theme.spacing(1),
    },
    popover: {
        boxShadow: "-5px 9px 19px 0px #5A5A5A66 !important",
    },
    disabledAction: {
        pointerEvents: "none",
        opacity: 0.5
    },
    disabledContainer: {
        background: "#F4F4F4"
    },
    disabledText: {
        color: "#979797"
    },  
}));

export const attributeListTableStyles = makeStyles((theme) => ({
    root: {
        width: "inherit",
    },
    row: {
        width: "100%",
        borderTop: theme.spacing(0.005),
        borderColor: "#97979724",
        borderLeft: theme.spacing(0),
        borderRight: theme.spacing(0),
        borderBottom: theme.spacing(0),
        borderStyle: "solid",
    },
    header: {
        padding: "14px 44px",
        backgroundColor: "rgba(87,107,234,0.05)",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 0, 1, 0),
        },
    },
    typography_body: {
        color: "#868686",
        fontSize: "14px",
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
            padding: theme.spacing(0, 0, 0, 1),
        },
    },
    typography_body_1: {
        color: "#868686",
        textAlign: "start",
        paddingLeft: theme.spacing(2.5),
        fontSize: "14px",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    }
}));

export type TitleProps = {
    name: string;
    size: | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | "auto"
    | boolean
    | undefined;
}

export const TITLES: Array<TitleProps> = [
    {
        name: "Attribute Name",
        size: 6,
    },
    {
        name: "Number of Questions",
        size: 5,
    },
    {
        name: "Action",
        size: 1,
    },
];
