import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { PrivacyPolicyLayoutProps } from "./type";

const drawerWidth = 0;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "inherit",
    },
    mainSection: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        margin: theme.spacing(4, 18.75, 1, 18.75),
      },
    },
  })
);

export const PrivacyPolicyLayout: React.FC<PrivacyPolicyLayoutProps> = ({
  renderPrivacyText,
  renderPrivacyHeader,
  renderGrowWizeLogo,
}) => {
  const classes = useStyles();
  window.scrollTo(0, 0);
  return (
    <Grid xs={12} container direction="row" className={classes.root}>
      <div className={classes.mainSection}>
        {renderGrowWizeLogo()}
        {renderPrivacyHeader()}
        {renderPrivacyText()}
      </div>
    </Grid>
  );
};
