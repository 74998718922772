import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./extra_fonts/landingFont.css";

export const useStyle = makeStyles((theme) => ({
  headerSection: {
    position: "fixed",
    height: theme.spacing(12.5),
    top: theme.spacing(0),
    boxShadow: "0 2px 10px 0 rgba(175,175,175,0.3)",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 10),
      height: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 6),
    },
  },
  logo: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
  },
  logoName: {
    fontSize: theme.spacing(2),
    fontWeight: 800,
    fontFamily: "Nunito",
    color: "#21395E",
    marginLeft: theme.spacing(0.85),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      marginLeft: theme.spacing(0.35),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1),
    }
  },
  get_started_btn: {
    textTransform: "none",
    width: theme.spacing(19.75),
    height: theme.spacing(6.25),
    borderRadius: theme.spacing(0.5),
    color: "#FFFFFF",
    backgroundColor: "#576BEA",
    padding: theme.spacing(1.25, 1.5),
    marginLeft: theme.spacing(4.5),
    fontFamily: "EuclideCircularAMedium",
    fontSize: theme.spacing(2.25),
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(12.5),
      height: theme.spacing(4),
      borderRadius: theme.spacing(1.5),
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0.75, 1.25),
      marginLeft: theme.spacing(2.25),
    },
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(10),
      height: theme.spacing(3),
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(1),
    }
  },
  _btn: {
    textTransform: "none",
    color: "#012241",
    backgroundColor: "transparent",
    marginLeft: theme.spacing(4.5),
    fontFamily: "EuclideCircularARegular",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    padding: theme.spacing(0),
    "&:hover": {
      backgroundColor: "#E7E7E7",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      marginLeft: theme.spacing(2.25),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(1),
      minWidth: theme.spacing(0)
    }
  },
}));

interface Props {}

export const MainHeader: React.FC<Props> = ({}) => {
  const classes = useStyle();

  return (
    <div className="scroll-to-top" style={{ zIndex: 10 }}>
      {
        <Grid
          xs={12}
          item
          container
          alignItems="center"
          className={classes.headerSection}
        >
          <Grid
            xs={3}
            lg={6}
            item
            container
            justify="flex-start"
            alignItems="center"
          >
            <img src="/LogoWeb1.svg" alt="GW" className={classes.logo} />
            <Typography className={classes.logoName}>Grow-Wize</Typography>
          </Grid>
          <Grid
            xs={9}
            lg={6}
            item
            container
            justify="flex-end"
            alignItems="center"
          >
            <Button className={classes._btn}>About</Button>
            <Button className={classes._btn}>Request Demo</Button>
            <Button className={classes.get_started_btn}>{`Get started >`}</Button>
          </Grid>
        </Grid>
      }
    </div>
  );
};
