import { Avatar, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { MemberChipProps } from "./type";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(3.75, 0, 5, 3),
    backgroundColor: "#FFFFFF",
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    border: "1px solid rgba(87,107,234,0.5)",
  },
  small: {
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
  },
  typography_body_3: {
    textAlign: "center",
    color: "#272727",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const MemberChip: React.FC<MemberChipProps> = ({user}) => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Chip
        variant="outlined"
        avatar={
          <Avatar
            alt={user?.firstName}
            src={user?.imageUrl}
            className={classes.small}
          />
        }
        label={
          <Typography className={classes.typography_body_3}>
            {user?.firstName + " " + user?.lastName}
          </Typography>
        }
        className={classes.chip}
      />
    </Grid>
  );
};
