import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShareAssessmentProps } from "../../modules/user/self_assessment/type";
import { post } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const shareAssessment = createAsyncThunk(
  "shareAssessment/sent",
  async (requestData: ShareAssessmentProps, storeActions) => {
    const url = `api/v1/user/assessments`;
    const response: any = await post(url, requestData, storeActions);

    if (response.status === HttpStatus.CREATED) {
      return {
        status: HttpStatus.CREATED,
      };
    }
    return {
      status: response.status,
    };
  }
);

const shareAssessmentSlice = createSlice({
  name: "shareAssessment",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      shareAssessment.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(shareAssessment.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(shareAssessment.rejected, (state) => {
      state.status = 503;
      state.loading = false;
    });
  },
});

export const { clearData } = shareAssessmentSlice.actions;

export default shareAssessmentSlice;
