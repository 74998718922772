import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export const AppLogoComponent: React.FC = () => {
  const classes = useStyle();
  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/LogoWeb1.svg"
          alt="GW"
          style={{ height: "56px", width: "56px", marginRight: "8px" }}
        />
        <Typography
          style={{
            fontFamily: "Nunito",
            fontWeight: 800,
            fontSize: "16px",
            color: "#21395E",
          }}
        >
          Grow-Wize
        </Typography>
      </Grid>
    </Grid>
  );
};
