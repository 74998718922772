import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangePageButtonComponent } from "../../../components/changePageButtons";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { MemberDetailsLayout } from "../../../../layouts/admin/members/details/MemberDetailsLayout";
import { countAssessmentSharedByMember } from "../../../../redux/slices/CountAssessmentSharedByMemberSlice";
import { countFeedbackRequestReceivedByMember } from "../../../../redux/slices/CountRequestReceivedByMemberSlice";
import { countRequestSendByMember } from "../../../../redux/slices/CountRequestSentByMemberSlice";
import { getAllReceivedRequestsByMember } from "../../../../redux/slices/FeedbackRequestReceivedByMemberSlice";
import { getAllSendRequestsByMember } from "../../../../redux/slices/FeedbackRequestsSentByMemberSlice";
import { getAllCountSharedAndReceivedFeedback } from "../../../../redux/slices/ReceivedAndSharedAllCountSlice";
import { getCountSharedAndReceivedFeedback } from "../../../../redux/slices/ReceivedAndSharedCountSlice";
import { receivedFeedbackListByMember } from "../../../../redux/slices/ReceivedFeedbackListByMemberSlice";
import { getSharedAssessmentListByMember } from "../../../../redux/slices/SharedAssessmentListByMemberSlice";
import { getSharedFeedbackListByMember } from "../../../../redux/slices/SharedFeedbackListByMemberSlice";
import {
  CountProps,
  Counts,
  DeactivateMemberProp,
  FeedbackListSliceProps,
  FeedbackRequestListSliceProps,
  State,
} from "../../../../type";
import { DetailSectionView } from "./DetailSection";
import { ListHeaderWithContent } from "./List";
import { TabsWithReportCTA } from "./tabsAndReportBar";

export const MemberDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const member = props.location.state?.member;
  const pageNo = props.location.state?.pageNumber;
  const tabNo = props.location.state?.tabValue;
  const itemNo = props.location.state?.itemValue;
  const from = props.location.state?.from;
  const teamId = props.location.state?.teamId;
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageNo ? pageNo : 1
  );
  const [tabValue, setTabValue] = React.useState<number>(tabNo ? tabNo : 0);
  const [itemValue, setItemValue] = React.useState<number>(itemNo ? itemNo : 1);
  const [open, setOpen] = React.useState<Boolean>(false);

  const [feedbackOrAssessmentId, setFeedbackOrAssessmentId] =
    React.useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    const arg: DeactivateMemberProp = {
      memberId: id,
      tag: itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
    };
    dispatch(getAllCountSharedAndReceivedFeedback(arg));
    dispatch(countFeedbackRequestReceivedByMember(arg));
    dispatch(countRequestSendByMember(arg));
    dispatch(countAssessmentSharedByMember(arg));
  }, []);

  useEffect(() => {
    const arg: DeactivateMemberProp = {
      memberId: id,
      tag: itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
    };
    if (tabValue === 0) {
      dispatch(getCountSharedAndReceivedFeedback(arg));
    } else if (tabValue === 3) {
      dispatch(countRequestSendByMember(arg));
    }
  }, [itemValue]);

  useEffect(() => {
    if (tabValue === 0) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        tag: "ANY",
      };
      dispatch(receivedFeedbackListByMember(arg));
    } else if (tabValue === 1) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
      };
      dispatch(getSharedFeedbackListByMember(arg));
    } else if (tabValue === 2) {
      const arg: FeedbackRequestListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        status: "PENDING",
        tag: "ANY",
      };
      dispatch(getAllReceivedRequestsByMember(arg));
    } else if (tabValue === 3) {
      const arg: FeedbackRequestListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        status: "PENDING",
        tag:
          itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
      };
      dispatch(getAllSendRequestsByMember(arg));
    } else if (tabValue === 4) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
      };
      dispatch(getSharedAssessmentListByMember(arg));
    }
  }, [tabValue]);

  useEffect(() => {
    if (tabValue === 0) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        tag:
          itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
      };
      dispatch(receivedFeedbackListByMember(arg));
    } else if (tabValue === 1) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
      };
      dispatch(getSharedFeedbackListByMember(arg));
    } else if (tabValue === 2) {
      const arg: FeedbackRequestListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        status: "PENDING",
        tag: "ANY",
      };
      dispatch(getAllReceivedRequestsByMember(arg));
    } else if (tabValue === 3) {
      const arg: FeedbackRequestListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
        status: "PENDING",
        tag:
          itemValue === 1 ? "ANY" : itemValue === 2 ? "INTERNAL" : "EXTERNAL",
      };
      dispatch(getAllSendRequestsByMember(arg));
    } else if (tabValue === 4) {
      const arg: FeedbackListSliceProps = {
        memberId: id,
        offSet: (pageNumber - 1) * 5,
        count: 5,
      };
      dispatch(getSharedAssessmentListByMember(arg));
    }
  }, [pageNumber, itemValue]);

  const counts: CountProps = useSelector(
    (state: State) => state.countSharedAndReceivedFeedback?.data
  );
  const allCounts: CountProps = useSelector(
    (state: State) => state.countAllSharedAndReceived?.data
  );

  const requestReceivedCount: Counts | null = useSelector(
    (state: State) => state.countRequestReceivedByMember.data
  );

  const requestSentCount: number | null = useSelector(
    (state: State) => state.countRequestSendByMember.data
  );

  const countAssessments: number | null = useSelector(
    (state: State) => state.countAssessmentSharedByMember.data
  );
  const statusCode: number | null = useSelector((state: State) =>
    state.countSharedAndReceivedFeedback.status !== 200
      ? state.countSharedAndReceivedFeedback.status
      : state.countAllSharedAndReceived.status !== 200
      ? state.countAllSharedAndReceived.status
      : state.countRequestReceivedByMember.status !== 200
      ? state.countRequestReceivedByMember.status
      : state.countRequestSendByMember.status !== 200
      ? state.countRequestSendByMember.status
      : state.receivedFeedbackListByMember.status !== 200
      ? state.receivedFeedbackListByMember.status
      : state.sharedFeedbackListByMember.status !== 200
      ? state.sharedFeedbackListByMember.status
      : state.allReceivedRequestsByMember.status !== 200
      ? state.allReceivedRequestsByMember.status
      : state.allSendRequestsByMember.status !== 200
      ? state.allSendRequestsByMember.status
      : state.countAssessmentSharedByMember.status !== 200
      ? state.countAssessmentSharedByMember.status
      : state.sharedAssessmentListByMember.status !== 200
      ? state.sharedAssessmentListByMember.status
      : state.changeMemberAccessRole.status !== 200
      ? state.changeMemberAccessRole.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderDetailSection = () => (
    <DetailSectionView
      firstName={member?.firstName}
      lastName={member?.lastName}
      imageUrl={member?.imageUrl}
      status={member?.status}
      id={member?.id}
      joiningDate={member?.joiningDate}
      emailId={member?.emailId}
      user_role={member?.userType}
    ></DetailSectionView>
  );

  const renderTabsWithReportCTA = () => (
    <TabsWithReportCTA
      tabValue={tabValue}
      setTabValue={setTabValue}
      setOpen={setOpen}
      setPageNumber={setPageNumber}
      setItemValue={setItemValue}
      member={member}
    />
  );

  const renderList = () => (
    <ListHeaderWithContent
      tabValue={tabValue}
      setFeedbackOrAssessmentId={setFeedbackOrAssessmentId}
      itemValue={itemValue}
      setItemValue={setItemValue}
      setPageNumber={setPageNumber}
    />
  );

  const renderChangePageComponent = () => (
    <Grid xs={12} item container justify="flex-end" alignItems="center">
      {tabValue === 0 && counts?.receivedCount > 0 && (
        <ChangePageButtonComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={Math.ceil(counts?.receivedCount / 5)}
          userRole="ADMIN"
        />
      )}
      {tabValue === 1 && allCounts?.sharedCount > 0 && (
        <ChangePageButtonComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={Math.ceil(allCounts?.sharedCount / 5)}
          userRole="ADMIN"
        />
      )}
      {tabValue === 2 &&
        requestReceivedCount &&
        requestReceivedCount?.pendingRequestCount > 0 && (
          <ChangePageButtonComponent
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={Math.ceil(requestReceivedCount?.pendingRequestCount / 5)}
            userRole="ADMIN"
          />
        )}
      {tabValue === 3 && requestSentCount && requestSentCount > 0 && (
        <ChangePageButtonComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={Math.ceil(requestSentCount / 5)}
          userRole="ADMIN"
        />
      )}
      {tabValue === 4 && countAssessments && countAssessments > 0 && (
        <ChangePageButtonComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={Math.ceil(countAssessments / 5)}
          userRole="ADMIN"
        />
      )}
    </Grid>
  );

  if (!isDetailView) {
    if (from === "dashboard") {
      history.push("/admin/dashboard");
    } else if (from === "team_details") {
      history.push(`/admin/teams/details/${teamId}`);
    } else {
      history.push("/admin/members");
    }
  }

  if (open) {
    history.push({
      pathname: `/admin/members/report/feedback/received/${id}`,
      state: {
        memberName: `${member.firstName} ${member.lastName}`,
        member: member,
      },
    });
  }

  if (feedbackOrAssessmentId > 0) {
    history.replace({
      pathname: `/admin/member/detail/${id}`,
      state: {
        member: member,
        tabValue: tabValue,
        pageNumber: pageNumber,
        itemValue: itemValue,
      },
    });
    history.push({
      pathname: `/admin/detail/view`,
      state: {
        feedbackOrAssessmentId: feedbackOrAssessmentId,
        memberId: id,
        tabValue: tabValue,
        member: member,
        pageNumber: pageNumber,
        itemValue: itemValue,
      },
    });
  }

  return (
    <MemberDetailsLayout
      props={props}
      memberName={`${member?.firstName} ${member?.lastName}`}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      loading={false}
      setLogout={setLogout}
      renderErrorModal={renderErrorModal}
      renderDetailSection={renderDetailSection}
      renderTabsWithReport={renderTabsWithReportCTA}
      renderList={renderList}
      renderChangePageComponent={renderChangePageComponent}
    ></MemberDetailsLayout>
  );
};
