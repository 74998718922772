import React from "react";
import { useSelector } from "react-redux";
import { ReceivedFeedbackDataProps, State } from "../../../../type";
import { ContentProp } from "../type";
import { ReportAttributeDetailComponent } from "../../../components/ReportAttributeDetail/ReportAttributeDetailComponent";

export const AttributeContent: React.FC<ContentProp> = ({ attributeIndex }) => {
  const emptyList: Array<ReceivedFeedbackDataProps> = [];
  const reportData: Array<ReceivedFeedbackDataProps> =
    useSelector((state: State) => state.receivedFeedbackReport.data) ??
    emptyList;
  return (
    <ReportAttributeDetailComponent reportData={reportData} attributeIndex={attributeIndex} />
  );
};
