import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  NoRecordSubText,
  NoRecordMainText,
  ActionCTAText,
} from "./NoRecordText";
import { NoRecordProps } from "./type";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-6),
    minHeight: `${window.innerHeight - 85}px`,
    backgroundColor: "#F5F6FA",
  },
  btn: {
    margin: theme.spacing(4, 0, 0, 0),
    backgroundColor: "#576BEA",
  },
  img: {
    padding: theme.spacing(6.5, 0, 6.5, 0),
    height: theme.spacing(47),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      height: theme.spacing(26.8),
    },
  },
}));

export const NoRecordComponent: React.FC<NoRecordProps> = ({
  setValue,
  from,
}) => {
  const classes = useStyle();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12}>
        <NoRecordMainText from={from} />
      </Grid>
      <Grid item>
        <img
          src="/NoFeedbackRequest.svg"
          alt="No_Feedback_Request"
          className={classes.img}
        ></img>
      </Grid>
      <Grid item xs={12}>
        <NoRecordSubText from={from} />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (from === "RECEIVED_FEEDBACK") setValue(2);
            else setValue(1);
          }}
          className={classes.btn}
        >
          <ActionCTAText from={from} />
        </Button>
      </Grid>
    </Grid>
  );
};
