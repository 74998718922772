import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../../../components/CommonFunctionalityComponent";
import { DetailPage } from "../../../../components/list/detail/DetailPage";
import { RequestFeedbackDetailsLayout } from "../../../../../layouts/user/request_feedback/detail/Layout";
import { sentFeedbackRequestDetails } from "../../../../../redux/slices/SentFeedbackRequestDetailsSlice";
import { SentFeedbackRequestDetailAPICallProps, State } from "../../../../../type";
import { FeedbackRequestDetailData } from "../../typeRequestFeedback";

export const RequestFeedbackDetails = (props) => {
  const { id } = props.match.params;
  const { uid } = props.match.params;
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState<Boolean>(false);

  useEffect(() => {
    const params: SentFeedbackRequestDetailAPICallProps = {
      feedbackRequestId: id,
      responderId: uid,
    };
    dispatch(sentFeedbackRequestDetails(params));
  }, [id, uid]);

  const data: FeedbackRequestDetailData =
    useSelector((state: State) => state.sentRequestDetails.data) ?? null;
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);

  const history = useHistory();

  const statusCode: number | null = useSelector((state: State) =>
    state.sentRequestDetails.status !== 200
      ? state.sentRequestDetails.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderDetails = () => (
    <DetailPage
      user={data?.user}
      date={data?.createdAt}
      context={data?.context}
      attributes={data?.requestedAttributes}
      from={"SENT_REQUEST"}
      status={data?.status}
    />
  );
  if (!isDetailView) {
    history.push({
      pathname: "/feedback/requests/sent",
      state: {
        pageNo: props.location.state?.pageNo,
        user: props.location.state?.user,
        filterNo: props.location.state?.filterNo,
      },
    });
  }

  return (
    <RequestFeedbackDetailsLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderDetails={renderDetails}
    ></RequestFeedbackDetailsLayout>
  );
};
