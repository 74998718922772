import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { PageNotFound } from "../modules/components/ErrorPages/PageNotFound";
import { AdminDashboard } from "../modules/admin/dashboard/Dashboard";
import { AllFeedback } from "../modules/admin/feedback/Feedback";
import { FeedbackAssessmentDetail } from "../modules/admin/members/details/detail/FeedbackAssessmentDetail";
import { MemberDetails } from "../modules/admin/members/details/MemberDetails";
import { Members } from "../modules/admin/members/Members";
import { ReceivedFeedbackReport } from "../modules/admin/members/reports/ReceivedFeedbackReport";
import { Dashboard } from "../modules/user/dashboard/Dashboard";
import { ShareFeedbackByExternalClient } from "../modules/client/share_feedback/ShareFeedbackByExternalClient";
import { FeedbackRequestDetails } from "../modules/user/feedback_requests/detail/FeedbackRequestDetails";
import { FeedbackRequests } from "../modules/user/feedback_requests/FeedbackRequests";
import { ReceivedFeedbackDetail } from "../modules/user/received_feedback/detail/ReceivedFeedbackDetail";
import { ReceivedFeedbacks } from "../modules/user/received_feedback/ReceivedFeedbacks";
import { RequestFeedback } from "../modules/user/request_feedback";
import { RequestFeedbackDetails } from "../modules/user/request_feedback/request_list/detail/RequestFeedbackDetails";
import { SelfAssessmentDetail } from "../modules/user/self_assessment/detail/SelfAssessmentDetail";
import { SelfAssessment } from "../modules/user/self_assessment/SelfAssessment";
import { SelfReport } from "../modules/user/self_report/SelfReport";
import { SharedFeedbackDetail } from "../modules/user/shared_feedback/detail/SharedFeedbackDetail";
import { SharedFeedbacks } from "../modules/user/shared_feedback/SharedFeedbacks";
import { ShareFeedback } from "../modules/user/share_feedback/ShareFeedback";
import { MainSection } from "../modules/onboarding/landing_page/MainSection";
import { WorkspaceSetup } from "../modules/onboarding/workspace/WorkspaceSetup";
import { PrivacyPolicy } from "../modules/onboarding/privacy_policy/PrivacyPolicyText";
import { Attributes } from "../modules/admin/attributes/Attributes";
import { CustomAttributeAndQuestions } from "../modules/admin/customAttributeAndQuestions/customAttributeAndQuestions";
import { AttributeDetail } from "../modules/admin/customAttributeAndQuestions/attributeDetail/attributeDetail";
import { State } from "../type";
import { useSelector } from "react-redux";
import { Settings } from "../modules/admin/settings/Settings";
import { CreateEditTeam } from "../modules/admin/teams/createTeams/CreateEditTeam";
import { Teams } from "../modules/admin/teams/Teams";
import { TeamDetails } from "../modules/admin/teams/details/TeamDetails";
import { TeamLeadTeams } from "../modules/user/teams/TeamLeadTeams";
import { TeamLeadTeamDetails } from "../modules/user/teams/TeamLeadTeamDetails";
import { TeamLeadMemberDetails } from "../modules/user/teams/TeamLeadMemberDetails";
import { NudgeBoardPage } from "../modules/admin/nudge/NudgeBoardPage";
import Website from "../Website";
import {SignIn} from "../modules/onboarding/sign_in";

export function createRoutes(token) {
  const isAuthenticated: boolean = !!token;
  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute
            exact
            path="/home"
            component={Dashboard}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/landing"
            component={MainSection}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/share/:id"
            component={ShareFeedback}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/shared"
            component={SharedFeedbacks}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/shared/detail/:id"
            component={SharedFeedbackDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/received"
            component={ReceivedFeedbacks}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/received/detail/:id"
            component={ReceivedFeedbackDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/requests/received/:STATUS"
            component={FeedbackRequests}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/requests/received/detail/:id"
            component={FeedbackRequestDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/requests/:STATUS"
            component={RequestFeedback}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/feedback/request/sent/detail/:id/:uid"
            component={RequestFeedbackDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/home/self-assessment"
            component={SelfAssessment}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/home/self-assessment/:id"
            component={SelfAssessmentDetail}
            isAuthenticated={isAuthenticated}
          />
           <PrivateRoute
            exact
            path="/home/self-assessment/edit/:id"
            component={SelfAssessment}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/report"
            component={SelfReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/teams"
            component={TeamLeadTeams}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/teams/details/:teamId"
            component={TeamLeadTeamDetails}
            isAuthenticated={isAuthenticated}
          />
           <PrivateRoute
            exact
            path="/member/detail/:id"
            component={TeamLeadMemberDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/members/report/feedback/received/:id"
            component={ReceivedFeedbackReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/detail/view"
            component={FeedbackAssessmentDetail}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/members"
            component={Members}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/manage/attributes"
            component={CustomAttributeAndQuestions}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/manage/attributes/:attributeId"
            component={AttributeDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/members/report/feedback/received/:id"
            component={ReceivedFeedbackReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/member/detail/:id"
            component={MemberDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/detail/view"
            component={FeedbackAssessmentDetail}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/client/feedback/share/:orgnization/:requestId"
            component={ShareFeedbackByExternalClient}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/setup/workspace"
            component={WorkspaceSetup}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/about/policy"
            component={PrivacyPolicy}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/feedback"
            component={AllFeedback}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/attributes"
            component={Attributes}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/settings"
            component={Settings}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/teams/create"
            component={CreateEditTeam}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            exact
            path="/admin/teams"
            component={Teams}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/nudge"
            component={NudgeBoardPage}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/teams/edit/:teamId"
            component={CreateEditTeam}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/admin/teams/details/:teamId"
            component={TeamDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/error"
            component={PageNotFound}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/"
            component={Website}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            exact
            path="/signIn"
            component={SignIn}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            component={PageNotFound}
            isAuthenticated={isAuthenticated}
          />
        </Switch>
      </div>
    </Router>
  );
}

export const PrivateRoute = ({ isAuthenticated, ...props }) => {
  return isAuthenticated ? <Route {...props}></Route> : <Redirect to={"/"} />;
};

export const PublicRoute = ({ isAuthenticated, ...props }) => {
  return !isAuthenticated ? (
    <Route {...props}></Route>
  ) : (
    <Redirect to={"/home"} />
  );
};

export const AllRoutes = () => {
  const token = useSelector((state: State) => state.auth.token);

  return <>{createRoutes(token)}</>;
};
