import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { SelfAssessmentDetailLayout } from "../../../../layouts/user/self_assessment/detail/Layout";
import { sharedAssessmentDetail } from "../../../../redux/slices/SharedAssessmentDetailSlice";
import { State } from "../../../../type";
import { SharedAssessmentDetailSliceProps } from "../type";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import {
  RenderAttributesDetails,
  RenderAttributeText,
  RenderDate,
} from "../../../components/list/detail/DetailComponent";
import { Button, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  btn: {
    height: "fit-content",
    width:"200px",
    background: "#7C69EC",
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "10px 28px",
  }
}));

export const SelfAssessmentDetail = (props) => {
  const dispatch = useDispatch();
  let { id } = props.match.params;
  const [logout, setLogout] = React.useState(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const [value, setValue] = React.useState<number>(-1);
  const [attributeDetails, setAttributeDetails] = React.useState<[]>([]);
  const data = useSelector((state: State) => state.sharedAssessmentDetail.data);
  const sharedDate = useSelector((state: State) => state.sharedAssessmentDetail.data?.createdAt);
  const isSharedAssessment = useSelector((state: State) => state.sharedAssessmentDetail.data?.isSharedAssessment);
  const currentRoutePath: string = useLocation().pathname;
  const isEditTeamRouteView: boolean = currentRoutePath.startsWith(
    "/home/self-assessment/edit"
  );
  const classes = useStyle();

  useEffect(() => {
    const params: SharedAssessmentDetailSliceProps = {
      assessmentId: id,
    };
    dispatch(sharedAssessmentDetail(params));
  }, [id]);

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.sharedAssessmentDetail.status !== 201
      ? state.sharedAssessmentDetail.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderEditAssessmentButton = () => {
    return (
      <Button
        color="primary"
        className={classes.btn}
        variant="contained"
        disabled={!isSharedAssessment}
        onClick={() => {
          history.push(`/home/self-assessment/edit/${id}`);
        }}
         
      >
        Edit Assessment
      </Button>
    );
  };

  const renderSharedDate = () => (
    <RenderDate date={data?.createdAt} from={"ASSESSMENT_SUBMITTED"} />
  );

  const renderAttributesDetail = () => (
    <>
      <RenderAttributeText from={"ASSESSMENT_SUBMITTED"} />
      <RenderAttributesDetails
        attributes={data?.attributes}
        from={"ASSESSMENT_SUBMITTED"}
      />
    </>
  );

  if (value === 0) {
    return <Redirect to="/home" />;
  }

  if (!isDetailView) {
    history.push({
      pathname: "/home/self-assessment",
      state: {
        pageNo: props.history.location.state?.pageNo,
        tab: 1,
      },
    });
  }

  return (
    <SelfAssessmentDetailLayout
      props={props}
      setLogout={setLogout}
      value={value}
      setValue={setValue}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderSharedDate={renderSharedDate}
      renderAttributesDetail={renderAttributesDetail}
      renderEditAssessmentButton={renderEditAssessmentButton}
    ></SelfAssessmentDetailLayout>
  );
};
