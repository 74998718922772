import { makeStyles } from "@material-ui/core/styles";

export const createTeamCancelModalStyles = makeStyles((theme) => ({
    confirmationInfo: {
        fontSize: 14,
        fontWeight: 500,
        color: "#272727",
    }
}));

