const month = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function formatDateToDDMonthYYYY(inputDate: Date): string {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthName = month[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
}

function formatDateToDDMonYYYY(inputDate: Date): string {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthName = month[date.getMonth()].slice(0, 3);
  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
}

function formatDateToDDMMYYYY(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export enum DateFormat {
  DDMMYYYY,
  DDMonYYYY,
  DDMonthYYYY,
}

export function formatDate(date: Date, requiredFormat: DateFormat) {
  switch (requiredFormat) {
    case DateFormat.DDMMYYYY:
      return formatDateToDDMMYYYY(date);

    case DateFormat.DDMonYYYY:
      return formatDateToDDMonYYYY(date);

    case DateFormat.DDMonthYYYY:
      return formatDateToDDMonthYYYY(date);

    default:
      return date;
  }
}
