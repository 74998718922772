import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DetailPageSliceProps } from "../../modules/admin/members/details/detail/type";
import { ReceivedFeedbackDetailData } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const receivedFeedbackDetailByMember = createAsyncThunk(
  "admin/feedback/received/detail",
  async (params: DetailPageSliceProps, storeActions) => {
    const url = `api/v1/admin/feedbacks/received/detail?feedbackId=${params.feedbackOrAssessmentId}&memberId=${params.memberId}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        feedback: await response.json(),
      };
    }
    return {
      status: response.status,
      feedback: null,
    };
  }
);

const receivedFeedbackDetailByMemberSlice = createSlice({
  name: "receivedFeedbackDetailByMember",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    feedback: null as ReceivedFeedbackDetailData | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.feedback = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      receivedFeedbackDetailByMember.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          status: number | null;
          feedback: ReceivedFeedbackDetailData | null;
        }>
      ) => {
        state.status = payload.status;
        state.loading = false;
        state.feedback = payload.feedback;
      }
    );
    builder.addCase(receivedFeedbackDetailByMember.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(receivedFeedbackDetailByMember.rejected, (state) => {
      state.status = 503;
      state.loading = false;
    });
  },
});

export const { clearData } = receivedFeedbackDetailByMemberSlice.actions;

export default receivedFeedbackDetailByMemberSlice;
