import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import theme from "../../../theme";
import { SharedDraftProps, State } from "../../../type";

const useStyle = makeStyles((theme) => ({
  tabs: {
    color: "#576BEA",
    width: theme.spacing(20),
    textAlign: "left",
  },
  divider: {
    backgroundColor: "#576bea66",
    width: "inherit",
  },
  typography_body_1: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

const ShareText = () => {
  const classes = useStyle();
  return (
    <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
      Share
    </Typography>
  );
};

const DraftsText = () => {
  const classes = useStyle();
  const emptyDrafts: Array<SharedDraftProps> = [];
  const drafts =
    useSelector((state: State) => state.data.data?.drafts) ?? emptyDrafts;
  const loading = useSelector((state: State) => state.data.loading);
  return (
    <Typography gutterBottom variant="h5" className={classes.typography_body_1}>
      {loading ? "Drafts" : `Drafts (${drafts?.length})`}
    </Typography>
  );
};

export const TabLabels = ({ tabValue, setTabValue, props, setMissing }) => {
  const classes = useStyle();
  const history = useHistory();
  const indexToTab = {
    0: "default",
    1: "drafts",
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let prevId = history.location;
    props.history.push(`/feedback/share/${indexToTab[newValue]}`);
    setMissing(false);
    let newId = history.location;
    if (newId !== prevId) setTabValue(newValue);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    let prevId = history.location;
    setMissing(false);
    props.history.push(
      `/feedback/share/${indexToTab[event.target.value as number]}`
    );
    let newId = history.location;
    if (newId !== prevId) setTabValue(event.target.value as number);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Hidden smDown>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab style={{ minWidth: theme.spacing(7.5) }} label={<ShareText />} />
          <Tab
            style={{
              marginLeft: theme.spacing(3),
              minWidth: theme.spacing(7.5),
            }}
            label={<DraftsText />}
          />
        </Tabs>
        <Divider className={classes.divider} />
      </Hidden>
      {
        <Hidden mdUp>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <MenuItem value={0}>
              <ShareText />
            </MenuItem>
            <MenuItem value={1}>
              <DraftsText />
            </MenuItem>
          </Select>
        </Hidden>
      }
    </Grid>
  );
};
