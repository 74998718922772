import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { TeamLeadTeamDetailsLayout } from "../../../layouts/user/teams/TeamLeadTeamDetailsLayout";
import { USER_ROLES } from "../../../enums/teamEnums";
import { State, TeamDetailSliceProps, User } from "../../../type";
import { makeStyles } from "@material-ui/core/styles";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import Grid from "@material-ui/core/Grid";
import TeamMemberList from "../../admin/teams/createTeams/TeamMemberList";
import { getTeamDetails } from "../../../redux/slices/TeamDetailsSlice";
import { Typography } from "@material-ui/core";
import { MESSAGES } from "../../admin/teams/helper";
import { HttpStatus } from "../../../enums/httpStatus";
import { TabLabels } from "./TabLabels";
import { RoundRobinSlide } from "./RoundRobin";

const useStyle = makeStyles((theme) => ({
  btn: {
    height: "fit-content",
    background: "#7C69EC",
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "10px 28px",
  },
  teamName: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: "#272727",
    lineHeight: "56px",
  },
}));

export const TeamLeadTeamDetails = (props) => {
  const dispatch = useDispatch();
  const { teamId }: any = useParams();
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const [allSelectedUsers, setAllSelectedUsers] = React.useState<User[]>([]);
  const [allSelectedUserRoles, setAllSelectedUserRoles] = React.useState<any>(
    []
  );
  const tabToIndex = {teamDetails: 0, roundRobin: 1};
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [tabValue, setTabValue] = React.useState<number>(tabToIndex["teamDetails"]);

  const history = useHistory();
  const classes = useStyle();
  const { data: teamDetails } =
    useSelector((state: State) => state.teamDetails) ?? [];
  const teamMembers = teamDetails?.teamMembers ?? [];
  const teamLeads = teamDetails?.teamLeads ?? [];
  const loading: Boolean = useSelector(
    (state: State) => state.teamDetails.loading
  );

  useEffect(() => {
    const teamAdded = props.location.state?.teamAdded;
    const teamUpdated = props.location.state?.teamUpdated;
    
    if (teamAdded) {
      setShowSuccess(true);
      setMessage(MESSAGES.TEAM_CREATED);
    } else if (teamUpdated) {
      setShowSuccess(true);
      setMessage(MESSAGES.TEAM_UPDATED);
    }
  }, []);

  useEffect(() => {
    if (teamDetails?.teamName) {
      const teamLeadsWithRole = (teamDetails?.teamLeads || []).map(
        (member) => ({
          ...member,
          role: USER_ROLES.TEAM_LEAD,
        })
      );

      const teamMembersWithRole = (teamDetails?.teamMembers || [])?.map(
        (member) => ({
          ...member,
          role: USER_ROLES.TEAM_MEMBER,
        })
      );
      const allTeamMembersAndLeads = [
        ...teamLeadsWithRole,
        ...teamMembersWithRole,
      ];
      setAllSelectedUsers(allTeamMembersAndLeads);
    }
  }, [teamLeads, teamMembers]);

  useEffect(() => {
    const param: TeamDetailSliceProps = {
      teamId: teamId,
    };
    dispatch(getTeamDetails(param));
  }, [teamId]);

  const renderTeamName = () => {
    return (
      <Typography className={classes.teamName}>
        {teamDetails?.teamName}
      </Typography>
    );
  };

  const statusCode: number | null = useSelector((state: State) =>
  state.teamDetails.status !== HttpStatus.OK
    ? state.teamDetails.status
        : null
);

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderTabLabels = () => (
    <TabLabels
      tabValue={tabValue}
      setTabValue={setTabValue}
    ></TabLabels>
  );

  const renderTeamDetailsList = () => {
    return (
      <Grid container>
        <TeamMemberList
          users={allSelectedUsers}
          setAllSelectedUsers={setAllSelectedUsers}
          loading={loading}
          roles={allSelectedUserRoles}
          setRoles={setAllSelectedUserRoles}
          isTeamDetailsView={true}
        />
      </Grid>
    );
  };

  const renderRoundRobin = () => {
    return (
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <RoundRobinSlide />
      </Grid>
    </Grid>
    );
  };

  if (!isDetailView) {
    const currentPage = props.location?.state?.currentPage || 1;
    history.push({ pathname: "/teams", search: `?page=${currentPage}` });
  }
  const handleCancel = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
    setMessage("");
  };

  const renderContent = () => {
    switch (tabValue) {
      case tabToIndex["teamDetails"]:
        return renderTeamDetailsList();
      case tabToIndex["roundRobin"]:
        return renderRoundRobin();
      default:
        return renderTeamDetailsList();
    }
  };

  return (
    <TeamLeadTeamDetailsLayout
      props={props}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      loading={loading}
      setLogout={setLogout}
      renderTeamDetailsList={renderContent}
      renderTeamName={renderTeamName}
      showSuccess={showSuccess}
      handleClose={handleCancel}
      message={message}
      tabValue={tabValue}
      renderTabs={renderTabLabels}
    ></TeamLeadTeamDetailsLayout>
  );
};
