import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../../type";
import { fetchFeedbackCounts } from "../../../../redux/slices/RoundRobinFeedbackSlice";
import { CalendarCTA } from "../../../components/CalenderCTA";
import { DateProps } from "../../dashboard/type";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    tableContainer: {
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        maxWidth: '100%',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        border: '1px solid #B0B0B0',
    },
    teamName: {
        fontSize: theme.spacing(3),
        fontWeight: 600,
        color: "#272727",
        lineHeight: "56px",
        flexGrow: 1,
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    calendarContainer: {
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginTop: theme.spacing(3),
            alignSelf: 'flex-start',
        },
    },
    nameColumn: {
        borderRight: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        left: 0,
    },
    centralHeader: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerRow1: {
        backgroundColor: '#D3D3D3',
        zIndex: 5,
    },
    headerRow2: {
        position: 'sticky',
        top: '38px',
        left: 0,
        zIndex: 3,
    },
    tableCell: {
        width: '250px',
        minWidth: '250px',
        maxWidth: '250px',
    },
}));

export const RoundRobinSlide = () => {
    const classes = useStyles();
    const { teamId }: any = useParams();
    const dispatch = useDispatch();
    const feedbackCounts = useSelector((state: State) => state.roundRobin.feedbackCounts);
    const [rows, setRows] = useState<any[]>([]);
    const [header1, setHeader1] = useState<string[]>([]);
    const [header2, setHeader2] = useState<string[]>([]);


    useEffect(() => {
        if (feedbackCounts) {
            const userList = feedbackCounts.userFeedbackDataList;
            setHeader1Data(userList)
            setHeader2Data(userList)

            const rowsData = userList.map((user1: any) => {
                const rowData: any = [];
                rowData.push(user1.fullName)

                userList.forEach((user2: any) => {
                    rowData.push(user1.sharedFeedbackCounts[user2.userId] || 0);
                });

                return rowData;
            });

            console.log(rowsData);
            setRows(rowsData);
        }
    }, [feedbackCounts]);

    const setHeader1Data = (userList) => {

        const visibleColumnIndex = 1;
        const totalUsers = userList.length;
        const emptyColumnBeforeContent = totalUsers <= 3 ? (totalUsers == 3 ? 1 : 0) : Math.max(0, visibleColumnIndex);
        const headers = new Array(emptyColumnBeforeContent).fill('');
        
        headers.push('Feedback Received By');
        let emptyColumnsAfterContent = 0;
        if(totalUsers > 1 && totalUsers <=3) {
            emptyColumnsAfterContent = 1;
        } else if(totalUsers > 3){
            emptyColumnsAfterContent = (totalUsers - emptyColumnBeforeContent - 1);
        }
        headers.push(...new Array(emptyColumnsAfterContent).fill(''));
        setHeader1([...headers]);
    }

    const setHeader2Data = (userList) => {
        setHeader2(userList.map((user: any) => user.fullName));
    }

    const containerHeight = (4 * 150);
    const containerWidth = (9.3 * 150);

    const [selectedDateCount, setSelectedDateCount] = useState<number>(0);
    const [receivedFeedbackDateRangeState, setReceivedFeedbackDateRangeState] = useState<DateProps>({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        endDate: new Date(),
    });

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const startDate = formatDate(receivedFeedbackDateRangeState.startDate);
    const endDate = formatDate(receivedFeedbackDateRangeState.endDate);
    const numericTeamId = Number(teamId);

    useEffect(() => {
        if (!isNaN(numericTeamId)) {
            dispatch(fetchFeedbackCounts({ teamId: numericTeamId, startDate, endDate }));
        } else {
            console.error('Invalid teamId:', teamId);
        }
    }, [numericTeamId, startDate, endDate, dispatch]);


    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <Typography className={classes.teamName}>Round Robin Feedback</Typography>
                <div className={classes.calendarContainer}>
                    <CalendarCTA
                        setDateRange={setReceivedFeedbackDateRangeState}
                        dateRange={receivedFeedbackDateRangeState}
                        selectedDateCount={selectedDateCount}
                        setSelectedDateCount={setSelectedDateCount}
                    />
                </div>
            </div>
            <Grid container className={classes.tableContainer} >
                <Grid item style={{ flexGrow: 1 }}>
                    {/* <TableContainer component={Paper} className={classes.tableWithShadow}> */}
                    <TableContainer style={{ maxHeight: containerHeight, maxWidth: containerWidth }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key={'Shared By'}
                                        align="center"
                                        className={` ${classes.headerRow1} ${classes.nameColumn} ${classes.tableCell}`}
                                        style={{ lineHeight: "0.3rem", zIndex: 6 }}

                                    >
                                        {'Shared By'}
                                    </TableCell>
                                    {header1.map((col) => (
                                        <TableCell
                                            key={col}
                                            align="center"
                                            className={` ${classes.headerRow1} ${classes.tableCell}`}
                                            style={{ lineHeight: "0.3rem" }}

                                        >
                                            {col}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow className={classes.headerRow2}>
                                    <TableCell
                                        key={'Name'}
                                        align="center"
                                        className={`${classes.nameColumn} ${classes.tableCell}`}
                                        style={{ width: `${100 / (header2.length + 1)}%`, backgroundColor: '#eeeefb', zIndex: 4}}

                                    >
                                        {'Name'}
                                    </TableCell>
                                    {header2.map((col) => (
                                        <TableCell
                                            key={col}
                                            align="center"
                                            className={`${classes.tableCell}`}
                                            style={{ width: `${100 / header2.length}%`, backgroundColor: '#eeeefb' }}

                                        >
                                            {col}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((col, colIndex) => (
                                            <TableCell
                                                key={col}
                                                align="center"
                                                className={`${colIndex === 0 ? classes.nameColumn : ''} ${classes.tableCell}`}
                                                style={{zIndex: colIndex === 0 ? 2 : 0, background: colIndex === 0 ? '#f5f6fa' : ''}}
                                            >
                                                {rowIndex === colIndex - 1 ? 'X' : col}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};
