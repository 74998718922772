import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { User } from "../../../type";
import { CustomDivider } from "./CustomDivider";
import { LeaderboardMemberCardComponent } from "./LeaderboardMemberCard";
import { TopLeaderCardComponent } from "./TopLeaderCard";
import { LeaderBaordProps } from "./type";
import "./style.css";
import { CustomSelect } from "../../components/CustomSelect";

const useStyles = makeStyles((theme) => ({
  appraisalSection: {
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    boxSizing: "border-box",
    margin: theme.spacing(1, 1, 2, 1),
  },
  dates: {
    position: "absolute",
    right: "47px",
    zIndex: 100,
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    fontFamily: "Montserrat",
    marginTop: "10px",
  },
}));

interface DataProps {
  count: number;
  members: Array<User>;
}

export const LeaderBoardComponent: React.FC<LeaderBaordProps> = ({
  data,
  feedbackTag,
  setFeedbackTag,
  loading,
  dateRangeState,
  setDateRangeState,
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyle();
  const [show, setShow] = React.useState<Boolean>(false);
  const handleDateChange = (range) => {
    const nextRange = {
      startDate: range?.range1?.startDate,
      endDate: range?.range1?.endDate,
    };
    setDateRangeState(nextRange);
  };

  const leaderMap = new Map();

  data?.map((u) => {
    if (leaderMap.get(u.feedbackCount) === undefined) {
      let arr = [u.member];
      leaderMap.set(u.feedbackCount, arr);
    } else {
      let arr = [u.member];
      let ar = [...leaderMap.get(u.feedbackCount), ...arr];
      leaderMap.delete(u.feedbackCount);
      leaderMap.set(u.feedbackCount, ar);
    }
  });

  const finalLeaders: Array<DataProps> = [];
  leaderMap.forEach((value, key) => {
    finalLeaders.push({
      count: key,
      members: value,
    });
  });

  return (
    <Grid xs={12} item className={classes.appraisalSection}>
      <Grid xs={12} item container style={{ padding: "11px 14px 11px 14px" }}>
        <Grid xs={7} item container justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{ color: "#272727" }}>
            Leaderboard
          </Typography>
        </Grid>
        <Grid xs item container justify="flex-end" alignItems="center">
          <Grid item>
            <IconButton
              onClick={() => setShow(true)}
              style={{
                padding: "0px 4px",
                margin: "0px",
                position: "relative",
                height: "20px",
                width: "20px",
              }}
            >
              <img src="/CalendarIcon.svg" alt="l-calendar" />
            </IconButton>
            {show && (
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => setShow(false)}
              >
                <div className={classes.dates}>
                  <DateRangePicker
                    ranges={[dateRangeState]}
                    onChange={(e) => handleDateChange(e)}
                    maxDate={new Date()}
                    dateDisplayFormat={"d MMM yyyy"}
                  />
                </div>
              </ClickAwayListener>
            )}
          </Grid>
          <Grid item>
            <CustomSelect
              tabValue={feedbackTag}
              setTabValue={setFeedbackTag}
              name={"FEEDBACK_TYPE_LIST"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <CustomDivider />
      </Grid>
      <Grid
        className="Leaderboard"
        style={{ height: theme.spacing(44), overflowY: "auto" }}
      >
        {finalLeaders[0]?.members && (
          <TopLeaderCardComponent
            users={finalLeaders[0]?.members}
            feedbackCount={finalLeaders[0]?.count}
          />
        )}
        {finalLeaders[1]?.members && (
          <LeaderboardMemberCardComponent
            users={finalLeaders[1]?.members}
            position={"2nd"}
            feedbackCount={finalLeaders[1]?.count}
          />
        )}
        {finalLeaders[2]?.members && (
          <LeaderboardMemberCardComponent
            users={finalLeaders[2]?.members}
            position={"3rd"}
            feedbackCount={finalLeaders[2]?.count}
          />
        )}
        {finalLeaders[3]?.members && (
          <LeaderboardMemberCardComponent
            users={finalLeaders[3]?.members}
            position={"4th"}
            feedbackCount={finalLeaders[3]?.count}
          />
        )}
        {finalLeaders[4]?.members && (
          <LeaderboardMemberCardComponent
            users={finalLeaders[4]?.members}
            position={"5th"}
            feedbackCount={finalLeaders[4]?.count}
          />
        )}
        {loading && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ position: "relative", marginTop: theme.spacing(16) }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && data?.length === 0 && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ marginTop: theme.spacing(16) }}
          >
            <Typography
              variant="body2"
              className={customClasses.typography_font_24_14_500}
            >
              Not able to fetch leader
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
