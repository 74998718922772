import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeactivateMemberProp, ReceivedFeedbackDataProps } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getSelfReport = createAsyncThunk(
  "feedback/received/report",
  async (params: DeactivateMemberProp, storeActions) => {
    const url = `api/v1/user/feedback/received/report` + (params.requestingParam ? '?' + params.requestingParam : '');
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const selfReportSlice = createSlice({
  name: "selfReport",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as Array<ReceivedFeedbackDataProps> | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSelfReport.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(getSelfReport.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(getSelfReport.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = selfReportSlice.actions;

export default selfReportSlice;
