import { SearchOption } from "../modules/components/type";
import { ResponseAttribute } from "../type";

export function attributeToOption(attr: ResponseAttribute) {
  return {
    userId: null,
    attrId: attr.id,
    name: attr.name,
  } as SearchOption;
}
