import { CircularProgress, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";

const drawerWidth = 0;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "inherit",
    },
    loaderBackground: {
      minHeight: `${window.innerHeight - 48}px`,
      padding: theme.spacing(6, 6, 6, 5),
      marginTop: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 4, 4, 4),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(11.25),
        padding: theme.spacing(4, 3, 3, 3),
      },
      background: "#FFFFFF",
    },
    mainSection: {
      margin: theme.spacing(2, 2, 1, 2),
      width: `calc(100% - 0px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        margin: theme.spacing(4, 18.75, 1, 18.75),
      },
    },
  })
);

export const Loader = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.loaderBackground}>
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

interface LayoutProps  {
    TopSectionPage: ()=>React.ReactNode;
    MainSectionPage1: ()=> React.ReactNode;
    MainSectionPage2: ()=> React.ReactNode;
    MainSectionPage3: ()=> React.ReactNode;
    MainSectionPage4: ()=> React.ReactNode;
    MainSectionPage5: ()=> React.ReactNode;
    MainSectionPage6: ()=> React.ReactNode;
}

export const Layout: React.FC<LayoutProps> =
  ({
      TopSectionPage,
      MainSectionPage1,
      MainSectionPage2,
      MainSectionPage3,
      MainSectionPage4,
      MainSectionPage5,
      MainSectionPage6,
  }) => {
    const classes = useStyles();
    window.scroll({
        behavior: "smooth"
    })
    return (
      <Grid xs={12} item container direction="row" className={classes.root}>
        <Grid container item xs={12} justify="center">
            {TopSectionPage()}
            {MainSectionPage1()}
            {MainSectionPage2()}
            {MainSectionPage3()}
            {MainSectionPage4()}
            {MainSectionPage5()}
            {MainSectionPage6()}
        </Grid>
      </Grid>
    );
  };
