import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CountCardProps } from "../type";

const useStyle = makeStyles((theme) => ({
  commonStyle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  cardName: {
    marginLeft: theme.spacing(2),
    textAlign: "start",
    fontSize: theme.spacing(1.75),
  },
  cardCount: {
    marginRight: theme.spacing(2),
    textAlign: "end",
    fontSize: theme.spacing(3.5),
  },
}));

export const CountCard: React.FC<CountCardProps> = ({
  cardName,
  cardCount,
}) => {
  const classes = useStyle();

  return (
    <Grid xs={12} item container justify="center" alignItems="center">
      <Grid xs={7} item container justify="flex-start" alignItems="center">
        <Grid xs={12} item>
          <Typography className={`${classes.commonStyle} ${classes.cardName}`}>
            {cardName}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={5} item container justify="flex-end" alignItems="center">
        <Grid xs={12} item>
          <Typography className={`${classes.commonStyle} ${classes.cardCount}`}>
            {cardCount < 10 && cardCount>0 ? `0${cardCount}` : `${cardCount}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
