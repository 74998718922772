import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  ClickAwayListener,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountProps, DeactivateMemberProp, State } from "../../../../type";
import { getDateInDesiredFormat } from "../../../../util/GetDateInDesiredFormat";
import { MemberDetailProps } from "../type";
import { CountCard } from "./CountCard";
import { deactivateMember } from "../../../../redux/slices/DeactivateMemberSlice";
import { DeactivateUserModalComponent } from "../DeactivateMemberModal";
import { changeMemberAccessRole } from "../../../../redux/slices/UpdateMemberAccessRole";
import { useLocation } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  img: {
    height: theme.spacing(12.5),
    width: theme.spacing(12.5),
    marginLeft: theme.spacing(1),
  },
  blackFont: {
    color: "#272727",
    fontFamily: "Montserrat",
    textAlign: "start",
  },
  memberName: {
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  memberMail: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  commonCardStyle: {
    height: theme.spacing(7.5),
    width: theme.spacing(27.5),
    borderRadius: theme.spacing(0.625),
  },
  receivedCountBackground: {
    background: "linear-gradient(47deg, #9768ED 0%, #576BEA 100%)",
    marginRight: theme.spacing(1.125),
  },
  sharedCountBackground: {
    background: "linear-gradient(44.05deg, #51A6FF 0%, #5328FF 100%)",
    marginLeft: theme.spacing(1.125),
  },
  moreCTAGrid: {
    height: theme.spacing(7.5),
    borderRadius: theme.spacing(0.625),
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(175,175,175,0.5)",
    marginLeft: theme.spacing(2.25),
  },
  moreCTA: {
    height: theme.spacing(4.25),
    width: theme.spacing(4.25),
  },
  detailSectionGrid: {
    borderRadius: theme.spacing(0.625),
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    height: theme.spacing(17.5),
  },
  moreViewItem: {
    fontSize: theme.spacing(1.5),
    textAlign: "center",
    fontWeight: 500,
    color: "#272727",
    fontFamily: "Montserrat",
    backgroundColor: "#FFFFFF",
  },
  navBar: {
    width: theme.spacing(20),
    height: theme.spacing(7),
    boxShadow: "-5px 9px 19px 0 rgba(90,90,90,0.4)",
  },
  moreBtn1: {
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    minWidth: theme.spacing(0),
  },
  moreBtn2: {
    width: "-moz-fill-available",
    height: "-moz-fill-available",
  },
}));

const SWITCH_ROLE_MAP = {
  REGULAR: "ADMIN",
  ADMIN: "REGULAR",
};

export const DetailSectionView: React.FC<MemberDetailProps> = ({
  firstName,
  lastName,
  imageUrl,
  status,
  id,
  emailId,
  joiningDate,
  user_role,
}) => {
  const classes = useStyle();
  const [moreOpt, setMoreOpt] = React.useState<Boolean>(false);
  const [lock, setLock] = React.useState<Boolean>(false);
  const [userRole, changeRole] = React.useState<string>(user_role);
  const [open, setOpen] = React.useState<boolean>(false);
  const counts: CountProps = useSelector(
    (state: State) => state.countAllSharedAndReceived?.data
  );
  const token = useSelector((state: State) => state.auth.token);
  const loggedInUserEmail = useSelector((state: State) => state.auth.email);
  const changeRoleStatus = useSelector(
    (state: State) => state.changeMemberAccessRole.status
  );
  const dispatch = useDispatch();
  const location = useLocation();

  async function handleCancel(e) {
    e.preventDefault();
    setOpen(false);
  }
  const handleClickAway = () => {
    setMoreOpt(false);
  };

  const handleDeactivateAccount = (id) => {
    const arg: DeactivateMemberProp = {
      memberId: id,
      tag: "INTERNAL",
    };
    dispatch(deactivateMember(arg));
    setOpen(false);
  };

  const renderDeactivateMemberModal = () => (
    <DeactivateUserModalComponent
      id={id}
      open={open}
      firstName={firstName}
      lastName={lastName}
      handleCancel={handleCancel}
      handleDeactivateAccount={handleDeactivateAccount}
    />
  );

  useEffect(() => {
    if (changeRoleStatus === 200 && lock) {
      changeRole(SWITCH_ROLE_MAP[userRole]);
      setLock(false);
    }
  }, [changeRoleStatus]);

  return (
    <Grid
      xs={12}
      item
      container
      alignItems="center"
      justify="flex-start"
      className={classes.detailSectionGrid}
    >
      <Grid xs={5} item container>
        {renderDeactivateMemberModal()}
        <Grid xs={4} item container justify="center">
          <Avatar
            src={imageUrl}
            alt={firstName?.toUpperCase()}
            className={classes.img}
            style={imageUrl === firstName ? {fontSize: "48px"}: {}}
          />
        </Grid>
        <Grid xs={8} item container>
          <Grid
            xs={12}
            item
            container
            justify="flex-start"
            alignItems="flex-end"
          >
            <Typography
              className={`${classes.blackFont} ${classes.memberName}`}
            >{`${firstName} ${lastName}`}</Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Typography
              className={`${classes.blackFont} ${classes.memberMail}`}
            >
              {joiningDate &&
                `${emailId} | ${getDateInDesiredFormat(new Date(joiningDate))}`}
            </Typography>
            <Typography
              className={`${classes.blackFont} ${classes.memberMail}`}
            >
              {joiningDate === null && `${emailId}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={7}
        item
        container
        justify="flex-end"
        style={{ paddingRight: "24px" }}
        alignItems="center"
      >
        <Grid
          item
          className={`${classes.commonCardStyle} ${classes.receivedCountBackground}`}
          container
          alignItems="center"
        >
          <CountCard
            cardName={"Total Feedback Received"}
            cardCount={counts?.receivedCount}
          ></CountCard>
        </Grid>
        <Grid
          item
          className={`${classes.commonCardStyle} ${classes.sharedCountBackground}`}
          container
          alignItems="center"
        >
          <CountCard
            cardName={"Total Feedback Shared"}
            cardCount={counts?.sharedCount}
          ></CountCard>
        </Grid>

        {location.pathname.startsWith("/admin/member/detail/") && id && (
        <Grid item xs={1} className={classes.moreCTAGrid}>
          <Button
            onClick={() => setMoreOpt(true)}
            className={`${classes.moreBtn1} ${classes.moreBtn2} `}
          >
            <img
              src="/moreIcon.svg"
              alt="btn"
              className={classes.moreCTA}
            ></img>
          </Button>
          {moreOpt && (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClickAway}
            >
              <List
                component="nav"
                style={{
                  position: "relative",
                  right: 90,
                  zIndex: 5,
                }}
              >
                {emailId !== loggedInUserEmail && (
                  <ListItem
                    className={classes.navBar}
                    style={{ backgroundColor: "#FFFFFF", opacity: 1 }}
                    button
                    onClick={() => {
                      dispatch(
                        changeMemberAccessRole({
                          userType: SWITCH_ROLE_MAP[userRole],
                          memberId: id,
                        })
                      );
                      setLock(true);
                    }}
                    disabled={status === "INACTIVE"}
                  >
                    <Typography className={classes.moreViewItem}>
                      {userRole === "REGULAR"
                        ? "Assign Admin Role"
                        : "Revoke Admin Role"}
                    </Typography>
                  </ListItem>
                )}
                <ListItem
                  className={classes.navBar}
                  style={{ backgroundColor: "#FFFFFF", opacity: 1 }}
                  button
                  onClick={() => {
                    setOpen(true);
                    setMoreOpt(false);
                  }}
                  disabled={status === "INACTIVE"}
                >
                  <Typography className={classes.moreViewItem}>
                    Deactivate
                  </Typography>
                </ListItem>
              </List>
            </ClickAwayListener>
          )}
        </Grid>
        )}
      </Grid>
    </Grid>
  );
};
