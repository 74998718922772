import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ErrorPopUp } from "../../components/ErrorPages/errorModal";
import { signInTitleText } from "../../constants/signInTitleText";
import { SignInLayout } from "../../../layouts";
import { State } from "../../../type";
import { TokenDataProps } from "../../home/Drawer";
import { SignInButton } from "./signInButton";
import Search from "@material-ui/icons/Search";
import { FooterComponent } from "../landing_page/Footer";
import { CorporateLogin } from "./CorporateLogin";


const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: theme.spacing(5.25),
    fontWeight: 600,
    color: "#FFFFFF",
    textAlign: "start",
    margin: theme.spacing(3, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      margin: theme.spacing(0, 0, 0, 0),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  typography2: {
    fontSize: theme.spacing(5.25),
    fontWeight: 600,
    color: "#FFFFFF",
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      textAlign: "start",
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  typography3: {
    fontSize: theme.spacing(5.25),
    fontWeight: 600,
    color: "#FFFFFF",
    textAlign: "start",
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.75),
    },
  },
  progressBar: {
    margin: theme.spacing("25%", "50%"),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing("25%", "40%"),
    },
  },
  img: {
    height: `${window.innerHeight / 2}px`,
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(62.5),
    },
    [theme.breakpoints.down("sm")]: {
      height: `auto`,
      width: theme.spacing(50),
    },
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(40),
    },
  },
  titleTopGrid: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  logo: {
    margin: theme.spacing(2.5, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2.5, 0, 6, 0.125),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1.5, 0, 1, 0),
    },
  },
  logoName: {
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
    fontWeight: 800,
    fontFamily: "Nunito",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  logoImg: {
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
}));

export const SignIn = (props) => {
  let pagePath = props.location.state?.path;
  const { title_line1, title_line2, title_line3 } = signInTitleText;
  const classes = useStyles();
  const [isDispatch, setIsDispatch] = React.useState<Boolean>(false);
  const [statusCode, setStatusCode] = React.useState<number | null>(null);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [corporateEmailEntered, setCorporateEmailEntered] = React.useState(false);
  const uid = new URLSearchParams(props.location.search).get("uid");
  const { status, loading, token, message } = useSelector(
    (state: State) => state.auth
  );

  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    status: "ACTIVE",
  };
  try {
    tokenData = jwt_decode(token);
  } catch (err) {
    console.error((err as Error).message);
  }

  useEffect(() => {
    if (status !== 200) {
      setIsError(true);
      setIsDispatch(false);
      setStatusCode(status);
    } else if (status !== null) setIsDispatch(false);
  }, [status]);

  const renderAppLogo = () => (
    <Grid
      container
      className={classes.logo}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <LazyLoadImage
          src="/LogoWeb2.svg"
          alt="App Logo"
          className={classes.logoImg}
        ></LazyLoadImage>
      </Grid>
      <Grid item>
        <Typography className={classes.logoName}>{"Grow-Wize"}</Typography>
      </Grid>
    </Grid>
  );

  const renderFooter = () => (
    <FooterComponent />
  );


  const renderWelcomeMessage = () => (
    <Grid container xs={12} item>
      <Grid container xs={12} item md={12} className={classes.titleTopGrid}>
        <Typography className={classes.typography}>{title_line1}</Typography>
        <Typography className={classes.typography2}>{title_line2}</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography className={classes.typography3}>{title_line3}</Typography>
      </Grid>
    </Grid>
  );
  const renderImage = () => (
    <LazyLoadImage
      alt="sign_in_illustrator"
      src="/Illustrator.svg"
      className={classes.img}
      effect="blur"
    />
  );

  const renderSignInCta = () => (
    <SignInButton
      setIsDispatch={setIsDispatch}
      setIsError={setIsError}
      setStatusCode={setStatusCode}
      pagePath={pagePath}
      corporateEmailEntered={corporateEmailEntered}
    ></SignInButton>
  );

  const renderCorporateLogin = () => (
    <CorporateLogin corporateEmailEntered={corporateEmailEntered} setCorporateEmailEntered={setCorporateEmailEntered} />
  );

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={isError}
      setShowModal={setIsError}
      handleAccessDenied={() => { setIsError(false); }}
      handleNoAuthorization={() => { setIsError(false); }}
      statusCode={statusCode}
      customErrorMessage={message}
    />
  );

  if (loading || isDispatch) {
    return (
      <div className={classes.progressBar}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <SignInLayout
      renderAppLogo={renderAppLogo}
      renderTitle={renderWelcomeMessage}
      renderImage={renderImage}
      renderCta={renderSignInCta}
      renderCorporateLogin={renderCorporateLogin}
      renderErrorModal={renderErrorModal}
      renderFooter={renderFooter}
    ></SignInLayout>
  );
};
