import { AttributeQuestionEmoji, ShareFeedbackAttribute } from "../../../type";

export const updateEmojiAndAttributeOnClick = (
  newValue,
  emojiSelected,
  shareFeedbackData,
  setEmojiSelected,
  setShareFeedbackData
) => {
  let updatedShareFBAttribute: Array<ShareFeedbackAttribute> = [];
  let updatedEmoji: Array<AttributeQuestionEmoji> = [];
  for (let index = 0; index < newValue.length; index++) {
    const arg: AttributeQuestionEmoji = {
      attributeId: newValue[index].id,
      queEmoji: newValue[index].questions.map((que) => {
        return {
          questionId: que.id,
          emojiId: -1,
        };
      }),
    };
    const arg1: ShareFeedbackAttribute = {
      attributeId: newValue[index].id,
      attributeName: newValue[index].name,
      questions: newValue[index].questions.map((que) => {
        return {
          questionId: que.id,
          questionName: que.questionText,
          answer: "",
          scaleName: que.answerType,
          scaleVersion: que.scaleVersion,
        };
      }),
      context: "",
      suggestions: "",
      resources: "",
    };
    let idx = 0;
    for (idx = 0; idx < shareFeedbackData.length; idx++) {
      if (shareFeedbackData[idx].attributeId === newValue[index].id) {
        break;
      }
    }
    if (idx === shareFeedbackData.length) {
      updatedEmoji.push(arg);
      updatedShareFBAttribute.push(arg1);
    } else {
      updatedEmoji.push(emojiSelected[idx]);
      updatedShareFBAttribute.push(shareFeedbackData[idx]);
    }
  }
  setEmojiSelected(updatedEmoji);
  setShareFeedbackData(updatedShareFBAttribute);
};

export const updateEmojiAndAttributeWithDraftData = (
  newValue,
  emojiSelected,
  shareFeedbackData,
  setEmojiSelected,
  setShareFeedbackData,
  draft,
  setSelectedDraftId,
  responseScales
) => {
  let updatedShareFBAttribute: Array<ShareFeedbackAttribute> = [];
  let updatedEmoji: Array<AttributeQuestionEmoji> = [];
  for (let index = 0; index < newValue.length; index++) {
    const arg: AttributeQuestionEmoji = {
      attributeId: newValue[index].id,
      queEmoji: newValue[index].questions.map((que, idx) => {
        let id = -1;
        const currentScale = responseScales.find(
          (scale) => scale.scaleName === que.answerType
        );
        currentScale?.scaleResponses.map((e, idx1) => {
          if (e.url === draft[index]?.questions[idx]?.answer) {
            id = idx1;
          }
        });
        return {
          questionId: que.id,
          emojiId: id,
        };
      }),
    };
    const arg1: ShareFeedbackAttribute = {
      attributeId: newValue[index].id,
      attributeName: newValue[index].name,
      questions: newValue[index].questions.map((que, idx) => {
        return {
          questionId: que.id,
          questionName: que.questionText,
          answer: draft[index]?.questions[idx]?.answer || "",
          scaleName: que.answerType,
          scaleVersion: que.scaleVersion,
        };
      }),
      context: draft[index]?.context,
      suggestions: draft[index]?.suggestions,
      resources: draft[index]?.resources,
    };
    let idx = 0;
    for (idx = 0; idx < shareFeedbackData.length; idx++) {
      if (shareFeedbackData[idx].attributeId === newValue[index].id) {
        break;
      }
    }
    if (idx === shareFeedbackData.length) {
      updatedEmoji.push(arg);
      updatedShareFBAttribute.push(arg1);
    } else {
      updatedEmoji.push(emojiSelected[idx]);
      updatedShareFBAttribute.push(shareFeedbackData[idx]);
    }
  }
  if (newValue.length === draft.length && shareFeedbackData.length === 0) {
    setEmojiSelected(updatedEmoji);
    setShareFeedbackData(updatedShareFBAttribute);
  }
  setSelectedDraftId(0);
};

export const handleClickChip =
  (
    index,
    idx,
    idx_1,
    emojiSelected,
    setEmojiSelected,
    shareFeedbackData,
    setShareFeedbackData,
    responseScale
  ) =>
  (e) => {
    let newEmoj = [...emojiSelected];
    newEmoj[idx].queEmoji[idx_1].emojiId = index;
    setEmojiSelected(newEmoj);

    let newData = [...shareFeedbackData];
    newData[idx].questions[idx_1].answer = responseScale[index].url;
    setShareFeedbackData(newData);
  };

export const handleOnChange =
  (index, shareFeedbackData, setShareFeedbackData) => (e) => {
    let newData = [...shareFeedbackData];
    newData[index][e.target.id] = e.target.value;
    setShareFeedbackData(newData);
  };

export const handleAnswer =
  (idx, idx_1, shareFeedbackData, setShareFeedbackData) => (e) => {
    let newData = [...shareFeedbackData];
    newData[idx].questions[idx_1].answer = e.target.value;
    setShareFeedbackData(newData);
  };

export const handleSkip = (
  idx,
  idx_1,
  shareFeedbackData,
  setShareFeedbackData
) => {
  let newData = [...shareFeedbackData];
  if (newData[idx].questions[idx_1].answer !== "Skipped")
    newData[idx].questions[idx_1].answer = "Skipped";
  else newData[idx].questions[idx_1].answer = "";
  setShareFeedbackData(newData);
};
