import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { WorkspaceSetupLayout } from "../../../layouts/onboarding/workspace/Layout";
import { State } from "../../../type";
import { AppLogoComponent } from "./AppLogo";
import { SetupForm } from "./Form";
import jwt_decode from "jwt-decode";
import { TokenDataProps } from "../../home/Drawer";
import { useHistory } from "react-router-dom";

export const WorkspaceSetup = (props) => {
  const [logout, setLogout] = React.useState<boolean>(false);
  const token = useSelector((state: State) => state.auth.token);

  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    status: "ACTIVE",
  };

  try {
    tokenData = jwt_decode(token);
  } catch (err) {
    console.error((err as Error).message);
  }

  const orgUpdateStatus = useSelector(
    (state: State) => state.updateOrgData.status
  );
  const addTeamStatus = useSelector(
    (state: State) => state.addTeamMembers.status
  );

  const history = useHistory();
  const statusCode: number | null =
    orgUpdateStatus !== 200
      ? orgUpdateStatus
      : addTeamStatus !== 201
      ? addTeamStatus
      : null;

  const AppLogo = () => <AppLogoComponent />;
  const renderSetupForm = () => <SetupForm />;
  const renderErrorComponent = () => (
    <CommonFunctionalityComponent
      props={props}
      statusCode={statusCode}
      logout={logout}
      setLogout={setLogout}
    />
  );

  if (
    (orgUpdateStatus === 200 && addTeamStatus === 201) ||
    tokenData.status === "ACTIVE"
  ) {
    history.push({
      pathname: "/home",
      state: {
        setupCompleted: true
      }
    });
  }

  return (
    <WorkspaceSetupLayout
      renderAppLogo={AppLogo}
      renderSetupForm={renderSetupForm}
      renderErrorModal={renderErrorComponent}
    ></WorkspaceSetupLayout>
  );
};
