import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { avatarColor } from "../../../constants/avatarBackgroundColor";
import theme from "../../../../theme";
import {
  ReceivedFeedbackSlice,
  ReceivedRequest,
  SendRequest,
  State,
} from "../../../../type";
import { getDateInDesiredFormat } from "../../../../util/GetDateInDesiredFormat";
import { TokenDataProps } from "../../../home/Drawer";
import { SharedAssessmentListData } from "../../../user/self_assessment/type";
import { SharedFeedback } from "../../../user/shared_feedback/typeSharedFeedbacks";
import { ListContentProps } from "../type";
import jwt_decode from "jwt-decode";

const useStyle = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1.25, 0, 1.25, 0),
    alignItems: "center",
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
  },
  basicStyle: {
    fontSize: theme.spacing(1.75),
    fontFamily: "Montserrat",
    wordBreak: "break-word",
  },
  dateNameStyle: {
    fontWeight: 500,
    color: "#272727",
  },
  action: {
    textTransform: "none",
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    color: "#868686",
    marginRight: theme.spacing(5.5),
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(175,175,175,0.5)",
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(1.25, 4, 1.25, 4),
    "&:hover": {
      backgroundColor: "#7C69EC",
      color: "#FFFFFF",
    },
  },
  avatar: {
    height: theme.spacing(3.75),
    width: theme.spacing(3.75),
    margin: theme.spacing(0, 0, 0, 5),
  },
  nameAlignMent: {
    textAlign: "start",
    marginLeft: theme.spacing(-1.25),
  },
  dateAlignMent: {
    textAlign: "start",
  },
  dateAlignMent2: {
    marginLeft: theme.spacing(5),
  },
  noRecordStyle: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
    fontFamily: "Montserrat",
    color: "#A0A0A0",
    margin: theme.spacing(20, 0, 20, 0),
  },
}));

export const ListContent: React.FC<ListContentProps> = ({
  tabValue,
  setFeedbackOrAssessmentId,
}) => {
  const classes = useStyle();

  const receivedFeedbackData: Array<ReceivedFeedbackSlice> = [];
  const feedbackData =
    useSelector(
      (state: State) => state.receivedFeedbackListByMember.feedbacks
    ) ?? receivedFeedbackData;
  const feedbacks: Array<SharedFeedback> =
    useSelector((state: State) => state.sharedFeedbackListByMember.data) ?? [];

  const emptyRequestArrray: Array<ReceivedRequest> = [];
  const requests: Array<ReceivedRequest> =
    useSelector((state: State) => state.allReceivedRequestsByMember.data) ??
    emptyRequestArrray;

  const emptyRequestArrray1: Array<SendRequest> = [];
  const sentRequests: Array<SendRequest> =
    useSelector((state: State) => state.allSendRequestsByMember.data) ??
    emptyRequestArrray1;

  const emptyArray: Array<SharedAssessmentListData> = [];
  const assessments =
    useSelector((state: State) => state.sharedAssessmentListByMember.data) ??
    emptyArray;

  const loading: Boolean = useSelector(
    (state: State) =>
      state.receivedFeedbackListByMember.loading ||
      state.sharedFeedbackListByMember.loading ||
      state.sharedAssessmentListByMember.loading ||
      state.allReceivedRequestsByMember.loading ||
      state.allSendRequestsByMember.loading
  );
  const token: string = useSelector((state: State) => state.auth.token);
  let tokenData: TokenDataProps = {
    role: "Regular",
    exp: 0,
    iat: 0,
    sub: 0,
    org_id: 0,
  };
  try {
    tokenData = jwt_decode(token);
  } catch (e) {
    console.log(e);
  }

  return (
    <>
      {tabValue === 0 &&
        feedbackData.length > 0 &&
        feedbackData.map((data, id) => (
          <Grid
            xs={12}
            item
            container
            className={classes.grid}
            key={`list-${id}`}
          >
            <Grid xs={4} item container alignItems="center">
              <Grid item xs={2}>
                <Avatar
                  alt={
                    data.user?.organizationId === tokenData?.org_id ? "E" : "C"
                  }
                  src={
                    data.user?.organizationId === tokenData?.org_id
                      ? "/EmployeeIcon.svg"
                      : "/ClientIcon.svg"
                  }
                  className={classes.avatar}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: theme.spacing(0, 0, 0, 2),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Avatar
                  alt={data.user?.firstName.toUpperCase()}
                  src={data.user?.imageUrl}
                  className={classes.avatar}
                  style={
                    data.user?.firstName === data.user?.imageUrl
                      ? {
                          margin: theme.spacing(0, 0, 0, 1),
                          backgroundColor:
                            avatarColor[Math.floor(Math.random() * 10)],
                          color: "#FFFFFF",
                        }
                      : { margin: theme.spacing(0, 0, 0, 1) }
                  }
                />
              </Grid>
              <Grid xs={8} item>
                <Typography
                  className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.nameAlignMent}`}
                >
                  {`${data.user.firstName} ${data.user.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={2} item>
              <Typography
                className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.dateAlignMent}`}
              >
                {getDateInDesiredFormat(new Date(data.createdAt))}
              </Typography>
            </Grid>
            <Grid xs={6} item container>
              <Grid xs={9} item></Grid>
              <Grid xs={3} item>
                <Button
                  onClick={() => setFeedbackOrAssessmentId(data.feedbackId)}
                  className={classes.action}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      {tabValue === 1 &&
        feedbacks.length > 0 &&
        feedbacks.map((data, id) => (
          <Grid
            xs={12}
            item
            container
            className={classes.grid}
            key={`shared-data-${id}`}
          >
            <Grid xs={4} item container alignItems="center">
              <Grid xs={3} item>
                <Avatar
                  src={data.user.imageUrl}
                  alt={data.user.firstName.toUpperCase()}
                  className={classes.avatar}
                />
              </Grid>
              <Grid xs={9} item>
                <Typography
                  className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.nameAlignMent}`}
                >
                  {`${data.user.firstName} ${data.user.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={2} item>
              <Typography
                className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.dateAlignMent}`}
              >
                {getDateInDesiredFormat(new Date(data.createdAt))}
              </Typography>
            </Grid>
            <Grid xs={6} item container>
              <Grid xs={9} item></Grid>
              <Grid xs={3} item>
                <Button
                  onClick={() => setFeedbackOrAssessmentId(data.feedbackId)}
                  className={classes.action}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      {tabValue === 2 &&
        requests[0] &&
        requests.map((req, idx) => (
          <Grid
            xs={12}
            item
            container
            className={classes.grid}
            key={`req-received-list-${idx}`}
          >
            <Grid xs={4} item container alignItems="center">
              <Grid xs={3} item>
                <Avatar
                  src={req.requestingUser.imageUrl}
                  alt={req.requestingUser.firstName.toUpperCase()}
                  className={classes.avatar}
                />
              </Grid>
              <Grid xs={9} item>
                <Typography
                  className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.nameAlignMent}`}
                >
                  {`${req.requestingUser.firstName} ${req.requestingUser.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={2} item>
              <Typography
                className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.dateAlignMent}`}
              >
                {getDateInDesiredFormat(new Date(req.timeOfRequest))}
              </Typography>
            </Grid>
            <Grid xs={6} item container></Grid>
          </Grid>
        ))}
      {tabValue === 3 &&
        sentRequests[0] &&
        sentRequests.map((req, idx) => (
          <Grid
            xs={12}
            item
            container
            className={classes.grid}
            key={`req-sent-list-${idx}`}
          >
            <Grid xs={4} item container alignItems="center">
              <Grid item xs={2}>
                <Avatar
                  alt={
                    req.respondingUser?.organizationId === tokenData.org_id
                      ? "E"
                      : "C"
                  }
                  src={
                    req.respondingUser?.organizationId === tokenData.org_id
                      ? "/EmployeeIcon.svg"
                      : "/ClientIcon.svg"
                  }
                  className={classes.avatar}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: theme.spacing(0, 0, 0, 2),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Avatar
                  alt={req.respondingUser?.firstName.toUpperCase()}
                  src={req.respondingUser?.imageUrl}
                  className={classes.avatar}
                  style={
                    req.respondingUser?.firstName ===
                    req.respondingUser?.imageUrl
                      ? {
                          margin: theme.spacing(0, 0, 0, 1),
                          backgroundColor:
                            avatarColor[Math.floor(Math.random() * 10)],
                          color: "#FFFFFF",
                        }
                      : { margin: theme.spacing(0, 0, 0, 1) }
                  }
                />
              </Grid>
              <Grid xs={8} item>
                <Typography
                  className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.nameAlignMent}`}
                >
                  {`${req.respondingUser?.firstName} ${req.respondingUser?.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={2} item>
              <Typography
                className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.dateAlignMent}`}
              >
                {getDateInDesiredFormat(new Date(req.timeOfRequest))}
              </Typography>
            </Grid>
            <Grid xs={6} item container></Grid>
          </Grid>
        ))}
      {tabValue === 4 &&
        assessments[0] &&
        assessments.map((data, idx) => (
          <Grid
            xs={12}
            item
            container
            className={classes.grid}
            key={`assessment-list-${idx}`}
          >
            <Grid xs={4} item container alignItems="center">
              <Typography
                className={`${classes.basicStyle} ${classes.dateNameStyle} ${classes.dateAlignMent2}`}
              >
                {getDateInDesiredFormat(new Date(data.sharedDate))}
              </Typography>
            </Grid>
            <Grid xs={2} item></Grid>
            <Grid xs={6} item container>
              <Grid xs={9} item></Grid>
              <Grid xs={3} item>
                <Button
                  onClick={() => setFeedbackOrAssessmentId(data.assessmentId)}
                  className={classes.action}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      {((tabValue === 0 && feedbackData.length === 0) ||
        (tabValue === 1 && feedbacks.length === 0) ||
        (tabValue === 2 && requests.length === 0) ||
        (tabValue === 3 && sentRequests.length === 0) ||
        (tabValue === 4 && assessments.length === 0)) && (
        <Grid xs={12} item container justify="center" alignItems="center">
          {!loading && (
            <Typography className={classes.noRecordStyle}>
              No record!
            </Typography>
          )}
          {loading && <CircularProgress className={classes.noRecordStyle} />}
        </Grid>
      )}
    </>
  );
};
