import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AdminDashboardLayout } from "../../../layouts/admin/dashboard/Layout";
import { getRecentActivities } from "../../../redux/slices/RecentActivitiesSlice";
import {
  NudgeCountSliceProps,
  NudgeSliceProps,
  RecentActivitiesFetchedData,
  RecentActivitySliceProp,
  SharedFeedbacksParams,
  State,
  User,
} from "../../../type";
import { RecentActivityComponent } from "./RecentActivities";
import { NudgeBoard } from "./NudgeBoard";
import { LeaderBoardComponent } from "./LeaderBoard";
import { getLeaderWithFeedbackCount } from "../../../redux/slices/LeaderFeedbackSlice";
import {
  DateProps,
  FeedbackTrendDataProps,
  LeaderBoardDataProps,
} from "./type";
import { getDateInLeaderBoardFormat } from "../../../util/GetDateInDesiredFormat";
import { getFeedbackTrendData } from "../../../redux/slices/FeedbackTrendDataSlice";
import { FeedbackTrendComponent } from "./FeedbackTrend";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { getNudgeList } from "../../../redux/slices/NudgeListSlice";
import { MESSAGES } from "./Helper";
import { MESSAGE_TYPE, MessageObject } from "../../components/ToastMessage";
import { HttpStatus } from "../../../enums/httpStatus";
import { countNudge } from "../../../redux/slices/NudgeCountSLice";
import { COUNT } from "./Helper";

export const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.auth.token);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [recentActivityTab, setRecentActivityTab] = React.useState<number>(0);
  const [feedbackTag, setFeedbackTag] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<number>(0);
  const [feedbackId, setFeedbackId] = React.useState<number>(0);
  const [assessmentId, setAssessmentId] = React.useState<number>(0);
  const [member, setMemeber] = React.useState<User>();
  const [viewProfile, setViewProfile] = React.useState<Boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<Boolean>(false);
  const [nudgeSuccessMsg, setNudgeSuccessMsg] = React.useState<MessageObject>();
  const [nudgeCtaSubmitted, setNudgeCtaSubmitted] =
    React.useState<boolean>(false);

  const [dateRangeState, setDateRangeState] = React.useState<DateProps>({
    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [trendDateRangeState, setTrendDateRangeState] =
    React.useState<DateProps>({
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    });

  const [activityDateRangeState, setActivityDateRangeState] =
    React.useState<DateProps>({
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    });

  const [nudgeDateRangeState, setNudgeDateRangeState] =
    React.useState<DateProps>({
      startDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    });
  const totalNudgeCount = useSelector(
    (state: State) => state.adminNudgeCountData.count
  );
  const pageNo = props.location.state?.pageNumber;
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageNo ? pageNo : 1
  );

  const nudgeLoading: Boolean = useSelector(
    (state: State) => state.adminNudgeData.loading
  );

  useEffect(() => {
    let str = "";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let startDate = getDateInLeaderBoardFormat(nudgeDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(nudgeDateRangeState.endDate);
    str += `&startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`;
    const param: NudgeCountSliceProps = {
      requestingParams: str,
    };
    dispatch(countNudge(param));
  }, [nudgeDateRangeState]);

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.recentActivities.status !== 200
      ? state.recentActivities.status
      : state.appraisals.status !== 200
        ? state.appraisals.status
        : state.leadersWithFeedbackCount.status !== 200
          ? state.leadersWithFeedbackCount.status
          : state.feedbackTrend.status !== 200
            ? state.feedbackTrend.status
            : null
  );

  const {
    status: nudgeSendNotificationStatus,
    loading: sendingNudgeNotification,
  } = useSelector((state: State) => state.adminNudgeSendNotification);

  useEffect(() => {
    let str = "";
    let startDate = getDateInLeaderBoardFormat(
      activityDateRangeState.startDate
    );
    let endDate = getDateInLeaderBoardFormat(activityDateRangeState.endDate);
    str += `&startDate=${startDate}&endDate=${endDate}`;
    const param: RecentActivitySliceProp = {
      activity:
        recentActivityTab === 0
          ? "ALL"
          : recentActivityTab === 1
            ? "FEEDBACK_SHARED"
            : recentActivityTab === 2
              ? "FEEDBACK_REQUESTED"
              : recentActivityTab === 3
                ? "USER_ONBOARDED"
                : "SELF_ASSESSMENT_UPDATED",
      requestingParams: str,
    };
    dispatch(getRecentActivities(param));
  }, [recentActivityTab, activityDateRangeState]);

  const fetchData = () => {
    let str = "";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let startDate = getDateInLeaderBoardFormat(nudgeDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(nudgeDateRangeState.endDate);
    str += `&startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`;
    const param: NudgeSliceProps = {
      requestingParams: str,
      offSet: (pageNumber - 1) * COUNT,
      count: COUNT,
    };
    dispatch(getNudgeList(param));
  };

  useEffect(() => {
    fetchData();
  }, [nudgeDateRangeState, pageNumber]);

  useEffect(() => {
    if (nudgeCtaSubmitted && !sendingNudgeNotification) {
      setShowSuccess(true);
      if (nudgeSendNotificationStatus === HttpStatus.OK) {
        setNudgeSuccessMsg({
          message: MESSAGES.NUDGE_SUCCESS,
          type: MESSAGE_TYPE.SUCCESS,
        });
      } else {
        setNudgeSuccessMsg({
          message: MESSAGES.NUDGE_FAIL,
          type: MESSAGE_TYPE.INFO,
        });
      }
      setNudgeCtaSubmitted(false);
      // refresh the nudge user list to get the latest nudge timestamps
      fetchData();
    }
  }, [sendingNudgeNotification]);

  useEffect(() => {
    let str = "";
    let startDate = getDateInLeaderBoardFormat(dateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(dateRangeState.endDate);
    if (feedbackTag === 0) str += "?tag=SHARED";
    else str += "?tag=RECEIVED";
    str += `&startDate=${startDate}&endDate=${endDate}`;
    const param: SharedFeedbacksParams = {
      requestingParams: str,
    };
    dispatch(getLeaderWithFeedbackCount(param));
  }, [token, dispatch, feedbackTag, dateRangeState]);

  useEffect(() => {
    let str = "";
    let startDate = getDateInLeaderBoardFormat(trendDateRangeState.startDate);
    let endDate = getDateInLeaderBoardFormat(trendDateRangeState.endDate);
    if (filter === 0) str += "?by=DAY";
    else str += "?by=MONTH";
    str += `&startDate=${startDate}&endDate=${endDate}`;
    const param: SharedFeedbacksParams = {
      requestingParams: str,
    };
    dispatch(getFeedbackTrendData(param));
  }, [trendDateRangeState, filter]);

  const { loading, data }: RecentActivitiesFetchedData = useSelector(
    (state: State) => state.recentActivities
  );

  const leaderLoading: Boolean = useSelector(
    (state: State) => state.leadersWithFeedbackCount.loading
  );

  const leaderData: Array<LeaderBoardDataProps> = useSelector(
    (state: State) => state.leadersWithFeedbackCount.data
  );

  const trendLoading: Boolean = useSelector(
    (state: State) => state.feedbackTrend.loading
  );

  const trendData: Array<FeedbackTrendDataProps> = useSelector(
    (state: State) => state.feedbackTrend.data
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderFeedbackTrend = () => (
    <FeedbackTrendComponent
      data={trendData}
      loading={trendLoading}
      dateRangeState={trendDateRangeState}
      setDateRangeState={setTrendDateRangeState}
      filter={filter}
      setFilter={setFilter}
    />
  );

  const renderLeaderboard = () => (
    <LeaderBoardComponent
      data={leaderData}
      feedbackTag={feedbackTag}
      setFeedbackTag={setFeedbackTag}
      loading={leaderLoading}
      dateRangeState={dateRangeState}
      setDateRangeState={setDateRangeState}
    ></LeaderBoardComponent>
  );

  const renderRecentActivities = () => (
    <RecentActivityComponent
      data={data}
      recentActivityTab={recentActivityTab}
      setRecentActivityTab={setRecentActivityTab}
      loading={loading}
      setFeedbackId={setFeedbackId}
      setAssessmentId={setAssessmentId}
      setMember={setMemeber}
      activityDateRangeState={activityDateRangeState}
      setActivityDateRangeState={setActivityDateRangeState}
    ></RecentActivityComponent>
  );

  const renderNudgeBoard = () => (
    <NudgeBoard
      dateRangeState={nudgeDateRangeState}
      loading={nudgeLoading}
      setDateRangeState={setNudgeDateRangeState}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      setNudgeCtaSubmitted={setNudgeCtaSubmitted}
      totalCount={totalNudgeCount}
    ></NudgeBoard>
  );

  if (feedbackId > 0) {
    history.push({
      pathname: `/admin/detail/view`,
      state: {
        feedbackOrAssessmentId: feedbackId,
        memberId: member?.id,
        tabValue: 1,
        member: member,
        pageNumber: 1,
        itemValue: 1,
        from: "dashboard",
      },
    });
  }

  if (assessmentId > 0) {
    history.push({
      pathname: `/admin/detail/view`,
      state: {
        feedbackOrAssessmentId: assessmentId,
        memberId: member?.id,
        tabValue: 4,
        member: member,
        pageNumber: 1,
        itemValue: 1,
        from: "dashboard",
      },
    });
  }

  if (viewProfile) {
    history.push({
      pathname: `/admin/member/detail/${member?.id}`,
      state: {
        member: member,
        tabValue: 0,
        pageNumber: 1,
        itemValue: 1,
        from: "dashboard",
      },
    });
  }

  const handleCancel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
    setNudgeSuccessMsg({ message: "" });
  };
  return (
    <AdminDashboardLayout
      props={props}
      loading={false}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderRecentActivities={renderRecentActivities}
      renderNudgeBoard={renderNudgeBoard}
      renderLeaderboard={renderLeaderboard}
      renderFeedbackTrend={renderFeedbackTrend}
      showSuccess={showSuccess}
      handleClose={handleCancel}
      nudgeSuccessMsg={nudgeSuccessMsg}
    ></AdminDashboardLayout>
  );
};
