import { Box, Button, Divider, Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import theme from "../../../../theme";
import { ModalProps } from "../../../../type";
import {
  RequestAnotherText,
  RequestSuccessfulText,
  ReturnDashboardText,
  ThankYouText,
} from "./popUpText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100 100 100 100 ",
      margin: theme.spacing(10, 10, 10, 10),
    },
    root: {
      background: "white",
      display: "flex,",
    },
    img: {
      display: "flex",
      padding: theme.spacing(5, 0, 5, 0),
    },
    btn: {
      margin: theme.spacing(0, 2, 2, 2),
      padding: theme.spacing(2, 2, 2, 2),
      minWidth: "200px",
    },
  })
);

export const ConsentModal: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  setValue,
  to,
}) => {
  const classes = useStyles();

  function handleClose(e) {
    e.preventDefault();
    setShowModal(false);
    if (to === "SHARE_FEEDBACK") setValue(1);
  }

  function handleReturnToDashboard(e) {
    e.preventDefault();
    setShowModal(false);
    setValue(0);
  }
  return (
    <Modal
      className={classes.modal}
      open={showModal}
      disablePortal={true}
      id={to}
    >
      <Grid className={classes.root}>
        <Box display="flex" justifyContent="center">
          <LazyLoadImage
            alt="Consent_Image"
            src="/Consent.svg"
            className={classes.img}
            effect="blur"
          />
        </Box>
        <Grid item xs={12}>
          <ThankYouText />
        </Grid>
        <Grid item xs={12}>
          <RequestSuccessfulText from={to} />
        </Grid>
        <Divider />
        <div
          style={
            to === "SELF_ASSESSMENT"
              ? { padding: theme.spacing(3, 15) }
              : { padding: theme.spacing(3, 5) }
          }
        >
          {to !== "SELF_ASSESSMENT" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              className={classes.btn}
            >
              <RequestAnotherText from={to} />
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleReturnToDashboard}
            className={classes.btn}
          >
            <ReturnDashboardText />
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};
