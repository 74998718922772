import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { HeaderDisplayProps, NavValueProps, State } from "../../type";
import { getTimeInDesiredFormat } from "../../util/GetDateInActitivityFormat";
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { markShareFeedbackTourDone, markMyTeamsTourDone } from "../../redux/slices/CoachMarkSlice";
import "../../App.css";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography_h2: {
      color: "#272727",
      padding: theme.spacing(4, 0, 4, 7),
      margin: theme.spacing(0, 0, 0, -5),
      fontSize: theme.spacing(3),
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(4, 0, 4, 2),
        margin: theme.spacing(0, 0, 0, 0),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4, 0),
        margin: theme.spacing(0),
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.75),
        padding: theme.spacing(0),
        margin: theme.spacing(0),
      },
    },
    typography_body_black: {
      color: "#313030",
      textTransform: "none",
      textAlign: "start",
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.5),
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    typography_body_white: {
      textTransform: "none",
      textAlign: "start",
      fontWeight: "bold",
      fontSize: theme.spacing(1.75),
      paddingLeft: theme.spacing(2.25),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.5),
        paddingLeft: theme.spacing(2.25),
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
        paddingLeft: theme.spacing(1),
      },
    },
    activeUserTabFontColor: {
      color: "#576BEA",
    },
    activeAdminTabFontColor: {
      color: "#7C69EC",
    },
    typograph_saving: {
      margin: theme.spacing(0, 0, 0.35, 0.75),
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      color: "#A0A0A0",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
        margin: theme.spacing(0, 0, 0.25, 0),
      },
    },
    typograph_saved: {
      margin: theme.spacing(0, 0, 0.35, 0.75),
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      color: "#2C8100",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
        margin: theme.spacing(0, 0, 0.25, 0),
      },
    },
    typograph_error: {
      margin: theme.spacing(0, 0, 0.35, 0.75),
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      color: "#F04D4D",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
        margin: theme.spacing(0, 0, 0.25, 0),
      },
    },
    btn_: {
      textTransform: "none",
      border: "1px solid #576BEA",
      color: "#576BEA",
      backgroundColor: "#FFFFFF",
      fontFamily: "Montserrat",
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#576BEA",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    btn_Hover: {
      textTransform: "none",
      border: "1px solid #576BEA",
      color: "#FFFFFF",
      backgroundColor: "#576BEA",
      "&:hover": {
        backgroundColor: "#576BEA",
      },
      fontFamily: "Montserrat",
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    saveIcon: {
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(1),
        width: theme.spacing(1),
        margin: theme.spacing(0, 0, 0.25, 0),
      },
    },
  })
);
interface SideLabelTextProp {
  idx: number;
  value: number;
  name: string;
}

export const SideLabelText: React.FC<SideLabelTextProp> = ({
  idx,
  value,
  name,
}) => {
  const classes = useStyles();
  const [showTour, setShowTour] = useState(false);
  const [showTeamsTour, setShowTeamsTour] = useState(false);
  const shareFeedbackButtonTourStatus = useSelector((state: State) => state.coachMarks.dashboardTour.shareFeedbackButtonTour);
  const myTeamsButtonTourStatus = useSelector((state: State) => state.coachMarks.dashboardTour.myTeamsButtonTour);
  const dashboardVisitCount = useSelector((state: State) => state.coachMarks.dashboardTour.dashboardVisitCount);
  const dispatch = useDispatch();
  const tourRef = useRef<Shepherd.Tour | null>(null);
  const history = useHistory();
  const isElementInViewport = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };


   
  useEffect(() => {
    if (shareFeedbackButtonTourStatus === "PENDING" && dashboardVisitCount === 1) {
      setShowTour(true);
      dispatch(markShareFeedbackTourDone()); 
    } else {
      setShowTour(false);
    }
  }, [shareFeedbackButtonTourStatus, dashboardVisitCount]);
  
  useEffect(() => {
    if (showTour) {
      tourRef.current = new Shepherd.Tour({
        defaultStepOptions: {
          classes: 'custom-class-sm',
          scrollTo: { behavior: 'smooth', block: 'center' },
        }
      });
  
      const attachToOptions = {
        element: '.share-feedback-button',
        on: 'right'
      };
  
      if (window.innerWidth <= 767) {
        attachToOptions.on = 'bottom';
      }
  
      tourRef.current.addSteps([
        // Tour steps
        {
          text: 'Share feedback with your peers from here',
          attachTo: attachToOptions,
          id: 'step2',
          scrollTo: true,
          when: {
            show: () => {
              setTimeout(() => {
                tourRef.current?.next();
              }, 5000);
            }
          },
          classes: 'shared-feedback-coachmark'
        }
      ]);
  
      tourRef.current.start();
    }
  }, [showTour]);

  useEffect(() => {
    const myTeamsButton = document.querySelector('.my-teams-button');
    const isMyTeamsButtonVisible = isElementInViewport(myTeamsButton);

    if (myTeamsButtonTourStatus === "PENDING" && dashboardVisitCount === 3 && isMyTeamsButtonVisible) {
      setShowTeamsTour(true);
      dispatch(markMyTeamsTourDone()); 
    } else {
      setShowTeamsTour(false);
    }
  }, [myTeamsButtonTourStatus, dashboardVisitCount]);
  
  useEffect(() => {
    if (showTeamsTour) {
      
      tourRef.current = new Shepherd.Tour({
        defaultStepOptions: {
          classes: 'custom-class-sm',
          scrollTo: { behavior: 'smooth', block: 'center' },
        }
      });
  
      const attachToOptions = {
        element: '.my-teams-button',
        on: 'right'
      };
  
      if (window.innerWidth <= 767) {
        attachToOptions.on = 'bottom';
      }
  
      tourRef.current.addSteps([
        {
          text: 'You can manage feedback for your teams from here',
          attachTo: attachToOptions,
          id: 'step5',
          scrollTo: true,
          when: {
            show: () => {
              setTimeout(() => {
                tourRef.current?.next();
              }, 5000);
            }
          },
          classes: 'my-teams-coachmark'
        }
      ]);
  
      tourRef.current.start();
    }
  }, [showTeamsTour]);

  useEffect(() => {
    return history.listen(() => {
      if (tourRef.current) {
        tourRef.current?.cancel();
      }
    });
  }, [history]);

  return (
    <Typography
      variant="h5"
      className={
        value === idx
          ? `${classes.typography_body_white} ${classes.activeUserTabFontColor}`
          : `${classes.typography_body_black} ${name === 'Share Feedback' ? 'share-feedback-button' : ''} ${name === 'My Teams' ? 'my-teams-button' : ''}`
      }
    >
      {name}
    </Typography>
  );
};

export const SideLabelAdminDashboardText: React.FC<NavValueProps> = ({
  value,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center">
      {value === 0 ? (
        <img
          src="/DashboardHIcon.svg"
          alt="dashboard_icon"
          style={{ marginLeft: "12px" }}
        />
      ) : (
        <img src="/DashboardIcon.svg" alt="dashboard_icon" />
      )}
      <Typography
        variant="h5"
        className={
          value === 0
            ? `${classes.typography_body_white} ${classes.activeAdminTabFontColor}`
            : classes.typography_body_black
        }
        style={{ paddingLeft: "8px" }}
      >
        Dashboard
      </Typography>
    </Grid>
  );
};

export const SideLabelAllMembersText: React.FC<NavValueProps> = ({ value }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      {value === 1 ? (
        <img
          src="/AllMembersIcon.svg"
          alt="members_icon"
          style={{ marginLeft: "12px" }}
        />
      ) : (
        <img src="/AllMembersNIcon.svg" alt="members_icon" />
      )}
      <Typography
        variant="h5"
        className={
          value === 1
            ? `${classes.typography_body_white} ${classes.activeAdminTabFontColor}`
            : classes.typography_body_black
        }
        style={{ paddingLeft: "8px" }}
      >
        All Members
      </Typography>
    </Grid>
  );
};

export const SideLabelManageTeamsText: React.FC<NavValueProps> = ({
  value,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      {value === 2 ? (
        <img
          src="/TeamsHIcon.svg"
          alt="teams_icon"
          style={{ marginLeft: "12px" }}
        />
      ) : (
        <img src="/TeamsIcon.svg" alt="teams_icon" />
      )}
      <Typography
        variant="h5"
        className={
          value === 2
            ? `${classes.typography_body_white} ${classes.activeAdminTabFontColor}`
            : classes.typography_body_black
        }
        style={{ paddingLeft: "8px" }}
      >
        Teams
      </Typography>
    </Grid>
  );
};


export const SideLabelManageQuestionsText: React.FC<NavValueProps> = ({
  value,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      {value === 3 ? (
        <img
          src="/AttributesHIcon.svg"
          alt="attributes_icon"
          style={{ marginLeft: "12px" }}
        />
      ) : (
        <img src="/AttributesIcon.svg" alt="attributes_icon" />
      )}
      <Typography
        variant="h5"
        className={
          value === 3
            ? `${classes.typography_body_white} ${classes.activeAdminTabFontColor}`
            : classes.typography_body_black
        }
        style={{ paddingLeft: "8px" }}
      >
        Attributes
      </Typography>
    </Grid>
  );
};

export const SideLabelSettings: React.FC<NavValueProps> = ({
  value,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      {value === 4 ? (
        <img
          src="/SettingsPurple.svg"
          alt="settings_icon"
          style={{ marginLeft: "12px", height: 20, padding: "0px 2px" }}
        />
      ) : (
        <img src="/Settings.svg" alt="settings_icon" style={{ height: 20, padding: "0px 2px" }} />
      )}
      <Typography
        variant="h5"
        className={
          value === 4
            ? `${classes.typography_body_white} ${classes.activeAdminTabFontColor}`
            : classes.typography_body_black
        }
        style={{ paddingLeft: "8px" }}
      >
       Settings
      </Typography>
    </Grid>
  );
};

export const HeaderDisplayText: React.FC<HeaderDisplayProps> = ({
  headerName,
}) => {
  const classes = useStyles();

  return (
    <Typography className={classes.typography_h2}>{headerName}</Typography>
  );
};

export const StatusText = ({
  value,
  setValue,
  tabValue,
  validLength,
  isDataChange,
  savedDraftDate,
  onAnyChange,
}) => {
  const classes = useStyles();
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const loading: Boolean = useSelector(
    (state: State) =>
      state.shareFeedback.loading || state.shareAssessment.loading
  );

  const status: number | null = useSelector(
    (state: State) => state.shareFeedback.status || state.shareAssessment.status
  );
  return (
    <>
      {value === 6 && loading && tabValue === 0 && validLength && (
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ margin: theme.spacing(0.25, 0, 0, 1) }}
        >
          <Grid item xs>
            <img
              src="/Shape2.svg"
              alt="saving"
              className={classes.saveIcon}
            ></img>
          </Grid>
          <Grid item xs>
            <Typography className={classes.typograph_saving}>
              Saving...
            </Typography>
          </Grid>
        </Grid>
      )}
      {value === 6 &&
        !loading &&
        status === 201 &&
        tabValue === 0 &&
        validLength && (
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ margin: theme.spacing(0.25, 0, 0, 1) }}
          >
            <Grid item xs>
              <img
                src="/Shape.svg"
                alt="saved"
                className={classes.saveIcon}
              ></img>
            </Grid>
            <Grid item xs>
              <Typography className={classes.typograph_saved}>Saved</Typography>
            </Grid>
          </Grid>
        )}
      {value === 5 &&
        !loading &&
        status !== 201 &&
        tabValue === 0 &&
        validLength && (
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="center"
            style={{ margin: theme.spacing(0.25, 0, 0, 1) }}
            justify="flex-start"
          >
            <Grid item xs={1}>
              <img
                src="/errorICON.svg"
                alt="error"
                className={classes.saveIcon}
              ></img>
            </Grid>
            <Grid
              item
              container
              xs={10}
              alignItems="center"
              direction="row"
              justify="flex-start"
            >
              <Grid item xs={11} sm={9}>
                <Typography className={classes.typograph_error}>
                  Failed to save draft
                </Typography>
              </Grid>
              <Grid item xs={1} sm={3}>
                <Button
                  onClick={() => setValue(6)}
                  variant="outlined"
                  className={classes.btn_}
                  style={{
                    padding: theme.spacing(0, 0, 0, 0),
                    marginBottom: theme.spacing(0.5),
                  }}
                >
                  Retry
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      {value === -1 && tabValue === 0 && validLength && isDataChange && (
        <Button
          startIcon={
            <img
              src={isHover ? "/draftWhite.svg" : "/draftBlue.svg"}
              alt="save"
              className={classes.saveIcon}
            />
          }
          onClick={() => {
            setValue(6);
            onAnyChange(false);
          }}
          type="submit"
          variant="outlined"
          className={isHover ? classes.btn_Hover : classes.btn_}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          Save as draft
        </Button>
      )}
      {value === -1 && tabValue === 0 && validLength && !isDataChange && (
        <Typography
          style={{
            color: "#A0A0A0",
            fontSize: "14px",
            fontWeight: 500,
            marginLeft: "16px",
          }}
        >{`last saved ${getTimeInDesiredFormat(savedDraftDate)}`}</Typography>
      )}
    </>
  );
};
