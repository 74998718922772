import { Button, Grid, Modal } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import {
  ErrorButtonText,
  ErrorMessage,
  SecondaryErrorMessage,
} from "./TextFile";
import { ErrorPopUpProps } from "./typeErrorPages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSadow: "0 2px 4px 0 #5A5A5A",
      [theme.breakpoints.down("sm")]: {
        boxShadow: "0 2px 4px 0 #5A5A5A",
      },
    },
    root: {
      height: theme.spacing(35),
      width: theme.spacing(100),
      borderRadius: theme.spacing(0.5),
      backgroundColor: "#FFFFFF",
      marginInline: "auto",
      [theme.breakpoints.down("sm")]: {
        height: theme.spacing(26),
        width: theme.spacing(36),
        borderRadius: theme.spacing(0.5),
        backgroundColor: "#FFFFFF",
      },
    },
    button: {
      backgroundColor: "#576BEA",
      "&:hover": {
        backgroundColor: "#576BEA",
      },
      margin: theme.spacing(5, 0, 5.5, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(3.5, 0, 3, 0),
      },
    },
  })
);

export const ErrorPopUp: React.FC<ErrorPopUpProps> = ({
  showModal,
  setShowModal,
  statusCode,
  handleNoAuthorization,
  handleAccessDenied,
  client,
  customErrorMessage,
}) => {
  const classes = useStyles();

  function handleClose(e) {
    e.preventDefault();
    setShowModal(false);
  }

  if (statusCode)
    return (
      <Modal
        className={classes.modal}
        open={showModal}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disablePortal={true}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item xs={12}>
            <ErrorMessage statusCode={statusCode}></ErrorMessage>
          </Grid>
          <Grid item xs={12}>
            <SecondaryErrorMessage
              statusCode={statusCode}
              client={client}
              customErrorMessage={customErrorMessage}
            ></SecondaryErrorMessage>
          </Grid>
          <Grid item>
            <Button
              onClick={
                statusCode === 401
                  ? handleNoAuthorization
                  : statusCode === 403
                  ? handleAccessDenied
                  : handleClose
              }
              className={classes.button}
            >
              <ErrorButtonText statusCode={statusCode}></ErrorButtonText>
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  return null;
};
