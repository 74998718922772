import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import updateAttributeStatusModalStyles from "../../admin/customAttributeAndQuestions/updateAttributeStatusModal/helper";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { ModalHeader } from "../Modal/ModalHeader";
import { Modal } from "../Modal/Modal";

export const DiscardContentModal = ({ open, handleCancel, handleConfirm, onClose }) => {
    const [submitted, setSubmitted] = useState(false);

    const classes = updateAttributeStatusModalStyles()

    useEffect(() => {
        if (submitted) {
            onClose();
        }
    }, [submitted])

    const handleSubmit = () => {

        setSubmitted(true);
        handleConfirm()
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalHeader title="Discard Content" showBackground={false} handleCancel={handleCancel} />
            <ModalBody>
                <Typography className={classes.text}>
                Are you sure, you want to discard changes?
                </Typography>
            </ModalBody>
            <ModalFooter buttonLabel="Confirm" handleCancel={handleCancel}  handleSubmit={handleSubmit} />
        </Modal>
    );
};