import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllAttributesData } from "./attributeSlice";
import { HttpStatus } from "../../enums/httpStatus";
import { getAttributeDetail } from "./AttributeDetailSlice";
import { patch } from "../../util/APIService";

export interface UpdateAttributeSliceProps {
  id: number;
  name?: string;
  attributeStatus?: String | undefined;
}

export const updateAttribute = createAsyncThunk(
  "admin/update/attributes",
  async (params: UpdateAttributeSliceProps, storeActions) => {
    const url = `api/v1/admin/attributes`;
    const response: any = await patch(url, params, storeActions);

    if (response.status === HttpStatus.OK) {
      storeActions.dispatch(getAllAttributesData())
      storeActions.dispatch(getAttributeDetail({ attributeId: params.id }))
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const updateAttributeSlice = createSlice({
  name: "updateAttribute",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as string | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAttribute.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(updateAttribute.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(updateAttribute.rejected, (state) => {
      state.status = HttpStatus.SERVICE_UNAVAILABLE;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = updateAttributeSlice.actions;

export default updateAttributeSlice;
