import { Avatar, Chip, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useSelector } from "react-redux";
import { AddButtonForExtProps, State, User } from "../../../../type";
import validator from "validator";
import { avatarColor } from "../../../constants/avatarBackgroundColor";
import { useCustomStyle } from "../../../../styles";

export const useStyle = makeStyles((theme) => ({
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    width: "-webkit-fill-available",
    fontSize: 13,
    "&:focus": {
      borderColor: "#576BEA !important",
    },
    "&:hover": {
      borderColor: "#576BEA !important",
    },
  },
  textField: {
    "&:focus": {
      border: "none !important",
    },
    "&:hover": {
      border: "none !important",
    },
    fontSize: theme.spacing(1.75),
    border: "none !important",
    color: "#A0A0A0",
    fontWeight: 500,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  chip: {
    margin: theme.spacing(2, 1, 1, 1),
    borderColor: "#576BEA",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: "#000000",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const AddButton: React.FC<AddButtonForExtProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  const classes = useStyle();
  const customStyle = useCustomStyle();

  const clients: Array<User> = [];
  const label: Array<User> =
    useSelector((state: State) => state.data.data?.clients) ?? clients;
  const userLables: Array<User> =
    useSelector((state: State) => state.data.data?.users) ?? clients;
  const arr = new Map();
  const userDomain = userLables[0]?.emailId.split("@")[1] ?? "technogise.com";
  label.map((l) =>
    arr.set(l.emailId, { firstName: l.firstName, lastName: l.lastName })
  );

  const labels: Array<string> = [];
  label.map((l) => labels.push(l.emailId));

  return (
    <>
      <Autocomplete
        id="tags-filled"
        className={`${classes.popper} ${customStyle.popperWidth} dashboard-autocomplete`}
        multiple
        freeSolo
        value={selectedUsers}
        getOptionSelected={(option, value) => option === value}
        onChange={(event, newValue, reason) => {
          setSelectedUsers(newValue);
        }}
        filterSelectedOptions
        disableCloseOnSelect
        renderTags={(value: string[], getTagProps) =>
          value.map((user: string, index: number) => (
            <>
              <Chip
                key={user}
                variant="outlined"
                avatar={
                  <Avatar
                    alt={user?.toUpperCase()}
                    src={arr?.get(user)?.firstName?.toUpperCase()}
                    className={classes.small}
                    style={{
                      color: "#FFFFFF",
                    }}
                  />
                }
                label={
                  <Typography
                    className={classes.typography_body_3}
                    style={
                      arr?.get(user) === undefined && !validator.isEmail(user)
                        ? { borderColor: "#DC0101" }
                        : user.includes(userDomain)
                        ? { borderColor: "#FF8850" }
                        : {}
                    }
                  >
                    {arr?.get(user)
                      ? `${arr?.get(user).firstName} ${arr?.get(user).lastName}`
                      : user}
                  </Typography>
                }
                className={classes.chip}
                style={
                  arr?.get(user) === undefined && !validator.isEmail(user)
                    ? { borderColor: "#DC0101" }
                    : user.includes(userDomain)
                    ? { borderColor: "#FF8850" }
                    : {}
                }
                {...getTagProps({ index })}
              />
            </>
          ))
        }
        options={labels}
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Avatar
              alt={option?.toUpperCase()}
              src={option?.toUpperCase()}
              className={classes.small}
              style={{
                backgroundColor: avatarColor[Math.floor(Math.random() * 10)],
                color: "#FFFFFF",
                fontWeight: 500,
              }}
            />
            <div className={classes.typography_body_3}>
              <Typography variant="body1" className={classes.typography_body_3}>
                {`${arr?.get(option).firstName} ${arr?.get(option).lastName}`}
              </Typography>
            </div>
          </React.Fragment>
        )}
        renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search / Enter client e-mail ID"
          variant="outlined"
          className={classes.textField}
          onBlurCapture={(e) => {
            if (
              !selectedUsers.find(
                (u) => u === (e.target as HTMLInputElement).value?.toLowerCase()
              ) && (e.target as HTMLInputElement).value?.length > 0
            )
              setSelectedUsers(
                selectedUsers.concat(
                  (e.target as HTMLInputElement).value?.toLowerCase()
                )
              );
          }}
        />
        )}
      />
    </>
  );
};
