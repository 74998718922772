import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AttributesDataForClient } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";

export const attributesDataForClients = createAsyncThunk(
  "client/feedback/request/attributes",
  async (attributeId: string) => {
    try {
      const options = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/external/request/attributes?requestId=${attributeId}`,
        options
      );
      if (response.status === HttpStatus.OK) {
        return {
          status: HttpStatus.OK,
          data: await response.json(),
        };
      } else {
        return {
          status: response.status,
          text: await response.text(),
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

const attributesDataForClientSlice = createSlice({
  name: "attributesDataForClient",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: {
      employee: undefined,
      attributes: [],
      request: [],
      drafts: [],
      scaleResponseEntityList: [],
      clientId: -1,
      requestId: -1,
    } as AttributesDataForClient | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attributesDataForClients.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(attributesDataForClients.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(attributesDataForClients.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = attributesDataForClientSlice.actions;

export default attributesDataForClientSlice;
