import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography_h3: {
      color: "#272727",
      marginRight: theme.spacing(2),
      fontSize: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    typography_body_1: {
      color: "#868686",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    typography_body_2: {
      color: "#DC0101",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
  })
);

export const ChooseMemberTextForShareFeedback = () => {
  const classes = useStyles();
  return (
    <Typography gutterBottom variant="h2" className={classes.typography_h3}>
      Choose Member
    </Typography>
  );
};

export const SelectColleague = ({ isValid }) => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={
        isValid === 0 ? classes.typography_body_1 : classes.typography_body_2
      }
    >
      Select a member to share feedback with
    </Typography>
  );
};

export const AddAttributeTitleText = () => {
  const classes = useStyles();
  return (
    <Typography gutterBottom variant="h2" className={classes.typography_h3}>
      Add Attributes
      
    </Typography>
  );
};

export const AddAttributeText = ({ isAnyAttribute }) => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      variant="body1"
      className={
        !isAnyAttribute ? classes.typography_body_1 : classes.typography_body_2
      }
    >
      Add attribute to share feedback on
    </Typography>
  );
};
