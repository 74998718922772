import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponseAttribute,
  State,
} from "../../../../type";
import { getAllAttributesData } from "../../../../redux/slices/attributeSlice";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import AttributeListTableItem from "./attributeListTableItem";
import { attributeListTableStyles, TITLES } from "./helper";
import ToastMessage from "../../../components/ToastMessage";
import { AttributeStatus, Messages } from "../helper";
import { HttpStatus } from "../../../../enums/httpStatus";
import { clearData as clearUpdateAttributeData } from "../../../../redux/slices/UpdateAttributeSlice";
const AttributeListTable = ({ data: attributes, pageNumber, loading }) => {
  const [message, setMessage] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [updatingAttribute, setUpdatingAttribute] = useState<boolean>(false);

  const dispatch = useDispatch();
  const classes = attributeListTableStyles();

  const { loading: updateAttributeLoading, status: updateAttributeStatus, data: updateAttributeData } = useSelector((state: State) => state.updateAttribute)

  useEffect(() => {
    if (updatingAttribute && !updateAttributeLoading && updateAttributeStatus === HttpStatus.OK) {
      const isEnabled = updateAttributeData?.attributeStatus === AttributeStatus.ACTIVE;
      setUpdatingAttribute(false);
      setMessage(isEnabled ? Messages.ENABLE_ATTRIBUTE : Messages.DISABLE_ATTRIBUTE);
      dispatch(clearUpdateAttributeData());
      setShowSuccess(true);
    }
  }, [updateAttributeLoading, updatingAttribute])

  useEffect(() => {
    if (!attributes?.length) {
      dispatch(getAllAttributesData());
    }
  }, []);

  const renderTitles = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        {TITLES.map((title) => (
          <Grid
            item
            key={title.name}
            xs={title.size}
          >
            <Typography variant="h5" className={classes.typography_body}>
              {title.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleCloseToastMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
    setMessage("");
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      className={classes.root}
    >
      <ToastMessage
        open={showSuccess}
        handleClose={handleCloseToastMessage}
        message={message}
      />
      <Paper variant="outlined" square style={{ width: "100%", position: "relative" }}>
        {renderTitles()}
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%", position: "absolute" }}
          >
            <CircularProgress />
          </Grid>
        )}
        {attributes.map((attribute: ResponseAttribute) => (
          <AttributeListTableItem
            attributeId={attribute?.id}
            attributeName={attribute?.name}
            questions={attribute?.questions}
            key={attribute?.id}
            titles={TITLES}
            status={attribute?.status}
            setUpdatingAttribute={setUpdatingAttribute}
            pageNumber={pageNumber}
          />
        ))}
      </Paper>
    </Grid>
  );
};

export default AttributeListTable;
