import { makeStyles } from "@material-ui/core";
import { appColor } from "../../../modules/constants/customThemeColor";

export const createTeamLayoutStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(11, 6, 2, 5),
        marginTop: theme.spacing(6.5),
        background: "#F5F6FA",
        height: "calc(100vh - 52px)",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(6, 4, 6, 4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(11.25),
            padding: theme.spacing(5, 3, 3, 3),
        },
    },
    label: {
        color: "#272727",
        fontSize: 18,
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        display: "flex",
        width: "100%",
        alignItems: "center",
        columnGap: theme.spacing(0.5),
    },
    divider: {
        width: "100%",
        background: "#E7E7E7",
        height: 0.5,
        marginBottom: theme.spacing(4)
    },
    footer: {
        marginTop: "auto",
    },
    saveButton: {
        height: "fit-content",
        background: "#7C69EC",
        textTransform: "uppercase",
        fontWeight: 600,
        fontFamily: "Montserrat",
        padding: "8px 40px",
        marginBottom: theme.spacing(5),
    },
    disabledButton: {
        pointerEvents: "none",
        backgroundColor: "#A0A0A0 !important",
        color: "#fff !important"
    },
    btn_: {
        margin: theme.spacing(1, 3, 0, 0),
        borderColor: `${appColor.pureWhite}`,
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(3, 3.5, 0, 0),
        },
    },
    typography_font_14_10_600: {
        textTransform: "none",
        fontWeight: 600,
        fontFamily: "Montserrat",
        fontSize: theme.spacing(1.75),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(1.25),
        },
    },
}));
