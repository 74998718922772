import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  btn1: {
    margin: theme.spacing(7.5, 0, 4.5, 0),
    padding: theme.spacing(1.5, 2.5, 1.5, 2.5),
    backgroundColor: "#576BEA",
    width: theme.spacing(30),
    height: theme.spacing(5.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
  typography_body_4: {
    alignItems: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const SubmitFeedbackButton = () => {
  const classes = useStyle();

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <Button
          type="submit"
          className={classes.btn1}
          color="primary"
          variant="contained"
        >
          <Typography variant="h5" className={classes.typography_body_4}>
            SEND FEEDBACK
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
