import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import "./extra_fonts/landingFont.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { State } from "../../../type";
import { interestSave } from "../../../redux/slices/CreateNewOrgInterest";
import "./extra_fonts/landingFont.css";

const useStyle = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  slide_1_main_text: {
    color: "#012241",
    fontSize: theme.spacing(4),
    fontWeight: 600,
    fontFamily: "EuclideCircularABold",
    margin: theme.spacing(2.5, 0),
    width: theme.spacing(79.75),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      width: `${3 * (window.innerWidth / 4)}px`,
      margin: theme.spacing(0),
    },
  },
  slide_1_sub_text: {
    color: "#012241",
    fontSize: theme.spacing(2.25),
    textAlign: "justify",
    fontWeight: 500,
    fontFamily: "Open Sans",
    width: theme.spacing(79.75),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: theme.spacing(2.5),
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  form_header: {
    color: "#FFFFFF",
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    margin: theme.spacing(5, 0, 1, 0),
    fontFamily: "EuclideCircularABold",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
    },
  },
  form_sub_header: {
    color: "#FFFFFF",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    fontFamily: "Open Sans",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
    },
  },
  submit_btn: {
    textTransform: "none",
    width: theme.spacing(15.5),
    height: theme.spacing(6.25),
    borderRadius: theme.spacing(0.5),
    color: "#012241",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(1.25, 2.5),
    marginTop: theme.spacing(5.25),
    fontFamily: "EuclideCircularAMedium",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#E7E7E7",
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(12),
      height: theme.spacing(4),
      borderRadius: theme.spacing(1.5),
      fontSize: theme.spacing(1.5),
      padding: theme.spacing(0.75, 1.25),
      margin: theme.spacing(2.25),
    },
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(9),
      height: theme.spacing(3),
    },
  },
  root: {
    fontSize: "16px",
    fontWeight: 500,
  },
  mdUpMargin: {
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(16.5),
      maxWidth: theme.spacing(70.375),
    },
  },
  mdUpFormStyle: {
    padding: theme.spacing(0, 1.875),
    height: "auto",
    margin: theme.spacing(1),
    backgroundColor: "#012241",
    boxShadow: "0 2px 8px 0 rgba(136,136,136,0.32)",
    borderRadius: theme.spacing(1.25),
    width: `${3 * (window.innerWidth / 4)}px`,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6.875),
      height: theme.spacing(69.375),
      width: theme.spacing(55.75),
    },
  },
  container: {
    margin: theme.spacing(15, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(5, 0),
    },
  },
  get_started_btn: {
    textTransform: "none",
    width: theme.spacing(19.75),
    height: theme.spacing(6.25),
    borderRadius: theme.spacing(0.5),
    color: "#FFFFFF",
    backgroundColor: "#576BEA",
    padding: theme.spacing(1.25, 2.5),
    fontFamily: "EuclideCircularARegular",
    fontSize: theme.spacing(2.25),
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#576BEA",
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(12.5),
      height: theme.spacing(4),
      borderRadius: theme.spacing(1.5),
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0.75, 1.25),
    },
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(10),
      height: theme.spacing(3),
      fontSize: theme.spacing(1),
    }
  },
}));
interface Props {}

interface FormDataProps {
  name: string;
  emailId: string;
  orgName: string;
}

export const ContactUsSlideComponent: React.FC<Props> = ({}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { loading, status } = useSelector((state: State) => state.orgInterest);

  const [formData, setFormData] = React.useState<FormDataProps>({
    name: "",
    emailId: "",
    orgName: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (
      formData.name.length > 2 &&
      formData.emailId.length > 5 &&
      formData.orgName.length > 2
    ) {
      dispatch(interestSave(formData));
    }
  }

  return (
    <div className={classes.parallax} style={{ backgroundImage: "#FFFFFF" }}>
      <Grid
        xs={12}
        item
        container
        className={classes.container}
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.mdUpMargin}>
          <Typography className={classes.slide_1_main_text}>
            {"You were born ready."}
          </Typography>
          <Typography className={classes.slide_1_sub_text}>
            {
              "Your business deserves our solution, so why choose to stay in the dark any longer? Reach out to us today and let’s discuss how our 360-degree feedback system can transform the way you help your team members become the hardest-working versions of themselves!"
            }
          </Typography>
          <Button
            className={classes.get_started_btn}
          >{`Get started >`}</Button>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            justify="center"
            className={classes.mdUpFormStyle}
          >
            <form onSubmit={handleSubmit} id="connect-with-us">
              {!loading && status === null && (
                <Grid item xs={12} container justify="center">
                  <Typography className={classes.form_header}>
                    {"Connect with us"}
                  </Typography>
                  <Typography className={classes.form_sub_header}>
                    {
                      "Fill in the form and we will get back to you as early as we can."
                    }
                  </Typography>

                  <FormControl
                    error
                    variant="standard"
                    style={{ width: "-webkit-fill-available", height: "80px" }}
                  >
                    <InputLabel
                      htmlFor="component-error"
                      error={
                        formData.name.length < 3 && formData.name.length > 0
                      }
                      style={{
                        fontSize: "18px",
                        fontFamily: "EuclideCircularARegular",
                        fontWeight: 500,
                        color: "#9FA8B7",
                      }}
                    >
                      Name
                    </InputLabel>
                    <Input
                      id="component-error"
                      onChange={(e) =>
                        setFormData({
                          name: e.target.value,
                          emailId: formData.emailId,
                          orgName: formData.orgName,
                        })
                      }
                      error={
                        formData.name.length < 3 && formData.name.length > 0
                      }
                      aria-describedby="component-error-text"
                      inputProps={{
                        style: {
                          fontSize: "18px",
                          fontFamily: "EuclideCircularARegular",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        },
                      }}
                    />
                    {formData.name.length < 3 && formData.name.length > 0 && (
                      <FormHelperText id="component-error-text">
                        minimum 3 char required
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    error
                    variant="standard"
                    style={{ width: "-webkit-fill-available", height: "80px" }}
                  >
                    <InputLabel
                      htmlFor="component-error"
                      error={
                        !validator.isEmail(formData.emailId) &&
                        formData.emailId.length > 0
                      }
                      style={{
                        fontSize: "18px",
                        fontFamily: "EuclideCircularARegular",
                        fontWeight: 500,
                        color: "#9FA8B7",
                      }}
                    >
                      Email Id
                    </InputLabel>
                    <Input
                      id="component-error"
                      onChange={(e) =>
                        setFormData({
                          name: formData.name,
                          emailId: e.target.value,
                          orgName: formData.orgName,
                        })
                      }
                      error={
                        !validator.isEmail(formData.emailId) &&
                        formData.emailId.length > 0
                      }
                      aria-describedby="component-error-text"
                      inputProps={{
                        style: {
                          fontSize: "18px",
                          fontFamily: "EuclideCircularARegular",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        },
                      }}
                    />
                    {!validator.isEmail(formData.emailId) &&
                      formData.emailId.length > 0 && (
                        <FormHelperText id="component-error-text">
                          Invalid email id
                        </FormHelperText>
                      )}
                  </FormControl>
                  <FormControl
                    error
                    variant="standard"
                    style={{ width: "-webkit-fill-available", height: "80px" }}
                  >
                    <InputLabel
                      htmlFor="component-error"
                      error={
                        formData.orgName.length < 3 &&
                        formData.orgName.length > 0
                      }
                      style={{
                        fontSize: "18px",
                        fontFamily: "EuclideCircularARegular",
                        fontWeight: 500,
                        color: "#9FA8B7",
                      }}
                    >
                      Organisation Name
                    </InputLabel>
                    <Input
                      id="component-error"
                      onChange={(e) =>
                        setFormData({
                          name: formData.name,
                          emailId: formData.emailId,
                          orgName: e.target.value,
                        })
                      }
                      error={
                        formData.orgName.length < 3 &&
                        formData.orgName.length > 0
                      }
                      aria-describedby="component-error-text"
                      inputProps={{
                        style: {
                          fontSize: "18px",
                          fontFamily: "EuclideCircularARegular",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        },
                      }}
                    />
                    {formData.orgName.length < 3 &&
                      formData.orgName.length > 0 && (
                        <FormHelperText id="component-error-text">
                          minimum 3 char required
                        </FormHelperText>
                      )}
                  </FormControl>
                  <Button
                    className={classes.submit_btn}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              )}
              {!loading && status === 201 && (
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  style={{ paddingTop: "150px" }}
                >
                  <Grid item>
                    <img
                      style={{
                        height: "80px",
                        width: "80px",
                        color: "#576BEA",
                      }}
                      src="/success.png"
                      alt="Done"
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "24px",
                        margin: "40px 0px 18px 0px",
                        color: "#FFFFFF",
                      }}
                    >
                      THANK YOU!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#FFFFFF" }}>
                      We will contact you as soon as possible
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {loading && (
                <Grid
                  xs={12}
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: "250px" }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
