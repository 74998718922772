import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnswerTypeEnum } from "../../enums/answerTypeEnums";
import { post } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";
import { CreateTeamProps } from "../../type";

export const createTeam = createAsyncThunk(
  "admin/teams/create",
  async (params: CreateTeamProps, storeActions) => {
    const response: any = await post('api/v1/admin/teams', params, storeActions);
    if (response.status === HttpStatus.CREATED) {
      return {
        status: response.status,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const createTeamSlice = createSlice({
  name: "createTeamDetails",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as number | null,
    unsavedTeamChanges: false as  boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
    setUnsavedTeamChanges: (state, action) => {
      state.unsavedTeamChanges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(createTeam.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(createTeam.rejected, (state) => {
      state.status = HttpStatus.SERVICE_UNAVAILABLE;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData, setUnsavedTeamChanges  } = createTeamSlice.actions;

export default createTeamSlice;
