import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import React, { useEffect } from "react";
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch, useSelector } from "react-redux";

import { State } from "../../../type";
import { OtpRequestProps, OtpVerificationRequest } from "../../../type";
import { OtpResponse, OtpVerificationResponse } from "../../../type";
import { otpRequest } from "../../../redux/slices/OtpRequestSlice";
import { signInCorporate } from "../../../redux/slices/OtpValidationSlice";
import { signInUser } from "../../../redux/slices/AuthSlice";
import { TokenId } from "../../../type";
import checkBlockedDomain from "../../../modules/constants/BlockedDomains";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    marginBottom: "30px",
    alignItems: "center",
    width: "75%",
    marginLeft: "15%"
  },
  subTextError: {
    color: "red",
    fontSize: "16px",
    marginBottom: theme.spacing(2),
  },
  nextButton: {
    marginTop: theme.spacing(2),
  },
  enteredEmailSection: {
    border: "1px solid #dfe2e5",
    paddingLeft: theme.spacing(2),
    borderRadius: "10px",
    width: "100%"
  },
  eneteredEmail: {
    fontSize: "16px",
    flex: 1,
    flexWrap: "wrap",
    width: "70%"
  },
  verifyButton: {
    fontWeight: 800,
    fontSize: "14px",
  },
  otp: {
    fontSize: "28px",
  }

}
));

export const CorporateLogin = ({ corporateEmailEntered, setCorporateEmailEntered }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state: State) => state.auth.token);
  const [corporateLogin, setCorporateLogin] = React.useState(true);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [flagValidEmail, setFlagValidEmail] = React.useState(false);
  const requesterTypeValue = "email";

  const loading: boolean = useSelector((state: State) => state.otpResponse.loading);

  const emptyOtpGenerationResponse: OtpResponse = { status: '', message: '', responseCode: '' };
  const otpGenerationResponse: OtpResponse =
    useSelector((state: State) => state.otpResponse.data) ?? emptyOtpGenerationResponse;

  useEffect(() => {
    validateOtpGenerationResponse(otpGenerationResponse.status, otpGenerationResponse.message);
  }, [otpGenerationResponse]);


  const otpLength = 6;

  function handleEmail(event) {
    if (!isValidEmail(event.target.value)) {
      setError('Please verify your email address.');
      setFlagValidEmail(false);
    } else {
      const domain = event.target.value.split('@')[1];
      if (checkBlockedDomain(domain)) {
        setFlagValidEmail(true);
        setError('');
      } else {
        setFlagValidEmail(false);
        setError('Kindly enter corporate email id');
      }
    }
    setEmail(event.target.value);
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function validateEmail() {
    return isValidEmail(email);
  }

  function handleOtp(event) {
    setOtp(event.target.value);
  }

  function requestOtp() {
    const arg: OtpRequestProps = {
      requester: Buffer.from(email).toString('base64'),
      requesterType: requesterTypeValue,
    };
    dispatch(otpRequest(arg));

  }

  function validateOtp() {
    resetEmail();
    const arg: TokenId = {
      id_token: '',
      name: '',
      imageUrl: '',
      email: Buffer.from(email).toString('base64'),
      isCorporate: true,
      otp: Buffer.from(otp).toString('base64'),
    };
    dispatch(signInUser(arg));
  }

  function validateOtpGenerationResponse(status, message) {
    if (status === 'SUCCESS') {
      setCorporateEmailEntered(true);
      setError('');
    } else if (status === 'FAILURE') {
      setCorporateEmailEntered(false);
      setError(message + '. Kindly try again after some time.');
    }
  }

  function submitEmail(event) {
    event.preventDefault();
    requestOtp();
  }
  function submitOTP(event) {
    event.preventDefault();
    validateOtp();
  }
  function resetEmail() {
    setCorporateEmailEntered(false);
    setEmail('');
    setFlagValidEmail(false);
    setOtp('');
  }

  useEffect(() => {
    resetEmail()
  }, []);


  const ColoredLine = ({ color, text }) => (
    <div style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
      <div style={{ flex: 1, backgroundColor: color, height: "1px" }} />
      <p style={{ margin: "0 10px" }}>{text}</p>
      <div style={{ flex: 1, backgroundColor: color, height: "1px" }} />
    </div>
  );


  return (
    <div>
      {!corporateEmailEntered && <Box onSubmit={submitEmail} component="form">
        <Grid>
          <Grid item>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Corporate Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleEmail}
              value={email}
              inputProps={{ maxLength: 75, "data-testid": "corporate-email" }}
            />
          </Grid>
          <Grid item>
            <sub className={classes.subTextError}>{error}</sub>
          </Grid>
          <Grid item>
            <Button fullWidth variant="contained" disabled={!flagValidEmail}
              onClick={requestOtp} color='primary'
              className={classes.nextButton} >Generate otp</Button>
          </Grid>
        </Grid>
      </Box>}

      {corporateEmailEntered &&
        <Grid item lg={8} style={{ margin: "auto" }}>
          <Box component="form" onSubmit={submitOTP}>
            <div className={classes.enteredEmailSection}>
              <span className={classes.eneteredEmail}>{email} </span>
              <span>
                <Button onClick={resetEmail} color='primary'
                  className={classes.verifyButton} >Change</Button>
              </span>
            </div>

            <TextField
              margin="normal"
              fullWidth
              id="otp"
              label="OTP"
              name="otp"
              type="password"
              autoComplete="otp"
              autoFocus
              value={otp}
              onChange={handleOtp}
              className={classes.otp}
              inputProps={{ style: { fontSize: 40 }, maxLength: otpLength }} />

            {corporateLogin && <Button color="primary" type="button" fullWidth variant="contained"
              disabled={otp?.length != otpLength} onClick={validateOtp}>
              Verify
            </Button>}
          </Box>


        </Grid>}

    </div>);
};
