import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ReportButtonProps } from "../type";

const useStyle = makeStyles((theme) => ({
  reportCTA: {
    padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
    // marginLeft: theme.spacing(2.5),
    textTransform: "none",
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    backgroundColor: "#7C69EC",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(6),
    width: theme.spacing(20.25),
    "&:hover": {
      backgroundColor: "#5c49cc",
    },
  },
}));

export const ReportCTA: React.FC<ReportButtonProps> = ({ setOpen }) => {
  const classes = useStyle();
  return (
    <Grid item>
      <Button
        startIcon={<img src="/ReportIcon.svg" alt="i" />}
        className={classes.reportCTA}
        onClick={() => setOpen(true)}
      >
        SHOW REPORT
      </Button>
    </Grid>
  );
};
