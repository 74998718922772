import {
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../../../type";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { reminderConfirmationModalStyles } from "./helper";

export const ReminderConfirmationModal = ({
  open,
  handleCancel,
  data,
  handleSuccess,
  disableActions
}) => {
  const classes = reminderConfirmationModalStyles();

  const isCreatingReminder = useSelector((state: State) => state.createReminderDetails.loading);

  return (
    <Modal open={open} loading={isCreatingReminder}>
      <ModalHeader title="Reminder" showBackground={false} handleCancel={handleCancel} />
      <ModalBody>
        <Typography className={classes.confirmationInfo}>
          Are you sure you want to change the reminder settings? Email reminders will be now sent according to the new frequency.
        </Typography>
      </ModalBody>
      <ModalFooter
        buttonLabel="Confirm"
        secondaryLabel="Discard"
        handleCancel={handleCancel}
        handleSubmit={handleSuccess}
        disableActions={disableActions}
      />
    </Modal>
  );
};
