import {
  Button,
  fade,
  Grid,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import React, { useEffect } from "react";
import { DeleteAttributeCardComponent } from "../../components/DeleteAttributeCard";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import {
  AddNewButtonProps,
  ResponseScaleProp,
  ShareFeedbackAttributeNameAndId,
  State,
} from "../../../type";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  RenderAttributeQuestion,
  RenderLikertNaScaleAnswer,
  RenderLikertScaleAnswer,
  RenderResources,
  RenderStringAnswer,
  RenderSuggestions,
  RenderContext,
} from "./addNewButtonElements";
import { updateEmojiAndAttributeOnClick } from "./addNewButtonHandles";
import { handleSkip } from "./addNewButtonHandles";
import { AttributeTitleComponent } from "./AttributeTitle";
import { VriddhiResponseOptions } from "./Vriddhi/VriddhiResponseOptions";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
  },
  textField: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    width: "100%",
    padding: "0px 0px 10px 0px",
    marginTop: "20px",
    marginBottom: "20px",
    textAlign: "start",
  },
  paper2: {
    padding: "0px 0px 10px 0px",
    margin: theme.spacing(3.5, 3, 3.5, 3),
    textAlign: "start",
    width: "-webkit-fill-available",
  },
  btn: {
    margin: theme.spacing(3, 0, 0, 0),
    backgroundColor: `${appColor.regularBackgroundColor}`,
    zIndex: 50,
  },
  btn2: {
    margin: theme.spacing(5, 0, 5, 3),
    backgroundColor: "#576BEA",
    padding: theme.spacing(1.5),
  },
  typography_body_1: {
    alignItems: "center",
    color: `${appColor.pureWhite}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  skipCTA: {
    fontSize: theme.spacing(1.75),
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#576BEA",
    textDecoration: "underline",
    margin: theme.spacing(0.25, 1.5, 0, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0.25, 0, 0, 0),
    },
  },
}));

export const AddNewButton: React.FC<AddNewButtonProps> = ({
  attributes,
  setAttributes,
  shareFeedbackData,
  setShareFeedbackData,
  emojiSelected,
  setEmojiSelected,
  setValue,
  onAnyChange,
  isMissing,
  attLabels,
  from,
}) => {
  const NO_SELECTION = -1;
  const classes = useStyle();
  const customClasses = useCustomStyle();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [onDeleteAttribute, setOnDeleteAttribute] = React.useState<number>(0);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [deletingAttibute, setDeletingAttribute] =
    React.useState<ShareFeedbackAttributeNameAndId>({
      attributeId: 0,
      attributeName: "",
    });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: number) => {
    setAttributes(attributes.filter((attribute) => attribute.id !== id));
    handleModalClose();
    setOnDeleteAttribute(1);
  };

  useEffect(() => {
    setAttributes(attributes);
    if (onDeleteAttribute === 1) {
      updateEmojiAndAttributeOnClick(
        attributes,
        emojiSelected,
        shareFeedbackData,
        setEmojiSelected,
        setShareFeedbackData
      );
    }
    setOnDeleteAttribute(0);
  }, [onDeleteAttribute]);

  const handleClose = (
    event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "toggleInput") {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const renderDeletionModal = () => (
    <DeleteAttributeCardComponent
      openModal={openModal}
      deletingAttribute={deletingAttibute}
      handleDelete={handleDelete}
      handleModalClose={handleModalClose}
    />
  );
  let regex = /(<([^>]+)>)/gi;

  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector((state: State) => state.data.data?.scaleResponseEntityList) ??
    emptyList;

  const responseScales2 =
    useSelector(
      (state: State) =>
        state.attributesDataForClient.data?.scaleResponseEntityList
    ) ?? emptyList;

  const skipClicked = (attributeIdx, questionIdx) => {
    clearQuestionResponse(attributeIdx, questionIdx);
    handleSkip(
      attributeIdx,
      questionIdx,
      shareFeedbackData,
      setShareFeedbackData
    );
  };

  const clearQuestionResponse = (attributeIdx, questionIdx) => {
    const updatedEmojiSelected = [...emojiSelected];
    const attribute = updatedEmojiSelected[attributeIdx];
    if (attribute && attribute.queEmoji[questionIdx]) {
      attribute.queEmoji[questionIdx].emojiId = NO_SELECTION;
      setEmojiSelected(updatedEmojiSelected);
    }
  };

  return (
    <>
      <Grid container alignItems="flex-start" direction="column">
        {shareFeedbackData.map((attribute, idx) => (
          <Paper
            variant="outlined"
            className={from === "EMPLOYEE" ? classes.paper : classes.paper2}
            key={attribute.attributeId}
            id={attribute.attributeId.toString()}
          >
            <div key={attribute.attributeId}>
              {
                <AttributeTitleComponent
                  attribute={attribute}
                  setDeletingAttribute={setDeletingAttribute}
                  handleModalOpen={handleModalOpen}
                />
              }
              {attribute.questions.map((question, idx_1) => (
                <Grid
                  key={question.questionId}
                  id={`que-id-${question.questionId}`}
                  container
                  alignItems="flex-start"
                  style={
                    question.answer
                      .replaceAll(/\&nbsp;/g, "")
                      .replaceAll(regex, "")
                      .replace(/\s/g, "")
                      .replace(/\n/g, "").length === 0 && isMissing
                      ? { backgroundColor: "#FFF4EB" }
                      : {}
                  }
                >
                  <Grid
                    item
                    xs
                    container
                    justify="flex-start"
                    style={
                      question.answer === "Skipped"
                        ? { opacity: 0.5 }
                        : { opacity: 1 }
                    }
                  >
                    {
                      <RenderAttributeQuestion
                        question={question.questionName}
                        answer={question.answer}
                      ></RenderAttributeQuestion>
                    }

                    {/* Emoticons */}
                    {question.scaleName === "LIKERT" && (
                      <RenderLikertScaleAnswer
                        idx={idx}
                        idx_1={idx_1}
                        emojiSelected={emojiSelected}
                        setEmojiSelected={setEmojiSelected}
                        shareFeedbackData={shareFeedbackData}
                        setShareFeedbackData={setShareFeedbackData}
                        scaleVersion={question.scaleVersion}
                      ></RenderLikertScaleAnswer>
                    )}

                    {/* Free Text Field */}
                    {question.scaleName === "STRING" && (
                      <RenderStringAnswer
                        question={question}
                        idx={idx}
                        idx_1={idx_1}
                        shareFeedbackData={shareFeedbackData}
                        setShareFeedbackData={setShareFeedbackData}
                        onAnyChange={onAnyChange}
                      ></RenderStringAnswer>
                    )}

                    {/* Emoticons with NA */}
                    {question.scaleName === "LIKERT_NA" && (
                      <RenderLikertNaScaleAnswer
                        idx={idx}
                        idx_1={idx_1}
                        emojiSelected={emojiSelected}
                        setEmojiSelected={setEmojiSelected}
                        shareFeedbackData={shareFeedbackData}
                        setShareFeedbackData={setShareFeedbackData}
                        scaleVersion={question.scaleVersion}
                      ></RenderLikertNaScaleAnswer>
                    )}

                    {/* Vriddhi */}
                    {question.scaleName === "VRIDDHI" && (
                      <VriddhiResponseOptions
                        idx={idx}
                        idx_1={idx_1}
                        emojiSelected={emojiSelected}
                        setEmojiSelected={setEmojiSelected}
                        shareFeedbackData={shareFeedbackData}
                        setShareFeedbackData={setShareFeedbackData}
                        scaleVersion={question.scaleVersion}
                        responseScales={responseScales}
                        responseScales2={responseScales2}
                      ></VriddhiResponseOptions>
                    )}
                  </Grid>

                  {question.scaleName !== "STRING" && (
                    <Grid item xs={1} container justify="flex-end">
                      <Button
                        onClick={() => skipClicked(idx, idx_1)}
                        className={classes.skipCTA}
                      >
                        {question.answer !== "Skipped" ? "SKIP" : "UNDO"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ))}
              {
                <RenderContext
                  attribute={attribute}
                  idx={idx}
                  shareFeedbackData={shareFeedbackData}
                  setShareFeedbackData={setShareFeedbackData}
                  setValue={setValue}
                  onAnyChange={onAnyChange}
                  isMissing={isMissing}
                ></RenderContext>
              }
              {
                <RenderSuggestions
                  attribute={attribute}
                  idx={idx}
                  shareFeedbackData={shareFeedbackData}
                  setShareFeedbackData={setShareFeedbackData}
                  setValue={setValue}
                  onAnyChange={onAnyChange}
                ></RenderSuggestions>
              }
              {
                <RenderResources
                  attribute={attribute}
                  idx={idx}
                  shareFeedbackData={shareFeedbackData}
                  setShareFeedbackData={setShareFeedbackData}
                  setValue={setValue}
                  onAnyChange={onAnyChange}
                ></RenderResources>
              }
            </div>
          </Paper>
        ))}
      </Grid>
      <Grid container justify="center" alignItems="center">
        {renderDeletionModal()}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={from === "EMPLOYEE" ? classes.btn : classes.btn2}
        endIcon={from === "CLIENT" && <ArrowDropDownIcon />}
      >
        <Typography variant="h2" className={classes.typography_body_1}>
          ADD NEW
        </Typography>
      </Button>
      <Popper
        id="popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <Autocomplete
          open
          onClose={handleClose}
          filterSelectedOptions
          multiple
          value={attributes}
          onChange={(event, newValue) => {
            setAttributes(newValue);
            updateEmojiAndAttributeOnClick(
              newValue,
              emojiSelected,
              shareFeedbackData,
              setEmojiSelected,
              setShareFeedbackData
            );
          }}
          disablePortal
          renderOption={(option) => (
            <React.Fragment>
              <div className={classes.typography_body_3}>
                <Typography variant="h5" className={classes.typography_body_3}>
                  {option.name}
                </Typography>
              </div>
            </React.Fragment>
          )}
          options={attLabels}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              placeholder="Search attribute by name "
              variant="outlined"
              ref={params.InputProps.ref}
              autoFocus
              inputProps={params.inputProps}
              className={`${customClasses.typography_font_14_10} ${classes.textField}`}
            />
          )}
        />
      </Popper>
    </>
  );
};
