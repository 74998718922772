import { makeStyles } from "@material-ui/core/styles";

const updateQuestionStatusModalStyles = makeStyles((theme) => ({
    text: {
        fontSize: 14,
        fontWeight: 500,
        color: "#272727"
    }
}));

export default updateQuestionStatusModalStyles;