import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../home/Layout";
import { ToolBar } from "../../../modules/home/ToolBar";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 2, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
}));

export const MembersLayout = ({
                                props,
                                loading,
                                setLogout,
                                isDetailView,
                                setIsDetailView,
                                renderErrorModal,
                                renderMemberList,
                                renderSearchFilter,
                              }) => {
  document.title = "Grow-Wize | Admin-Members";
  window.scrollTo(0, 0);
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
      <ToolBar
          headerName="Members"
          setLogout={setLogout}
          isDetailView={isDetailView}
          setIsDetailView={setIsDetailView}
          handleDrawerToggle={handleDrawerToggle}
          value={-2}
          setValue={tempFunction}
          tabValue={-2}
          validLength={false}
      ></ToolBar>
  );

  const renderMembersLayout = () => (
      <>
        {renderErrorModal()}
        <Grid container direction="row" className={classes.root} justify="center">
          <Grid item xs={12}>
            {renderSearchFilter()}
            {renderMemberList()}
          </Grid>
        </Grid>
      </>
  );

  return (
      <HomeLayout
          loading={loading}
          renderToolBar={renderToolBar}
          props={props}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          renderSelectedOption={renderMembersLayout}
      ></HomeLayout>
  );
};
