import { makeStyles } from "@material-ui/core/styles";

export const reminderConfirmationModalStyles = makeStyles((theme) => ({
    confirmationInfo: {
        fontSize: 14,
        fontWeight: 500,
        color: "#272727",
    }
}));



export const REMINDER_FREQUENCY_MAPPING = {
    "ONE_MONTH": "every month",
    "THREE_MONTHS": "every quarter",
    "SIX_MONTHS": "every 6 months",
    "TWELVE_MONTHS": "every year",
}

export const getDayWithOrdinal = (day : number) => {
    if (day >= 11 && day <= 13) {
        return day + "th";
    }
    switch (day % 10) {
        case 1:
            return day + "st";
        case 2:
            return day + "nd";
        case 3:
            return day + "rd";
        default:
            return day + "th";
    }
}
