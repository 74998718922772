import { Switch, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles((theme) => ({
    root: {
        padding: 0,
        width: theme.spacing(4.25),
        height: 16,
        "& > :first-child": {
            padding: 0
        },
        "& > .MuiSwitch-track": {
            height: 16,
            background: "#272727",
            opacity: 1,
            borderRadius: theme.spacing(2.5)
        },
        "& > .Mui-checked + .MuiSwitch-track": {
            background: "blue",
        },
        "& > span > span > .MuiSwitch-thumb": {
            height: 10,
            width: 10,
            marginTop: 3,
            marginLeft: 3
        },
        "& > .Mui-checked > span > .MuiSwitch-thumb": {
            background: "#FFFFFF",
            marginLeft: 0
        }
    },
    arrow: {
        '&::before': {
            color: '#5A5A5A'
        }
    },
    tooltip: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#ffffff",
        backgroundColor: "#5A5A5A",
        fontFamily: "Montserrat",
        height: theme.spacing(4.25),
        display: "flex",
        alignItems: "center",
        padding: "4px 10px"
    }
}));

export const SwitchButton = ({ checked, onChange, helperText }) => {
    const classes = useStyle();
    return (
        <Tooltip title={helperText} arrow placement='bottom' classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
            <Switch checked={checked} onChange={onChange} className={classes.root} />
        </Tooltip>
    )
}