import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { SearchBoxProps } from "./type";
import { User } from "../../type";

export const useStyle = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(3.75, 0, 2.5, 0),
    fontColor: "darkgrey",
    width: "310px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.1rem",
      borderColor: "darkgrey",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.1rem",
      borderColor: "darkgrey",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.5, 0, 2.25, 0),
      width: "inherit",
    },
  },
  inputText: {
    fontSize: 16,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
}));

export const UserSearchBox: React.FC<SearchBoxProps> = ({
  user,
  setUser,
  setPageNumber,
  users,
}) => {
  const classes = useStyle();
  return (
    <Autocomplete
      className={classes.input}
      value={user}
      onChange={(event: any, newValue: User | null) => {
        setUser(newValue);
        setPageNumber(1);
      }}
      options={users}
      forcePopupIcon={false}
      getOptionLabel={(user) => user.firstName + " " + user.lastName}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Search request by name..."
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              className: classes.inputText,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
