import {
  Grid,
  TextField,
  Box,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../../type";
import { RESPONSE_TYPE_LIST } from "../../../../constants/questionConstants";
import {
  AddQuestionSliceProp,
  addQuestion,
  selectAddQuestionErrorMessage,
  selectAddQuestionStatus,
} from "../../../../redux/slices/AddQuestionSlice";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { IValidationErrors, addQuestionModalStyles } from "./helper";
import { ValidationErrors } from "../helper";
import { HttpStatus } from "../../../../enums/httpStatus";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { OptionType } from "../../../components/Dropdown/helper";
import { AnswerTypeEnum } from "../../../../enums/answerTypeEnums";

export const AddQuestionModal = ({
  open,
  handleCancel,
  handleSuccess,
  attributeId,
}) => {
  const classes = addQuestionModalStyles();
  const [questionText, setQuestionText] = React.useState<string>("");
  const [selectedResponseType, setSelectedResponseType] = React.useState<OptionType | undefined>();
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [created, setCreated] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IValidationErrors>({
    questionText: "",
    responseType: "",
  });

  useEffect(() => {
    if (open) {
      resetData()
    }
  }, [open])

  const dispatch = useDispatch();

  const addQuestionResponseStatus = useSelector(selectAddQuestionStatus);

  const addQuestionErrorMessage = useSelector(selectAddQuestionErrorMessage);

  useEffect(() => {
    if (submitted || errors?.questionText || errors?.responseType) {
      checkValidation();
    }
  }, [questionText, selectedResponseType]);

  
  useEffect(() => {
    if (addQuestionResponseStatus === HttpStatus.OK) {
      resetData();
      handleSuccess();
    } else if (addQuestionResponseStatus === HttpStatus.BAD_REQUEST) {
      const validationErrors = { ...errors };
      validationErrors.questionText = addQuestionErrorMessage;
      setErrors(validationErrors);
    }
    setSubmitted(false);
  }, [addQuestionResponseStatus]);

  const cancelClicked = (): void => {
    resetData();
    handleCancel();
  };

  const resetData = (): void => {
    setQuestionText("");
    setSelectedResponseType(undefined);
    setSubmitted(false);
    setErrors({
      questionText: "",
      responseType: "",
    });
    setCreated(false);
  };

  const checkValidation = (): boolean => {
    let isValid = true;
    const errors = {} as IValidationErrors;
    if (questionText.trim().length === 0) {
      isValid = false;
      errors.questionText = ValidationErrors.QUESTION_TEXT;
    }
    if (!selectedResponseType) {
      isValid = false;
      errors.responseType = ValidationErrors.RESPONSE_TYPE;
    }
    setErrors(errors);
    return isValid;
  };

  const isAddingQuestion = useSelector((state: State) => state.addQuestionData?.loading);
  const addQuestionStatus = useSelector((state: State) => state.addQuestionData?.status);

  useEffect(() => {
    if (created && !isAddingQuestion && addQuestionStatus !== HttpStatus.OK) {
      setCreated(false)
    }
  }, [created, isAddingQuestion])

  const handleCreate = () => {
    setSubmitted(true);
    setCreated(true);
    if (!created) {
      if (checkValidation()) {
        const params: AddQuestionSliceProp = {
          attributeId: attributeId,
          questionDetails: {
            questionText,
            answerType: selectedResponseType?.key as AnswerTypeEnum,
          },
        };

        dispatch(addQuestion(params));
      } else {
        setCreated(false)
      }
    }
  };

  return (
    <Modal open={open} loading={isAddingQuestion}>
      <ModalHeader title="Create New Question" handleCancel={cancelClicked} />
      <ModalBody>
        {/* \Question Input */}
        <Grid item xs={12} className={classes.showQuestionsWrapper}>
          <Box marginBottom={3}>
            <InputLabel
              htmlFor="component-error"
              error={submitted && questionText.length === 0}
              style={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "#272727",
                marginBottom: "12px",
              }}
            >
              Question
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Write a question here"
              variant="outlined"
              error={errors.questionText ? true : false}
              value={questionText}
              helperText={errors.questionText}
              className={classes.addQuestionInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestionText(event.target.value);
              }}
            ></TextField>
          </Box>
          <Grid container alignItems="flex-start">
            <div
              style={{
                color: "rgb(39, 39, 39)",
                fontWeight: 500,
                fontSize: 14,
                paddingTop: "8px",
              }}
            >
              Response Type:
            </div>
            <Grid className={classes.dropdownWrapper}>
              <Dropdown
                options={RESPONSE_TYPE_LIST}
                placeholder="Select response"
                onSelect={setSelectedResponseType}
                selectedOption={selectedResponseType}
              />
              {errors['responseType'] && <FormHelperText className={classes.helperText}>{errors['responseType']}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter
        buttonLabel="Create"
        handleCancel={cancelClicked}
        handleSubmit={handleCreate}
        disableActions={created}
      />
    </Modal>
  );
};
