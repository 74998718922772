import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TokenId } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";

export const signInUser = createAsyncThunk(
  "authToken/signInUserWithGoogle",
  async (token: TokenId) => {
    try {
      let response;

      if(token.isCorporate){

         const options = {
             method: "post",
             headers: {
                "Content-Type": "application/json",
             },
             body: JSON.stringify(token),
           };

           response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/v1/otp/validate`,options
            );

            if (response.status === HttpStatus.OK) {
                 const tokenResponse = await response.json();
                return {
                  status: HttpStatus.OK,
                  token: tokenResponse.auth,
                  name: tokenResponse.firstName+' '+tokenResponse.lastName,
                  imageUrl: tokenResponse.imageUrl,
                  email: token.email,
                  message: "",
                };
              }
              return {
                status: response.status,
                token: response.statusText,
                name: token.name,
                imageUrl: token.imageUrl,
                email: token.email,
                message: await response.text(),
              };
      }else{
           response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/sign-in`,
            { method: "GET", headers: { id_token: token.id_token } }
          );

          if (response.status === HttpStatus.OK) {
                  return {
                    status: HttpStatus.OK,
                    token: await response.text(),
                    name: token.name,
                    imageUrl: token.imageUrl,
                    email: token.email,
                    message: "",
                  };
                }
                return {
                  status: response.status,
                  token: response.statusText,
                  name: token.name,
                  imageUrl: token.imageUrl,
                  email: token.email,
                  message: await response.text(),
                };
      }


    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null as string | null,
    status: null as number | null,
    loading: false as boolean,
    name: null as string | null,
    imageUrl: null as string | null,
    email: null as string | null,
    message: null as string | null,
    isExpired: null as boolean | null,
  },
  reducers: {
    signInFailed: (state) => {
      state.token = null;
      state.status = 401;
      state.loading = false;
      state.name = null;
      state.imageUrl = null;
      state.email = null;
      state.message = null;
      state.isExpired = null;
    },
    clearData: (state) => {
      state.token = null;
      state.status = null;
      state.loading = false;
      state.name = null;
      state.imageUrl = null;
      state.email = null;
      state.message = null;
    },
    sessionExpired: (state) => {
      state.status = null;
      state.loading = false;
      state.name = null;
      state.imageUrl = null;
      state.email = null;
      state.message = null;
      state.isExpired = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      signInUser.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          status: number;
          token: string;
          name: string;
          imageUrl: string;
          email: string;
          message: string;
        }>
      ) => {
        state.status = payload.status;
        state.token = payload.token;
        state.loading = false;
        state.name = payload.name;
        state.imageUrl = payload.imageUrl;
        state.email = payload.email;
        state.message = payload.message;
        state.isExpired = null;
      }
    );
    builder.addCase(signInUser.pending, (state) => {
      state.loading = true;
      state.token = null;
      state.status = null;
    });
    builder.addCase(signInUser.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.token = null;
      state.name = null;
      state.imageUrl = null;
      state.email = null;
      state.message = null;
      state.isExpired = null;
    });
  },
});

export const { clearData, signInFailed, sessionExpired } = authSlice.actions;

export default authSlice;
