import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeactivateMemberProp, ReceivedFeedbackDataProps, State } from "../../../../type";
import { AttributeContent } from "./AttributeContent";
import { getReceivedFeedbackReport } from "../../../../redux/slices/ConsolidateReportOfReceivedFeedbackSlice";
import { ReceivedFeedbackReportLayout } from "../../../../layouts/admin/members/reports/ReceivedFeedbackReportLayout";
import { useHistory, useLocation } from "react-router-dom";
import ScrollToTopBtn from "./ScrollOnTop";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { UserInfoCard } from "./UserInfoCard";
import { map } from "lodash";
import { Tabs } from "../../../components/Tabs/Tabs";
import { getStartAndEndDateForCalendarDateRange } from "../../../../util/GetDateInDesiredFormat";

const useStyle = makeStyles((theme) => ({
  noDataText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    margin: theme.spacing(40, 0, 40, 0),
  },
  img: {
    padding: theme.spacing(6.5, 0, 6.5, 0),
    height: theme.spacing(47),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      height: theme.spacing(26.8),
    },
  },
  text: {
    color: "#272727",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
}));

export const ReceivedFeedbackReport = (props) => {
  const { id } = props.match.params;
  const memberName = props.location.state?.memberName;
  const member = props.location.state?.member;
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);

  const classes = useStyle();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const history = useHistory(); 
  const location = useLocation();
  const currentPath = location.pathname;
  const teamId = props.location.state?.teamId;

  const feedbackData: Array<ReceivedFeedbackDataProps> =
    useSelector((state: State) => state.receivedFeedbackReport.data) ?? [];

  const tabsData = useMemo(() => { return map(feedbackData, (item, index) => ({ key: index, label: item?.attributeName })) }, [feedbackData])

  useEffect(() => {
    setTabValue(0);
  }, [tabsData])

  useEffect(() => {
    let str = "";
    let dateRange = getStartAndEndDateForCalendarDateRange();
    str += `startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    const params: DeactivateMemberProp = {
      memberId: id,
      tag: "INTERNAL",
      requestingParam: str,
    };
    dispatch(getReceivedFeedbackReport(params));
  }, [id]);
  const statusCode: number | null = useSelector((state: State) =>
    state.receivedFeedbackReport.status !== 200
      ? state.receivedFeedbackReport.status
      : null
  );
  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderNoRecordFoundText = () => (
    <Grid xs={12} item container justify="center" alignItems="center">
      <Typography
        className={classes.noDataText}
      >{`No record found`}</Typography>
    </Grid>
  );

  const renderAttributeTabs = () => (
    <Tabs
      tabValue={tabValue}
      setTabValue={setTabValue}
      data={tabsData}
    />
  );

  const renderMemberCard = () => (
    <UserInfoCard member={member}></UserInfoCard>
  );

  const renderAttributeContent = () => (
    <AttributeContent attributeIndex={tabValue}></AttributeContent>
  );

  const renderScrollOnTop = () => <ScrollToTopBtn />;

  const renderNoFeedbacks = () => (
    <><Grid item style={{
      justifyContent: "center",
      display: "flex"
    }}>
      <img
        src="/NoFeedbacks.svg"
        alt="No_Feedback_Request"
        className={classes.img}
      ></img>
    </Grid><Typography className={classes.text}>
        {" "}
        No records of feedback available here!
      </Typography></>
  );

  const loading: Boolean = useSelector(
    (state: State) => state.receivedFeedbackReport.loading
  );

  if (!isDetailView) {
    if (currentPath === `/admin/members/report/feedback/received/${id}`) {
      history.push({
        pathname: `/admin/member/detail/${id}`,
        state: { member: member, teamId: teamId },
      });
    } else if (currentPath === `/members/report/feedback/received/${id}`) {
      history.push({
        pathname: `/member/detail/${id}`,
        state: { member: member, teamId: teamId  },
      });
    }
  }
  return (
    <ReceivedFeedbackReportLayout
      props={props}
      loading={loading}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      memberName={memberName}
      renderErrorModal={renderErrorModal}
      renderNoRecordText={renderNoRecordFoundText}
      renderMemberCard={renderMemberCard}
      renderNoFeedbacks={renderNoFeedbacks}
      renderAttributeTabs={renderAttributeTabs}
      renderAttributeContent={renderAttributeContent}
      renderScrollOnTop={renderScrollOnTop}
    ></ReceivedFeedbackReportLayout>
  );
};
