import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExtProps } from "../../../src/type";

export const requestFeedbackFromExtClient = createAsyncThunk(
  "requestFeedback/client/sendRequest",
  async (requestData: ExtProps) => {
    try {
      const authToken: string = JSON.parse(
        JSON.parse(localStorage.getItem("persist:root") ?? " ").auth
      ).token;
      const options = {
        method: "post",
        headers: {
          Accept: "application/text",
          "Content-Type": "application/json",
          authToken: authToken,
        },
        body: JSON.stringify(requestData),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/ext_requests`,
        options
      );
      return {
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  }
);

const requestFeedbackFromExtClientSlice = createSlice({
  name: "requestFeedbackFromExtClient",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      requestFeedbackFromExtClient.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(requestFeedbackFromExtClient.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(requestFeedbackFromExtClient.rejected, (state) => {
      state.status = 503;
      state.loading = true;
    });
  },
});

export const { clearData } = requestFeedbackFromExtClientSlice.actions;

export default requestFeedbackFromExtClientSlice;
