import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../../components/CommonFunctionalityComponent";
import { DetailPage } from "../../../components/list/detail/DetailPage";
import { SharedFeedbackDetailsLayout } from "../../../../layouts/user/shared_feedback/detail/Layout";
import { sharedFeedbackDetail } from "../../../../redux/slices/SharedFeedbackDetailSlice";
import { requestData } from "../../../../redux/slices/UsersAndAttributesSlice";
import {
  FeedbackDetailAPICallProps,
  State,
  UserAndAttributeSliceProps,
} from "../../../../type";
import { SharedFeedbackDetailData } from "../typeSharedFeedbacks";

export const SharedFeedbackDetail = (props) => {
  const [value, setValue] = React.useState(-1);
  const { id } = props.match.params;
  const [logout, setLogout] = React.useState<Boolean>(false);
  const token: string = useSelector((state: State) => state.auth.token);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(true);
  const data: SharedFeedbackDetailData =
    useSelector((state: State) => state.sharedFeedbackDetail.data) ?? null;
  const loading: Boolean = useSelector(
    (state: State) => state.sharedFeedbackDetail.loading || state.data.loading
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: false,
      isAttributes: false,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: true,
    };
    dispatch(requestData(param));
    const params: FeedbackDetailAPICallProps = {
      feedbackId: id,
    };
    dispatch(sharedFeedbackDetail(params));
  }, [id]);
  const statusCode: number | null = useSelector((state: State) =>
    state.sharedFeedbackDetail.status !== 200
      ? state.sharedFeedbackDetail.status
      : state.data.status !== 200
      ? state.data.status
      : null
  );


  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderDetails = () => (
    <DetailPage
      user={data?.user}
      date={data?.createdAt}
      context={data?.context}
      attributes={data?.attributes}
      from={"SHARED_FEEDBACK"}
      setValue={setValue}
    />
  );

  if (!isDetailView) {
    history.push({
      pathname: "/feedback/shared",
      state: {
        pageNo: props.history.location.state?.pageNo,
        option: props.location.state?.option,
      },
    });
  }

  if (value === 1) {
    return <Redirect to={`/feedback/share/${data?.user?.id}`} />;
  }

  return (
    <SharedFeedbackDetailsLayout
      loading={loading}
      logout={logout}
      setLogout={setLogout}
      props={props}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderDetails={renderDetails}
      renderErrorModal={renderErrorModal}
    ></SharedFeedbackDetailsLayout>
  );
};
