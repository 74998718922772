import { Provider } from "react-redux";
import { AllRoutes } from "./routes";

import './App.css';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStores } from './redux/store';
const { persistor, store } = configureStores();



function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="AppTest">
          <AllRoutes />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
