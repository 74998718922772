import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { RequestFeedbackLayout } from "../../../layouts/user/request_feedback/Layout";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import { State, User, UserAndAttributeSliceProps } from "../../../type";
import { RequestFeedbackForm } from "./request_employee/requestFeedbackForm";
import { RequestFeedbackFromExtClientForm } from "./request_client/RequestFeedbackFromExtClient";
import { RequestFeedbackList } from "./request_list/RequestFeedbackList";
import { TabLabels } from "./tabLabels";

export const RequestFeedback = (props) => {
  const { STATUS } = props.match.params;
  const [value, setValue] = React.useState(-1);
  const [requestedFeedbackId, setRequestedFeedbackId] =
    React.useState<number>(0);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [context, setContext] = React.useState<string>("");
  const [selectedAttributeIds, setSelectedAttributeIds] = React.useState<
    number[]
  >([]);
  const [requestedUserId, setRequestedUserId] = React.useState<number | null>(
    null
  );
  const tabToIndex = {
    employee: 0,
    client: 1,
    sent: 2,
  };
  const pageNo = props.location.state?.pageNo;
  const usr = props.location.state?.user;
  const filterNo = props.location.state?.filterNo;
  const [tabValue, setTabValue] = React.useState<number>(tabToIndex[STATUS]);
  const [user, setUser] = React.useState<User | null>(usr ? usr : null);
  const [pageNumber, setPageNumber] = React.useState<number>(
    pageNo ? pageNo : 1
  );
  const [filterValue, setFilterValue] = React.useState<number>(
    filterNo ? filterNo : 0
  );
  const token = useSelector((state: State) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: true,
      isClients: true,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
  }, []);

  const history = useHistory();
  const statusCode: number | null = useSelector((state: State) =>
    state.data.status !== 200
      ? state.data.status
      : state.countRequestsSend.status !== 200
      ? state.countRequestsSend.status
      : state.sendRequests.status !== 200
      ? state.sendRequests.status
      : null
  );

  if (
    props.location.pathname !== "/feedback/requests/employee" &&
    props.location.pathname !== "/feedback/requests/client" &&
    props.location.pathname !== "/feedback/requests/sent"
  )
    return <Redirect to={`/error`} />;

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderTabLabels = () => (
    <TabLabels
      tabValue={tabValue}
      setTabValue={setTabValue}
      props={props}
    ></TabLabels>
  );

  const renderRequestFeedbackForm = () => (
    <RequestFeedbackForm
      setValue={setValue}
      user={usr ? usr : null}
      context={context}
      setContext={setContext}
      selectedAttributeIds={selectedAttributeIds}
      setSelectedAttributeIds={setSelectedAttributeIds}
    ></RequestFeedbackForm>
  );

  const renderRequestFeedbackFromExtClientForm = () => (
    <RequestFeedbackFromExtClientForm
      setValue={setValue}
      user={usr ? usr : null}
      context={context}
      setContext={setContext}
      selectedAttributeIds={selectedAttributeIds}
      setSelectedAttributeIds={setSelectedAttributeIds}
    ></RequestFeedbackFromExtClientForm>
  );

  const renderRequestFeedbackList = () => (
    <RequestFeedbackList
      user={user}
      setUser={setUser}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      setValue={setValue}
      setRequestedUserId={setRequestedUserId}
      setRequestedFeedbackId={setRequestedFeedbackId}
      filterValue={filterValue}
      setFilterValue={setFilterValue}
    ></RequestFeedbackList>
  );

  if (value === 0) {
    return <Redirect to="/home" />;
  }

  if (requestedFeedbackId !== 0) {
    history.replace({
      pathname: "/feedback/requests/sent",
      state: {
        pageNo: pageNumber,
        user: user,
        filterNo: filterValue,
      },
    });
    history.push({
      pathname: `/feedback/request/sent/detail/${requestedFeedbackId}/${requestedUserId}`,
      state: {
        pageNo: pageNumber,
        user: user,
        filterNo: filterValue,
      },
    });
  }
  return (
    <RequestFeedbackLayout
      props={props}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      tabValue={tabValue}
      renderErrorModal={renderErrorModal}
      renderTabs={renderTabLabels}
      renderRequestFeedbackForm={renderRequestFeedbackForm}
      renderRequestFeedbackList={renderRequestFeedbackList}
      renderRequestFeedbackFromExtClientForm={
        renderRequestFeedbackFromExtClientForm
      }
    ></RequestFeedbackLayout>
  );
};
