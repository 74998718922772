import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ToolBar } from "../../../modules/home/ToolBar";
import { ReceivedFeedbacksLayoutProp, State } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  root2: {
    alignItems: "center",
    padding: theme.spacing(6, 0, 0, 0),
    marginTop: theme.spacing(10),
    backgroundColor: "white",
    minHeight: `${window.innerHeight - 85}px`,
  },
}));

export const ReceivedFeedbacksLayout: React.FC<ReceivedFeedbacksLayoutProp> = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  count,
  showNoRequest,
  renderErrorModal,
  renderSearchBox,
  renderReceivedFeedbacksList,
  renderChangePageButtons,
  renderNoFeedbacks,
  renderFilter,
}) => {
  const classes = useStyle();
  window.scrollTo(0,0);
  document.title = "Grow-Wize | Received Feedback";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Received Feedback"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderReceivedFeedbacksLayout = () => (
    <>
      {renderErrorModal()}
      {showNoRequest ? (
        <Grid
          container
          direction="column"
          className={classes.root2}
          justify="flex-start"
          alignItems="flex-start"
        >
          {renderNoFeedbacks()}
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          <Grid xs={12} container alignItems="center">
            <Grid xs={6} container justify="flex-start">
              {renderSearchBox()}
            </Grid>
            <Grid xs={6} container justify="flex-end">
              {renderFilter()}
            </Grid>
            <Grid xs={12} container>
              {renderReceivedFeedbacksList()}
              {count !== 0 ? renderChangePageButtons() : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );

  const loading: Boolean = useSelector(
    (state: State) => state.countReceived.loading
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderReceivedFeedbacksLayout}
    ></HomeLayout>
  );
};
