import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import "./extra_fonts/landingFont.css";

const useStyle = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    padding: theme.spacing(12.5, 0, 2.5, 0),
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#F3F5FB",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  slide_1_main_text: {
    color: "#012241",
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    fontFamily: "EuclideCircularABold",
    letterSpacing: theme.spacing(0.05),
    width: theme.spacing(60.25),
    marginBottom: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(3),
      textAlign: "center",
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  img: {
    width: theme.spacing(31.75),
    backgroundColor: "#FFFFFF",
    height: theme.spacing(37.5),
    borderRadius: theme.spacing(1.25),
    boxShadow: "2px 2px 4px 0 rgba(193,193,193,0.5)",
    margin: theme.spacing(0, 2.25, 10, 2.25),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      width: "full",
    },
  },
  card_text: {
    color: "#012241",
    textAlign: "center",
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    fontFamily: "EuclideCircularASemiBold",
    padding: theme.spacing(21.25, 2, 5, 2),
  },
}));

const ADDITIONAL_FEATURES_TEXT = [
  "<p>Encourage</br>Team</br>Communication</p>",
  "<p>Build a culture</br>of continuous</br>growth</p>",
  "<p>Share feedback</br>from any</br>device</p>",
  "<p>Regular reminders</br>to stay in the</br>loop</p>",
  "<p>Visualise</br>feedback</br>trends</p>",
  "<p>View the engagement</br>superstars in your</br>system</p>",
];

export const AdditionalFeatureSectionComponent: React.FC = () => {
  const classes = useStyle();
  return (
    <>
      <Grid
        xs={12}
        item
        container
        className={classes.parallax}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} container justify="center" alignItems="center">
          <Grid item>
            <Typography className={classes.slide_1_main_text}>
              How Do We Make Your Life Easier?
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid
          xs={12}
          lg={10}
          item
          container
          justify="center"
          alignItems="center"
          style={{ display: "flex" }}
        >
          {ADDITIONAL_FEATURES_TEXT.map((item, idx) => (
            <Grid item key={idx}>
              <Paper className={classes.img}>
                <Grid
                  xs={12}
                  item
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={classes.card_text}
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
    </>
  );
};
