import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ToolBar } from "../../../modules/home/ToolBar";
import { FeedbackRequestLayoutProps } from "../../../type";
import { HomeLayout } from "../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 4, 4, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(4, 3, 3, 3),
    },
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: `${window.innerHeight - 102}px`,
  },
  root2: {
    alignItems: "center",
    padding: theme.spacing(6, 0, 0, 0),
    marginTop: theme.spacing(10),
    backgroundColor: "white",
  },
}));

export const FeedbackRequestLayout: React.FC<FeedbackRequestLayoutProps> = ({
  props,
  setLogout,
  isDetailView,
  setIsDetailView,
  tabValue,
  loading,
  count,
  selectedUser,
  renderErrorModal,
  renderTabLabels,
  renderSearchBox,
  renderNoRequest,
  renderRequestList,
  renderChangePageButtons,
}) => {
  const classes = useStyle();
  window.scrollTo(0,0);
  const indexToTab = {
    0: "All",
    1: "Pending",
    2: "Closed",
  };
  document.title = `Grow-Wize | Feedback Requests | ${indexToTab[tabValue]}`;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName="Feedback Requests"
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderFeedbackRequestsLayout = () => (
    <Grid
      container
      className={
        count.allRequestCount === 0 && selectedUser === null
          ? classes.root2
          : classes.root
      }
    >
      {renderErrorModal()}
      {count.allRequestCount === 0 && selectedUser === null ? (
        renderNoRequest()
      ) : (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: "inherit" }}
        >
          {renderTabLabels()}
          {renderSearchBox()}
          {renderRequestList()}
          {renderChangePageButtons()}
        </Grid>
      )}
    </Grid>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderFeedbackRequestsLayout}
    ></HomeLayout>
  );
};
