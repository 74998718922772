import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useCustomStyle } from "../../../styles";

export const RightsText = () => {
  const classes = useCustomStyle();
  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      style={{ margin: "16px 0px 24px 42px" }}
    >
      <Grid item>
        <img src="/CopyrightIcon.svg" alt="c" />
      </Grid>
      <Grid item>
        <Typography
          variant="h6"
          className={classes.typography_font_14_12_500}
          style={{ textAlign: "start" }}
        >
          {`${new Date().getFullYear()} Grow-Wize. All rights reserved`}
        </Typography>
      </Grid>
    </Grid>
  );
};
