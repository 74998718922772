import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { State } from "../../../type";

const useStyle = makeStyles((theme) => ({
  tabs: {
    color: "#576BEA",
    width: theme.spacing(20),
    textAlign: "left",
  },
  divider: {
    backgroundColor: "#576bea66",
    width: "inherit",
  },
}));

const MyAssessmentText = () => {
  const classes = useCustomStyle();
  return (
    <Typography
      gutterBottom
      variant="h5"
      className={classes.typography_font_14_10_600}
    >
      My Assessment
    </Typography>
  );
};

const SharedText = () => {
  const classes = useCustomStyle();
  const count: number =
    useSelector((state: State) => state.countSharedAssessments.data) ?? 0;
  return (
    <Typography
      gutterBottom
      variant="h5"
      className={classes.typography_font_14_10_600}
    >
      {`Shared (${count})`}
    </Typography>
  );
};

export const TabLabels = ({ tabValue, setTabValue, props }) => {
  const classes = useStyle();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    setTabValue(event.target.value as number);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Hidden smDown>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab style={{ minWidth: theme.spacing(7.5) }} label={<MyAssessmentText />} />
          <Tab
            style={{
              marginLeft: theme.spacing(3),
              minWidth: theme.spacing(7.5),
            }}
            label={<SharedText />}
          />
        </Tabs>
        <Divider className={classes.divider} />
      </Hidden>
      {
        <Hidden mdUp>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <MenuItem value={0}>
              <MyAssessmentText />
            </MenuItem>
            <MenuItem value={1}>
              <SharedText />
            </MenuItem>
          </Select>
        </Hidden>
      }
    </Grid>
  );
};
