import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeactivateMemberProp, ReceivedFeedbackDataProps, State } from "../../../type";
import { AttributeContent } from "./AttributeContent";
import { ReceivedFeedbackReportLayout } from "../../../layouts/admin/members/reports/ReceivedFeedbackReportLayout";
import ScrollToTopBtn from "../../admin/members/reports/ScrollOnTop";
import { getSelfReport } from "../../../redux/slices/SelfReportSlice";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { ActionCTAText } from "../../components/list/noRecordPage/NoRecordText";
import { Redirect } from "react-router";
import { Tabs } from "../../components/Tabs/Tabs";
import { map } from 'lodash';
import { getStartAndEndDateForCalendarDateRange } from "../../../util/GetDateInDesiredFormat";

const useStyle = makeStyles((theme) => ({
  noDataText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#868686",
    margin: theme.spacing(40, 0, 40, 0),
  }, img: {
    padding: theme.spacing(6.5, 0, 6.5, 0),
    height: theme.spacing(47),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      height: theme.spacing(26.8),
    },
  },
  text: {
    color: "#272727",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  btn: {
    marginTop: theme.spacing(4),
    backgroundColor: "#7C69EC",
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1, 0)
  },
}));

export const SelfReport = (props) => {
  const dispatch = useDispatch();
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const classes = useStyle();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [value, setValue] = React.useState<number>(0);

  const reportData: Array<ReceivedFeedbackDataProps> = useSelector((state: State) => state.selfReport.data) ?? [];

  const tabsData = useMemo(() => { return map(reportData, (item, index) => ({ key: index, label: item?.attributeName })) }, [reportData])


  useEffect(() => {
    let str = "";
    let dateRange = getStartAndEndDateForCalendarDateRange();
    str += `startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    const params: DeactivateMemberProp = {
      requestingParam: str,
    };
    dispatch(getSelfReport(params));
  }, []);

  useEffect(() => {
    setTabValue(0);
  }, [tabsData])
  
  const statusCode: number | null = useSelector((state: State) =>
    state.selfReport.status !== 200
      ? state.selfReport.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderNoRecordFoundText = () => (
    <Grid xs={12} item container justify="center" alignItems="center">
      <Typography
        className={classes.noDataText}
      >{`No record found`}</Typography>
    </Grid>
  );

  const renderAttributeTabs = () => (
    
    <Tabs
      tabValue={tabValue}
      setTabValue={setTabValue}
      data={tabsData}
    />
  );

  const renderAttributeContent = () => (
    <AttributeContent attributeIndex={tabValue}></AttributeContent>
  );

  const renderNoFeedbacks = () => (
    <><Grid item>
      <Grid item style={{ justifyContent: "center", display: "flex" }}>
        <img
          src="/NoFeedbacks.svg"
          alt="No_Feedback_Request"
          className={classes.img}
        ></img>
      </Grid>
      <Typography className={classes.text}>
        No records of feedback available here!
      </Typography>
      <Grid item style={{ justifyContent: "center", display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setValue(1);
          }}
          className={classes.btn}
        >
          <ActionCTAText from={"FEEDBACK_SUMMARY"} />
        </Button>
      </Grid>
    </Grid>
    </>
  );

  const renderMemberCard = () => { }
  const renderScrollOnTop = () => <ScrollToTopBtn />;

  const loading: Boolean = useSelector(
    (state: State) => state.selfReport.loading
  );

  if (value === 1) return <Redirect to={`/feedback/requests/employee`} />;

  return (
    <ReceivedFeedbackReportLayout
      props={props}
      loading={loading}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      memberName={"Self"}
      renderErrorModal={renderErrorModal}
      renderNoRecordText={renderNoRecordFoundText}
      renderNoFeedbacks={renderNoFeedbacks}
      renderAttributeTabs={renderAttributeTabs}
      renderMemberCard={renderMemberCard || null}
      renderAttributeContent={renderAttributeContent}
      renderScrollOnTop={renderScrollOnTop}
    ></ReceivedFeedbackReportLayout>
  );
};
