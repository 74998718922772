import React, { useEffect, useMemo } from 'react';
import { getMappedFeedbacks, reportAttributeDetailStyles } from './helper';
import { Avatar, Divider, Grid, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

export const ReportAttributeDetailComponent = ({ reportData, attributeIndex, isSelfReport = false }) => {
    const classes = reportAttributeDetailStyles();

    const mappedFeedbacks = useMemo(() => {
        const attributeFeedbacks = reportData[attributeIndex];
        const mappedData = getMappedFeedbacks(attributeFeedbacks);
        return mappedData;
    }, [reportData, attributeIndex]);

    useEffect(() => {
        if (window.scrollY > 167) {
            window.scrollTo({
                top: 167,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [attributeIndex])

    return (
        <Grid className={`${classes.wrapper} ${isSelfReport ? classes.wrapperSelf : ""}`}>
            {mappedFeedbacks?.map(feedback => (
                <Grid className={classes.listItemWrapper} key={feedback.feedbackId}>
                    <Grid className={classes.feedbackHeader}>
                        <Avatar
                            src={feedback?.imageUrl || ""}
                            alt={feedback?.userName}
                            className={classes.profilePic}
                        />
                        <Typography className={classes.name}>{feedback.userName}</Typography>
                        <Grid className={classes.dateWrapper}>
                            <Typography className={classes.receivedText}>Received on</Typography>
                            <Typography className={classes.date}>{format(parseISO(feedback.date), "do MMMM yyyy")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={classes.contentWrapper}>
                        <Grid className={classes.content}>
                            <Typography className={classes.title}>What was good</Typography>
                            <Typography className={classes.feedbackText}
                                dangerouslySetInnerHTML={{
                                    __html: feedback?.context ? feedback.context : "",
                                }}
                            />
                        </Grid>
                        {feedback?.suggestions && (
                            <>
                                <Divider className={classes.divider} />
                                <Grid className={classes.content}>
                                    <Typography className={classes.title}>Improvement Suggestions</Typography>
                                    <Typography className={classes.feedbackText}
                                        dangerouslySetInnerHTML={{
                                            __html: feedback?.suggestions ? feedback.suggestions : "",
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}