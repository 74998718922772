import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Redirect } from "react-router";
import { CustomDivider } from "../../client/share_feedback/LocalDivider";
import { RightsText } from "../../client/share_feedback/RightsText";
import { ButtonText, CannotFindPage, Error404 } from "./TextFile";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: "inherit",
    minHeight: "100vh",
  },
  btn: {
    margin: theme.spacing(3, 0, 6, 0),
    backgroundColor: "#576BEA",
    position: "relative",
    zIndex: 3,
  },
  img: {
    width: theme.spacing(62.5),
    height: theme.spacing(62.5),
    marginTop: "calc(-16%)",
    marginBottom: "calc(-12%)",
    marginLeft: "calc(-2%)",
    marginRight: "calc(-2%)",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(33.5),
      height: theme.spacing(33.5),
      marginTop: "calc(-6%)",
      marginBottom: "calc(-2%)",
      marginLeft: "calc(0%)",
      marginRight: "calc(0%)",
    },
  },
}));

export const PageNotFound = (props) => {
  const classes = useStyle();
  const [clicked, setClicked] = React.useState(false);
  const status: string = props?.location?.state?.status;

  if (props.location.pathname !== "/error") return <Redirect to={`/error`} />;
  else if (clicked) return <Redirect to={`/`} />;
  else
    return (
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        {status ? <Error404 client={true} /> : <Error404 />}
        <Grid container justify="center" alignItems="center">
          <Box justifyContent="center">
            <img
              src="/Page_Not_Found.gif"
              alt="Page_Not_Found"
              className={classes.img}
            ></img>
          </Box>
        </Grid>
        {status ? (
          <CannotFindPage client={true} status={status} />
        ) : (
          <CannotFindPage />
        )}
        {status ? (
          <></>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setClicked(true)}
            className={classes.btn}
          >
            <ButtonText />
          </Button>
        )}
        {status ? (
          <Grid
            xs={12}
            item
            style={{ position: "relative", margin: "40px 0 10px 0" }}
          >
            <CustomDivider />
          </Grid>
        ) : null}
        {status ? (
          <Grid style={{marginTop: "110px"}}>
            <RightsText></RightsText>
          </Grid>
        ) : null}
      </Grid>
    );
};
