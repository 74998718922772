import { Grid } from "@material-ui/core";
import React from "react";
import {
  UserName,
  ContextText,
  RenderDate,
  RenderTypeOfRequest,
  AttributeChipComponent,
  RenderAttributeText,
  RenderAttributesDetails,
  ActionCta,
  CustomDivider,
  Status,
} from "./DetailComponent";
import { DetailPageProp } from "./type";

export const DetailPage: React.FC<DetailPageProp> = ({
  from,
  date,
  user,
  context,
  attributeNames,
  attributes,
  setValue,
  requestId,
  status,
}) => {
  const renderTypeOfRequest = () => (
    <>
      {from === "RECEIVED_FEEDBACK" && (
        <RenderTypeOfRequest requestId={requestId} />
      )}
    </>
  );
  const renderDate = () => <RenderDate from={from} date={date} />;
  const renderUser = () => <UserName user={user} />;
  const renderContext = () => <>{context && <ContextText text={context} />}</>;
  const renderAttributesChip = () => (
    <>
      {(from === "SHARED_FEEDBACK" || from === "SENT_REQUEST") && (
        <AttributeChipComponent attributes={attributes} />
      )}
      {from === "RECEIVED_REQUEST" && attributeNames && (
        <AttributeChipComponent attributes={attributeNames} />
      )}
    </>
  );
  const renderAttributeHeader = () => <RenderAttributeText from={from} />;
  const renderAttributesDetail = () =>
    from !== "SENT_REQUEST" && (
      <RenderAttributesDetails attributes={attributes} />
    );
  const renderActionCta = () =>
    setValue && (
      <ActionCta from={from} userStatus={user?.status} setValue={setValue} />
    );
  const renderRequestStatus = () => status && <Status status={status} />;
  const renderDivider = () => <CustomDivider />;
  return (
    <>
      <Grid container justify="flex-start">
        {renderTypeOfRequest()}
        {renderDate()}
      </Grid>
      {renderUser()}
      {renderDivider()}
      {renderContext()}
      {context && renderDivider()}
      {renderAttributesChip()}
      {(from === "SHARED_FEEDBACK" ||
        from === "SENT_REQUEST" ||
        from === "RECEIVED_REQUEST") &&
        renderDivider()}
      {renderAttributeHeader()}
      {renderAttributesDetail()}
      {renderActionCta()}
      {renderRequestStatus()}
    </>
  );
};
