import {
  Grid,
  TextField,
  Box,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAttriubteSliceProp,
  addAttribute,
} from "../../../../redux/slices/AddAttributeSlice";
import { State } from "../../../../type";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { addAttributeModalStyles, checkValidation } from "./helper";
import { RESPONSE_TYPE_LIST } from "../../../../constants/questionConstants";
import { AnswerTypeEnum } from "../../../../enums/answerTypeEnums";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { OptionType } from "../../../components/Dropdown/helper";
import { HttpStatus } from "../../../../enums/httpStatus";

export const AddAttributeModal = ({ open, handleCancel }) => {
  const classes = addAttributeModalStyles();
  const [attributeName, setAttributeName] = useState<string>("");
  const [questionText, setQuestionText] = useState<string>("");
  const [selectedResponseType, setSelectedResponseType] = React.useState<OptionType | undefined>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [created, setCreated] = useState<boolean>(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const cancelClicked = (): void => {
    setAttributeName("");
    setQuestionText("");
    setSelectedResponseType(undefined);
    setErrors({});
    setSubmitted(false);
    handleCancel();
    setCreated(false);
  };

  useEffect(() => {
    setCreated(false);
  }, [errors])

  const attributeId = useSelector(
    (state: State) => state.addAttribute.data?.id
  );

  const { status, text } = useSelector(
    (state: State) => state.addAttribute
  );

  useEffect(() => {
    if (status === 400) {
      const apiError = JSON.parse(text)?.message;
      const validationErrors = { ...errors };
      validationErrors["attributeName"] = apiError;
      setErrors(validationErrors);
    }
  }, [status, text])

  useEffect(() => {
    if (submitted && attributeId && checkValidation({ attributeName, questionText, selectedResponseType, setErrors })) {
      history.push({
        pathname: `/admin/manage/attributes/${attributeId}`,
        state: { from: "manage_attributes", addedAttribute: true },
      });
      setSubmitted(false);
    }
  }, [attributeId, submitted]);

  useEffect(() => {
    if (submitted) {
      checkValidation({ attributeName, questionText, selectedResponseType, setErrors });
    }
  }, [attributeName, questionText, selectedResponseType]);

  const isAddingAttribute = useSelector((state: State) => state.addAttribute?.loading);
  const addAttributeStatus = useSelector((state: State) => state.addAttribute?.status);

  useEffect(() => {
    if (created && !isAddingAttribute && addAttributeStatus !== HttpStatus.OK) {
      setCreated(false)
    }
  }, [created, isAddingAttribute])

  const handleCreate = () => {
    setSubmitted(true);
    if (!created) {
      const isValid: boolean = checkValidation({ attributeName, questionText, selectedResponseType, setErrors });
      if (!isValid) {
        return;
      }

      const params: AddAttriubteSliceProp = {
        attributeName,
        status: "ACTIVE",
        questions: [
          {
            questionText,
            answerType: selectedResponseType?.key as AnswerTypeEnum
          }
        ],
      };
      setCreated(true);
      dispatch(addAttribute(params));
    }
  };

  return (
    <Modal open={open} loading={isAddingAttribute}>
      <ModalHeader title="Create New Attribute" handleCancel={cancelClicked} />
      <ModalBody>
        <Grid item xs={12}>
          <Box marginTop={2} marginBottom={3}>
            <InputLabel
              htmlFor="component-error"
              error={attributeName.length > 0}
              style={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "#272727",
                marginBottom: "12px",
              }}
            >
              Attribute Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Write attribute name here"
              variant="outlined"
              value={attributeName}
              helperText={errors?.attributeName}
              error={errors["attributeName"]}
              className={classes.attributeInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAttributeName(event.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
        {/* \Question Input */}
        <Grid item xs={12} className={classes.showQuestionsWrapper}>
          <Box marginBottom={3}>
            <InputLabel
              htmlFor="component-error"
              error={questionText.length === 0}
              style={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "#272727",
                marginBottom: "12px",
              }}
            >
              Question 1
            </InputLabel>
            <div
              style={{ color: "#A0A0A0", fontWeight: 400, fontSize: 12 }}
            >
              To create a new attribute, please make sure to add at least
              one question
            </div>
            <TextField
              fullWidth
              placeholder="Write a question here"
              variant="outlined"
              error={errors["questionText"]}
              value={questionText}
              helperText={errors?.questionText}
              className={classes.addQuestionInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestionText(event.target.value);
              }}
            ></TextField>
          </Box>
          <Grid container alignItems="flex-start">
            <div
              style={{ color: "rgb(39, 39, 39)", fontWeight: 500, fontSize: 14, paddingTop: 8 }}
            >
              Response Type:
            </div>
            <Grid className={classes.dropdownWrapper}>
              <Dropdown
                options={RESPONSE_TYPE_LIST}
                onSelect={setSelectedResponseType}
                selectedOption={selectedResponseType}
                placeholder="Select response"
              />
              {errors['responseType'] && <FormHelperText className={classes.helperText}>{errors['responseType']}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter buttonLabel="Create" handleCancel={cancelClicked} handleSubmit={handleCreate} disableActions={created} />
    </Modal >
  );
}
