import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import theme from "../../../theme";
import CssBaseline from '@material-ui/core/CssBaseline';

interface Props {
  renderAppLogo: () => React.ReactNode;
  renderTitle: () => React.ReactNode;
  renderImage: () => React.ReactNode;
  renderCta: () => React.ReactNode;
  renderCorporateLogin: () => React.ReactNode;
  renderErrorModal: () => React.ReactNode;
  renderFooter: () => React.ReactNode;
}

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      height: window.innerHeight,
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(70),
    },
  },
  leftSide: {
    height: "inherit",
    backgroundColor: "#576BEA",
    borderRadius: theme.spacing(0, 1.25, 1.25, 0),
    [theme.breakpoints.down("md")]: {
      height: window.innerHeight,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(0, 0, 1.25, 1.25),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(70),
    },
  },
  title: {
    height: theme.spacing(22.5),
    width: theme.spacing(75.375),
    margin: theme.spacing(0, 0, 2, 5),
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(6),
      width: theme.spacing(33.5),
      justifyContent: "center",
    },
  },
  signInCta: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4, 0, 0, 0),
    },
  },
  logo: {
    margin: theme.spacing(0.5, 0, 0, 4),
  },
  loginWrapper: {
     marginLeft:'auto',
    marginRight:'auto',
    height:'auto',
    width:'auto',
  },
  styleForMobile: {
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
}));

export const SignInLayout: React.FC<Props> = ({
  renderAppLogo,
  renderTitle,
  renderImage,
  renderCta,
  renderCorporateLogin,
  renderErrorModal,
  renderFooter
}) => {
  const classes = useStyle();
  document.title = "Grow-Wize | Sign In";

  return (
    <Grid container className={classes.root}>
      {renderErrorModal()}
      <Grid
        item
        xs={12}
        md={7}
        container
        justify="center"
        direction="row"
        className={classes.leftSide}
      >
        <Hidden smDown>
          <Grid container justify="flex-start" className={classes.logo}>
            <Grid item>{renderAppLogo()}</Grid>
          </Grid>
        </Hidden>
        <Grid
          container
          xs={12}
          item
          direction="row"
          className={classes.styleForMobile}
        >
          <Grid xs={2} md={1} lg={1} item></Grid>
          <Hidden mdUp>
            <Grid xs={12} md={1} item>
              {renderAppLogo()}
            </Grid>
          </Hidden>
          <Grid xs={12} md={11} lg={8} item container className={classes.title}>
            {renderTitle()}
          </Grid>
        </Grid>
        <Grid container justify="center">
          {renderImage()}
        </Grid>
        <Grid container justify="center">
          {renderFooter()}
        </Grid>

      </Grid>

        <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                md={5}
                className={classes.signInCta}>

               <Grid >
                     <Grid item> {renderCta()}</Grid>
                     <Grid item> {renderCorporateLogin()}</Grid>
                 </Grid>
        </Grid>
    </Grid>
  );
};
