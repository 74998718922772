import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GenerateFeedbackReportProps,
  ReceivedFeedbackDataProps,
} from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const fetchReceivedFeedbackByDateRange = createAsyncThunk(
  "report/feedback/received/filterByDateRange",
  async (params: GenerateFeedbackReportProps, storeActions) => {
    const url = `api/v1/admin/feedback/received/consolidate/detail?memberId=${params.memberId}&startDate=${params.startDate}&endDate=${params.endDate}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const receivedFeedbackReportByDateRange = createSlice({
  name: "receivedFeedbackReportByDateRange",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    errorMessage: null as string | null,
    receviedFeedbacksByDateRange:
      null as Array<ReceivedFeedbackDataProps> | null,
  },
  reducers: {
    clearGeneratePDFState: (state) => {
      state.status = null;
      state.loading = false;
      state.errorMessage = null;
      state.receviedFeedbacksByDateRange = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchReceivedFeedbackByDateRange.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.loading = false;
        if (action.payload.data !== undefined) {
          state.receviedFeedbacksByDateRange = action.payload.data;
          state.errorMessage = null;
        } else if (action.payload.text !== undefined) {
          state.receviedFeedbacksByDateRange = null;
          state.errorMessage = action.payload.text;
        }
      }
    );
    builder.addCase(fetchReceivedFeedbackByDateRange.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.receviedFeedbacksByDateRange = null;
      state.errorMessage = null;
    });
    builder.addCase(fetchReceivedFeedbackByDateRange.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.receviedFeedbacksByDateRange = null;
      state.errorMessage = null;
    });
  },
});

export const selectfetchReceivedFeedbackByDateRangeStatus = (state) =>
  state.generatefeedbackReportState.status;
export const selectFetchFeedbackReportByDateRangeLoading = (state) =>
  state.generatefeedbackReportState.loading;
export const selectFetchfeedbackReportErrorMessage = (state) =>
  state.generatefeedbackReportState.errorMessage;
export const selectFeedbackReportsByDateRangeData = (state) =>
  state.generatefeedbackReportState.receviedFeedbacksByDateRange;

export const { clearGeneratePDFState } =
  receivedFeedbackReportByDateRange.actions;

export default receivedFeedbackReportByDateRange;
