import React from "react";
import { RecentActivityDetailProp } from "../../../type";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { getDateInActivityFormat } from "../../../util/GetDateInActitivityFormat";

export const UserOnBoardedActivityCard: React.FC<RecentActivityDetailProp> = ({
  activityUser,
  activityDate,
}) => {
  return (
    <Grid
      xs={12}
      item
      container
      justify="flex-start"
      style={{ padding: "14px 14px 14px 14px" }}
      alignItems="center"
    >
      <Grid
        xs={9}
        item
        container
        justify="flex-start"
        alignItems="center"
        direction="row"
      >
        <Grid xs={1} item style={{ marginRight: "10px"}}>
          <Avatar
            src={activityUser?.imageUrl}
            alt={activityUser?.firstName}
            style={{ height: "40px", width: "40px" }}
          />
        </Grid>
        <Grid xs item container direction="column">
          <Grid xs={12} item>
            <Typography
              variant="h5"
              style={{ textAlign: "start", wordWrap: "break-word", marginLeft: "10px" }}
            >
              {
                <span style={{ color: "#272727" }}>
                  {activityUser?.firstName + " " + activityUser?.lastName}
                </span>
              }{" "}
              joined Grow-Wize{" "}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography
              variant="h4"
              style={{
                marginLeft: "10px",
                textAlign: "start",
                fontSize: "10px",
                marginTop: "4px",
              }}
            >
              {activityDate && getDateInActivityFormat(new Date(activityDate))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={1} item container justify="flex-end"></Grid>
    </Grid>
  );
};
