import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  typography_body: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export const NoDraftText = () => {
  const classes = useStyle();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "50vh" }}
    >
      <Typography variant="h4" className={classes.typography_body}>
        No saved drafts
      </Typography>
    </Grid>
  );
};
