import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ErrorPopUp } from "../../../components/ErrorPages/errorModal";
import { ClearAllData } from "../../../constants/clearData";
import { RequestFeedbackFormLayout } from "../../../../layouts/user/request_feedback/requestFeedbackFormLayout";
import { requestFeedback } from "../../../../redux/slices/RequestFeebackSlice";
import { Props, ResponseAttribute, State, User } from "../../../../type";
import { RequestFeedbackFormProps } from "../typeRequestFeedback";
import { AddButton, CancelButton, SendButton } from "./buttons";
import { ConsentModal } from "./popUp";
import {
  AdditionalInfoText,
  AttributeChips,
  AttributeSelectionNoteText,
  AttributeSelectionText,
  ChooseMemberText,
  ContextText,
  FormDivider,
  OptionalText,
  SelectAttributesText,
  SelectColleagues,
} from "./requestFeedbackText";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ValidationPromptModal } from "../../../components/ValidationModal";
import { ContextTextEditorComponent } from "../ContextText";

export const RequestFeedbackForm: React.FC<RequestFeedbackFormProps> = ({
  setValue,
  user,
  context,
  setContext,
  selectedAttributeIds,
  setSelectedAttributeIds,
}) => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>(
    user ? [user] : []
  );
  const [showModal, setShowModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [dispatched, setDispatched] = React.useState(false);
  const [dataCleared, setDataCleared] = React.useState<Boolean>(false);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isAnyUser, setIsAnyUser] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState(
    context.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(context).contentBlocks,
            convertFromHTML(context).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const [showToolbar, setShowToolbar] = React.useState<Boolean>(false);

  const renderFormDivider = () => <FormDivider />;

  const onEditorStateChange = (state) => {
    setEditorState(state);
    setContext(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  const statusCode: number | null = useSelector(
    (state: State) => state.request.status
  );

  const history = useHistory();
  const goToPreviousPath = () => {
    if (history.length > 10) {
      history.goBack();
    } else {
      history.push("/");
    }
  };
  const logoutUser = () => {
    setLogout(true);
  };

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={showErrorModal}
      setShowModal={setShowErrorModal}
      statusCode={statusCode}
      handleNoAuthorization={logoutUser}
      handleAccessDenied={goToPreviousPath}
    ></ErrorPopUp>
  );

  const renderModal = () => (
    <ConsentModal
      showModal={showModal}
      setShowModal={setShowModal}
      setValue={setValue}
      to={"REQUEST_EMPLOYEE"}
    ></ConsentModal>
  );

  const renderChooseMemberText = () => <ChooseMemberText role={"EMPLOYEE"} />;

  const renderSelectColleagues = () => (
    <SelectColleagues isAnyUser={isAnyUser} role={"EMPLOYEE"} />
  );

  const renderAddButton = () => (
    <AddButton
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    ></AddButton>
  );

  const renderSelectAttributesText = () => <SelectAttributesText />;

  const renderAttributeSelectionText = () => <AttributeSelectionText />;

  const renderAttributeSelectionNoteText = () => <AttributeSelectionNoteText />;

  const emptyAttributes: Array<ResponseAttribute> = [];
  const attributes: Array<ResponseAttribute> =
    useSelector((state: State) => state.data.data?.attributes) ??
    emptyAttributes;

  const renderAttributes = () => (
    <AttributeChips
      attributes={attributes}
      selectedAttributeIds={selectedAttributeIds}
      setSelectedAttributeIds={setSelectedAttributeIds}
    ></AttributeChips>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const renderValidationModal = () => (
    <ValidationPromptModal
      open={open}
      setOpen={setOpen}
      charLimitExceeded={true}
    />
  );

  const renderContextText = () => <ContextText role={"EMPLOYEE"} />;

  const renderOptionalText = () => <OptionalText />;

  const renderAdditionalInfoText = () => <AdditionalInfoText alert={false} />;

  const renderTextField = () => (
    <ContextTextEditorComponent
      showToolbar={showToolbar}
      setShowToolbar={setShowToolbar}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      context={context}
    />
  );

  const renderCancelButton = () => (
    <CancelButton setValue={setValue}></CancelButton>
  );

  const renderSendButton = () => <SendButton />;

  function handleSubmit(e) {
    e.preventDefault();
    if (selectedUsers.length === 0) {
      setIsAnyUser(true);
    } else if (context.length > 10000) {
      handleOpen();
    } else {
      const arg: Props = {
        usersRequested: selectedUsers.map((user) => user.id),
        attributes: selectedAttributeIds,
        context: context
          .replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
          .trim(),
      };
      dispatch(requestFeedback(arg));
      setDispatched(true);
    }
  }

  const { status, loading } = useSelector((state: State) => state.request);

  useEffect(() => {
    if (dispatched === true && status === 201 && loading === false) {
      setSelectedUsers([]);
      setSelectedAttributeIds([]);
      setContext("");
      setEditorState(EditorState.createEmpty());
      setShowModal(true);
      setDispatched(false);
    } else if (dispatched === true && status !== null && loading === false) {
      setDispatched(false);
      setShowErrorModal(true);
    }
  }, [dispatched, status, loading]);

  const attributesLoading: Boolean = useSelector(
    (state: State) => state.data.loading
  );

  if (logout && !dataCleared) ClearAllData(setDataCleared);

  if (logout && dataCleared) {
    return <Redirect to="/"></Redirect>;
  }

  const renderWarningText = () => <></>;

  return (
    <RequestFeedbackFormLayout
      loading={attributesLoading || loading}
      usersArrayLength={selectedUsers.length}
      renderValidationModal={renderValidationModal}
      renderFormDivider={renderFormDivider}
      renderErrorModal={renderErrorModal}
      renderModal={renderModal}
      handleSubmit={handleSubmit}
      renderChooseMemberText={renderChooseMemberText}
      renderSelectColleagues={renderSelectColleagues}
      renderAddButton={renderAddButton}
      renderSelectAttributesText={renderSelectAttributesText}
      renderAttributeSelectionText={renderAttributeSelectionText}
      renderAttributes={renderAttributes}
      renderAttributeSelectionNoteText={renderAttributeSelectionNoteText}
      renderContextText={renderContextText}
      renderOptionalText={renderOptionalText}
      renderAdditionalInfoText={renderAdditionalInfoText}
      renderTextField={renderTextField}
      renderCancelButton={renderCancelButton}
      renderSendButton={renderSendButton}
      isValidEmail={false}
      renderWarningText={renderWarningText}
    ></RequestFeedbackFormLayout>
  );
};
