import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const vriddhiResponseOptionsStyle = makeStyles((theme: Theme) =>
  createStyles({
    vriddhiResponseChip: {
      justifyContent: "center",
      textAlign: "center",
      width: theme.spacing(10),
      height: theme.spacing(6),
      padding: "25px 0px 25px 10px",
      margin: "30px 45px 16px 45px",
      borderRadius: "24px",
      backgroundColor: "#FFFFFF",
      border: "1px solid rgba(175,175,175,0.3)",
      boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.15)",
      "&.selected": {
        borderColor: "#8BC660 !important",
      },
      "&:hover:not(.selected)": {
        backgroundColor: "#FFFFFF",
        borderColor: "#576BEA !important",
      },
      "&:active": {
        backgroundColor: "#FFFFFF",
      },
      [theme.breakpoints.down("md")]: {
        padding: "25px 0px 25px 10px",
      },
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(6.25),
        height: theme.spacing(3.75),
        padding: "1px 0px 1px 10px",
        margin: "16px 12px 16px 9px",
      },
    },
    textBox: {
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    remainingChar: {
      fontSize: theme.spacing(1.5),
      textAlign: "end",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.125),
      },
    },
    textBoxx: {
      margin: theme.spacing(0),
      padding: theme.spacing(0.5, 2.5, 1, 2.5),
      width: "-webkit-fill-available",
      flexWrap: "wrap",
      wordWrap: "break-word",
    },
    textBoxx1: {
      width: "-moz-available",
    },
    avatar: {
      borderRadius: 0,
      margin: "1px 0px 1px 15px",
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(2.625),
        height: theme.spacing(2.625),
        margin: "1px 0px 1px 0px",
      },
    },
    wrapperClassName: {
      height: "100%",
    },
    avatarVriddhiResponseText: {
      textAlign: "center",
      margin: theme.spacing(0, 0, 0, 0.5),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 1, 0, 0.5),
      },
    },
    vriddhiGrid: {
      margin: theme.spacing(0, 0, 4, -1.75),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 0, 3, 1.5),
      },
    },
    avatarInfo: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(1.625),
        width: theme.spacing(1.625),
      },
    },
    chipStyle: {
      margin: theme.spacing(1.5, 0, 0, 0),
      borderColor: "#576bea",
      width: theme.spacing(16),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(12.5),
      },
    },
  })
);
