import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get } from "../../util/APIService";

export const fetchFeedbackCounts = createAsyncThunk(
  "roundRobinFeedback/fetchFeedbackCounts",
  async (
    { teamId, startDate, endDate }: { teamId: number; startDate: string; endDate: string },
    storeActions
  ) => {
    const url = `api/v1/admin/teams/${teamId}/feedbackCounts?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await get(url, storeActions);
      if (!response) {
        throw new Error("No response received");
      }
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const roundRobinFeedbackSlice = createSlice({
  name: "roundRobinFeedback",
  initialState: {
    feedbackCounts: null,
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearFeedbackCounts: (state) => {
      state.feedbackCounts = null;
      state.loading = false;
      state.status = null;
    },
    clearData: (state) => {
      state.feedbackCounts = null;
      state.loading = false;
      state.status = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFeedbackCounts.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.feedbackCounts = payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchFeedbackCounts.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(fetchFeedbackCounts.rejected, (state) => {
      state.loading = false;
      state.status = 503;
    });
  },
});

export const { clearFeedbackCounts, clearData } = roundRobinFeedbackSlice.actions;
export default roundRobinFeedbackSlice;
