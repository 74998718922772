import {Avatar, Chip, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { MembersLayout } from "../../../layouts/admin/members/Layout";
import { getAllMemberList } from "../../../redux/slices/AllMemberList";
import { GetAllMemberListSliceProps, State, User } from "../../../type";
import { MemberCard } from "./ShowMemberCard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useCustomStyle} from "../../../styles";
import {appColor} from "../../constants/customThemeColor";

const useStyle = makeStyles((theme) => ({
  root: {
    height: theme.spacing(62.85),
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(2),
    border: "1px solid #E7E7E7",
    borderRadius: theme.spacing(1.25),
    boxShadow: "0 2px 4px 0 rgba(90,90,90,0.1)",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 4, 0),
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
      margin: theme.spacing(3, 0, 4, 0),
    },
  },
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: `${appColor.whiteBackground}`,
    width: "-webkit-fill-available",
    "&:focus": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
    "&:hover": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
  },
  editor: {
    width: "-webkit-fill-available",
    backgroundColor: appColor.pureWhite,
  },
  marginStyle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(1),
    },
  },
  shareCTA: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: appColor.pureWhite,
    backgroundColor: appColor.regularBackgroundColor,
    padding: theme.spacing(1, 2.5),
    marginTop: theme.spacing(3),
    "&:hover": {
      color: appColor.pureWhite,
      backgroundColor: appColor.regularBackgroundColor,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    color: appColor.pureBlack,
  },
  chip: {
    margin: theme.spacing(5, 0, 1, 0),
    borderColor: "#576BEA",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  typography_header_style: {
    margin: theme.spacing(1.5, 0, 0.5, 0),
  },
}));

export const Members = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.auth.token);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [openReport, setOpenReport] = React.useState<boolean>(false);
  const [memberDetail, setMemberDetail] = React.useState<User | null>(null);
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const users: Array<User> = [];

  const labels = useSelector((state: State) => state.data.data?.users) ?? users;
  const history = useHistory();
  const classes = useStyle();
  const customClasses = useCustomStyle();

  useEffect(() => {
    const params : GetAllMemberListSliceProps = {
      isActiveUser: false
    };
    const fetchedUsers = getAllMemberList(params);
    dispatch(fetchedUsers);
  }, []);

  const emptyList: Array<User> = [];
  const membersList: Array<User> =
      useSelector((state: State) => state.membersList.data) ?? emptyList;
  const loading: boolean = useSelector(
      (state: State) => state.membersList.loading
  );

  const [filteredUsersList, setFilteredUsersList] = React.useState<User[] | null>(null);
  const statusCode: number | null = useSelector((state: State) =>
      state.membersList.status !== 200
          ? state.membersList.status
          : state.deactivateMember.status !== 200
              ? state.deactivateMember.status
              : state.changeMemberAccessRole.status !== 200
                  ? state.changeMemberAccessRole.status
                  : null
  );

  const filterByUser = (filteredData) => {
    if (!selectedUser) {
      return filteredData;
    }

    const filteredUsers = filteredData.filter(
        (user) => user.id === selectedUser.id);
    return filteredUsers;
  };

  const handleUserSelection = (event) => {
    setSelectedUser(event.target.value);
  };

  useEffect(() => {
    if(filteredUsersList &&  filteredUsersList[0]) {
      setFilteredUsersList(filterByUser(membersList));
    }else{
      setFilteredUsersList(membersList);
    }
  }, [selectedUser,membersList]);

  const renderErrorModal = () => (
      <CommonFunctionalityComponent
          props={props}
          logout={logout}
          setLogout={setLogout}
          statusCode={statusCode}
      />
  );

  const renderSearchFilter = () => (
      <>
        {selectedUser && (
            <Chip
                key={selectedUser.id}
                variant="outlined"
                onDelete={() => setSelectedUser(null)}
                avatar={
                  <Avatar
                      alt={selectedUser.firstName}
                      src={selectedUser.imageUrl}
                      className={classes.small}
                  />
                }
                label={
                  <Typography
                      variant="h6"
                      className={`${classes.typography_body_3} ${customClasses.typography_font_14_10}`}
                  >
                    {selectedUser.firstName + " " + selectedUser.lastName}
                  </Typography>
                }
                className={classes.chip}
            />
        )}{selectedUser === null && (
          <>
            <Typography
                variant="h6"
                style={{ fontSize: "14px" }}
                className={classes.typography_header_style}
            >
              Choose member
            </Typography>
            <Autocomplete
                id="tags-filled"
                data-testid="autocomplete"
                className={`${classes.editor} ${classes.popper} ${customClasses.popperWidth} ${classes.marginStyle} dashboard-autocomplete`}
                freeSolo
                value={selectedUser}
                getOptionSelected={(option, value) =>
                    option.id === value.id
                }
                onChange={(event, newValue) => {
                  if (typeof newValue !== "string")
                    setSelectedUser(newValue);
                }}
                filterSelectedOptions
                disableCloseOnSelect
                options={membersList}
                getOptionLabel={(option) =>
                    option.firstName &&
                    option.firstName + " " + option.lastName
                }
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Grid container item xs={12} alignItems="center">
                        <Grid
                            item
                            xs={10}
                            container
                            justify="flex-start"
                            alignItems="center"
                        >
                          <Avatar
                              alt={option.firstName + " " + option.lastName}
                              src={option.imageUrl}
                              className={customClasses.avatarSmall}
                          />
                          <div className={classes.typography_body_3}>
                            <Typography
                                variant="body1"
                                className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_3}`}
                            >
                              {option.firstName + " " + option.lastName}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Search member by name"
                        variant="outlined"
                    />
                )}
            />
          </>
      )}
      </>
  )
  const renderMemberList = () => (
      <>
        <Grid container justify="flex-start">
          {filteredUsersList &&  filteredUsersList[0] &&
              filteredUsersList.map((member, idx) => (
                  <Grid
                      container
                      md={2}
                      sm={3}
                      xs={12}
                      justify="center"
                      key={`member-${idx}`}
                  >
                    <MemberCard
                        firstName={member.firstName}
                        lastName={member.lastName}
                        imageUrl={member.imageUrl}
                        id={member.id}
                        status={member.status}
                        setOpenReport={setOpenReport}
                        setMemberDetail={setMemberDetail}
                        member={member}
                    ></MemberCard>
                  </Grid>
              ))}
        </Grid>
      </>
  );

  if (openReport && memberDetail) {
    history.push({
      pathname: `/admin/member/detail/${memberDetail.id}`,
      state: {
        member: memberDetail,
      },
    });
  }

  return (
      <MembersLayout
          props={props}
          loading={loading}
          setLogout={setLogout}
          isDetailView={isDetailView}
          setIsDetailView={setIsDetailView}
          renderErrorModal={renderErrorModal}
          renderMemberList={renderMemberList}
          renderSearchFilter={renderSearchFilter}
      ></MembersLayout>
  );
};
