import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deactivateMember } from "../../../redux/slices/DeactivateMemberSlice";
import { changeMemberAccessRole } from "../../../redux/slices/UpdateMemberAccessRole";
import { DeactivateMemberProp, State } from "../../../type";
import { DeactivateUserModalComponent } from "./DeactivateMemberModal";
import { MemberCardProps } from "./type";

const useStyle = makeStyles((theme) => ({
  profilePic: {
    height: theme.spacing(22.5),
    width: "inherit",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(26.5),
    },
  },
  userName: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#272727",
    textAlign: "start",
    wordWrap: "break-word",
    padding: theme.spacing(0, 1.5),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: theme.spacing(0),
    },
  },
  memberGrid: {
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    height: theme.spacing(30),
    width: "inherit",
    margin: theme.spacing(3, 1, 3, 1),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(34.5),
    },
  },
  hoveredView: {
    position: "absolute",
    display: "flex",
    height: theme.spacing(22.5),
    width: "inherit",
    backgroundColor: "#000000",
    top: theme.spacing(0),
    right: theme.spacing(0),
    opacity: 0.81,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(26.5),
    },
  },
  detailViewCTA: {
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(5),
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
  },
  moreViewCTA: {
    height: theme.spacing(1),
  },
  moreViewItem: {
    fontSize: theme.spacing(1.25),
    textAlign: "start",
    fontWeight: 500,
    color: "#272727",
    fontFamily: "Montserrat",
    backgroundColor: "#FFFFFF",
  },
}));

const SWITCH_ROLE_MAP = {
  REGULAR: "ADMIN",
  ADMIN: "REGULAR",
};

export const MemberCard: React.FC<MemberCardProps> = ({
  firstName,
  lastName,
  imageUrl,
  status,
  id,
  setOpenReport,
  setMemberDetail,
  member,
}) => {
  const classes = useStyle();
  let str = "";
  if (imageUrl) {
    for (let i = 0; i < imageUrl.length; i++) {
      if (imageUrl[i] !== "=") {
        str += imageUrl[i];
      } else {
        str += "=s598-c";
        imageUrl = str;
        break;
      }
    }
  }

  const [lock, setLock] = React.useState<Boolean>(false);
  const [userRole, changeRole] = React.useState<string>(member?.userType);
  const [isHovered, setIsHovered] = React.useState<Boolean>(false);
  const [moreOpt, setMoreOpt] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const token = useSelector((state: State) => state.auth.token);
  const loggedInUserEmail = useSelector((state: State) => state.auth.email);
  const dispatch = useDispatch();
  const changeRoleStatus = useSelector(
    (state: State) => state.changeMemberAccessRole.status
  );
  useEffect(() => {
    if (changeRoleStatus === 200 && lock) {
      changeRole(SWITCH_ROLE_MAP[userRole]);
      setLock(false);
    }
  }, [changeRoleStatus]);

  async function handleCancel(e) {
    e.preventDefault();
    setOpen(false);
  }

  const handleDeactivateAccount = (id) => {
    const arg: DeactivateMemberProp = {
      memberId: id,
      tag: "INTERNAL",
    };
    dispatch(deactivateMember(arg));
    setOpen(false);
  };

  const renderDeactivateMemberModal = () => (
    <DeactivateUserModalComponent
      id={id}
      open={open}
      firstName={firstName}
      lastName={lastName}
      handleCancel={handleCancel}
      handleDeactivateAccount={handleDeactivateAccount}
    />
  );

  return (
    <Grid
      item
      container
      xs={12}
      justify="flex-start"
      direction="row"
      className={classes.memberGrid}
    >
      {" "}
      {renderDeactivateMemberModal()}
      <Grid
        item
        xs={12}
        container
        justify="center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          setMoreOpt(false);
        }}
        className={classes.profilePic}
      >
        {!imageUrl || imageUrl === firstName ? (
          <Avatar
            src={imageUrl || ""}
            alt={firstName?.toUpperCase()}
            className={classes.profilePic}
            style={{ fontSize: "150px", backgroundColor: "#FFFFFF", color: "#A0A0A0" }}
          />
        ) : (
          <img
            src={imageUrl}
            alt="member_img"
            className={classes.profilePic}
          ></img>
        )}
        {isHovered && (
          <Grid className={classes.hoveredView} container>
            {status === "ACTIVE" && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                  className={classes.moreViewCTA}
                >
                  <IconButton onClick={() => setMoreOpt(true)}>
                    <img src="/moreViewCTA.svg" alt="more"></img>
                  </IconButton>
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                  {moreOpt && (
                    <List component="nav">
                      {member.emailId !== loggedInUserEmail && (
                        <ListItem
                          style={{ backgroundColor: "#FFFFFF", opacity: 1 }}
                          button
                          onClick={() => {
                            dispatch(
                              changeMemberAccessRole({
                                userType: SWITCH_ROLE_MAP[userRole],
                                memberId: id,
                              })
                            );
                            setLock(true);
                          }}
                        >
                          <Typography className={classes.moreViewItem}>
                            {userRole === "REGULAR"
                              ? "Assign Admin Role"
                              : "Revoke Admin Role"}
                          </Typography>
                        </ListItem>
                      )}
                      <ListItem
                        style={{ backgroundColor: "#FFFFFF", opacity: 1 }}
                        button
                        onClick={() => setOpen(true)}
                      >
                        <Typography className={classes.moreViewItem}>
                          Deactivate
                        </Typography>
                      </ListItem>
                    </List>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} container justify="center" alignItems="center">
              <Button
                variant="outlined"
                className={classes.detailViewCTA}
                onClick={() => {
                  setMemberDetail(member);
                  setOpenReport(true);
                }}
              >
                VIEW DETAILS
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.userName}>
          {firstName + " " + lastName}
        </Typography>
      </Grid>
    </Grid>
  );
};
