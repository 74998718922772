import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DraftListLayout } from "../../../../layouts/user/share_feedback/draft/Layout";
import { getSavedDrafts } from "../../../../redux/slices/SavedDraftsSlice";
import { SharedFeedbacksParams, State, UserAndAttributeSliceProps } from "../../../../type";
import {
  SharedFeedback,
} from "../../shared_feedback/typeSharedFeedbacks";
import { DraftContainer } from "./draftContainer";
import { clearData as clearSharedDraftDetail } from "../../../../redux/slices/SharedDraftDetailSlice";
import { requestData } from "../../../../redux/slices/UsersAndAttributesSlice";

const Drafts = ({
  setSelectedDraftId,
  feedbacks,
  setSelectedUser,
  setTabValue,
  setShowDraftWithId,
  setShareFeedbackData,
}) => {
  const loading: Boolean = useSelector(
    (state: State) => state.savedDrafts.loading || state.deleteDraft.loading
  );

  return (
    <DraftContainer
      setTabValue={setTabValue}
      loading={loading}
      setRequestedUserId={setSelectedDraftId}
      feedbacks={feedbacks}
      setSelectedUser={setSelectedUser}
      setShowDraftWithId={setShowDraftWithId}
      setShareFeedbackData={setShareFeedbackData}
    ></DraftContainer>
  );
};

export const DraftList = ({
  setSelectedDraftId,
  setSelectedUser,
  setTabValue,
  setShowDraftWithId,
  setShareFeedbackData,
  setAttributes,
  setIsContext,
  onDataChange,
}) => {
  const dispatch = useDispatch();
  const deleteDraftLoading: Boolean = useSelector(
    (state: State) => state.deleteDraft.loading
  );

  useEffect(() => {
    setSelectedUser(undefined);
    setSelectedDraftId(-1);
    setShowDraftWithId(-1);
    setShareFeedbackData([]);
    setAttributes([]);
    setIsContext(false);
    onDataChange(false);
    if (!deleteDraftLoading) {
      let requestParams: string = "";
      requestParams += "?offSet=" + 0;
      let requestingParams: SharedFeedbacksParams = {
        requestingParams: requestParams,
      };
      const param: UserAndAttributeSliceProps = {
        isUsers: true,
        isAttributes: true,
        isClients: false,
        isDrafts: true,
        isRequests: true,
        isScale: true,
      }
      dispatch(requestData(param));
      dispatch(getSavedDrafts(requestingParams));
      dispatch(clearSharedDraftDetail());
    }
  }, [dispatch, deleteDraftLoading]);

  const drafts: Array<SharedFeedback> =
    useSelector((state: State) => state.savedDrafts.data) ?? [];

  const renderDrafts = () => (
    <Drafts
      feedbacks={drafts}
      setSelectedDraftId={setSelectedDraftId}
      setSelectedUser={setSelectedUser}
      setTabValue={setTabValue}
      setShowDraftWithId={setShowDraftWithId}
      setShareFeedbackData={setShareFeedbackData}
    ></Drafts>
  );

  return (
    <DraftListLayout
      loading={false}
      renderList={renderDrafts}
    ></DraftListLayout>
  );
};
