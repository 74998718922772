import {
  Avatar,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import theme from "../../theme";
import { State, ToolbarProp } from "../../type";
import { FullScreenDialog } from "../faqs/FullScreenDialog";
import { HeaderDisplayText, StatusText } from "./HomeText";
import { setUnsavedTeamChanges } from "../../redux/slices/CreateTeamSlice";
import { CreateTeamCancelModal } from "../admin/teams/createTeams/createTeamCancelModal/createTeamCancelModal";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      margin: theme.spacing(0, 0, 0, -2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    detailBtn: {
      margin: theme.spacing(4.25, -5, 0, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(4.25, -8.5, 0, 0),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(4.25, 0, 0, 0),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0),
      },
    },
    detailBtnWithProfile: {
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(-9.25, 0, 0, 0),
      },
    },
    list: {
      width: theme.spacing(38.75),
      height: theme.spacing(15.25),
      backgroundColor: "#FFFFFF",
      boxShadow: "-5px 9px 19px 0 rgba(90,90,90,0.4)",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        boxShadow: "-5px 19px 16px 15px rgb(90 90 90 / 40%)",
        width: theme.spacing(26.75),
        height: theme.spacing(10.25),
      },
    },
    listTopGrid: {
      margin: theme.spacing(11, 0, 0, -0.5),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(11, 0, 0, 0.5),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(6, 0, 0, -1),
      },
    },
    topGrid: {
      margin: theme.spacing(0, 0, 0, -0.5),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 0, -1),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(-2, 0, 0, -1),
      },
    },
    listTopGrid2: {
      margin: theme.spacing(17, 0, 0, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 0, 0),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(-3, 0, 0, 0),
      },
    },
    topGrid2: {
      margin: theme.spacing(2, 0, 0, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 0, 0, 0),
      },
    },
    profileBtn: {
      minWidth: "1px",
      backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    profileBtnItem: {
      color: "#A0A0A0",
      backgroundColor: "#FFFFFF",
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      margin: theme.spacing(2, 0, 1, 0),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    profileBtnItem2: {
      color: "#272727",
      backgroundColor: "#FFFFFF",
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      margin: theme.spacing(1.5, 0, 1.5, 0),
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    headerName: {
      [theme.breakpoints.down("xl")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 0, 0, 0),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 0, 0.5),
        textAlign: "start",
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 0, 0, 0),
        textAlign: "start",
      },
    },
    headerName2: {
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(-9, 0, 0, 0),
        textAlign: "start",
      },
    },
    headerGrid: {
      marginTop: theme.spacing(-11.25),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(-6.25, 0, 0, 0),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(9.75, 0, 0, 0),
      },
    },
    headerDetail: {
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 0, 0, 5),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 0, 0),
        textAlign: "start",
      },
    },
    headerDetail2: {
      marginTop: theme.spacing(-11.25),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(-11.25, 0, 0, 7),
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(-6.25, 0, 0, 0),
      },
    },
    gridWithDetail: {
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(-2.5, 0, 0, 0),
      },
    },
    backCta: {
      margin: theme.spacing(-0.95, 0, 0, 0),
      color: "black",
    },
    toolTip: {
      height: theme.spacing(4),
      width: theme.spacing(8),
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      fontColor: "#FFFFFF",
      backgroundColor: "#272727",
      textAlign: "center",
      padding: theme.spacing(1, 1.25),
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(0.875),
        height: theme.spacing(2),
        width: theme.spacing(4),
        padding: theme.spacing(0.5, .75),
      },
    },
    arrow: {
      color: "#272727",
    },
  })
);

export const ToolBar: React.FC<ToolbarProp> = ({
  headerName,
  setLogout,
  isDetailView,
  setIsDetailView,
  handleDrawerToggle,
  value,
  setValue,
  tabValue,
  validLength,
  isDataChange,
  savedDraftDate,
  onAnyChange,
}) => {
  const classes = useStyles();
  const [detailView, setDetailView] = React.useState<Boolean>(isDetailView);
  const [open, setOpen] = React.useState<Boolean>(false);
  const [toolTip, setToolTip] = React.useState<boolean>(false);
  const { name, imageUrl } = useSelector((state: State) => state.auth);
  const [showFAQ, setShowFAQ] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setOpen(false);
    setLogout(true);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const unsavedTeamChanges = useSelector(
    (state: State) => state.createTeamDetails.unsavedTeamChanges
  );

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const handleConfirmTeamTabModal = () => {
    setSubmitted(true);
    setOpenModal(false);
    dispatch(setUnsavedTeamChanges(false));
    setDetailView(false);
    setIsDetailView(false);
  };

  const renderSwitchViewTeamModal = () => {
    return (
      <CreateTeamCancelModal
        open={openModal}
        handleCancel={handleCancelModal}
        handleSuccess={handleConfirmTeamTabModal}
        disableActions={submitted}
      />
    );
  };

  return (
    <>
    {renderSwitchViewTeamModal()}
    <Toolbar>
      <Grid container direction="row" alignItems="center">
        <FullScreenDialog open={showFAQ} setOpen={setShowFAQ} />
        <Hidden smUp>
          <Grid
            container
            xs={12}
            item
            justify="flex-start"
            className={
              open && detailView
                ? classes.gridWithDetail
                : open
                ? classes.listTopGrid2
                : classes.topGrid2
            }
          >
            <Grid container xs={12} item alignItems="center">
              <Grid
                item
                container
                xs={2}
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon color="primary"></MenuIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                xs={10}
                container
                justify="flex-end"
                alignItems="center"
                className={open ? classes.headerGrid : undefined}
              >
                <Grid container justify="flex-end" alignItems="center">
                <Tooltip
                    open={toolTip}
                    onOpen={() => setToolTip(true)}
                    onClose={() => setToolTip(false)}
                    title="FAQs"
                    placement="bottom"
                    arrow
                    classes={{arrow: classes.arrow , tooltip: classes.toolTip }}
                  >
                    <IconButton onClick={() => setShowFAQ(true)}>
                      <Avatar
                        src={"/QUE_MARK.svg"}
                        alt={"faq"}
                        style={{
                          width: theme.spacing(2),
                          height: theme.spacing(2),
                        }}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                  <Button
                    onClick={() => setOpen(true)}
                    className={classes.profileBtn}
                  >
                    <Avatar
                      src={imageUrl}
                      style={{
                        width: theme.spacing(3),
                        height: theme.spacing(3),
                      }}
                    ></Avatar>
                  </Button>
                </Grid>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={handleClickAway}
                >
                  <Grid container justify="flex-end">
                    {open ? (
                      <List component="nav" className={classes.list}>
                        <ListItem style={{ backgroundColor: "#FFFFFF" }}>
                          <Typography className={classes.profileBtnItem}>
                            {"Welcome,"}{" "}
                            <span
                              style={{ color: "#576BEA" }}
                            >{`${name}`}</span>
                          </Typography>
                        </ListItem>
                        <Divider
                          style={{
                            backgroundColor: "rgb(174 183 241 / 50%)",
                            height: theme.spacing(0.25),
                            margin: theme.spacing(0, 2.5, 0, 2.125),
                          }}
                        />
                        <ListItem
                          button
                          onClick={handleLogout}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <Typography className={classes.profileBtnItem2}>
                            Logout
                          </Typography>
                        </ListItem>
                      </List>
                    ) : null}
                  </Grid>
                </ClickAwayListener>
              </Grid>
            </Grid>
            <Grid xs={12} item container justify="flex-start">
              {detailView ? (
                <Grid
                  className={
                    open ? classes.detailBtnWithProfile : classes.detailBtn
                  }
                >
                  <IconButton
                    onClick={() => {
                      if (currentPath === "/admin/teams/create" && unsavedTeamChanges) {
                        setOpenModal(true);                  
                      } else {
                        setDetailView(false);
                        setIsDetailView(false);
                      }
                    }}
                    style={{ padding: "0px" }}
                  >
                    <ArrowBackIosIcon
                      style={{
                        height: "16px",
                        width: "16px",
                        color: "#000000",
                      }}
                    />
                  </IconButton>
                </Grid>
              ) : null}
              <Grid className={open ? classes.headerName2 : classes.headerName}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <HeaderDisplayText
                      headerName={headerName}
                    ></HeaderDisplayText>
                  </Grid>
                  <Grid item>
                    <StatusText
                      value={value}
                      setValue={setValue}
                      tabValue={tabValue}
                      validLength={validLength}
                      isDataChange={isDataChange}
                      savedDraftDate={savedDraftDate}
                      onAnyChange={onAnyChange}
                    ></StatusText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid
            xs={12}
            item
            container
            className={open ? classes.listTopGrid : classes.topGrid}
          >
            <Grid
              xs={10}
              sm={8}
              item
              container
              justify="flex-start"
              direction="row"
            >
              {detailView ? (
                <Grid
                  xs={1}
                  item
                  container
                  className={classes.detailBtn}
                  justify="flex-start"
                >
                  <Grid item xs={12}>
                    <IconButton
                      onClick={() => {
                        if (currentPath === "/admin/teams/create" && unsavedTeamChanges) {
                          setOpenModal(true);                  
                        } else {
                          setDetailView(false);
                          setIsDetailView(false);
                        }
                      }}
                      className={classes.backCta}
                    >
                      <ArrowBackIosIcon></ArrowBackIosIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                xs={11}
                item
                container
                justify="flex-start"
                alignItems="center"
                className={
                  open && detailView
                    ? classes.headerGrid && classes.headerDetail2
                    : open
                    ? classes.headerGrid
                    : detailView
                    ? classes.headerDetail
                    : undefined
                }
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <HeaderDisplayText
                      headerName={headerName}
                    ></HeaderDisplayText>
                  </Grid>
                  <Grid item>
                    <StatusText
                      value={value}
                      setValue={setValue}
                      tabValue={tabValue}
                      validLength={validLength}
                      isDataChange={isDataChange}
                      savedDraftDate={savedDraftDate}
                      onAnyChange={onAnyChange}
                    ></StatusText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={2} sm={4} item container justify="flex-end">
              <Grid
                container
                direction="column"
                style={{ margin: theme.spacing(2, 0, 0, 0) }}
              >
                <Grid container justify="flex-end">
                  <Tooltip
                    open={toolTip}
                    onOpen={() => setToolTip(true)}
                    onClose={() => setToolTip(false)}
                    title="FAQs"
                    placement="bottom"
                    arrow
                    classes={{ arrow: classes.arrow ,tooltip: classes.toolTip }}
                  >
                    <IconButton onClick={() => setShowFAQ(true)}>
                      <Avatar
                        src={"/QUE_MARK.svg"}
                        alt={"faq"}
                        style={{
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                        }}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                  <Button
                    onClick={() => setOpen(true)}
                    className={classes.profileBtn}
                  >
                    <Avatar src={imageUrl}></Avatar>
                  </Button>
                </Grid>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={handleClickAway}
                >
                  <Grid container justify="flex-end">
                    {open ? (
                      <List component="nav" className={classes.list}>
                        <ListItem style={{ backgroundColor: "#FFFFFF" }}>
                          <Typography className={classes.profileBtnItem}>
                            {"Welcome,"}{" "}
                            <span
                              style={{ color: "#576BEA" }}
                            >{`${name}`}</span>
                          </Typography>
                        </ListItem>
                        <Divider
                          style={{
                            backgroundColor: "rgb(174 183 241 / 50%)",
                            height: "0.05vw",
                            margin: theme.spacing(0, 2.5, 0, 2.125),
                          }}
                        />
                        <ListItem
                          button
                          onClick={handleLogout}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <Typography className={classes.profileBtnItem2}>
                            Logout
                          </Typography>
                        </ListItem>
                      </List>
                    ) : null}
                  </Grid>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Toolbar>
  </>
  );
};
