import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ToolBar } from "../../../../modules/home/ToolBar";
import { SharedFeedbackDetailsLayoutProp } from "../../../../modules/user/shared_feedback/typeSharedFeedbacks";
import { HomeLayout } from "../../../home/Layout";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(3, 3, 3, 3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(2.25, 2.75, 2.75, 2.75),
    },
    minHeight: `${window.innerHeight - 102}px`,
  },
}));

export const SharedFeedbackDetailsLayout: React.FC<SharedFeedbackDetailsLayoutProp> =
  ({
    loading,
    props,
    setLogout,
    isDetailView,
    setIsDetailView,
    renderDetails,
    renderErrorModal,
  }) => {
    const classes = useStyle();
    window.scrollTo(0,0);
    document.title = "Grow-Wize | Shared Feedback Detail";
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const tempFunction = () => {
      return 0;
    };

    const renderToolBar = () => (
      <ToolBar
        headerName="Shared Feedback Detail"
        setLogout={setLogout}
        isDetailView={isDetailView}
        setIsDetailView={setIsDetailView}
        handleDrawerToggle={handleDrawerToggle}
        value={-2}
        setValue={tempFunction}
        tabValue={-2}
        validLength={false}
      ></ToolBar>
    );

    const renderSharedFeedbackDetailLayout = () => (
      <Grid
        container
        direction="row"
        className={classes.root}
        alignItems="stretch"
      >
        <Grid container justify="flex-start" alignItems="flex-start">
          {renderErrorModal()}
        </Grid>
        <Grid container alignItems="stretch" direction="column">
          {renderDetails()}
        </Grid>
      </Grid>
    );

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    return (
      <HomeLayout
        loading={loading}
        renderToolBar={renderToolBar}
        props={props}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        renderSelectedOption={renderSharedFeedbackDetailLayout}
      ></HomeLayout>
    );
  };
