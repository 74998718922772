import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { CustomDivider } from "./CustomDivider";
import { TrendChart } from "./TrendChart";
import { FeedbackTrendProps } from "./type";
import { CustomSelect } from "../../components/CustomSelect";

const useStyles = makeStyles((theme) => ({
  trendSection: {
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    boxSizing: "border-box",
    margin: theme.spacing(1, 1, 2, 1),
  },

  dates: {
    position: "absolute",
    zIndex: 100,
    right: theme.spacing(65.8),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    fontFamily: "Montserrat",
    marginTop: "16px",
  },
}));

export const FeedbackTrendComponent: React.FC<FeedbackTrendProps> = ({
  data,
  loading,
  dateRangeState,
  setDateRangeState,
  filter,
  setFilter,
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyle();
  const [show, setShow] = React.useState<Boolean>(false);

  const handleDateChange = (range) => {
    const nextRange = {
      startDate: range?.range1?.startDate,
      endDate: range?.range1?.endDate,
    };
    setDateRangeState(nextRange);
  };
  return (
    <Grid
      xs={12}
      item
      direction="row"
      justify="center"
      alignItems="flex-start"
      className={classes.trendSection}
    >
      <Grid xs={12} item container style={{ padding: "11px 14px 11px 14px" }}>
        <Grid xs={7} item container justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{ color: "#272727" }}>
            Feedback Trend
          </Typography>
        </Grid>
        <Grid xs container justify="flex-end" alignItems="center">
          <Grid item>
            <IconButton
              onClick={() => setShow(true)}
              style={{
                padding: "0px 4px",
                margin: "0px",
                position: "relative",
                height: "20px",
                width: "20px",
              }}
            >
              <img src="/CalendarIcon.svg" alt="trend-calendar" />
            </IconButton>
            {show && (
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => setShow(false)}
              >
                <div className={classes.dates}>
                  <DateRangePicker
                    ranges={[dateRangeState]}
                    onChange={(e) => handleDateChange(e)}
                    maxDate={new Date()}
                    dateDisplayFormat={"d MMM yyyy"}
                  />
                </div>
              </ClickAwayListener>
            )}
          </Grid>
          <Grid item>
            <CustomSelect
              tabValue={filter}
              setTabValue={setFilter}
              name={"TIME_UNIT"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <CustomDivider />
      </Grid>
      <Grid style={{ height: theme.spacing(44), overflowY: "auto" }}>
        {data?.length > 0 && (
          <Grid xs={12} item className={"trend-chart"}>
            <TrendChart data={data} />
          </Grid>
        )}
        {loading && (
          <Grid
            item
            container
            justify="center"
            style={{ position: "relative", marginTop: theme.spacing(16) }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && data?.length === 0 && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ marginTop: theme.spacing(16) }}
          >
            <Typography
              variant="body2"
              className={customClasses.typography_font_24_14_500}
            >
              No feedback...
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
