import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { ListHeaderContentProps } from "../type";
import { ListContent } from "./ListContent";
import { ListHeader } from "./ListHeader";

const useStyle = makeStyles((theme) => ({
  grid: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(0.625),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
  },
}));

const headerNameAndActionTextByTabValue = [
  {
    name: "Received from",
    action: "Action",
  },
  {
    name: "Shared to",
    action: "Action",
  },
  {
    name: "Requested from",
    action: "",
  },
  {
    name: "Request sent to",
    action: "",
  },
  {
    name: "Shared on",
    action: "Action",
  },
];

export const ListHeaderWithContent: React.FC<ListHeaderContentProps> = ({
  tabValue,
  setFeedbackOrAssessmentId,
  itemValue,
  setItemValue,
  setPageNumber,
}) => {
  const classes = useStyle();

  return (
    <Grid xs={12} item container className={classes.grid}>
      {(
        <Grid xs={12} item container>
          <ListHeader
            headerName={headerNameAndActionTextByTabValue[tabValue].name}
            headerAction={headerNameAndActionTextByTabValue[tabValue].action}
            itemValue={itemValue}
            setItemValue={setItemValue}
            tabValue={tabValue}
            setPageNumber={setPageNumber}
          ></ListHeader>
        </Grid>
      )}
      <Grid xs={12} item container>
        <ListContent tabValue={tabValue} setFeedbackOrAssessmentId={setFeedbackOrAssessmentId}/>
      </Grid>
    </Grid>
  );
};
