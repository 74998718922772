import { Button, ClickAwayListener, makeStyles } from "@material-ui/core"
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { getDateInDesiredFormat } from "../../util/GetDateInDesiredFormat";

const useStyle = makeStyles((theme) => ({
    calenderCTA: {
        padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
        textTransform: "none",
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        color: "#7C69EC",
        fontFamily: "Montserrat",
        borderRadius: theme.spacing(0.5),
        height: theme.spacing(6),
        border: "1px solid #7C69EC",
    },
    dateRangeWrapper: {
        position: "absolute",
        zIndex: 100,
        right: theme.spacing(6.5),
        boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
        border: "1px solid rgba(175,175,175,0.3)",
        fontFamily: "Montserrat",
        marginTop: "16px",
        "& > .rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper": {
            display: "none",
        }
    },
}));

export const CalendarCTA = ({
    setDateRange,
    dateRange,
    selectedDateCount,
    setSelectedDateCount,
}) => {
    const classes = useStyle();
    const [showDateRange, setShowDateRange] = useState<boolean>(false);

    const handleDateChange = (range) => {
        const nextRange = {
            startDate: range?.range1?.startDate,
            endDate: range?.range1?.endDate,
        };
        setDateRange(nextRange);
        if (selectedDateCount === 1) {
            setShowDateRange(false);
            setSelectedDateCount(2);
        } else if (selectedDateCount === 0) {
            setSelectedDateCount(1);
        }
    };

    return (<>
        <Button
            startIcon={<img src="/CalendarIconInvert.svg" alt="export_report"></img>}
            variant="outlined"
            data-testid="export-button"
            className={classes.calenderCTA}
            onClick={() => setShowDateRange(true)}
        >
            {/* Sep 2023 - Sep 2024 */}
            {getDateInDesiredFormat(dateRange.startDate)} - {getDateInDesiredFormat(dateRange.endDate)}
        </Button>
        {showDateRange && (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => setShowDateRange(false)}
            >
                <div className={classes.dateRangeWrapper}>
                    <DateRangePicker
                        ranges={[dateRange]}
                        onChange={(e) => handleDateChange(e)}
                        maxDate={new Date()}
                        staticRanges={[]}
                        inputRanges={[]}
                        dateDisplayFormat={"d MMM yyyy"}
                    />
                </div>
            </ClickAwayListener>
        )}
    </>
    );
}
