import {
  makeStyles,
} from "@material-ui/core";

import { appColor } from "../../../constants/customThemeColor";
import { RESPONSE_TYPE_LIST } from "../../../../constants/questionConstants";

export const questionComponentStyle = makeStyles((theme) => ({
  container: {
    border: "0.5px solid #BFBFBF",
    borderRadius: 4,
    background: appColor.whiteBackground,
  },
  header: {
    background: "#576BEA0D",
    padding: theme.spacing(2, 2.5),
  },
  disabledHeader: {
    background: "#E1E1E1",
  },
  body: {
    padding: "32px 20px",
  },
  question_text: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#272727",
    marginBottom: theme.spacing(6),
  },
  label: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#A0A0A0",
    paddingTop: theme.spacing(1.25),
  },
  value: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: "#272727",
    paddingTop: theme.spacing(1.25),
  },
  title: {
    width: "fit-content",
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    color: "#272727",
  },
  actionButtons: {
    width: "fit-content",
    gap: theme.spacing(1),
  },
  editButton: {
    height: "fit-content",
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1.75),
    color: "#576BEA",
    fontWeight: 600,
    fontFamily: "Montserrat",
    textDecorationLine: "underline",
    textTransform: "none",
  },
  responseType: {
    gap: theme.spacing(2),
  },
  questionNameInput: {
    marginBottom: 22,
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
    "& >:first-child": {
      background: "#FFFFFF",
    },
    background: "#FFF",
    borderRadius: 4,
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7C69EC ",
    },
    "& > p:last-child": {
      color: "#DC0101",
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 0,
      marginTop: theme.spacing(1),
      fontFamily: "Montserrat"
    }
  },

  fullWidth: {
    width: "100%",
  },
  listItem: {
    "& span": {
      fontSize: 14,
    },
  },
  disabledText: {
    color: "#979797"
  },
  disabled: {
    opacity: 0.3,
  }
}));

export const scrollToTargetAdjusted = (ref) => {
  var headerOffset = 200;
  var elementPosition = ref?.current?.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
  });
}

export const getMatchingResponseType = (answerType: string) => RESPONSE_TYPE_LIST.find(item => item.key === answerType);
