import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    backgroundColor: "#F6F7FE",
    boxShadow: "0 2px 4px 0 rgba(189,189,189,0.5)",
  },
  commonFontStyle: {
    textAlign: "justify",
    fontFamily: "Montserrat",
    marginLeft: theme.spacing(3),
  },
  heading: {
    fontWeight: 600,
    color: "#272727",
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(3,0,2.25,3),
  },
}));

export const FeedbackHeaderText = () => {
  const classes = useStyles();
  
  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.headerGrid}
    >
      <Grid xs={12} item>
        <Typography className={`${classes.heading} ${classes.commonFontStyle}`}>
          Add your feedback
        </Typography>
      </Grid>
    </Grid>
  );
};
