import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HomeLayout } from "../../home/Layout";
import { ToolBar } from "../../../modules/home/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setNudgeDateRange } from "../../../redux/slices/NudgeListSlice";
import { DateRangeProps, State } from "../../../type";
import { NudgeCalendarCTA } from "../../../modules/components/NudgeCalendarCTA";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    padding: theme.spacing(6, 6, 2, 5),
    marginTop: theme.spacing(6.5),
    background: "#F5F6FA",
    height: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  calendarBox: {
    width: "260px",
    height: "45px",
    border: "1px solid blue", 
    padding: "0.5px",
    borderRadius: "5px",
    backgroundColor: "white",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  }
}));

export const NudgeBoardLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  renderErrorModal,
  renderNudgeList
}) => {
document.title = "Grow-Wize | Admin - Nudge Board";
const classes = useStyle();

const [mobileOpen, setMobileOpen] = React.useState(false);
const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};

const tempFunction = () => {
  return 0;
};
const dispatch = useDispatch();
const location = useLocation();
const currentPath = location.pathname;
const [selectedDateCount, setSelectedDateCount] = useState<number>(0);

const nudgeDateRangeState : DateRangeProps = useSelector((state: State) => state.adminNudgeData.nudgeDateRange)
const setNudgeDateRangeState =(dateRange : DateRangeProps)=>{
  dispatch(setNudgeDateRange(dateRange))
}

const defaultDateRange = {
  startDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
};

  useEffect(() => {
    if (currentPath === '/admin/nudge') {
      setNudgeDateRangeState(defaultDateRange);
    }
  }, [currentPath]);

const renderToolBar = () => (
  <ToolBar
    headerName="Nudge Board"
    setLogout={setLogout}
    isDetailView={isDetailView}
    setIsDetailView={setIsDetailView}
    handleDrawerToggle={handleDrawerToggle}
    value={-2}
    setValue={tempFunction}
    tabValue={-2}
    validLength={false}
  ></ToolBar>
);


const renderNudgeLayout = () => (
  <>
    {renderErrorModal()}
    <Grid container direction="row" className={classes.root} justify="center">
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        style={{ width: "inherit" }}
      >
      <div className={classes.calendarBox}>
        <NudgeCalendarCTA
          setDateRange={setNudgeDateRangeState}
          dateRange={nudgeDateRangeState}
          selectedDateCount={selectedDateCount}
          setSelectedDateCount={setSelectedDateCount}
          />
        </div>
        {renderNudgeList()}
      </Grid>
    </Grid>
  </>
);

return (
  <HomeLayout
    loading={loading}
    renderToolBar={renderToolBar}
    props={props}
    mobileOpen={mobileOpen}
    handleDrawerToggle={handleDrawerToggle}
    renderSelectedOption={renderNudgeLayout}
  ></HomeLayout>
);
};
