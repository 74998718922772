import {
  Avatar,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";
import { ResponseNudge, State } from "../../../type";
import { NudgeCard } from "./NudgeCardComponent";
import { CustomDivider } from "./CustomDivider";
import { NudgeBoardProp } from "./type";
import { NUDGE_TITLE } from "./Helper";
import { NudgeCalendarCTA } from "../../components/NudgeCalendarCTA";
import { Loader } from "../../../layouts/admin/attributeDetail/Layout";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appraisalSection: {
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    boxSizing: "border-box",
    margin: theme.spacing(0, 1),
  },
  header: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  dates: {
    position: "absolute",
    zIndex: 100,
    right: "60px",
    boxShadow: "0 2px 4px 0 rgba(175,175,175,0.5)",
    border: "1px solid rgba(175,175,175,0.3)",
    fontFamily: "Montserrat",
    marginTop: "0px",
  },
  toolTip_list: {
    height: theme.spacing(8),
    width: theme.spacing(25),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    fontColor: "#FFFFFF",
    backgroundColor: "#272727",
    textAlign: "center",
    padding: theme.spacing(1, 1),
    whiteSpace: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(0.875),
      height: theme.spacing(2),
      width: theme.spacing(4),
      padding: theme.spacing(0.5, 0.75),
    },
  },
  toolTip_action: {
    height: theme.spacing(4),
    width: theme.spacing(20),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    fontColor: "#FFFFFF",
    backgroundColor: "#272727",
    textAlign: "center",
    padding: theme.spacing(1, 1.25),
    right: theme.spacing(6.25),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(0.875),
      height: theme.spacing(2),
      width: theme.spacing(4),
      padding: theme.spacing(0.5, 0.75),
    },
  },
  arrow_default: {
    color: "#272727",
  },
  arrow_right_align: {
    color: "#272727",
    left: "120px !important",
  },
  footerContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1.3),
    borderTop: "1px solid rgba(175,175,175,0.3)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0.1),
  },
  footerButton: {
    color: '#7C69EC',
    textTransform: 'none',
    marginTop: '10px',
    marginRight: "20px",
    padding: theme.spacing(0),
    lineHeight: '1.6', // Adjust line-height for vertical alignment
    fontSize: '1rem',
  }
}));

export const NudgeBoard: React.FC<NudgeBoardProp> = ({
  dateRangeState,
  setDateRangeState,
  pageNumber,
  setPageNumber,
  totalCount,
  setNudgeCtaSubmitted,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const customClasses = useCustomStyle();
  const [showListToolTip, setShowListToolTip] = React.useState<boolean>(false);
  const [showActionToolTip, setShowActionToolTip] = React.useState<boolean>(false);
  const [selectedDateCount, setSelectedDateCount] = useState<number>(0);
  const nudges = useSelector((state: State) => state.adminNudgeData.nudges);
  const renderTitles = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        {NUDGE_TITLE.map((title) => (
          <Grid item key={title.name} xs={title.size}>
            <Typography
              variant="h5"
              style={{
                paddingLeft:
                  title.name === "Name" ? 10 : title.name === "Action" ? 28 : 0,
              }}
            >
              {title.name}
              {title.name === "Shared"}
              {title.name === "Received"}
              {title.name === "Action" && (
                <Tooltip
                  open={showActionToolTip}
                  onOpen={() => setShowActionToolTip(true)}
                  onClose={() => setShowActionToolTip(false)}
                  title="Nudge them via email"
                  placement="bottom"
                  arrow
                  classes={{ arrow: classes.arrow_right_align, tooltip: classes.toolTip_action }}
                >
                  <IconButton
                    onClick={() => setShowActionToolTip(true)}
                    style={{ padding: 6 }}
                  >
                    <Avatar
                      src={"/INFO_MARK_Action_Nudge.svg"}
                      alt={"info"}
                      style={{
                        width: theme.spacing(3),
                        height: theme.spacing(3),
                      }}
                    ></Avatar>
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };
  useEffect(() => {
    if (pageNumber != 1) {
      setPageNumber(1);
    }
  }, [dateRangeState]);

  const handleClick = () => {
    history.push({
      pathname: `/admin/nudge`});
  }

  return (
    <Grid
      xs={12}
      item
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.appraisalSection}
    >
      <Grid xs={12} item container style={{ padding: "11px 14px 11px 14px" }}>
        <Grid xs={5} item container justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{ color: "#272727" }}>
            Nudge Board
          </Typography>

          <Tooltip
            open={showListToolTip}
            onOpen={() => setShowListToolTip(true)}
            onClose={() => setShowListToolTip(false)}
            title="See who hasn’t shared or received feedback recently"
            placement="bottom"
            arrow
            classes={{ arrow: classes.arrow_default, tooltip: classes.toolTip_list }}
          >
            <IconButton
              onClick={() => setShowListToolTip(true)}
              style={{ padding: 6 }}
            >
              <Avatar
                src={"/INFO_MARK.svg"}
                alt={"info"}
                style={{
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                }}
              ></Avatar>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid xs item container justify="flex-end" alignItems="center">
          <NudgeCalendarCTA
            setDateRange={setDateRangeState}
            dateRange={dateRangeState}
            selectedDateCount={selectedDateCount}
            setSelectedDateCount={setSelectedDateCount}
          />
        </Grid>
      </Grid>

      <Grid xs={12} item container>
        <CustomDivider />
      </Grid>

      <Grid xs={12} item container>
        {totalCount > 0 && (
          <Grid xs={12} item container>
            {renderTitles()}
          </Grid>
        )}
      </Grid>

      <Grid xs={12} item container>
        <CustomDivider />
      </Grid>

      <Grid
        style={{
          height: totalCount <= 0 ? theme.spacing(41.8) : theme.spacing(29.4),
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ position: "relative", marginTop: theme.spacing(16) }}
          >
            <Loader></Loader>
          </Grid>
        ) : (
          <>
            {totalCount > 0 &&
              nudges.map((nudge: ResponseNudge) => (
                <div>
                  <NudgeCard
                    user={nudge.user}
                    sharedFeedbackCount={nudge.sharedFeedbackCount}
                    receivedFeedbackCount={nudge.receivedFeedbackCount}
                    nudgeSenderData={nudge.nudgeSenderData}
                    titles={NUDGE_TITLE}
                    setNudgeCtaSubmitted={setNudgeCtaSubmitted}
                  />
                  <Grid xs={12} item>
                    <CustomDivider />
                  </Grid>
                </div>
              ))}
          </>
        )}
        {!loading && totalCount === 0 && (
          <Grid
            xs={12}
            item
            container
            justify="center"
            style={{ position: "relative", marginTop: theme.spacing(16) }}
          >
            <Typography
              variant="body2"
              className={customClasses.typography_font_24_14_500}
            >
              No nudges...
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid xs={12} className={classes.footerContainer}>
        <Button
          className={classes.footerButton}
          onClick = {handleClick}
        >
          Show More
        </Button>
      </Grid>
    </Grid>
  );
};