import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OtpVerificationRequest } from "../../../src/type";
import { OtpResponse } from "../../type";

export const signInCorporate = createAsyncThunk(
  "otpVerify",
  async (requestData: OtpVerificationRequest) => {
    try {

     const options = {
         method: "post",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(requestData),
       };

let response;
    if(!requestData.flag){
       response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/otp/validate`,
        options
      );
     }else{
         response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/otp/validate123`,
                options
              );
     }


      if (response.status === 200) {
        return {
          status: 200,
          data: await response.json(),
        };
      } else {
        return {
          status: response.status,
          text: await response.text(),
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

const otpValidationSlice = createSlice({
  name: "otpValidationResponse",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as OtpResponse | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInCorporate.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;


      if (action.payload.data !== undefined) {
      console.log('---- data '+JSON.stringify( action.payload.data));

        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }

    });
    builder.addCase(signInCorporate.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(signInCorporate.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = otpValidationSlice.actions;

export default otpValidationSlice;

