import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCustomStyle } from "../../../styles";
import { DashboardCountCardsProps } from "../../../type";

const useStyle = makeStyles((theme) => ({
  receivedCountCard: {
    height: theme.spacing(15),
    width: "-webkit-fill-available",
    boxShadow:"0 2px 4px 0 rgba(90,90,90,0.1)",
    borderRadius: theme.spacing(1.5),
    border: "0.6px solid rgba(87,107,234,0.5)",
    "&:hover": {
      border: "1px solid rgba(87,107,234,1)",
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(-2.25, 0, 2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(10),
    },
  },
  cardIcon: {
    margin: theme.spacing(3.75, 1, 0, 2.5),
    [theme.breakpoints.up("lg")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      margin: theme.spacing(3, 0, 0, 0.8),
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1.75, 0, 0, 0.8),
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  typography_Body: {
    fontSize: theme.spacing(6),
    margin: theme.spacing(1, 1.5, 0, 0),
    fontWeight: 600,
    color: "#272727",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0.5, 2, 0, 0),
      fontSize: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5, 2, 0, 0),
      fontSize: theme.spacing(3),
    },
  },
  typography_Body2: {
    color: "#868686",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "18px",
    margin: theme.spacing(-1, 1.5, 0, 0),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(1.95, 0.5, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(-1.5, 2, 0, 0),
    },
  },
  receivedCard: {
    padding: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2.75, 0, 0),
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  sentCard: {
    padding: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 0, 2.75),
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  cardBtn: {
    textTransform: "none",
    padding: theme.spacing(0),
    [theme.breakpoints.down("xl")]: {
      width: "-webkit-fill-available",
      "&:hover": {
        border: "#FFFFFF",
        backgroundColor: "#F5F6FA",
      },
    },
  },
}));

export const DashboardCountCards: React.FC<DashboardCountCardsProps> = ({
  countShared,
  countReceived,
  setValue,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();

  return (
    <Grid container direction="row">
      <Grid item md={6} xs={12} className={classes.receivedCard} id="Total_Received_Count_Card">
        <Button className={`${customClasses.popperWidth} ${classes.cardBtn}`} onClick={() => setValue(4)}>
          <Paper className={`${customClasses.popperWidth} ${classes.receivedCountCard}`} variant="outlined">
            <Grid container>
              <Grid item xs={2}>
                <LazyLoadImage
                  src="TotalFeedbackReceivedIcon.svg"
                  alt="total received feedback"
                  className={classes.cardIcon}
                />
              </Grid>
              <Grid item container xs={10} direction="row" justify="flex-end">
                <Grid item xs={12} container justify="flex-end">
                  <Typography className={classes.typography_Body}>
                    {countReceived === -1
                      ? 0
                      : countReceived < 10 && countReceived > 0
                      ? "0" + countReceived
                      : countReceived}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Typography className={classes.typography_Body2}>
                    Total Feedback Received
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Button>
      </Grid>
      <Grid item md={6} xs={12} className={classes.sentCard} id="Total_Shared_Count_Card">
        <Button className={`${customClasses.popperWidth} ${classes.cardBtn}`} onClick={() => setValue(3)}>
          <Paper className={`${customClasses.popperWidth} ${classes.receivedCountCard}`} variant="outlined">
            <Grid container>
              <Grid item xs={2}>
                <LazyLoadImage
                  src="TotalFeedbackSentIcon.svg"
                  alt="total shared feedback"
                  className={classes.cardIcon}
                />
              </Grid>
              <Grid item container xs={10} direction="row" justify="flex-end">
                <Grid item xs={12} container justify="flex-end">
                  <Typography className={classes.typography_Body}>
                    {countShared === -1
                      ? 0
                      : countShared < 10 && countShared > 0
                      ? "0" + countShared
                      : countShared}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Typography className={classes.typography_Body2}>
                    Total Feedback Shared
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Button>
      </Grid>
    </Grid>
  );
};
