import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FeedbackRequestDetailData } from "../../modules/user/feedback_requests/typeFeedbackrequest";
import { ReceivedFeedbackRequestDetailAPICallProps } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";
import { get } from "../../util/APIService";

export const receivedFeedbackRequestDetails = createAsyncThunk(
  "feedback/requests/received/detail",
  async (params: ReceivedFeedbackRequestDetailAPICallProps, storeActions) => {
    const url = `api/v1/user/feedback-requests/received/detail?feedbackRequestId=${params.feedbackRequestId}`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const receivedFeedbackRequestDetailsSlice = createSlice({
  name: "receivedFeedbackRequestDetails",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as FeedbackRequestDetailData | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      receivedFeedbackRequestDetails.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.loading = false;
        if (action.payload.data !== undefined) {
          state.data = action.payload.data;
          state.text = null;
        } else if (action.payload.text !== undefined) {
          state.data = null;
          state.text = action.payload.text;
        }
      }
    );
    builder.addCase(receivedFeedbackRequestDetails.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(receivedFeedbackRequestDetails.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = receivedFeedbackRequestDetailsSlice.actions;

export default receivedFeedbackRequestDetailsSlice;
