import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShareFeedbackProps } from "../../../src/type";
import { post } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const submitFeedback = createAsyncThunk(
  "submitFeedback/sendFeedback",
  async (requestData: ShareFeedbackProps, storeActions) => {
    const url = `api/v1/user/feedbacks`;
    const response: any = await post(url, requestData, storeActions);

    if (response.status === HttpStatus.CREATED) {
      return {
        status: HttpStatus.CREATED,
      };
    }
    return {
      status: response.status,
    };
  }
);

const submitFeedbackSlice = createSlice({
  name: "submitFeedback",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      submitFeedback.fulfilled,
      (state, { payload }: PayloadAction<{ status: number }>) => {
        state.status = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(submitFeedback.pending, (state) => {
      state.loading = true;
      state.status = null;
    });
    builder.addCase(submitFeedback.rejected, (state) => {
      state.status = 503;
      state.loading = false;
    });
  },
});

export const { clearData } = submitFeedbackSlice.actions;

export default submitFeedbackSlice;
