import {
  Button,
  createStyles,
  Grid,
  Fade,
  Paper,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { ValidationProps } from "../user/request_feedback/typeRequestFeedback";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    modal: { display: "flex", alignItems: "center", justifyContent: "center" },
    modalGrid: {
      width: theme.spacing(70),
      height: theme.spacing(35),
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(8, 0, 4, 0),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(40),
        height: theme.spacing(25),
        padding: theme.spacing(6, 0, 6, 0),
      },
    },
    modalErrorText: {
      fontSize: theme.spacing(3),
      fontWeight: 600,
      color: "#576BEA",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(2),
      },
    },
    btn1: {
      padding: theme.spacing(1.375, 4.25, 1.375, 4.25),
      backgroundColor: "#576BEA",
      "&:hover": {
        backgroundColor: "#576BEA",
      },
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: theme.spacing(2.5),
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
        fontSize: theme.spacing(1.75),
      },
    },
  })
);

export const ValidationPromptModal: React.FC<ValidationProps> = ({
  open,
  setOpen,
  charLimitExceeded,
}) => {
  const classes = useStyle();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper elevation={0} variant="outlined">
          <Grid container className={classes.modalGrid}>
            <Grid item xs={12} container justify="center" alignItems="flex-end">
              <Typography className={classes.modalErrorText}>
                {!charLimitExceeded && "Please enter all mandatory fields!!"}
                {charLimitExceeded && "You've exceeded the character limit!!"}
              </Typography>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Button onClick={handleClose} className={classes.btn1}>
                Okay
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};
