import { makeStyles } from "@material-ui/core";

export const attributeDetailStyles = makeStyles((theme) => ({
    btn: {
        height: "fit-content",
        background: "#7C69EC",
        textTransform: "none",
        fontWeight: 600,
        fontFamily: "Montserrat",
        padding: "10px 28px",
        alignSelf: "flex-start",
        marginTop: theme.spacing(1.5)
    },
    questionsList: {
        gap: theme.spacing(3.75),
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.3
    },
    heading: {
        width: "fit-content",
        alignSelf: "flex-start",
    },
    attributeName: {
        fontSize: theme.spacing(3),
        fontWeight: 600,
        color: "#272727",
        lineHeight: "56px",
    },
    attributeNameInput: {
        marginBottom: 0,
        width: theme.spacing(48),
        "& >:first-child": {
            background: "#FFFFFF",
        },
        "& > p:last-child": {
            color: "#DC0101",
            fontSize: 12,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: theme.spacing(1),
            fontFamily: "Montserrat",
        },
    },
    buttonsContainer: {
        alignSelf: "flex-start",
        alignItems: "flex-start",
        width: "fit-content",
        marginLeft: theme.spacing(2),
        gap: theme.spacing(0.5)
    },
    actionButton: {
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        fontFamily: "Montserrat",
        textDecoration: "underline",
        textDecorationColor: "#576BEA",
        textTransform: "none",
        color: "#576BEA",
        alignSelf: "flex-start",
        marginTop: theme.spacing(1.5),
        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: "#576BEA",
        },
    },
    bannerContainer: {
        display: "flex",
        alignItems: "center",
        background: "#EBE8FF",
        height: theme.spacing(7.75),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(1.5)
    },
    infoText: {
        fontSize: 14,
        fontWeight: 600,
        color: "#272727"
    }
}));