import React from "react";
import { RecentActivityDetailProp } from "../../../type";
import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { appColor } from "../../constants/customThemeColor";
import { getDateInActivityFormat } from "../../../util/GetDateInActitivityFormat";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: theme.spacing(4.75),
    width: theme.spacing(12.5),
    textTransform: "none",
    backgroundColor: `${appColor.pureWhite}`,
    borderRadius: theme.spacing(0.5),
    color: `${appColor.darkGray}`,
    padding: theme.spacing(1.25, 4),
    border: "1px solid rgba(175,175,175,0.5)",
    "&:hover": {
      backgroundColor: `${appColor.adminThemeColor}`,
      color: `${appColor.pureWhite}`,
      border: "none",
    },
  },
}));

export const SharedFeedbackActivityCard: React.FC<RecentActivityDetailProp> = ({
  activityId,
  activityDate,
  activityUser,
  others,
  setFeedbackId,
  setMember
}) => {
  const classes = useStyles();
  return (
    <Grid
      xs={12}
      item
      container
      justify="flex-start"
      style={{ padding: "14px 14px 14px 14px" }}
      alignItems="center"
    >
      <Grid
        xs={10}
        item
        container
        justify="flex-start"
        alignItems="center"
        direction="row"
      >
        <Grid xs={1} item style={{ marginRight: "4px" }}>
          <Avatar
            src={activityUser?.imageUrl}
            alt={activityUser?.firstName.toUpperCase()}
            style={
              activityUser?.firstName === activityUser?.imageUrl
                ? {
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#A0A0A0",
                    color: "#FFFFFF",
                    fontWeight: 600,
                  }
                : { height: "40px", width: "40px" }
            }
          />
        </Grid>
        <Grid xs item container direction="column">
          <Grid xs={12} item>
            <Typography
              variant="h5"
              style={{ textAlign: "start", wordWrap: "break-word", marginLeft: "10px" }}
            >
              {
                <span style={{ color: "#272727" }}>
                  {activityUser?.firstName + " " + activityUser?.lastName}
                </span>
              }{" "}
              shared feedback with{" "}
              {<span style={{ color: "#272727" }}>{others && others[0]}</span>}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography
              variant="h4"
              style={{
                marginLeft: "10px",
                textAlign: "start",
                fontSize: "10px",
                marginTop: "4px",
              }}
            >
              {activityDate && getDateInActivityFormat(new Date(activityDate))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={2} item container justify="flex-end">
        <Button className={classes.btn} onClick={()=>{
          setFeedbackId(activityId);
          setMember(activityUser);
        }}>
          <Typography variant="h5">View</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
