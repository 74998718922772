import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { put } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export interface UpdateOrgDataSliceProp {
  orgName: string,
}

export const updateOrgData = createAsyncThunk(
  "update/organization",
  async (params: UpdateOrgDataSliceProp, storeActions) => {
    const orgStatus = "ACTIVE";
    const url = `api/v1/user/update/organization?orgName=${params.orgName}&status=${orgStatus}`;
    const response: any = await put(url, params, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const updateOrgDataSlice = createSlice({
  name: "updateOrgData",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateOrgData.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(updateOrgData.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(updateOrgData.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = updateOrgDataSlice.actions;

export default updateOrgDataSlice;
