import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ErrorPopUp } from "../../../components/ErrorPages/errorModal";
import { ClearAllData } from "../../../constants/clearData";
import { RequestFeedbackFormLayout } from "../../../../layouts/user/request_feedback/requestFeedbackFormLayout";
import { ExtProps, ResponseAttribute, State, User } from "../../../../type";
import { AddButton } from "./buttons";
import { CancelButton, SendButton } from "../request_employee/buttons";
import { ConsentModal } from "../request_employee/popUp";
import {
  AdditionalInfoText,
  AttributeChips,
  AttributeSelectionNoteText,
  AttributeSelectionText,
  ChooseMemberText,
  ContextText,
  FormDivider,
  SelectAttributesText,
  SelectColleagues,
} from "../request_employee/requestFeedbackText";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import validator from "validator";
import { requestFeedbackFromExtClient } from "../../../../redux/slices/RequestFeedbackFromExtClientSlice";
import { RequestFeedbackFormProps } from "../typeRequestFeedback";
import { ValidationPromptModal } from "../../../components/ValidationModal";
import { ContextTextEditorComponent } from "../ContextText";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      fontSize: theme.spacing(1.75),
      fontWeight: 600,
      fontFamily: "Montserrat",
      padding: theme.spacing(1),
      textAlign: "start",
    },
  })
);

export const RequestFeedbackFromExtClientForm: React.FC<
  RequestFeedbackFormProps
> = ({
  setValue,
  user,
  context,
  setContext,
  selectedAttributeIds,
  setSelectedAttributeIds,
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>(
    user ? [user.emailId] : []
  );
  const [isValidEmail, setIsValidEmail] = React.useState<Boolean>(false);
  const [isAnyDomainEmail, setIsAnyDomainEmail] =
    React.useState<Boolean>(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [dispatched, setDispatched] = React.useState(false);
  const [dataCleared, setDataCleared] = React.useState<Boolean>(false);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isAnyUser, setIsAnyUser] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState(
    context.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(context).contentBlocks,
            convertFromHTML(context).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const [showToolbar, setShowToolbar] = React.useState<Boolean>(false);
  const [contextAlert, setContextAlert] = React.useState<Boolean>(false);

  const renderFormDivider = () => <FormDivider />;

  const onEditorStateChange = (state) => {
    setEditorState(state);
    setContext(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  const statusCode: number | null = useSelector(
    (state: State) => state.requestFeedbackFromExtClient.status
  );
  const clients: Array<User> = [];
  const userLables: Array<User> =
    useSelector((state: State) => state.data.data?.users) ?? clients;
  const loggedInUserEmail: string = useSelector(
    (state: State) => state.auth.email
  );
  const userDomain = loggedInUserEmail.split("@")[1];

  const history = useHistory();
  const goToPreviousPath = () => {
    if (history.length > 10) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  const logoutUser = () => {
    setLogout(true);
  };

  const renderErrorModal = () => (
    <ErrorPopUp
      showModal={showErrorModal}
      setShowModal={setShowErrorModal}
      statusCode={statusCode}
      handleNoAuthorization={logoutUser}
      handleAccessDenied={goToPreviousPath}
    ></ErrorPopUp>
  );

  const renderModal = () => (
    <ConsentModal
      showModal={showModal}
      setShowModal={setShowModal}
      setValue={setValue}
      to={"REQUEST_CLIENT"}
    ></ConsentModal>
  );

  const renderChooseMemberText = () => <ChooseMemberText role={"CLIENT"} />;

  const renderSelectColleagues = () => (
    <SelectColleagues isAnyUser={isAnyUser} role={"CLIENT"} />
  );

  const renderAddButton = () => (
    <AddButton
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    ></AddButton>
  );

  const renderSelectAttributesText = () => <SelectAttributesText />;

  const renderAttributeSelectionText = () => <AttributeSelectionText />;

  const renderAttributeSelectionNoteText = () => <AttributeSelectionNoteText />;

  const emptyAttributes: Array<ResponseAttribute> = [];
  const attributes: Array<ResponseAttribute> =
    useSelector((state: State) => state.data.data?.attributes) ??
    emptyAttributes;

  useEffect(() => {
    let validEmail = false;
    let domainValidation = false;

    selectedUsers.map((user) => {
      if (user.includes(userDomain)) domainValidation = true;
      if (!validator.isEmail(user)) validEmail = true;
    });
    setIsValidEmail(validEmail);
    setIsAnyDomainEmail(domainValidation);
  }, [selectedUsers, userDomain]);

  const renderAttributes = () => (
    <AttributeChips
      attributes={attributes}
      selectedAttributeIds={selectedAttributeIds}
      setSelectedAttributeIds={setSelectedAttributeIds}
    ></AttributeChips>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const renderValidationModal = () => (
    <ValidationPromptModal
      open={open}
      setOpen={setOpen}
      charLimitExceeded={true}
    />
  );

  const renderContextText = () => <ContextText role={"CLIENT"} />;

  const renderOptionalText = () => <></>;

  const renderAdditionalInfoText = () => (
    <AdditionalInfoText alert={contextAlert} />
  );

  const renderTextField = () => (
    <ContextTextEditorComponent
      showToolbar={showToolbar}
      setShowToolbar={setShowToolbar}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      context={context}
    />
  );

  const renderWarningText = () => (
    <>
      {isValidEmail && (
        <Grid xs={12} item>
          <Typography
            className={classes.warningText}
            style={{
              color: "#DC0101",
            }}
          >
            {"Kindly enter valid Email-Id(s)"}
          </Typography>
        </Grid>
      )}
      {isAnyDomainEmail && (
        <Grid xs={12} item>
          <Typography
            className={classes.warningText}
            style={{
              color: "#FF8850",
            }}
          >
            {"Kindly enter client email(s) only"}
          </Typography>
        </Grid>
      )}
    </>
  );

  const renderCancelButton = () => (
    <CancelButton setValue={setValue}></CancelButton>
  );

  const renderSendButton = () => <SendButton />;

  function handleSubmit(e) {
    e.preventDefault();
    let regex = /(<([^>]+)>)/gi;
    let validation = false;
    let domainValidation = false;
    let ans = context;
    for (let idx = 0; idx < selectedUsers.length; idx++) {
      if (!validator.isEmail(selectedUsers[idx])) {
        validation = true;
      }
      if (selectedUsers[idx].includes(userDomain)) {
        domainValidation = true;
      }
    }
    if (selectedUsers.length === 0) {
      setIsAnyUser(true);
    }
    if (validation || domainValidation) {
      if (domainValidation) setIsAnyDomainEmail(domainValidation);
      if (validation) setIsValidEmail(validation);
    }
    if (
      ans
        .replaceAll(/\&nbsp;/g, "")
        .replaceAll(regex, "")
        .replace(/\s/g, "")
        .replace(/\n/g, "").length < 3 ||
      context.length < 9
    ) {
      setContextAlert(true);
    } else if (context.length > 10000) {
      handleOpen();
    } else if (selectedUsers.length > 0 && !validation && !domainValidation) {
      const arg: ExtProps = {
        requestedUsersEmail: selectedUsers,
        attributes: selectedAttributeIds,
        context: context
          .replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
          .trim(),
      };
      dispatch(requestFeedbackFromExtClient(arg));
      setDispatched(true);
      setIsAnyDomainEmail(false);
      setIsValidEmail(false);
    }
  }

  const { status, loading } = useSelector(
    (state: State) => state.requestFeedbackFromExtClient
  );

  useEffect(() => {
    if (dispatched === true && status === 201 && loading === false) {
      setSelectedUsers([]);
      setSelectedAttributeIds([]);
      setContext("");
      setContextAlert(false);
      setEditorState(EditorState.createEmpty());
      setShowModal(true);
      setDispatched(false);
    } else if (dispatched === true && status !== null && loading === false) {
      setDispatched(false);
      setShowErrorModal(true);
    }
  }, [dispatched, status, loading]);

  const attributesLoading: Boolean = useSelector(
    (state: State) => state.data.loading
  );

  if (logout && !dataCleared) ClearAllData(setDataCleared);

  if (logout && dataCleared) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <RequestFeedbackFormLayout
      loading={attributesLoading || loading}
      usersArrayLength={selectedUsers.length}
      renderValidationModal={renderValidationModal}
      renderFormDivider={renderFormDivider}
      renderErrorModal={renderErrorModal}
      renderModal={renderModal}
      handleSubmit={handleSubmit}
      renderChooseMemberText={renderChooseMemberText}
      renderSelectColleagues={renderSelectColleagues}
      renderAddButton={renderAddButton}
      renderSelectAttributesText={renderSelectAttributesText}
      renderAttributeSelectionText={renderAttributeSelectionText}
      renderAttributes={renderAttributes}
      renderAttributeSelectionNoteText={renderAttributeSelectionNoteText}
      renderContextText={renderContextText}
      renderOptionalText={renderOptionalText}
      renderAdditionalInfoText={renderAdditionalInfoText}
      renderTextField={renderTextField}
      renderCancelButton={renderCancelButton}
      renderSendButton={renderSendButton}
      renderWarningText={renderWarningText}
      isValidEmail={isValidEmail}
    ></RequestFeedbackFormLayout>
  );
};
