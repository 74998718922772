import { ShareAssessmentAttribute } from "./type";

export const updateAttributeOnClick = (
  newValue,
  shareAssessmentData,
  setShareAssessmentData,
) => {
  let updatedShareFBAttribute: Array<ShareAssessmentAttribute> = [];
  for (let index = 0; index < newValue.length; index++) {
    const arg1: ShareAssessmentAttribute = {
      attributeId: newValue[index].id,
      attributeName: newValue[index].name,
      whatWentWell: "",
      needsToBeImproved: "",
    };
    let idx = 0;
    for (idx = 0; idx < shareAssessmentData.length; idx++) {
      if (shareAssessmentData[idx].attributeId === newValue[index].id) {
        break;
      }
    }
    if (idx === shareAssessmentData.length) {
      updatedShareFBAttribute.push(arg1);
    } else {
      updatedShareFBAttribute.push(shareAssessmentData[idx]);
    }
  }
  setShareAssessmentData(updatedShareFBAttribute);
};

export const handleOnChange =
  (index, shareAssessmentData, setShareAssessmentData) => (e) => {
    let newData = [...shareAssessmentData];
    newData[index][e.target.id] = e.target.value;
    setShareAssessmentData(newData);
  };
