import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Avatar,
  Button,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCustomStyle } from "../../../styles";
import { appColor } from "../../constants/customThemeColor";
import { useDispatch, useSelector } from "react-redux";
import {
  NuggetShareComponentProps,
  ResponseAttribute,
  ShareNuggetProps,
  State,
  User,
} from "../../../type";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  EditorForText,
  RemainingCharWarnText,
} from "../share_feedback/addNewButtonElements";
import { AttributeChips } from "../request_feedback/request_employee/requestFeedbackText";
import { nuggetShare } from "../../../redux/slices/NuggetShareSlice";
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { markShareNuggetTourDone } from "../../../redux/slices/CoachMarkSlice";  
import "../../../App.css";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    height: theme.spacing(62.85),
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(2),
    border: "1px solid #E7E7E7",
    borderRadius: theme.spacing(1.25),
    boxShadow: "0 2px 4px 0 rgba(90,90,90,0.1)",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 4, 0),
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
      margin: theme.spacing(3, 0, 4, 0),
    },
  },
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: `${appColor.whiteBackground}`,
    width: "-webkit-fill-available",
    "&:focus": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
    "&:hover": {
      border: `1px solid ${appColor.regularBorder} !important`,
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
  },
  editor: {
    width: "-webkit-fill-available",
    backgroundColor: appColor.pureWhite,
  },
  marginStyle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(1),
    },
  },
  shareCTA: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: appColor.pureWhite,
    backgroundColor: appColor.regularBackgroundColor,
    padding: theme.spacing(1, 2.5),
    marginTop: theme.spacing(3),
    "&:hover": {
      color: appColor.pureWhite,
      backgroundColor: appColor.regularBackgroundColor,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    color: appColor.pureBlack,
  },
  chip: {
    margin: theme.spacing(5, 0, 1, 0),
    borderColor: "#576BEA",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  typography_header_style: {
    margin: theme.spacing(1.5, 0, 0.5, 0),
  },
  attributeChipsWrapper: {
    height: theme.spacing(15),
    overflowY: "scroll",
    marginBottom: theme.spacing(1.25)
  },
}));

export const NuggetShareComponent: React.FC<NuggetShareComponentProps> = ({ refreshResetSeed }) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [context, setContext] = React.useState<string>("");
  const [successPrompt, setSuccessPrompt] = React.useState<Boolean>(false);
  const [btnClicked, setBtnClicked] = React.useState<Boolean>(false);
  const [selectedAttributeIds, setSelectedAttributeIds] = React.useState<
    number[]
  >([]);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    setContext(draftToHtml(convertToRaw(state.getCurrentContent())));
  };
  const loading: Boolean = useSelector(
    (state: State) => state.data.loading || state.nuggetFeedback.loading
  );
  const status: number | null =
    useSelector((state: State) => state.nuggetFeedback.status) ?? null;
  const [showTour, setShowTour] = useState(false);
  const shareNuggetTourStatus = useSelector((state: State) => state.coachMarks.dashboardTour.shareNuggetTour);
  const dashboardVisitCount = useSelector((state: State) => state.coachMarks.dashboardTour.dashboardVisitCount);
  const tourRef = useRef<Shepherd.Tour | null>(null);
  const history = useHistory();

    useEffect(() => {
    
      if (shareNuggetTourStatus === "PENDING" && dashboardVisitCount === 2) {
        setShowTour(true);
        dispatch(markShareNuggetTourDone()); 
      } else {
        setShowTour(false);
      }
    }, [shareNuggetTourStatus, dashboardVisitCount]);
  
    
    useEffect(() => {
      if (showTour) {
  
        tourRef.current = new Shepherd.Tour({
  
          defaultStepOptions: {
            classes: 'custom-class-sm',
            scrollTo: { behavior: 'smooth', block: 'center' },
          }
        });
    
        const attachToOptions = {
          element: '.share-nugget-class',
          on: 'top'
        };
    
        if (window.innerWidth <= 767) {
  
          attachToOptions.on = 'bottom';
        }
    
        tourRef.current.addSteps([
  
          {
            text: 'Share quick feedback to your peers from here',
            attachTo: attachToOptions,
            id: 'step3',
            scrollTo: true,
            when: {
              show: () => {
                setTimeout(() => {
                  tourRef.current?.next();
                }, 5000);
              }
            },
            classes: 'share-nugget-button-coachmark'
          }
        ]);
    
        tourRef.current.start();
      }
    }, [showTour]);

  useEffect(() => {
    return history.listen(() => {
      if (tourRef.current) {
        tourRef.current?.cancel(); // *** Cancel the tour on route change ***
      }
    });
  }, [history]);

  useEffect(() => {
    if (status === 201 && btnClicked && !loading) {
      setSuccessPrompt(true);
      setSelectedUser(null);
      setSelectedAttributeIds([]);
      setContext("");
      onEditorStateChange(EditorState.createEmpty());
      if(btnClicked){setBtnClicked(false);}
    }
  }, [status, btnClicked, loading]);

  useEffect(() => {
    setTimeout(() => {
      if (successPrompt) setSuccessPrompt(false);
    }, 3000);
  }, [successPrompt]);

  const emptyAttributes: Array<ResponseAttribute> = [];
  const attributes: Array<ResponseAttribute> =
    useSelector((state: State) => state.data.data?.attributes) ??
    emptyAttributes;
  const users: Array<User> = [];
  const labels = useSelector((state: State) => state.data.data?.users) ?? users;

  function handleSubmit(e) {
    e.preventDefault();
    if (
      selectedUser &&
      context
        .replaceAll(/\&nbsp;/g, "")
        .replace(/\s/g, "")
        .replace(/\n/g, "").length > 0 &&
      context.length < 1001
    ) {
      const arg: ShareNuggetProps = {
        receiverId: selectedUser?.id,
        message: context
          .replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
          .trim(),
        attributeIds: selectedAttributeIds,
      };
      dispatch(nuggetShare(arg));
      setBtnClicked(true);
      refreshResetSeed();
    }
  }
  return (
    <>
      <Grid
        xs={12}
        item
        container
        direction="row"
        className={`assessment ${classes.root} share-nugget-class`}
      >
        <form onSubmit={handleSubmit} style={{ width: "inherit" }}>
          {!loading && !successPrompt && (
            <>
              {" "}
              <Grid container justify="center" alignItems="center" item xs={12} >
                <Typography variant="h1" className={`${classes.header} `}>
                  Share a nugget
                </Typography>
              </Grid>
              {selectedUser && (
                <Chip
                  key={selectedUser.id}
                  variant="outlined"
                  onDelete={() => setSelectedUser(null)}
                  avatar={
                    <Avatar
                      alt={selectedUser.firstName}
                      src={selectedUser.imageUrl}
                      className={classes.small}
                    />
                  }
                  label={
                    <Typography
                      variant="h6"
                      className={`${classes.typography_body_3} ${customClasses.typography_font_14_10}`}
                    >
                      {selectedUser.firstName + " " + selectedUser.lastName}
                    </Typography>
                  }
                  className={classes.chip}
                />
              )}
              {selectedUser === null && (
                <>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "14px" }}
                    className={classes.typography_header_style}
                  >
                    Choose member
                  </Typography>
                  <Autocomplete
                    id="tags-filled"
                    data-testid="autocomplete"
                    className={`${classes.editor} ${classes.popper} ${customClasses.popperWidth} ${classes.marginStyle} dashboard-autocomplete`}
                    freeSolo
                    value={selectedUser}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      if (typeof newValue !== "string")
                        setSelectedUser(newValue);
                    }}
                    filterSelectedOptions
                    disableCloseOnSelect
                    options={labels}
                    getOptionLabel={(option) =>
                      option.firstName &&
                      option.firstName + " " + option.lastName
                    }
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Grid container item xs={12} alignItems="center">
                          <Grid
                            item
                            xs={10}
                            container
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Avatar
                              alt={option.firstName + " " + option.lastName}
                              src={option.imageUrl}
                              className={customClasses.avatarSmall}
                            />
                            <div className={classes.typography_body_3}>
                              <Typography
                                variant="body1"
                                className={`${customClasses.typography_font_14_10_600} ${classes.typography_body_3}`}
                              >
                                {option.firstName + " " + option.lastName}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search member by name"
                        variant="outlined"
                      />
                    )}
                  />
                </>
              )}
              <div
                className={`${customClasses.popperWidth} ${classes.editor} ${classes.marginStyle} dashboard`}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "14px" }}
                  className={classes.typography_header_style}
                >
                  Feedback message
                </Typography>
                <EditorForText
                  idx={1}
                  textHeader={"main-context"}
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  always={true}
                />
                <RemainingCharWarnText text={context} limit={1000} />
              </div>
              <Typography
                variant="h6"
                style={{ fontSize: "14px" }}
                className={classes.typography_header_style}
              >
                Select Attribute(s)
              </Typography>
              <div className={classes.attributeChipsWrapper}>
                <AttributeChips
                  attributes={attributes}
                  selectedAttributeIds={selectedAttributeIds}
                  setSelectedAttributeIds={setSelectedAttributeIds}
                  from={"DASHBOARD"}
                ></AttributeChips>
              </div>
              <Grid item xs={12} container justify="center" alignItems="center">
                <Button
                  className={classes.shareCTA}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={
                    context.length < 10 ||
                    context.length > 1000 ||
                    selectedAttributeIds.length === 0 ||
                    selectedUser === null
                  }
                >
                  Share
                </Button>
              </Grid>{" "}
            </>
          )}
          {loading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ position: "relative", marginTop: "210px" }}
            >
              <CircularProgress
                style={{ height: "55px", width: "55px", color: "#576BEA" }}
              />
            </Grid>
          )}
          {!loading && successPrompt && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ position: "relative", marginTop: "210px" }}
              id={"SHARE_NUGGET"}
            >
              <img
                style={{ height: "80px", width: "80px", color: "#576BEA" }}
                src="/success.png"
                alt="Done"
              />
            </Grid>
          )}
        </form>
      </Grid>{" "}
    </>
  );
};
