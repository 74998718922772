import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { PrivacyPolicyLayout } from "../../../layouts/onboarding/privacy_policy/Layout";
import { useCustomStyle } from "../../../styles";
import theme from "../../../theme";

export const PrivacyPolicy = (props) => {
  const classes = useCustomStyle();

  const renderGrowWizeLogo = () => (
    <Grid xs={12} container justify="flex-start" alignItems="center">
      <Grid item>
        <img
          src="/LogoWithName.svg"
          alt="Grow Wize by Technogise"
          style={{
            height: theme.spacing(8),
            width: theme.spacing(20.5),
          }}
        />
      </Grid>
    </Grid>
  );

  const renderPrivacyHeader = () => (
    <Grid xs={12} container justify="center" alignItems="center">
      <Grid item>
        <Typography
          style={{
            fontSize: "40px",
            color: "#272727",
            fontWeight: 800,
            fontFamily: "Montserrat",
            marginBottom: "30px",
          }}
        >
          Privacy and Cookie Policy
        </Typography>
      </Grid>
    </Grid>
  );
  const renderPrivacyText = () => (
    <Grid
      xs={12}
      container
      style={{
        border: "1px solid #DCDCDC",
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 2px 4px 4px rgba(191,191,191,0.3)",
        padding: "40px 60px 40px 60px ",
        marginBottom: "48px",
      }}
    >
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>Introduction</span>
        <br /> Grow-Wize is a product of Technogise Private Limited (referred to
        as ‘Technogise’, ‘We’, ‘our’, or ’us’ below). Technogise is committed to
        respecting your privacy and choices. The statement highlights our
        privacy practices regarding Personal Information that during the course
        of carrying out our business and performing our services, we collect and
        store information about you through this website and also for that
        Personal Information that you provide us while participating in our
        events, emails, interviews, and campaigns.
        <br /> Grow-Wize collects personal data to conduct our business, provide
        and market our services, and meet our legal obligations. Likewise, we
        may also collect personal data for other purposes, which we would
        describe in more detail to you at the point we collect the personal
        data.
        <br /> You may refuse to provide us with some or all of your personal
        data; however, this may limit the ways in which we can interact with
        you, including providing you with our services.
        <br />{" "}
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>
          How we collect your information?
        </span>
        <br /> Your personal information such as name, address, email address
        and phone number, or any other personal data which may reasonably
        identify you, may be collected by: <br />{" "}
        <ul style={{ listStyleType: "disc" }}>
          <li>Attending meetings and/or during telephone calls to us</li>
          <li>
            {" "}
            Corresponding with us by email, in which case we may retain the
            content of your email messages together with your email address and
            our responses
          </li>
          <li>
            {" "}
            Supplying us with your personal information by signing up to receive
            a newsletter or to attend an event
          </li>
          <li>
            {" "}
            Our market research team strongly believes that you / your
            organization will benefit a lot from the services we provide
          </li>
          <li>
            {" "}
            Supplying us with your personal information as part of the
            recruitment process
          </li>
          <li>
            {" "}
            Using our website, during which other personal data may be
            automatically collected, such as your IP address (please see the
            section on cookies).
          </li>
        </ul>
        <br />
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>Sensitive information</span> We will
        not collect sensitive (special category) personal information from
        visitors to our website, clients or our employees, except where
        necessitated by the terms of our engagement. ‘Sensitive personal
        information’ includes information about racial or ethnic origin,
        political opinions, religious or philosophical beliefs, trade union
        membership, genetic data, biometric data, data concerning health or data
        concerning a natural person’s sex life or sexual orientation.
        <br /> Any sensitive personal information collected as part of our
        lawful processing obligations will be protected using best practice
        information security controls.
        <br />{" "}
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>
          How we use your personal information
        </span>{" "}
        <br />
        We will use your personal information solely for the purpose for which
        it was provided and share it with Technogise partners, staff and
        consultants on a ‘need to know’ basis.
        <br /> We will not use your data for other purposes without your
        explicit consent. You can find a detailed list of our partners under
        Disclosing personal information to third parties section.
        <br /> We may use your personal information for the following purposes:
        <br />
        <ul style={{ listStyleType: "disc" }}>
          {" "}
          <li>
            To communicate with you regarding your query or application. We may
            contact you by post, email, telephone or text message. You may
            opt-out / unsubscribe from such communications at any time by
            emailing us at{" "}
            <span style={{ color: "#576BEA" }}>privacy@technogise.com</span>{" "}
          </li>
          <li>
            To process any job application you may make to us (including any
            sensitive personal information you provide);
          </li>
          <li> To share, market, provide or administer our services;</li>
          <li> To update client records; </li>
          <li>
            To confirm identity, ascertain creditworthiness and trace
            whereabouts where necessary;
          </li>
          <li>
            {" "}
            To comply with legal and regulatory requirements including verifying
            the identity of new clients/associates (and in certain circumstances
            existing clients/associates) to comply with anti-money laundering
            regulations, ‘right to work’ and other legislative requirements
            applicable to Technogise;
          </li>
          <li> To comply with law enforcement.</li>
        </ul>
        <br /> As described in this policy, personal information will be
        retained by us and will not be sold, transferred or otherwise disclosed
        to any third party, other than for the purpose of lawful processing.
        Personal information may be anonymized/pseudonymized for the purpose of
        research to inform our marketing and development strategies or where
        such disclosure is required by law or court order.
        <br /> If your personal information changes or you no longer wish to
        receive a particular communication from us please let us know and we
        will correct, update or remove your details. This can be done by
        emailing us at{" "}
        <span style={{ color: "#576BEA" }}>privacy@technogise.com</span>
        .<br />
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>Security</span>
        <br /> In providing services to you, we may process personal data as a
        controller or on your behalf as a data processor. Where we act as a
        processor on your behalf we will only process personal data for the
        purposes outlined above or otherwise in accordance with your
        instructions, and only keep it for as long as it is needed for that
        purpose.
        <br /> We use up-to-date data storage and security controls to protect
        your personal information from unauthorized access, improper use or
        disclosure, unauthorized modification or unlawful destruction or
        accidental loss. All our employees and any third parties we engage to
        process your personal information are obliged to respect the
        confidentiality of your information.
        <br />{" "}
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>
          Disclosing personal information to third parties
        </span>{" "}
        We may disclose your personal information to our agents or service
        providers, where there is a lawful basis, for any of the purposes set
        out in this policy. Categories of Non-Technogise parties with which we
        share your personal data include vendors such as host and cloud service
        providers, marketing and mailing agencies, and sub-contractors involved
        in the fulfillment of our contractual obligations towards our clients.
        <br />
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        We will not sell your information to a third party.
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <br /> <span style={{ fontWeight: 600 }}>Cookies</span>
        <br /> Grow-Wize website makes use of cookies to save and retrieve
        information about your visit to our sites. Cookies are small files of
        software which save and retrieve information about your visit to a
        website or application. They reside in your internet browser to help
        remember your preferences and previous activity. You can find more
        information about cookies at{" "}
        <span style={{ color: "#576BEA" }}>
          www.allaboutcookies.org
        </span> and{" "}
        <span style={{ color: "#576BEA" }}>www.youronlinechoices.eu</span>.
        <br /> Cookies currently used on our site identify you as an anonymized
        number. We and our partners (e.g., marketing partners, affiliates, or
        analytics providers) use cookies and log files to analyze trends,
        administer the website, track users' movements around the website, and
        gather demographic information about our user base. We may receive
        anonymized reports based on the use of these technologies by our
        partners on an aggregated basis. We also use this data to provide the
        site’s content, ensure the functionality of our information technology
        systems, and to optimize our website.
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <br /> The following cookies are in use on our website:
        <br />
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Essential Cookies : for improving the user’s experience and
            navigation of the website
          </li>
          <li>
            {" "}
            Google Analytics: for monitoring traffic to our website. Knowing how
            many people have visited and from where can help us build a better
            site
          </li>
          <li>
            {" "}
            Microsoft Clarity: for understanding people interaction and to help
            us build a better site
          </li>
        </ul>
        <br /> You can turn off cookies in your web browser’s settings.
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <br /> <span style={{ fontWeight: 600 }}>Your rights</span>
        <br /> Under the GDPR, you have rights as an individual which you can
        exercise in relation to the information we hold about you.
        <br /> If at any time you wish to exercise your rights as a data subject
        in relation to information we hold about you as an individual please
        contact us by email to{" "}
        <span style={{ color: "#576BEA" }}>privacy@technogise.com</span> <br />
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <span style={{ fontWeight: 600 }}>Testimonials</span>
        <br /> We display personal testimonials of satisfied customers on our
        site in addition to other endorsements. With your consent, we may post
        your testimonial along with your name. If you wish to update or delete
        your testimonial, you can contact us at{" "}
        <span style={{ color: "#576BEA" }}>privacy@technogise.com</span>
      </Typography>
      <Typography
        variant="h6"
        className={classes.typography_font_14_10}
        style={{ textAlign: "justify", marginBottom: "24px" }}
      >
        <br /> <span style={{ fontWeight: 600 }}>Further enquiry</span>
        <br /> To know more about our Data Protection policies, you can contact
        us at <span style={{ color: "#576BEA" }}>privacy@technogise.com</span>
      </Typography>
    </Grid>
  );
  return (
    <PrivacyPolicyLayout
      renderPrivacyText={renderPrivacyText}
      renderPrivacyHeader={renderPrivacyHeader}
      renderGrowWizeLogo={renderGrowWizeLogo}
    ></PrivacyPolicyLayout>
  );
};
