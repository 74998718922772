import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseTeam } from "../../type";
import { get } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

export const getAllTeamsData = createAsyncThunk(
  "teamsData/getAllTeams",
  async (_, storeActions) => {
    const url = `api/v1/admin/teams`;
    const response: any = await get(url, storeActions);

    if (response.status === HttpStatus.OK) {
      return {
        status: HttpStatus.OK,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const adminTeamDataSlice = createSlice({
  name: "adminTeamData",
  initialState: {
    status: null as number | null,
    text: null as string | null,
    teams: [] as ResponseTeam[] | [],
    loaded: false as boolean,
    loading: false as boolean,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.loaded = false;
      state.text = null;
      state.teams = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTeamsData.fulfilled, (state, action) => {
      state.status = action.payload.status;
      if (action.payload.data !== undefined) {
        state.teams = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.teams = [];
        state.text = action.payload.text;
      }
      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(getAllTeamsData.pending, (state) => {
      state.loading = true;
      state.status = null;
      state.text = null;
      state.teams = state.teams?.length > 0 ? state.teams : [];
      state.loaded = false;
    });
    builder.addCase(getAllTeamsData.rejected, (state, action) => {
      state.status = 503;
      state.text = null;
      state.teams = [];
      state.loaded = false;
      state.loading = false;
    });
  },
});

export const { clearData } = adminTeamDataSlice.actions;

export default adminTeamDataSlice;
