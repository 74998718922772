import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnswerTypeEnum } from "../../enums/answerTypeEnums";
import { post } from "../../util/APIService";
import { HttpStatus } from "../../enums/httpStatus";

type AddQuestionRequest = {
  questionText: string,
  answerType: AnswerTypeEnum | null
}

export interface AddAttriubteSliceProp {
  attributeName: string,
  status: string,
  questions: AddQuestionRequest[],
}

export const addAttribute = createAsyncThunk(
  "admin/add/attributes",
  async (params: AddAttriubteSliceProp, storeActions) => {
    const response: any = await post('api/v1/admin/attributes', params, storeActions);
    if (response.status === HttpStatus.CREATED) {
      return {
        status: response.status,
        data: await response.json(),
      };
    } else {
      return {
        status: response.status,
        text: await response.text(),
      };
    }
  }
);

const addAttributeSlice = createSlice({
  name: "addAttribubte",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as number | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAttribute.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(addAttribute.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(addAttribute.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = addAttributeSlice.actions;

export default addAttributeSlice;
