import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  likertChip: {
    border: "1px solid",
    height: theme.spacing(6),
    width: theme.spacing(10),
    borderRadius: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(3.75),
      width: theme.spacing(6.25),
    },
  },
  avatarIcon: {
    height: theme.spacing(3.75),
    width: theme.spacing(3.75),
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
  },
  container: {
    padding: theme.spacing(0, 2.5, 0, 2.5),
    margin: theme.spacing(1.5, 0, 1.5, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5, 0, 1.5),
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  avatarLikertText: {
    color: "#576BEA",
    margin: theme.spacing(0, 0, 0, 1.75),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 0, 0.75),
    },
  },
  avatarLikertNaText: {
    color: "#272727",
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
  },
  commonStyle: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
    },
  },
}));

export const ResponseValue = ({ answer }) => {
  const classes = useStyle();

  return (
    <Grid item xs={12} container justify="flex-start" alignItems="center">
      {answer[1] !== "/NA.svg" && (
        <Grid
          item
          className={classes.likertChip}
          container
          justify="center"
          alignItems="center"
          style={{
            borderColor: answer[3],
            backgroundColor: answer[2],
          }}
        >
          {<Avatar src={answer[1]} className={classes.avatarIcon} />}
        </Grid>
      )}
      <Grid item>
        <Typography
          className={
            answer[1] !== "/NA.svg"
              ? `${classes.commonStyle} ${classes.avatarLikertText}`
              : `${classes.commonStyle} ${classes.avatarLikertNaText}`
          }
          style={
            answer[1] !== "/NA.svg"
              ? { color: answer[3] }
              : { color: "#272727" }
          }
        >
          {answer[0]}
        </Typography>
      </Grid>
    </Grid>
  );
};
