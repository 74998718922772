import { makeStyles } from "@material-ui/core";
import { TitleProps } from "../customAttributeAndQuestions/attributeListTable/helper";
import { appColor } from "../../constants/customThemeColor";

export const TEAM_TITLES: Array<TitleProps> = [
  {
    name: "Name",
    size: 3,
  },
  {
    name: "Assign Role",
    size: 4,
  },
  {
    name: "Status",
    size: 3,
  },
  {
    name: "Action",
    size: 2,
  },
];

export const createTeamStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(11, 6, 2, 5),
    marginTop: theme.spacing(6.5),
    background: "#FFFFFF",
    height: "calc(100vh - 52px)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  label: {
    color: "#272727",
    fontSize: 18,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
  divider: {
    width: "100%",
    background: "#E7E7E7",
    height: 0.5,
    marginBottom: theme.spacing(4),
  },
  saveButton: {
    height: "fit-content",
    background: "#7C69EC",
    textTransform: "uppercase",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "8px 40px",
    marginBottom: theme.spacing(5),
  },
  disabledButton: {
    pointerEvents: "none",
    backgroundColor: "#A0A0A0 !important",
    color: "#fff !important",
  },
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  infoText: {
    color: "#A0A0A0",
    fontSize: 14,
    fontWeight: 400,
  },
  attributeNameInput: {
    marginBottom: 0,
    // width: "100%",
    "& >:first-child": {
      background: "#FFFFFF",
    },
    "& > p:last-child": {
      color: "#DC0101",
      fontSize: 14,
      fontWeight: 400,
      marginLeft: 0,
      marginTop: theme.spacing(2),
      fontFamily: "Montserrat",
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: `${appColor.pureBlack}`,
  },
  cancelButton: {
    margin: theme.spacing(2, 2, 0, 0),
    padding: theme.spacing(1.125, 4.95, 1.375, 4.95),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3.5, 0, 0),
      padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
    },
  },
  chip: {
    margin: theme.spacing(2, 1, 1, 1),
    borderColor: "#576BEA",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const teamMemberListItemStyles = makeStyles((theme) => ({
  container: {
    padding: "14px 44px",
    height: theme.spacing(11),
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  small: {
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    margin: theme.spacing(0, 2.5, 0, 2.5),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: theme.spacing(0, 0, 0, 1),
    },
  },
  body: {
    width: "100%",
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF",
  },
  typography_body_2: {
    textAlign: "left",
    wordBreak: "break-word",
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0, 1.5),
    },
  },
  btn: {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 0, 0),
  },
  typograph_cta: {
    fontWeight: 500,
    color: "#576BEA",
    fontStyle: "Montserrat",
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "none",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  typography_active: {
    color: "#576BEA",
    backgroundColor: "#E4E8FB",
    borderRadius: theme.spacing(12.5),
  },
  disabled_status: {
    backgroundColor: "#AFAFAF",
    color: "#fff",
  },
  typography_user_status: {
    textAlign: "center",
    padding: theme.spacing(0.75),
    fontWeight: 500,
    borderRadius: theme.spacing(5.5),
    width: theme.spacing(11.25),
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(8),
      fontSize: "10px",
    },
  },
  disabledAction: {
    pointerEvents: "none",
    opacity: 0.5,
    "& > MuiGrid-root makeStyles-wrapper-145" :{
      background: "rgba(175, 175, 175, 0.30)",
      border: "1px solid rgba(175, 175, 175, 0.30)",

    }
  },
  disabledContainer: {
    background: "#F4F4F4",
  },
  disabledText: {
    color: "#979797",
  },
}));

export const teamMemberListStyles = makeStyles((theme) => ({
  root: {
    width: "inherit",
  },
  row: {
    width: "100%",
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
  },
  header: {
    padding: "14px 44px",
    backgroundColor: "rgba(87,107,234,0.05)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  typography_body: {
    color: "#868686",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      padding: theme.spacing(0, 0, 0, 1),
    },
  },
  typography_body_1: {
    color: "#868686",
    textAlign: "start",
    paddingLeft: theme.spacing(2.5),
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  infoText: {
    color: "#A0A0A0",
    fontSize: 14,
    fontWeight: 400,
  },
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
}));

export enum MESSAGES {
  TEAM_CREATED = "New team created successfully",
  TEAM_UPDATED = "Team details edited successfully",
}
