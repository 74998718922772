import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { NoCustomDataText } from "../../components/list/noRecordPage/NoRecordText";
import { useCustomStyle } from "../../../styles";
import { State } from "../../../type";
import { getDateInDesiredFormat } from "../../../util/GetDateInDesiredFormat";
import { SharedAssessmentListData } from "./type";

const useStyle = makeStyles((theme) => ({
  typography_body_1: {
    textAlign: "left",
    padding: theme.spacing(1, 1, 1, 1),
    marginLeft: theme.spacing(1.75),
  },
  typography_body_2: {
    textDecorationLine: "underline",
    color: "#576BEA",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "none",
    padding: theme.spacing(1, 1, 1, 1),
    marginRight: theme.spacing(1),
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  header: {
    padding: theme.spacing(1, 0, 1, 0),
    width: "100%",
    borderTop: theme.spacing(0.005),
    borderColor: "#97979724",
    borderLeft: theme.spacing(0),
    borderRight: theme.spacing(0),
    borderBottom: theme.spacing(0),
    borderStyle: "solid",
  },
}));

export const AssessmentList = ({ setAssessmentId }) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();

  const emptyArray: Array<SharedAssessmentListData> = [];
  const assessments =
    useSelector((state: State) => state.sharedAssessmentList.data) ??
    emptyArray;

  return (
    <>
      {assessments.length > 0 &&
        assessments.map((assessment, index) => (
          <Grid
            key={`assessment-shared-list-${index}`}
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            className={classes.header}
          >
            <Grid
              item
              container
              justify="flex-start"
              alignItems="center"
              xs={10}
            >
              <Typography
                variant="h6"
                className={`${customClasses.typography_font_14_12_500} ${classes.typography_body_1}`}
              >
                {getDateInDesiredFormat(new Date(assessment.sharedDate))}
              </Typography>
            </Grid>
            <Grid item container justify="flex-end" alignItems="center" xs={2}>
              <Button onClick={() => setAssessmentId(assessment.assessmentId)}>
                <Typography
                  variant="body1"
                  className={classes.typography_body_2}
                >
                  View More
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ))}
      {assessments.length === 0 && (
        <NoCustomDataText from={"SHARED_ASSESSMENT"} />
      )}
    </>
  );
};
