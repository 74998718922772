import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import HttpsRedirect from "react-https-redirect";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://1a3a05a798ce4552a1a3315b49308991@o913969.ingest.sentry.io/5852256",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: `${process.env.REACT_APP_ENV}`,
});

ReactDOM.render(
  <HttpsRedirect
    disabled={process.env.REACT_APP_DISABLED_HTTPS_REDIRECT === "true"}
  >
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <CssBaseline />
        <Sentry.ErrorBoundary
          fallback={<p>An error occurred while loading page</p>}
        >
          <App />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    </ThemeProvider>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
