import { useCustomStyle } from "../../../../styles";
import { VriddhiResponseTypesProps } from "../../../../type";
import { vriddhiResponseOptionsStyle } from "./vriddhi.styles";
import { Avatar, Chip, Grid, Hidden, Typography } from "@material-ui/core";
import { handleClickChip } from "../addNewButtonHandles";
import { AnswerTypeEnum } from "../../../../enums/answerTypeEnums";

export const VriddhiResponseOptions: React.FC<VriddhiResponseTypesProps> = ({
  idx,
  idx_1,
  emojiSelected,
  setEmojiSelected,
  shareFeedbackData,
  setShareFeedbackData,
  scaleVersion,
  responseScales,
  responseScales2,
}) => {
  const classes = vriddhiResponseOptionsStyle();
  const customClasses = useCustomStyle();

  let currentScale;
  if (responseScales.length > 0) {
    currentScale = responseScales.find(
      (scale) =>
        scale.scaleName === AnswerTypeEnum.VRIDDHI &&
        scale.scaleVersion === scaleVersion
    );
  } else {
    currentScale = responseScales2.find(
      (scale) =>
        scale.scaleName === AnswerTypeEnum.VRIDDHI &&
        scale.scaleVersion === scaleVersion
    );
  }

  const renderResponseChip = ({ emoji, idx_2 }) => {
    return (
      <>
        <Chip
          key={`chip ${idx_2}`}
          disabled={
            shareFeedbackData[idx].questions[idx_1].answer === "Skipped"
          }
          avatar={<Avatar src={emoji.url} className={classes.avatar}></Avatar>}
          className={`${classes.vriddhiResponseChip} ${
            idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId
              ? "selected"
              : ""
          }`}
          onClick={handleClickChip(
            idx_2,
            idx,
            idx_1,
            emojiSelected,
            setEmojiSelected,
            shareFeedbackData,
            setShareFeedbackData,
            currentScale.scaleResponses
          )}
          style={
            idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
            shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
              ? {
                  borderColor: emoji.borderColor,
                  backgroundColor: "#8BC6601F",
                }
              : { borderColor: "#FFFFFF" }
          }
        />
        <Typography
          variant="h4"
          className={`${customClasses.typography_font_14_10} ${classes.avatarVriddhiResponseText}`}
          style={
            idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
            shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
              ? { color: "rgb(44, 129, 0)" }
              : { color: "#A0A0A0" }
          }
          data-testid={`responseTypeLabel-${idx_2}`}
        >
          {emoji.name}
        </Typography>
      </>
    );
  };

  return (
    <Grid
      container
      direction="row"
      key={`grid ${idx + idx_1}`}
      className={classes.vriddhiGrid}
      data-testid="vriddhi-response-container"
    >
      {currentScale.scaleResponses.map((emoji, idx_2) => (
        <>
          <Hidden xsDown key={`list-${idx_2}`}>
            <Grid item justify="center">
              {renderResponseChip({ emoji, idx_2 })}
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item justify="flex-start" container alignItems="center">
              {renderResponseChip({ emoji, idx_2 })}
            </Grid>
          </Hidden>
        </>
      ))}
    </Grid>
  );
};
