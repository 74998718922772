import { Avatar, Button, Chip, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useSelector } from "react-redux";
import { useCustomStyle } from "../../../../styles";
import theme from "../../../../theme";
import { AddButtonProps, CancelButtonProps, State, User } from "../../../../type";

const useStyle = makeStyles((theme) => ({
  popper: {
    border: `1px solid #E7E7E7 !important`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#FFFFFF",
    width: "-webkit-fill-available",
    fontSize: 13,
    "&:focus": {
      borderColor: "#576BEA !important",
    },
    "&:hover": {
      borderColor: "#576BEA !important",
    },
  },
  textField: {
    "&:focus": {
      border: "none !important",
    },
    "&:hover": {
      border: "none !important",
    },
    fontSize: theme.spacing(1.75),
    border: "none !important",
    color: "#A0A0A0",
    fontWeight: 500,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  chip: {
    margin: theme.spacing(2, 1, 1, 1),
    borderColor: "#576BEA",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  btn: {
    margin: theme.spacing(3, 0, 0, 0),
    backgroundColor: "#576BEA",
  },
  btn_: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  typography_body_1: {
    alignItems: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  typography_body_2: {
    alignItems: "center",
    color: "#868686",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: "#000000",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

export const AddButton: React.FC<AddButtonProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  const classes = useStyle();
  const customStyle = useCustomStyle();

  const users: Array<User> = [];
  const labels = useSelector((state: State) => state.data.data?.users) ?? users;

  return (
    <>
      <Autocomplete
        id="tags-filled"
        className={`${classes.popper} ${customStyle.popperWidth} dashboard-autocomplete`}
        multiple
        freeSolo
        value={selectedUsers}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedUsers(newValue.filter((v) => typeof v !== "string"));
        }}
        filterSelectedOptions
        disableCloseOnSelect
        renderTags={(value: User[], getTagProps) =>
          value.map((user: User, index: number) => (
            <Chip
              key={user.id}
              variant="outlined"
              avatar={
                <Avatar
                  alt={user.firstName.toUpperCase()}
                  src={user.imageUrl}
                  className={classes.small}
                />
              }
              label={
                <Typography className={classes.typography_body_3}>
                  {user.firstName + " " + user.lastName}
                </Typography>
              }
              className={classes.chip}
              {...getTagProps({ index })}
            />
          ))
        }
        options={labels}
        getOptionLabel={(option) => option.firstName + " " + option.lastName}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Avatar
              alt={option.firstName.toUpperCase()}
              src={option.imageUrl}
              className={classes.small}
            />
            <div className={classes.typography_body_3}>
              <Typography className={classes.typography_body_3}>
                {option.firstName + " " + option.lastName}
              </Typography>
            </div>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search member by name"
            variant="outlined"
            className={classes.textField}
          />
        )}
      />
    </>
  );
};

export const SendButton = () => {
  const classes = useStyle();

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      className={classes.btn}
    >
      <Typography variant="h2" className={classes.typography_body_1}>
        Send
      </Typography>
    </Button>
  );
};

export const CancelButton: React.FC<CancelButtonProps> = ({ setValue }) => {
  const classes = useStyle();

  async function handleCancel(e) {
    e.preventDefault();
    setValue(0);
  }
  return (
    <Button
      variant="outlined"
      style={{ marginRight: theme.spacing(1) }}
      onClick={handleCancel}
      className={classes.btn_}
    >
      <Typography variant="h2" className={classes.typography_body_2}>
        Cancel
      </Typography>
    </Button>
  );
};
