import {
  Button,
  fade,
  Grid,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { DeleteAttributeCardComponent } from "../../components/DeleteAttributeCard";
import {
  ResponseAttribute,
  ShareFeedbackAttributeNameAndId,
  State,
} from "../../../type";
import { AttributeTitleComponent } from "../share_feedback/AttributeTitle";
import { RenderSuggestions, RenderContext } from "./addNewButtonElements";
import { updateAttributeOnClick } from "./addNewButtonHandles";
import { AddNewButtonProps } from "./type";

const useStyle = makeStyles((theme) => ({
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
  },
  textField: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    width: "100%",
    padding: "0px 0px 10px 0px",
    marginTop: "20px",
    marginBottom: "20px",
    textAlign: "start",
  },
  btn: {
    margin: theme.spacing(3, 0, 0, 0),
    backgroundColor: "#576BEA",
  },
  typography_body_1: {
    alignItems: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  typography_body_3: {
    marginLeft: theme.spacing(0.5),
    alignItems: "center",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  skipCTA: {
    fontSize: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#868686",
    textDecoration: "underline",
    margin: theme.spacing(0.25, 1.5, 0, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0.25, 0, 0, 0),
    },
  },
}));

export const AddNewButton: React.FC<AddNewButtonProps> = ({
  attributes,
  setAttributes,
  shareAssessmentData,
  setShareAssessmentData,
  setValue,
  onAnyChange
}) => {
  const classes = useStyle();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const attr: Array<ResponseAttribute> = [];
  const attLabels =
    useSelector((state: State) => state.data.data?.attributes) ?? attr;
  const [onDeleteAttribute, setOnDeleteAttribute] = React.useState<number>(0);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [deletingAttibute, setDeletingAttribute] =
    React.useState<ShareFeedbackAttributeNameAndId>({
      attributeId: 0,
      attributeName: "",
    });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: number) => {
    setAttributes(attributes.filter((attribute) => attribute.id !== id));
    handleModalClose();
    setOnDeleteAttribute(1);
  };

  useEffect(() => {
    if (onDeleteAttribute === 1) {
      updateAttributeOnClick(
        attributes,
        shareAssessmentData,
        setShareAssessmentData
      );
    }
    setOnDeleteAttribute(0);
  }, [onDeleteAttribute]);

  const handleClose = (
    event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "toggleInput") {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const renderDeletionModal = () => (
    <DeleteAttributeCardComponent
      openModal={openModal}
      deletingAttribute={deletingAttibute}
      handleDelete={handleDelete}
      handleModalClose={handleModalClose}
    />
  );

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        direction="column"
        className="assessment"
      >
        {shareAssessmentData.map((attribute, idx) => (
          <Paper
            variant="outlined"
            className={classes.paper}
            key={attribute.attributeId}
            id={attribute.attributeId.toString()}
          >
            <Grid key={attribute.attributeId} xs={12} item container>
              {<AttributeTitleComponent attribute={attribute} setDeletingAttribute={setDeletingAttribute} handleModalOpen={handleModalOpen} />}
              <Grid xs={6} item container justify="flex-start">
                {
                  <RenderContext
                    attribute={attribute}
                    idx={idx}
                    shareAssessmentData={shareAssessmentData}
                    setShareAssessmentData={setShareAssessmentData}
                    setValue={setValue}
                    onAnyChange={onAnyChange}
                  ></RenderContext>
                }
              </Grid>

              <Grid xs={6} item container justify="flex-start">
                {
                  <RenderSuggestions
                    attribute={attribute}
                    idx={idx}
                    shareAssessmentData={shareAssessmentData}
                    setShareAssessmentData={setShareAssessmentData}
                    setValue={setValue}
                    onAnyChange={onAnyChange}
                  ></RenderSuggestions>
                }
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
      <Grid container justify="center" alignItems="center">
        {renderDeletionModal()}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.btn}
      >
        <Typography variant="h2" className={classes.typography_body_1}>
          ADD NEW
        </Typography>
      </Button>
      <Popper
        id="popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <Autocomplete
          open
          onClose={handleClose}
          filterSelectedOptions
          multiple
          value={attributes}
          onChange={(event, newValue) => {
            setAttributes(newValue);
            updateAttributeOnClick(
              newValue,
              shareAssessmentData,
              setShareAssessmentData
            );
          }}
          disablePortal
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={(option) => (
            <React.Fragment>
              <div className={classes.typography_body_3}>
                <Typography
                  variant="h5"
                  className={classes.typography_body_3}
                >
                  {option.name}
                </Typography>
              </div>
            </React.Fragment>
          )}
          options={attLabels}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              placeholder="Search attribute by name"
              variant="outlined"
              ref={params.InputProps.ref}
              autoFocus
              inputProps={params.inputProps}
              className={classes.textField}
            />
          )}
        />
      </Popper>
    </>
  );
};
