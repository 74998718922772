import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  commonFontStyle: {
    textAlign: "justify",
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#576BEA",
    fontSize: theme.spacing(3),
    marginTop: theme.spacing(7.5),
  },
}));

export const WelcomeText = () => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid xs={12} item>
        <Typography className={classes.commonFontStyle}>Hello,</Typography>
      </Grid>
    </Grid>
  );
};
