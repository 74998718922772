import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { ResponseTeam, State } from "../../../type";
import { CustomSearchBoxComponent } from "../../components/CustomSearchboxComponent";
import { SearchOption } from "../../components/type";
import { ChangePageButtonComponent } from "../../components/changePageButtons";
import { useHistory, withRouter } from "react-router-dom";
import { TeamsLayout } from "../../../layouts/admin/teams/Layout";
import { teamToOption } from "../../../util/teamOption";
import TeamListTable from "./teamListTable/teamListTable";

const useStyle = makeStyles((theme) => ({
  btn: {
    height: "fit-content",
    background: "#7C69EC",
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "10px 28px",
  },
}));

const Teams = (props) => {
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [filteredTeams, setFilteredTeams] = React.useState<Array<ResponseTeam>>(
    []
  );
  const [pageData, setPageData] = React.useState<Array<ResponseTeam>>([]);
  const opt = props.location.state?.option;
  const [option, setOption] = React.useState<SearchOption | null>(
    opt ? opt : null
  );

  const queryParams = new URLSearchParams(props.location.search);
  const pageValue =
    props.location.state?.pageNo || Number(queryParams.get("page"));

  const [pageNumber, setPageNumber] = React.useState<number>(
    pageValue ? pageValue : 1
  );
  const history = useHistory();

  const teams = useSelector((state: State) => state.adminTeamData.teams);

  const count = teams?.length;

  useEffect(() => {
    const pageData = option
      ? [...filteredTeams]
      : filteredTeams?.slice((pageNumber - 1) * 10, pageNumber * 10);
    setPageData(pageData);
    history.push({
      pathname: "/admin/teams",
      search: !option ? `?page=${pageNumber}` : `?search=${option?.name}`,
    });
  }, [filteredTeams, pageNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  useEffect(() => {
    if (option === null) {
      setFilteredTeams(teams);
    }
    if (option !== null) {
      const matchingTeams = teams.filter((team) => team?.id === option?.teamId);
      setFilteredTeams(matchingTeams);
    }
  }, [option, teams]);

  useEffect(() => {
    setPageNumber(pageValue ? pageValue : 1);
  }, [option]);

  const classes = useStyle();

  const loading: Boolean = useSelector(
    (state: State) => state.adminTeamData.loading
  );

  const statusCode: number | null = useSelector((state: State) =>
    state.adminTeamData.status !== 200 ? state.adminTeamData.status : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const renderSearchBox = () => (
    <CustomSearchBoxComponent
      option={option}
      setOption={setOption}
      setPageNumber={setPageNumber}
      options={[...teams?.map(teamToOption)]}
      placeholder="Search by teams name"
    ></CustomSearchBoxComponent>
  );

  const renderTeamsList = () => {
    return (
      <Grid container>
      <TeamListTable
        loading={loading}
        data={pageData}
        pageNumber={pageNumber}
      ></TeamListTable>
      </Grid>
    );
  };

  const renderNewTeamButton = () => {
    return (
      <Button
        color="primary"
        className={classes.btn}
        variant="contained"
        onClick={() => {
          history.push("/admin/teams/create");
        }}
      >
        Create New Team
      </Button>
    );
  };

  const renderChangePageButtons = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <ChangePageButtonComponent
        pageCount={Math.ceil(filteredTeams.length / 10)}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      ></ChangePageButtonComponent>
    </Grid>
  );

  return (
    <TeamsLayout
      props={props}
      loading={loading}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderTeamsList={renderTeamsList}
      renderSearchBox={renderSearchBox}
      renderChangePageButtons={renderChangePageButtons}
      count={count}
      renderNewTeamButton={renderNewTeamButton}
    ></TeamsLayout>
  );
};

const Component = withRouter(Teams);
export { Component as Teams };
