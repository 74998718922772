import {
    Button,
    Grid,
    Fade,
    makeStyles,
    Typography,
  } from "@material-ui/core";
  import React from "react";
  import Backdrop from "@material-ui/core/Backdrop";
  import Modal from "@material-ui/core/Modal";
import { DeactivateUserModalComponentProp } from "./type";

  const useStyle = makeStyles((theme) => ({
    modal: { display: "flex", alignItems: "center", justifyContent: "center" },
    modalGrid: {
      width: theme.spacing(83),
      height: theme.spacing(28),
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      padding: theme.spacing(0, 0, 4, 0),
      border: "4px solid rgba(175,175,175,0.3)",
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(40),
        height: theme.spacing(25),
        padding: theme.spacing(0, 0, 6, 0),
      },
    },
    deactivateText: {
      fontWeight: 600,
      fontSize: theme.spacing(3),
      color: "#272727",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.75),
      },
    },
    btn1: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(1.375, 4.95, 1.375, 4.95),
      backgroundColor: "#7C69EC",
      "&:hover": {
        backgroundColor: "#5c49cc",
      },
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
      },
    },
    btn_: {
      margin: theme.spacing(2, 5, 0, 0),
      padding: theme.spacing(1.125, 4.95, 1.375, 4.95),
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3, 3.5, 0, 0),
        padding: theme.spacing(1.125, 2.625, 1.125, 2.625),
      },
    },
    typography_body_2_: {
      alignItems: "center",
      color: "#868686",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    typography_body_4: {
      alignItems: "center",
      color: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
  }));

  export const DeactivateUserModalComponent: React.FC<DeactivateUserModalComponentProp> = ({
      id,
      open,
      firstName,
      lastName,
      handleCancel,
      handleDeactivateAccount
  }) => {
      const classes = useStyle();
      return <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid
          container
          className={classes.modalGrid}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} container justify="center" alignItems="flex-start">
            <Typography
              className={classes.deactivateText}
            >{`Deactivate ${firstName} ${lastName}?`}</Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
          >
            <Grid item>
              {
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  className={classes.btn_}
                >
                  <Typography
                    variant="h5"
                    className={classes.typography_body_2_}
                  >
                    Cancel
                  </Typography>
                </Button>
              }
            </Grid>
            <Grid item>
              {
                <Button
                  onClick={() => {
                    handleDeactivateAccount(id);
                  }}
                  className={classes.btn1}
                  color="primary"
                  variant="contained"
                >
                  <Typography
                    variant="h5"
                    className={classes.typography_body_4}
                  >
                    Confirm
                  </Typography>
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  }
