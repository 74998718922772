import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { CommonFunctionalityComponent } from "../../components/CommonFunctionalityComponent";
import { DashboardLayout } from "../../../layouts/user/dashboard/Layout";
import { countReceivedFeedback } from "../../../redux/slices/CountReceivedFeedbacksSlice";
import { countSharedFeedback } from "../../../redux/slices/CountSharedFeedbackSlice";
import { getAllReceivedRequests } from "../../../redux/slices/FeedbackRequestsReceivedSlice";
import { requestData } from "../../../redux/slices/UsersAndAttributesSlice";
import {
  ReceivedRequest,
  RequestingParams,
  State,
  UserAndAttributeSliceProps,
} from "../../../type";
import { DashboardCountCards } from "./dashboardCountCards";
import { NuggetShareComponent } from "./NuggetShareComponent";
import { PendingListContainer } from "./pendingListContainer";
import { TokenDataProps } from "../../home/Drawer";
import jwt_decode from "jwt-decode";
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { markRequestFeedbackTourDone, requestNowPageVisitIncrementCounter } from "../../../redux/slices/CoachMarkSlice";  
import "../../../App.css";

const useStyle = makeStyles((theme) => ({
  typography_title: {
    fontSize: theme.spacing(3.5),
    textAlign: "start",
    margin: theme.spacing(0, 0, 0, 15.5),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 0, 0, 0),
      textAlign: "center",
    },
  },
  btn: {
    margin: theme.spacing(3, 0, 3, 15),
    backgroundColor: "#576BEA",
    color: "white",
    textAlign: "center",
    fontWeight: 600,
    padding: theme.spacing(1, 2, 1, 2),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 0, 0, 0),
    },
  },
  dashboardTopGrid: {
    margin: theme.spacing(-21, 0, 0, 0),
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(-21, 0, 0, -10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(-21, 0, 0, 0),
    },
  },
  comingSoonGrid: {
    height: theme.spacing(67.25),
    padding: theme.spacing(8.5, 0, 0, 0),
    margin: theme.spacing(0, 0, 0, 0),
    border: "1px solid #E7E7E7",
    borderRadius: theme.spacing(1.25),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 4, 0),
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
      margin: theme.spacing(-3, 0, 4, 0),
      height: theme.spacing(64.25),
    },
  },
  dashboardTopArtworkCta: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dashboardItems: {
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(6, 0, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(3, 0, -5, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(6, 0, -4, 0),
    },
  },
  dashboardItems2: {
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(0, 0, 0, 0),
    },
  },
  img: {
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(20),
      width: "100%",
    },
  },
}));

export const Dashboard = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(-1);
  const [requestedUserId, setRequestedUserId] = React.useState(0);
  const emptyRequestArrray: Array<ReceivedRequest> = [];
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.auth.token);
  const [logout, setLogout] = React.useState<Boolean>(false);
  const [isDetailView, setIsDetailView] = React.useState<Boolean>(false);
  const [refreshSeed, setRefreshSeed] = React.useState(-1);
  const [showTour, setShowTour] = useState(false);
  const requestFeedbackButtonTourStatus = useSelector((state: State) => state.coachMarks.dashboardTour.requestNowButtonTour);
  const dashboardVisitCount = useSelector((state: State) => state.coachMarks.dashboardTour.dashboardVisitCount);
  const history = useHistory();
  const tourRef = useRef<Shepherd.Tour | null>(null);

  useEffect(() => {
    const isSetupCompleted = props?.location?.state?.setupCompleted;
    let tokenData: TokenDataProps = {
      role: "Regular",
      exp: 0,
      iat: 0,
      sub: 0,
      status: "ACTIVE",
    };
    try {
      tokenData = jwt_decode(token);
      if (tokenData.status === "INACTIVE" && !isSetupCompleted) {
        props.history.push("/setup/workspace");
      }
    } catch (err) {
      console.error((err as Error).message);
    }
  }, [token]);

  useEffect(() => {
    let requestParams: string = "";
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(countSharedFeedback(requestingData));
    let requestParams2: string = "?requestTag=ANY";
    let requestingData2: RequestingParams = {
      requestParams: requestParams2,
    };
    dispatch(countReceivedFeedback(requestingData2));
    let requestParams3: string = "?status=PENDING";
    let requestingData3: RequestingParams = {
      requestParams: requestParams3,
    };
    dispatch(getAllReceivedRequests(requestingData3));
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: true,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
    dispatch(requestData(param));
  }, []);

  useEffect(() => {
    let requestParams: string = "";
    let requestingData: RequestingParams = {
      requestParams: requestParams,
    };
    dispatch(countSharedFeedback(requestingData));
    let requestParams2: string = "?requestTag=ANY";
    let requestingData2: RequestingParams = {
      requestParams: requestParams2,
    };
    dispatch(countReceivedFeedback(requestingData2));
    let requestParams3: string = "?status=PENDING";
    let requestingData3: RequestingParams = {
      requestParams: requestParams3,
    };
    dispatch(getAllReceivedRequests(requestingData3));
    const param: UserAndAttributeSliceProps = {
      isUsers: true,
      isAttributes: true,
      isClients: false,
      isDrafts: false,
      isRequests: false,
      isScale: false,
    };
  }, [refreshSeed]);

  useEffect(() => {
    
    if (requestFeedbackButtonTourStatus === "PENDING" && dashboardVisitCount === 0) {
      setShowTour(true);
      dispatch(markRequestFeedbackTourDone()); 
    } else {
      setShowTour(false);
    }
  }, [requestFeedbackButtonTourStatus, dashboardVisitCount]);

  
  useEffect(() => {
    if (showTour) {
      tourRef.current = new Shepherd.Tour({

        defaultStepOptions: {
          classes: 'custom-class-sm',
          scrollTo: { behavior: 'smooth', block: 'center' },
        }
      });
      
      const attachToOptions = {
        element: '.custom-request-btn',
        on: 'right'
      };
  
      if (window.innerWidth <= 767) {

        attachToOptions.on = 'bottom';
      }
  
      tourRef.current.addSteps([

        {
          text: 'Request your peers for feedback from here',
          attachTo: attachToOptions,
          id: 'step1',
          scrollTo: true,
          when: {
            show: () => {
              setTimeout(() => {
                tourRef.current?.next();
              }, 5000);
            }
          },
          classes: 'request-now-button-coachmark'
        }
      ]);
  
      tourRef.current.start();
    }
  }, [showTour]);

  useEffect(() => {
    return history.listen(() => {
      if (tourRef.current) {
        tourRef.current?.cancel(); // *** Cancel the tour on route change ***
      }
    });
  }, [history]);

  const refreshResetSeed = () => {
    setTimeout(() => {
      setRefreshSeed(Math.random());
  }, 2000);
  };

  const countShared: number =
    useSelector((state: State) => state.countShared.data) ?? -1;

  const countReceived: number =
    useSelector((state: State) => state.countReceived.data) ?? -1;

  const requests =
    useSelector((state: State) => state.receivedRequests.data) ??
    emptyRequestArrray;

  const loading: boolean = useSelector(
    (state: State) =>
      state.receivedRequests.loading ||
      state.countReceived.loading ||
      state.countShared.loading
  );
  const statusCode: number | null = useSelector((state: State) =>
    state.countShared.status !== 200
      ? state.countShared.status
      : state.countReceived.status !== 200
      ? state.countReceived.status
      : state.receivedRequests.status !== 200
      ? state.receivedRequests.status
      : state.data.status !== 200
      ? state.data.status
      : state.nuggetFeedback.status !== 201
      ? state.nuggetFeedback.status
      : null
  );

  const renderErrorModal = () => (
    <CommonFunctionalityComponent
      props={props}
      logout={logout}
      setLogout={setLogout}
      statusCode={statusCode}
    />
  );

  const requestNowClicked = () => {
    dispatch(requestNowPageVisitIncrementCounter());
  }

  const renderDashboardTopArtwork = () => (
    <Grid container justify="center">
      <Grid container xs={12} item>
        <Hidden smDown>
          <LazyLoadImage
            src="/DashboardTopArtwork.svg"
            alt="DashboardTopArtwork"
            width={"100%"}
            className={classes.img}
          />
        </Hidden>
        <Hidden mdUp>
          <LazyLoadImage
            src="/DTA.svg"
            alt="DashboardTopArtwork"
            width={"100%"}
          />
        </Hidden>
      </Grid>
      <Grid
        xs={12}
        item
        container
        direction="row"
        className={classes.dashboardTopGrid}
      >
        <Grid item xs={12} className={classes.dashboardItems}>
          <Typography variant="h6" className={classes.typography_title}>
            There is no failure.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.dashboardItems2}>
          <Typography variant="h6" className={classes.typography_title}>
            Only feedback
          </Typography>
        </Grid>
        <Grid item xs={12} container className={classes.dashboardTopArtworkCta }>
            <Button
              color="primary"
              className={`${classes.btn} custom-request-btn `}
              onClick={() => { setValue(2); requestNowClicked(); }}
              variant="contained"
            >
              REQUEST NOW
            </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderComingSoon = () => (
    <>
      <Grid container justify="center" style={{ backgroundColor: "#F5F6FA" }}>
        <NuggetShareComponent
          refreshResetSeed={refreshResetSeed}
        ></NuggetShareComponent>
      </Grid>
    </>
  );

  const renderCountCards = () => (
    <DashboardCountCards
      countShared={countShared}
      countReceived={countReceived}
      setValue={setValue}
    ></DashboardCountCards>
  );

  const renderPendingListComponent = () => (
    <PendingListContainer
      requests={requests}
      setValue={setValue}
      setRequestedUserId={setRequestedUserId}
      requestedUserId={requestedUserId}
      refreshResetSeed={refreshResetSeed}
    ></PendingListContainer>
  );

  if (requestedUserId > 0) {
    return <Redirect to={`/feedback/share/${requestedUserId}`} />;
  }

  if (value === 2) {
    history.push("/feedback/requests/employee");
  }

  if (value === 3) {
    history.push("/feedback/shared");
  }

  if (value === 4) {
    history.push("/feedback/received");
  }

  return (
    <DashboardLayout
      props={props}
      loading={loading}
      logout={logout}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      renderErrorModal={renderErrorModal}
      renderDashboardTopArtwork={renderDashboardTopArtwork}
      renderComingSoon={renderComingSoon}
      renderCountCards={renderCountCards}
      renderPendingListComponent={renderPendingListComponent} 
    >

    </DashboardLayout>
  );
};
