import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { LeaderCardProps } from "./type";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    height: theme.spacing(7.5),
    backgroundColor: `#F5F6FA`,
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0, 1.75, 1.5, 1.75),
    padding: theme.spacing(0.75, 2, 1.5, 1.75),
  },
}));

export const LeaderboardMemberCardComponent: React.FC<LeaderCardProps> = ({
  users,
  position,
  feedbackCount,
}) => {
  const classes = useStyles();
  let names: Array<string> = [];
  if (users) {
    names = users?.map((u) => u?.firstName + " " + u?.lastName);
    names = names.reverse();
  }
  return (
    <Grid xs={12} item container justify="center" alignItems="center">
      <Grid
        xs={12}
        item
        container
        alignItems="center"
        className={classes.cardGrid}
      >
        <Grid xs={1} item container justify="flex-start">
          <Typography variant="h5" style={{ color: "#5F41B2" }}>
            {position}
          </Typography>
        </Grid>
        <Grid xs={10} item container>
          <Grid item className="otherLeader">
            <div className="avatars">
              {users?.map((u, idx) => (
                <span className="avatar" id={`1-${idx}`} key={`1-${idx}`}>
                  <img
                    src={u.imageUrl}
                    alt={u.firstName.toUpperCase()}
                    style={{
                      height: theme.spacing(5),
                      width: theme.spacing(5),
                    }}
                  />
                </span>
              ))}
            </div>
          </Grid>
          <Grid xs={7} item container alignItems="center">
            <Typography
              variant="h5"
              style={{
                color: "#272727",
                textAlign: "start",
                position: "relative",
                left: "14px",
              }}
            >
              {names.length > 0 && names?.join(", ")}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={1} item justify="flex-end" container>
          <Typography
            style={{
              color: "#5F41B2",
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Montserrat",
            }}
          >
            {feedbackCount}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
