import { makeStyles } from "@material-ui/core/styles";

export const addQuestionModalStyles = makeStyles((theme) => ({
    addQuestionInput: {
        marginBottom: theme.spacing(1.75),
        marginTop: theme.spacing(1.75),
        "& > p:last-child": {
            color: "#DC0101",
            fontSize: 12,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: theme.spacing(1),
            fontFamily: "Montserrat",
        },
    },
    showQuestionsWrapper: {
        marginBottom: theme.spacing(1.75),
    },
    dropdown: {
        padding: "4px 12px",
        "& > :first-child": {
            backgroundColor: "none",
            "&:focus": {
                background: "none",
            },
        },
    },
    helperText: {
        color: "#DC0101",
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 0,
        marginTop: theme.spacing(1),
        fontFamily: "Montserrat"
    },
    dropdownWrapper: {
        marginLeft: theme.spacing(3)

    }
}));

export interface IValidationErrors {
    questionText: string;
    responseType: string;
}