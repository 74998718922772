/* eslint-disable no-param-reassign */
// redux toolkit lib uses immer lib to which allows us to use mutable syntax
// the state here is nested, it is easier to use a mutable syntax to uppdate the state

import { createSlice} from '@reduxjs/toolkit';

 
const coachMarkSlice = createSlice({
  name: 'coachMarks',
  initialState: {
    showTour: false,
    dashboardTour: {
        requestNowButtonTour : "PENDING",
        shareFeedbackButtonTour : "PENDING",
        shareNuggetTour : "PENDING",
        requestClientTour : "PENDING",
        myTeamsButtonTour : "PENDING",
        dashboardVisitCount : 0,
        requestNowPageVisitCount : 0

    }  
  },
  reducers: {
    markRequestFeedbackTourDone : (state) => {
        state.dashboardTour.requestNowButtonTour = "DONE";
      },
    markShareFeedbackTourDone : (state) => {
      state.dashboardTour.shareFeedbackButtonTour = "DONE";
    },
    markShareNuggetTourDone : (state) => {
      state.dashboardTour.shareNuggetTour = "DONE";
    },
    markRequestClientTourDone : (state) => {
      state.dashboardTour.requestClientTour = "DONE";
    },
    markMyTeamsTourDone : (state) => {
      state.dashboardTour.myTeamsButtonTour = "DONE";
    },
    dashboardVisitIncrementCounter : (state) => {
      state.dashboardTour.dashboardVisitCount += 1;
    },
    requestNowPageVisitIncrementCounter : (state) => {
      state.dashboardTour.requestNowPageVisitCount += 1;
    }
  } 
});

export const { markRequestFeedbackTourDone,  markShareFeedbackTourDone, markShareNuggetTourDone, markRequestClientTourDone, markMyTeamsTourDone, dashboardVisitIncrementCounter,requestNowPageVisitIncrementCounter} = coachMarkSlice.actions;
export default coachMarkSlice;
