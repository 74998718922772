import {
    Typography,
  } from "@material-ui/core";
  import { Modal } from "../../../../components/Modal/Modal";
  import { ModalHeader } from "../../../../components/Modal/ModalHeader";
  import { ModalBody } from "../../../../components/Modal/ModalBody";
  import { ModalFooter } from "../../../../components/Modal/ModalFooter";
  import { createTeamCancelModalStyles } from "./helper";
  
  export const CreateTeamCancelModal = ({
    open,
    handleCancel,
    handleSuccess,
    disableActions
  }) => {
    const classes = createTeamCancelModalStyles();
    
    return (
      <Modal open={open} >
        <ModalHeader title="Alert" showBackground={false} handleCancel={handleCancel} />
        <ModalBody>
        <div>
          <Typography className={classes.confirmationInfo}>
            If you exit this page, any data will be lost, and you will need to initiate the team creation process from the start.
          </Typography>
          <Typography className={classes.confirmationInfo}>
            Are you sure you want to leave the page?
          </Typography>
        </div>
        </ModalBody>
        <ModalFooter
          buttonLabel="Leave"
          secondaryLabel="Continue editing"
          handleCancel={handleCancel}
          handleSubmit={handleSuccess}
          disableActions={disableActions}
        />
      </Modal>
    );
  };
  