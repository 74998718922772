import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { SearchBoxPropsWithPageNumberReset, SearchOption } from "./type";
import { useStyle } from "./UserSearchBoxComponent";

export const CustomSearchBoxComponent: React.FC<
  SearchBoxPropsWithPageNumberReset
> = ({ option, setOption, setPageNumber, options, placeholder }) => {
  const classes = useStyle();

  return (
    <Autocomplete
      className={classes.input}
      value={option}
      onChange={(event: any, newValue: SearchOption | null) => {
        setOption(newValue);
        setPageNumber(1);
      }}
      options={options}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={placeholder || "Search by name, attribute..."}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              className: classes.inputText,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
