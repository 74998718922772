import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../../home/Layout";
import { ToolBar } from "../../../../modules/home/ToolBar";

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: "left",
    width: `${window.innerWidth - 224}px`,
    padding: theme.spacing(6, 6, 6, 5),
    marginTop: theme.spacing(6),
    background: "#F5F6FA",
    height: "-webkit-fill-available",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 4, 6, 4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11.25),
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
}));

export const MemberDetailsLayout = ({
  props,
  loading,
  setLogout,
  isDetailView,
  setIsDetailView,
  memberName,
  renderErrorModal,
  renderDetailSection,
  renderTabsWithReport,
  renderList,
  renderChangePageComponent,
}) => {
  document.title = "Grow-Wize | Member Details";
  window.scrollTo(0, 0);
  const classes = useStyle();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempFunction = () => {
    return 0;
  };

  const renderToolBar = () => (
    <ToolBar
      headerName={`${memberName} Details`}
      setLogout={setLogout}
      isDetailView={isDetailView}
      setIsDetailView={setIsDetailView}
      handleDrawerToggle={handleDrawerToggle}
      value={-2}
      setValue={tempFunction}
      tabValue={-2}
      validLength={false}
    ></ToolBar>
  );

  const renderReceivedFeedbackReportLayout = () => (
    <Grid
      container
      direction="column"
      className={classes.root}
      justify="flex-start"
    >
    <Grid container alignItems="stretch" direction="column">
      {renderErrorModal()}
      <Grid xs={12} item container>
        {renderDetailSection()}
      </Grid>
      <Grid xs={12} item container>
        {renderTabsWithReport()}
      </Grid>
      <Grid xs={12} item container>
        {renderList()}
      </Grid>
      <Grid xs={12} item container>
        {renderChangePageComponent()}
      </Grid>
      </Grid>
    </Grid>
  );

  return (
    <HomeLayout
      loading={loading}
      renderToolBar={renderToolBar}
      props={props}
      mobileOpen={mobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      renderSelectedOption={renderReceivedFeedbackReportLayout}
    ></HomeLayout>
  );
};
