import React from 'react';
import { Divider, Grid, makeStyles, Tab, Tabs as MuiTabs } from "@material-ui/core";


const useStyle = makeStyles((theme) => ({
    tabsRoot: {
        background: "#FFFFFF"
    },
    tabs: {
        width: "-webkit-fill-available",
        padding: theme.spacing(0),
    },
    tabsMozWidth: {
        width: "-moz-available",
    },
    divider: {
        height: 3,
        background: "#E7E7E7",
        borderRadius: 2,
        position: "absolute",
        width: "100%",
        bottom: -0.5,
        zIndex: 1
    },
    indicator: {
        backgroundColor: "#7C69EC",
        zIndex: 2,
        borderRadius: 1
    },
    tabsGrid: {
        backgroundColor: "#F5F6FA",
        position: "relative"
    },
    tabText: {
        textTransform: "none",
        width: "inherit",
        fontSize: theme.spacing(1.75),
        fontWeight: 500,
        fontFamily: "Montserrat",
    },
    defaultColor: {
        color: "#868686",
        fontSize: 14,
        fontWeight: 500
    },
    activeColor: {
        color: "#7C69EC",
        fontSize: 14,
        fontWeight: 700
    },
    tab: {
        width: "inherit",
        minWidth: theme.spacing(10),
        padding: theme.spacing(0.5, 2.5, 0, 2.5),
    },
}));

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export const Tabs = ({ tabValue, setTabValue, data }) => {
    const classes = useStyle();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Grid
            xs={12}
            item
            className={classes.tabsGrid}
        >
            <MuiTabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={`${classes.tabsMozWidth} ${classes.tabs}`}
                classes={{
                    indicator: classes.indicator,
                    root: classes.tabsRoot
                }}
            >
                {data?.map(item => (
                    <Tab
                        key={item?.key}
                        classes={{ root: classes.tab }}
                        label={(
                            <span
                                className={`${classes.tabText} ${tabValue === item?.key ? classes.activeColor : classes.defaultColor}`}
                            >{item?.label}</span>
                        )}
                        {...a11yProps(item?.key)}
                    />
                ))}
            </MuiTabs>
            <Divider className={classes.divider} />
        </Grid >
    );
}