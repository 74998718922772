
function Website() {
    return (
        <div style={{margin: 0 }}>
            <iframe src="website/index.html" style={{ width: '100vw', height: '99vh' }}/>
        </div>
    )
}

export default Website;
