import { Grid, Typography } from "@material-ui/core";
import { teamListTableItemStyles } from "./helper";
import { useHistory, useLocation } from "react-router-dom";


const TeamListTableItem = ({
  teamId,
  teamName,
  teamLeads,
  totalMembers,
  titles,
  pageNumber,
}) => {
  const classes = teamListTableItemStyles();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleViewMore = (teamId: number) => {
    if (currentPath === "/admin/teams") {
      history.push({
        pathname: `/admin/teams/details/${teamId}`,
        state: { from: "teams_list", currentPage: pageNumber },
      });
    } else if (currentPath === "/teams") {
      history.push({
        pathname: `/teams/details/${teamId}`,
        state: { from: "teams_list", currentPage: pageNumber },
      });
    }

  };
  return (
    <>
      <Grid
        key={teamId}
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={`${classes.container}`}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={titles[0]?.size}
        >
          <Typography className={`${classes.typography_body_2}`}>
            {teamName}
          </Typography>
        </Grid>

        <Grid item xs={titles[1]?.size}>
          <Typography variant="h6" className={`${classes.typography_body_3}`} style={{ maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
           {teamLeads.length > 0 ? teamLeads.join(', ') : "-"} 
          </Typography>
        </Grid>

        <Grid item xs={titles[2]?.size}>
          <Typography variant="h6" className={`${classes.typography_body_3}`}>
            {totalMembers}
          </Typography>
        </Grid>

        <Grid container direction="row" alignItems="center" item xs={titles[3]?.size} style={{ display: "flex"}}>
          <Typography>
            <button
                style={{
                    borderRadius: "5px",
                    border: "1px solid #D7D7D7",
                    background: "#FFF",
                    display: "flex",
                    padding: "11px 32px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    color: "#868686",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    cursor: "pointer",
                }}
                onClick={()=> handleViewMore(teamId)}  
            >
                View
            </button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TeamListTableItem;
