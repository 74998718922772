import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { HomeLayout } from "../../home/Layout";
import { ToolBar } from "../../../modules/home/ToolBar";

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(11, 6, 2, 5),
        marginTop: theme.spacing(6.5),
        background: "#FFFFFF",
        height: "calc(100vh - 52px)",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(6, 4, 6, 4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(11.25),
            padding: theme.spacing(5, 3, 3, 3),
        },
    },
    loaderBackground: {
        height: "100%",
        padding: 0,
        background: "none",
        overflow: "hidden"
    },
    disabledContainer: {
        pointerEvents: "none",
        opacity: 0.3
    },
}));

export const Loader = () => {
    const classes = useStyle();
    return (
        <Grid container className={classes.loaderBackground}>
            <Grid container justify="center" alignItems="center">
                <CircularProgress />
            </Grid>
        </Grid>
    );
};

export const SettingsLayout = ({
    props,
    loading,
    setLogout,
    isDetailView,
    setIsDetailView,
    renderErrorModal,
    renderSettings
}) => {
    document.title = "Grow-Wize | Admin - Attributes";
    const classes = useStyle();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const tempFunction = () => {
        return 0;
    };

    const renderToolBar = () => (
        <ToolBar
            headerName="Settings"
            setLogout={setLogout}
            isDetailView={isDetailView}
            setIsDetailView={setIsDetailView}
            handleDrawerToggle={handleDrawerToggle}
            value={-2}
            setValue={tempFunction}
            tabValue={-2}
            validLength={false}
        ></ToolBar>
    );

    const renderSettingsContent = () => (
        <>
            {renderErrorModal()}
            <Grid container direction="row" className={classes.root} justify="center">
                {loading ? <Grid
                    item
                    xs={12}
                    container
                    style={{ width: "100%", height: "80vh", position: "absolute" }}
                >
                    <Loader></Loader>
                </Grid> :
                    <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                        style={{ width: "inherit", height: "100%" }}
                    >
                        {renderSettings()}
                    </Grid>
                }
            </Grid>
        </>
    )

    return (
        <HomeLayout
            loading={loading}
            renderToolBar={renderToolBar}
            props={props}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            renderSelectedOption={renderSettingsContent}
        ></HomeLayout>
    );
};
