const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getDateInDesiredFormat(date: Date) {
  return (
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear()
  ).toString();
}

export function getDateInLeaderBoardFormat(date: Date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let str = day + "/" + month + "/" + year;
  return str;
}

export function getMonthAndYearFormat(monthYear: string) {
  let array = monthYear.split("-");
  return (month[parseInt(array[0]) - 1] + "' " + array[1].substr(2)).toString();
}

export function getStartAndEndDateForCalendarDateRange() {
  return (
    {
      startDate : getDateInLeaderBoardFormat(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), // last year
      endDate : getDateInLeaderBoardFormat(new Date()) // current date
    }
  );
}
