export enum Messages {
    ADD_ATTRIBUTE = "New attribute created successfully!",
    UPDATE_ATTRIBUTE_NAME = "Attribute name updated successfully!",
    DISABLE_ATTRIBUTE = "Attribute disabled successfully",
    ENABLE_ATTRIBUTE = "Attribute enabled successfully",
    UPDATE_QUESTION = "Question updated Successfully!",
    DISABLE_QUESTION = "Question disabled successfully",
    ENABLE_QUESTION = "Question enabled successfully",
    ADD_QUESTION = "Question added successfully",
}

export enum ValidationErrors {
    QUESTION_TEXT = "Please write a question here",
    RESPONSE_TYPE = "Please select response type",
    ATTRIBUTE_NAME = "Please enter attribute name",
    ATTRIBUTE_NAME_LENGTH = "Character length limit has been reached - 50 characters"
}

export enum AttributeStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}