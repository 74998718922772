import { Layout } from "../../../layouts/onboarding/landing_page/Layout";
import { MainHeader } from "./MainHeader";
import "./extra_fonts/landingFont.css";
import { HeroSectionComponent } from "./HeroSection";
import { ContactUsSlideComponent } from "./ContactUsSlide";
import { BridgeComponent } from "./BridgeSection";
import { FeatureSectionComponent } from "./FeatureSection";
import { AdditionalFeatureSectionComponent } from "./AdditionalFeature";
import { FooterComponent } from "./Footer";

export const MainSection = (props) => {
  const TopSectionPage = () => <MainHeader />;
  const MainSectionPage1 = () => <HeroSectionComponent />;
  const MainSectionPage2 = () => <BridgeComponent />;
  const MainSectionPage3 = () => <FeatureSectionComponent />;
  const MainSectionPage4 = () => <AdditionalFeatureSectionComponent />;
  const MainSectionPage5 = () => <ContactUsSlideComponent />;
  const MainSectionPage6 = () => <FooterComponent />;
  return (
    <Layout
      TopSectionPage={TopSectionPage}
      MainSectionPage1={MainSectionPage1}
      MainSectionPage2={MainSectionPage2}
      MainSectionPage3={MainSectionPage3}
      MainSectionPage4={MainSectionPage4}
      MainSectionPage5={MainSectionPage5}
      MainSectionPage6={MainSectionPage6}
    ></Layout>
  );
};
