import {
  Avatar,
  Button,
  Chip,
  createStyles,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import theme from "../../../theme";
import {
  RenderAddNewProps,
  RenderStringAnswerProp,
  RenderAttributeQuestionProp,
  RenderReactionAnswerProp,
  State,
  ResponseScaleProp,
  EditorForTextProps,
  ConfirmationPromptProp,
} from "../../../type";
import {
  handleClickChip,
  handleOnChange,
  handleAnswer,
} from "./addNewButtonHandles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";
import "./style.css";
import { appColor } from "../../constants/customThemeColor";
import { useCustomStyle } from "../../../styles";
import {
  onDraftEditorCopy,
  onDraftEditorCut,
  handleDraftEditorPastedText,
} from "draftjs-conductor";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    likertChip: {
      justifyContent: "center",
      textAlign: "center",
      width: theme.spacing(10),
      height: theme.spacing(6),
      padding: "25px 0px 25px 10px",
      margin: "30px 45px 16px 45px",
      borderRadius: "24px",
      backgroundColor: "#FFFFFF",
      border: "1px solid rgba(175,175,175,0.3)",
      boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.15)",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      "&:active": {
        backgroundColor: "#FFFFFF",
      },
      [theme.breakpoints.down("md")]: {
        padding: "25px 0px 25px 10px",
      },
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(6.25),
        height: theme.spacing(3.75),
        padding: "1px 0px 1px 10px",
        margin: "16px 12px 16px 9px",
      },
    },
    textBox: {
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.25),
      },
    },
    remainingChar: {
      fontSize: theme.spacing(1.5),
      textAlign: "end",
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.spacing(1.125),
      },
    },
    textBoxx: {
      margin: theme.spacing(0),
      padding: theme.spacing(0.5, 2.5, 1, 2.5),
      width: "-webkit-fill-available",
      flexWrap: "wrap",
      wordWrap: "break-word",
    },
    textBoxx1: {
      width: "-moz-available",
    },
    avatar: {
      margin: "1px 0px 1px 15px",
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(2.625),
        height: theme.spacing(2.625),
        margin: "1px 0px 1px 0px",
      },
    },
    wrapperClassName: {
      height: "100%",
    },
    // toolTip: {
    //   height: theme.spacing(6.5),
    //   width: theme.spacing(24.25),
    //   fontSize: theme.spacing(1.75),
    //   fontWeight: 500,
    //   fontColor: "#FFFFFF",
    //   padding: theme.spacing(1, 1.25, 1, 1.25),
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: theme.spacing(1),
    //     height: theme.spacing(4.25),
    //     width: theme.spacing(14.625),
    //   },
    // },
    avatarLikertText: {
      textAlign: "center",
      margin: theme.spacing(0, 0, 0, 0.5),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 1, 0, 0.5),
      },
    },
    likertGrid: {
      margin: theme.spacing(0, 0, 4, -1.75),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 0, 3, 1.5),
      },
    },
    avatarInfo: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(1.625),
        width: theme.spacing(1.625),
      },
    },
    chipStyle: {
      margin: theme.spacing(1.5, 0, 0, 0),
      borderColor: "#576bea",
      width: theme.spacing(16),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(12.5),
      },
    },
  })
);

export const RenderAttributeQuestion: React.FC<RenderAttributeQuestionProp> = ({
  question,
  answer,
}) => {
  const classes = useStyle();
  let regex = /(<([^>]+)>)/gi;
  const customClasses = useCustomStyle();

  let str = answer
    .replaceAll(/\&nbsp;/g, "")
    .replaceAll(regex, "")
    .replace(/\s/g, "")
    .replace(/\n/g, "");
  return (
    <Grid item xs={12}>
      <Typography
        gutterBottom
        variant="h5"
        className={classes.textBox}
        style={{ margin: theme.spacing(1.25, 2.5, 0, 2.5) }}
      >
        {question}{" "}
        {str?.length < 1 && (
          <span
            style={{ color: `${appColor.redWarn}` }}
            className={customClasses.typography_font_14_8_500}
          >
            (required)
          </span>
        )}
      </Typography>
    </Grid>
  );
};

export const RenderLikertScaleAnswer: React.FC<RenderReactionAnswerProp> = ({
  idx,
  idx_1,
  emojiSelected,
  setEmojiSelected,
  shareFeedbackData,
  setShareFeedbackData,
  scaleVersion,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector((state: State) => state.data.data?.scaleResponseEntityList) ??
    emptyList;
  const responseScales2 =
    useSelector(
      (state: State) =>
        state.attributesDataForClient.data?.scaleResponseEntityList
    ) ?? emptyList;
  let currentScale;
  if (responseScales.length > 0) {
    currentScale = responseScales.find(
      (scale) =>
        scale.scaleName === "LIKERT" && scale.scaleVersion === scaleVersion
    );
  } else {
    currentScale = responseScales2.find(
      (scale) =>
        scale.scaleName === "LIKERT" && scale.scaleVersion === scaleVersion
    );
  }
  return (
    <Grid
      container
      direction="row"
      key={`grid ${idx + idx_1}`}
      className={classes.likertGrid}
    >
      {currentScale?.scaleResponses?.map((emoji, idx_2) => (
        <>
          <Hidden xsDown key={`list-${idx_2}`}>
            <Grid item justify="center">
              <Chip
                key={`chip ${idx_2}`}
                disabled={
                  shareFeedbackData[idx].questions[idx_1].answer === "Skipped"
                }
                avatar={
                  <Avatar src={emoji.url} className={classes.avatar}></Avatar>
                }
                className={classes.likertChip}
                onClick={handleClickChip(
                  idx_2,
                  idx,
                  idx_1,
                  emojiSelected,
                  setEmojiSelected,
                  shareFeedbackData,
                  setShareFeedbackData,
                  currentScale.scaleResponses
                )}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? {
                        borderColor: emoji.borderColor,
                        backgroundColor: emoji.color,
                      }
                    : { borderColor: "#FFFFFF" }
                }
              />
              <Typography
                variant="h4"
                className={`${customClasses.typography_font_14_10} ${classes.avatarLikertText}`}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? { color: emoji.borderColor }
                    : { color: "#A0A0A0" }
                }
              >
                {emoji.name}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item justify="flex-start" container alignItems="center">
              <Chip
                key={`chip ${idx_2}`}
                avatar={
                  <Avatar src={emoji.url} className={classes.avatar}></Avatar>
                }
                className={classes.likertChip}
                onClick={handleClickChip(
                  idx_2,
                  idx,
                  idx_1,
                  emojiSelected,
                  setEmojiSelected,
                  shareFeedbackData,
                  setShareFeedbackData,
                  currentScale.scaleResponses
                )}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? {
                        borderColor: emoji.borderColor,
                        backgroundColor: emoji.color,
                      }
                    : { borderColor: "#FFFFFF" }
                }
              />
              <Typography
                variant="h4"
                className={`${customClasses.typography_font_14_10} ${classes.avatarLikertText}`}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? { color: emoji.borderColor }
                    : { color: "#A0A0A0" }
                }
              >
                {emoji.name}
              </Typography>
            </Grid>
          </Hidden>
        </>
      ))}
    </Grid>
  );
};

export const RenderLikertNaScaleAnswer: React.FC<RenderReactionAnswerProp> = ({
  idx,
  idx_1,
  emojiSelected,
  setEmojiSelected,
  shareFeedbackData,
  setShareFeedbackData,
  scaleVersion,
}) => {
  const classes = useStyle();
  const customClasses = useCustomStyle();
  const emptyList: Array<ResponseScaleProp> = [];
  const responseScales =
    useSelector((state: State) => state.data.data?.scaleResponseEntityList) ??
    emptyList;
  const responseScales2 =
    useSelector(
      (state: State) =>
        state.attributesDataForClient.data?.scaleResponseEntityList
    ) ?? emptyList;
  let currentScale;
  if (responseScales.length > 0) {
    currentScale = responseScales.find(
      (scale) =>
        scale.scaleName === "LIKERT_NA" && scale.scaleVersion === scaleVersion
    );
  } else {
    currentScale = responseScales2.find(
      (scale) =>
        scale.scaleName === "LIKERT_NA" && scale.scaleVersion === scaleVersion
    );
  }

  return (
    <Grid
      container
      direction="row"
      key={`grid ${idx + idx_1}`}
      className={classes.likertGrid}
    >
      {currentScale?.scaleResponses.map((emoji, idx_2) => (
        <>
          <Hidden xsDown key={`list-${idx_2}`}>
            <Grid item justify="center">
              <Chip
                disabled={
                  shareFeedbackData[idx].questions[idx_1].answer === "Skipped"
                }
                key={`chip ${idx_2}`}
                avatar={
                  emoji.url !== "/NA.svg" ? (
                    <Avatar src={emoji.url} className={classes.avatar}></Avatar>
                  ) : (
                    <img src={emoji.url} alt="NA"></img>
                  )
                }
                className={classes.likertChip}
                onClick={handleClickChip(
                  idx_2,
                  idx,
                  idx_1,
                  emojiSelected,
                  setEmojiSelected,
                  shareFeedbackData,
                  setShareFeedbackData,
                  currentScale.scaleResponses
                )}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? {
                        borderColor: emoji.borderColor,
                        backgroundColor: emoji.color,
                      }
                    : { borderColor: "#FFFFFF" }
                }
              />
              <Typography
                variant="h4"
                className={`${customClasses.typography_font_14_10} ${classes.avatarLikertText}`}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? { color: emoji.borderColor }
                    : { color: "#A0A0A0" }
                }
              >
                {emoji.name}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item justify="flex-start" container alignItems="center">
              <Chip
                key={`chip ${idx_2}`}
                avatar={
                  emoji.url !== "/NA.svg" ? (
                    <Avatar src={emoji.url} className={classes.avatar}></Avatar>
                  ) : (
                    <img src={emoji.url} alt="NA"></img>
                  )
                }
                className={classes.likertChip}
                onClick={handleClickChip(
                  idx_2,
                  idx,
                  idx_1,
                  emojiSelected,
                  setEmojiSelected,
                  shareFeedbackData,
                  setShareFeedbackData,
                  currentScale.scaleResponses
                )}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? {
                        borderColor: emoji.borderColor,
                        backgroundColor: emoji.color,
                      }
                    : { borderColor: "#FFFFFF" }
                }
              />
              <Typography
                variant="h4"
                className={`${customClasses.typography_font_14_10} ${classes.avatarLikertText}`}
                style={
                  idx_2 === emojiSelected[idx].queEmoji[idx_1].emojiId &&
                  shareFeedbackData[idx].questions[idx_1].answer[0] === "/"
                    ? { color: emoji.borderColor }
                    : { color: "#A0A0A0" }
                }
              >
                {emoji.name}
              </Typography>
            </Grid>
          </Hidden>
        </>
      ))}
    </Grid>
  );
};

export const RenderStringAnswer: React.FC<RenderStringAnswerProp> = ({
  question,
  idx,
  idx_1,
  shareFeedbackData,
  setShareFeedbackData,
  onAnyChange,
}) => {
  const classes = useStyle();
  const [editorState, setEditorState] = React.useState(
    question.answer?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(question.answer).contentBlocks,
            convertFromHTML(question.answer).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const onEditorStateChange = (state) => {
    setEditorState(state);
    question.answer = draftToHtml(convertToRaw(state.getCurrentContent()))
    .replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim();
    onAnyChange(true);
  };
  return (
    <Grid xs={12} item className={`${classes.textBoxx1} ${classes.textBoxx}`}>
      <EditorForText
        idx={idx}
        textHeader={"answer"}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeHolderText={"Name the attribute(s) "}
      />
      <TextareaAutosize
        id={`answer ${idx_1}`}
        hidden
        value={question.answer}
        onChange={handleAnswer(
          idx,
          idx_1,
          shareFeedbackData,
          setShareFeedbackData
        )}
      />
    </Grid>
  );
};

export const RenderSuggestions: React.FC<RenderAddNewProps> = ({
  attribute,
  idx,
  shareFeedbackData,
  setShareFeedbackData,
  onAnyChange,
}) => {
  const classes = useStyle();
  const [editorState, setEditorState] = React.useState(
    attribute.suggestions?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(attribute.suggestions).contentBlocks,
            convertFromHTML(attribute.suggestions).entityMap
          )
        )
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    attribute.suggestions = draftToHtml(
      convertToRaw(state.getCurrentContent())
      ).replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim().replaceAll("_self","_blank");
    onAnyChange(true);
  };
  const [open, setOpen] = React.useState(attribute.suggestions ? true : false);

  const [confirm, setConfirm] = React.useState(false);

  const handleClose = () => {
    setEditorState(EditorState.createEmpty());
    attribute.suggestions = "";
    onAnyChange(true);
    setOpen(false);
    setConfirm(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirmation = () => {
    if (attribute.suggestions) setConfirm(true);
    else {
      setOpen(false);
    }
  };
  return (
    <div
      className={`${classes.textBoxx1} ${classes.textBoxx}`}
      style={{
        position: "relative",
      }}
    >
      <Chip
        variant="outlined"
        style={
          open
            ? {
                marginBottom: "12px",
              }
            : {}
        }
        className={classes.chipStyle}
        key={open ? "OPENED_SUGGESTION" : "CLOSED_SUGGESTION"}
        id={open ? "OPENED_SUGGESTION" : "CLOSED_SUGGESTION"}
        onClick={open ? handleConfirmation : handleOpen}
        onDelete={open ? handleConfirmation : handleOpen}
        deleteIcon={
          <Avatar
            src={open ? "/Remove.svg" : "/Add.svg"}
            alt="info"
            className={classes.avatarInfo}
          ></Avatar>
        }
        label={
          <Typography
            gutterBottom
            variant="h5"
            className={classes.textBox}
            style={{
              textAlign: "start",
              margin: "0px",
            }}
          >
            {"Suggestions"}
          </Typography>
        }
      />
      {confirm && (
        <ConfirmationPrompt
          confirm={confirm}
          setConfirm={setConfirm}
          handleClose={handleClose}
        />
      )}
      {open && (
        <>
          <EditorForTextWithPermanentToolbar
            idx={idx}
            textHeader={"suggestions"}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            always={false}
          />
          <TextareaAutosize
            id={`suggestions ${idx}`}
            hidden
            value={attribute.suggestions}
            onChange={handleOnChange(
              idx,
              shareFeedbackData,
              setShareFeedbackData
            )}
          />
        </>
      )}
    </div>
  );
};

export const RenderResources: React.FC<RenderAddNewProps> = ({
  attribute,
  idx,
  shareFeedbackData,
  setShareFeedbackData,
  onAnyChange,
}) => {
  const classes = useStyle();
  const [editorState, setEditorState] = React.useState(
    attribute.resources?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(attribute.resources).contentBlocks,
            convertFromHTML(attribute.resources).entityMap
          )
        )
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    attribute.resources = draftToHtml(convertToRaw(state.getCurrentContent())
    ).replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim().replaceAll("_self","_blank");
    onAnyChange(true);
  };

  const [open, setOpen] = React.useState(attribute.resources ? true : false);
  const [confirm, setConfirm] = React.useState(false);

  const handleClose = () => {
    setEditorState(EditorState.createEmpty());
    attribute.resources = "";
    onAnyChange(true);
    setOpen(false);
    setConfirm(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirmation = () => {
    if (attribute.resources) setConfirm(true);
    else {
      setOpen(false);
    }
  };
  return (
    <div
      className={`${classes.textBoxx1} ${classes.textBoxx}`}
      style={{
        position: "relative",
      }}
    >
      <Chip
        variant="outlined"
        style={
          open
            ? {
                marginBottom: "12px",
              }
            : {}
        }
        className={classes.chipStyle}
        key={open ? "OPENED_REFERENCES" : "CLOSED_REFERENCES"}
        id={open ? "OPENED_REFERENCES" : "CLOSED_REFERENCES"}
        onClick={open ? handleConfirmation : handleOpen}
        onDelete={open ? handleConfirmation : handleOpen}
        deleteIcon={
          <Avatar
            src={open ? "/Remove.svg" : "/Add.svg"}
            alt="info"
            className={classes.avatarInfo}
          ></Avatar>
        }
        label={
          <Typography
            gutterBottom
            variant="h5"
            className={classes.textBox}
            style={{
              textAlign: "start",
              margin: "0px",
            }}
          >
            {"References "}
          </Typography>
        }
      />
      {confirm && (
        <ConfirmationPrompt
          confirm={confirm}
          setConfirm={setConfirm}
          handleClose={handleClose}
        />
      )}
      {open && (
        <>
          <EditorForTextWithPermanentToolbar
            idx={idx}
            textHeader={"resources"}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            placeHolderText={"Add helpful links / resources"}
            always={false}
          />
          <TextareaAutosize
            id={`resources ${idx}`}
            hidden
            value={attribute.resources}
            onChange={handleOnChange(
              idx,
              shareFeedbackData,
              setShareFeedbackData
            )}
          />
        </>
      )}
    </div>
  );
};

export const RenderContext: React.FC<RenderAddNewProps> = ({
  attribute,
  idx,
  shareFeedbackData,
  setShareFeedbackData,
  onAnyChange,
  isMissing,
}) => {
  const classes = useStyle();
  let regex = /(<([^>]+)>)/gi;
  const customClasses = useCustomStyle();
  const [editorState, setEditorState] = React.useState(
    attribute.context?.length > 0
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(attribute.context).contentBlocks,
            convertFromHTML(attribute.context).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const onEditorStateChange = (state) => {
    setEditorState(state);
    attribute.context = draftToHtml(convertToRaw(state.getCurrentContent())
    ).replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ').trim().replaceAll("_self","_blank");
    onAnyChange(true);
  };

  let str = attribute.context
    .replaceAll(/\&nbsp;/g, "")
    .replaceAll(regex, "")
    .replace(/\s/g, "")
    .replace(/\n/g, "");
  return (
    <div
      className={`${classes.textBoxx1} ${classes.textBoxx}`}
      style={isMissing && str.length < 1 ? { backgroundColor: "#FFF4EB" } : {}}
    >
      <Typography gutterBottom variant="h5" className={classes.textBox} id={`explanation-id-${attribute.attributeId}`}>
        {"Explanation/Details "}
        {(str?.length < 1 || attribute.context?.length < 9) && (
          <span
            style={{ color: `${appColor.redWarn}` }}
            className={customClasses.typography_font_14_8_500}
          >
            (required)
          </span>
        )}
      </Typography>
      <EditorForText
        idx={idx}
        textHeader={"context"}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
      <TextareaAutosize
        id={`context ${idx}`}
        hidden
        value={attribute.context}
        onChange={handleOnChange(idx, shareFeedbackData, setShareFeedbackData)}
      />
    </div>
  );
};

export const EditorForText: React.FC<EditorForTextProps> = ({
  idx,
  textHeader,
  editorState,
  onEditorStateChange,
  always,
  placeHolderText,
}) => {
  const [showToolbar, setShowToolbar] = React.useState<Boolean>(
    always === true ? false : always === false ? true : false
  );
  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState
  ) => {
    let newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      onEditorStateChange(newState);
      return true;
    }

    return false;
  };

  return (
    <Editor
      id={`${textHeader} ${idx}`}
      editorState={editorState}
      placeholder={placeHolderText ? placeHolderText : "Type here..."}
      toolbarClassName="toolbarClassName"
      toolbarOnFocus={showToolbar}
      toolbarHidden={!showToolbar}
      onFocus={() => {
        if (always === true) setShowToolbar(false);
        else setShowToolbar(true);
      }}
      onBlur={() => {
        if (always === false) setShowToolbar(true);
        else setShowToolbar(false);
      }}
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      toolbar={{
        options: ["inline", "link", "emoji", "list"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        link: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          popupClassName: undefined,
          dropdownClassName: undefined,
          options: ["link", "unlink"],
        },
        list: {
          options: ["unordered"],
        },
      }}
      onEditorStateChange={onEditorStateChange}
      onCopy={onDraftEditorCopy}
      onCut={onDraftEditorCut}
      handlePastedText={handlePastedText}
    />
  );
};

export const EditorForTextWithPermanentToolbar: React.FC<
  EditorForTextProps
> = ({
  idx,
  textHeader,
  editorState,
  onEditorStateChange,
  always,
  placeHolderText,
}) => {
  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState
  ) => {
    let newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      onEditorStateChange(newState);
      return true;
    }

    return false;
  };
  return (
    <Editor
      id={`${textHeader} ${idx}`}
      editorState={editorState}
      placeholder={placeHolderText ? placeHolderText : "Type here..."}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      toolbar={{
        options: ["inline", "link", "emoji", "list"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        link: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          popupClassName: undefined,
          dropdownClassName: undefined,
          options: ["link", "unlink"],
        },
        list: {
          options: ["unordered"],
        },
      }}
      onEditorStateChange={onEditorStateChange}
      onCopy={onDraftEditorCopy}
      onCut={onDraftEditorCut}
      handlePastedText={handlePastedText}
    />
  );
};

interface RemainingCharWarnTextProp {
  text: string;
  limit: number;
}

export const RemainingCharWarnText: React.FC<RemainingCharWarnTextProp> = ({
  text,
  limit,
}) => {
  const classes = useStyle();
  return (
    <>
      {text && text?.length > limit - 50 && (
        <Typography variant="body2" className={classes.remainingChar}>
          {text?.length < limit + 1 ? (
            `${limit - text?.length} char remains`
          ) : (
            <span style={{ color: "#DC0101" }}>{`${
              limit - text?.length
            } char remains`}</span>
          )}
        </Typography>
      )}
    </>
  );
};

export const ConfirmationPrompt: React.FC<ConfirmationPromptProp> = ({
  confirm,
  setConfirm,
  handleClose,
}) => {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setConfirm(false)}
    >
      <Grid
        xs={12}
        item
        container
        style={{
          backgroundColor: "#FFFFFF",
          height: "120px",
          width: "260px",
          position: "absolute",
          boxShadow: "0 2px 4px 0 rgba(90,90,90,0.4)",
          padding: "12px",
          zIndex: 5,
          bottom: 165,
        }}
      >
        <Grid xs={12} item>
          <Typography
            variant="h6"
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "12px",
            }}
          >
            Are you sure you want to remove?
          </Typography>
        </Grid>
        <Grid xs={12} item container justify="center">
          <Grid item>
            <Button
              onClick={() => setConfirm(false)}
              variant="outlined"
              style={{ color: "#868686", borderColor: "#868686" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={1} item></Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#576bea", borderColor: "#576bea" }}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};
