import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SharedFeedbackDetailData } from "../../modules/user/shared_feedback/typeSharedFeedbacks";
import { SharedDraftDetailByClientSliceProps } from "../../type";
import { HttpStatus } from "../../enums/httpStatus";

export const sharedDraftDetailByClient = createAsyncThunk(
  "client/feedback/shared/draft/detail",
  async (params: SharedDraftDetailByClientSliceProps) => {
    try {
      const options = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/external/client/feedbacks/shared/draft/detail?feedbackId=${params.feedbackId}&senderId=${params.senderId}`,
        options
      );
      if (response.status === HttpStatus.OK) {
        return {
          status: HttpStatus.OK,
          data: await response.json(),
        };
      } else {
        return {
          status: response.status,
          text: await response.text(),
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

const sharedDraftDetailByClientSlice = createSlice({
  name: "sharedDraftDetailByClient",
  initialState: {
    status: null as number | null,
    loading: false as boolean,
    text: null as string | null,
    data: null as SharedFeedbackDetailData | null,
  },
  reducers: {
    clearData: (state) => {
      state.status = null;
      state.loading = false;
      state.text = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedDraftDetailByClient.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data;
        state.text = null;
      } else if (action.payload.text !== undefined) {
        state.data = null;
        state.text = action.payload.text;
      }
    });
    builder.addCase(sharedDraftDetailByClient.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.data = null;
      state.text = null;
    });
    builder.addCase(sharedDraftDetailByClient.rejected, (state) => {
      state.status = 503;
      state.loading = false;
      state.data = null;
      state.text = null;
    });
  },
});

export const { clearData } = sharedDraftDetailByClientSlice.actions;

export default sharedDraftDetailByClientSlice;
