import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyle = makeStyles((theme) => ({
  typography_body: {
    fontSize: theme.spacing(1.75),
    color: "#868686",
    textAlign: 'start',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    }
  },
  typography_body1: {
    fontSize: theme.spacing(1.75),
    color: "#868686",
    textAlign: 'start',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      margin:theme.spacing(0,0,0,-3.5),
    }
  },
  header: {
    padding: theme.spacing(1, 1.5, 1.5, 1),
    backgroundColor: 'rgba(87,107,234,0.05)'
  },
}));

export const PendingRequestHeader = () => {
  const classes = useStyle();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.header}
    >
      <Grid item xs={5} sm={5}>
        <Typography className={classes.typography_body}>
          Name
      </Typography>
      </Grid>
      <Grid item xs={5} sm={4}>
        <Typography className={classes.typography_body}>
          Requested Date
      </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} sm={2}>
        <Typography className={classes.typography_body1}>
          Action
      </Typography>
      </Grid>
    </Grid>)
}
