import jwt_decode from "jwt-decode";
import { HttpStatus } from "../enums/httpStatus";
import { sessionExpired } from "../redux/slices/AuthSlice";

export const isTokenExpired = (token: any) => {
    if (!token) return true;
    try {
        const tokenData: any = jwt_decode(token);
        const expiration = new Date(tokenData?.exp * 1000);
        return expiration < new Date();
    } catch (e) {
        console.log(e);
        return true;
    }
};

export const post = async (url: string, data: any, { getState, dispatch }) => {
    const state = getState();
    const token = state?.auth?.token;
    const isExpired = isTokenExpired(token);

    if (isExpired) {
        dispatch(sessionExpired());
        return;
    }
    if (!isExpired) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authToken: token,
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
            return response;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const get = async (url, { getState, dispatch }) => {
    const state = getState();
    const token = state?.auth?.token;
    const isExpired = isTokenExpired(token);

    if (isExpired) {
        dispatch(sessionExpired());
        return;
    }
    if (!isExpired) {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authToken: token,
            },
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
            return response;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const patch = async (url, data, { getState, dispatch }) => {
    const state = getState();
    const token = state?.auth?.token;
    const isExpired = isTokenExpired(token);

    if (isExpired) {
        dispatch(sessionExpired());
        return;
    }

    if (!isExpired) {
        const options = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                authToken: token,
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const put = async (url, data, { getState, dispatch }) => {
    const state = getState();
    const token = state?.auth?.token;
    const isExpired = isTokenExpired(token);

    if (isExpired) {
        dispatch(sessionExpired());
        return;
    }

    if (!isExpired) {
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                authToken: token,
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const deleteApi = async (url, { getState, dispatch }) => {
    const state = getState();
    const token = state?.auth?.token;
    const isExpired = isTokenExpired(token);

    if (isExpired) {
        dispatch(sessionExpired());
        return;
    }

    if (!isExpired) {
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                authToken: token,
            },
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}