import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { RequestFeedbackListLayoutProps } from "../../../type";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "-webkit-fill-available",
    minHeight: `${window.innerHeight - 102}px`,
  },
  loaderBackground: {
    width: "inherit",
    height: `${window.innerHeight - 225}px`,
  },
}));

export const RequestFeedbackListLayout: React.FC<RequestFeedbackListLayoutProps> =
  ({
    loading,
    renderSearchBox,
    renderRequests,
    renderFilter,
    renderChangePageButtons,
    renderRequestCancelModal,
  }) => {
    const classes = useStyle();

    if (loading)
      return (
        <Grid container className={classes.loaderBackground}>
          <Grid container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </Grid>
      );
    else
      return (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          <Grid xs={12} item container alignItems="center">
            <Grid
              xs={6}
              item
              container
              justify="flex-start"
              alignItems="center"
            >
              {renderSearchBox()}
              {renderRequestCancelModal()}
            </Grid>
            <Grid xs={6} item container justify="flex-end" alignItems="center">
              {renderFilter()}
            </Grid>
            <Grid xs={12} item container>
              {renderRequests()}
            </Grid>
            <Grid xs={12} item container justify="flex-end">
              {renderChangePageButtons()}
            </Grid>
          </Grid>
        </Grid>
      );
  };
