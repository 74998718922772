import React, { FunctionComponent } from 'react';
import MuiModal from "@material-ui/core/Modal";
import { Backdrop, CircularProgress, Fade, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalGrid: {
        display: "flex",
        flexDirection: "column",
        width: theme.spacing(112),
        backgroundColor: "#FFFFFF",
        border: "4px solid rgba(175,175,175,0.3)",
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(40),
            padding: theme.spacing(0, 0, 6, 0),
        },
        position: "relative"
    },
    loaderBackground: {
        position: "absolute",
        height: "100%"
    },
    fadedWrapper: {
        opacity: 0.4,
        pointerEvents: "none"
    }
}))

type ModalProps = {
    open: boolean,
    loading?: Boolean | undefined,
    onClose?: () => {},
    className?: string,
}

export const Loader = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.loaderBackground}>
            <Grid container justify="center" alignItems="center">
                <CircularProgress />
            </Grid>
        </Grid>
    );
};

export const Modal: FunctionComponent<ModalProps> = ({ open, children, loading, onClose, className = "" }) => {
    const classes = useStyles()
    return (
        <MuiModal
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={onClose}
        >
            <Fade in={open}>
                <Grid className={`${classes.modalGrid} ${className}`}>
                    {loading && (
                        <Loader />
                    )}
                    <Grid className={`${loading ? classes.fadedWrapper : ""}`}>
                        {children}
                    </Grid>
                </Grid>
            </Fade>
        </MuiModal>
    );
}