import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import "./extra_fonts/landingFont.css";

const useStyle = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    height: `${window.innerHeight}px`,
    padding: theme.spacing(0, 15),
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 4),
      margin: theme.spacing(2, 0),
      width: `${window.innerWidth}px`,
    },
  },
  slide_1_main_text: {
    color: "#012241",
    fontSize: theme.spacing(5),
    fontWeight: 600,
    fontFamily: "EuclideCircularABold",
    marginBottom: theme.spacing(3),
    width: theme.spacing(85.75),
    letterSpacing: theme.spacing(0.0625),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: "center",
      width: "auto",
    },
  },
  slide_1_sub_text: {
    color: "#012241",
    fontSize: theme.spacing(2.5),
    letterSpacing: theme.spacing(0.0625),
    fontWeight: 500,
    fontFamily: "Open Sans",
    width: theme.spacing(85.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
      textAlign: "center",
      width: "auto",
    },
  },
  img: {
    width: theme.spacing(60.25),
    backgroundColor: "#D8D8D8",
    border: "1px solid #D8D8D8",
    height: theme.spacing(37.5),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      width: `${3 * (window.innerWidth / 4)}px`,
    },
  },
  left_section: {
    marginRight: theme.spacing(3.75),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(14, 0, 2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(8, 0, 2, 0),
    },
  },
  right_section: {
    marginLeft: theme.spacing(3.75),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 5, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
    },
  },
}));

export const HeroSectionComponent: React.FC = () => {
  const classes = useStyle();
  return (
    <>
      <Grid
        xs={12}
        item
        container
        className={classes.parallax}
        style={{ backgroundImage: "#FFFFFF" }}
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.left_section}>
          <Typography className={classes.slide_1_main_text}>
            We help you{" "}
            <span style={{ color: "#576bea", fontStyle: "italic" }}>
              Work Smarter
            </span>{" "}
            with a structured, 360° feedback system
          </Typography>
          <Typography className={classes.slide_1_sub_text}>
            Encourage productivity and help your employees grow with meaningful
            feedback.
          </Typography>
        </Grid>
        <Grid item className={classes.right_section}>
          <Grid xs={12} item>
            <Paper className={classes.img} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
