import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, {useState} from "react";
import theme from "../../../theme";
import {PendingListContainerProps, RequestingParams, State} from "../../../type";
import { PendingRequestCard } from "./dashboardPendingRequestCard";
import { PendingRequestHeader } from "./dashboardPendingRequestHeader";
import {DeleteAttributeCardComponent} from "../../components/DeleteAttributeCard";
import {updateRequestStatus} from "../../../redux/slices/UpdateRequestStatusSlice";
import {useDispatch, useSelector} from "react-redux";

const useStyle = makeStyles((theme) => ({
  pendingComponent: {
    height: theme.spacing(38.75),
    margin: theme.spacing(0, 0, 0, 0),
    borderRadius: theme.spacing(1.25),
    boxShadow: "0 2px 4px 0 rgba(90,90,90,0.1)",
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(30),
      margin: theme.spacing(0, 0, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(35),
      margin: theme.spacing(0, 0, 0, 0),
    },
  },
  tableHeader: {
    margin: theme.spacing(5, 0, 1.25, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0, 2.25, 0),
    },
  },
  pendingListContent: {
    height: theme.spacing(33),
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(37),
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(30),
    },
  },
}));

export const PendingListContainer: React.FC<PendingListContainerProps> = ({
  requests,
  setValue,
  setRequestedUserId,
                                                                            requestedUserId,
                                                                            refreshResetSeed,
                                                                          }) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState<boolean>(false);
  const [requestingUserId,setRequestingUserId] = useState(-1);
  const [requestId,setRequestId] = useState(-1);

  const handleModalClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const handleDelete = () => {
    const feedbackRequestId=requestId  ;
    const requestedUser=requestingUserId;

    let params =
        "?feedbackRequestId=" +
        feedbackRequestId +
        "&responderId=" +
        requestedUser;
    const param: RequestingParams = {
      requestParams: params,
    };
    dispatch(updateRequestStatus(param));
    setOpen(false);
    refreshResetSeed();
  };
  const renderRequestCancelModal = () => (
      <DeleteAttributeCardComponent
          openModal={open}
          handleModalClose={handleModalClose}
          handleDelete={handleDelete}
          from={"SENT_REQUEST"}
      />
  );

  return (
    <>
      <Grid container justify="flex-start" className={classes.tableHeader}>
        <Typography variant="body1" style={{ color: "#272727" }}>
          Received Requests
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            className={classes.pendingComponent}
            elevation={0}
          >
            <PendingRequestHeader></PendingRequestHeader>
            <Grid className={classes.pendingListContent}>
              {requests.length >= 1 ? (
                requests.map((req, idx) => (
                  <>
                    <Divider
                      key={`list-${idx}`}
                      style={{
                        backgroundColor: "#576bea66",
                        height: "0.05vw",
                      }}
                    ></Divider>
                    <PendingRequestCard
                      key={`pending-list-${idx}`}
                      setValue={setValue}
                      setRequestedUserId={setRequestedUserId}
                      requestingUser={req.requestingUser}
                      timeOfRequest={req.timeOfRequest}
                      renderRequestCancelModal={renderRequestCancelModal}
                      setOpen={setOpen}
                      setRequestingUserId={setRequestingUserId}
                      setRequestId={setRequestId}
                      requestId={req.feedbackRequestId}
                    ></PendingRequestCard>
                  </>
                ))
              ) : (
                <Grid
                  container
                  xs={12}
                  item
                  style={{ marginTop: theme.spacing(4) }}
                  justify="center"
                  alignItems="center"
                >
                  <Typography style={{ color: "#868686" }}>
                    No pending requests
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
