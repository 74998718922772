import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import theme from "../../../theme";
import { PendingRequestCardProps } from "../../../type";
import { getDateInDesiredFormat } from "../../../util/GetDateInDesiredFormat";

const useStyle = makeStyles((theme) => ({
  typography_body1: {
    textAlign: "start",
    color: "#576BEA",
    textDecoration: "underline",
    fontSize: theme.spacing(1.75),
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
      margin: theme.spacing(0, 0, 0, -8),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0, 0, 0, 0),

    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      margin: theme.spacing(0, 0, 0, -1.5),
    },
  },
  typography_body_3: {
    textAlign: "start",
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.25),
    },
  },
  btn: {
    padding: theme.spacing(0, 0, 0, 0),
    textAlign: "start",
    margin: theme.spacing(0, 0, 0, 0),
  },
  avatar: {
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, -1.25, 0, 1),
    },
  },
  name: {
    padding: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1, 0, 1.5),
    },
  },
}));

export const PendingRequestCard: React.FC<PendingRequestCardProps> = ({
  requestingUser,
  timeOfRequest,
  setValue,
  setRequestedUserId, renderRequestCancelModal,setOpen,setRequestingUserId,setRequestId,requestId
}) => {
  const classes = useStyle();
  const onDecline = () => {
    setRequestingUserId(requestingUser.id);
    setRequestId(requestId);
    setOpen(true);
  }
  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        style={{ padding: theme.spacing(1, 1.5, 1.5, 1) }}
      >
        {renderRequestCancelModal()}
        <Grid container item xs={5} sm={5} direction="row" alignItems="center">
          <Grid item xs={2} className={classes.avatar}>
            <Avatar
              alt={"img"}
              src={requestingUser.imageUrl}
              className={classes.small}
            />
          </Grid>
          <Grid item xs={10} className={classes.name}>
            <Typography variant="h6" className={classes.typography_body_3}>
              {requestingUser.firstName + " " + requestingUser.lastName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} sm={4}>
          <Typography variant="h6" className={classes.typography_body_3}>
            {getDateInDesiredFormat(new Date(timeOfRequest))}
          </Typography>
        </Grid>
        <Grid item xs={2} container>
          <Grid item xs={10} className={classes.name}>
          <Button
            className={classes.btn}
            onClick={() => {
              setRequestedUserId(requestingUser.id);
              setValue(1);
            }}
          >
            <Typography className={classes.typography_body1}>
              View Request
            </Typography>
          </Button>

        </Grid>
          <Grid item xs={2} className={classes.name}>
            <Button
                className={classes.btn}
                onClick={()=>{

                  onDecline();
                }}

                style={{
                  marginLeft: "12px",
                }}
            >
              <Typography className={classes.typography_body1}>
                Decline
              </Typography>
            </Button>
          </Grid>
          </Grid>
      </Grid>
    </>
  );
};
