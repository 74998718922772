import React, { useEffect, useState } from 'react';
import { SettingsLayout } from '../../../layouts/admin/settings/Layout';
import { CommonFunctionalityComponent } from '../../components/CommonFunctionalityComponent';
import { Button, Divider, Grid, Icon, Radio, Tooltip, Typography } from '@material-ui/core';
import { OptionType } from '../../components/Dropdown/helper';

import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { MESSAGES, useSettingsStyles } from './helper';
import { addDays, format, getDate } from 'date-fns';
import { ReminderConfirmationModal } from './reminderConfirmationModal/reminderConfirmationModal';
import { FREQUENCY_OPTIONS } from '../../../constants/reminderFrequencyConstants';
import { REMINDER_FREQUENCY_MAPPING, getDayWithOrdinal } from './reminderConfirmationModal/helper';
import ToastMessage from '../../components/ToastMessage';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../type';
import { HttpStatus } from '../../../enums/httpStatus';
import { UpdateReminderSliceProp, updateReminder } from '../../../redux/slices/UpdateReminderSlice';
import { ReminderFrequencyEnum } from '../../../enums/reminderFrequencyEnums';
import { getReminder } from '../../../redux/slices/GetReminderSlice';
import { CreateReminderSliceProp, createReminder } from '../../../redux/slices/CreateReminderSlice';

const CustomRadio = ({ option, selectedOption, setSelectedOption }) => {
    const classes = useSettingsStyles();

    return (
        <Grid container alignItems='center' onClick={() => setSelectedOption(option)} className={classes.radioWrapper}>
            <Radio classes={{
                root: classes.radioRoot,
                checked: classes.radioChecked,
            }}
                checked={option?.key === selectedOption?.key}
            />
            <Typography className={classes.radioLabel} >{option?.value}</Typography>
        </Grid>
    )
}

const InfoIconWithTooltip = ({ message }) => {
    const classes = useSettingsStyles();
    return (
        <Tooltip title={message} arrow classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
            <img src="/infoIcon.svg" alt="info" height={18} width={18} style={{ cursor: "pointer" }}></img>
        </Tooltip>
    )
}

export const Settings = (props) => {
    const [logout, setLogout] = useState<Boolean>(false);
    const [isDetailView, setIsDetailView] = useState<Boolean>(false);
    const tomorrowDate = addDays(new Date(), 1);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [nextScheduledDate, setNextScheduledDate] = useState<Date | null>(null);
    const [frequency, setFrequency] = useState<OptionType | undefined>()
    const [open, setOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [data, setData] = useState<any>("");
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { loading: isCreatingReminder, status: createReminderStatus } = useSelector((state: State) => state.createReminderDetails);
    const { loading: isUpdatingReminder, status: updateReminderStatus } = useSelector((state: State) => state.updateReminderDetails);
    const { loading: isFetchingReminder, status: fetchReminderStatus, data: reminderDetails } = useSelector((state: State) => state.getReminderDetails);

    const handleDateChange = (date) => {
        setStartDate(date);
    };

    useEffect(() => {
        if (reminderDetails?.length > 0) {
            const frequency = FREQUENCY_OPTIONS?.find(option => option?.key === reminderDetails[0]?.frequency);
            setStartDate(new Date(reminderDetails[0]?.startDate));
            setFrequency(frequency);
            setNextScheduledDate(new Date(reminderDetails[0]?.nextScheduledDate))
        }
    }, [reminderDetails])

    const classes = useSettingsStyles();

    const loading: Boolean = isFetchingReminder;

    const statusCode: number | null = null;

    const fetchReminderDetails = () => {
        dispatch(getReminder());
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchReminderDetails();
    }, [])

    const renderErrorModal = () => (
        <CommonFunctionalityComponent
            props={props}
            logout={logout}
            setLogout={setLogout}
            statusCode={statusCode}
        />
    );

    const checkValidation = () => {
        if (reminderDetails?.length > 0) {
            const currentDate = new Date(reminderDetails[0]?.startDate);
            const currentFrequency = reminderDetails[0]?.frequency;

            if (currentDate.getTime() === startDate?.getTime() && currentFrequency === frequency?.key) {
                return false;
            } else return true;

        } else {
            return startDate && frequency;
        }

    }

    const isSaveDisabled = !checkValidation();

    const handleSave = () => {
        const startDay = getDate(startDate || new Date());
        setData({ startDay: getDayWithOrdinal(startDay), frequency: REMINDER_FREQUENCY_MAPPING[frequency?.key || ""] })
        setOpenModal(true);
    }

    const handleCancel = () => {
        setOpenModal(false);
        resetData();
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    const resetData = () => {
        if (reminderDetails?.length > 0) {
            const frequency = FREQUENCY_OPTIONS?.find(option => option?.key === reminderDetails[0]?.frequency);
            setStartDate(new Date(reminderDetails[0]?.startDate));
            setFrequency(frequency);
            setNextScheduledDate(new Date(reminderDetails[0]?.nextScheduledDate))
        } else {
            setStartDate(null);
            setFrequency(undefined);
            setTimeout(() => {
                setData(null);
            }, 1000);
        }
    }

    const handleFocus = (e) => {
        e.target.blur();
    };


    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSuccess(false);
        setMessage("");
    };

    useEffect(() => {
        if (!isCreatingReminder && submitted && createReminderStatus === HttpStatus.OK) {
            setShowSuccess(true);
            setMessage(MESSAGES.SAVE_REMINDER);
        }
    }, [isCreatingReminder, createReminderStatus])

    useEffect(() => {
        if (!isUpdatingReminder && submitted && updateReminderStatus === HttpStatus.OK) {
            setShowSuccess(true);
            setMessage(MESSAGES.SAVE_REMINDER);
        }
    }, [isUpdatingReminder, updateReminderStatus])

    const handleConfirm = () => {
        setSubmitted(true);
        if (!submitted) {
            if (reminderDetails?.length > 0) {
                const params: UpdateReminderSliceProp = {
                    reminderId: reminderDetails[0]?.id,
                    reminderDetails: {
                        startDate: format(startDate || new Date(), 'dd-MM-yyyy'),
                        frequency: frequency?.key as ReminderFrequencyEnum,
                    },
                };
                dispatch(updateReminder(params));
            } else {
                const params: CreateReminderSliceProp = {
                    reminderDetails: {
                        startDate: format(startDate || new Date(), 'dd-MM-yyyy'),
                        frequency: frequency?.key as ReminderFrequencyEnum,
                    },
                };
                dispatch(createReminder(params));
            }
        }
    };

    useEffect(() => {
        if (!isCreatingReminder && submitted) {
            handleClose();
            setSubmitted(false);
        }
    }, [isCreatingReminder])

    useEffect(() => {
        if (!isUpdatingReminder && submitted) {
            handleClose();
            setSubmitted(false);
        }
    }, [isUpdatingReminder])

    const shouldDisableDate = (date) => {
        if (!reminderDetails || reminderDetails?.length === 0) {
            return date < new Date();
        }
        const areDatesEqual = date.getTime() === new Date(reminderDetails[0]?.startDate).getTime();
        return areDatesEqual ? false : date < new Date();
    }
    
    const renderInfoBanner = () => {
        return (
            <Grid container direction="row" className={classes.bannerContainer}>
                <Icon
                    style={{
                        height: "18px",
                        width: "18px",
                        paddingBottom: "32px",
                        marginRight: "6px",
                    }}
                >
                    <img src="/infoIcon.svg" alt="info" height={18} width={18}></img>
                </Icon>
                <Typography className={classes.infoText}>
                    According to current settings upcoming reminder is scheduled for {format(nextScheduledDate || new Date(), "dd/MM/yyyy")}
                </Typography>
            </Grid>
        );
    };

    const renderSettings = () => (
        <Grid className={classes.root} container direction='column'>
            <ToastMessage
                open={showSuccess}
                handleClose={handleCloseToast}
                message={message}
            />
            <ReminderConfirmationModal
                open={openModal}
                handleCancel={handleCancel}
                data={data}
                handleSuccess={handleConfirm}
                disableActions={submitted}
            />
            <Typography className={classes.label} style={{ marginBottom: 6 }}>
                Reminder
            </Typography>
            <Divider className={classes.divider} />
            <Grid style={{ marginBottom: 32 }}>
                {nextScheduledDate && renderInfoBanner()}
                <Typography className={classes.label}>
                    Start Date
                    <InfoIconWithTooltip message="This is a reminder setting" />
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        placeholder='DD/MM/YYYY'
                        value={startDate}
                        onKeyDown={(e) => e.preventDefault()}
                        onFocus={handleFocus}
                        onChange={handleDateChange}
                        open={open}
                        onClick={() => setOpen(true)}
                        InputProps={{ disableUnderline: true, style: { color: "#272727", fontWeight: 500 } }}
                        className={classes.datePicker}
                        onClose={() => setOpen(false)}
                        PopoverProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            PaperProps: {
                                style: { marginTop: "16px", marginLeft: "-12px" },
                            },
                        }}
                        keyboardIcon={
                            <img src={open ? "/arrowUp3.svg" : "/arrowDown2.svg"} alt="toggle" />
                        }
                        autoOk
                        shouldDisableDate={shouldDisableDate}
                        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                            const isSelected = day?.toDateString() === selectedDate?.toDateString();

                            return (
                                <div
                                    className={`${isSelected ? classes.selectedDate : ''}`}
                                >
                                    {dayComponent}
                                </div>
                            );
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid>
                <Typography className={classes.label}>
                    Set Frequency
                    <InfoIconWithTooltip message="This is a reminder setting" />
                </Typography>
                <Grid>
                    {FREQUENCY_OPTIONS.map(option => (
                        <CustomRadio option={option} selectedOption={frequency} setSelectedOption={setFrequency} />
                    ))}
                </Grid>
            </Grid>
            <Grid className={classes.footer} container direction='column' alignItems='flex-end'>
                <Divider className={classes.divider} />
                <Button
                    color="primary"
                    className={`${classes.saveButton} ${isSaveDisabled ? classes.disabledButton : ""}`}
                    onClick={handleSave}
                    variant="contained"
                    disabled={isSaveDisabled}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <SettingsLayout
            props={props}
            loading={loading}
            setLogout={setLogout}
            isDetailView={isDetailView}
            setIsDetailView={setIsDetailView}
            renderErrorModal={renderErrorModal}
            renderSettings={renderSettings}
        />
    )
}
